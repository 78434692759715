<nz-page-header class="site-page-header" nzTitle="Compliance"
                [nzSubtitle]="data.tipoTela == 'grupo' ? 'Tributos por Grupo' : 'Tributos por Empresa'">
    <nz-page-header-extra>
        <nz-button-group>
            <button nz-button nzType="primary" nzGhost
                    style="border-top-left-radius: 0.45rem;border-bottom-left-radius: 0.45rem;"
                    (click)="data.tipoTela == 'grupo' ? openTab('ComplianceTributosGruposComponent') :
                     openTab('ComplianceTributosComponent')"
            >
                <i nz-icon nzType="bar-chart" nzTheme="outline"></i>
                Dashboard
            </button>
            <nz-badge [nzCount]="qtdFiltrosAtivosCabecalho" [nzStyle]="{ backgroundColor: '#52c41a' }"
                      style="margin-right: 15px;">
                <button nz-button nzType="primary" nzGhost (click)="abrirModal(formFiltrarCabecalho)"
                        style="margin-right: 0; border-top-right-radius: 0.45rem;border-bottom-right-radius: 0.45rem;">
                    <i nz-icon nzType="search"></i>
                    <span>Filtros</span>
                </button>
            </nz-badge>
        </nz-button-group>

    </nz-page-header-extra>
</nz-page-header>

<nz-content>
    <nz-card *ngIf="data.tipoTela == 'grupo' else cardEmpresa" [nzLoading]="cardLoadingEmpresa">
        <div class="d-flex justify-content-between" style="margin-bottom: 20px;">
            <div class="d-flex align-items-center">
                <div style="height: 45px;border: 1px rgba(0,0,0,.45) solid;border-radius: 50%;min-width: 45px; width: 45px;"
                     class="d-flex justify-content-center align-items-center">
                    <div class="ant-avatar" style="{{'background-color:'+ empresa.backgroundColor}}">
                        <span>{{ empresa.iniciais }}</span>
                    </div>
                </div>
                <h5 style="display: inline-block; margin: 0 10px" nz-typography>
                    {{ empresa.nome }}
                </h5>
                <span style="margin-left: 5px" *ngIf="data.tipoTela !== 'grupo'">
                    CNPJ: {{ modoDemo(empresa.cnpj | cpfCnpj, 'cnpj') }}
                </span>
                <span style="margin-left: 15px" *ngIf="ieSelecionada">
                    IE: {{ ieSelecionada }}
                </span>
            </div>
            <!--            Aguardando finalizar fluxo do book para descomentar o link para a tela de gerar book-->
            <!--            <i style="cursor: pointer; float: right; ; font-size: 1.5em; margin-right: 10px; color: #40a9ff"-->
            <!--               [nz-tooltip]="'Diagnóstico Fiscal'"-->
            <!--               class="fa-solid fa-stethoscope" (click)="openTab(-->
            <!--                   'DiagnosticoFiscalComponent', null, {idEmpresa})"></i>-->

        </div>
        <nz-descriptions nzTitle="" [nzColumn]="3">
            <nz-descriptions-item
                    nzTitle="Obrigações Min">{{ empresa.obrigacoesMin | currency:'R$' }}
            </nz-descriptions-item>
            <nz-descriptions-item
                    nzTitle="Obrigações Max">{{ empresa.obrigacoesMax | currency:'R$' }}
            </nz-descriptions-item>
            <nz-descriptions-item
                    nzTitle="Pagamentos">{{ empresa.pagamentos | currency:'R$' }}
            </nz-descriptions-item>
        </nz-descriptions>
    </nz-card>
    <ng-template #cardEmpresa>
        <nz-card [nzLoading]="cardLoadingEmpresa">
            <div class="d-flex justify-content-between" style="margin-bottom: 20px;">
                <div class="d-flex align-items-center">
                    <div style="height: 45px;border: 1px rgba(0,0,0,.45) solid;border-radius: 50%;min-width: 45px; width: 45px;"
                         class="d-flex justify-content-center align-items-center">
                        <svg width="40" height="40"
                             [data-jdenticon-value]="'001 - CROWN LIFT TRUCKS DO BRASIL - COMERCIO DE EMPILHADEIRA'"></svg>
                    </div>
                    <h5 style="display: inline-block; margin: 0 10px" nz-typography>
                        {{ modoDemo(empresa.nome, 'empresaCodigoNome') }}
                    </h5>
                    <span style="margin-left: 5px">
                    CNPJ: {{ modoDemo(empresa.cnpj | cpfCnpj, 'cnpj') }}
                </span>
                    <span style="margin-left: 15px" *ngIf="ieSelecionada">
                    IE: {{ ieSelecionada }}
                </span>
                </div>
                <!--            Aguardando finalizar fluxo do book para descomentar o link para a tela de gerar book-->
                <!--            <i style="cursor: pointer; float: right; ; font-size: 1.5em; margin-right: 10px; color: #40a9ff"-->
                <!--               [nz-tooltip]="'Diagnóstico Fiscal'"-->
                <!--               class="fa-solid fa-stethoscope" (click)="openTab(-->
                <!--                   'DiagnosticoFiscalComponent', null, {idEmpresa})"></i>-->

            </div>
            <nz-descriptions nzTitle="" [nzColumn]="3">
                <nz-descriptions-item
                        nzTitle="Obrigações Min">{{ empresa.obrigacoesMin | currency:'R$' }}
                </nz-descriptions-item>
                <nz-descriptions-item
                        nzTitle="Obrigações Max">{{ empresa.obrigacoesMax | currency:'R$' }}
                </nz-descriptions-item>
                <nz-descriptions-item
                        nzTitle="Pagamentos">{{ empresa.pagamentos | currency:'R$' }}
                </nz-descriptions-item>
            </nz-descriptions>
        </nz-card>
    </ng-template>
    <ng-template #extarCard>
        {{ labelAno }}
        <button style="margin-left: 5px" (click)="exibirTudo(!expandAllCards)" nz-button>
            <em *ngIf="expandAllCards" class="fas fa-angle-double-down"></em>
            <em *ngIf="!expandAllCards" class="fas fa-angle-double-up"></em>
        </button>
    </ng-template>

    <div *ngIf="promoverCard">
        <ng-container *ngTemplateOutlet="cardAusensiasDivergencias"></ng-container>
    </div>

    <div nz-row [nzGutter]="24">
        <div nz-col [nzSpan]="10">
            <nz-card class="font-doze"
                     nzTitle="Obrigações Acessórias (Oficiais)"
                     [nzExtra]="extarCard"
                     [ngClass]="!cardLoadingObrigacoesAcessorias ? 'ant-card-table' : ''"
                     [nzLoading]="cardLoadingObrigacoesAcessorias">
                <nz-table [ngStyle]="expandAllCards ? {} : {display: 'none'}" #tableObrigacoesAcessoriasOficiais
                          [nzData]="arrayTableObrigacoesAcessoriasOficiais"
                          [nzFrontPagination]="false"
                          [nzShowPagination]="false"
                          [nzScroll]="{ x: 'auto' }"
                          nzSize="small">
                    <thead>
                    <tr>
                        <th></th>
                        <th style="text-align: center;">Qtde</th>
                        <th style="text-align: center;">Min (R$)</th>
                        <th style="text-align: center;">Max (R$)</th>
                        <th style="text-align: center;">Min x Max (R$)</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let item of arrayTableObrigacoesAcessoriasOficiais" style="white-space: nowrap;">
                        <td style="">{{ item.obrigacao }}</td>
                        <td style="text-align: center;">
                            {{ item.qtde !== null ? item.qtde : '-' }}
                        </td>
                        <td style="text-align: right;">
                            <div *ngIf="item.obrigacoesMin < 0">
                                {{ item.obrigacoesMin * -1 | number }}
                                <span>(C)</span>
                            </div>
                            <div *ngIf="item.obrigacoesMin > 0">
                                {{ (item.obrigacoesMin |  number: '1.2-2' : 'pt-br') }}
                                <span>(D)</span>
                            </div>
                            <div *ngIf="item.obrigacoesMin == 0">
                                {{ (item.obrigacoesMin  | number: '1.2-2' : 'pt-br') }}
                            </div>
                            <div *ngIf="item.obrigacoesMin === null">-</div>
                        </td>
                        <td style="text-align: right;">
                            <div *ngIf="item.obrigacoesMax < 0">
                                {{ (item.obrigacoesMax * -1  | number: '1.2-2' : 'pt-br') }}
                                <span>(C)</span>
                            </div>
                            <div *ngIf="item.obrigacoesMax > 0">
                                {{ (item.obrigacoesMax  | number: '1.2-2' : 'pt-br') }}
                                <span>(D)</span>
                            </div>
                            <div *ngIf="item.obrigacoesMax == 0">
                                {{ (item.obrigacoesMax  | number: '1.2-2' : 'pt-br') }}
                            </div>
                            <div *ngIf="item.obrigacoesMax === null">-</div>
                        </td>
                        <td style="text-align: right;">
                            <span *ngIf="item.minMax < 0" [ngStyle]="{'color': 'red'}">
                                {{ (item.minMax * -1  | number: '1.2-2' : 'pt-br') }}
                            </span>
                            <span *ngIf="item.minMax > 0" [ngStyle]="item.minMax > 0 && {'color': 'red'}">
                                {{ (item.minMax  | number: '1.2-2' : 'pt-br') }}
                            </span>
                            <span *ngIf="item.minMax == 0" [ngStyle]="item.minMax > 0 && {'color': 'red'}">
                                {{ (item.minMax  | number: '1.2-2' : 'pt-br') }}
                            </span>
                            <span *ngIf="item.minMax === null">-</span>
                        </td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr>
                        <th>Total</th>
                        <th></th>
                        <th style="text-align: right;">
                            <div *ngIf="minObrigacoesOficiais < 0">
                                {{ (minObrigacoesOficiais * -1  | number: '1.2-2' : 'pt-br') }}
                                <span>(C)</span>
                            </div>
                            <div *ngIf="minObrigacoesOficiais > 0">
                                {{ (minObrigacoesOficiais  | number: '1.2-2' : 'pt-br') }}
                                <span>(D)</span>
                            </div>
                            <div *ngIf="minObrigacoesOficiais == 0">
                                {{ (minObrigacoesOficiais  | number: '1.2-2' : 'pt-br') }}
                            </div>
                            <div *ngIf="minObrigacoesOficiais === null">-</div>
                        </th>

                        <th style="text-align: right;">
                            <div *ngIf="maxObrigacoesOficiais < 0">
                                {{ (maxObrigacoesOficiais * -1  | number: '1.2-2' : 'pt-br') }}
                                <span>(C)</span>
                            </div>
                            <div *ngIf="maxObrigacoesOficiais > 0">
                                {{ (maxObrigacoesOficiais  | number: '1.2-2' : 'pt-br') }}
                                <span>(D)</span>
                            </div>
                            <div *ngIf="maxObrigacoesOficiais == 0">
                                {{ (maxObrigacoesOficiais  | number: '1.2-2' : 'pt-br') }}
                            </div>
                            <div *ngIf="maxObrigacoesOficiais === null">-</div>
                        </th>
                        <th style="text-align: right;">
                            <span *ngIf="minMaxObrigacoesOficiais < 0" [ngStyle]="{'color':'red'}">
                                {{ (minMaxObrigacoesOficiais * -1  | number: '1.2-2' : 'pt-br') }}
                            </span>
                            <span *ngIf="minMaxObrigacoesOficiais > 0">
                                {{ (minMaxObrigacoesOficiais | number: '1.2-2' : 'pt-br') }}
                            </span>
                            <span *ngIf="minMaxObrigacoesOficiais == 0">
                                {{ (minMaxObrigacoesOficiais  | number: '1.2-2' : 'pt-br') }}
                            </span>
                            <span *ngIf="minMaxObrigacoesOficiais === null">-</span>
                        </th>
                    </tr>
                    </tfoot>
                </nz-table>
            </nz-card>
        </div>
        <div nz-col [nzSpan]="14">
            <nz-card class="font-doze"
                     nzTitle="Obrigações Principais - Pagamentos"
                     [nzExtra]="extarCard"
                     [ngClass]="!cardLoadingObrigacoesPrincipais ? 'ant-card-table' : ''"
                     [nzLoading]="cardLoadingObrigacoesPrincipais">
                <div [ngStyle]="expandAllCards ? {} : {display: 'none'}" nz-row [nzGutter]="24">
                    <div nz-col [nzSpan]="12" class="d-flex align-items-center">
                        <highcharts-chart
                                [Highcharts]="HighchartsPie"
                                [options]="chartPieOptions"
                                [(update)]="updateFlag"
                                *ngIf="HighchartsPie && chartPieOptions"
                                style="width: 100%; height: 310px; display: block;"
                        ></highcharts-chart>
                    </div>
                    <div [nzSpan]="12" nz-col>
                        <nz-table #tableObrigacoesPrincipaisPagamentos
                                  [nzData]="arrayTableObrigacoesPrincipaisPagamentos"
                                  [nzFrontPagination]="false"
                                  [nzShowPagination]="false"
                                  [nzScroll]="{ x: 'auto' }"
                                  nzSize="small">
                            <thead>
                            <tr>
                                <th></th>
                                <th style="text-align: center;">Qtde</th>
                                <th style="text-align: center;">Valor (R$)</th>
                                <th style="text-align: center;">Pagto x Max (R$)</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let item of arrayTableObrigacoesPrincipaisPagamentos"
                                style="white-space: nowrap;">
                                <td>
                                    <i class="fas fa-circle" [ngStyle]="{'color': item.cor}"></i>
                                    <span>
                                        {{ item.obrigacao }}
                                    </span>
                                </td>
                                <td style="text-align: center;">{{ item.qtde }}</td>
                                <td style="text-align: right;">
                                    <div *ngIf="item.pagamentos < 0">
                                        {{ (item.pagamentos * -1  | number: '1.2-2' : 'pt-br') }}
                                    </div>
                                    <div *ngIf="item.pagamentos > 0">
                                        {{ (item.pagamentos  | number: '1.2-2' : 'pt-br') }}

                                    </div>
                                    <div *ngIf="item.pagamentos == 0">
                                        {{ (item.pagamentos  | number: '1.2-2' : 'pt-br') }}
                                    </div>
                                    <div *ngIf="item.pagamentos === null">-</div>
                                </td>
                                <td style="text-align: right;">
                                    <div *ngIf="item.pagamentosMax < 0" [ngStyle]="{'color':'red'}">
                                        {{ (item.pagamentosMax * -1  | number: '1.2-2' : 'pt-br') }}
                                    </div>
                                    <div *ngIf="item.pagamentosMax > 0">
                                        {{ (item.pagamentosMax | number: '1.2-2' : 'pt-br') }}
                                    </div>
                                    <div *ngIf="item.pagamentosMax == 0">
                                        {{ (item.pagamentosMax  | number: '1.2-2' : 'pt-br') }}
                                    </div>
                                    <div *ngIf="item.pagamentosMax === null">-</div>
                                </td>
                            </tr>
                            </tbody>
                            <tfoot>
                            <tr>
                                <th>Total</th>
                                <th></th>
                                <th style="text-align: right;">
                                    <div *ngIf="pagamentosObrigacoes < 0">
                                        {{ (pagamentosObrigacoes * -1  | number: '1.2-2' : 'pt-br') }}

                                    </div>
                                    <div *ngIf="pagamentosObrigacoes > 0">
                                        {{ (pagamentosObrigacoes | number: '1.2-2' : 'pt-br') }}

                                    </div>
                                    <div *ngIf="pagamentosObrigacoes == 0">
                                        {{ (pagamentosObrigacoes | number: '1.2-2' : 'pt-br') }}
                                    </div>
                                    <div *ngIf="pagamentosObrigacoes === null">-</div>
                                </th>
                                <th style="text-align: right;">
                                    <span *ngIf="pagtoMaxObrigacoes < 0" [ngStyle]="{'color':'red'}">
                                        {{ (pagtoMaxObrigacoes * -1  | number: '1.2-2' : 'pt-br') }}

                                    </span>
                                    <span *ngIf="pagtoMaxObrigacoes > 0">
                                        {{ (pagtoMaxObrigacoes  | number: '1.2-2' : 'pt-br') }}

                                    </span>
                                    <span *ngIf="pagtoMaxObrigacoes == 0">
                                        {{ (pagtoMaxObrigacoes  | number: '1.2-2' : 'pt-br') }}
                                    </span>
                                    <span *ngIf="pagtoMaxObrigacoes === null">-</span>
                                </th>
                            </tr>
                            </tfoot>
                        </nz-table>
                    </div>
                </div>
            </nz-card>
        </div>
    </div>
</nz-content>

<nz-card nzTitle="Tributos" *ngIf="data.tipoTela !== 'grupo' else cardTributoGrupo"
         [ngClass]="[status ? 'card-fullscreen' : '', !cardLoadingTabs ? 'ant-card-table' : '']"
         [nzExtra]="tabsExtra" [nzLoading]="cardLoadingTabs">
    <nz-card-tab>
        <nz-tabset [nzSize]="'large'" (nzSelectChange)="changeTabs()" [(nzSelectedIndex)]="tabNumber">
            <nz-tab nzTitle="{{imposto}}" *ngFor="let imposto of arrayImpostos"></nz-tab>
        </nz-tabset>
    </nz-card-tab>
    <nz-table #tableInconsistencias
              [nzData]="arrayTableObrigacoesEvolucao"
              [nzFrontPagination]="false"
              [nzShowPagination]="false"
              nzSize="small"
              class="scrollbar font-doze"
              nzBordered
              style="table-layout:fixed;">
        <thead>
        <tr>
            <th #column1 nzWidth="170px" style="max-width: 170px">Obrigações Oficiais</th>
            <th #column2 nzWidth="34px"></th>
            <th #column3 style="text-align: center;"
                [ngStyle]="divergenciaMes === 1 && {'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}">
                JAN
            </th>
            <th #column4 style="text-align: center;"
                [ngStyle]="divergenciaMes === 2 && {'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}">
                FEV
            </th>
            <th #column5 style="text-align: center;"
                [ngStyle]="divergenciaMes === 3 && {'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}">
                MAR
            </th>
            <th #column6 style="text-align: center;"
                [ngStyle]="divergenciaMes === 4 && {'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}">
                ABR
            </th>
            <th #column7 style="text-align: center;"
                [ngStyle]="divergenciaMes === 5 && {'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}">
                MAI
            </th>
            <th #column8 style="text-align: center;"
                [ngStyle]="divergenciaMes === 6 && {'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}">
                JUN
            </th>
            <th #column9 style="text-align: center;"
                [ngStyle]="divergenciaMes === 7 && {'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}">
                JUL
            </th>
            <th #column10 style="text-align: center;"
                [ngStyle]="divergenciaMes === 8 && {'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}">
                AGO
            </th>
            <th #column11 style="text-align: center;"
                [ngStyle]="divergenciaMes === 9 && {'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}">
                SET
            </th>
            <th #column12 style="text-align: center;"
                [ngStyle]="divergenciaMes === 10 && {'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}">
                OUT
            </th>
            <th #column13 style="text-align: center;"
                [ngStyle]="divergenciaMes === 11 && {'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}">
                NOV
            </th>
            <th #column14 style="text-align: center;"
                [ngStyle]="divergenciaMes === 12 && {'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}">
                DEZ
            </th>
        </tr>
        </thead>
        <tbody>
        <ng-container class="ant-table-tbody" *ngFor="let data of tableInconsistencias.data">
            <tr *ngIf="data.obrigacoes">
                <td>
                    <strong>{{ data.label }}</strong>
                </td>
                <td [nzExpand]="expandSet.has(data.id)" (nzExpandChange)="onExpandChange(data.id, $event)"></td>
                <td colspan="12"></td>
            </tr>

            <ng-container *ngIf="data.obrigacoes" class="scrollbar">
                <tr [nzExpand]="expandSet.has(data.id)" *ngFor="let obrigacao of data.obrigacoes"
                    style="font-size: 11px;">
                    <td [ngStyle]="{'width': column1Width + 'px'}" [nzExpand]="expandSetSub.has(obrigacao.id)"
                        (nzExpandChange)="onExpandChangeSub(obrigacao.id, $event)"
                        style="padding: 8px 4px 8px 8px; word-break: break-word;">
                        &nbsp; {{ obrigacao.nome }}
                    </td>
                    <td [ngStyle]="{'width': column2Width + 'px'}"
                        style="position: relative; padding: 8px; text-align: right;"></td>
                    <td [ngStyle]="divergenciaMes === 1 ?
                          {'width': column3Width + 'px', 'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}
                        : {'width': column3Width + 'px'} "
                        style="position: relative; padding: 8px; text-align: right;">
                        <div *ngIf="obrigacao.id !== 'PERDCOMPWEB'">
                            <a *ngIf="obrigacao.jan.valor < 0"
                               (click)="showModal(obrigacao.nome, obrigacao.jan.url, obrigacao.jan.tributoEmBranco)"
                               [class.disabled]="!obrigacao.jan.url">
                                {{ (obrigacao.jan.valor * -1  | number: '1.2-2' : 'pt-br') }}
                                <small>(C)</small>
                            </a>
                            <a *ngIf="obrigacao.jan.valor > 0"
                               (click)="showModal(obrigacao.nome, obrigacao.jan.url, obrigacao.jan.tributoEmBranco)"
                               [class.disabled]="!obrigacao.jan.url">
                                {{ (obrigacao.jan.valor  | number: '1.2-2' : 'pt-br') }}
                                <small>(D)</small>
                            </a>
                            <a *ngIf="obrigacao.jan.valor == 0"
                               (click)="showModal(obrigacao.nome, obrigacao.jan.url, obrigacao.jan.tributoEmBranco)"
                               [class.disabled]="!obrigacao.jan.url">
                                {{ (obrigacao.jan.valor  | number: '1.2-2' : 'pt-br') }}
                            </a>
                        </div>
                        <span *ngIf="obrigacao.jan.valor == null && obrigacao.jan.ausente != 1">-</span>
                        <span *ngIf="obrigacao.jan.valor == null && obrigacao.jan.ausente == 1" nz-typography
                              style="color:#dc5706">
                                Arquivo Ausente
                        </span>
                        <a *ngIf="obrigacao.id == 'PERDCOMPWEB'"
                           (click)="showModalTabela(obrigacao, true, obrigacao.jan.url)">
                            {{ (obrigacao.jan.valor  | number: '1.2-2' : 'pt-br') }}
                        </a>
                    </td>
                    <td [ngStyle]="divergenciaMes === 2 ?
                          {'width': column4Width + 'px', 'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}
                        : {'width': column4Width + 'px'}"
                        style="position: relative; padding: 8px; text-align: right;">
                        <div *ngIf="obrigacao.id !== 'PERDCOMPWEB'">
                            <a *ngIf="obrigacao.fev.valor < 0"
                               (click)="showModal(obrigacao.nome, obrigacao.fev.url, obrigacao.fev.tributoEmBranco)"
                               [class.disabled]="!obrigacao.fev.url">
                                {{ (obrigacao.fev.valor * -1  | number: '1.2-2' : 'pt-br') }}
                                <small>(C)</small>
                            </a>
                            <a *ngIf="obrigacao.fev.valor > 0"
                               (click)="showModal(obrigacao.nome, obrigacao.fev.url, obrigacao.fev.tributoEmBranco)"
                               [class.disabled]="!obrigacao.fev.url">
                                {{ (obrigacao.fev.valor  | number: '1.2-2' : 'pt-br') }}
                                <small>(D)</small>
                            </a>
                            <a *ngIf="obrigacao.fev.valor == 0"
                               (click)="showModal(obrigacao.nome, obrigacao.fev.url, obrigacao.fev.tributoEmBranco)"
                               [class.disabled]="!obrigacao.fev.url">
                                {{ (obrigacao.fev.valor  | number: '1.2-2' : 'pt-br') }}
                            </a>
                        </div>
                        <span *ngIf="obrigacao.fev.valor == null && obrigacao.fev.ausente != 1">-</span>
                        <span *ngIf="obrigacao.fev.valor == null && obrigacao.fev.ausente == 1" nz-typography
                              style="color:#dc5706">
                                Arquivo Ausente
                        </span>
                        <a *ngIf="obrigacao.id == 'PERDCOMPWEB'"
                           (click)="showModalTabela(true, obrigacao, obrigacao.fev.url)">
                            {{ (obrigacao.fev.valor  | number: '1.2-2' : 'pt-br') }}
                        </a>
                    </td>
                    <td [ngStyle]="divergenciaMes === 3 ?
                          {'width': column5Width + 'px', 'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}
                        : {'width': column5Width + 'px'}"
                        style="position: relative; padding: 8px; text-align: right;">
                        <div *ngIf="obrigacao.id !== 'PERDCOMPWEB'">
                            <a *ngIf="obrigacao.mar.valor < 0"
                               (click)="showModal(obrigacao.nome, obrigacao.mar.url, obrigacao.mar.tributoEmBranco)"
                               [class.disabled]="!obrigacao.mar.url">
                                {{ (obrigacao.mar.valor * -1  | number: '1.2-2' : 'pt-br') }}
                                <small>(C)</small>
                            </a>
                            <a *ngIf="obrigacao.mar.valor > 0"
                               (click)="showModal(obrigacao.nome, obrigacao.mar.url, obrigacao.mar.tributoEmBranco)"
                               [class.disabled]="!obrigacao.mar.url">
                                {{ (obrigacao.mar.valor  | number: '1.2-2' : 'pt-br') }}
                                <small>(D)</small>
                            </a>
                            <a *ngIf="obrigacao.mar.valor == 0"
                               (click)="showModal(obrigacao.nome, obrigacao.mar.url, obrigacao.mar.tributoEmBranco)"
                               [class.disabled]="!obrigacao.mar.url">
                                {{ (obrigacao.mar.valor  | number: '1.2-2' : 'pt-br') }}
                            </a>
                        </div>
                        <span *ngIf="obrigacao.mar.valor == null && obrigacao.mar.ausente != 1">-</span>
                        <span *ngIf="obrigacao.mar.valor == null && obrigacao.mar.ausente == 1" nz-typography
                              style="color:#dc5706">
                                Arquivo Ausente
                        </span>
                        <a *ngIf="obrigacao.id == 'PERDCOMPWEB'"
                           (click)="showModalTabela(true, obrigacao, obrigacao.mar.url)">
                            {{ (obrigacao.mar.valor  | number: '1.2-2' : 'pt-br') }}
                        </a>
                    </td>
                    <td [ngStyle]="divergenciaMes === 4 ?
                          {'width': column6Width + 'px', 'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}
                        : {'width': column6Width + 'px'}"
                        style="position: relative; padding: 8px; text-align: right;">
                        <div *ngIf="obrigacao.id !== 'PERDCOMPWEB'">
                            <a *ngIf="obrigacao.abr.valor < 0"
                               (click)="showModal(obrigacao.nome, obrigacao.abr.url, obrigacao.abr.tributoEmBranco)"
                               [class.disabled]="!obrigacao.abr.url">
                                {{ (obrigacao.abr.valor * -1 | number: '1.2-2' : 'pt-br') }}
                                <small>(C)</small>
                            </a>
                            <a *ngIf="obrigacao.abr.valor > 0"
                               (click)="showModal(obrigacao.nome, obrigacao.abr.url, obrigacao.abr.tributoEmBranco)"
                               [class.disabled]="!obrigacao.abr.url">
                                {{ (obrigacao.abr.valor  | number: '1.2-2' : 'pt-br') }}
                                <small>(D)</small>
                            </a>
                            <a *ngIf="obrigacao.abr.valor == 0"
                               (click)="showModal(obrigacao.nome, obrigacao.abr.url, obrigacao.abr.tributoEmBranco)"
                               [class.disabled]="!obrigacao.abr.url">
                                {{ (obrigacao.abr.valor  | number: '1.2-2' : 'pt-br') }}
                            </a>
                        </div>
                        <span *ngIf="obrigacao.abr.valor == null && obrigacao.abr.ausente != 1">-</span>
                        <span *ngIf="obrigacao.abr.valor == null && obrigacao.abr.ausente == 1" nz-typography
                              style="color:#dc5706">
                                Arquivo Ausente
                        </span>
                        <a *ngIf="obrigacao.id == 'PERDCOMPWEB'"
                           (click)="showModalTabela(true, obrigacao, obrigacao.abr.url)">
                            {{ (obrigacao.abr.valor  | number: '1.2-2' : 'pt-br') }}
                        </a>
                    </td>
                    <td [ngStyle]="divergenciaMes === 5 ?
                          {'width': column5Width + 'px', 'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}
                        : {'width': column5Width + 'px'}"
                        style="position: relative; padding: 8px; text-align: right;">
                        <div *ngIf="obrigacao.id !== 'PERDCOMPWEB'">
                            <a *ngIf="obrigacao.mai.valor < 0"
                               (click)="showModal(obrigacao.nome, obrigacao.mai.url, obrigacao.mai.tributoEmBranco)"
                               [class.disabled]="!obrigacao.mai.url">
                                {{ (obrigacao.mai.valor * -1  | number: '1.2-2' : 'pt-br') }}
                                <small>(C)</small>
                            </a>
                            <a *ngIf="obrigacao.mai.valor > 0"
                               (click)="showModal(obrigacao.nome, obrigacao.mai.url, obrigacao.mai.tributoEmBranco)"
                               [class.disabled]="!obrigacao.mai.url">
                                {{ (obrigacao.mai.valor  | number: '1.2-2' : 'pt-br') }}
                                <small>(D)</small>
                            </a>
                            <a *ngIf="obrigacao.mai.valor == 0"
                               (click)="showModal(obrigacao.nome, obrigacao.mai.url, obrigacao.mai.tributoEmBranco)"
                               [class.disabled]="!obrigacao.mai.url">
                                {{ (obrigacao.mai.valor | number: '1.2-2' : 'pt-br') }}
                            </a>
                        </div>
                        <span *ngIf="obrigacao.mai.valor == null && obrigacao.mai.ausente != 1">-</span>
                        <span *ngIf="obrigacao.mai.valor == null && obrigacao.mai.ausente == 1" nz-typography
                              style="color:#dc5706">
                                Arquivo Ausente
                        </span>
                        <a *ngIf="obrigacao.id == 'PERDCOMPWEB'"
                           (click)="showModalTabela(true, obrigacao, obrigacao.mai.url)">
                            {{ (obrigacao.mai.valor  | number: '1.2-2' : 'pt-br') }}
                        </a>
                    </td>
                    <td [ngStyle]="divergenciaMes === 6 ?
                          {'width': column8Width + 'px', 'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}
                        : {'width': column8Width + 'px'}"
                        style="position: relative; padding: 8px; text-align: right;">
                        <div *ngIf="obrigacao.id !== 'PERDCOMPWEB'">
                            <a *ngIf="obrigacao.jun.valor < 0"
                               (click)="showModal(obrigacao.nome, obrigacao.jun.url, obrigacao.jun.tributoEmBranco)"
                               [class.disabled]="!obrigacao.jun.url">
                                {{ (obrigacao.jun.valor * -1  | number: '1.2-2' : 'pt-br') }}
                                <small>(C)</small>
                            </a>
                            <a *ngIf="obrigacao.jun.valor > 0"
                               (click)="showModal(obrigacao.nome, obrigacao.jun.url, obrigacao.jun.tributoEmBranco)"
                               [class.disabled]="!obrigacao.jun.url">
                                {{ (obrigacao.jun.valor  | number: '1.2-2' : 'pt-br') }}
                                <small>(D)</small>
                            </a>
                            <a *ngIf="obrigacao.jun.valor == 0"
                               (click)="showModal(obrigacao.nome, obrigacao.jun.url, obrigacao.jun.tributoEmBranco)"
                               [class.disabled]="!obrigacao.jun.url">
                                {{ (obrigacao.jun.valor  | number: '1.2-2' : 'pt-br') }}
                            </a>
                        </div>
                        <span *ngIf="obrigacao.jun.valor == null && obrigacao.jun.ausente != 1">-</span>
                        <span *ngIf="obrigacao.jun.valor == null && obrigacao.jun.ausente == 1" nz-typography
                              style="color:#dc5706">
                                Arquivo Ausente
                        </span>
                        <a *ngIf="obrigacao.id == 'PERDCOMPWEB'"
                           (click)="showModalTabela(true, obrigacao, obrigacao.jun.url)">
                            {{ (obrigacao.jun.valor  | number: '1.2-2' : 'pt-br') }}
                        </a>
                    </td>
                    <td [ngStyle]="divergenciaMes === 7 ?
                          {'width': column9Width + 'px', 'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}
                        : {'width': column9Width + 'px'}"
                        style="position: relative; padding: 8px; text-align: right;">
                        <div *ngIf="obrigacao.id !== 'PERDCOMPWEB'">
                            <a *ngIf="obrigacao.jul.valor < 0"
                               (click)="showModal(obrigacao.nome, obrigacao.jul.url, obrigacao.jul.tributoEmBranco)"
                               [class.disabled]="!obrigacao.jul.url">
                                {{ (obrigacao.jul.valor * -1  | number: '1.2-2' : 'pt-br') }}
                                <small>(C)</small>
                            </a>
                            <a *ngIf="obrigacao.jul.valor > 0"
                               (click)="showModal(obrigacao.nome, obrigacao.jul.url, obrigacao.jul.tributoEmBranco)"
                               [class.disabled]="!obrigacao.jul.url">
                                {{ (obrigacao.jul.valor  | number: '1.2-2' : 'pt-br') }}
                                <small>(D)</small>
                            </a>
                            <a *ngIf="obrigacao.jul.valor == 0"
                               (click)="showModal(obrigacao.nome, obrigacao.jul.url, obrigacao.jul.tributoEmBranco)"
                               [class.disabled]="!obrigacao.jul.url">
                                {{ (obrigacao.jul.valor  | number: '1.2-2' : 'pt-br') }}
                            </a>
                        </div>
                        <span *ngIf="obrigacao.jul.valor == null && obrigacao.jul.ausente != 1">-</span>
                        <span *ngIf="obrigacao.jul.valor == null && obrigacao.jul.ausente == 1" nz-typography
                              style="color:#dc5706">
                                Arquivo Ausente
                        </span>
                        <a *ngIf="obrigacao.id == 'PERDCOMPWEB'"
                           (click)="showModalTabela(true, obrigacao, obrigacao.jul.url)">
                            {{ (obrigacao.jul.valor  | number: '1.2-2' : 'pt-br') }}
                        </a>
                    </td>
                    <td [ngStyle]="divergenciaMes === 8 ?
                          {'width': column10Width + 'px', 'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}
                        : {'width': column10Width + 'px'}"
                        style="position: relative; padding: 8px; text-align: right;">
                        <div *ngIf="obrigacao.id !== 'PERDCOMPWEB'">
                            <a *ngIf="obrigacao.ago.valor < 0"
                               (click)="showModal(obrigacao.nome, obrigacao.ago.url, obrigacao.ago.tributoEmBranco)"
                               [class.disabled]="!obrigacao.ago.url">
                                {{ (obrigacao.ago.valor * -1  | number: '1.2-2' : 'pt-br') }}
                                <small>(C)</small>
                            </a>
                            <a *ngIf="obrigacao.ago.valor > 0"
                               (click)="showModal(obrigacao.nome, obrigacao.ago.url, obrigacao.ago.tributoEmBranco)"
                               [class.disabled]="!obrigacao.ago.url">
                                {{ (obrigacao.ago.valor  | number: '1.2-2' : 'pt-br') }}
                                <small>(D)</small>
                            </a>
                            <a *ngIf="obrigacao.ago.valor == 0"
                               (click)="showModal(obrigacao.nome, obrigacao.ago.url, obrigacao.ago.tributoEmBranco)"
                               [class.disabled]="!obrigacao.ago.url">
                                {{ (obrigacao.ago.valor  | number: '1.2-2' : 'pt-br') }}
                            </a>
                        </div>
                        <span *ngIf="obrigacao.ago.valor == null && obrigacao.ago.ausente != 1">-</span>
                        <span *ngIf="obrigacao.ago.valor == null && obrigacao.ago.ausente == 1" nz-typography
                              style="color:#dc5706">
                                Arquivo Ausente
                        </span>
                        <a *ngIf="obrigacao.id == 'PERDCOMPWEB'"
                           (click)="showModalTabela(true, obrigacao, obrigacao.ago.url)">
                            {{ (obrigacao.ago.valor  | number: '1.2-2' : 'pt-br') }}
                        </a>
                    </td>
                    <td [ngStyle]="divergenciaMes === 9 ?
                          {'width': column11Width + 'px', 'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}
                        : {'width': column11Width + 'px'}"
                        style="position: relative; padding: 8px; text-align: right;">
                        <div *ngIf="obrigacao.id !== 'PERDCOMPWEB'">
                            <a *ngIf="obrigacao.set.valor < 0"
                               (click)="showModal(obrigacao.nome, obrigacao.set.url, obrigacao.set.tributoEmBranco)"
                               [class.disabled]="!obrigacao.set.url">
                                {{ (obrigacao.set.valor * -1  | number: '1.2-2' : 'pt-br') }}
                                <small>(C)</small>
                            </a>
                            <a *ngIf="obrigacao.set.valor > 0"
                               (click)="showModal(obrigacao.nome, obrigacao.set.url, obrigacao.set.tributoEmBranco)"
                               [class.disabled]="!obrigacao.set.url">
                                {{ (obrigacao.set.valor  | number: '1.2-2' : 'pt-br') }}
                                <small>(D)</small>
                            </a>
                            <a *ngIf="obrigacao.set.valor == 0"
                               (click)="showModal(obrigacao.nome, obrigacao.set.url, obrigacao.set.tributoEmBranco)"
                               [class.disabled]="!obrigacao.set.url">
                                {{ (obrigacao.set.valor  | number: '1.2-2' : 'pt-br') }}
                            </a>
                        </div>
                        <span *ngIf="obrigacao.set.valor == null && obrigacao.set.ausente != 1">-</span>
                        <span *ngIf="obrigacao.set.valor == null && obrigacao.set.ausente == 1" nz-typography
                              style="color:#dc5706">
                                Arquivo Ausente
                        </span>
                        <a *ngIf="obrigacao.id == 'PERDCOMPWEB'"
                           (click)="showModalTabela(true, obrigacao, obrigacao.set.url)">
                            {{ (obrigacao.set.valor  | number: '1.2-2' : 'pt-br') }}
                        </a>
                    </td>
                    <td [ngStyle]="divergenciaMes === 10 ?
                          {'width': column12Width + 'px', 'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}
                        : {'width': column12Width + 'px'}"
                        style="position: relative; padding: 8px; text-align: right;">
                        <div *ngIf="obrigacao.id !== 'PERDCOMPWEB'">
                            <a *ngIf="obrigacao.out.valor < 0"
                               (click)="showModal(obrigacao.nome, obrigacao.out.url, obrigacao.out.tributoEmBranco)"
                               [class.disabled]="!obrigacao.out.url">
                                {{ (obrigacao.out.valor * -1  | number: '1.2-2' : 'pt-br') }}
                                <small>(C)</small>
                            </a>
                            <a *ngIf="obrigacao.out.valor > 0"
                               (click)="showModal(obrigacao.nome, obrigacao.out.url, obrigacao.out.tributoEmBranco)"
                               [class.disabled]="!obrigacao.out.url">
                                {{ (obrigacao.out.valor  | number: '1.2-2' : 'pt-br') }}
                                <small>(D)</small>
                            </a>
                            <a *ngIf="obrigacao.out.valor == 0"
                               (click)="showModal(obrigacao.nome, obrigacao.out.url, obrigacao.out.tributoEmBranco)"
                               [class.disabled]="!obrigacao.out.url">
                                {{ (obrigacao.out.valor  | number: '1.2-2' : 'pt-br') }}
                            </a>
                        </div>
                        <span *ngIf="obrigacao.out.valor == null && obrigacao.out.ausente != 1">-</span>
                        <span *ngIf="obrigacao.out.valor == null && obrigacao.out.ausente == 1" nz-typography
                              style="color:#dc5706">
                                Arquivo Ausente
                        </span>
                        <a *ngIf="obrigacao.id == 'PERDCOMPWEB'"
                           (click)="showModalTabela(true, obrigacao, obrigacao.out.url)">
                            {{ (obrigacao.out.valor  | number: '1.2-2' : 'pt-br') }}
                        </a>
                    </td>
                    <td [ngStyle]="divergenciaMes === 11 ?
                          {'width': column13Width + 'px', 'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}
                        : {'width': column13Width + 'px'}"
                        style="position: relative; padding: 8px; text-align: right;">
                        <div *ngIf="obrigacao.id !== 'PERDCOMPWEB'">
                            <a *ngIf="obrigacao.nov.valor < 0"
                               (click)="showModal(obrigacao.nome, obrigacao.nov.url, obrigacao.nov.tributoEmBranco)"
                               [class.disabled]="!obrigacao.nov.url">
                                {{ (obrigacao.nov.valor * -1  | number: '1.2-2' : 'pt-br') }}
                                <small>(C)</small>
                            </a>
                            <a *ngIf="obrigacao.nov.valor > 0"
                               (click)="showModal(obrigacao.nome, obrigacao.nov.url, obrigacao.nov.tributoEmBranco)"
                               [class.disabled]="!obrigacao.nov.url">
                                {{ (obrigacao.nov.valor  | number: '1.2-2' : 'pt-br') }}
                                <small>(D)</small>
                            </a>
                            <a *ngIf="obrigacao.nov.valor == 0"
                               (click)="showModal(obrigacao.nome, obrigacao.nov.url, obrigacao.nov.tributoEmBranco)"
                               [class.disabled]="!obrigacao.nov.url">
                                {{ (obrigacao.nov.valor  | number: '1.2-2' : 'pt-br') }}
                            </a>
                        </div>
                        <span *ngIf="obrigacao.nov.valor == null && obrigacao.nov.ausente != 1">-</span>
                        <span *ngIf="obrigacao.nov.valor == null && obrigacao.nov.ausente == 1" nz-typography
                              style="color:#dc5706">
                                Arquivo Ausente
                        </span>
                        <a *ngIf="obrigacao.id == 'PERDCOMPWEB'"
                           (click)="showModalTabela(true, obrigacao, obrigacao.nov.url)">
                            {{ (obrigacao.nov.valor  | number: '1.2-2' : 'pt-br') }}
                        </a>
                    </td>
                    <td [ngStyle]="divergenciaMes === 12 ?
                          {'width': column14Width + 'px', 'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}
                        : {'width': column14Width + 'px'}"
                        style="position: relative; padding: 8px; text-align: right;">
                        <div *ngIf="obrigacao.id !== 'PERDCOMPWEB'">
                            <a *ngIf="obrigacao.dez.valor < 0"
                               (click)="showModal(obrigacao.nome, obrigacao.dez.url, obrigacao.dez.tributoEmBranco)"
                               [class.disabled]="!obrigacao.dez.url">
                                {{ (obrigacao.dez.valor * -1  | number: '1.2-2' : 'pt-br') }}
                                <small>(C)</small>
                            </a>
                            <a *ngIf="obrigacao.dez.valor > 0"
                               (click)="showModal(obrigacao.nome, obrigacao.dez.url, obrigacao.dez.tributoEmBranco)"
                               [class.disabled]="!obrigacao.dez.url">
                                {{ (obrigacao.dez.valor  | number: '1.2-2' : 'pt-br') }}
                                <small>(D)</small>
                            </a>
                            <a *ngIf="obrigacao.dez.valor == 0"
                               (click)="showModal(obrigacao.nome, obrigacao.dez.url, obrigacao.dez.tributoEmBranco)"
                               [class.disabled]="!obrigacao.dez.url">
                                {{ (obrigacao.dez.valor  | number: '1.2-2' : 'pt-br') }}
                            </a>
                        </div>
                        <span *ngIf="obrigacao.dez.valor == null && obrigacao.dez.ausente != 1">-</span>
                        <span *ngIf="obrigacao.dez.valor == null && obrigacao.dez.ausente == 1" nz-typography
                              style="color:#dc5706">
                                Arquivo Ausente
                        </span>
                        <a *ngIf="obrigacao.id == 'PERDCOMPWEB'"
                           (click)="showModalTabela(true, obrigacao, obrigacao.dez.url)">
                            {{ (obrigacao.dez.valor  | number: '1.2-2' : 'pt-br') }}
                        </a>
                    </td>

                    <!-- Sub tabela------------------------------------------------------------>

                    <ng-container class="scrollbar">
                        <tr [nzExpand]="expandSetSub.has(obrigacao.id)" *ngFor="let d of obrigacao.dinamicos"
                            style="font-size: 11px;">
                            <td [ngStyle]="{'width': column1Width + 'px'}"
                                style="padding-left: 50px; font-size: 11px;">
                                {{ d.descricao }}
                            </td>
                            <td [ngStyle]="{'width': column2Width + 'px'}"
                                style="position: relative; padding: 8px; text-align: right;"></td>
                            <td [ngStyle]="divergenciaMes === 1 ?
                          {'width': column3Width + 'px', 'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}
                        : {'width': column3Width + 'px'} "

                                style="position: relative; padding: 8px; text-align: right">

                                <a *ngIf="d.jan?.detalhe else semUrlJ"
                                   (click)="showModal(d.descricao, d.jan.detalhe ,0)">
                                    {{ d.jan?.valor ? (d.jan.valor  | number: '1.2-2' : 'pt-br') : '-' }}
                                </a>
                                <ng-template #semUrlJ>
                            <span class="pr-5">
                               {{ d.jan?.valor ? (d.jan.valor  | number: '1.2-2' : 'pt-br') : '-' }}
                            </span>
                                </ng-template>
                            </td>
                            <td [ngStyle]="divergenciaMes === 2 ?
                          {'width': column4Width + 'px', 'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}
                        : {'width': column4Width + 'px'}"
                                style="position: relative; padding: 8px; text-align: right;">
                                <a *ngIf="d.fev?.detalhe else semUrlF"
                                   (click)="showModal(d.descricao, d.fev.detalhe ,0)">
                                    {{ d.fev?.valor ? (d.fev.valor  | number: '1.2-2' : 'pt-br') : '-' }}
                                </a>
                                <ng-template #semUrlF>
                            <span class="pr-5">
                               {{ d.fev?.valor ? (d.fev.valor  | number: '1.2-2' : 'pt-br') : '-' }}
                            </span>
                                </ng-template>
                            </td>
                            <td [ngStyle]="divergenciaMes === 3 ?
                          {'width': column5Width + 'px', 'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}
                        : {'width': column5Width + 'px'}"
                                style="position: relative; padding: 8px; text-align: right;">
                                <a *ngIf="d.mar?.detalhe else semUrlM"
                                   (click)="showModal(d.descricao, d.mar.detalhe ,0)">
                                    {{ d.mar?.valor ? (d.mar.valor  | number: '1.2-2' : 'pt-br') : '-' }}
                                </a>
                                <ng-template #semUrlM>
                            <span class="pr-5">
                               {{ d.mar?.valor ? (d.mar.valor  | number: '1.2-2' : 'pt-br') : '-' }}
                            </span>
                                </ng-template>
                            </td>
                            <td [ngStyle]="divergenciaMes === 4 ?
                          {'width': column6Width + 'px', 'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}
                        : {'width': column6Width + 'px'}"

                                style="position: relative; padding: 8px; text-align: right;">
                                <a *ngIf="d.abr?.detalhe else semUrlA"
                                   (click)="showModal(d.descricao, d.abr.detalhe ,0)">
                                    {{ d.abr?.valor ? (d.abr.valor  | number: '1.2-2' : 'pt-br') : '-' }}
                                </a>
                                <ng-template #semUrlA>
                            <span class="pr-5">
                               {{ d.abr?.valor ? (d.abr.valor  | number: '1.2-2' : 'pt-br') : '-' }}
                            </span>
                                </ng-template>
                            </td>
                            <td [ngStyle]="divergenciaMes === 5 ?
                          {'width': column5Width + 'px', 'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}
                        : {'width': column5Width + 'px'}"
                                style="position: relative; padding: 8px; text-align: right;">
                                <a *ngIf="d.mai?.detalhe else semUrlMai"

                                   (click)="showModal(d.descricao, d.mai.detalhe ,0)">
                                    {{ d.mai?.valor ? (d.mai.valor  | number: '1.2-2' : 'pt-br') : '-' }}
                                </a>
                                <ng-template #semUrlMai>
                            <span class="pr-5">
                               {{ d.mai?.valor ? (d.mai.valor  | number: '1.2-2' : 'pt-br') : '-' }}
                            </span>
                                </ng-template>
                            </td>
                            <td [ngStyle]="divergenciaMes === 6 ?
                          {'width': column8Width + 'px', 'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}
                        : {'width': column8Width + 'px'}"
                                style="position: relative; padding: 8px; text-align: right;">
                                <a *ngIf="d.jun?.detalhe else semUrlJun"

                                   (click)="showModal(d.descricao, d.jun.detalhe ,0)">
                                    {{ d.jun?.valor ? (d.jun.valor  | number: '1.2-2' : 'pt-br') : '-' }}
                                </a>
                                <ng-template #semUrlJun>
                            <span class="pr-5">
                               {{ d.jun?.valor ? (d.jun.valor  | number: '1.2-2' : 'pt-br') : '-' }}
                            </span>
                                </ng-template>
                            </td>
                            <td [ngStyle]="divergenciaMes === 7 ?
                          {'width': column9Width + 'px', 'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}
                        : {'width': column9Width + 'px'}"
                                style="position: relative; padding: 8px; text-align: right;">
                                <a *ngIf="d.jul?.detalhe else semUrlJul"

                                   (click)="showModal(d.descricao, d.jul.detalhe ,0)">
                                    {{ d.jul?.valor ? (d.jul.valor  | number: '1.2-2' : 'pt-br') : '-' }}
                                </a>
                                <ng-template #semUrlJul>
                            <span class="pr-5">
                               {{ d.jul?.valor ? (d.jul.valor  | number: '1.2-2' : 'pt-br') : '-' }}
                            </span>
                                </ng-template>
                            </td>
                            <td [ngStyle]="divergenciaMes === 8 ?
                          {'width': column10Width + 'px', 'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}
                        : {'width': column10Width + 'px'}"
                                style="position: relative; padding: 8px; text-align: right;">
                                <a *ngIf="d.ago?.detalhe else semUrlAg"

                                   (click)="showModal(d.descricao, d.ago.detalhe ,0)">
                                    {{ d.ago?.valor ? (d.ago.valor  | number: '1.2-2' : 'pt-br') : '-' }}
                                </a>
                                <ng-template #semUrlAg>
                            <span class="pr-5">
                               {{ d.ago?.valor ? (d.ago.valor  | number: '1.2-2' : 'pt-br') : '-' }}
                            </span>
                                </ng-template>
                            </td>
                            <td [ngStyle]="divergenciaMes === 9 ?
                          {'width': column11Width + 'px', 'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}
                        : {'width': column11Width + 'px'}"
                                style="position: relative; padding: 8px; text-align: right;">
                                <a *ngIf="d.set?.detalhe else semUrlS"
                                   (click)="showModal(d.descricao, d.set.detalhe ,0)">
                                    {{ d.set?.valor ? (d.set.valor  | number: '1.2-2' : 'pt-br') : '-' }}
                                </a>
                                <ng-template #semUrlS>
                            <span class="pr-5">
                               {{ d.set?.valor ? (d.set.valor  | number: '1.2-2' : 'pt-br') : '-' }}
                            </span>
                                </ng-template>
                            </td>
                            <td [ngStyle]="divergenciaMes === 10 ?
                          {'width': column12Width + 'px', 'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}
                        : {'width': column12Width + 'px'}"
                                style="position: relative; padding: 8px; text-align: right;">
                                <a *ngIf="d.out?.detalhe else semUrlO"
                                   (click)="showModal(d.descricao, d.out.detalhe ,0)">
                                    {{ d.out?.valor ? (d.out.valor  | number: '1.2-2' : 'pt-br') : '-' }}
                                </a>
                                <ng-template #semUrlO>
                            <span class="pr-5">
                               {{ d.out?.valor ? (d.out.valor  | number: '1.2-2' : 'pt-br') : '-' }}
                            </span>
                                </ng-template>
                            </td>
                            <td [ngStyle]="divergenciaMes === 11 ?
                          {'width': column13Width + 'px', 'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}
                        : {'width': column13Width + 'px'}"
                                style="position: relative; padding: 8px; text-align: right;">
                                <a *ngIf="d.nov?.detalhe else semUrlN"
                                   (click)="showModal(d.descricao, d.nov.detalhe ,0)">
                                    {{ d.nov?.valor ? (d.nov.valor  | number: '1.2-2' : 'pt-br') : '-' }}
                                </a>
                                <ng-template #semUrlN>
                            <span class="pr-5">
                               {{ d.nov?.valor ? (d.nov.valor  | number: '1.2-2' : 'pt-br') : '-' }}
                            </span>
                                </ng-template>
                            </td>
                            <td [ngStyle]="divergenciaMes === 12 ?
                          {'width': column14Width + 'px', 'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}
                        : {'width': column14Width + 'px'}"
                                style="position: relative; padding: 8px; text-align: right;">
                                <a *ngIf="d.dez?.detalhe else semUrlD"
                                   (click)="showModal(d.descricao, d.dez.detalhe ,0)">
                                    {{ d.dez?.valor ? (d.dez.valor  | number: '1.2-2' : 'pt-br') : '-' }}
                                </a>
                                <ng-template #semUrlD>
                            <span class="pr-5">
                               {{ d.dez?.valor ? (d.dez.valor  | number: '1.2-2' : 'pt-br') : '-' }}
                            </span>
                                </ng-template>
                            </td>
                        </tr>

                    </ng-container>
                    <!-- SubTabela a cima !!!--------------------------------------------------------->

                </tr>

            </ng-container>

        </ng-container>
        </tbody>
        <tfoot>
        <tr style="background-color: #fafafa;">
            <td colspan="14"></td>
        </tr>
        <tr style="font-size: 11px;">
            <th>
                Acessória Mínima
            </th>
            <td>
            </td>
            <td style="text-align: right;"
                [ngStyle]="divergenciaMes === 1 &&
                {'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}">
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.obrigacoesAcessorias.min.jan !== null">{{ objectTableObrigacoesEvolucaoTotal.obrigacoesAcessorias.min.jan | currency:'BRL' }}</span>
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.obrigacoesAcessorias.min.jan == null">-</span>
            </td>
            <td style="text-align: right;"
                [ngStyle]="divergenciaMes === 2 &&
                {'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}">
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.obrigacoesAcessorias.min.fev !== null">{{ objectTableObrigacoesEvolucaoTotal.obrigacoesAcessorias.min.fev | currency:'BRL' }}</span>
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.obrigacoesAcessorias.min.fev == null">-</span>
            </td>
            <td style="text-align: right;"
                [ngStyle]="divergenciaMes === 3 &&
                {'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}">
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.obrigacoesAcessorias.min.mar !== null">{{ objectTableObrigacoesEvolucaoTotal.obrigacoesAcessorias.min.mar | currency:'BRL' }}</span>
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.obrigacoesAcessorias.min.mar == null"></span>
            </td>
            <td style="text-align: right;"
                [ngStyle]="divergenciaMes === 4 &&
                {'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}">
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.obrigacoesAcessorias.min.abr !== null">{{ objectTableObrigacoesEvolucaoTotal.obrigacoesAcessorias.min.abr | currency:'BRL' }}</span>
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.obrigacoesAcessorias.min.abr == null">-</span>
            </td>
            <td style="text-align: right;"
                [ngStyle]="divergenciaMes === 5 &&
                {'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}">
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.obrigacoesAcessorias.min.mai !== null">{{ objectTableObrigacoesEvolucaoTotal.obrigacoesAcessorias.min.mai | currency:'BRL' }}</span>
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.obrigacoesAcessorias.min.mai == null">-</span>
            </td>
            <td style="text-align: right;"
                [ngStyle]="divergenciaMes === 6 &&
                {'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}">
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.obrigacoesAcessorias.min.jun !== null">{{ objectTableObrigacoesEvolucaoTotal.obrigacoesAcessorias.min.jun | currency:'BRL' }}</span>
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.obrigacoesAcessorias.min.jun == null">-</span>
            </td>
            <td style="text-align: right;"
                [ngStyle]="divergenciaMes === 7 &&
                {'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}">
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.obrigacoesAcessorias.min.jul !== null">{{ objectTableObrigacoesEvolucaoTotal.obrigacoesAcessorias.min.jul | currency:'BRL' }}</span>
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.obrigacoesAcessorias.min.jul == null">-</span>
            </td>
            <td style="text-align: right;"
                [ngStyle]="divergenciaMes === 8 &&
                {'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}">
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.obrigacoesAcessorias.min.ago !== null">{{ objectTableObrigacoesEvolucaoTotal.obrigacoesAcessorias.min.ago | currency:'BRL' }}</span>
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.obrigacoesAcessorias.min.ago == null">-</span></td>
            <td style="text-align: right;"
                [ngStyle]="divergenciaMes === 9 &&
                {'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}">
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.obrigacoesAcessorias.min.set !== null">{{ objectTableObrigacoesEvolucaoTotal.obrigacoesAcessorias.min.set | currency:'BRL' }}</span>
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.obrigacoesAcessorias.min.set == null">-</span>
            </td>
            <td style="text-align: right;"
                [ngStyle]="divergenciaMes === 10 &&
                {'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}">
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.obrigacoesAcessorias.min.out !== null">{{ objectTableObrigacoesEvolucaoTotal.obrigacoesAcessorias.min.out | currency:'BRL' }}</span>
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.obrigacoesAcessorias.min.out == null">-</span>
            </td>
            <td style="text-align: right;"
                [ngStyle]="divergenciaMes === 11 &&
                {'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}">
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.obrigacoesAcessorias.min.nov !== null">{{ objectTableObrigacoesEvolucaoTotal.obrigacoesAcessorias.min.nov | currency:'BRL' }}</span>
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.obrigacoesAcessorias.min.nov == null">-</span></td>
            <td style="text-align: right;"
                [ngStyle]="divergenciaMes === 12 &&
                {'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}">
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.obrigacoesAcessorias.min.dez !== null">{{ objectTableObrigacoesEvolucaoTotal.obrigacoesAcessorias.min.dez | currency:'BRL' }}</span>
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.obrigacoesAcessorias.min.dez == null">-</span>
            </td>
        </tr>
        <tr style="font-size: 11px;">
            <th>
                Acessória Máxima
            </th>
            <td>
            </td>
            <td style="text-align: right;"
                [ngStyle]="divergenciaMes === 1 &&
                {'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}">
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.obrigacoesAcessorias.max.jan !== null">{{ objectTableObrigacoesEvolucaoTotal.obrigacoesAcessorias.max.jan | currency:'BRL' }}</span>
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.obrigacoesAcessorias.max.jan == null">-</span>
            </td>
            <td style="text-align: right;"
                [ngStyle]="divergenciaMes === 2 &&
                {'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}">
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.obrigacoesAcessorias.max.fev !== null">{{ objectTableObrigacoesEvolucaoTotal.obrigacoesAcessorias.max.fev | currency:'BRL' }}</span>
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.obrigacoesAcessorias.max.fev == null">-</span>
            </td>
            <td style="text-align: right;"
                [ngStyle]="divergenciaMes === 3 &&
                {'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}">
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.obrigacoesAcessorias.max.mar !== null">{{ objectTableObrigacoesEvolucaoTotal.obrigacoesAcessorias.max.mar | currency:'BRL' }}</span>
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.obrigacoesAcessorias.max.mar == null"></span>
            </td>
            <td style="text-align: right;"
                [ngStyle]="divergenciaMes === 4 &&
                {'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}">
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.obrigacoesAcessorias.max.abr !== null">{{ objectTableObrigacoesEvolucaoTotal.obrigacoesAcessorias.max.abr | currency:'BRL' }}</span>
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.obrigacoesAcessorias.max.abr == null">-</span>
            </td>
            <td style="text-align: right;"
                [ngStyle]="divergenciaMes === 5 &&
                {'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}">
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.obrigacoesAcessorias.max.mai !== null">{{ objectTableObrigacoesEvolucaoTotal.obrigacoesAcessorias.max.mai | currency:'BRL' }}</span>
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.obrigacoesAcessorias.max.mai == null">-</span>
            </td>
            <td style="text-align: right;"
                [ngStyle]="divergenciaMes === 6 &&
                {'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}">
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.obrigacoesAcessorias.max.jun !== null">{{ objectTableObrigacoesEvolucaoTotal.obrigacoesAcessorias.max.jun | currency:'BRL' }}</span>
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.obrigacoesAcessorias.max.jun == null">-</span>
            </td>
            <td style="text-align: right;"
                [ngStyle]="divergenciaMes === 7 &&
                {'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}">
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.obrigacoesAcessorias.max.jul !== null">{{ objectTableObrigacoesEvolucaoTotal.obrigacoesAcessorias.max.jul | currency:'BRL' }}</span>
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.obrigacoesAcessorias.max.jul == null">-</span>
            </td>
            <td style="text-align: right;"
                [ngStyle]="divergenciaMes === 8 &&
                {'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}">
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.obrigacoesAcessorias.max.ago !== null">{{ objectTableObrigacoesEvolucaoTotal.obrigacoesAcessorias.max.ago | currency:'BRL' }}</span>
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.obrigacoesAcessorias.max.ago == null">-</span></td>
            <td style="text-align: right;"
                [ngStyle]="divergenciaMes === 9 &&
                {'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}">
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.obrigacoesAcessorias.max.set !== null">{{ objectTableObrigacoesEvolucaoTotal.obrigacoesAcessorias.max.set | currency:'BRL' }}</span>
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.obrigacoesAcessorias.max.set == null">-</span>
            </td>
            <td style="text-align: right;"
                [ngStyle]="divergenciaMes === 10 &&
                {'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}">
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.obrigacoesAcessorias.max.out !== null">{{ objectTableObrigacoesEvolucaoTotal.obrigacoesAcessorias.max.out | currency:'BRL' }}</span>
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.obrigacoesAcessorias.max.out == null">-</span>
            </td>
            <td style="text-align: right;"
                [ngStyle]="divergenciaMes === 11 &&
                {'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}">
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.obrigacoesAcessorias.max.nov !== null">{{ objectTableObrigacoesEvolucaoTotal.obrigacoesAcessorias.max.nov | currency:'BRL' }}</span>
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.obrigacoesAcessorias.max.nov == null">-</span></td>
            <td style="text-align: right;"
                [ngStyle]="divergenciaMes === 12 &&
                {'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}">
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.obrigacoesAcessorias.max.dez !== null">{{ objectTableObrigacoesEvolucaoTotal.obrigacoesAcessorias.max.dez | currency:'BRL' }}</span>
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.obrigacoesAcessorias.max.dez == null">-</span>
            </td>
        </tr>


        <tr style="font-size: 11px;">
            <th>
                Diferença Min x Max
            </th>
            <td>
            </td>
            <td style="text-align: right;"
                [ngStyle]="divergenciaMes === 1 &&
                {'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}">
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.minMax.jan !== null">{{ objectTableObrigacoesEvolucaoTotal.minMax.jan | currency:'BRL' }}</span>
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.minMax.jan == null">-</span>
            </td>
            <td style="text-align: right;"
                [ngStyle]="divergenciaMes === 2 &&
                {'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}">
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.minMax.fev !== null">{{ objectTableObrigacoesEvolucaoTotal.minMax.fev | currency:'BRL' }}</span>
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.minMax.fev == null">-</span>
            </td>
            <td style="text-align: right;"
                [ngStyle]="divergenciaMes === 3 &&
                {'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}">
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.minMax.mar !== null">{{ objectTableObrigacoesEvolucaoTotal.minMax.mar | currency:'BRL' }}</span>
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.minMax.mar == null"></span>
            </td>
            <td style="text-align: right;"
                [ngStyle]="divergenciaMes === 4 &&
                {'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}">
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.minMax.abr !== null">{{ objectTableObrigacoesEvolucaoTotal.minMax.abr | currency:'BRL' }}</span>
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.minMax.abr == null">-</span>
            </td>
            <td style="text-align: right;"
                [ngStyle]="divergenciaMes === 5 &&
                {'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}">
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.minMax.mai !== null">{{ objectTableObrigacoesEvolucaoTotal.minMax.mai | currency:'BRL' }}</span>
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.minMax.mai == null">-</span>
            </td>
            <td style="text-align: right;"
                [ngStyle]="divergenciaMes === 6 &&
                {'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}">
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.minMax.jun !== null">{{ objectTableObrigacoesEvolucaoTotal.minMax.jun | currency:'BRL' }}</span>
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.minMax.jun == null">-</span>
            </td>
            <td style="text-align: right;"
                [ngStyle]="divergenciaMes === 7 &&
                {'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}">
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.minMax.jul !== null">{{ objectTableObrigacoesEvolucaoTotal.minMax.jul | currency:'BRL' }}</span>
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.minMax.jul == null">-</span>
            </td>
            <td style="text-align: right;"
                [ngStyle]="divergenciaMes === 8 &&
                {'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}">
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.minMax.ago !== null">{{ objectTableObrigacoesEvolucaoTotal.minMax.ago | currency:'BRL' }}</span>
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.minMax.ago == null">-</span></td>
            <td style="text-align: right;"
                [ngStyle]="divergenciaMes === 9 &&
                {'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}">
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.minMax.set !== null">{{ objectTableObrigacoesEvolucaoTotal.minMax.set | currency:'BRL' }}</span>
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.minMax.set == null">-</span>
            </td>
            <td style="text-align: right;"
                [ngStyle]="divergenciaMes === 10 &&
                {'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}">
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.minMax.out !== null">{{ objectTableObrigacoesEvolucaoTotal.minMax.out | currency:'BRL' }}</span>
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.minMax.out == null">-</span>
            </td>
            <td style="text-align: right;"
                [ngStyle]="divergenciaMes === 11 &&
                {'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}">
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.minMax.nov !== null">{{ objectTableObrigacoesEvolucaoTotal.minMax.nov | currency:'BRL' }}</span>
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.minMax.nov == null">-</span></td>
            <td style="text-align: right;"
                [ngStyle]="divergenciaMes === 12 &&
                {'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}">
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.minMax.dez !== null">{{ objectTableObrigacoesEvolucaoTotal.minMax.dez | currency:'BRL' }}</span>
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.minMax.dez == null">-</span>
            </td>
        </tr>


        <tr style="font-size: 11px;">
            <th>Total das Principais</th>
            <td></td>
            <td style="text-align: right;"
                [ngStyle]="divergenciaMes === 1 &&
                {'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}">
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.obrigacoesPrincipais.jan !== null">{{ objectTableObrigacoesEvolucaoTotal.obrigacoesPrincipais.jan | currency:'BRL' }}</span>
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.obrigacoesPrincipais.jan == null">-</span>
            </td>
            <td style="text-align: right;"
                [ngStyle]="divergenciaMes === 2 &&
                {'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}">
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.obrigacoesPrincipais.fev !== null">{{ objectTableObrigacoesEvolucaoTotal.obrigacoesPrincipais.fev | currency:'BRL' }}</span>
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.obrigacoesPrincipais.fev == null">-</span>
            </td>
            <td style="text-align: right;"
                [ngStyle]="divergenciaMes === 3 &&
                {'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}">
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.obrigacoesPrincipais.mar !== null">{{ objectTableObrigacoesEvolucaoTotal.obrigacoesPrincipais.mar | currency:'BRL' }}</span>
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.obrigacoesPrincipais.mar == null"></span>
            </td>
            <td style="text-align: right;"
                [ngStyle]="divergenciaMes === 4 &&
                {'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}">
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.obrigacoesPrincipais.abr !== null">{{ objectTableObrigacoesEvolucaoTotal.obrigacoesPrincipais.abr | currency:'BRL' }}</span>
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.obrigacoesPrincipais.abr == null">-</span>
            </td>
            <td style="text-align: right;"
                [ngStyle]="divergenciaMes === 5 &&
                {'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}">
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.obrigacoesPrincipais.mai !== null">{{ objectTableObrigacoesEvolucaoTotal.obrigacoesPrincipais.mai | currency:'BRL' }}</span>
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.obrigacoesPrincipais.mai == null">-</span>
            </td>
            <td style="text-align: right;"
                [ngStyle]="divergenciaMes === 6 &&
                {'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}">
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.obrigacoesPrincipais.jun !== null">{{ objectTableObrigacoesEvolucaoTotal.obrigacoesPrincipais.jun | currency:'BRL' }}</span>
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.obrigacoesPrincipais.jun == null">-</span>
            </td>
            <td style="text-align: right;"
                [ngStyle]="divergenciaMes === 7 &&
                {'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}">
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.obrigacoesPrincipais.jul !== null">{{ objectTableObrigacoesEvolucaoTotal.obrigacoesPrincipais.jul | currency:'BRL' }}</span>
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.obrigacoesPrincipais.jul == null">-</span>
            </td>
            <td style="text-align: right;"
                [ngStyle]="divergenciaMes === 8 &&
                {'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}">
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.obrigacoesPrincipais.ago !== null">{{ objectTableObrigacoesEvolucaoTotal.obrigacoesPrincipais.ago | currency:'BRL' }}</span>
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.obrigacoesPrincipais.ago == null">-</span></td>
            <td style="text-align: right;"
                [ngStyle]="divergenciaMes === 9 &&
                {'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}">
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.obrigacoesPrincipais.set !== null">{{ objectTableObrigacoesEvolucaoTotal.obrigacoesPrincipais.set | currency:'BRL' }}</span>
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.obrigacoesPrincipais.set == null">-</span>
            </td>
            <td style="text-align: right;"
                [ngStyle]="divergenciaMes === 10 &&
                {'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}">
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.obrigacoesPrincipais.out !== null">{{ objectTableObrigacoesEvolucaoTotal.obrigacoesPrincipais.out | currency:'BRL' }}</span>
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.obrigacoesPrincipais.out == null">-</span>
            </td>
            <td style="text-align: right;"
                [ngStyle]="divergenciaMes === 11 &&
                {'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}">
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.obrigacoesPrincipais.nov !== null">{{ objectTableObrigacoesEvolucaoTotal.obrigacoesPrincipais.nov | currency:'BRL' }}</span>
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.obrigacoesPrincipais.nov == null">-</span></td>
            <td style="text-align: right;"
                [ngStyle]="divergenciaMes === 12 &&
                {'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}">
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.obrigacoesPrincipais.dez !== null">{{ objectTableObrigacoesEvolucaoTotal.obrigacoesPrincipais.dez | currency:'BRL' }}</span>
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.obrigacoesPrincipais.dez == null">-</span>
            </td>
        </tr>
        <tr style="font-size: 11px;">
            <th>Principais x Acessória</th>
            <td></td>
            <td style="text-align: right;"
                [ngStyle]="divergenciaMes === 1 &&
                {'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}">
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.pricipalAcessorias.jan !== null"
                      [ngStyle]="objectTableObrigacoesEvolucaoTotal.pricipalAcessorias.jan < 0  && {'color': 'red'}">
                    {{ objectTableObrigacoesEvolucaoTotal.pricipalAcessorias.jan | currency:'BRL' }}</span>
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.pricipalAcessorias.jan == null">-</span>
            </td>
            <td style="text-align: right;"
                [ngStyle]="divergenciaMes === 2 &&
                {'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}">
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.pricipalAcessorias.fev !== null"
                      [ngStyle]="objectTableObrigacoesEvolucaoTotal.pricipalAcessorias.fev < 0  && {'color': 'red'}">
                    {{ objectTableObrigacoesEvolucaoTotal.pricipalAcessorias.fev | currency:'BRL' }}</span>
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.pricipalAcessorias.fev == null">-</span>
            </td>
            <td style="text-align: right;"
                [ngStyle]="divergenciaMes === 3 &&
                {'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}">
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.pricipalAcessorias.mar !== null"
                      [ngStyle]="objectTableObrigacoesEvolucaoTotal.pricipalAcessorias.mar < 0  && {'color': 'red'}">
                    {{ objectTableObrigacoesEvolucaoTotal.pricipalAcessorias.mar | currency:'BRL' }}</span>
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.pricipalAcessorias.mar == null">-</span>
            </td>
            <td style="text-align: right;"
                [ngStyle]="divergenciaMes === 4 &&
                {'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}">
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.pricipalAcessorias.abr !== null"
                      [ngStyle]="objectTableObrigacoesEvolucaoTotal.pricipalAcessorias.abr < 0  && {'color': 'red'}">
                    {{ objectTableObrigacoesEvolucaoTotal.pricipalAcessorias.abr | currency:'BRL' }}</span>
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.pricipalAcessorias.abr == null">-</span>
            </td>
            <td style="text-align: right;"
                [ngStyle]="divergenciaMes === 5 &&
                {'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}">
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.pricipalAcessorias.mai !== null"
                      [ngStyle]="objectTableObrigacoesEvolucaoTotal.pricipalAcessorias.mai < 0  && {'color': 'red'}">
                    {{ objectTableObrigacoesEvolucaoTotal.pricipalAcessorias.mai | currency:'BRL' }}</span>
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.pricipalAcessorias.mai == null">-</span>
            </td>
            <td style="text-align: right;"
                [ngStyle]="divergenciaMes === 6 &&
                {'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}">
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.pricipalAcessorias.jun !== null"
                      [ngStyle]="objectTableObrigacoesEvolucaoTotal.pricipalAcessorias.jun < 0  && {'color': 'red'}">
                    {{ objectTableObrigacoesEvolucaoTotal.pricipalAcessorias.jun | currency:'BRL' }}</span>
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.pricipalAcessorias.jun == null">-</span>
            </td>
            <td style="text-align: right;"
                [ngStyle]="divergenciaMes === 7 &&
                {'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}">
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.pricipalAcessorias.jul !== null"
                      [ngStyle]="objectTableObrigacoesEvolucaoTotal.pricipalAcessorias.jul < 0  && {'color': 'red'}">
                    {{ objectTableObrigacoesEvolucaoTotal.pricipalAcessorias.jul | currency:'BRL' }}</span>
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.pricipalAcessorias.jul == null">-</span>
            </td>
            <td style="text-align: right;"
                [ngStyle]="divergenciaMes === 8 &&
                {'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}">
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.pricipalAcessorias.ago !== null"
                      [ngStyle]="objectTableObrigacoesEvolucaoTotal.pricipalAcessorias.ago < 0  && {'color': 'red'}">
                    {{ objectTableObrigacoesEvolucaoTotal.pricipalAcessorias.ago | currency:'BRL' }}</span>
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.pricipalAcessorias.ago == null">-</span>
            </td>
            <td style="text-align: right;"
                [ngStyle]="divergenciaMes === 9 &&
                {'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}">
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.pricipalAcessorias.set !== null"
                      [ngStyle]="objectTableObrigacoesEvolucaoTotal.pricipalAcessorias.set < 0  && {'color': 'red'}">
                    {{ objectTableObrigacoesEvolucaoTotal.pricipalAcessorias.set | currency:'BRL' }}</span>
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.pricipalAcessorias.set == null">-</span>
            </td>
            <td style="text-align: right;"
                [ngStyle]="divergenciaMes === 10 &&
                {'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}">
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.pricipalAcessorias.out !== null"
                      [ngStyle]="objectTableObrigacoesEvolucaoTotal.pricipalAcessorias.out < 0  && {'color': 'red'}">
                    {{ objectTableObrigacoesEvolucaoTotal.pricipalAcessorias.out | currency:'BRL' }}</span>
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.pricipalAcessorias.out == null">-</span>
            </td>
            <td style="text-align: right;"
                [ngStyle]="divergenciaMes === 11 &&
                {'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}">
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.pricipalAcessorias.nov !== null"
                      [ngStyle]="objectTableObrigacoesEvolucaoTotal.pricipalAcessorias.nov < 0  && {'color': 'red'}">
                    {{ objectTableObrigacoesEvolucaoTotal.pricipalAcessorias.nov | currency:'BRL' }}</span>
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.pricipalAcessorias.nov == null">-</span>
            </td>
            <td style="text-align: right;"
                [ngStyle]="divergenciaMes === 12 &&
                {'background-color' : bgTableDivergencia, 'color' : colorTableDivergencia}">
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.pricipalAcessorias.dez !== null"
                      [ngStyle]="objectTableObrigacoesEvolucaoTotal.pricipalAcessorias.dez < 0  && {'color': 'red'}">
                    {{ objectTableObrigacoesEvolucaoTotal.pricipalAcessorias.dez | currency:'BRL' }}</span>
                <span *ngIf="objectTableObrigacoesEvolucaoTotal.pricipalAcessorias.dez == null">-</span>
            </td>
        </tr>
        </tfoot>
    </nz-table>
</nz-card>

<ng-template #cardTributoGrupo>
    <app-card-tributo-por-grupo [data]="{anoAtivo: ano, grupoId: data.unidade}"></app-card-tributo-por-grupo>
</ng-template>

<nz-card nzTitle="Pagamentos no Ano" [nzLoading]="cardLoadingChart" [nzExtra]="extraPagamentos">
    <highcharts-chart
            [Highcharts]="Highcharts"
            [options]="chartOptions"
            [(update)]="updateFlagPagamentos"
            *ngIf="chartOptions && Highcharts"
            style="width: 100%; height: 350px; display: block;"
    ></highcharts-chart>
</nz-card>

<div *ngIf="!promoverCard">
    <ng-container *ngTemplateOutlet="cardAusensiasDivergencias"></ng-container>
</div>

<ng-template #tAusencias>
    <nz-badge [nzCount]="qtdA" style="border: none!important; position: absolute; right: -34px; top: 6px">
    </nz-badge>
    Ausências
</ng-template>

<ng-template #tDivergencias>
    <nz-badge [nzCount]="qtdD" style="border: none!important; position: absolute; right: -34px; top: 6px">
    </nz-badge>
    Divergências
</ng-template>

<ng-template #cardAusensiasDivergencias>
    <nz-card [ngClass]="[status ? 'card-fullscreen' : '', !loadingCardTabsTwo ? 'ant-card-table' : '']"
             [nzLoading]="loadingCardTabsTwo">
        <nz-card-tab>
            <nz-tabset nzSize="large" [(nzSelectedIndex)]="tabAtivaTwo" [nzTabBarExtraContent]="tabsExtraTwo"
                       (nzSelectChange)="changeTabsTwo($event, true)">
                <nz-tab [nzTitle]=tDivergencias></nz-tab>
                <nz-tab [nzTitle]="tAusencias"></nz-tab>
            </nz-tabset>
        </nz-card-tab>

        <nz-table #tableInconsistencias
                  [nzData]="arrayTableDivergencias"
                  *ngIf="tabAtivaTwo === 0" class="scrollbar"
                  [nzFrontPagination]="false"
                  nzSize="small"
                  nzBordered
                  style="table-layout:fixed;">
            <thead>
            <tr>
                <th #column16 nzAlign="center" style="max-width: 280px">Grupo Empresarial</th>
                <th #column17 nzAlign="center" nzWidth="34px"></th>
                <th #column18 style="width: 15%" nzAlign="center">Empresa</th>
                <th #column18 style="width: 10%" nzAlign="center">Competência</th>
                <th #column19 style="width: 10%" nzAlign="center">Obrigação</th>
                <th #column20 style="width: 15%" nzAlign="center">Campo</th>
                <th #column21 style="width: 25%" nzAlign="center">Ocorrência</th>
                <th #column30 style="text-align: center;  width: 90px">Observações</th>
            </tr>
            </thead>
            <tbody>
            <ng-container class="ant-table-tbody" *ngFor="let divergencia of arrayTableDivergencias">
                <tr>
                    <td style="max-width: 280px">
                        <h5 nz-typography>{{ divergencia.grupoEmpresarial_descricao }}</h5>
                    </td>
                    <td style="text-align: center" nzWidth="34px" nzAlign="center" style="color: #1e9ff2;"
                        [nzExpand]="expandSetDivergencias.has(divergencia.grupoEmpresarial_id)"
                        (nzExpandChange)="onExpandChangeDivergencias(divergencia.grupoEmpresarial_id, $event)"
                    ></td>
                    <td nzAlign="center"></td>
                    <td nzAlign="center"></td>
                    <td nzAlign="center"></td>
                    <td nzAlign="center"></td>
                    <td nzAlign="center"></td>
                </tr>
                <ng-container *ngIf="divergencia.registros.length > 0">
                    <tr [nzExpand]="expandSetDivergencias.has(divergencia.grupoEmpresarial_id)"
                        *ngFor="let data of divergencia.registros">
                        <td [ngStyle]="{
                            'max-width': '280px',
                            position: 'relative',
                            padding: '8px',
                            'overflow-wrap': 'break-word'
                            }">
                        </td>
                        <td [ngStyle]="{
                            'width': '34px',
                            position: 'relative',
                            padding: '8px 0',
                            'overflow-wrap': 'break-word',
                            'text-align': 'center'
                            }">
                        </td>
                        <td nzAlign="center" [ngStyle]="{
                            'width': '15%',
                            position: 'relative',
                            padding: '8px',
                            'overflow-wrap': 'break-word',
                            'text-align': 'left'
                            }">
                            {{ modoDemo(data.empresa, 'empresaCodigoNome') }}
                            <span *ngIf="data.ie"
                                  style="color: rgb(24, 144, 255); cursor: pointer" nz-icon
                                  nzType="info-circle" nzTheme="outline" nz-tooltip="IE: {{data.ie}}">
                        </span>
                        </td>
                        <td nzAlign="center" [ngStyle]="{
                            'width': '10%',
                            position: 'relative',
                            padding: '8px',
                            'overflow-wrap': 'break-word',
                            'text-align': 'left'
                            }">
                            {{ data.competencia }}

                        </td>
                        <td nzAlign="center" [ngStyle]="{
                            'width': '10%',
                            position: 'relative',
                            padding: '8px',
                            'overflow-wrap': 'break-word',
                            'text-align': 'center'
                            }">
                            {{ data.obrigacao }}
                        </td>
                        <td nzAlign="center" [ngStyle]="{
                            'width': '15%',
                            position: 'relative',
                            padding: '8px',
                            'overflow-wrap': 'break-word',
                            'text-align': 'center'
                            }">
                            {{ data.campo }}
                        </td>
                        <td nzAlign="center" [ngStyle]="{
                            'width': '25%',
                            position: 'relative',
                            padding: '8px',
                            'overflow-wrap': 'break-word',
                            'text-align': 'center'
                            }">
                            {{ data.ocorrencia }}
                        </td>
                        <td nzAlign="center" style=" width: 100px">
                                     <span style="margin: 0; cursor: pointer;"
                                           (click)="data.qtdObservacoes && modalOcorrencia(true, 'divergencia', data.id)"
                                           class="ant-badge ant-badge-not-a-wrapper ng-star-inserted">
                                        <div class="ant-scroll-number ng-animate-disabled ng-trigger ng-trigger-zoomBadgeMotion ant-badge-count ng-star-inserted">{{ data.qtdObservacoes }}</div>
                                    </span>
                        </td>
                    </tr>
                </ng-container>
                <ng-container *ngIf="divergencia.registros.length === 0 || divergencia.registros === undefined">
                    <tr [nzExpand]="expandSetDivergencias.has(divergencia.grupoEmpresarial_id)"
                        style="text-align: center;">
                        Sem Dados
                    </tr>
                </ng-container>
            </ng-container>
            </tbody>
        </nz-table>

        <nz-table #tableAusencias
                  [nzData]="arrayTableAusencias"
                  [nzFrontPagination]="false"
                  [nzShowPagination]="false"
                  *ngIf="tabAtivaTwo === 1"
                  nzSize="small"
                  nzBordered
                  style="table-layout:fixed;">
            <thead>
            <tr>
                <th #column22 style="text-align: center">Grupo Empresarial</th>
                <th #column23 style="text-align: center;" nzWidth="34px"></th>
                <th #column24 style="text-align: left;  width: 20%">Empresa</th>
                <th #column25 style="text-align: center; width: 15%">CNPJ</th>
                <th #column26 style="text-align: center; width: 10%">IE</th>
                <th #column27 style="text-align: center; width: 10%">Obrigação</th>
                <th #column28 style="text-align: center;  width: 15%">Vencimento</th>
                <th #column29 style="text-align: center; width: 90px">Observações</th>
            </tr>
            </thead>
            <tbody>
            <ng-container class="ant-table-tbody" *ngFor="let ausencia of arrayTableAusencias">
                <tr>
                    <td class="td-grupo">
                        <h5 nz-typography>{{ ausencia.grupoEmpresarial_descricao }}</h5>
                    </td>
                    <td nzWidth="34px" style="color: #1e9ff2;"
                        [nzExpand]="expandSetAusencias.has(ausencia.grupoEmpresarial_id)"
                        (nzExpandChange)="onExpandChangeAusencias(ausencia.grupoEmpresarial_id, $event)"
                    ></td>
                    <td nzAlign="left"></td>
                    <td nzAlign="center"></td>
                    <td nzAlign="center"></td>
                    <td nzAlign="center"></td>
                    <td nzAlign="center"></td>
                    <td nzAlign="center"></td>
                </tr>
                <ng-container *ngIf="ausencia.registros.length > 0">
                    <tr [nzExpand]="expandSetAusencias.has(ausencia.grupoEmpresarial_id)"
                        *ngFor="let data of ausencia.registros">
                        <td [ngStyle]="{
                            'width': column22Width + 'px',
                            'min-width': '250px',
                            position: 'relative',
                            padding: '8px',
                            'overflow-wrap': 'break-word'
                            }">
                        </td>
                        <td [ngStyle]="{
                            'width': '34px',
                            position: 'relative',
                            padding: '4px 0',
                            'overflow-wrap': 'break-word',
                            'text-align': 'center'
                            }">
                        </td>
                        <td [ngStyle]="{
                            'width': '20%',
                            position: 'relative',
                            padding: '0 4px',
                            'overflow-wrap': 'break-word',
                            'text-align': 'left'
                            }">
                            {{ modoDemo(data.empresa.trim(), 'empresaCodigoNome') }}
                        </td>
                        <td [ngStyle]="{
                            'width': '15%',
                            position: 'relative',
                            padding: '8px',
                            'overflow-wrap': 'break-word',
                            'text-align': 'center'
                            }">
                            {{ modoDemo(data.cnpj | cpfCnpj, 'cnpj').trim() }}
                        </td>
                        <td [ngStyle]="{
                            'width': '10%',
                            position: 'relative',
                            padding: '8px',
                            'overflow-wrap': 'break-word',
                            'text-align': 'center'
                            }">
                            {{ data.ie }}
                        </td>
                        <td [ngStyle]="{
                            'width': '15%',
                            position: 'relative',
                            padding: '8px',
                            'text-align': 'center',
                            'word-break': 'keep-all'
                            }">
                            {{ data.obrigacao }}
                        </td>
                        <td [ngStyle]="{
                            'width': '10%',
                            position: 'relative',
                            padding: '8px',
                            'overflow-wrap': 'break-word',
                            'text-align': 'center'
                            }">
                            {{ data.vencimento | date: 'dd/MM/YYYY' }}
                        </td>
                        <td nzAlign="center" style=" width: 90px">
                        <span *ngIf="idOcorrencia === data.id && loadingObservacao else btModal" nz-icon
                              nzType="loading" nzTheme="outline" style="color: #ff4d4f"></span>

                            <ng-template #btModal>

                             <span style="margin: 0; cursor: pointer;"
                                   (click)="data.qtdObservacoes && modalOcorrencia(true, 'ausencia', data.id)"
                                   class="ant-badge ant-badge-not-a-wrapper ng-star-inserted">
                            <div class="ant-scroll-number ng-animate-disabled ng-trigger ng-trigger-zoomBadgeMotion ant-badge-count ng-star-inserted">{{ data.qtdObservacoes }}</div>
                        </span>

                            </ng-template>
                        </td>
                    </tr>
                </ng-container>
                <ng-container *ngIf="ausencia.registros.length === 0 || ausencia.registros === undefined">
                    <tr [nzExpand]="expandSetAusencias.has(ausencia.grupoEmpresarial_id)" style="text-align: center;">
                        Sem Dados
                    </tr>
                </ng-container>
            </ng-container>
            </tbody>
        </nz-table>
    </nz-card>
</ng-template>

<div style="padding: 1px"></div>

<ng-template #extraPagamentos>
    <button style="margin-right: 10px;" nz-button nzType="primary" nz-dropdown
            [nzDropdownMenu]="menu">{{ selectedValue }}
        <i nz-icon nzType="down"></i>
    </button>
    <nz-dropdown-menu #menu="nzDropdownMenu">
        <ul nz-menu>
            <li nz-menu-item *ngFor="let ano of arrayAnos" (click)="changeAno(ano)">
                <a>{{ ano }}</a>
            </li>
        </ul>
    </nz-dropdown-menu>
    <button nz-button nzType="primary" nz-dropdown [nzDropdownMenu]="menuTributos">{{ arrayImpostos[tabNumber] }}
        <i nz-icon nzType="down"></i>
    </button>
    <nz-dropdown-menu #menuTributos="nzDropdownMenu">
        <ul nz-menu>
            <li nz-menu-item *ngFor="let imposto of arrayImpostos">
                <a (click)="changeImposto(imposto)">{{ imposto }}</a>
            </li>
        </ul>
    </nz-dropdown-menu>
</ng-template>

<ng-template #tabsExtra>

    <div class="d-flex align-items-center">
        <button style="margin-right: 10px"
                (click)="onExpandAll(!expandAll)" nz-button>
            <em *ngIf="expandAll" class="fas fa-angle-double-up"></em>
            <em *ngIf="!expandAll" class="fas fa-angle-double-down"></em>
        </button>
        <button *ngIf="currentUser ?.origem === 'console' && data.tipoTela !== 'grupo'"
                nz-tooltip="(user-console) Atualizar compliance"
                nz-button nzType="default" nzLoading="{{loadingAtualizando}}"
                (click)="atuizarTributosEmpresa()" style="margin-right: 15px">
            Atualizar
        </button>

        <button nz-button [nzType]="'primary'"
                style="margin-right: 15px;" (click)="exportar()">
            <span>Exportar</span>
        </button>
        <button nz-button [nzType]="'default'" (click)="clickEvent()">
            <i class="fas" [ngClass]="!status ? 'fa-expand' : 'fa-compress'"></i>
        </button>
    </div>
</ng-template>

<ng-template #tabsExtraTwo>
    <div class="d-flex align-items-center">
        <nz-badge [nzCount]="qtdFiltrosAtivos" [nzStyle]="{ backgroundColor: '#52c41a' }" style="margin-right: 15px;">
            <button disabled="{{loadingCardTabsTwo}}" style="margin-right: 10px"
                    (click)="exibirTudoTwo(!expandCardsTwo)" nz-button>
                <em *ngIf="expandCardsTwo" class="fas fa-angle-double-up"></em>
                <em *ngIf="!expandCardsTwo" class="fas fa-angle-double-down"></em>
            </button>
            <button nz-button [nzType]="'primary'" (click)="abrirModal(formFiltrar)"
                    style="margin-right: 0;">
                <i nz-icon nzType="search"></i>
                <span>Filtros</span>
            </button>
        </nz-badge>
        <!--<button nz-button [nzType]="'primary'"
                style="margin-right: 15px;" (click)="exportar()">
            <span>Exportar</span>
        </button>-->
        <button nz-button [nzType]="'default'" (click)="clickEvent()">
            <i class="fas" [ngClass]="!status ? 'fa-expand' : 'fa-compress'"></i>
        </button>
    </div>
</ng-template>

<nz-modal (nzOnCancel)="handleCancel()" [nzBodyStyle]="{'padding' : '0'}" [(nzVisible)]="isVisible" [nzWidth]="1200"
          nzTitle="{{empresaSelecionada}}">
    <ng-container *nzModalContent>
        <iframe *ngIf="!tributoEmBranco" [src]="iframeUrl" title=""
                style="width: 100%; height: 550px; border: none;"></iframe>
        <div *ngIf="tributoEmBranco">
            <h3 style="display: inline-block; margin: 30px; color: #6c757d">
                Não há dados para apresentar.
            </h3>
        </div>
    </ng-container>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="handleCancel()">Fechar</button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formFiltrar.modalVisible"
          [nzTitle]="'Filtros Avançados'" [nzClosable]="true"
          (nzOnCancel)="fechar(formFiltrar)" [nzWidth]="648">
    <nz-row *nzModalContent>
        <nz-col [nzSpan]="24">
            <form nz-form [nzNoColon]="true" [formGroup]="formFiltrar.formGroup">
                <nz-form-item>
                    <nz-form-label [nzSpan]="5">Obrigação</nz-form-label>
                    <nz-form-control [nzSpan]="14" nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrar.formGroup.value.obrigacao">
                        <nz-select nzShowSearch nzAllowClear
                                   [nzPlaceHolder]="'Selecione'"
                                   formControlName="obrigacao">
                            <nz-option *ngFor="let opt of arraySelectObrigacoes"
                                       [nzLabel]="opt.descricao"
                                       [nzValue]="opt.id"
                            >
                            </nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="5">Ocorrência</nz-form-label>
                    <nz-form-control [nzSpan]="14" nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrar.formGroup.value.ocorrencia">
                        <nz-select nzShowSearch nzAllowClear
                                   [nzPlaceHolder]="'Selecione'" formControlName="ocorrencia">
                            <nz-option *ngFor="let opt of arraySelectOcorrencias"
                                       [nzLabel]="opt.descricao"
                                       [nzValue]="opt.id"
                            >
                            </nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
            </form>
        </nz-col>
    </nz-row>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="fechar(formFiltrar)">Cancelar</button>
        <button nz-button nzType="primary" (click)="filtrar()">Filtrar</button>
    </div>
</nz-modal>

<nz-modal nzTitle="Observações"
          class="modal-table"
          [(nzVisible)]="displayObservacaoModal"
          (nzOnCancel)="modalOcorrencia(false)"
          [nzWidth]="848"
          [nzBodyStyle]="{'padding' : '0'}">
    <div *nzModalContent>
        <nz-table #observacaoTable
                  [nzData]="arrayTableObservacao"
                  [nzFrontPagination]="false"
                  [nzShowPagination]="false"
                  class="fonte-doze table-small"
                  nzLoading="{{loadingObservacao}}">
            <thead>
            <tr>
                <th *ngFor="let column of listOfColumn;"
                    [(nzSortOrder)]="column.sortOrder"
                    [nzSortFn]="column.sortFn"
                    [ngStyle]="{'text-align' : column.align}">
                    {{ column.title }}
                </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let observacao of observacaoTable.data">
                <td nzAlign="center">{{ observacao?.descricao }}</td>
                <td nzAlign="center">{{ observacao?.created_at | date : 'dd/MM/yyyy HH:mm' }}</td>
                <td nzRight nzAlign="center" style="white-space: nowrap">
                    <a nz-button nzType="link"
                       nz-tooltip="true"
                       nzTooltipTitle="Editar"
                       (click)="modalObservacao(true, true, observacao.descricao, observacao.id)">
                        <i class="fa-regular fa-pen-to-square"></i>
                    </a>
                    <nz-divider nzType="vertical"></nz-divider>
                    <a nz-button nzType="link"
                       nzDanger
                       nz-tooltip="true"
                       nzTooltipTitle="Remover"
                       [nzLoading]="loadingsObservacaoExcluir[observacao.id]"
                       (click)="showConfirmExcluirObservacao(this.tabAtiva === 1 ? 'divergencia' : 'ausencia', observacao.id)">
                        <i class="far fa-trash-alt"></i>
                    </a>
                </td>
            </tr>
            </tbody>
        </nz-table>
    </div>
    <div *nzModalFooter class="d-flex justify-content-between">
        <button nz-button nzType="link" (click)="modalObservacao(true)">
            <i class="fa-solid fa-circle-plus" style="margin-right: 5px;"></i> Adicionar Observação
        </button>
        <button nz-button nzType="link" (click)="modalOcorrencia(false)">Fechar</button>
    </div>
</nz-modal>

<nz-modal [nzTitle]="!flagEditarObservacao ? 'Adicionar Observação' : 'Editar Observação'"
          [(nzVisible)]="displayObservasaoModal"
          (nzOnCancel)="modalObservacao(false)"
          [nzWidth]="848">
    <div *nzModalContent>
        <textarea rows="4" nz-input [(ngModel)]="observacaoValue" placeholder="Digite..."></textarea>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalObservacao(false)">Fechar</button>
        <button [nzLoading]="loadingsObservacaoAdicionar" nz-button nzType="primary" *ngIf="!flagEditarObservacao"
                (click)="adicionarObservacao()">Adicionar
        </button>
        <button [nzLoading]="loadingsObservacaoEditar" nz-button nzType="primary" *ngIf="flagEditarObservacao"
                (click)="editarObservacao()">Gravar
        </button>
    </div>
</nz-modal>

<nz-modal (nzOnCancel)="showModalTabela(false)" [nzBodyStyle]="{'padding' : '0'}" [(nzVisible)]="isVisibleModalTable"
          [nzWidth]="1200"
          nzTitle="{{obrigacaoSelecionadaNome}}">
    <ng-container *nzModalContent>
        <nz-skeleton *ngIf="loadings.detalhe"></nz-skeleton>
        <div *ngIf="obrigacaoSelecionada.length && !loadings.detalhe"
             style="min-height: 500px; margin: 20px 0; padding-top: 35px">
            <table class="table-embedded">
                <thead>
                <tr>
                    <th nzAlign="center" nzColumnKey="numeroPerdcomp">Nº Processo</th>
                    <th nzAlign="center" nzColumnKey="arquivoFormatoDocumento">Tipo Documento</th>
                    <th nzAlign="center" nzColumnKey="creditoTipo">Tipo Crédito</th>
                    <th nzAlign="center" nzColumnKey="valor">Valor</th>
                    <th nzAlign="center" nzColumnKey="valor">Código</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let obg of obrigacaoSelecionada">
                    <td nzAlign="center">{{ obg.numeroPerdcomp }}</td>
                    <td nzAlign="center">{{ obg.documentoTipo }}</td>
                    <td nzAlign="center">{{ obg.creditoTipo }}</td>
                    <td nzAlign="center">{{ obg.valor ? (obg.valor | currency : 'BRL') : '-' }}</td>
                    <td nzAlign="center">{{ obg.codigoReceitaDestino || '-' }}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </ng-container>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="showModalTabela(false)">Fechar</button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formFiltrarCabecalho.modalVisible"
          [nzTitle]="'Filtros'" [nzClosable]="true"
          (nzOnCancel)="fechar(formFiltrarCabecalho)" [nzWidth]="648">
    <nz-row *nzModalContent>
        <nz-col [nzSpan]="24">
            <form nz-form [nzNoColon]="true" [formGroup]="formFiltrarCabecalho.formGroup">
                <nz-form-item>
                    <nz-form-label [nzSpan]="5">Ano</nz-form-label>
                    <nz-form-control [nzSpan]="14" nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrarCabecalho.formGroup.value.ano">
                        <nz-select nzShowSearch nzAllowClear
                                   [nzPlaceHolder]="'Selecione'"
                                   formControlName="ano">
                            <nz-option *ngFor="let a of arrayAnos"
                                       [nzLabel]="a"
                                       [nzValue]="a"
                            >
                            </nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item *ngIf="this.data.tipoTela == 'grupo' else empresasSelect">
                    <nz-form-label [nzSpan]="5">Grupo</nz-form-label>
                    <nz-form-control [nzSpan]="14" nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrarCabecalho.formGroup.value.unidade">
                        <nz-select nzShowSearch nzAllowClear
                                   [nzPlaceHolder]="'Selecione'" formControlName="unidade">
                            <nz-option *ngFor="let opt of optionsEmpresasOuGrupos"
                                       [nzLabel]="opt.descricao"
                                       [nzValue]="opt.id">
                            </nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>

                <ng-template #empresasSelect>
                    <nz-form-item>
                        <nz-form-label [nzSpan]="5">Empresa</nz-form-label>
                        <nz-form-control [nzSpan]="14" nzValidateStatus="success"
                                         [nzHasFeedback]="formFiltrarCabecalho.formGroup.value.unidade">
                            <nz-select nzShowSearch nzAllowClear
                                       [nzPlaceHolder]="'Selecione'" formControlName="unidade">
                                <nz-option *ngFor="let opt of optionsEmpresasOuGrupos"
                                           [nzLabel]="opt.nome + ' (' + (opt.cnpj | cpfCnpj) + ')'"
                                           [nzValue]="idFltroSemIe(opt.id)"
                                           nzCustomContent>
                                    {{ opt.nome }}
                                    <br><small *ngIf="opt.ie">({{ (opt.cnpj | cpfCnpj) + ') IE: ' + opt.ie }}</small>
                                    <small *ngIf="!opt.ie">({{ (opt.cnpj | cpfCnpj) + ')' }}</small>
                                </nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                </ng-template>
            </form>
        </nz-col>
    </nz-row>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="fechar(formFiltrarCabecalho)">Cancelar</button>
        <button [disabled]="!formFiltrarCabecalho.formGroup.valid" nz-button nzType="primary"
                (click)="filtrarCabecalho()">Filtrar
        </button>
    </div>
</nz-modal>

