<nz-page-header class="site-page-header" nzTitle="Check" nzSubtitle="EFD Contribuições Detalhes">
</nz-page-header>
<nz-content>
    <nz-row [nzGutter]="24">
        <nz-col [nzSpan]="13">
            <nz-content>
                <nz-row [nzGutter]="24">
                    <nz-col [nzSpan]="24">
                        <nz-card>
                            <div class="d-flex align-items-center" style="margin-bottom: 20px;">
                                <div style="height: 45px;border: 1px rgba(0,0,0,.45) solid;border-radius: 50%;min-width: 45px; width: 45px;"
                                     class="d-flex justify-content-center align-items-center">
                                    <svg width="40" height="40"
                                         [data-jdenticon-value]="dadosAnalise?.unidade"
                                         *ngIf="dadosAnalise.unidade"></svg>
                                </div>
                                <h5 nz-typography style="margin-bottom: 0; margin-left: 10px;">
                                    {{ modoDemo(dadosAnalise.unidade, 'empresaCodigoNome') }}
                                </h5>
                            </div>
                            <nz-descriptions nzTitle="" [nzColumn]="2">
                                <nz-descriptions-item nzTitle="CNPJ">
                                    {{ modoDemo(dadosAnalise.cnpj | cpfCnpj, 'cnpj') }}
                                </nz-descriptions-item>
                                <nz-descriptions-item nzTitle="Competência">
                                    {{ dadosAnalise.competencia | date: 'MM/yyyy' }}
                                </nz-descriptions-item>
                            </nz-descriptions>
                            <nz-descriptions nzTitle="" [nzColumn]="1">
                                <nz-descriptions-item nzTitle="Solicitação">
                                    {{ dadosAnalise.cadastroData | date: 'dd/MM/yyyy' }} por
                                    {{ dadosAnalise.cadastroUsuarioNome ? dadosAnalise.cadastroUsuarioNome : '-' }}
                                </nz-descriptions-item>
                            </nz-descriptions>
                        </nz-card>
                    </nz-col>
                </nz-row>
                <nz-row [nzGutter]="24">
                    <nz-col [nzSpan]="12">
                        <div>
                            <nz-card class="ant-card-table ant-card-hoverable" [nzCover]="coverCardTotais"
                                     (click)="filtrarClick('status_')">
                                <nz-content>
                                    <nz-row style="color: #fff; text-align: center;">
                                        <nz-col [nzSpan]="8"
                                                style="padding: 26px 0;">
                                            <div>
                                                <h3 nz-typography
                                                    style="margin-bottom: 0;">{{ contadores?.status_t?.status_1.qtd.toLocaleString('pt-BR') }}</h3>
                                            </div>
                                            <div>
                                                <h5 nz-typography class="text-muted"
                                                    style="font-weight: 400; margin-bottom: 0;">Notas</h5>
                                            </div>
                                        </nz-col>
                                        <nz-col [nzSpan]="8"
                                                style="padding: 26px 0;">
                                            <div>
                                                <h3 nz-typography
                                                    style="margin-bottom: 0;">{{ contadores?.status_t?.status_2.qtd.toLocaleString('pt-BR') }}</h3>
                                            </div>
                                            <div>
                                                <h5 nz-typography class="text-muted"
                                                    style="font-weight: 400; margin-bottom: 0;">Itens</h5>
                                            </div>
                                        </nz-col>
                                        <nz-col [nzSpan]="8"
                                                style="padding: 26px 0;">
                                            <div>
                                                <h3 nz-typography
                                                    style="margin-bottom: 0;">{{ contadores?.status_t?.status_3.qtd.toLocaleString('pt-BR') }}</h3>
                                            </div>
                                            <div>
                                                <h5 nz-typography class="text-muted"
                                                    style="font-weight: 400; margin-bottom: 0;">NCM</h5>
                                            </div>
                                        </nz-col>
                                    </nz-row>
                                </nz-content>
                            </nz-card>
                        </div>
                        <ng-template #coverCardTotais>
                            <div class="ant-card-head ng-star-inserted">
                                <div class="ant-card-head-wrapper">
                                    <div class="ant-card-head-title ng-star-inserted">
                                        <i class="fas fa-file" style="margin-right: 5px;"></i> Totais
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </nz-col>
                    <nz-col [nzSpan]="12">
                        <div class="purple-gradiend">
                            <nz-card class="purple-card ant-card-table ant-card-hoverable"
                                     [nzCover]="coverCardNcmNaoMapeado"
                                     (click)="filtrarClick('status_4')">
                                <nz-content>
                                    <nz-row style="color: #fff; text-align: center;">
                                        <nz-col [nzSpan]="24"
                                                style="padding: 26px 0;">
                                            <div>
                                                <h3 nz-typography
                                                    style="color: #fff; margin-bottom: 0;">{{ contadores?.status_nnm?.quantidade.toLocaleString('pt-BR') }}</h3>
                                            </div>
                                            <div>
                                                <h5 nz-typography style="color: #fff; margin-bottom: 0;">NCM's</h5>
                                            </div>
                                        </nz-col>
                                    </nz-row>
                                </nz-content>
                            </nz-card>
                        </div>
                        <ng-template #coverCardNcmNaoMapeado>
                            <div class="ant-card-head ng-star-inserted">
                                <div class="ant-card-head-wrapper">
                                    <div class="ant-card-head-title ng-star-inserted">
                                        <i class="fas fa-file" style="margin-right: 5px; color: #fff;"></i> NCM Não
                                        Mapeado
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </nz-col>
                </nz-row>
            </nz-content>
        </nz-col>
        <nz-col [nzSpan]="11">
            <nz-content>
                <nz-row [nzGutter]="24">
                    <nz-col [nzSpan]="12">
                        <div class="red-gradiend">
                            <nz-card class="bg-red-exagon ant-card-table ant-card-hoverable"
                                     [nzCover]="coverCardEntradasComDivergencias"
                                     (click)="filtrarClick('status_5')">
                                <nz-content style="text-align: center;">
                                    <nz-row>
                                        <nz-col [nzSpan]="12" style="padding: 36px 0;">
                                            <div>
                                                <h3 nz-typography
                                                    style="color: #fff; margin-bottom: 0;">{{ contadores?.status_cd?.status_1.qtd.toLocaleString('pt-BR') }}</h3>
                                            </div>
                                            <div>
                                                <h5 nz-typography
                                                    style="color: #fff; margin-bottom: 0; font-weight: 400;">Notas</h5>
                                            </div>
                                        </nz-col>
                                        <nz-col [nzSpan]="12" style="padding: 36px 0;">
                                            <div>
                                                <h3 nz-typography
                                                    style="margin-bottom: 0; color: #fff;">{{ contadores?.status_cd?.status_2.qtd.toLocaleString('pt-BR') }}</h3>
                                            </div>
                                            <div>
                                                <h5 nz-typography style="font-weight: 400; color: #fff;">Quant.
                                                    Itens</h5>
                                            </div>
                                        </nz-col>
                                    </nz-row>
                                </nz-content>
                            </nz-card>
                        </div>
                        <ng-template #coverCardEntradasComDivergencias>
                            <div class="ant-card-head ng-star-inserted">
                                <div class="ant-card-head-wrapper">
                                    <div class="ant-card-head-title ng-star-inserted d-flex justify-content-between align-items-center">
                                        <span><i class="fas fa-not-equal" style="margin-right: 5px;"></i> Entradas Com Divergência</span>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                        <div class="red-gradiend">
                            <nz-card class="bg-red-exagon ant-card-table ant-card-hoverable"
                                     [nzCover]="coverCardSaidasComDivergencias"
                                     (click)="filtrarClick('status_7')">
                                <nz-content style="text-align: center;">
                                    <nz-row>
                                        <nz-col [nzSpan]="12" style="padding: 21px 0;">
                                            <div>
                                                <h3 nz-typography
                                                    style="margin-bottom: 0; color: #fff;">{{ contadores?.status_cd?.status_3.qtd.toLocaleString('pt-BR') }}</h3>
                                            </div>
                                            <div>
                                                <h5 nz-typography style="font-weight: 400;color: #fff;">Notas</h5>
                                            </div>
                                        </nz-col>
                                        <nz-col [nzSpan]="12" style="padding: 21px 0;">
                                            <div>
                                                <h3 nz-typography
                                                    style="margin-bottom: 0; color: #fff;">{{ contadores?.status_cd?.status_4.qtd.toLocaleString('pt-BR') }}</h3>
                                            </div>
                                            <div>
                                                <h5 nz-typography style="font-weight: 400; color: #fff;">Quant.
                                                    Itens</h5>
                                            </div>
                                        </nz-col>
                                    </nz-row>
                                </nz-content>
                            </nz-card>
                        </div>
                        <ng-template #coverCardSaidasComDivergencias>
                            <div class="ant-card-head ng-star-inserted">
                                <div class="ant-card-head-wrapper">
                                    <div class="ant-card-head-title ng-star-inserted d-flex justify-content-between align-items-center">
                                        <span><i class="fas fa-not-equal" style="margin-right: 5px;"></i> Saídas Com Divergência</span>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </nz-col>
                    <nz-col [nzSpan]="12">
                        <div class="checkEfdContribuicoesTributacaoDetalhes extended">
                            <nz-card class="bg-white-exagon ant-card-table" [nzCover]="coverCardSemDivergencias">
                                <nz-content style="text-align: center;">
                                    <nz-row>
                                        <nz-col [nzSpan]="24" (click)="filtrarClick('status_6')"
                                                class="ant-card-hoverable" style="padding-top: 7px;">
                                            <h5 nz-typography style="font-weight: 400;">Notas de Entrada</h5>
                                            <h3 nz-typography
                                                style="margin-top: 0;">{{ contadores?.status_sd?.status_1.qtd.toLocaleString('pt-BR') }}</h3>
                                        </nz-col>
                                    </nz-row>
                                    <nz-row>
                                        <nz-col [nzSpan]="24" (click)="filtrarClick('status_6')"
                                                class="ant-card-hoverable" style="padding-top: 7px;">
                                            <h5 nz-typography style="font-weight: 400;">Quant. Itens</h5>
                                            <h3 nz-typography
                                                style="margin-top: 0;">{{ contadores?.status_sd?.status_2.qtd.toLocaleString('pt-BR') }}</h3>
                                        </nz-col>
                                    </nz-row>
                                    <nz-row>
                                        <nz-col [nzSpan]="24" (click)="filtrarClick('status_8')"
                                                class="ant-card-hoverable" style="padding-top: 7px;">
                                            <h5 nz-typography style="font-weight: 400;">Notas de Saída</h5>
                                            <h3 nz-typography
                                                style="margin-top: 0;">{{ contadores?.status_sd?.status_3.qtd.toLocaleString('pt-BR') }}</h3>
                                        </nz-col>
                                    </nz-row>
                                    <nz-row>
                                        <nz-col [nzSpan]="24" (click)="filtrarClick('status_8')"
                                                class="ant-card-hoverable" style="padding-top: 7px;">
                                            <h5 nz-typography style="font-weight: 400;">Quant. Itens</h5>
                                            <h3 nz-typography
                                                style="margin-top: 0;">{{ contadores?.status_sd?.status_4.qtd.toLocaleString('pt-BR') }}</h3>
                                        </nz-col>
                                    </nz-row>
                                </nz-content>
                            </nz-card>
                        </div>
                        <ng-template #coverCardSemDivergencias>
                            <div class="ant-card-head ng-star-inserted">
                                <div class="ant-card-head-wrapper">
                                    <div class="ant-card-head-title ng-star-inserted d-flex justify-content-between align-items-center">
                                        <span><i class="fas fa-not-equal" style="margin-right: 5px;"></i> Sem Divergência</span>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </nz-col>
                </nz-row>
            </nz-content>
        </nz-col>
    </nz-row>
</nz-content>
<nz-content>
    <nz-card [ngClass]="[!loading ? 'ant-card-table' : '']"
             class="ant-card-scroll" [nzExtra]="navBar"
             nzTitle="Notas" *ngIf="cardNotaVisible"
             [nzLoading]="loading">
        <nz-alert nzType="info" nzShowIcon [nzMessage]="checkedMessage" style="margin: 15px !important;"
                  *ngIf="checkedItems.size > 0">
            <ng-template #checkedMessage>
                <div nz-row nzAlign="middle" nzGutter="24">
                    <div nz-col nzSpan="24">
                        <strong class="text-primary"></strong>
                        {{ checkedItems.size }} Registros Selecionados
                    </div>
                </div>
            </ng-template>
        </nz-alert>
        <ng-template #semDados>
            <div style="font-size: 1.5em; color: #c2c2c2; padding: 30px 0">
                {{ first ? 'Selecione os filtros para listar os dados.' : 'Não há dados.' }}
            </div>
        </ng-template>
        <nz-table #basicTable
                  nzSize="small"
                  [nzScroll]="{ x: 'auto' }"
                  [nzData]="items"
                  [nzFrontPagination]="false"
                  [nzTotal]="pagination?.total"
                  [nzPageSize]="pagination?.per_page"
                  [nzPageIndex]="pagination?.current_page"
                  (nzQueryParams)="listByTable($event)"
                  [nzNoResult]="semDados"
                  [nzFooter]="footer"
        >
            <thead>
            <tr>
                <!--<th (nzCheckedChange)="onAllChecked($event)" [nzChecked]="checked" [nzIndeterminate]="indeterminate"
                    id="check-all">
                </th>-->
                <th nzWidth="60px"></th>
                <th nzColumnKey="data" [nzSortFn]="true">Emissão</th>
                <th nzColumnKey="chave" [nzSortFn]="true" class="text-center">Chave de Acesso</th>
                <th nzColumnKey="numero" [nzSortFn]="true" class="text-center">Número</th>
                <th nzColumnKey="serie" [nzSortFn]="true" class="text-center">Série</th>
                <th nzColumnKey="nome" [nzSortFn]="true" class="text-center">Destinatário/Emitente</th>
                <th nzColumnKey="documento" [nzSortFn]="true" class="text-center">Documento</th>
                <th nzColumnKey="valor" [nzSortFn]="true" class="text-center">Valor</th>
                <th nzColumnKey="operacao" [nzSortFn]="true" class="text-center">Operação</th>
                <th nzColumnKey="situacao" [nzSortFn]="true" class="text-center">Situação</th>
                <th nzColumnKey="status" [nzSortFn]="true" class="text-center">Status</th>
            </tr>
            </thead>
            <tbody>
            <ng-container *ngFor="let item of basicTable.data">
                <tr style="white-space: nowrap;">
                    <td [nzExpand]="expandSet.has(item.id)"
                        (nzExpandChange)="onExpandChange(item.checkEfdContribTributo_id, $event, item.id)"></td>
                    <td nzAlign="center">
                        {{ item.data | date: 'dd/MM/yyyy' }}
                    </td>
                    <td nzAlign="center">
                        {{ item.chave }}
                    </td>
                    <td nzAlign="center">
                        {{ modoDemo(item.numero, 'total') }}
                    </td>
                    <td nzAlign="center">
                        {{ item.serie }}
                    </td>
                    <td nzAlign="center">
                        {{ modoDemo(item.nome, 'empresaCodigoNome') }}
                    </td>
                    <td nzAlign="center">
                        {{ modoDemo(item.documento | cpfCnpj, 'cnpj') }}
                    </td>
                    <td nzAlign="center">
                        {{ item.valor | currency : 'BRL' }}
                    </td>
                    <td nzAlign="center">
                        {{ item.operacaoDescricao ? item.operacaoDescricao : '-' }}
                    </td>
                    <td nzAlign="center">
                        {{ item.situacaoDescricao ? item.situacaoDescricao : '-' }}
                    </td>
                    <td nzAlign="center">
                        <i class="fas fa-check" *ngIf="item.status === 1" style="color: #28d094;" nz-tooltip
                           nzTooltipTitle="{{item.statusDescricao}}"></i>
                        <i class="fas fa-exclamation" *ngIf="item.status === 2" style="color: #ff4961;" nz-tooltip
                           nzTooltipTitle="Existem divegências. Maximize para ver os campos."></i>
                        <i class="far fa-circle" *ngIf="item.status === 3" style="color: #ff9149;" nz-tooltip
                           nzTooltipTitle="{{item.statusDescricao}}"></i>
                        <i class="far fa-map" *ngIf="item.status === 4" style="color: #1e9ff2;" nz-tooltip
                           nzTooltipTitle="{{item.statusDescricao}}"></i>
                    </td>
                </tr>
                <tr [nzExpand]="expandSet.has(item.id)">
                    <nz-table #expandTable
                              nzSize="small"
                              [nzData]="arrayTableItensNota[item.id]"
                              [nzFrontPagination]="false"
                              [nzShowPagination]="false"
                    >
                        <thead>
                        <tr>
                            <th nzColumnKey="codigo" [nzSortFn]="true">Código</th>
                            <th nzColumnKey="descricao" [nzSortFn]="true">Descrição</th>
                            <th nzColumnKey="ncm" [nzSortFn]="true">NCM</th>
                            <th nzColumnKey="cfop" [nzSortFn]="true">CFOP</th>
                            <th nzColumnKey="pisCst" [nzSortFn]="true">CST PIS</th>
                            <th nzColumnKey="pisAliquota" [nzSortFn]="true">Aliq. PIS</th>
                            <th nzColumnKey="cofinsCst" [nzSortFn]="true">CST COFINS</th>
                            <th nzColumnKey="cofinsAliq" [nzSortFn]="true">Aliq. COFINS</th>
                            <th nzColumnKey="total" [nzSortFn]="true">Total</th>
                            <th>Ações</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let expandItem of expandTable.data">
                            <td [ngStyle]="expandItem.camposDivergentes.includes('codigo') ? {'color' : '#ff4961', 'font-weight': 'bold'} : {}">{{ expandItem.codigo }}</td>
                            <td [ngStyle]="expandItem.camposDivergentes.includes('descricao') ? {'color' : '#ff4961', 'font-weight': 'bold'} : {}">{{ expandItem.descricao }}</td>
                            <td [ngStyle]="expandItem.camposDivergentes.includes('ncm') ? {'color' : '#ff4961', 'font-weight': 'bold'} : {}">{{ expandItem.ncm }}</td>
                            <td [ngStyle]="expandItem.camposDivergentes.includes('cfop') ? {'color' : '#ff4961', 'font-weight': 'bold'} : {}">{{ expandItem.cfop }}</td>
                            <td [ngStyle]="expandItem.camposDivergentes.includes('pisCst') ? {'color' : '#ff4961', 'font-weight': 'bold'} : {}">
                                {{ expandItem.pisCst }}
                            </td>
                            <td [ngStyle]="expandItem.camposDivergentes.includes('pisAliquota') ? {'color' : '#ff4961', 'font-weight': 'bold'} : {}">{{ expandItem.pisAliquota }}</td>
                            <td [ngStyle]="expandItem.camposDivergentes.includes('cofinsCst') ? {'color' : '#ff4961', 'font-weight': 'bold'} : {}">{{ expandItem.cofinsCst }}</td>
                            <td [ngStyle]="expandItem.camposDivergentes.includes('cofinsAliquota') ? {'color' : '#ff4961', 'font-weight': 'bold'} : {}">{{ expandItem.cofinsAliquota }}</td>
                            <td [ngStyle]="expandItem.camposDivergentes.includes('valorProduto') ? {'color' : '#ff4961', 'font-weight': 'bold'} : {}">{{ expandItem.valorProduto | currency : 'BRL' }}</td>
                            <td></td>
                        </tr>
                        </tbody>

                    </nz-table>
                </tr>
            </ng-container>
            </tbody>

            <ng-template #footer>
                <span *ngIf="items && items.length > 0">{{ pagination?.from }}-{{ pagination?.to }}
                    de {{ pagination?.total }} registros</span>
            </ng-template>
        </nz-table>
    </nz-card>

    <nz-card class="ant-card-table ant-card-scroll" [nzExtra]="botoesQuebra" nzTitle="Quebra de sequência"
             *ngIf="cardQuebraVisible">
        <nz-table #basicTable2
                  nzSize="small"
                  [nzData]="quebras"
                  [nzFrontPagination]="false"
                  [nzShowPagination]="false">
            <thead>
            <tr>
                <th nzShowSort="false" class="text-center">Série</th>
                <th nzShowSort="false" class="text-center">Modelo</th>
                <th nzShowSort="false" class="text-center">Análise</th>
                <th nzShowSort="false" class="text-center">Total de Ausência</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of basicTable2.data" style="white-space: nowrap;">
                <td class="text-center">{{ item.serie }}</td>
                <td class="text-center">{{ item.modelo }}</td>
                <td class="text-center">De {{ item.min | number:'1.0-0' : 'pt-br' }}
                    até {{ item.max | number:'1.0-0' : 'pt-br' }}
                </td>
                <td class="text-center">{{ (item.max - item.min)  | number:'1.0-0' : 'pt-br' }}</td>
            </tr>
            </tbody>
        </nz-table>
    </nz-card>
</nz-content>
<ng-template #navBar>
    <div class="d-flex align-items-center">
        <button nz-button nzType="primary" nzGhost
                (click)="modalFiltrar(true);"
                style="margin-right: 10px;">
            <i nz-icon nzType="search"></i>
            Filtros
        </button>
        <button [nzDropdownMenu]="menuAcoes" class="centralized-icon" nz-button nz-dropdown
                nzType="default" style="margin-right: 10px;">
            Opções
            <em nz-icon nzType="down"></em>
        </button>
        <nz-dropdown-menu #menuAcoes="nzDropdownMenu">
            <ul nz-menu>
                <li nz-menu-item (click)="modalExportar(true);">Exportar Planilha</li>
            </ul>
        </nz-dropdown-menu>

        <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton">
            <input (keydown.enter)="search()" type="text" nz-input placeholder="Busca" [(ngModel)]="pesquisar"/>
        </nz-input-group>
        <ng-template #suffixIconButton>
            <button nz-button nzType="primary" nzSearch style="margin-right: 10px;" (click)="search();"><i nz-icon
                                                                                                           nzType="search"></i>
            </button>
        </ng-template>

        <button nz-button nzType="default" (click)="limparFiltros();">Limpar</button>

    </div>
</ng-template>

<ng-template #botoesQuebra>
    <div class="d-flex align-items-center">
        <button nz-button nzType="default" (click)="cardQuebraSequencia(false)">Notas</button>
    </div>
</ng-template>
<nz-modal [(nzVisible)]="formFiltrar.modalVisible"
          [nzTitle]="'Filtros Avançados'" [nzClosable]="true"
          (nzOnCancel)="modalFiltrar(false)"
          [nzWidth]="768">
    <div nz-row *nzModalContent>
        <form nz-form [nzNoColon]="true" [formGroup]="formFiltrar.formGroup" style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="6">Data Emissão</nz-form-label>
                <nz-form-control [nzSpan]="12">
                    <nz-date-picker [nzFormat]="'dd/MM/yyyy'"
                                    formControlName="emissaoData" style="width: 100%;"
                                    nzPlaceHolder="Selecione"></nz-date-picker>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="6">Chave</nz-form-label>
                <nz-form-control [nzSpan]="12">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="chave">
                        <nz-option *ngFor="let opt of comboChaves"
                                   [nzLabel]="opt.label"
                                   [nzValue]="opt.id"
                        ></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="6">Número</nz-form-label>
                <nz-form-control [nzSpan]="12">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="numero">
                        <nz-option *ngFor="let opt of comboNumeros"
                                   [nzLabel]="modoDemo(opt.label, 'total')"
                                   [nzValue]="opt.id"
                        ></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="6">Série</nz-form-label>
                <nz-form-control [nzSpan]="12">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="serie">
                        <nz-option *ngFor="let opt of comboSeries"
                                   [nzLabel]="opt.label"
                                   [nzValue]="opt.id"
                        ></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="6">Destinatário/Emitente</nz-form-label>
                <nz-form-control [nzSpan]="12">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="destinatarioEmitente">
                        <nz-option *ngFor="let opt of comboDestinatariosEmitentes"
                                   [nzLabel]="modoDemo(opt.label,'empresaCodigoNome')"
                                   [nzValue]="opt.id"
                        ></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="6">Documento</nz-form-label>
                <nz-form-control [nzSpan]="12">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="documento">
                        <nz-option *ngFor="let opt of comboDocumentos"
                                   [nzLabel]="modoDemo(opt.label, 'cnpj')"
                                   [nzValue]="opt.id"
                        ></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="6">Operação</nz-form-label>
                <nz-form-control [nzSpan]="12">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="operacao">
                        <nz-option *ngFor="let opt of comboOperacoes"
                                   [nzLabel]="opt.label"
                                   [nzValue]="opt.id"
                        ></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="6">Situação</nz-form-label>
                <nz-form-control [nzSpan]="12">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="situacao">
                        <nz-option *ngFor="let opt of comboSituacoes"
                                   [nzLabel]="opt.label"
                                   [nzValue]="opt.id"
                        ></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="6">Status Processamento</nz-form-label>
                <nz-form-control [nzSpan]="12">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="statusProcessamento">
                        <nz-option *ngFor="let opt of comboStatusProcessamento"
                                   [nzLabel]="opt.label"
                                   [nzValue]="opt.id"
                        ></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="6">Status Divergência</nz-form-label>
                <nz-form-control [nzSpan]="12">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="statusDivergencia">
                        <nz-option *ngFor="let opt of comboStatusDivergencia"
                                   [nzLabel]="opt.label"
                                   [nzValue]="opt.id"
                        ></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalFiltrar(false);">Cancelar</button>
        <button nz-button nzType="primary" (click)="confirmaFiltrar()">Filtrar</button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formExportar.modalVisible"
          [nzTitle]="'Exportar Planilha'" [nzClosable]="true"
          (nzOnCancel)="modalExportar(false)"
          [nzWidth]="425">
    <div nz-row *nzModalContent>
        <form nz-form [nzNoColon]="true" [formGroup]="formExportar.formGroup" style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="24">Selecione o formato do arquivo a ser exportado.</nz-form-label>
                <nz-form-control [nzSpan]="24">
                    <nz-select nzPlaceHolder="Selecione"
                               formControlName="tipo" style="width: 100%;">
                        <nz-option [nzLabel]="'.CSV'" [nzValue]="'csv'"></nz-option>
                        <nz-option [nzLabel]="'.XLSX'" [nzValue]="'xlsx'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalExportar(false);">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="loadings.exportando" (click)="confirmaExportar()">Exportar
        </button>
    </div>
</nz-modal>
