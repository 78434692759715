<div class="principal">
    <div class="info">

        <div class="div-l">

            <div class="textos">
                <h2>
                    Conta não encontrada!
                </h2>

                <p>
                    Verifique se o identificador "<b>{{ identificador }}</b>" da conta foi digitado corretamente no
                    endereço ou entre em contato com a nossa empresa para auxílio.
                </p>

                <div class="link">
                    <a href="https://www.mastertax.com.br/" target="_blank">
                        Ir para o site da MasterTax
                        <i class="fa-solid fa-arrow-right"></i>
                    </a>
                </div>
            </div>

            <div class="img-logo">
                <img alt="logo-mastertax" src="{{logoUrl}}">
            </div>

        </div>


    </div>
    <div class="imagem">
        <img src="" class="image"
             *ngIf="backgroundImageUrl"
             [ngStyle]="{'background-image': 'url(' + backgroundImageUrl + ')', 'background-size' : 'cover'}">
    </div>
</div>
