<nz-page-header class="site-page-header" nzTitle="Detalhe da Empresa"
                nzSubtitle="Informações cadastrais da unidade no sistema">
</nz-page-header>
<nz-content>
    <ng-template #indicatorTemplate>
        <i class="loading-icon" nz-icon nzType="loading"></i>
    </ng-template>
    <nz-spin [nzSpinning]="carregando" [nzSize]="'large'" nzSimple [nzIndicator]="indicatorTemplate"></nz-spin>
    <div nz-row [nzGutter]="16" *ngIf="!carregando">
        <div nz-col nzXs="24" nzSm="24" nzMd="6" nzLg="6" nzXl="6">
            <nz-card>
                <nz-card-meta [nzTitle]="modoDemo(empresa.nome)"
                              [nzDescription]="modoDemo(empresa.cnpj | cpfCnpj)"></nz-card-meta>
                <nz-divider></nz-divider>
                <nz-list>
                    <nz-list>
                        <nz-list-item>
                            <nz-list-item-meta nzDescription="Status">
                                <nz-list-item-meta-title>
                                    {{ empresa?.ativo ? 'Ativo' : 'Inativo' }}
                                </nz-list-item-meta-title>
                            </nz-list-item-meta>
                        </nz-list-item>
                    </nz-list>

                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Criado em">
                            <nz-list-item-meta-title>
                                {{ empresa.created_at ? (empresa.created_at | date:'dd/MM/YY - HH:mm:ss') : '-' }}
                                <small *ngIf="empresa.criado_por"><br>Por <b>{{ empresa.criado_por }}</b></small>
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                    </nz-list-item>
                </nz-list>
                <nz-list>
                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Última alteração">
                            <nz-list-item-meta-title>
                                {{ empresa.updated_at ? (empresa.updated_at | date:'dd/MM/YY - HH:mm:ss') : '-' }}
                                <small *ngIf="empresa.atualizado_por"><br>Por
                                    <b>{{ empresa.atualizado_por }}</b></small>
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                    </nz-list-item>
                </nz-list>
            </nz-card>
            <nz-affix [nzOffsetTop]="offsetTop" [nzTarget]="target">
                <nz-anchor class="transparent-anchor" [nzAffix]="false" [nzShowInkInFixed]="true"
                           [nzContainer]="target">
                    <nz-link nzHref="#st1" nzTitle="Informações Cadastrais"></nz-link>
                    <nz-link nzHref="#st2" nzTitle="Endereço"></nz-link>
                    <nz-link nzHref="#st3" nzTitle="Agrupamento"></nz-link>
                    <nz-link nzHref="#st4" nzTitle="Regime Tributário"></nz-link>
                    <nz-link nzHref="#st5" nzTitle="Certificado A1"></nz-link>
                    <nz-link nzHref="#st6" nzTitle="Acessos E-CAC"></nz-link>
                    <nz-link nzHref="#st7" nzTitle="Acessos Estaduais"></nz-link>
                    <nz-link nzHref="#st8" nzTitle="Acessos Municipais"></nz-link>
                    <nz-link nzHref="#st9" nzTitle="Acessos Previdenciários"></nz-link>
                    <nz-link nzHref="#st10" nzTitle="Receita Federal"></nz-link>
                    <nz-link nzHref="#st11" nzTitle="Inscrição Estadual"></nz-link>
                    <nz-link nzHref="#st12" nzTitle="Inscrição Municipal"></nz-link>
                    <nz-link nzHref="#st13" nzTitle="Inscrição Imobiliária"></nz-link>
                    <nz-link *ngIf="this.arrayModulos.includes('cnd')" nzHref="#st14"
                             nzTitle="Controle Certidões"></nz-link>
                    <nz-link *ngIf="this.arrayModulos.includes('dec')" nzHref="#st15"
                             nzTitle="Domicílio Eletrônico"></nz-link>
                    <nz-link *ngIf="verificaModulo(['calendar', 'check', 'compliance'])" nzHref="#st16"
                             nzTitle="Obrigações"></nz-link>
                    <nz-link nzHref="#st19" nzTitle="Configurações avançadas do Auditor"></nz-link>
                    <nz-link nzHref="#st17" nzTitle="Configurações DFE"></nz-link>
                    <nz-link nzHref="#st20" nzTitle="Configurações avançadas do Gestor DFE"></nz-link>

                </nz-anchor>
            </nz-affix>
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="18" nzLg="18" nzXl="18" style="padding-bottom: 200px;">
            <nz-card id="st1" nzTitle="Informações Cadastrais" [nzExtra]="extraInfCadastrais">
                <nz-list>
                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Nome Amigável">
                            <nz-list-item-meta-title>
                                {{ modoDemo(empresa.nome) }}
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                    </nz-list-item>
                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Código">
                            <nz-list-item-meta-title>
                                {{ empresa.codigo ? modoDemo(empresa.codigo, 'total') : '-' }}
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                    </nz-list-item>
                    <nz-list-item>
                        <nz-list-item-meta nzDescription="CNPJ">
                            <nz-list-item-meta-title>
                                {{ empresa.cnpj ? modoDemo(empresa.cnpj | cpfCnpj) : '-' }}
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                    </nz-list-item>
                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Atividade">
                            <nz-list-item-meta-title>
                                {{ empresa.atividade ? empresa.atividade : '-' }}
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                    </nz-list-item>
                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Razão Social">
                            <nz-list-item-meta-title>
                                {{ modoDemo(empresa.razao) }}
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                    </nz-list-item>
                </nz-list>
            </nz-card>
            <ng-template #extraInfCadastrais>
                <button nz-button nzType="primary" (click)="editar(formDadosCadastrais)">Alterar</button>
            </ng-template>
            <nz-card id="st2" nzTitle="Endereço" [nzExtra]="extraEndereco">
                <p>
                    {{ modoDemo(empresa.endereco) }},{{ modoDemo(empresa.numero) }}- {{ modoDemo(empresa.complemento) }}
                    <br/>
                    {{ modoDemo(empresa.bairro) }} - {{ modoDemo(empresa.cidade) }}/{{ empresa.estado }}
                    <br/>
                    {{ modoDemo(empresa.cep | cep) }}
                </p>
            </nz-card>
            <ng-template #extraEndereco>
                <button nz-button nzType="primary" (click)="editar(formEndereco)">Alterar</button>
            </ng-template>
            <nz-card id="st3" nzTitle="Agrupamento">
                <nz-list>
                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Grupo Empresarial">
                            <nz-list-item-meta-title>
                                {{ empresa.grupoEmpresarial_id ? modoDemo(empresa.grupoEmpresarial_descricao) : '-' }}
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                        <ul nz-list-item-actions>
                            <nz-list-item-action>
                                <button nz-button nzType="primary" (click)="editar(formGrupoEmpresarial)">Alterar
                                </button>
                            </nz-list-item-action>
                        </ul>
                    </nz-list-item>

                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Visibilidade">
                            <nz-list-item-meta-title>
                                <nz-skeleton *ngIf="loadings.agrupamento"></nz-skeleton>
                                <div *ngIf="!loadings.agrupamento && empresa.visibilidade_id">
                                    <nz-tag>{{empresa.visibilidade_descricao}}</nz-tag>
                                </div>
                                <div *ngIf="!loadings.agrupamento && !empresa.visibilidade_id">
                                    Empresa não possuí vínculo com nenhuma visibilidade.
                                </div>
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                        <ul nz-list-item-actions>
                            <nz-list-item-action>
                                <button nz-button nzType="primary"
                                        (click)="modalAlterarVisibilidade(true)"
                                        [nzLoading]="loadings.alterandoVisibilidade">Alterar
                                </button>
                            </nz-list-item-action>
                        </ul>
                    </nz-list-item>


                </nz-list>
            </nz-card>
            <nz-card id="st4" class="ant-card-table" nzTitle="Regime Tributário" [nzExtra]="extraRegimes"
                     [nzLoading]="loadingRegime"
                     [nzBodyStyle]="loadingRegime ? {'overflow' : 'hidden', 'padding':'15px'} : {}">
                <ng-template #extraRegimes>
                    <button nz-button nzType="primary" (click)="cadastrar(formRegime, false);">
                        Cadastrar
                    </button>
                </ng-template>
                <nz-table #basicTable2
                          nzSize="small"
                          [nzData]="regimes"
                          [nzFrontPagination]="false"
                          [nzLoading]="loadingRegime"
                          [nzTotal]="paginations.regimes?.total"
                          [nzPageSize]="paginations.regimes?.per_page"
                          [nzPageIndex]="paginations.regimes?.current_page"
                          (nzQueryParams)="listarRegimes($event)">
                    <thead>
                    <tr>
                        <th nzColumnKey="regimeTributario_descricao" [nzSortFn]="true">Regime</th>
                        <th nzColumnKey="dataInicio" [nzSortFn]="true" nzAlign="center">Início</th>
                        <th nzColumnKey="dataFim" [nzSortFn]="true" nzAlign="center">Fim</th>
                        <th nzAlign="right"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let item of basicTable2.data">
                        <td>{{ modoDemo(item.regimeTributario_descricao, 'total') }}</td>
                        <td nzAlign="center">{{ item.dataInicio | date: 'dd/MM/yyyy' }}</td>
                        <td nzAlign="center">{{ item.dataFim | date: 'dd/MM/yyyy' }}</td>
                        <td nzRight nzAlign="right">
                            <button nz-button nzType="default"
                                    (click)="alterarRegime(item);"
                                    style="margin-right: 10px;">
                                Alterar
                            </button>
                            <button nz-button nzType="default" [nzLoading]="this.removendoId === item.id"
                                    (click)="showConfirm('regime', item);">
                                Remover
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </nz-table>
            </nz-card>
            <nz-card id="st5" class="ant-card-table" nzTitle="Certificado A1" [nzExtra]="st5Extra">
                <ng-template #st5Extra>
                    <button nz-button nzType="default"
                            style="margin-right: 10px; padding-left: 8px; padding-right: 8px;"
                            (click)="listarCertificados(currentsParams.certificados, currentSearch.certificados);"
                            title="Atualizar">
                        <i nz-icon nzType="reload"></i>
                    </button>
                    <button nz-button nzType="primary" (click)="cadastrar(formAdicionarCertificado, false);">
                        Adicionar
                    </button>
                </ng-template>
                <nz-alert nzType="info" nzShowIcon [nzMessage]="checkedMessage" style="margin: 15px !important;"
                          *ngIf="usaCertificadoMatriz">
                    <ng-template #checkedMessage>
                        <div nz-row nzAlign="middle" nzGutter="24">
                            <div nz-col nzSpan="24">
                                <strong class="text-primary"></strong>
                                Esta filial usa certificado da matriz.
                            </div>
                        </div>
                    </ng-template>
                </nz-alert>
                <nz-table #basicTable1
                          nzSize="small"
                          [nzData]="listaCretificados"
                          [nzFrontPagination]="false"
                          [nzLoading]="loadings.certificados"
                          [nzTotal]="paginations.certificados?.total"
                          [nzPageSize]="paginations.certificados?.per_page"
                          [nzPageIndex]="paginations.certificados?.current_page"
                          (nzQueryParams)="listarCertificados($event)"
                >
                    <thead>
                    <tr>
                        <th nzColumnKey="emissor" [nzSortFn]="true" nzAlign="left">Emissor</th>
                        <th nzColumnKey="tipo" [nzSortFn]="true" nzAlign="center">Categoria</th>
                        <th nzColumnKey="situacao" [nzSortFn]="false" nzShowSort="false" nzAlign="center">Situação</th>
                        <th nzColumnKey="validade" [nzSortFn]="true" nzAlign="center">Validade</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let item of basicTable1.data">
                        <td>{{ item.emissor }}</td>
                        <td nzAlign="center">{{ item.tipo_descricao }}</td>
                        <td nzAlign="center">{{ item.situacao_descricao }}</td>
                        <td nzAlign="center">{{ item.validade | date: 'dd/MM/yyyy' }}</td>
                        <td nzAlign="right">
                            <button nz-button nzType="default"
                                    (click)="alterarCertificadoA1(item);"
                                    style="margin-right: 10px;">
                                Detalhe
                            </button>
                            <button nz-button nzType="default"
                                    (click)="showConfirm('certificados', item);">
                                Remover
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </nz-table>
            </nz-card>
            <nz-card id="st6" class="ant-card-table" nzTitle="Acessos E-CAC" [nzExtra]="st6Extra"
                     [nzBodyStyle]="loadings.acessosEcac ? {'overflow' : 'hidden', 'padding':'15px'} : {}"
                     [nzLoading]="loadings.acessosEcac">
                <ng-template #st6Extra>
                    <button nz-button nzType="default"
                            style="margin-right: 10px; padding-left: 8px; padding-right: 8px;"
                            (click)="listarAcessosEcac(currentsParams.acessosEcac, currentSearch.acessosEcac);"
                            title="Atualizar">
                        <i nz-icon nzType="reload"></i>
                    </button>
                    <button nz-button nzType="primary" (click)="modalAcessoEcac(true, true);">
                        Cadastrar
                    </button>
                </ng-template>
                <nz-table #basicTable7
                          nzSize="small"
                          [nzData]="listaAcessosEcac"
                          [nzFrontPagination]="false"
                          [nzTotal]="paginations.acessosEcac?.total"
                          [nzPageSize]="paginations.acessosEcac?.per_page"
                          [nzPageIndex]="paginations.acessosEcac?.current_page"
                          (nzQueryParams)="listByTableAcessosEcac($event)">
                    <thead>
                    <tr>
                        <th nzColumnKey="created_at" [nzSortFn]="true" nzAlign="center">Data</th>
                        <th nzColumnKey="validade" [nzSortFn]="true" nzAlign="center">Validade</th>
                        <th nzColumnKey="status" [nzSortFn]="true" nzAlign="center">Status</th>
                        <th nzColumnKey="status" [nzSortFn]="true" nzAlign="center">Última Baixa</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let item of basicTable7.data">
                        <td nzAlign="center">
                            <div>
                                            <span>
                                                {{ item.created_at ? (item.created_at | date : 'dd/MM/yyyy HH:mm') : '-' }}
                                            </span>
                            </div>
                        </td>
                        <td nzAlign="center">
                            <div>
                                            <span>
                                                {{ item.validade ? (item.validade | date : 'dd/MM/yyyy HH:mm') : '-' }}
                                            </span>
                            </div>
                        </td>
                        <td>
                            <div style="text-align: center">
                                            <span>
                                                {{ item.ativo ? 'Ativo' : 'inativo' }}
                                            </span>
                            </div>
                        </td>
                        <td nzAlign="center">
                            <div *ngIf="item.statusProcessamento else semStatus"
                                 style="text-align: center; font-size: 1.2em">
                                            <span *ngIf="item.statusProcessamento == 1 else erroUltimoProcessamentoEcac">
                                               <i [nz-tooltip]="item.statusMensagem + ' - Data: ' + (item.statusData | date: 'dd/MM/yyyy HH:mm' )"
                                                  style="color: #32a852" class="fa-solid fa-check"></i>
                                            </span>
                                <ng-template #erroUltimoProcessamentoEcac>
                                    <i [nz-tooltip]="item.statusMensagem + ' - Data: ' + (item.statusData | date: 'dd/MM/yyyy HH:mm' )"
                                       style="color: #eb1a2b" class="fa-solid fa-xmark"></i>
                                </ng-template>
                            </div>

                            <ng-template #semStatus>
                                            <span>
                                                -
                                            </span>
                            </ng-template>
                        </td>
                        <td nzAlign="center">
                            <button nz-button nzType="link" (click)="modalAcessoIntegradoEcac(item, true);">
                                Ver
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </nz-table>
            </nz-card>
            <nz-card id="st7" class="ant-card-table" nzTitle="Acessos Estaduais"
                     [nzExtra]="st7Extra">
                <ng-template #st7Extra>
                    <button nz-button nzType="default"
                            style="margin-right: 10px; padding-left: 8px; padding-right: 8px;"
                            (click)="listarAcessos(currentsParams.acessos, currentSearch.acessos);" title="Atualizar">
                        <i nz-icon nzType="reload"></i>
                    </button>
                    <button nz-button nzType="primary" (click)="modalAcesso(true);">
                        Cadastrar
                    </button>
                </ng-template>
                <nz-table #basicTable4
                          nzSize="small"
                          [nzData]="listaAcessos"
                          [nzFrontPagination]="false"
                          [nzLoading]="loadings.acessos"
                          [nzTotal]="paginations.acessos?.total"
                          [nzPageSize]="paginations.acessos?.per_page"
                          [nzPageIndex]="paginations.acessos?.current_page"
                          (nzQueryParams)="listarAcessos($event)">
                    <thead>
                    <tr>
                        <th nzColumnKey="uf" [nzSortFn]="true" nzAlign="center">UF</th>
                        <th nzColumnKey="portal" [nzSortFn]="true" nzAlign="center">Portal</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let item of basicTable4.data">
                        <td nzAlign="center">{{ item?.uf }}</td>
                        <td nzAlign="center">{{ item?.portal }}</td>
                        <td nzAlign="right">
                            <button nz-button nzType="default"
                                    (click)="alterarAcessosEstaduais(item);"
                                    style="margin-right: 10px;">
                                Alterar
                            </button>
                            <button nz-button nzType="default"
                                    (click)="showConfirm('acessos', item);"
                            >
                                Remover
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </nz-table>
            </nz-card>
            <nz-card id="st8" class="ant-card-table" nzTitle="Acessos Municipais" [nzExtra]="st8Extra"
                     [nzBodyStyle]="loadings.acessosMunicipais ? {'overflow' : 'hidden', 'padding':'15px'} : {}"
                     [nzLoading]="loadings.acessosMunicipais">
                <ng-template #st8Extra>
                    <button nz-button nzType="default"
                            style="margin-right: 10px; padding-left: 8px; padding-right: 8px;"
                            (click)="listarAcessosMunicipais(currentsParams.acessosMunicipais, currentSearch.acessosMunicipais);"
                            title="Atualizar">
                        <i nz-icon nzType="reload"></i>
                    </button>
                    <button nz-button nzType="primary" (click)="modalAcessoMunicipal(true);">
                        Cadastrar
                    </button>
                </ng-template>
                <nz-table #basicTable5
                          nzSize="small"
                          [nzData]="listaAcessosMunicipais"
                          [nzFrontPagination]="false"
                          [nzTotal]="paginations.acessosMunicipais?.total"
                          [nzPageSize]="paginations.acessosMunicipais?.per_page"
                          [nzPageIndex]="paginations.acessosMunicipais?.current_page"
                          (nzQueryParams)="listByTableMunicipais($event)">
                    <thead>
                    <tr>
                        <th nzColumnKey="uf" [nzSortFn]="true" nzAlign="center">UF</th>
                        <th nzColumnKey="portal" [nzSortFn]="true" nzAlign="center">Município</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let item of basicTable5.data">
                        <td nzAlign="center">
                            <div *ngIf="dadosCadastrais.estado && dadosCadastrais.estado != item.uf else mesmaUFMunicipais">
                                {{ item?.uf }}
                                <span
                                        nz-icon nzType="info-circle" nzTheme="outline"
                                        style="font-size: 12px; color: #FAA646; margin-left: 5px;"
                                        [nz-tooltip]="'UF diferente do cadastro da empresa (' + dadosCadastrais.estado + ')'"
                                ></span>
                            </div>
                            <ng-template #mesmaUFMunicipais>
                                            <span style="margin-left: -20px">
                                                 {{ item?.uf }}
                                            </span>
                            </ng-template>
                        </td>
                        <td nzAlign="center">{{ item?.portal }}</td>
                        <td nzAlign="right">
                            <button nz-button nzType="default"
                                    (click)="alterarAcessosMunicipais(item);"
                                    style="margin-right: 10px;">
                                Alterar
                            </button>
                            <button nz-button nzType="default"
                                    (click)="showConfirm('acessosMunicipais', item);">
                                Remover
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </nz-table>
            </nz-card>
            <nz-card id="st9" class="ant-card-table" nzTitle="Acessos Previdenciários" [nzExtra]="st9Extra"
                     [nzBodyStyle]="loadings.acessosPrevidenciarios ? {'overflow' : 'hidden', 'padding':'15px'} : {}"
                     [nzLoading]="loadings.acessosPrevidenciarios">
                <ng-template #st9Extra>
                    <button nz-button nzType="default"
                            style="margin-right: 10px; padding-left: 8px; padding-right: 8px;"
                            (click)="listarAcessosPrevidenciarios(currentsParams.acessosPrevidenciarios, currentSearch.acessosPrevidenciarios);"
                            title="Atualizar">
                        <i nz-icon nzType="reload"></i>
                    </button>
                    <button nz-button nzType="primary" (click)="modalAcessoPrevidenciario(true);">
                        Cadastrar
                    </button>
                </ng-template>
                <nz-table #basicTable6
                          nzSize="small"
                          [nzData]="listaAcessosPrevidenciarios"
                          [nzFrontPagination]="false"
                          [nzTotal]="paginations.acessosPrevidenciarios?.total"
                          [nzPageSize]="paginations.acessosPrevidenciarios?.per_page"
                          [nzPageIndex]="paginations.acessosPrevidenciarios?.current_page"
                          (nzQueryParams)="listByTablePrevidenciarios($event)">
                    <thead>
                    <tr>
                        <th nzColumnKey="portal" [nzSortFn]="true" nzAlign="center">Portal</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let item of basicTable6.data">
                        <td nzAlign="center">{{ item?.portal }}</td>
                        <td nzAlign="right">
                            <button nz-button nzType="default"
                                    (click)="alterarAcessosPrevidenciarios(item);"
                                    style="margin-right: 10px;">
                                Alterar
                            </button>
                            <button nz-button nzType="default"
                                    (click)="showConfirm('acessosPrevidenciarios', item);">
                                Remover
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </nz-table>
            </nz-card>
            <nz-card id="st10" class="ant-card-table" nzTitle="Receita Federal"
                     [nzExtra]="extraIfs">
                <ng-template #extraIfs>
                    <button nz-button nzType="primary" (click)="cadastrar(formIf, false);">
                        Cadastrar
                    </button>
                </ng-template>
                <nz-table #basicTableIf
                          nzSize="small"
                          [nzData]="ifs"
                          [nzFrontPagination]="false"
                          [nzLoading]="loadingIf"
                          [nzTotal]="paginations.ifs?.total"
                          [nzPageSize]="paginations.ifs?.per_page"
                          [nzPageIndex]="paginations.ifs?.current_page"
                          (nzQueryParams)="listarIfs($event)">
                    <thead>
                    <tr>
                        <th nzColumnKey="documento" [nzSortFn]="true">Documento</th>
                        <th nzColumnKey="dataInicio" [nzSortFn]="true" nzAlign="center">Início</th>
                        <th nzColumnKey="dataFim" [nzSortFn]="true" nzAlign="center">Fim</th>
                        <th nzColumnKey="situacaoCadastral" [nzSortFn]="true" nzAlign="center">Situação Cadastral</th>
                        <th nzColumnKey="situacaoCadastralData" [nzSortFn]="true" nzAlign="center">Data Situação
                            Cadastral
                        </th>
                        <th nzAlign="right"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let item of basicTableIf.data">
                        <td style="white-space: nowrap;">{{ modoDemo(item.documento | cpfCnpj) }}</td>
                        <td nzAlign="center">{{ item.dataInicio | date: 'dd/MM/yyyy' }}</td>
                        <td nzAlign="center">{{ item.dataFim  | date: 'dd/MM/yyyy' }}</td>
                        <td nzAlign="center">{{ item.situacaoCadastral }}</td>
                        <td nzAlign="center">{{ item.situacaoCadastralData  | date: 'dd/MM/yyyy' }}</td>
                        <td style="white-space: nowrap; text-align: right">
                            <button nz-button nzType="default"
                                    (click)="alterarIf(item);"
                                    style="margin-right: 5px;">
                                Alterar
                            </button>
                            <button nz-button nzType="default"
                                    (click)="alterarReceita(item.documento);"
                                    style="margin-right: 5px;" [nzLoading]="loadingReceita">
                                Atualizar
                            </button>
                            <button nz-button nzType="default"
                                    (click)="showConfirm('if', item);">
                                Remover
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </nz-table>
            </nz-card>
            <nz-card id="st11" class="ant-card-table" nzTitle="Inscrição Estadual"
                     [nzExtra]="extraIes">
                <ng-template #extraIes>
                    <button nz-button nzType="primary" (click)="cadastrar(formIe, false);">
                        Cadastrar
                    </button>
                </ng-template>
                <nz-table #basicTable3
                          nzSize="small"
                          [nzData]="ies"
                          [nzFrontPagination]="false"
                          [nzLoading]="loadingIe"
                          [nzTotal]="paginations.ies?.total"
                          [nzPageSize]="paginations.ies?.per_page"
                          [nzPageIndex]="paginations.ies?.current_page"
                          (nzQueryParams)="listarIes($event)">
                    <thead>
                    <tr>
                        <th nzColumnKey="isento" [nzSortFn]="false" nzShowSort="false">Isento</th>
                        <th nzColumnKey="ie" [nzSortFn]="true" nzAlign="center">IE</th>
                        <th nzColumnKey="uf" [nzSortFn]="true" nzAlign="center">UF</th>
                        <th nzColumnKey="tipo" [nzSortFn]="true" nzAlign="center">Tipo</th>
                        <th nzColumnKey="dataInicio" [nzSortFn]="true" nzAlign="center">Inicio</th>
                        <th nzColumnKey="dataFim" [nzSortFn]="true" nzAlign="center">Fim</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let item of basicTable3.data">
                        <td>{{ item.ie ? 'Não' : 'Sim' }}</td>
                        <td nzAlign="center">{{ item.ie ? modoDemo(item.ie, 'total') : '-' }}</td>
                        <td nzAlign="center">{{ item.uf ? item.uf : '-' }}</td>
                        <td nzAlign="center">{{ item.ie ? item.tipo : '-' }}</td>
                        <td nzAlign="center">{{ item.ie ? (item.dataInicio | date: 'dd/MM/yyyy') : '-' }}</td>
                        <td nzAlign="center">{{ item.ie ? (item.dataFim | date: 'dd/MM/yyyy') : '-' }}</td>
                        <td nzRight nzAlign="right">
                            <button nz-button nzType="default"
                                    (click)="alterarIe(item);"
                                    style="margin-right: 10px;">
                                Alterar
                            </button>
                            <button nz-button nzType="default"
                                    (click)="showConfirm('ie', item);">
                                Remover
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </nz-table>
            </nz-card>
            <nz-card id="st12" class="ant-card-table" nzTitle="Inscrição Municipal"
                     [nzExtra]="extraIms">
                <ng-template #extraIms>
                    <button nz-button nzType="primary" (click)="cadastrar(formIm, true);">
                        Cadastrar
                    </button>
                </ng-template>
                <nz-table #tableInscricaoMunicipal
                          nzSize="small"
                          [nzData]="ims"
                          [nzFrontPagination]="false"
                          [nzLoading]="loadingIm"
                          [nzTotal]="paginations.ims?.total"
                          [nzPageSize]="paginations.ims?.per_page"
                          [nzPageIndex]="paginations.ims?.current_page"
                          (nzQueryParams)="listarIms($event)"
                >
                    <thead>
                    <tr>
                        <th nzColumnKey="im" [nzSortFn]="true">IM</th>
                        <th nzColumnKey="municipioCodigo" [nzSortFn]="true" nzAlign="center">Cod. Mun</th>
                        <th nzColumnKey="cidade_nome" [nzSortFn]="true" nzAlign="center">Municipio</th>
                        <th nzColumnKey="situacaoCadastral" [nzSortFn]="true" nzAlign="center">Situação</th>
                        <th nzColumnKey="tipo" [nzSortFn]="true" nzAlign="center">Tipo</th>
                        <th nzColumnKey="dataInicio" [nzSortFn]="true" nzAlign="center">Inicio</th>
                        <th nzColumnKey="dataFim" [nzSortFn]="true" nzAlign="center">Fim</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let item of tableInscricaoMunicipal.data">
                        <td>{{ modoDemo(item.im, 'total') }}</td>
                        <td nzAlign="center">{{ modoDemo(item.municipioCodigo, 'total') }}</td>
                        <td nzAlign="center">{{ item.cidade_nome }}</td>
                        <td nzAlign="center">{{ item.situacaoCadastral }}</td>
                        <td nzAlign="center">{{ item.tipo ?? '-' }}</td>
                        <td nzAlign="center">{{ item.dataInicio | date: 'dd/MM/yyyy' }}</td>
                        <td nzAlign="center">{{ item.dataFim | date: 'dd/MM/yyyy' }}</td>
                        <td nzRight nzAlign="right">
                            <button nz-button nzType="default"
                                    (click)="alterarIm(item);"
                                    style="margin-right: 10px;">
                                Alterar
                            </button>
                            <button nz-button nzType="default"
                                    (click)="showConfirm('im', item);">
                                Remover
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </nz-table>
            </nz-card>
            <nz-card id="st13" [nzLoading]="loadings.gravandoImobiliaria || loadingImombiliarias"
                     [nzBodyStyle]="loadings.gravandoImobiliaria  || loadingImombiliarias ? {'padding' : '10px' } : {}"
                     class="ant-card-table" nzTitle="Inscrição Imobiliária" [nzExtra]="extraImobiliarias">
                <ng-template #extraImobiliarias>
                    <button nz-button nzType="primary" (click)="cadastrar(formImobiliaria, false);">
                        Cadastrar
                    </button>
                </ng-template>
                <nz-table #basicTableImob
                          nzSize="small"
                          [nzData]="iImobiliarias"
                          [nzFrontPagination]="false"
                          [nzLoading]="loadingImombiliarias"
                          [nzTotal]="paginationImobiliarias?.total"
                          [nzPageSize]="paginationImobiliarias?.per_page"
                          [nzPageIndex]="paginationImobiliarias?.current_page"
                          (nzQueryParams)="listarImobiliarias($event)"
                >
                    <thead>
                    <tr>
                        <th nzColumnKey="ii" [nzSortFn]="true">Inscrição</th>
                        <th nzColumnKey="estado_uf" [nzSortFn]="true">UF</th>
                        <th nzColumnKey="cidade_nome" [nzSortFn]="true" nzAlign="center">Municipio</th>
                        <th nzColumnKey="situacaoCadastral" [nzSortFn]="true" nzAlign="center">Situação</th>
                        <th nzColumnKey="dataInicio" [nzSortFn]="true" nzAlign="center">Inicio</th>
                        <th nzColumnKey="setor" [nzSortFn]="true" nzAlign="center">Setor</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let item of basicTableImob.data">
                        <td>{{ item.ii ? modoDemo(item.ii, 'total') : '-' }}</td>
                        <td nzAlign="center">{{ item.estado_uf || '-' }}</td>
                        <td nzAlign="center">{{ item.cidade_nome + ' - (' + item.municipioCodigo + ')' }}</td>
                        <td nzAlign="center">{{ item.situacaoCadastral ? item.situacaoCadastral === 'Ativo' ? 'Ativa' : 'Cancelada' : ' ' }}</td>
                        <td nzAlign="center">{{ item.dataInicio | date: 'dd/MM/yyyy' }}</td>
                        <td nzAlign="center">{{ item.setor || '-' }}</td>
                        <td nzRight nzAlign="right">
                            <button nz-button nzType="default"
                                    (click)="alterarImobiliaria(item);"
                                    style="margin-right: 10px;">
                                Alterar
                            </button>
                            <button nz-button nzType="default"
                                    (click)="showConfirm('imobiliaria', item);">
                                Remover
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </nz-table>
            </nz-card>
            <nz-card *ngIf="this.arrayModulos.includes('cnd')" id="st14"
                     [ngClass]="statusCardCnd ? 'card-fullscreen' : 'ant-card-table'"
                     nzTitle="Controle Certidões - CND" [nzExtra]="cndExtra">
                <ng-template #cndExtra>
                    <div class="d-flex align-items-center">
                        <nz-badge [nzCount]="qtdFiltrosAtivosCnd"
                                  [nzStyle]="{ backgroundColor: '#52c41a', 'margin-right' : '10px'}">
                            <button nz-button nzType="primary"
                                    style="margin-right: 10px;"
                                    (click)="modalFiltrarCnd(true);">
                                <i nz-icon nzType="search"></i>
                                Filtros
                            </button>
                        </nz-badge>
                        <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" style="margin-right: 10px;">
                            <input (keydown.enter)="queryTableCnd(currentsParams.cnd, currentSearch.cnd);" type="text"
                                   nz-input placeholder="Busca" [(ngModel)]="currentSearch.certidoes">
                        </nz-input-group>
                        <ng-template #suffixIconButton>
                            <button nz-button nzType="primary" nzSearch
                                    (click)="queryTableCnd(currentsParams.cnd, currentSearch.cnd);">
                                <i nz-icon nzType="search"></i></button>
                        </ng-template>
                        <button nz-button [nzType]="'primary'" style="margin-right: 5px;"
                                (click)="modalCadastrarCnd(formDadosCnd)">
                            Adicionar
                        </button>
                        <button [nzDropdownMenu]="menuAcoes" style="margin-right: 5px;" class="centralized-icon"
                                nz-button nz-dropdown
                                nzType="default">
                            Opções
                            <em nz-icon nzType="down"></em>
                        </button>
                        <nz-dropdown-menu #menuAcoes="nzDropdownMenu">
                            <ul nz-menu>
                                <li nz-submenu nzTitle="Manutenção Coletiva">
                                    <ul>
                                        <li nz-menu-item nzDisabled>Ações</li>
                                        <li nz-menu-divider></li>
                                        <li nz-menu-item
                                            (click)="checkedItems?.size > 0 && showConfirmRemoverCndsEmMassa()"
                                            [nzDisabled]="checkedItems?.size === 0">Remover Registros
                                        </li>
                                        <li nz-submenu nzTitle="Editar Registros">
                                            <ul>
                                                <li nz-menu-item nzDisabled>Ações</li>
                                                <li nz-menu-divider></li>
                                                <li [nzDisabled]="checkedItems?.size === 0" nz-menu-item
                                                    (click)="modalEditarCndMassa(true)">
                                                    Editar Data Inicio ou Fim
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li nz-menu-item nz-button (click)="showConfirmAdicionarCertidoes()">Adicionar Certidões
                                    Sugeridas
                                </li>
                            </ul>
                        </nz-dropdown-menu>
                        <button nz-button [nzType]="'default'" (click)="reloadCardCnd()"
                                style="padding-left: 8px; padding-right: 8px; margin-right: 5px;">
                            Limpar
                        </button>
                        <button nz-button [nzType]="'default'"
                                (click)="queryTableCnd(currentsParams.cnd, currentSearch.cnd)"
                                style="padding-left: 8px; padding-right: 8px; margin-right: 5px;">
                            <i class="fas fa-redo-alt"></i>
                        </button>
                        <button nz-button [nzType]="'default'" (click)="clickEvent('cnd')"
                                style="padding-left: 8px; padding-right: 8px;">
                            <i class="fas" [ngClass]="!statusCardCnd ? 'fa-expand' : 'fa-compress'"></i>
                        </button>
                    </div>
                </ng-template>
                <nz-alert nzType="info" nzShowIcon [nzMessage]="checkedMessage" style="margin: 15px !important;"
                          *ngIf="checkedItems.size > 0">
                    <ng-template #checkedMessage>
                        <div nz-row nzAlign="middle" nzGutter="24">
                            <div nz-col nzSpan="24">
                                <strong class="text-primary"></strong>
                                {{ checkedItems.size }} Registros Selecionados
                            </div>
                        </div>
                    </ng-template>
                </nz-alert>
                <nz-table #basicTable
                          nzSize="small"
                          [nzData]="itemsCnd"
                          [nzFrontPagination]="false"
                          [nzLoading]="loadings.tableCnd"
                          [nzTotal]="paginations?.cnd?.total"
                          [nzPageSize]="paginations?.cnd?.per_page"
                          [nzPageIndex]="paginations?.cnd?.current_page"
                          (nzQueryParams)="queryTableCnd($event, currentSearch.cnd)">
                    <thead>
                    <tr>
                        <th style="text-align: center;">
                            <label nz-checkbox [(ngModel)]="checked" (nzCheckedChange)="onAllChecked($event)"></label>
                        </th>
                        <th nzColumnKey="descricao" [nzSortFn]="true">Descrição</th>
                        <th nzColumnKey="dataInicio" [nzSortFn]="true" nzAlign="center">Data Início</th>
                        <th nzColumnKey="dataFim" [nzSortFn]="true" nzAlign="center">Data Fim</th>
                        <th nzColumnKey="acesso" [nzSortFn]="true" nzAlign="center">Acesso</th>
                        <th nzAlign="right"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let cnd of basicTable.data">
                        <td nzAlign="center" (nzCheckedChange)="onItemChecked(cnd.controleCertidaoEmpresa_id, $event)"
                            [nzChecked]="checkedItems.has(cnd.controleCertidaoEmpresa_id)"
                            [nzDisabled]="cnd.disabled">
                        </td>
                        <td nzLeft>{{ cnd?.certidao_descricao }}</td>
                        <td nzAlign="center">{{ cnd?.dataInicio | date: 'dd/MM/YYYY' }}</td>
                        <td nzAlign="center">{{ cnd?.dataFim | date: 'dd/MM/YYYY' }}</td>
                        <td nzAlign="center">{{ cnd?.acesso ? cnd?.acesso : '-' }}</td>
                        <td nzAlign="right" style="width: 200px;">
                            <button nz-button
                                    nzType="default"
                                    (click)="onEditarCnd(cnd.controleCertidaoEmpresa_id)">
                                Alterar
                            </button>
                            <nz-divider nzType="vertical"></nz-divider>
                            <button nz-button
                                    nzType="default"
                                    (click)="showConfirmRemover(cnd.controleCertidaoEmpresa_id, 'cnd')">
                                Remover
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </nz-table>
            </nz-card>
            <nz-card *ngIf="this.arrayModulos.includes('dec')" id="st15"
                     [ngClass]="statusCardDec ? 'card-fullscreen' : 'ant-card-table'"
                     nzTitle="Domicílio Eletrônico - DEC" [nzExtra]="decExtra">
                <ng-template #decExtra>
                    <div class="d-flex align-items-center">
                        <nz-badge [nzCount]="qtdFiltrosAtivosDec"
                                  [nzStyle]="{ backgroundColor: '#52c41a', 'margin-right' : '10px'}">
                            <button nz-button nzType="primary"
                                    style="margin-right: 10px;"
                                    (click)="modalFiltrarDec(true);">
                                <i nz-icon nzType="search"></i>
                                Filtros
                            </button>
                        </nz-badge>
                        <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" style="margin-right: 10px;">
                            <input (keydown.enter)="queryTableDec(currentsParams.dec, currentSearch.domicilios);"
                                   type="text" nz-input placeholder="Busca" [(ngModel)]="currentSearch.domicilios">
                        </nz-input-group>
                        <ng-template #suffixIconButton>
                            <button nz-button nzType="primary" nzSearch
                                    (click)="queryTableDec(currentsParams.dec, currentSearch.domicilios);">
                                <i nz-icon nzType="search"></i></button>
                        </ng-template>
                        <button nz-button [nzType]="'primary'" style="margin-right: 5px;"
                                (click)="modalCadastrarDec(formDadosDec)">
                            Adicionar
                        </button>
                        <button [nzDropdownMenu]="menuAcoes" style="margin-right: 5px;" class="centralized-icon"
                                nz-button nz-dropdown
                                nzType="default">
                            Opções
                            <em nz-icon nzType="down"></em>
                        </button>
                        <nz-dropdown-menu #menuAcoes="nzDropdownMenu">
                            <ul nz-menu>
                                <li nz-submenu nzTitle="Manutenção Coletiva">
                                    <ul>
                                        <li nz-menu-item nzDisabled>Ações</li>
                                        <li nz-menu-divider></li>
                                        <li nz-menu-item
                                            (click)="checkedItemsDec?.size > 0 && showConfirmRemoverDecsEmMassa()"
                                            [nzDisabled]="checkedItemsDec?.size === 0">Remover Registros
                                        </li>
                                        <li nz-submenu nzTitle="Editar Registros">
                                            <ul>
                                                <li nz-menu-item nzDisabled>Ações</li>
                                                <li nz-menu-divider></li>
                                                <li [nzDisabled]="checkedItemsDec?.size === 0" nz-menu-item
                                                    (click)="modalEditarDecMassa(true)">
                                                    Editar Data Inicio ou Fim
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li nz-menu-item nz-button (click)="showConfirmAdicionarDomicilios()">Adicionar DEC's
                                    Sugeridos
                                </li>
                            </ul>
                        </nz-dropdown-menu>
                        <button nz-button [nzType]="'default'" (click)="reloadCardDec()"
                                style="padding-left: 8px; padding-right: 8px; margin-right: 5px;">
                            Limpar
                        </button>
                        <button nz-button [nzType]="'default'"
                                (click)="queryTableDec(currentsParams.dec, currentSearch.domicilios)"
                                style="padding-left: 8px; padding-right: 8px; margin-right: 5px;">
                            <i class="fas fa-redo-alt"></i>
                        </button>
                        <button nz-button [nzType]="'default'" (click)="clickEvent('dec')"
                                style="padding-left: 8px; padding-right: 8px;">
                            <i class="fas" [ngClass]="!statusCardDec ? 'fa-expand' : 'fa-compress'"></i>
                        </button>
                    </div>
                </ng-template>
                <nz-alert nzType="info" nzShowIcon [nzMessage]="checkedMessage" style="margin: 15px !important;"
                          *ngIf="checkedItemsDec.size > 0">
                    <ng-template #checkedMessage>
                        <div nz-row nzAlign="middle" nzGutter="24">
                            <div nz-col nzSpan="24">
                                <strong class="text-primary"></strong>
                                {{ checkedItemsDec.size }} Registros Selecionados
                            </div>
                        </div>
                    </ng-template>
                </nz-alert>
                <nz-table #basicTableDec
                          nzSize="small"
                          [nzData]="itemsDec"
                          [nzFrontPagination]="false"
                          [nzLoading]="loadings.tableDec"
                          [nzTotal]="paginations?.dec?.total"
                          [nzPageSize]="paginations?.dec?.per_page"
                          [nzPageIndex]="paginations?.dec?.current_page"
                          (nzQueryParams)="queryTableDec($event)">
                    <thead>
                    <tr>
                        <th style="text-align: center;">
                            <label nz-checkbox [(ngModel)]="checkedDec"
                                   (nzCheckedChange)="onAllCheckedDec($event)"></label>
                        </th>
                        <th nzColumnKey="descricao" [nzSortFn]="true">Descrição</th>
                        <th nzColumnKey="criado" [nzSortFn]="true" nzAlign="center">Cadastro</th>
                        <th nzColumnKey="dataInicio" [nzSortFn]="true" nzAlign="center">Data Início</th>
                        <th nzColumnKey="dataFim" [nzSortFn]="true" nzAlign="center">Data Fim</th>
                        <th nzAlign="right"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let dec of basicTableDec.data">
                        <td nzAlign="center"
                            (nzCheckedChange)="onItemDECChecked(dec.domicilioEletronicoEmpresa_id, $event)"
                            [nzChecked]="checkedItemsDec.has(dec.domicilioEletronicoEmpresa_id)"
                            [nzDisabled]="dec.disabled">
                        </td>
                        <td nzLeft>{{ dec?.domicilioEletronico_descricao }}</td>
                        <td nzAlign="center">{{ dec?.created_at | date: 'dd/MM/yyyy HH:mm:ss' }}</td>
                        <td nzAlign="center">{{ dec?.dataInicio | date: 'dd/MM/yyyy' }}</td>
                        <td nzAlign="center">{{ dec?.dataFim |  date: 'dd/MM/yyyy' }}</td>
                        <td nzAlign="right" style="width: 200px;">
                            <button nz-button
                                    nzType="default"
                                    (click)="onEditarDec(dec?.domicilioEletronicoEmpresa_id, dec.domicilioEletronico_descricao)">
                                Alterar
                            </button>
                            <nz-divider nzType="vertical"></nz-divider>
                            <button nz-button
                                    nzType="default"
                                    (click)="showConfirmRemover(dec?.domicilioEletronicoEmpresa_id, 'dec')">
                                Remover
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </nz-table>
            </nz-card>
            <div *ngIf="verificaModulo(['calendar', 'check', 'compliance'])">
                <app-empresa-detalhe-tab-obrigacoes [empresa]="empresa"></app-empresa-detalhe-tab-obrigacoes>
            </div>

            <nz-card id="st19" nzTitle="Configurações avançadas do Auditor">
                <nz-list id="configuracoesAuditor">
                    <nz-list-item>
                        <nz-list-item-meta>
                            <nz-list-item-meta-title>
                                <button nz-button nzType="link"
                                        (click)="openTabEmbedded('/configuracoes/auditor/', empresa.id, {id: empresa.id, cnpj: empresa.cnpj, link: '/auditor/configuracoes/conta/&conta_id=' + empresa.cnpj + '&tipo=auditor', extra:''}, false, ' - Configuração Auditor')">
                                    Editar configurações
                                </button>
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                    </nz-list-item>
                </nz-list>
            </nz-card>

            <nz-card id="st17" [ngClass]="statusCardDec ? 'card-fullscreen' : 'ant-card-table'"
                     nzTitle="Configurações DFE" [nzExtra]="extraConfigura" [nzLoading]="loadingsConfigDFE">
                <ng-template #extraConfigura>
                    <button nz-button nzType="primary"
                            (click)="showModal(formConfiguracoesDFE)">
                        Alterar
                    </button>
                </ng-template>
                <div style="padding: 10px 30px">
                    <nz-list id="configuracoesDFE">
                        <nz-list-item>
                            <nz-list-item-meta nzDescription="Ativa DFE">
                                <nz-list-item-meta-title>
                                    {{ configuracoesDFE.ativo ? 'SIM' : 'NÃO' }}
                                </nz-list-item-meta-title>
                            </nz-list-item-meta>
                        </nz-list-item>
                        <nz-list-item>
                            <nz-list-item-meta nzDescription="Intervalo Consulta">
                                <nz-list-item-meta-title
                                        *ngIf="configuracoesDFE.horarioInicio else semIntervaloInformado">
                                    {{ configuracoesDFE.horarioInicio || '-' }}
                                    às
                                    {{ configuracoesDFE.horarioFim || '-' }}
                                </nz-list-item-meta-title>
                                <ng-template #semIntervaloInformado>
                                    <nz-list-item-meta-title>
                                        -
                                    </nz-list-item-meta-title>
                                </ng-template>
                            </nz-list-item-meta>
                        </nz-list-item>
                    </nz-list>
                </div>

            </nz-card>
            <nz-card id="st20" nzTitle="Configurações avançadas do Gestor DFE">
                <nz-list id="configuracoesGestorDFE">
                    <nz-list-item>
                        <nz-list-item-meta>
                            <nz-list-item-meta-title>
                                <button nz-button nzType="link"
                                        (click)="openTabEmbedded('/configuracoes/gestor/', empresa.id, {id: empresa.id, cnpj: empresa.cnpj, link: '/auditor/configuracoes/conta/&conta_id=' + empresa.cnpj + '&tipo=gestor', extra:''}, false, ' - Configuração Gestor')">
                                    Editar configurações
                                </button>
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                    </nz-list-item>
                </nz-list>
            </nz-card>

        </div>
    </div>

</nz-content>
<nz-modal [(nzVisible)]="formDadosCadastrais.modalVisible" [nzTitle]="'Alterar Dados Pessoais'" [nzClosable]="true"
          (nzOnCancel)="fechar(formDadosCadastrais)">
    <div nz-row *nzModalContent>
        <form nz-form [nzAutoTips]="autoTips" [nzNoColon]="true" [formGroup]="formDadosCadastrais.formGroup">
            <nz-form-item>
                <nz-form-label [nzSpan]="8">Nome Amigável</nz-form-label>
                <nz-form-control [nzSpan]="15">
                    <input nz-input type="text" formControlName="nome" size="100">
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="8">Código</nz-form-label>
                <nz-form-control [nzSpan]="15">
                    <input nz-input type="text" formControlName="codigo" maxlength="20"/>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="8" nzRequired>Status</nz-form-label>
                <nz-form-control [nzSpan]="15">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="ativo">
                        <nz-option [nzLabel]="'Ativo'" [nzValue]="1"></nz-option>
                        <nz-option [nzLabel]="'Inativo'" [nzValue]="0"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="8" nzRequired>CNPJ</nz-form-label>
                <nz-form-control [nzSpan]="15">
                    <input nz-input type="text" formControlName="cnpj" mask="00.000.000/0000-00" size="18"/>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="8" nzRequired>Atividade</nz-form-label>
                <nz-form-control [nzSpan]="15">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="atividade">
                        <nz-option nzLabel="Indústria" nzValue="Indústria"></nz-option>
                        <nz-option nzLabel="Varejo" nzValue="Varejo"></nz-option>
                        <nz-option nzLabel="Comércio" nzValue="Comércio"></nz-option>
                        <nz-option nzLabel="Serviços" nzValue="Serviços"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="8">Razão Social</nz-form-label>
                <nz-form-control [nzSpan]="15">
                    <input nz-input type="text" formControlName="razao" size="100">
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="fechar(formDadosCadastrais)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="gravando" (click)="confirmar(formDadosCadastrais)">Confirmar
        </button>
    </div>
</nz-modal>
<nz-modal [(nzVisible)]="formEndereco.modalVisible" [nzTitle]="'Alterar Endereço'" [nzClosable]="true"
          (nzOnCancel)="fechar(formEndereco)">
    <div *nzModalContent>
        <form nz-form [nzAutoTips]="autoTips" [nzNoColon]="true" [formGroup]="formEndereco.formGroup">
            <nz-form-item>
                <nz-form-label [nzSpan]="8">Endereço</nz-form-label>
                <nz-form-control [nzSpan]="15">
                    <input nz-input type="text" formControlName="endereco" size="60"/>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="8" nzFor="primeiroNome">Número/Complemento</nz-form-label>
                <nz-form-control [nzSpan]="7">
                    <input nz-input type="text" formControlName="numero" placeholder="número"/>
                </nz-form-control>
                &nbsp;
                <nz-form-control [nzSpan]="8">
                    <input nz-input type="text" formControlName="complemento" placeholder="complemento"/>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="8">Bairro</nz-form-label>
                <nz-form-control [nzSpan]="15">
                    <input nz-input type="text" formControlName="bairro" size="60"/>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="8">Cidade</nz-form-label>
                <nz-form-control [nzSpan]="15">
                    <input nz-input type="text" formControlName="cidade" size="60"/>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="8">Estado</nz-form-label>
                <nz-form-control [nzSpan]="4">
                    <input nz-input type="text" formControlName="estado" maxlength="2"/>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="8">CEP</nz-form-label>
                <nz-form-control [nzSpan]="6">
                    <input nz-input type="text" formControlName="cep" size="9" mask="00000-000"/>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="fechar(formEndereco)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="gravando" (click)="confirmar(formEndereco)">Confirmar</button>
    </div>
</nz-modal>
<nz-modal [(nzVisible)]="formGrupoEmpresarial.modalVisible" [nzTitle]="'Alterar Grupo Empresarial'" [nzClosable]="true"
          (nzOnCancel)="fechar(formGrupoEmpresarial)">
    <div *nzModalContent>
        <form nz-form [nzAutoTips]="autoTips" [nzNoColon]="true" [formGroup]="formGrupoEmpresarial.formGroup">
            <nz-form-item>
                <nz-form-label [nzSpan]="8">Grupo Empresarial</nz-form-label>
                <nz-form-control [nzSpan]="12">
                    <nz-select formControlName="grupoEmpresarial_id" placeholder="selecione..."
                               [nzPlaceHolder]="'Selecione'" [nzAllowClear]="true">
                        <nz-option *ngFor="let option of grupoEmpresarialOptions" [nzLabel]="modoDemo(option.label)"
                                   [nzValue]="option.value"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="fechar(formGrupoEmpresarial)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="gravando" (click)="confirmar(formGrupoEmpresarial)">Confirmar
        </button>
    </div>
</nz-modal>
<nz-modal [(nzVisible)]="formVisibilidade.modalVisible" [nzTitle]="'Alterar Visibilidade'" [nzClosable]="true"
          (nzOnCancel)="fechar(formVisibilidade)">
    <div *nzModalContent>
        <form nz-form [nzAutoTips]="autoTips" [nzNoColon]="true" [formGroup]="formVisibilidade.formGroup">
            <nz-form-item>
                <nz-form-label [nzSpan]="8">Visibilidade</nz-form-label>
                <nz-form-control [nzSpan]="12">
                    <nz-select formControlName="visibilidade_id" placeholder="selecione..."
                               [nzPlaceHolder]="'Selecione'" [nzAllowClear]="true">
                        <nz-option *ngFor="let option of visibilidadeOptions" [nzLabel]="option.label"
                                   [nzValue]="option.value"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="fechar(formVisibilidade)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="gravando" (click)="confirmar(formVisibilidade)">Confirmar
        </button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formCertificadoA1.modalVisible"
          [nzTitle]="'Alterar Certificado A1'" [nzClosable]="true"
          (nzOnCancel)="modal(formCertificadoA1, false)"
          [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzNoColon]="true" [formGroup]="formCertificadoA1.formGroup">
            <nz-form-item>
                <nz-form-label [nzSpan]="10">Emissor</nz-form-label>
                <nz-form-control [nzSpan]="13">
                    <input nz-input type="text" formControlName="emissor" maxlength="20" size="60"/>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="10">Categoria</nz-form-label>
                <nz-form-control [nzSpan]="13">
                    <input nz-input type="text" formControlName="categoria" maxlength="20" size="60"/>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="10">Situação</nz-form-label>
                <nz-form-control [nzSpan]="13">
                    <input nz-input type="text" formControlName="situacao" maxlength="20" size="60"/>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="10">Validade</nz-form-label>
                <nz-form-control [nzSpan]="13">
                    <input nz-input type="text" formControlName="validade" maxlength="20" size="60"/>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modal(formCertificadoA1, false);">Cancelar</button>
        <button nz-button nzType="primary">Confirmar</button>
    </div>
</nz-modal>

<app-modal-credencial-de-acesso #modalCredencialDeAcesso
                                (concluiuOperacao)="listarCertificados(currentParams, currentSearch)"
                                (fecharModal)="stopAssist()"
                                [data]="data"></app-modal-credencial-de-acesso>

<nz-modal [(nzVisible)]="formAcessosEstaduais.modalVisible"
          [nzTitle]="editando.acessos ? 'Alterar Acesso Estadual' : 'Cadastrar Acesso Estadual'"
          [nzClosable]="true"
          (nzOnCancel)="modalAcesso(false)"
          [nzWidth]="648">
    <div nz-row *nzModalContent>
        <nz-skeleton *ngIf="loadings.formAcessos"></nz-skeleton>
        <form nz-form [nzNoColon]="true" [formGroup]="formAcessosEstaduais.formGroup" style="width: 100%;"
              *ngIf="!loadings.formAcessos"
        >
            <nz-form-item>
                <nz-form-label [nzSpan]="10" nzRequired>UF</nz-form-label>
                <nz-form-control [nzSpan]="13">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="uf"
                               (ngModelChange)="filtrarPortais(formAcessosEstaduais.formGroup.value.uf)">

                        <nz-option *ngFor="let opt of comboUFs"
                                   [nzLabel]="opt.label"
                                   [nzValue]="opt.key">
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="10" nzRequired>Portal</nz-form-label>
                <nz-form-control [nzSpan]="13">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="portal"
                               (ngModelChange)="filtrarCampos(formAcessosEstaduais.formGroup.value.portal);">
                        <nz-option *ngFor="let opt of comboObrigacoes"
                                   [nzLabel]="opt.label"
                                   [nzValue]="opt.key">
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-content *ngIf="camposFormDataPrev.length > 0">
                <nz-form-item *ngFor="let item of camposFormDataPrev">
                    <nz-form-label [nzSpan]="10" *ngIf="!loadings.formAcessos">{{ item.label }}:</nz-form-label>
                    <nz-form-control [nzSpan]="13" *ngIf="item.valores.length > 0 && !loadings.formAcessos">
                        <nz-select nzShowSearch nzAllowClear
                                   [nzPlaceHolder]="'Selecione'" formControlName="{{item.label}}">
                            <nz-option *ngFor="let opt of item.valores"
                                       [nzLabel]="opt"
                                       [nzValue]="opt.trim()">
                            </nz-option>
                        </nz-select>
                    </nz-form-control>
                    <nz-form-control [nzSpan]="13" *ngIf="item.valores.length === 0 && !loadings.formAcessos">
                        <input appCnpjMask *ngIf="item.label === 'cnpj' else semFormatacao" nz-input type="text"
                               formControlName="{{item.label}}"/>
                        <ng-template #semFormatacao>
                            <input nz-input type="text" formControlName="{{item.label}}"/>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
            </nz-content>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalAcesso(false);">Cancelar</button>
        <button [nzLoading]="loadings.acessos" nz-button nzType="primary" *ngIf="!editando.acessos"
                (click)="insertAcessos();">Confirmar
        </button>
        <button [nzLoading]="loadings.acessos" nz-button nzType="primary" *ngIf="editando.acessos"
                (click)="updateAcessos();">Confirmar
        </button>
    </div>
</nz-modal>
<nz-modal [(nzVisible)]="formIf.modalVisible"
          [nzTitle]="editandoIf ? 'Alterar Inscrição Federal' : 'Cadastrar Inscrição Federal'" [nzClosable]="true"
          (nzOnCancel)="fechar(formIf)" [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzAutoTips]="autoTips" [nzNoColon]="true" [formGroup]="formIf.formGroup">
            <nz-form-item>
                <nz-form-label [nzSpan]="8" nzRequired>Documento</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <input nz-input
                           formControlName="documento" type="text"
                           autocomplete="off" size="60" mask="00.000.000/0000-00"/>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="8" nzRequired>Início</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-date-picker [nzFormat]="'dd/MM/yyyy'"
                                    formControlName="dataInicio" style="width: 100%;"></nz-date-picker>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="8">Fim</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-date-picker [nzFormat]="'dd/MM/yyyy'"
                                    formControlName="dataFim" style="width: 100%;"></nz-date-picker>
                </nz-form-control>
            </nz-form-item>

        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="fechar(formIf)">Cancelar</button>
        <button *ngIf="!editandoIf" nz-button nzType="primary" [nzLoading]="gravando" (click)="insertIf()">
            Inserir
        </button>
        <button *ngIf="editandoIf" nz-button nzType="primary" [nzLoading]="gravando" (click)="updateIf()">
            Alterar
        </button>
    </div>
</nz-modal>
<nz-modal [(nzVisible)]="formIe.modalVisible"
          [nzTitle]="editandoIe ? 'Alterar Inscrição Estadual' : 'Cadastrar Inscrição Estadual'" [nzClosable]="true"
          (nzOnCancel)="fechar(formIe)" [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzAutoTips]="autoTips" [nzNoColon]="true" [formGroup]="formIe.formGroup" style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="8">Isento?</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <label nz-checkbox formControlName="isento">
                        Sim
                    </label>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item *ngIf="!formIe.formGroup.value.isento">
                <nz-form-label [nzSpan]="8" nzRequired>IE</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <input nz-input
                           formControlName="ie" type="text"
                           autocomplete="off" size="60">
                </nz-form-control>
            </nz-form-item>
            <nz-form-item *ngIf="!formIe.formGroup.value.isento">
                <nz-form-label [nzSpan]="8" nzRequired>Tipo</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="tipo">
                        <nz-option [nzLabel]="'Normal'" [nzValue]="'Normal'"></nz-option>
                        <nz-option [nzLabel]="'Substituta'" [nzValue]="'Substituta'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item *ngIf="!formIe.formGroup.value.isento">
                <nz-form-label [nzSpan]="8" nzRequired>UF</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="uf">
                        <nz-option *ngFor="let opt of comboUFs"
                                   [nzLabel]="opt.label"
                                   [nzValue]="opt.key">
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item *ngIf="!formIe.formGroup.value.isento">
                <nz-form-label [nzSpan]="8" nzRequired>Situação</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="situacaoCadastral">
                        <!--                        <nz-option [nzLabel]="'Nula'" [nzValue]="'Nula'"></nz-option>-->
                        <nz-option [nzLabel]="'Ativa'" [nzValue]="'Ativo'"></nz-option>
                        <nz-option [nzLabel]="'Inativa'" [nzValue]="'Inativo'"></nz-option>
                        <!--                        <nz-option [nzLabel]="'Suspensa'" [nzValue]="'Suspensa'"></nz-option>-->
                        <!--                        <nz-option [nzLabel]="'Inapta'" [nzValue]="'Inapta'"></nz-option>-->
                        <!--                        <nz-option [nzLabel]="'Baixada'" [nzValue]="'Baixada'"></nz-option>-->
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item *ngIf="!formIe.formGroup.value.isento">
                <nz-form-label [nzSpan]="8" nzRequired>Início</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-date-picker [nzFormat]="'dd/MM/yyyy'"
                                    formControlName="dataInicio" style="width: 100%;">
                    </nz-date-picker>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item *ngIf="!formIe.formGroup.value.isento">
                <nz-form-label [nzSpan]="8">Fim</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-date-picker [nzFormat]="'dd/MM/yyyy'"
                                    formControlName="dataFim" style="width: 100%;"></nz-date-picker>
                </nz-form-control>
            </nz-form-item>

        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="fechar(formIe)">Cancelar</button>
        <button *ngIf="!editandoIe" nz-button nzType="primary" [nzLoading]="gravando" (click)="insertIe()">
            Inserir
        </button>
        <button *ngIf="editandoIe" nz-button nzType="primary" [nzLoading]="gravando" (click)="updateIe()">
            Alterar
        </button>
    </div>
</nz-modal>
<nz-modal [(nzVisible)]="formIm.modalVisible"
          [nzTitle]="editandoIm ? 'Alterar Inscrição Municipal' : 'Cadastrar Inscrição Municipal'" [nzClosable]="true"
          (nzOnCancel)="fechar(formIm)" [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzAutoTips]="autoTips" [nzNoColon]="true" [formGroup]="formIm.formGroup" style="width: 100%;">

            <nz-form-item>
                <nz-form-label [nzSpan]="8" nzRequired>IM</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <input nz-input
                           formControlName="im" type="text"
                           autocomplete="off" size="60">
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="8">UF</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="uf"
                               (ngModelChange)="filtraComboMunicipio(formIm.formGroup.value.uf)">
                        <nz-option *ngFor="let opt of comboUFs"
                                   [nzLabel]="opt.label"
                                   [nzValue]="opt.key">
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="8" nzRequired>Município</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="municipioCodigo">
                        <nz-option *ngFor="let opt of comboMunicipio"
                                   [nzLabel]="opt.label"
                                   [nzValue]="opt.key">
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="8" nzRequired>Situação</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="situacaoCadastral">
                        <nz-option [nzLabel]="'Nula'" [nzValue]="'Nulo'"></nz-option>
                        <nz-option [nzLabel]="'Ativa'" [nzValue]="'Ativo'"></nz-option>
                        <nz-option [nzLabel]="'Suspensa'" [nzValue]="'Suspenso'"></nz-option>
                        <nz-option [nzLabel]="'Inapta'" [nzValue]="'Inapto'"></nz-option>
                        <nz-option [nzLabel]="'Baixada'" [nzValue]="'Baixado'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="8" nzRequired>Início</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-date-picker [nzFormat]="'dd/MM/yyyy'"
                                    formControlName="dataInicio" style="width: 100%;"></nz-date-picker>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="8">Fim</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-date-picker [nzFormat]="'dd/MM/yyyy'"
                                    formControlName="dataFim" style="width: 100%;"></nz-date-picker>
                </nz-form-control>
            </nz-form-item>

        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="fechar(formIm)">Cancelar</button>
        <button *ngIf="!editandoIm" nz-button nzType="primary" [nzLoading]="gravando" (click)="insertIm();">
            Inserir
        </button>
        <button *ngIf="editandoIm" nz-button nzType="primary" [nzLoading]="gravando" (click)="updateIm();">
            Alterar
        </button>
    </div>
</nz-modal>


<nz-modal [(nzVisible)]="formDadosCnd.modalVisible" [nzTitle]="'Cadastrar Nova CND'" [nzClosable]="true"
          (nzOnCancel)="modalCadastrarCnd(false)" nzWidth="648px">
    <div *nzModalContent>
        <form nz-form [nzDisableAutoTips]="true" [nzNoColon]="true" [formGroup]="formDadosCnd.formGroup">
            <nz-form-item>
                <nz-form-label [nzSpan]="6">UF</nz-form-label>
                <nz-form-control [nzSpan]="12">
                    <nz-select formControlName="uf" nzShowSearch nzAllowClear
                               (ngModelChange)="changeSelectCndsUF($event)">
                        <nz-option [nzLabel]="'Sem UF'" [nzValue]="null"></nz-option>
                        <nz-option *ngFor="let opt of comboUFs" [nzLabel]="opt.label" [nzValue]="opt.key"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="6">Municipio</nz-form-label>
                <nz-form-control [nzSpan]="12">
                    <nz-select formControlName="municipio" nzShowSearch nzAllowClear
                               (ngModelChange)="changeSelectCndsMunicipio($event)">
                        <nz-option [nzLabel]="'Sem UF'" [nzValue]="null"></nz-option>
                        <nz-option *ngFor="let opt of comboMunicipio" [nzLabel]="opt.label"
                                   [nzValue]="opt.key"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="6" nzRequired>Descrição</nz-form-label>
                <nz-form-control [nzSpan]="12">
                    <nz-select formControlName="certidao_id" nzShowSearch>
                        <nz-option *ngFor="let opt of comboCnd" [nzLabel]="opt.label" [nzValue]="opt.key"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="6" nzRequired>Data Início</nz-form-label>
                <nz-form-control [nzSpan]="12">
                    <nz-date-picker [nzFormat]="'dd/MM/yyyy'"
                                    formControlName="dataInicio" style="width: 100%;"></nz-date-picker>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="6">Data Fim</nz-form-label>
                <nz-form-control [nzSpan]="12">
                    <nz-date-picker [nzFormat]="'dd/MM/yyyy'"
                                    formControlName="dataFim" style="width: 100%;"></nz-date-picker>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalCadastrarCnd(false)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="cadastrando" (click)="confirmaCadastroCnd()">Confirmar</button>
    </div>
</nz-modal>
<nz-modal [(nzVisible)]="formFiltrarCnd.modalVisible" [nzTitle]="'Filtros Avançados'" [nzClosable]="true"
          (nzOnCancel)="modalFiltrarCnd(false)" [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzDisableAutoTips]="true" [nzNoColon]="true" [formGroup]="formFiltrarCnd.formGroup"
              style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="6">Certidão</nz-form-label>
                <nz-form-control [nzSpan]="14"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrarCnd.formGroup.value.certidao">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="certidao">
                        <nz-option *ngFor="let opt of comboCertidoes"
                                   [nzLabel]="opt.label" [nzValue]="opt.key">
                        </nz-option>

                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="6">Data Início</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-date-picker style="width: 100%;"
                                    formControlName="dataInicio"
                                    nzFormat="dd/MM/yyyy"></nz-date-picker>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="6">Data Fim</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-date-picker style="width: 100%;"
                                    formControlName="dataFim"
                                    nzFormat="dd/MM/yyyy"></nz-date-picker>
                </nz-form-control>
            </nz-form-item>


        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalFiltrarCnd(false)">Cancelar</button>
        <button nz-button nzType="primary" (click)="confirmaFiltrarCnd()">Confirmar</button>
    </div>
</nz-modal>
<nz-modal [(nzVisible)]="formEditarDatas.modalVisible"
          [nzTitle]="'Editar Datas'" [nzClosable]="true"
          (nzOnCancel)="modalEditarCndMassa(false)"
          [nzWidth]="648">
    <nz-row *nzModalContent>
        <nz-col nzSpan="24">
            <form nz-form [nzNoColon]="true" [formGroup]="formEditarDatas.formGroup"
                  *ngIf="formEditarDatas.modalVisible">
                <nz-form-item>
                    <nz-form-label [nzSpan]="6">Data Início</nz-form-label>
                    <nz-form-control [nzSpan]="12">
                        <nz-date-picker style="width: 100%;"
                                        formControlName="dataInicio"
                                        nzFormat="dd/MM/yyyy"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="6">Data Fim</nz-form-label>
                    <nz-form-control [nzSpan]="12">
                        <nz-date-picker style="width: 100%;"
                                        formControlName="dataFim"
                                        nzFormat="dd/MM/yyyy"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
            </form>
        </nz-col>
    </nz-row>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalEditarCndMassa(false)">Cancelar</button>
        <button nz-button nzType="primary" (click)="editarVencimentosMassa()">Editar</button>
    </div>
</nz-modal>
<nz-modal [(nzVisible)]="formEditarCnd.modalVisible" [nzTitle]="'Alterar CND'" [nzClosable]="true"
          (nzOnCancel)="fechar(formEditarCnd)">
    <div *nzModalContent>
        <nz-skeleton *ngIf="loadings.getCnd"></nz-skeleton>
        <form nz-form [nzAutoTips]="autoTips" [nzNoColon]="true" [formGroup]="formEditarCnd.formGroup"
              style="width: 100%;" *ngIf="!loadings.getCnd">
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Descrição</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <input nz-input type="text" formControlName="descricao" disabled>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5" nzRequired>Data Início</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-date-picker [nzFormat]="'dd/MM/yyyy'"
                                    formControlName="dataInicio" style="width: 100%;"></nz-date-picker>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Data Fim</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-date-picker [nzFormat]="'dd/MM/yyyy'"
                                    formControlName="dataFim" style="width: 100%;"></nz-date-picker>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="fechar(formEditarCnd)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="loadings.updateCnd" (click)="confirmarEditCnd()">Confirmar
        </button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formDadosDec.modalVisible" [nzTitle]="'Cadastrar Nova DEC'" [nzClosable]="true"
          (nzOnCancel)="modalCadastrarDec(false)" nzWidth="648px">
    <div *nzModalContent>
        <form nz-form [nzDisableAutoTips]="true" [nzNoColon]="true" [formGroup]="formDadosDec.formGroup">
            <nz-form-item>
                <nz-form-label [nzSpan]="6">Domicílio Eletônico</nz-form-label>
                <nz-form-control [nzSpan]="12">
                    <nz-select formControlName="domicilioEletronico_id" nzShowSearch>
                        <nz-option *ngFor="let opt of comboDomicilios" [nzLabel]="opt.label"
                                   [nzValue]="opt.key"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="6" nzRequired>Data Início</nz-form-label>
                <nz-form-control [nzSpan]="12" nzErrorTip="Data Início Obrigatório">
                    <nz-date-picker style="width: 100%;"
                                    formControlName="dataInicio"
                                    nzFormat="dd/MM/yyyy"></nz-date-picker>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="6">Data Fim</nz-form-label>
                <nz-form-control [nzSpan]="12">
                    <nz-date-picker style="width: 100%;"
                                    formControlName="dataFim"
                                    nzFormat="dd/MM/yyyy"></nz-date-picker>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalCadastrarDec(false)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="cadastrando" (click)="confirmaCadastroDec()">Confirmar</button>
    </div>
</nz-modal>
<nz-modal [(nzVisible)]="formFiltrarDec.modalVisible" [nzTitle]="'Filtros Avançados'" [nzClosable]="true"
          (nzOnCancel)="modalFiltrarDec(false)" [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzDisableAutoTips]="true" [nzNoColon]="true" [formGroup]="formFiltrarDec.formGroup"
              style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="6">Domicilio</nz-form-label>
                <nz-form-control [nzSpan]="14"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrarDec.formGroup.value.domicilioEletronico">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="domicilioEletronico">
                        <nz-option *ngFor="let opt of comboDomicilios"
                                   [nzLabel]="opt.label" [nzValue]="opt.key">
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="6">Data Início</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-date-picker style="width: 100%;"
                                    formControlName="dataInicio"
                                    nzFormat="dd/MM/yyyy"></nz-date-picker>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="6">Data Fim</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-date-picker style="width: 100%;"
                                    formControlName="dataFim"
                                    nzFormat="dd/MM/yyyy"></nz-date-picker>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalFiltrarDec(false)">Cancelar</button>
        <button nz-button nzType="primary" (click)="confirmaFiltrarDec()">Confirmar</button>
    </div>
</nz-modal>
<nz-modal [(nzVisible)]="formEditarDatasDec.modalVisible"
          [nzTitle]="'Editar Datas'" [nzClosable]="true"
          (nzOnCancel)="modalEditarDecMassa(false)"
          [nzWidth]="648">
    <nz-row *nzModalContent>
        <nz-col nzSpan="24">
            <form nz-form [nzNoColon]="true" [formGroup]="formEditarDatasDec.formGroup"
                  *ngIf="formEditarDatasDec.modalVisible">
                <nz-form-item>
                    <nz-form-label [nzSpan]="6">Data Início</nz-form-label>
                    <nz-form-control [nzSpan]="12">
                        <nz-date-picker style="width: 100%;"
                                        formControlName="dataInicio"
                                        nzFormat="dd/MM/yyyy"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="6">Data Fim</nz-form-label>
                    <nz-form-control [nzSpan]="12">
                        <nz-date-picker style="width: 100%;"
                                        formControlName="dataFim"
                                        nzFormat="dd/MM/yyyy"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
            </form>
        </nz-col>
    </nz-row>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalEditarDecMassa(false)">Cancelar</button>
        <button nz-button nzType="primary" (click)="editarVencimentosDecMassa()">Editar</button>
    </div>
</nz-modal>
<nz-modal [(nzVisible)]="formEditarDec.modalVisible" [nzTitle]="'Alterar DEC'" [nzClosable]="true"
          (nzOnCancel)="fechar(formEditarDec)">
    <div *nzModalContent>
        <nz-skeleton *ngIf="loadings.getDec"></nz-skeleton>
        <form nz-form [nzAutoTips]="autoTips" [nzNoColon]="true" [formGroup]="formEditarDec.formGroup"
              style="width: 100%;" *ngIf="!loadings.getDec">
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Descrição</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <input nz-input type="text" formControlName="descricao" disabled>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5" nzRequired>Data Início</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-date-picker [nzFormat]="'dd/MM/yyyy'"
                                    formControlName="dataInicio" style="width: 100%;">
                    </nz-date-picker>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Data Fim</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-date-picker [nzFormat]="'dd/MM/yyyy'"
                                    formControlName="dataFim" style="width: 100%;">
                    </nz-date-picker>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="fechar(formEditarDec)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="loadings.updateCnd" (click)="confirmarEditDec()">Confirmar
        </button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formConfiguracoesDFE.modalVisible"
          [nzTitle]="'Alterar Configurações DFE'" [nzClosable]="true"
          (nzOnCancel)="showModal(formConfiguracoesDFE)" [nzWidth]="748">
    <div nz-row *nzModalContent>
        <form nz-form [nzAutoTips]="autoTips" [nzNoColon]="true" [formGroup]="formConfiguracoesDFE.formGroup"
              style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="6">Ativa DFE</nz-form-label>
                <nz-form-control [nzSpan]="15"
                                 [nzHasFeedback]="formConfiguracoesDFE.formGroup.value.ativo">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="ativo">
                        <nz-option [nzLabel]="'SIM'" [nzValue]="1"></nz-option>
                        <nz-option [nzLabel]="'NÃO'" [nzValue]="0"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <div nz-row>
                <nz-form-label nz-col [nzSpan]="6">Intervalo Consulta DFE</nz-form-label>
                <nz-form-item nz-col [nzSpan]="7">
                    <nz-form-control [nzHasFeedback]="formConfiguracoesDFE.formGroup.value.horarioInicio">
                        <nz-time-picker style="width: 100%;"
                                        formControlName="horarioInicio"
                                        nzFormat="HH:mm"
                                        nzPlaceHolder="Até:">
                        </nz-time-picker>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item nz-col [nzSpan]="1"></nz-form-item>
                <nz-form-item nz-col [nzSpan]="7">
                    <nz-form-control [nzHasFeedback]="formConfiguracoesDFE.formGroup.value.horarioFim">
                        <nz-time-picker style="width: 100%;"
                                        formControlName="horarioFim"
                                        nzFormat="HH:mm"
                                        nzPlaceHolder="Até:">
                        </nz-time-picker>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="showModal(formConfiguracoesDFE)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="this.loadingsConfigDFE" (click)="confirmaAlterarDFE()">
            Alterar
        </button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formImobiliaria.modalVisible"
          [nzTitle]="editandoImobiliaria ? 'Alterar Inscrição Imobiliária' : 'Cadastrar Inscrição Imobiliária'"
          [nzClosable]="true"
          (nzOnCancel)="fechar(formImobiliaria)" [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzAutoTips]="autoTips" [nzNoColon]="true" [formGroup]="formImobiliaria.formGroup"
              style="width: 100%;">

            <nz-form-item>
                <nz-form-label [nzSpan]="8" nzRequired>Inscrição</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <input nz-input
                           formControlName="ii"
                           (keydown)="keyPressNoSpecialChar($event)"
                           (keyup)="charUnidentified('ii')"
                           autocomplete="off" size="60">
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label nzRequired [nzSpan]="8">UF</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="uf"
                               (ngModelChange)="filtraComboMunicipio(formImobiliaria.formGroup.value.uf)">
                        <nz-option *ngFor="let opt of comboUFs"
                                   [nzLabel]="opt.label"
                                   [nzValue]="opt.key">
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="8" nzRequired>Município</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-select nzShowSearch nzAllowClear
                               (ngModelChange)="changeMunicipio()"
                               [nzPlaceHolder]="'Selecione'" formControlName="municipioCodigo">
                        <nz-option *ngFor="let opt of comboMunicipio"
                                   [nzLabel]="opt.label"
                                   [nzValue]="opt.key">
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="8" nzRequired>Situação</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="situacaoCadastral">
                        <nz-option [nzLabel]="'Cancelada'" [nzValue]="'Cancelada'"></nz-option>
                        <nz-option [nzLabel]="'Ativa'" [nzValue]="'Ativo'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="8" nzRequired>Início</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-date-picker (keyup)="maskNZDatePicker($event)" [nzFormat]="'dd/MM/yyyy'"
                                    formControlName="dataInicio" style="width: 100%;"></nz-date-picker>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item *ngIf="formImobiliaria.formGroup.get('municipioCodigo').value === '3556206'">
                <nz-form-label nzRequired [nzSpan]="8">Setor</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <input nz-input
                           type="number"
                           formControlName="setor"
                           autocomplete="off">
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="fechar(formImobiliaria)">Cancelar</button>
        <button *ngIf="!editandoImobiliaria" nz-button nzType="primary" [nzLoading]="loadings.gravandoII"
                (click)="insertImobiliaria();">
            Inserir
        </button>
        <button *ngIf="editandoImobiliaria" nz-button nzType="primary" [nzLoading]="loadings.gravandoII"
                (click)="updateImobiliarias();">
            Alterar
        </button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formRegime.modalVisible"
          [nzTitle]="editandoRegime ? 'Alterar Regime Tributário' : 'Cadastrar Regime Tributário'" [nzClosable]="true"
          (nzOnCancel)="fechar(formRegime)" [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzAutoTips]="autoTips" [nzNoColon]="true" [formGroup]="formRegime.formGroup"
              style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="8" nzRequired>Regime Tributário</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="regimeTributario_id">
                        <nz-option *ngFor="let opt of comboRegimes"
                                   [nzLabel]="opt.descricao"
                                   [nzValue]="opt.id"
                        >
                        </nz-option>

                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="8" nzRequired>Início</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-date-picker (keyup)="maskNZDatePicker($event)" [nzFormat]="'dd/MM/yyyy'"
                                    formControlName="dataInicio" style="width: 100%;"></nz-date-picker>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="8">Fim</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-date-picker (keyup)="maskNZDatePicker($event)" [nzFormat]="'dd/MM/yyyy'"
                                    formControlName="dataFim" style="width: 100%;"></nz-date-picker>
                </nz-form-control>
            </nz-form-item>

        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="fechar(formRegime)">Cancelar</button>
        <button *ngIf="!editandoRegime" nz-button nzType="primary" [nzLoading]="loadings.gravandoRegime"
                (click)="insertRegime()">
            Inserir
        </button>
        <button *ngIf="editandoRegime" nz-button nzType="primary" [nzLoading]="loadings.gravandoRegime"
                (click)="updateRegime()">
            Alterar
        </button>
    </div>
</nz-modal>


<nz-modal [(nzVisible)]="formCadastrarEmMassa.modalVisible"
          [nzTitle]="'Cadastrar Obrigações Sugeridas em Massa'" [nzClosable]="true"
          (nzOnCancel)="modalCadastrarEmMassa(false);"
          [nzWidth]="968">
    <div *nzModalContent>
        <div style="border-bottom: 1px rgba(0,0,0,0.2) solid;">
            <nz-steps nzDirection="vertical">
                <nz-step *ngIf="steps[1].step === 'process'"
                         nzTitle="Selecionar Módulos:"
                         nzSubtitle="Selecione os módulos que deseja cadastrar as Obrigações."
                         nzStatus="{{steps[1].step}}"
                         nzIcon="{{steps[1].icon}}"></nz-step>
                <nz-step *ngIf="steps[1].step === 'finish'"
                         nzTitle="Selecionar Obrigações:"
                         nzSubtitle="Selecione as Obrigações a serem cadastradas."
                         nzStatus="{{steps[2].step}}"
                         nzIcon="{{steps[2].icon}}"></nz-step>
                <nz-step *ngIf="steps[2].step === 'finish'"
                         nzTitle="Cadastro Finalizado"
                         nzStatus="{{steps[3].step}}"
                         nzIcon="{{steps[3].icon}}"></nz-step>
            </nz-steps>
        </div>
        <form nz-form [nzNoColon]="false" [formGroup]="formStepsObrigacoes.formGroup">
            <nz-content *ngIf="steps[0].step === 'finish' && steps[1].step !== 'finish'" style="margin: 40px 0 40px 0;">
                <nz-row nzGutter="24">
                    <nz-col nzSpan="24">
                        <nz-checkbox-wrapper style="width: 100%;" (nzOnChange)="selecionarModulos($event)">
                            <div nz-row>
                                <div nz-col nzSpan="8">
                                    <label nz-checkbox nzValue="calendar" [(ngModel)]="modelCalendar"
                                           [ngModelOptions]="{standalone: true}">Calendar</label>
                                </div>
                            </div>
                            <div nz-row>
                                <div nz-col nzSpan="8">
                                    <label nz-checkbox nzValue="check" [(ngModel)]="modelCheck"
                                           [ngModelOptions]="{standalone: true}">Check</label>
                                </div>
                            </div>
                            <div nz-row>
                                <div nz-col nzSpan="8">
                                    <label nz-checkbox nzValue="compliance" [(ngModel)]="modelCompliance"
                                           [ngModelOptions]="{standalone: true}">Compliance</label>
                                </div>
                            </div>
                        </nz-checkbox-wrapper>
                    </nz-col>
                </nz-row>
            </nz-content>
            <nz-content *ngIf="steps[1].step === 'finish' && steps[2].step !== 'finish'" style="margin: 20px 0 40px 0;">
                <nz-row nzGutter="24" style="margin-bottom: 20px;">
                    <nz-col nzSpan="24">
                        <nz-radio-group [(ngModel)]="radioValueStepOne" [ngModelOptions]="{standalone: true}">
                            <label nz-radio nzValue="todas">Todas as Obrigações</label>
                            <label nz-radio nzValue="algumas">Algumas Obrigações</label>
                        </nz-radio-group>
                    </nz-col>
                </nz-row>
                <nz-row nzGutter="24" *ngIf="radioValueStepOne === 'algumas'">
                    <nz-col nzSpan="24">
                        <nz-alert nzType="info" nzShowIcon [nzMessage]="checkedMessageObrigacoes"
                                  style="margin: 15px !important;"
                                  *ngIf="checkedItemsObrigacoes.size > 0">
                            <ng-template #checkedMessageObrigacoes>
                                <div nz-row nzAlign="middle" nzGutter="24">
                                    <div nz-col nzSpan="24">
                                        <strong class="text-primary"></strong>
                                        {{ checkedItemsObrigacoes.size }} Registros Selecionados
                                    </div>
                                </div>
                            </ng-template>
                        </nz-alert>
                        <nz-table #tableObrigacoes
                                  [nzData]="previaObrigacoes"
                                  [nzFrontPagination]="false"
                                  [nzTotal]="paginationObrigcoes?.total"
                                  [nzPageSize]="paginationObrigcoes?.per_page"
                                  [nzPageIndex]="paginationObrigcoes?.current_page"
                                  (nzQueryParams)="retornaObrigacoesEmpresasSteps($event)"
                                  [nzShowTotal]="rangeTemplateObrigacao"
                                  [nzLoading]="loadings.previaObrigacoes"
                                  class="table-small">
                            <thead>
                            <tr>
                                <th></th>
                                <th nzColumnKey="descricao" [nzSortFn]="true" nzAlign="center">Obrigação</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let arquivo of tableObrigacoes.data">
                                <td nzLeft (nzCheckedChange)="onItemCheckedObrigacoes(arquivo.id, $event)"
                                    [nzChecked]="checkedItemsObrigacoes.has(arquivo.id)"
                                    [nzDisabled]="arquivo.disabled">
                                </td>
                                <td nzAlign="center">{{ arquivo?.descricao }}</td>
                            </tr>
                            </tbody>
                        </nz-table>
                        <ng-template #rangeTemplateObrigacao let-range="range" let-total>
                            {{ range[0] }}-{{ range[1] }} de {{ total }} Registros
                        </ng-template>
                    </nz-col>
                </nz-row>
            </nz-content>
        </form>

    </div>
    <div *nzModalFooter>
        <div class="d-flex"
             [ngClass]="[stepNumber === 1 ? 'justify-content-end' : '', stepNumber > 1 ? 'justify-content-between' : '']">
            <button nz-button nzType="default"
                    *ngIf="stepNumber !== 1 && stepNumber < 3"
                    (click)="changeStep(this.stepNumber, 'prev')">
                <i nz-icon nzType="double-left" nzTheme="outline"></i>
                Retornar
            </button>
            <button nz-button nzType="primary"
                    *ngIf="stepNumber < 3"
                    (click)="changeStep(this.stepNumber, 'next')" [nzLoading]="stepsLoading">
                {{ nextButtonText }}
                <i nz-icon nzType="double-right" nzTheme="outline"></i>
            </button>
        </div>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formAcessoEcac.modalVisible"
          [nzTitle]="'Cadastrar Acesso E-CAC'"
          [nzClosable]="true"
          (nzOnCancel)="modalAcessoEcac(false)"
          [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzAutoTips]="autoTips" [nzNoColon]="true" [formGroup]="formAcessoEcac.formGroup"
              style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="5" nzRequired>Empresa</nz-form-label>
                <nz-form-control [nzSpan]="15">
                    <nz-select nzShowSearch nzAllowClear [nzDisabled]="true"
                               [nzPlaceHolder]="'Selecione'" formControlName="ids">

                        <nz-option *ngFor="let opt of optionsEmpresas"
                                   [nzLabel]="opt.nome + ' (' + (opt.cnpj | cpfCnpj) + ')'"
                                   [nzValue]="opt.id"
                                   nzCustomContent>
                            {{ opt.nome }}
                            <br><small>({{ opt.cnpj | cpfCnpj }})</small>
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5" nzRequired>Procurador</nz-form-label>
                <nz-form-control [nzSpan]="15">
                    <nz-select nzShowSearch nzAllowClear [nzPlaceHolder]="'Selecione'" formControlName="procurador">
                        <nz-option *ngFor="let r of responsaveis"
                                   [nzLabel]="r.nome + ' (' + (r.documento | cpfCnpj) + ')'"
                                   [nzValue]="r.id"
                                   nzCustomContent>
                            {{ r.nome }}
                            <br><small>({{ r.documento | cpfCnpj }})</small>
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </form>

        <div style="margin-top: 20px">
            <div class="termos-resumidos" *ngIf="!termosCompletosVisible else termosCompletos">
                <h3 style="margin-bottom: 5px">Termos e condições</h3>
                <p>Ao ativar a integração com o eCAC, o contribuinte diretamente
                    ou indiretamente, através de um procurador,
                    autoriza a Master Intelligence Tax a utilizar os serviços SERPRO para fins de <b>CONSULTA</b>. Caso
                    não
                    exista as procurações necessárias, será feita a outorga automaticamente. <br>
                    Veja mais detalhes <a (click)="verTermosCompletos()">aqui</a>. </p>
            </div>
            <ng-template #termosCompletos>
                <div class="termos-completos">
                    <a (click)="verTermosCompletos()">< Voltar</a>
                    <h3 style="margin: 5px 0">Termos e condições</h3>
                    <div style="max-height: 400px; overflow-x: auto; margin: 15px 0">
                        <h3>Autorização</h3>
                        <p style="padding-left: 5px">
                            Autorizo a empresa CONTRATANTE, identificada neste termo de autorização como DESTINATÁRIO, a
                            executar as requisições dos serviços web disponibilizados pela API INTEGRA CONTADOR,
                            onde terei o papel de AUTOR PEDIDO DE DADOS no corpo da mensagem enviada na requisição do
                            serviço
                            web. Esse termo de autorização está assinado digitalmente com o certificado digital
                            do PROCURADOR ou OUTORGADO DO CONTRIBUINTE responsável, identificado como AUTOR DO PEDIDO DE
                            DADOS.
                        </p>
                        <h3>Aviso Legal</h3>
                        <p style="padding-left: 5px">
                            O acesso a estas informações foi autorizado pelo próprio PROCURADOR ou OUTORGADO DO
                            CONTRIBUINTE,
                            responsável pela informação, via assinatura digital. É dever do destinatário da
                            autorização e consumidor deste acesso observar a adoção de base legal para o tratamento dos
                            dados
                            recebidos conforme artigos 7º ou 11º da LGPD (Lei n.º 13.709, de 14 de agosto de 2018),
                            aos direitos do titular dos dados (art. 9º, 17 e 18, da LGPD) e aos princípios que norteiam
                            todos os
                            tratamentos de dados no Brasil (art. 6º, da LGPD).
                        </p>
                        <h3>Finalidade</h3>
                        <p style="padding-left: 5px">
                            A finalidade única e exclusiva desse TERMO DE AUTORIZAÇÃO, é garantir que o CONTRATANTE
                            apresente a API
                            INTEGRA CONTADOR esse consentimento do PROCURADOR ou OUTORGADO DO CONTRIBUINTE
                            assinado digitalmente, para que possa realizar as requisições dos serviços web da API
                            INTEGRA
                            CONTADOR
                            em nome do AUTOR PEDIDO DE DADOS (PROCURADOR ou OUTORGADO DO CONTRIBUINTE
                        </p>
                    </div>
                </div>
            </ng-template>

            <label nz-checkbox [(ngModel)]="termosAceitos">Aceito</label>
        </div>

    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalAcessoEcac(false);">Cancelar</button>

        <button *ngIf="!termosAceitos else btConfirmar"
                [nz-tooltip]="'Aceite os termos para proseguir'" nz-button
                class="bt-disabled">Confirmar
        </button>
        <ng-template #btConfirmar>
            <button *ngIf="termosAceitos" nz-button nzType="primary"
                    [nzLoading]="loadingAcessoEcac"
                    (click)="ativarInativarAcessoIntegradoECAC(false, true)">Confirmar
            </button>
        </ng-template>

    </div>
</nz-modal>

<nz-modal [(nzVisible)]="termosVisible" nzTitle="Acesso Integrado E-CAC"
          (nzOnCancel)="modalAcessoIntegradoEcac(null, false)">
    <div *nzModalContent>

        <div class="termos-resumidos" *ngIf="!termosCompletosVisible else termosCompletos">

            <div nz-row style="margin-bottom: 20px">
                <form nz-form [nzNoColon]="true" style="width: 100%;">
                    <nz-form-item>
                        <nz-form-label style="text-align: left" [nzSpan]="7">Data</nz-form-label>
                        <nz-form-control [nzSpan]="13">
                            <span>
                                   {{ acessoEcacSelecionado.created_at ? (acessoEcacSelecionado.created_at | date : 'dd/MM/yyyy') : '-' }}
                            </span>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                        <nz-form-label style="text-align: left" [nzSpan]="7">Validade</nz-form-label>
                        <nz-form-control [nzSpan]="13">
                            <span>
                                   {{ acessoEcacSelecionado.validade ? (acessoEcacSelecionado.validade | date : 'dd/MM/yyyy') : '-' }}
                            </span>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                        <nz-form-label style="text-align: left" [nzSpan]="7">Status</nz-form-label>
                        <nz-form-control [nzSpan]="13">
                            <span>
                                   {{ acessoEcacSelecionado.ativo === 1 ? 'Ativo' : 'Inativo' }}
                            </span>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item>
                        <nz-form-label style="text-align: left" [nzSpan]="7">Integrador</nz-form-label>
                        <nz-form-control [nzSpan]="13">
                            <span>{{ (acessoEcacSelecionado.contratante | cpfCnpj) || '-' }} </span>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                        <nz-form-label style="text-align: left" [nzSpan]="7">Procurador</nz-form-label>
                        <nz-form-control [nzSpan]="13">
                            <span> {{ (acessoEcacSelecionado.procurador | cpfCnpj) || '-' }} </span>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                        <nz-form-label style="text-align: left" [nzSpan]="7">Contribuinte</nz-form-label>
                        <nz-form-control [nzSpan]="13">
                            <span> {{ (acessoEcacSelecionado.contribuinte | cpfCnpj) || '-' }} </span>
                        </nz-form-control>
                    </nz-form-item>
                </form>
            </div>

            <h3 style="margin-bottom: 5px">Termos e condições</h3>

            <p>Ao ativar a integração com o
                eCAC, o contribuinte diretamente
                ou indiretamente, através de um procurador,
                autoriza a Master Intelligence Tax a utilizar os serviços SERPRO para fins de <b>CONSULTA</b>. Caso não
                exista as procurações necessárias, será feita a outorga automaticamente. <br>
                Veja mais detalhes
                <a (click)="verTermosCompletos()">aqui</a>. </p>
        </div>
        <ng-template #termosCompletos>
            <div class="termos-completos">
                <a (click)="verTermosCompletos()">< Voltar</a>
                <h3 style="margin: 5px 0">Termos e condições</h3>
                <div style="max-height: 400px; overflow-x: auto; margin: 15px 0">
                    <h3>Autorização</h3>
                    <p style="padding-left: 5px">
                        Autorizo a empresa CONTRATANTE, identificada neste termo de autorização como DESTINATÁRIO, a
                        executar as requisições dos serviços web disponibilizados pela API INTEGRA CONTADOR,
                        onde terei o papel de AUTOR PEDIDO DE DADOS no corpo da mensagem enviada na requisição do
                        serviço
                        web. Esse termo de autorização está assinado digitalmente com o certificado digital
                        do PROCURADOR ou OUTORGADO DO CONTRIBUINTE responsável, identificado como AUTOR DO PEDIDO DE
                        DADOS.
                    </p>
                    <h3>Aviso Legal</h3>
                    <p style="padding-left: 5px">
                        O acesso a estas informações foi autorizado pelo próprio PROCURADOR ou OUTORGADO DO
                        CONTRIBUINTE,
                        responsável pela informação, via assinatura digital. É dever do destinatário da
                        autorização e consumidor deste acesso observar a adoção de base legal para o tratamento dos
                        dados
                        recebidos conforme artigos 7º ou 11º da LGPD (Lei n.º 13.709, de 14 de agosto de 2018),
                        aos direitos do titular dos dados (art. 9º, 17 e 18, da LGPD) e aos princípios que norteiam
                        todos os
                        tratamentos de dados no Brasil (art. 6º, da LGPD).
                    </p>
                    <h3>Finalidade</h3>
                    <p style="padding-left: 5px">
                        A finalidade única e exclusiva desse TERMO DE AUTORIZAÇÃO, é garantir que o CONTRATANTE
                        apresente a API
                        INTEGRA CONTADOR esse consentimento do PROCURADOR ou OUTORGADO DO CONTRIBUINTE
                        assinado digitalmente, para que possa realizar as requisições dos serviços web da API INTEGRA
                        CONTADOR
                        em nome do AUTOR PEDIDO DE DADOS (PROCURADOR ou OUTORGADO DO CONTRIBUINTE
                    </p>
                </div>
            </div>
        </ng-template>

        <label *ngIf="!checkDisabled" nz-checkbox [(ngModel)]="termosAceitos">Aceito</label>


        <div style="margin-top: 25px; padding-top: 10px; max-height: 300px; overflow-y: auto"
             *ngIf="!termosCompletosVisible">

            <nz-timeline *ngIf="acessoEcacSelecionado.historico">
                <nz-timeline-item *ngFor="let h of acessoEcacSelecionado.historico"
                                  [nzColor]="h.acao === 'autorizado' ? 'blue' : 'red'">
                    {{ h.acao === 'autorizado' ? 'Aceito ' : 'Inativo ' }} por {{ h.created_from_nome || '-' }}
                    em {{ h.created_at ? (h.created_at | date : 'dd/MM/yyyy') + ' às ' + (h.created_at | date : 'HH:mm') : '-' }}
                </nz-timeline-item>
            </nz-timeline>
        </div>

    </div>
    <div *nzModalFooter>
        <button nz-button nzType="default" (click)="modalAcessoIntegradoEcac(null, false)">
            Fechar
        </button>
        <button *ngIf="acessoEcacSelecionado.ativo === 1" nzType="link"
                [nzLoading]="loadingAcessoEcac"
                (click)="confirmInativar()" nz-button>Inativar
        </button>
        <button *ngIf="acessoEcacSelecionado.ativo === 0 && !termosAceitos else btAtivar"
                [nz-tooltip]="'Aceite os termos para proseguir'" nz-button
                class="bt-disabled">Ativar
        </button>
        <ng-template #btAtivar>
            <button *ngIf="acessoEcacSelecionado.ativo === 0 && termosAceitos" nz-button nzType="primary"
                    [nzLoading]="loadingAcessoEcac"
                    (click)="modalProcurador(1, true)">Ativar
            </button>
        </ng-template>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="selecionarProcurador"
          [nzTitle]="'Selecione o Procurador'"
          [nzClosable]="true"
          (nzOnCancel)="modalProcurador(0, false)"
          [nzWidth]="600">
    <div nz-row *nzModalContent>
        <form nz-form [nzAutoTips]="autoTips" [nzNoColon]="true" [formGroup]="formAcessoEcac.formGroup"
              style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="5" nzRequired>Procurador</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-select [nzPlaceHolder]="'Selecione'" formControlName="procurador" nzShowSearch nzAllowClear>
                        <nz-option *ngFor="let r of responsaveis"
                                   [nzLabel]="r.nome + ' (' + (r.documento | cpfCnpj) + ')'"
                                   [nzValue]="r.id"
                                   nzCustomContent>
                            {{ r.nome }}
                            <br><small>({{ r.documento | cpfCnpj }})</small>
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalProcurador(0, false);">Cancelar</button>
        <button [nzLoading]="loadingAcessoEcac" nz-button nzType="primary"
                (click)="ativarInativarAcessoIntegradoECAC();">Confirmar
        </button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formAcessosMunicipais.modalVisible"
          [nzTitle]="editando.acessosMunicipais ? 'Alterar Acesso Municipal' : 'Cadastrar Acesso Municipal'"
          [nzClosable]="true"
          (nzOnCancel)="modalAcessoMunicipal(false, null)"
          [nzWidth]="648">
    <div nz-row *nzModalContent>
        <nz-skeleton *ngIf="loadings.formAcessosMunicipais"></nz-skeleton>
        <form nz-form [nzNoColon]="true" [formGroup]="formAcessosMunicipais.formGroup" style="width: 100%;"
              *ngIf="!loadings.formAcessosMunicipais"
        >
            <nz-form-item>
                <nz-form-label [nzSpan]="10" nzRequired>UF</nz-form-label>
                <nz-form-control [nzSpan]="13">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="uf"
                               (ngModelChange)="listMunicipios(formAcessosMunicipais.formGroup.value.uf)">

                        <nz-option *ngFor="let opt of comboUFs"
                                   [nzLabel]="opt.label"
                                   [nzValue]="opt.key">
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="10" nzRequired>Município</nz-form-label>
                <nz-form-control [nzSpan]="13">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="portal"
                               (ngModelChange)="filtrarCamposMunicipal(formAcessosMunicipais.formGroup.value.portal)">

                        <nz-option *ngFor="let opt of comboMunicipios"
                                   [nzLabel]="opt.label"
                                   [nzValue]="opt.key">
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-content *ngIf="camposFormDataPrevMunicipais.length > 0">
                <nz-form-item *ngFor="let item of camposFormDataPrevMunicipais">
                    <nz-form-label nzRequired [nzSpan]="10" *ngIf="!loadings.formAcessosMunicipais">{{ item.label }}:
                    </nz-form-label>
                    <nz-form-control [nzSpan]="13" *ngIf="item.valores.length > 0 && !loadings.formAcessosMunicipais">
                        <nz-select nzShowSearch nzAllowClear
                                   [nzPlaceHolder]="'Selecione'" formControlName="{{item.label}}">
                            <nz-option *ngFor="let opt of item.valores"
                                       [nzLabel]="opt"
                                       [nzValue]="opt.trim()">
                            </nz-option>
                        </nz-select>
                    </nz-form-control>
                    <nz-form-control [nzSpan]="13" *ngIf="item.valores.length === 0 && !loadings.formAcessosMunicipais">
                        <input nz-input type="text" formControlName="{{item.label}}"/>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item
                        *ngIf="editando.acessosMunicipais && municipalSelecionado.created_at">
                    <nz-form-label [nzSpan]="10">Cadastrado por:</nz-form-label>
                    <nz-form-control [nzSpan]="13">
                        <nz-form-text>{{ municipalSelecionado.criado_por }}
                            em {{ municipalSelecionado.created_at | date : 'dd/MM/yyyy HH:mm' }}
                        </nz-form-text>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item
                        *ngIf="editando.acessosMunicipais && municipalSelecionado.atualizado_por">
                    <nz-form-label [nzSpan]="10">Alterado por:</nz-form-label>
                    <nz-form-control [nzSpan]="13">
                        <nz-form-text>{{ municipalSelecionado.atualizado_por }}
                            em {{ municipalSelecionado.updated_at | date : 'dd/MM/yyyy HH:mm' }}
                        </nz-form-text>
                    </nz-form-control>
                </nz-form-item>
            </nz-content>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalAcessoMunicipal(false, null);">Cancelar</button>
        <button nz-button nzType="primary" *ngIf="!editando.acessosMunicipais"
                [nzLoading]="loadings.insertAcessoMunicipal" (click)="insertAcessosMunicipais();">Confirmar
        </button>
        <button nz-button nzType="primary" *ngIf="editando.acessosMunicipais"
                [nzLoading]="loadings.updateAcessosMunicipal" (click)="updateAcessosMunicipais();">Confirmar
        </button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formAcessosPrevidenciarios.modalVisible"
          [nzTitle]="editando.acessosPrevidenciarios ? 'Alterar Acesso Previdenciário' : 'Cadastrar Acesso Previdenciário'"
          [nzClosable]="true"
          (nzOnCancel)="modalAcessoPrevidenciario(false, null)"
          [nzWidth]="648">
    <div nz-row *nzModalContent>
        <nz-skeleton *ngIf="loadings.formAcessosPrevidenciarios"></nz-skeleton>
        <form nz-form [nzNoColon]="true" [formGroup]="formAcessosPrevidenciarios.formGroup" style="width: 100%;"
              *ngIf="!loadings.formAcessosPrevidenciarios"
        >
            <nz-form-item>
                <nz-form-label [nzSpan]="10" nzRequired>Portal</nz-form-label>
                <nz-form-control nzErrorTip="Informe o portal!" [nzSpan]="10">
                    <nz-select nzShowSearch nzAllowClear required
                               [nzDisabled]="editando.acessosPrevidenciarios"
                               [nzPlaceHolder]="'Selecione'" formControlName="portal"
                               (ngModelChange)="filtrarCamposPrevidenciario(formAcessosPrevidenciarios.formGroup.value)">

                        <nz-option *ngFor="let opt of comboPortaisPrevidenciarios"
                                   [nzLabel]="opt.label"
                                   [nzValue]="opt.key">
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-content *ngIf="camposFormDataPrevPrevidencirios.length > 0">
                <nz-form-item *ngFor="let item of camposFormDataPrevPrevidencirios">
                    <nz-form-label nzRequired [nzSpan]="10"
                                   *ngIf="!loadings.formAcessosPrevidenciarios">{{ item.label }}:
                    </nz-form-label>
                    <nz-form-control [nzSpan]="13"
                                     *ngIf="item.valores.length > 0 && !loadings.formAcessosPrevidenciarios">
                        <nz-select nzShowSearch nzAllowClear
                                   [nzPlaceHolder]="'Selecione'" formControlName="{{item.label}}">
                            <nz-option *ngFor="let opt of item.valores"
                                       [nzLabel]="opt"
                                       [nzValue]="opt.trim()">
                            </nz-option>
                        </nz-select>
                    </nz-form-control>
                    <nz-form-control [nzSpan]="13"
                                     *ngIf="item.valores.length === 0 && !loadings.formAcessosPrevidenciarios">
                        <input nz-input type="text" formControlName="{{item.label}}"/>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item
                        *ngIf="editando.acessosPrevidenciarios  && dataPrevSelecionado.created_at">
                    <nz-form-label [nzSpan]="10">Cadastrado por:</nz-form-label>
                    <nz-form-control [nzSpan]="13">
                        <nz-form-text>{{ dataPrevSelecionado.criado_por }}
                            em {{ dataPrevSelecionado.created_at | date : 'dd/MM/yyyy HH:mm' }}
                        </nz-form-text>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item
                        *ngIf="editando.acessosPrevidenciarios && dataPrevSelecionado.atualizado_por">
                    <nz-form-label [nzSpan]="10">Alterado por:</nz-form-label>
                    <nz-form-control [nzSpan]="13">
                        <nz-form-text>{{ dataPrevSelecionado.atualizado_por }}
                            em {{ dataPrevSelecionado.updated_at | date : 'dd/MM/yyyy HH:mm' }}
                        </nz-form-text>
                    </nz-form-control>
                </nz-form-item>
            </nz-content>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalAcessoPrevidenciario(false, null);">Cancelar</button>
        <button nz-button nzType="primary" *ngIf="!editando.acessosPrevidenciarios"
                [nzLoading]="loadings.acessosPrevidenciarios" (click)="insertAcessosPrevidnciario();">Confirmar
        </button>
        <button nz-button nzType="primary" *ngIf="editando.acessosPrevidenciarios"
                [nzLoading]="loadings.updateAcessosPrevidenciarios" (click)="updateAcessosPrevidenciarios();">Confirmar
        </button>
    </div>
</nz-modal>

<ng-template #confirm>
    <label nz-checkbox [(ngModel)]="removerTodosAnos">Remover <b>todos</b> os anos cadastrados</label>
</ng-template>


<nz-modal [(nzVisible)]="visibleModals.visibilidade" [nzTitle]="'Alterar Visibilidade'" [nzClosable]="true"
          (nzOnCancel)="modalAlterarVisibilidade(false)">
    <div *nzModalContent>
        <nz-skeleton *ngIf="loadings.buscandoVisibilidade"></nz-skeleton>
        <div *ngIf="!loadings.buscandoVisibilidade">

            <form nz-form [nzAutoTips]="autoTips" [nzNoColon]="true" [formGroup]="formVisibilidade.formGroup">
                <nz-form-item>
                    <nz-form-label [nzSpan]="8">Visibilidade</nz-form-label>
                    <nz-form-control [nzSpan]="12">
                        <nz-select formControlName="visibilidade_id" placeholder="selecione..."
                                   [nzPlaceHolder]="'Selecione'" [nzAllowClear]="false">
                            <nz-option *ngFor="let option of visibilidadeOptions" [nzLabel]="modoDemo(option.label)"
                                       [nzValue]="option.value"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
            </form>
        </div>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalAlterarVisibilidade(false)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="loadings.alterandoVisibilidade"
                (click)="confirmaGravarVisibilidade()">Confirmar
        </button>
    </div>
</nz-modal>
