import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {Helpers} from '../../helpers';
import {DataService} from '@services/data.service';
import {environment} from '../../../../environments/environment';
import {ModalCredencialDeAcessoService} from './modal-credencial-de-acesso.service';
import {ToastrService} from 'ngx-toastr';
import {NzModalService} from 'ng-zorro-antd/modal';
import {DomSanitizer} from '@angular/platform-browser';
import {NzUploadFile} from 'ng-zorro-antd/upload';
import * as fileSaver from 'file-saver-es';

@Component({
    selector: 'app-modal-credencial-de-acesso',
    templateUrl: './modal-credencial-de-acesso.component.html',
    styleUrls: ['./modal-credencial-de-acesso.component.scss']
})
export class ModalCredencialDeAcessoComponent {

    @Input() data;
    tipo: 'empresa' | 'responsavel' = 'empresa';

    @Output() concluiuOperacao = new EventEmitter<string>();
    @Output() fecharModal = new EventEmitter<string>();

    currentUser: any;
    apiUrl = environment.apiUrl;

    formAdicionarCertificado = this.fb.group({
        empresa_id: ['', Validators.required],
        emissor: ['', Validators.required],
        emissaoData: ['', Validators.required],
        validade: ['', Validators.required],
        tipo: ['', Validators.required],
        serial: ['', Validators.required],
        uso: ['Próprio', Validators.required],
        arquivo: ['', Validators.required],
        replicar: [''],
    });

    formSenha = this.fb.group({
        senha: [null, Validators.required]
    });

    visible = false;
    modalSenhaVisible = false;
    loading = false;
    loadingDownload = false;
    loadingBuscarProcuracoes = false;

    erro = false;
    mensagem = '';

    fileListCertificado: NzUploadFile[] = [];

    editarCertificados: any = {};

    dadosCadastrais: any = {};

    editandoCert = false;

    editando: any = {
        certificados: null
    };

    item = null;
    typeSenha = 'password';
    boolCopiado = false;

    constructor(
        private fb: FormBuilder,
        private service: ModalCredencialDeAcessoService,
        private toastService: ToastrService,
        private modalService: NzModalService,
        public sanitizer: DomSanitizer,
        private dataService: DataService
    ) {

        this.dataService.currentUser.subscribe((data) => {
            this.currentUser = data;
        });
    }

    showModal(visible: boolean) {

        this.formAdicionarCertificado.get('uso').setValue('Próprio');

        if (visible && this.editandoCert) {
            this.alterarCertificadoA1();
        } else {
            this.visible = visible;
        }

        if (!visible) {
            this.formAdicionarCertificado.reset();
            this.editando.certificados = null;
            this.editandoCert = false;
            this.editarCertificados = {};
            this.fileListCertificado = [];
            this.fecharModal.emit();
        }
    }

    alterarCertificadoA1(): void {

        this.formAdicionarCertificado.get('uso').setValue(this.item.uso);

        this.editando.certificados = this.item.id;

        this.formAdicionarCertificado.get('serial').setValue(this.item.serial);
        this.formAdicionarCertificado.get('emissaoData').setValue(this.item.emissaoData);
        this.formAdicionarCertificado.get('validade').setValue(this.item.validade);
        this.formAdicionarCertificado.get('tipo').setValue(this.item.tipo);

        this.editarCertificados.empresa_id = this.item.empresa_id;
        this.editarCertificados.emissor = this.item.emissor;
        this.editarCertificados.nomeArquivo = this.item.nomeArquivo;
        this.editarCertificados.situacao_dias = this.item.situacao_dias;
        this.editarCertificados.created_at = this.item.created_at ? this.item.created_at : null;
        this.editarCertificados.created_from_nome = this.item.created_from_nome ? this.item.created_from_nome : null;


        this.beforeUploadCertificado(this.item.arquivoUpdate);

        this.visible = true;

    }

    validaFormCertificados(): void {

        if (this.formAdicionarCertificado.value.emissaoData) {

            this.formAdicionarCertificado.value.emissaoData = Helpers.formatDateDb(
                this.formAdicionarCertificado.value.emissaoData
            );

        }

        if (this.formAdicionarCertificado.value.validade) {

            this.formAdicionarCertificado.value.validade = Helpers.formatDateDb(
                this.formAdicionarCertificado.value.validade
            );

        }

        if (!this.formAdicionarCertificado.value.tipo) {
            this.erro = true;
            this.mensagem = 'Campo Categoria obrigatório';
        }

        if (!this.editandoCert) {

            if (this.formAdicionarCertificado.value.uso === 'Próprio') {

                if (this.fileListCertificado.length < 1) {
                    this.erro = true;
                    this.mensagem = 'Campo Arquivo obrigatório';
                }

                if (!this.formAdicionarCertificado.value.serial) {
                    this.erro = true;
                    this.mensagem = 'Campo Senha obrigatório';
                }

            } else {

                if (!this.formAdicionarCertificado.value.emissaoData) {
                    this.erro = true;
                    this.mensagem = 'Campo Emitido Em obrigatório';
                }

                if (!this.formAdicionarCertificado.value.validade) {
                    this.erro = true;
                    this.mensagem = 'Campo Validade Em obrigatório';
                }

            }

        }

    }

    confirmAtualiarProcuracoes() {

        this.validaFormCertificados();

        if (!this.erro) {

            this.modalService.confirm({
                nzTitle: 'Deseja consultar procurações?',
                nzOkText: 'Atualizar',
                nzOnOk: () => this.buscarProcuracoesEcac(true),
                nzOnCancel: () => this.insertCertificados()
            });
        } else {
            this.toastService.error(this.mensagem);
            this.erro = false;
            this.loading = false;
        }
    }

    buscarProcuracoesEcac(insert: boolean = false): void {

        if (insert) {
            this.insertCertificados();
        }

        this.loadingBuscarProcuracoes = true;
        this.service.buscarProcuracoes(this.dadosCadastrais.id).subscribe(
            (response) => {
                this.loadingBuscarProcuracoes = false;
                this.toastService.success(response.message);

            },
            (response) => {
                this.loadingBuscarProcuracoes = false;
                this.toastService.error(response.error.message);
            }
        );
    }

    insertCertificados(): void {

        this.loading = true;

        const formData = new FormData();

        Object.entries(this.formAdicionarCertificado.value).forEach(
            // tslint:disable-next-line:variable-name
            ([key, value]) => {
                if (value) {
                    formData.set(key, value.toString());
                }
            });

        if (this.tipo == 'empresa') {
            formData.set('empresa_id', this.data.id);
        }else{
            formData.set('responsavel_id', this.data.id);
        }


        this.fileListCertificado.forEach((file: any) => {
            formData.append('arquivo', file);
        });


        const id = this.data.id;
        this.service.insertCertificado(id, formData, this.tipo).subscribe((response: any) => {

                this.toastService.success('Certificado A1 cadastrado com sucesso!');

                this.loading = false;
                this.showModal(false);
                this.editando.certificados = null;
                this.editarCertificados = {};

                this.concluiuOperacao.emit();
            },
            (response) => {
                this.toastService.error(response.error.message);
                this.loading = false;
                this.showModal(false);
                this.editando.certificados = null;
                this.editarCertificados = {};
            });
        this.erro = false;
        this.mensagem = '';

    }

    updateCertificados(): void {

        this.loading = true;
        this.validaFormCertificados();

        const formData = new FormData();

        Object.entries(this.formAdicionarCertificado.value).forEach(
            ([key, value]) => {
                if (value && (key === 'padrao' || key === 'tipo')) {
                    formData.set(key, value.toString());
                }
            });

        formData.set('empresa_id', this.data.id);

        if (!this.erro) {

            this.service.updateCertificados(this.editando.certificados, formData).subscribe((response: any) => {

                    this.toastService.success('Certificado A1 alterado com sucesso!');
                    this.showModal(false);
                    this.loading = false;
                    this.editando.certificados = null;

                    this.concluiuOperacao.emit();
                },
                (response) => {
                    if (response.error?.erros) {
                        response.error.erros.forEach((erro) => {
                            this.toastService.error(erro);
                        });
                        this.loading = false;
                    } else {
                        this.loading = false;
                        this.toastService.error('Falha ao realizar operação');
                    }

                });
            this.erro = false;
            this.mensagem = '';

        } else {
            this.toastService.error(this.mensagem);
            this.erro = false;
            this.loading = false;
        }

    }

    maskNZDatePicker(event: any) {

        if (event.key !== 'Backspace' && (event.target.value.length === 2 || event.target.value.length === 5)) {
            event.target.value += '/';
        }
    }

    beforeUploadCertificado = (file: NzUploadFile): boolean => {

        this.fileListCertificado = [];
        this.fileListCertificado.push(file);
        return false;
    }


    changeIputsAddCerificado(event) {
        this.formAdicionarCertificado.value.uso = event;
    }

    modalSenha(visible: boolean, senha: string = '') {
        this.modalSenhaVisible = visible;
        this.boolCopiado = false;
        this.typeSenha = 'password';

        if (senha) {
            // @ts-ignore
            this.formSenha.get('senha').setValue(senha);
        }
    }

    downloadCertificado() {

        this.loadingDownload = true;

        this.modalSenha(false);

        const senha = Helpers.generateStrongPassword(12);

        this.service.downloadCertificado(this.editando.certificados, senha).subscribe((res) => {

            this.formSenha.reset();

            this.modalSenha(true, senha);

            this.loadingDownload = false;

            const blob = new Blob([res], {type: 'text/json; charset=utf-8'});

            fileSaver.saveAs(blob, this.editarCertificados.nomeArquivo);
        }, error => {
            this.loadingDownload = false;
            this.toastService.error(error.error.message);
        });

    }

    copyToClipboard(): void {
        const senha = this.formSenha.value.senha;
        if (navigator.clipboard) {
            navigator.clipboard.writeText(senha).then(() => {
                this.toastService.success('Senha copiada para área de transferência.');
                this.boolCopiado = true;
                setTimeout(() => {
                    this.boolCopiado = false;
                }, 1000);
            }).catch(err => {
                this.toastService.error('Erro ao copiar senha: ', err);
            });
        } else {
            this.toastService.error('API de Clipboard não suportada pelo navegador.');
        }
    }

    mudarTypeSenha() {
        this.typeSenha = this.typeSenha === 'password' ? 'text' : 'password';
    }
}
