<nz-page-header class="site-page-header" nzTitle="Check" nzSubtitle="Armazenamento">
    <nz-page-header-extra>
        <nz-button-group>
            <nz-badge [nzCount]="qtdFiltrosCabecalho"
                      [nzStyle]="{ backgroundColor: '#52c41a'}">
                <button nz-button nzType="primary" nzGhost
                        style="border-radius: 0.45rem;"
                        (click)="abrirModal(formFiltrosCabecalho)">
                    <i nz-icon nzType="search"></i>
                    Filtros
                </button>
            </nz-badge>
        </nz-button-group>
    </nz-page-header-extra>
</nz-page-header>
<nz-content>
    <div nz-row [nzGutter]="24">
        <div nz-col [nzXs]="24" [nzSm]="12" [nzMd]="8" [nzXl]="6">
            <nz-card [nzLoading]="loadingContadores">
                <div nz-row>
                    <div nz-col [nzSpan]="24" class="d-flex align-items-left justify-content-left">
                        <div>
                            <h1 nz-typography class="custom-card-value text-nowrap"
                                style="margin: 0; font-size: 28px; margin-bottom: 10px;">
                                {{ dadosCabecalho.obrigacao }}
                            </h1>
                            <h4 class="custom-card-title text-muted" style="margin: 0; line-height: 1;">
                                {{ dadosCabecalho.orgao }}
                            </h4>
                        </div>
                    </div>
                </div>
            </nz-card>
        </div>
        <div nz-col [nzXs]="24" [nzSm]="12" [nzMd]="8" [nzXl]="4">
            <nz-card [nzLoading]="loadingContadores">
                <div nz-row>
                    <div nz-col [nzSpan]="24" class="d-flex align-items-left justify-content-left">
                        <div>
                            <h4 class="custom-card-title text-muted" style="line-height: 1;">
                                Vencimento:
                            </h4>
                            <h1 nz-typography class="custom-card-value" style="margin: 0; font-size: 30px">
                                {{ dadosCabecalho.mesSelecionado }}<span
                                    style="font-weight: 400;">/</span>{{ dadosCabecalho.anoSelecionado }}
                            </h1>
                        </div>
                    </div>
                </div>
            </nz-card>
        </div>
        <div nz-col [nzXs]="24" [nzSm]="12" [nzMd]="8" [nzXl]="4">
            <nz-card [nzLoading]="loadingContadores">
                <div nz-row>
                    <div nz-col [nzSpan]="24" class="d-flex align-items-center justify-content-center">
                        <i class="far fa-building"
                           style="font-size: 56px; margin-right: 15px; color: #009688 !important;"></i>
                        <div>
                            <h4 class="custom-card-title text-muted" style="margin: 0; line-height: 1;">
                                Unidades
                            </h4>
                            <h1 nz-typography class="custom-card-value"
                                style="margin: 0; color: #009688 !important; font-size: 36px;">
                                {{ dadosCabecalho.qtdUnidades | number: '1.0-0' }}
                            </h1>
                        </div>
                    </div>
                </div>
            </nz-card>
        </div>
        <div nz-col [nzXs]="24" [nzSm]="12" [nzMd]="8" [nzXl]="4">
            <nz-card [nzLoading]="loadingContadores">
                <div nz-row>
                    <div nz-col [nzSpan]="24"
                         class="d-flex align-items-center justify-content-center">
                        <i class="far fa-calendar-alt"
                           style="font-size: 60px; margin-right: 15px; color:#1e9ff2 !important;"></i>
                        <div>
                            <h4 class="custom-card-title text-muted" style="margin: 0; line-height: 1;">
                                Obrigações
                            </h4>
                            <h1 nz-typography class="custom-card-value"
                                style="margin: 0; color: #1e9ff2 !important; font-size: 36px;">
                                {{ dadosCabecalho.qtdObrigacoes | number: '1.0-0' }}
                            </h1>
                        </div>
                    </div>
                </div>
            </nz-card>
        </div>
        <div nz-col [nzXs]="24" [nzSm]="12" [nzMd]="8" [nzXl]="6">
            <nz-card [nzLoading]="loadingContadores">
                <div nz-row>
                    <div nz-col [nzSpan]="24"
                         class="d-flex align-items-center">
                        <i nz-icon nzType="info-circle" nzTheme="outline"
                           style="font-size: 56px; margin-right: 15px; color: #3653a1 !important;"></i>
                        <div>
                            <h1 class="custom-card-title text-muted" style="margin: 0; line-height: 1;">
                                Status
                            </h1>
                            <div style="width: 200px;">
                                <a class="custom-card-title text-muted mt-5"
                                   (click)="filtrarCampo(formFiltrosGrid, 'situacao', 'armazenadas')"
                                   style="margin: 0; margin-top: 6px; line-height: 1; width: 100%; display: block;">
                                    Armazenadas
                                    <span class="custom-card-span-valor" style="color: #28a745;">
                                    {{ dadosCabecalho.qtdArmazenadas | number: '1.0-0' }}
                                    </span>
                                </a>
                                <a class="custom-card-title text-muted"
                                   (click)="filtrarCampo(formFiltrosGrid, 'situacao', 'nao-armazenadas')"
                                   style="margin: 0; margin-top: 5px;line-height: 1; width: 100%; display: block;">
                                    Ausentes
                                    <span class="custom-card-span-valor" style="color: #cf1322;">
                                        {{ dadosCabecalho.qtdAusentes | number: '1.0-0' }}
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </nz-card>
        </div>
    </div>
</nz-content>
<nz-content>
    <nz-card class="fonte-doze" [nzExtra]="navBar" nzTitle="Armazenamento" [nzLoading]="loading"
             [ngClass]="[!loading ? 'ant-card-table' : '']">
        <ng-template #navBar>
            <div class="nav-item d-flex">
                <nz-badge [nzCount]="qtdFiltrosAtivos"
                          [nzStyle]="{ backgroundColor: '#52c41a', 'margin-right' : '15px'}">
                    <button nz-button nzType="primary" style="margin-right: 10px;" (click)="modalFiltrar(true);">
                        <i nz-icon nzType="search"></i>
                        Filtros
                    </button>
                </nz-badge>
                <button [nzDropdownMenu]="menuAcoes" class="centralized-icon" nz-button nz-dropdown
                        nzType="default" style="margin-right: 10px;">
                    Opções
                    <em nz-icon nzType="down"></em>
                </button>
                <nz-dropdown-menu #menuAcoes="nzDropdownMenu">
                    <ul nz-menu>
                        <li nz-menu-item (click)="confirmaExportar()">
                            Exportar Planilha
                        </li>
                        <li nz-button *ngIf="currentUser?.origem === 'console'" nz-submenu nzTitle="Console">
                            <ul>
                                <li nz-menu-item nz-button
                                    (click)="checkedItems.size && showConfirmConciliacao()"
                                    [nzDisabled]="checkedItems.size === 0 || loadingProcessandoConciliacoes">
                                    {{ !loadingProcessandoConciliacoes ? 'Processar conciliação' : 'Procensando conciliação...' }}
                                </li>
                                <li nz-menu-item nz-button
                                    (click)="checkedItems.size && showConfirm()"
                                    [nzDisabled]="checkedItems.size === 0 || loadingProcessandoConciliacoes">
                                    {{ !loadingProcessandoConciliacoes ? 'Processar validação' : 'Procensando validação...' }}
                                </li>
                            </ul>
                        </li>
                    </ul>
                </nz-dropdown-menu>
                <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" style="margin-right: 10px;">
                    <input (keydown.enter)="queryTable(currentParams, currentSearch, true);" type="text" nz-input
                           placeholder="Busca" [(ngModel)]="currentSearch"/>
                </nz-input-group>
                <ng-template #suffixIconButton>
                    <button nz-button nzType="primary" nzSearch
                            (click)="queryTable(currentParams, currentSearch, true);"><i
                            nz-icon nzType="search"></i></button>
                </ng-template>
                <button nz-button nzType="default" style="margin-right: 10px;"
                        (click)="btnResetSearch();">
                    Limpar
                </button>
            </div>
        </ng-template>
        <nz-alert nzType="info" nzShowIcon [nzMessage]="checkedMessage" style="margin: 15px !important;"
                  *ngIf="checkedItems.size > 0">
            <ng-template #checkedMessage>
                <div nz-row nzAlign="middle" nzGutter="24">
                    <div nz-col nzSpan="24">
                        <strong class="text-primary"></strong>
                        {{ checkedItems.size }} Registros Selecionados
                    </div>
                </div>
            </ng-template>
        </nz-alert>
        <nz-table #basicTable
                  [nzData]="items"
                  [nzFrontPagination]="false"
                  [nzTotal]="pagination?.total"
                  [nzPageSize]="pagination?.per_page"
                  [nzPageIndex]="pagination?.current_page"
                  (nzQueryParams)="listByTableArmazenamentos($event)"
                  [nzShowTotal]="rangeTemplate"
                  class="table-small"
        >
            <thead>
            <tr>
                <th (nzCheckedChange)="onAllChecked($event)" [nzChecked]="checked" [nzIndeterminate]="indeterminate"
                    id="check-all">
                </th>
                <th nzWidth="50px">
                </th>
                <th nzColumnKey="obrigacao" [nzSortFn]="true" nzAlign="center">Obrigação</th>
                <th nzColumnKey="grupo" [nzSortFn]="true" nzAlign="center">Grupo</th>
                <th nzColumnKey="empresa" [nzSortFn]="true" nzAlign="center">Empresa</th>
                <th nzColumnKey="competencia" [nzSortFn]="true" nzAlign="center">Competência</th>
                <th nzColumnKey="vencimento" [nzSortFn]="true" nzAlign="center">Vencimento</th>
                <th nzColumnKey="situacao" [nzSortFn]="true" nzAlign="center">Situação</th>
                <th nzColumnKey="armazenamento" [nzSortFn]="true" nzAlign="center">Armazenamento</th>
                <th nzColumnKey="origem" [nzSortFn]="true" nzAlign="center">Origem</th>
                <th nzColumnKey="usuario" [nzSortFn]="true" nzAlign="center">Usuário</th>
                <th [nzSortFn]="false" nzShowSort="false" nzAlign="center">Arquivo</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let arquivo of basicTable.data">
                <td nzAlign="center" (nzCheckedChange)="onItemChecked(arquivo.id, $event)"
                    [nzChecked]="checkedItems.has(arquivo.id)"
                    [nzDisabled]="arquivo.disabled">
                </td>
                <td>1</td>
                <td>
                    <button nz-button nzType="link"
                            *ngIf="arquivo?.temHistorico"
                            (click)="openTab('/cadastros/obrigacao-empresa-detalhe/', arquivo?.id, {id: arquivo?.id})">
                        {{ arquivo?.obrigacao_descricao }}
                    </button>
                    <button nz-button nzType="link"
                            *ngIf="!arquivo?.temHistorico"
                            (click)="msgSemHistorico()">
                        {{ arquivo?.obrigacao_descricao }}
                    </button>
                </td>
                <td>{{ modoDemo(arquivo?.grupo, 'total') }}</td>
                <td style="width: 300px;">{{ modoDemo(arquivo?.empresa_descricao, 'empresaCodigoNome') }}<br>
                    ({{ (modoDemo(arquivo.empresa_cnpj | cpfCnpj, 'cnpj')) }})
                    <span *ngIf="arquivo.ie"> IE: {{ modoDemo(arquivo.ie) }}</span>
                </td>
                <td nzAlign="center">{{ arquivo?.competencia | date : 'MM/yyyy' }}</td>
                <td nzAlign="center">{{ arquivo?.vencimento | date : 'dd/MM/yyyy' }}</td>
                <td nzAlign="center">
                    <nz-alert nzType="success" *ngIf="arquivo?.situacao === 1"
                              nzMessage="{{arquivo?.situacao_descricao}}"></nz-alert>
                    <nz-alert nzType="error" *ngIf="arquivo?.status === 2"
                              nzMessage="{{arquivo?.situacao_descricao}}"></nz-alert>
                </td>
                <td nzAlign="center">
                    <span>
                        {{ arquivo?.armazenamento ? (arquivo?.armazenamento | date: 'dd/MM/YYYY HH:mm') : '' }}
                    </span>
                    <br>
                    <span>
                        {{arquivo.tamanhoDescricao}}
                    </span>
                </td>
                <td nzAlign="center">{{ arquivo?.origem }}</td>
                <td nzAlign="center">{{ arquivo?.usuario }}</td>
                <td nzAlign="center">
                    <button nz-button
                            nzType="link"
                            *ngIf="arquivo.situacao === 1" [nzLoading]="loadingExport.includes(arquivo.driveArquivo_id)"
                            (click)="exportDocumento(arquivo?.driveArquivo_id, arquivo?.arquivoNome);">
                        <i nz-icon nzType="download" nzTheme="outline"></i>
                    </button>
                </td>
            </tr>
            </tbody>
            <ng-template #rangeTemplate let-range="range" let-total>
                {{ range[0] }}-{{ range[1] }} de {{ total }} Registros
            </ng-template>
        </nz-table>
    </nz-card>
</nz-content>
<nz-modal [(nzVisible)]="formFiltrosCabecalho.modalVisible"
          [nzTitle]="'Filtros'" [nzClosable]="true"
          (nzOnCancel)="fecharModal(formFiltrosCabecalho)"
          [nzWidth]="648">
    <nz-row *nzModalContent>
        <nz-col [nzSpan]="24">
            <form nz-form [nzNoColon]="false" [formGroup]="formFiltrosCabecalho.formGroup">
                <nz-form-item>
                    <nz-form-label [nzSpan]="5">Vencimento</nz-form-label>
                    <nz-form-control [nzSpan]="14"
                                     nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrosCabecalho.formGroup.value.vencimento">
                        <nz-date-picker [nzMode]="'month'" [nzFormat]="'MM/yyyy'"
                                        [(ngModel)]="formFiltrosCabecalho.formGroup.value.vencimento"
                                        formControlName="vencimento" style="width: 100%;"
                                        [nzAllowClear]="false"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="5">Categoria</nz-form-label>
                    <nz-form-control [nzSpan]="14"
                                     nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrosCabecalho.formGroup.value.categoria">
                        <nz-select nzShowSearch
                                   nzPlaceHolder="Selecione"
                                   formControlName="categoria"
                                   [(ngModel)]="formFiltrosCabecalho.formGroup.value.categoria"
                                   (ngModelChange)="clearInput(formFiltrosCabecalho.formGroup, 'categoria', 'obrigacao')">
                            <nz-option *ngFor="let opt of arraySelectCategorias"
                                       [nzLabel]="opt.descricao"
                                       [nzValue]="opt.categoria_id"
                            ></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="5">Obrigação</nz-form-label>
                    <nz-form-control [nzSpan]="14"
                                     nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrosCabecalho.formGroup.value.obrigacao">
                        <nz-select nzShowSearch
                                   nzPlaceHolder="Selecione"
                                   formControlName="obrigacao"
                                   [(ngModel)]="formFiltrosCabecalho.formGroup.value.obrigacao"
                                   (ngModelChange)="clearInput(formFiltrosCabecalho.formGroup, 'obrigacao', 'categoria')">
                            <nz-option *ngFor="let opt of arraySelectObrigacoes"
                                       [nzLabel]="opt.descricao"
                                       [nzValue]="opt.id"
                            ></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
            </form>
        </nz-col>
    </nz-row>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="fecharModal(formFiltrosCabecalho)">Cancelar</button>
        <button nz-button nzType="primary" (click)="filtrarCabecalho()">Filtrar</button>
    </div>
</nz-modal>
<nz-modal [(nzVisible)]="formFiltrosGrid.modalVisible"
          [nzTitle]="'Filtros Avançados'" [nzClosable]="true"
          (nzOnCancel)="fecharModal(formFiltrosGrid)"
          [nzWidth]="648">
    <nz-row *nzModalContent>
        <nz-col [nzSpan]="24">
            <form nz-form [nzNoColon]="false" [formGroup]="formFiltrosGrid.formGroup">
                <nz-form-item>
                    <nz-form-label [nzSpan]="5">Grupo</nz-form-label>
                    <nz-form-control [nzSpan]="14"
                                     nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrosGrid.formGroup.value.grupo">
                        <nz-select nzShowSearch
                                   nzAllowClear
                                   nzPlaceHolder="Selecione"
                                   formControlName="grupo">
                            <nz-option *ngFor="let opt of arraySelectGrupos"
                                       [nzLabel]="modoDemo(opt.descricao, 'total')"
                                       [nzValue]="opt.id"
                            ></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="5">CNPJ</nz-form-label>
                    <nz-form-control [nzSpan]="14"
                                     nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrosGrid.formGroup.value.empresa_id">
                        <nz-select nzShowSearch
                                   nzAllowClear
                                   nzPlaceHolder="Selecione"
                                   formControlName="empresa_id"
                                   [(ngModel)]="filtroEmpresaID">
                            <nz-option *ngFor="let opt of arraySelectCnpj"
                                       [nzLabel]="modoDemo(opt.descricao | cpfCnpj, 'cnpj')"
                                       [nzValue]="opt.id"
                            ></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="5">Empresa</nz-form-label>
                    <nz-form-control [nzSpan]="14"
                                     nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrosGrid.formGroup.value.empresa_id">
                        <nz-select nzShowSearch
                                   nzAllowClear
                                   nzPlaceHolder="Selecione"
                                   formControlName="empresa_id"
                                   [(ngModel)]="filtroEmpresaID">
                            <nz-option *ngFor="let opt of arraySelectEmpresa"
                                       [nzLabel]="modoDemo(opt.descricao, 'empresaCodigoNome')"
                                       [nzValue]="opt.id"
                            ></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="5">Competência</nz-form-label>
                    <nz-form-control [nzSpan]="14"
                                     nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrosGrid.formGroup.value.competencia">
                        <nz-date-picker [nzMode]="'month'" [nzFormat]="'MM/yyyy'"
                                        [(ngModel)]="formFiltrosGrid.formGroup.value.competencia"
                                        formControlName="competencia" style="width: 100%;"
                                        [nzAllowClear]="false"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="5">Situação</nz-form-label>
                    <nz-form-control [nzSpan]="14"
                                     nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrosGrid.formGroup.value.situacao">
                        <nz-select nzShowSearch
                                   nzAllowClear
                                   nzPlaceHolder="Selecione"
                                   formControlName="situacao"
                                   [(ngModel)]="formFiltrosGrid.formGroup.value.situacao">
                            <nz-option [nzLabel]="'Armazenada'"
                                       [nzValue]="'armazenadas'"
                            ></nz-option>
                            <nz-option [nzLabel]="'Não Armazenada'"
                                       [nzValue]="'nao-armazenadas'"
                            ></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="5">Origem</nz-form-label>
                    <nz-form-control [nzSpan]="14"
                                     nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrosGrid.formGroup.value.origem"
                    >
                        <nz-select nzShowSearch
                                   nzAllowClear
                                   nzPlaceHolder="Selecione"
                                   formControlName="origem">
                            <nz-option *ngFor="let opt of arraySelectOrigem"
                                       [nzLabel]="opt.label"
                                       [nzValue]="opt.value"
                            ></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="5">Usuário</nz-form-label>
                    <nz-form-control [nzSpan]="14"
                                     nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrosGrid.formGroup.value.usuario">
                        <nz-select nzShowSearch
                                   nzAllowClear
                                   nzPlaceHolder="Selecione"
                                   formControlName="usuario">
                            <nz-option *ngFor="let opt of arraySelectUsuarios"
                                       [nzLabel]="opt.nome"
                                       [nzValue]="opt.id"
                            ></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
            </form>
        </nz-col>
    </nz-row>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="fecharModal(formFiltrosGrid)">Cancelar</button>
        <button nz-button nzType="primary" (click)="filtrarGrid()">Filtrar</button>
    </div>
</nz-modal>
