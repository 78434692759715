import { Injectable } from '@angular/core';
import {
    ComplianceTributosGruposDetalhesService
} from '../compliance-tributos-grupos-detalhes/compliance-tributos-grupos-detalhes.service';
import {ComplianceTributosEmpresaService} from './complianceTributosEmpresa.service';

@Injectable()
export class DynamicComplianceServiceFactory {
    constructor(
        private complianceTributosEmpresaDetalheServ: ComplianceTributosEmpresaService,
        private complianceTributosGrupoDetalheServ: ComplianceTributosGruposDetalhesService
    ) {}

    getService(tipoTela: string) {
        if (tipoTela === 'grupo') {
            return this.complianceTributosGrupoDetalheServ;
        } else {
            return this.complianceTributosEmpresaDetalheServ;
        }
    }
}
