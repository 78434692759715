import {ChangeDetectorRef, Component, Input, NgZone, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {Tab} from '@models/tab.model';
import {TabService} from '@services/tab.service';
import {buildUrl, findComponentByUrl} from '../../../shared/components-helper';
import {TabHandlerInterface} from '../../../shared/interfaces/tab-handler.interface';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {HttpClient} from '@angular/common/http';
import {DataService} from '@services/data.service';
import {NzModalService} from 'ng-zorro-antd/modal';
import {NotasfiscaisEletronicasService} from './notasfiscaisEletronicas.service';
import {
    EditarNotasFiscaisEletronicasService
} from '../editarNotasFiscaisEletronicas/editarNotasFiscaisEletronicas.service';
import {Pagination} from '@models/pagination.model';
import {Subscription} from 'rxjs';
import {EditarSATService} from "../editarSAT/editarSAT.service";

interface FormStack {
    modalVisible: boolean;
    formGroup: FormGroup;
}


@Component({
    selector: 'app-cadastros-inscricoes-regimes',
    templateUrl: './notasFiscaisEletronicas.component.html',
    styleUrls: ['./notasFiscaisEletronicas.component.scss']
})
export class NotasFiscaisEletronicasComponent extends AbstractListTable<any> implements OnInit, TabHandlerInterface {
    readonly registerLink = '/usuarios/cadastrar';

    token;
    formFiltrar: FormStack;
    formAlterar: FormStack;
    formAlterarSAT: FormStack;
    qtdFiltrosAtivos = 0;
    usuarioLogado: any;
    @Input() data;
    optionsEmpresas = [];
    ultimaMensagemSefaz = '';
    modalUltimaMensagemTitulo = '';
    modalMensagemSefazVisible = false;
    statusCard = false;

    itemsSat: any;
    paginationSat = new Pagination();

    tipoSelecionado = 'DFE';

    loadingTable = false;
    tabAtiva = 0;

    loadingAlterar = false;

    arrayStatusCertidao = [
        {
            color: '#ff4961',
            descricao: 'Certificado Vencido'
        },
        {
            color: '#42ba96',
            descricao: 'Certificado Válido'
        },
        {
            color: '#1565c0',
            descricao: 'Certificado de Procurador'
        },
        {
            color: '#b4b4b4',
            descricao: 'Sem Certificado'
        },
        {
            color: '#0f724f',
            descricao: 'Certificado Matriz'
        },
    ];

    formExportar: FormStack;

    loadingExportar = false;

    checked = false;

    requestBt: Subscription;

    constructor(
        private fb: FormBuilder,
        private modalService: NzModalService,
        private service: NotasfiscaisEletronicasService,
        private toastService: ToastrService,
        private tabService: TabService,
        private cdr: ChangeDetectorRef,
        private zone: NgZone,
        private editarNFEervice: EditarNotasFiscaisEletronicasService,
        private editarSAT: EditarSATService,
        private dataService: DataService,
        protected http: HttpClient) {
        super(service, null, toastService);

        this.formFiltrar = {
            modalVisible: false,
            formGroup: this.fb.group({
                ativaDFE: [null, null],
                empresa_id: [null, null]
            })
        };

        this.formAlterar = {
            modalVisible: false,
            formGroup: this.fb.group({
                dataConsultaDFEInicio: [null, Validators.required],
                dataConsultaDFEFim: [null, null]
            })
        };

        this.formAlterarSAT = {
            modalVisible: false,
            formGroup: this.fb.group({
                intervaloConsulta: [null, Validators.required]
            })
        };

        this.formExportar = {
            modalVisible: false,
            formGroup: this.fb.group({
                tipo: [null, Validators.required],
            })
        };

        this.currentParams = {
            pageIndex: 1,
            pageSize: 50,
            sort: [],
            filter: []
        };
    }

    ngOnInit() {

        this.dataService.currentUser.subscribe((data) => {
            this.usuarioLogado = data;
        });

        this.service.retornarOptionEmpresas().subscribe((retorno: any) => {
            this.optionsEmpresas = [];
            this.optionsEmpresas = retorno;
        });

        this.queryTable(this.currentParams, this.currentSearch);

    }

    changeTabs(event: any) {
        this.requestBt?.unsubscribe();
        this.loadingTable = true;
        this.currentParams.pageIndex = 1;
        this.checkedItems.clear();
        this.checked = false;
        this.tabAtiva = event.index;

        if (this.tabAtiva === 0) {
            this.tipoSelecionado = 'DFE';
            this.queryTable(this.currentParams, this.currentSearch);
        } else {
            this.tipoSelecionado = 'SAT';
            this.queryTableSat(this.currentParams, this.currentSearch);
        }
    }

    listByTable(params: NzTableQueryParams) {
        let buscar = false;

        if (params.pageIndex === this.pagination.current_page) {
            params.sort.forEach(s => {
                if (s.value) {
                    buscar = true;
                }
            });
        }

        if ((buscar || params.pageIndex === 1 && this.pagination.current_page > 1)
            || params.pageIndex > 1
            && params.pageIndex !== this.pagination.current_page) {
            this.queryTable(params);
            this.pagination.current_page = params.pageIndex;
        }

    }

    listByTableSat(params: NzTableQueryParams) {
        let buscar = false;

        if (params.pageIndex === this.paginationSat.current_page) {
            params.sort.forEach(s => {
                if (s.value) {
                    buscar = true;
                }
            });
        }

        if ((buscar || params.pageIndex === 1 && this.paginationSat.current_page > 1)
            || params.pageIndex > 1
            && params.pageIndex !== this.paginationSat.current_page) {
            this.queryTableSat(params);
            this.paginationSat.current_page = params.pageIndex;
        }

    }

    showModalAlterar(visible: boolean) {
        if (this.tabAtiva === 0) {
            this.formAlterar.modalVisible = visible;
        } else {
            this.formAlterarSAT.modalVisible = visible;
        }
    }

    showConfirmAtivarDesativar(ativo: string): void {

        this.modalService.confirm({
            nzTitle: `Deseja ${ativo === '1' ? 'ativar' : 'desativar'} ${this.tipoSelecionado} para as notas fiscais selecionadas?`,
            nzOkText: 'Confirmar',
            nzCancelText: 'Cancelar',
            nzOnOk: () => this.tabAtiva === 0 ? this.confirmaAlterar(ativo) : this.confirmaAlterarSAT(ativo)
        });

    }

    confirmaAlterar(ativo: string = null) {

        this.loadingAlterar = true;

        const data: any = {};
        data.empresa_id = Array.from(this.checkedItems);

        if (ativo) {
            data.ativo = Number(ativo);
        }

        if (this.formAlterar.formGroup.get('dataConsultaDFEInicio').value) {
            data.horarioInicio = this.formAlterar.formGroup.get('dataConsultaDFEInicio').value;
            const inicio = new Date(data.horarioInicio).getHours();
            const fim = new Date(data.horarioInicio).getMinutes();
            data.horarioInicio = (inicio < 9 ? '0' + inicio : inicio) + ':' + (fim < 9 ? '0' + fim : fim);
        } else {
            data.horarioInicio = null;
        }

        if (this.formAlterar.formGroup.get('dataConsultaDFEFim').value) {
            data.horarioFim = this.formAlterar.formGroup.get('dataConsultaDFEFim').value;
            const inicio = new Date(data.horarioFim).getHours();
            const fim = new Date(data.horarioFim).getMinutes();
            data.horarioFim = (inicio < 9 ? '0' + inicio : inicio) + ':' + (fim < 9 ? '0' + fim : fim);
        } else {
            data.horarioFim = null;
        }

        this.editarNFEervice.alterar(data).subscribe((response) => {
            this.toastService.success(response.message);
            this.loadingAlterar = false;
            this.checkedItems.clear();
            this.showModalAlterar(false);
            this.formAlterar.formGroup.reset();

            this.queryTable(this.currentParams, this.currentSearch);

        }, error => {
            this.toastService.error(error.error.message);
            this.loadingAlterar = false;

        });

    }

    confirmaAlterarSAT(ativo: string = null) {

        this.loadingAlterar = true;

        const data: any = {};
        data.empresa_id = Array.from(this.checkedItems);

        if (ativo) {
            data.ativo = Number(ativo);
        }

        if (this.formAlterarSAT.formGroup.get('intervaloConsulta').value) {
            data.intervaloConsulta = this.formAlterarSAT.formGroup.get('intervaloConsulta').value;
        }

        this.editarSAT.alterarSat(data).subscribe((response) => {
            this.toastService.success(response.message);
            this.loadingAlterar = false;
            this.checkedItems.clear();
            this.showModalAlterar(false);
            this.formAlterarSAT.formGroup.reset();

            this.queryTableSat(this.currentParams, this.currentSearch);

        }, error => {
            this.toastService.error(error.error.message);
            this.loadingAlterar = false;

        });

    }

    openTab(componentName: string, queryParams?: string, data?: {}) {
        const component = findComponentByUrl(componentName);
        const url = buildUrl(component, queryParams);
        const newTab = new Tab(component.name, component.title, url, component.urlType, data);
        this.tabService.closeAndAddTab(newTab);
    }

    modalFiltrar(visible) {
        this.formFiltrar.modalVisible = visible;
    }

    confirmaFiltrar(): void {

        this.currentParams.pageIndex = 1;

        if (this.pageIndex === 0) {
            this.queryTable(this.currentParams, this.currentSearch);
        } else {
            this.queryTableSat(this.currentParams, this.currentSearch);
        }

        this.modalFiltrar(false);

    }

    onAllChecked(checked: boolean): void {
        const itens = this.items.filter(({disabled}) => !disabled);
        itens.forEach(item => {
            this.updateCheckedSet(item.id, checked);
        });
    }

    buscar() {
        this.currentParams.pageIndex = 1;
        if (this.pageIndex === 0) {
            this.queryTable(this.currentParams, this.currentSearch);
        } else {
            this.queryTableSat(this.currentParams, this.currentSearch);
        }
    }

    queryTable(params: NzTableQueryParams, search: string = null): void {

        this.currentParams = params;

        if (search) {
            this.currentSearch = search;
        }

        this.calculaBadgeFiltros();

        this.currentParams.filter = [];

        const filtros = this.formFiltrar.formGroup.value;

        for (const [chave, valor] of Object.entries(filtros)) {
            if (valor) {
                this.currentParams.filter.push({key: chave, value: valor});
            }
        }

        params.sort.forEach(s => {
            if (s.value) {
                this.currentParams.filter.push({key: 'ordenar', value: s.key});
                this.currentParams.filter.push({key: 'sentido', value: s.value});
            }
        });

        this.zone.run(() => {
            this.loadingTable = true;

            if (this.currentSearch) {
                this.currentParams.filter.push({key: 'procurar', value: this.currentSearch});
            }

            this.requestBt = this.service.listar(this.currentParams).subscribe((response) => {

                this.items = [];

                this.items = response.data;

                this.loadingTable = false;

                this.pagination = new Pagination(
                    response?.per_page || 50,
                    response?.current_page || 1,
                    response?.last_page || 1,
                    response?.total || 50,
                    response?.from || 1,
                    response?.to || 1);

                this.cdr.detectChanges();

            }, error => {
                this.loadingTable = false;
                this.toastService.error(error.error.message);
            });
        });

    }

    queryTableSat(params: NzTableQueryParams, search: string = null): void {

        this.currentParams = params;

        this.calculaBadgeFiltros();

        this.currentParams.filter = [];

        const filtros = this.formFiltrar.formGroup.value;

        for (const [chave, valor] of Object.entries(filtros)) {
            if (valor) {
                const key = this.tabAtiva === 1 && chave === 'ativaDFE' ? 'ativo' : chave;
                this.currentParams.filter.push({key, value: valor});
            }
        }

        params.sort.forEach(s => {
            if (s.value) {
                this.currentParams.filter.push({key: 'ordenar', value: s.key});
                this.currentParams.filter.push({key: 'sentido', value: s.value});
            }
        });


        this.loadingTable = true;

        if (search) {
            this.currentSearch = search;
            this.currentParams.filter.push({key: 'procurar', value: this.currentSearch});
        }

        this.requestBt = this.service.listarSat(this.currentParams).subscribe((response) => {

            this.itemsSat = [];

            this.itemsSat = response.data;

            this.loadingTable = false;

            this.paginationSat = new Pagination(
                response?.per_page || 50,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 50,
                response?.from || 1,
                response?.to || 1);

            this.cdr.detectChanges();

        }, error => {
            this.loadingTable = false;
            this.toastService.error(error.error.message);
        });
    }

    onItemChecked(id: string, checked: boolean): void {

        this.updateCheckedSet(id, checked);

    }

    updateCheckedSet(id: string, checked: boolean): void {

        if (checked) {

            this.checkedItems.add(id);

        } else {

            this.checkedItems.delete(id);

        }

        if (this.items.length === this.checkedItems.size) {
            this.checked = true;
        } else {
            this.checked = false;
        }
    }

    modalMsgSefaz(visible: boolean, msg?: string, titulo?: string) {
        if (!visible) {
            this.ultimaMensagemSefaz = null;
            this.modalUltimaMensagemTitulo = null;
        } else {
            this.ultimaMensagemSefaz = msg;
            this.modalUltimaMensagemTitulo = titulo;
        }
        this.modalMensagemSefazVisible = visible;
    }

    calculaBadgeFiltros(): void {

        this.qtdFiltrosAtivos = 0;

        if (typeof this.formFiltrar !== 'undefined') {
            for (const [chave, valor] of Object.entries(this.formFiltrar.formGroup.value)) {

                if (valor && chave) {
                    this.qtdFiltrosAtivos += 1;
                }
            }
        }

    }

    btnResetSearch() {

        this.checkedItems.clear();
        this.checked = false;

        this.currentSearch = null;
        this.currentParams = {
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            sort: [],
            filter: [],
        };

        this.formFiltrar.formGroup.reset();

        if (this.tabAtiva === 0) {
            this.queryTable(this.currentParams, this.currentSearch);
        } else {
            this.queryTableSat(this.currentParams, this.currentSearch);
        }

    }

    clickEvent() {

        this.statusCard = !this.statusCard;

    }

    modalExportar(visible: boolean): void {
        this.formExportar.modalVisible = visible;
        this.formExportar.formGroup.reset();
    }

    confirmaExportar(): void {

        if (this.formExportar.formGroup.valid) {

            this.loadingExportar = true;

            const tipo = this.formExportar.formGroup.value.tipo;

            this.service.exportExcel(
                this.formFiltrar.formGroup.value, this.currentSearch, tipo, this.tabAtiva)
                .subscribe((res) => {

                        this.loadingExportar = false;

                        this.toastService.success('Solicitação realizada com sucesso. Acompanhe na central de downloads.');

                        this.formExportar.modalVisible = false;

                        this.formExportar.formGroup.reset();

                        setTimeout(() => {
                            this.openTab('/downloadCenter', '', {});
                        }, 1000);

                    }, err => {
                        this.loadingExportar = false;
                        this.toastService.error('Erro ao tentar exportar dados.');
                    }
                );

        } else {
            for (const key in this.formExportar.formGroup.controls) {

                if (key) {

                    const campo = this.formExportar.formGroup.get(key);

                    campo.markAsDirty();
                    campo.updateValueAndValidity();

                }
            }
        }
    }

}
