import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Helpers} from '../../../core/helpers';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ComplianceTributosGruposService {

  constructor(protected http: HttpClient) { }

  getInfoGrupos(ano, filtros: any): Observable<any> {

    const queryStr = Helpers.montaQueryString(filtros);

    return this.http.get(`${environment.apiUrl}/compliance/grupos/dashboard/${ano}${queryStr}`);

  }
}
