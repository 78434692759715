<nz-page-header class="site-page-header" nzTitle="Cadastros" nzSubtitle="Gatilhos">
</nz-page-header>
<nz-content>
    <nz-card [nzLoading]="loading" class="ant-card-table fonte-doze"
             [nzExtra]="navBar" nzTitle="Gatilhos" [nzBodyStyle]="{'padding': loading ? '15px' : '0px'}">
        <ng-template #navBar>
            <div class="nav-item d-flex">
                <button nz-button nzType="primary"
                        (click)="showHideModal(formAddGatilho,true);">
                    Adicionar
                </button>
            </div>
        </ng-template>
        <nz-alert nzType="info" nzShowIcon [nzMessage]="checkedMessage"
                  *ngIf="checkedItems.size > 0">
            <ng-template #checkedMessage>
                <div nz-row nzAlign="middle" nzGutter="24">
                    <div nz-col nzSpan="24">
                        <strong class="text-primary"></strong>
                        {{ checkedItems.size }} Registros Selecionados
                    </div>
                </div>
            </ng-template>
        </nz-alert>
        <nz-table #basicTable
                  nzSize="small"
                  [nzData]="items"
                  [nzFrontPagination]="false"
                  (nzQueryParams)="queryTable($event)"
                  [nzShowTotal]="rangeTemplate">
            <thead>
            <tr>
                <th nzColumnKey="evento" nzAlign="center">Eventos</th>
                <th nzColumnKey="acao" nzAlign="center">Ações</th>
                <th nzColumnKey="obrigacoes" nzAlign="center">Obrigações</th>
                <th *ngIf="this.arrayModulos.includes('cnd')" nzColumnKey="certidoes" nzAlign="center">Certidões</th>
                <th nzColumnKey="empresas" nzAlign="center">Unidades</th>
                <th nzColumnKey="ativo" nzAlign="center">Ativo</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let gatilho of basicTable.data">
                <td nzAlign="center">
                    <div *ngFor="let evento of gatilho.eventos; index as i">
                        <span style="margin-right: 5px;">{{evento.nome}}</span>
                        <span *ngIf="i < (gatilho.eventos.length - 1)">
                            <strong>{{evento.condicao === 'AND' ? 'E' : (evento.condicao === 'OR' ? 'OU' : '')}}</strong>
                        </span>
                    </div>
                </td>

                <td nzAlign="center">
                    <div *ngFor="let acao of gatilho.acoes; index as i">
                        <span *ngIf="!acao.usuariosAprovacao && !acao.usuariosNotificacao"
                              style="margin-right: 5px;">{{acao.nome}}</span>
                        <span *ngIf="acao.usuariosAprovacao || acao.usuariosNotificacao"
                              style="margin-right: 5px;">{{acao.nome}}<strong>:</strong></span>
                        <nz-content *ngIf="acao.codigo === 'A4'">
                            <span *ngFor="let usuario of acao.usuariosAprovacao; index as i">
                                {{usuario.nome}}
                                <span *ngIf="i < (acao.usuariosAprovacao.length - 1)">
                                    <strong>{{acao.condicaoUsuariosAprovacao === 'AND' ? 'E' : (acao.condicaoUsuariosAprovacao === 'OR' ? 'OU' : '')}}</strong>
                                </span>
                            </span>
                        </nz-content>
                        <nz-content *ngIf="acao.codigo === 'A5'">
                            <span *ngFor="let usuario of acao.usuariosNotificacao; index as i">
                                {{usuario.nome}}
                                <span *ngIf="i < (acao.usuariosNotificacao.length - 1)">, </span>
                            </span>
                        </nz-content>
                        <span *ngIf="i < (gatilho.acoes.length - 1)">
                            <strong>{{acao.condicao === 'AND' ? 'E' : (acao.condicao === 'OR' ? 'OU' : '')}}</strong>
                        </span>
                    </div>
                </td>
                <td nzAlign="center">
                    <ng-template #zero>
                        <div style="color: #1890ff; cursor: default">
                            -
                        </div>
                    </ng-template>
                    <button nz-button nzType="link" *ngIf="gatilho.obrigacoes?.length else zero"
                            (click)="verObrigacoes(gatilho.obrigacoes)">{{gatilho.obrigacoes.length}}</button>
                </td>
                <td *ngIf="this.arrayModulos.includes('cnd')" nzAlign="center">
                    <button nz-button nzType="link" *ngIf="gatilho.certidoes?.length else zero"
                            (click)="verCertidoes(gatilho.certidoes)">{{gatilho.certidoes.length}}</button>
                </td>
                <td nzAlign="center">
                    <ng-template #zero>
                        <div style="color: #1890ff; cursor: default">
                            -
                        </div>
                    </ng-template>
                    <button nz-button nzType="link" *ngIf="gatilho.empresas ?.length else zero"
                            (click)="verUnidades(gatilho.empresas)">{{gatilho.empresas.length}}</button>
                </td>
                <td nzAlign="center">
                    <nz-switch [(ngModel)]="gatilho.ativo"
                               (ngModelChange)="toggleGatilho($event, gatilho.ids)"
                               nzCheckedChildren="Ativo" nzUnCheckedChildren="Inativo"
                               [nzLoading]="loadingAtivo[gatilho.ids]"></nz-switch>
                </td>
                <td nzRight nzAlign="center">
                    <button (click)="showHideModalEditar(formEditGatilho, true, gatilho)"
                            nz-button
                            nzType="link">
                        Editar
                    </button>
                    <button nz-button (click)="showConfirm(gatilho.ids)"
                            [nzLoading]="loadings.remover == gatilho.ids"
                            nzType="link">
                        Remover
                    </button>
                </td>
            </tr>
            </tbody>
            <ng-template #rangeTemplate let-range="range" let-total>
                {{ range[0] }}-{{ range[1] }} de {{ total }} Registros
            </ng-template>
        </nz-table>
    </nz-card>
</nz-content>
<nz-modal [(nzVisible)]="formAddGatilho.modalVisible"
          [nzTitle]="'Adicionar Gatilho'" [nzClosable]="true"
          (nzOnCancel)="showHideModal(formAddGatilho, false)"
          [nzWidth]="768">
    <div *nzModalContent>
        <form nz-form [nzNoColon]="true">
            <nz-row nzGutter="24" *ngIf="steps.selecionarEvento">
                <nz-col nzSpan="24">
                    <p nz-typography>
                        <strong>Quando:</strong>
                    </p>
                </nz-col>
            </nz-row>
            <nz-row nzGutter="24" *ngIf="steps.selecionarEvento">
                <nz-col nzSpan="24">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="4">Evento(s):</nz-form-label>
                        <nz-form-control [nzSpan]="16">
                            <nz-select nzMode="multiple" nzPlaceHolder="Selecione os eventos"
                                       [(ngModel)]="formAddGatilho.formGroup.value.eventos"
                                       [ngModelOptions]="{standalone: true}"
                                       (ngModelChange)="changeEvento($event, 'adicionar')">
                                <nz-option *ngFor="let option of comboEventos" [nzLabel]="option.label"
                                           [nzValue]="option.value"></nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                </nz-col>
                <nz-col nzSpan="24">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="4">Unidade(s):</nz-form-label>
                        <nz-form-control [nzSpan]="16">
                            <nz-select *ngIf="!todasAsUnidades else todas" nzShowSearch nzAllowClear
                                       nzMode="multiple"
                                       name="empresas"
                                       nzPlaceHolder="'Selecione'"
                                       [(ngModel)]="formAddGatilho.formGroup.value.empresas">
                                <nz-option *ngFor="let opt of arraySelectUnidades"
                                           [nzLabel]="modoDemo(opt.descricao, 'empresaCodigoNome')+ ' (' + (modoDemo(opt.empresa_cnpj | cpfCnpj, 'cnpj'))+')'"
                                           [nzValue]="opt.id"
                                ></nz-option>
                            </nz-select>
                            <ng-template #todas>
                                <nz-select nzShowSearch nzAllowClear
                                           [nzDisabled]="true"
                                           nzMode="multiple"
                                           name="empresas"
                                           nzPlaceHolder="Todas"
                                           [(ngModel)]="formAddGatilho.formGroup.value.empresas">
                                    <nz-option [nzLabel]="'Todas'"
                                               [nzValue]="null"
                                    ></nz-option>
                                </nz-select>
                            </ng-template>
                            <label (nzCheckedChange)="onChecked($event)" style="margin-top: 5px; margin-left: 330px"
                                   [(nzChecked)]="todasAsUnidades"
                                   nz-checkbox>Todas as unidades</label>
                        </nz-form-control>
                    </nz-form-item>
                </nz-col>
            </nz-row>
            <!--<nz-row nzGutter="24" *ngIf="steps.selecionarCondicaoEvento">
                <nz-col nzSpan="24">
                    <p nz-typography>
                        <strong>Selecione a condição que será aplicada no evento do gatilho.</strong>
                    </p>
                </nz-col>
            </nz-row>-->
            <nz-row nzGutter="24" *ngIf="steps.selecionarCondicaoEvento">
                <nz-col nzSpan="24">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="4">Condição:</nz-form-label>
                        <nz-form-control [nzSpan]="16">
                            <nz-radio-group [(ngModel)]="formAddGatilho.formGroup.value.condicaoEventos"
                                            [ngModelOptions]="{standalone: true}">
                                <label nz-radio nzValue="AND">Todos os eventos ocorrerem</label>
                                <label nz-radio nzValue="OR">Um dos eventos ocorrer</label>
                            </nz-radio-group>
                        </nz-form-control>
                    </nz-form-item>
                </nz-col>
            </nz-row>
            <nz-row nzGutter="24" *ngIf="steps.selecionarAcao">
                <nz-col nzSpan="24">
                    <p nz-typography>
                        <strong>Executar:</strong>
                    </p>
                </nz-col>
            </nz-row>
            <nz-row nzGutter="24" *ngIf="steps.selecionarAcao">
                <nz-col nzSpan="24">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="4">Açõe(s):</nz-form-label>
                        <nz-form-control [nzSpan]="16">
                            <nz-select nzMode="tags" nzPlaceHolder="Selecione as ações"
                                       [(ngModel)]="formAddGatilho.formGroup.value.acoes"
                                       [ngModelOptions]="{standalone: true}"
                                       (ngModelChange)="changeAcao($event)">
                                <nz-option *ngFor="let option of comboAcoes" [nzLabel]="option.label"
                                           [nzValue]="option.value"></nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                </nz-col>
            </nz-row>

            <nz-row nzGutter="24" *ngIf="steps.selecionarUsuarioAprovacao">
                <nz-col nzSpan="24">
                    <p nz-typography>
                        <strong>Para ação de Aprovação:</strong>
                    </p>
                </nz-col>
            </nz-row>
            <nz-row nzGutter="24" *ngIf="steps.selecionarUsuarioAprovacao">
                <nz-col nzSpan="24">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="4">Usuário(s):</nz-form-label>
                        <nz-form-control [nzSpan]="16">
                            <nz-select nzMode="tags" nzPlaceHolder="Informe os usuários"
                                       [(ngModel)]="formAddGatilho.formGroup.value.usuariosAprovacao"
                                       [ngModelOptions]="{standalone: true}"
                                       (ngModelChange)="changeUsuario($event)">
                                <nz-option *ngFor="let option of comboUsuariosAcoes" [nzLabel]="option.label"
                                           [nzValue]="option.value"></nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                </nz-col>
            </nz-row>
            <nz-row nzGutter="24" *ngIf="steps.selecionarUsuarioAprovacao && steps.selecionarCondicaoUsuarioAprovacao">
                <nz-col nzSpan="24">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="4">Condição:</nz-form-label>
                        <nz-form-control [nzSpan]="16">
                            <nz-radio-group [(ngModel)]="formAddGatilho.formGroup.value.condicaoUsuariosAprovacao"
                                            [ngModelOptions]="{standalone: true}"
                                            (ngModelChange)="condicaoHierarquia($event)">
                                <label nz-radio nzValue="AND">Todos aprovarem</label>
                                <label nz-radio nzValue="OR">Qualquer um aprovar</label>
                                <label nz-radio nzValue="ORDER">Por Hierarquia</label>
                            </nz-radio-group>
                        </nz-form-control>
                    </nz-form-item>
                </nz-col>
            </nz-row>
            <nz-row nzGutter="24" *ngIf="steps.selecionarUsuarioNotificacao">
                <nz-col nzSpan="24">
                    <p nz-typography>
                        <strong>Para ação de Notificação:</strong>
                    </p>
                </nz-col>
            </nz-row>
            <nz-row nzGutter="24" *ngIf="steps.selecionarUsuarioNotificacao">
                <nz-col nzSpan="24">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="4">Usuário(s):</nz-form-label>
                        <nz-form-control [nzSpan]="16">
                            <nz-select nzMode="tags" nzPlaceHolder="Informe os usuários"
                                       [(ngModel)]="formAddGatilho.formGroup.value.usuariosNotificacao"
                                       [ngModelOptions]="{standalone: true}">
                                <nz-option *ngFor="let option of comboUsuariosNotificacoes" [nzLabel]="option.label"
                                           [nzValue]="option.value"></nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                </nz-col>
            </nz-row>

            <!--            <nz-row nzGutter="24" *ngIf="steps.selecionarObrigacao">-->
            <!--                <nz-col nzSpan="24">-->
            <!--                    <nz-form-item>-->
            <!--                        <nz-form-label [nzSpan]="4">Obrigações:</nz-form-label>-->
            <!--                        <nz-form-control [nzSpan]="16">-->
            <!--                            <nz-select nzMode="tags" nzPlaceHolder="Selecione as obrigações"-->
            <!--                                       [(ngModel)]="formAddGatilho.formGroup.obrigacoes"-->
            <!--                                       [ngModelOptions]="{standalone: true}">-->
            <!--                                <nz-option *ngFor="let option of comboObrigacoes" [nzLabel]="option.label"-->
            <!--                                           [nzValue]="option.value"></nz-option>-->
            <!--                            </nz-select>-->
            <!--                        </nz-form-control>-->
            <!--                    </nz-form-item>-->
            <!--                </nz-col>-->
            <!--            </nz-row>-->

            <nz-row nzGutter="18"
                    *ngIf="comboObrigacoes.length && steps.selecionarObrigacao && eventosSelecionados.length > 0 && !eventosSelecionados.includes('E15')">
                <nz-col style="margin-left: -8px" nzSpan="1"></nz-col>
                <nz-col nzSpan="20">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="4">Obrigações:</nz-form-label>
                        <nz-transfer
                                [nzTitles]="['', 'selecionadas']"
                                [nzItemUnit]="'Obrigações'"
                                [nzItemsUnit]="'Obrigações'"
                                [nzDataSource]="comboObrigacoes"
                                [nzListStyle]="{ 'width.px': 218, 'height.px': 300 }"
                                [nzRender]="render"
                                (nzChange)="changeTranferObrigacoes($event, 'adicionar')"
                        >
                            <ng-template #render let-item>
                                {{ item.label }}
                            </ng-template>
                        </nz-transfer>
                    </nz-form-item>
                </nz-col>
            </nz-row>


            <nz-row nzGutter="18"
                    *ngIf="steps.selecionarObrigacao && eventosSelecionados.length > 0 && eventosSelecionados.includes('E15')">
                <nz-col style="margin-left: -8px" nzSpan="1"></nz-col>
                <nz-col nzSpan="20">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="4">Certidões:</nz-form-label>
                        <nz-transfer
                                [nzTitles]="['', 'selecionadas']"
                                [nzItemUnit]="'Certidões'"
                                [nzItemsUnit]="'Certidões'"
                                [nzDataSource]="comboCertidoes"
                                [nzListStyle]="{ 'width.px': 218, 'height.px': 300 }"
                                [nzRender]="render"
                                (nzChange)="changeTranferCertidoes($event, 'adicionar')"
                        >
                            <ng-template #render let-item>
                                {{ item.label }}
                            </ng-template>
                        </nz-transfer>
                    </nz-form-item>
                </nz-col>
            </nz-row>


        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="showHideModal(formAddGatilho, false)">Cancelar</button>
        <button [nzLoading]="loadings.adicionar" nz-button nzType="primary" (click)="adicionarGatilho()">Adicionar
        </button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formEditGatilho.modalVisible"
          [nzTitle]="'Editar Gatilho'" [nzClosable]="true"
          (nzOnCancel)="showHideModalEditar(formEditGatilho, false)"
          [nzWidth]="768">
    <div *nzModalContent>
        <form nz-form [nzNoColon]="true">
            <nz-row nzGutter="24" *ngIf="steps.selecionarEvento">
                <nz-col nzSpan="24">
                    <p nz-typography>
                        <strong>Quando:</strong>
                    </p>
                </nz-col>
            </nz-row>
            <nz-row nzGutter="24" *ngIf="steps.selecionarEvento">
                <nz-col nzSpan="24">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="4">Evento(s):</nz-form-label>
                        <nz-form-control [nzSpan]="16">
                            <nz-select nzMode="multiple" nzPlaceHolder="Selecione os eventos"
                                       [(ngModel)]="formEditGatilho.formGroup.value.eventos"
                                       [ngModelOptions]="{standalone: true}"
                                       (ngModelChange)="changeEvento($event, 'editar')">
                                <nz-option *ngFor="let option of comboEventos" [nzLabel]="option.label"
                                           [nzValue]="option.value"></nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                </nz-col>
                <nz-col nzSpan="24">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="4">Unidade(s):</nz-form-label>
                        <nz-form-control [nzSpan]="16">
                            <nz-select *ngIf="!todasAsUnidades else todasE" nzShowSearch nzAllowClear
                                       [nzDisabled]="todasAsUnidades"
                                       nzMode="multiple"
                                       name="empresas"
                                       nzPlaceHolder="{{todasAsUnidades ? 'Todos' : 'Selecione'}}"
                                       [(ngModel)]="formEditGatilho.formGroup.value.empresas">
                                <nz-option *ngFor="let opt of arraySelectUnidades"
                                           [nzLabel]="modoDemo(opt.descricao, 'empresaCodigoNome')+ ' (' + (modoDemo(opt.empresa_cnpj | cpfCnpj, 'cnpj'))+')'"
                                           [nzValue]="opt.id"
                                ></nz-option>
                            </nz-select>
                            <ng-template #todasE>
                                <nz-select nzShowSearch nzAllowClear
                                           [nzDisabled]="true"
                                           name="empresas"
                                           [(ngModel)]="formEditGatilho.formGroup.value.empresas"
                                           nzPlaceHolder="Todas">
                                    <nz-option [nzLabel]="'Todas'"
                                               [nzValue]="null"
                                    ></nz-option>
                                </nz-select>
                            </ng-template>
                            <label (nzCheckedChange)="onChecked($event)" style="margin-top: 5px; margin-left: 330px"
                                   [(nzChecked)]="todasAsUnidades"
                                   nz-checkbox>Todas as unidades</label>
                        </nz-form-control>
                    </nz-form-item>

                </nz-col>
            </nz-row>
            <!--<nz-row nzGutter="24" *ngIf="steps.selecionarCondicaoEvento">
                <nz-col nzSpan="24">
                    <p nz-typography>
                        <strong>Selecione a condição que será aplicada no evento do gatilho.</strong>
                    </p>
                </nz-col>
            </nz-row>-->
            <nz-row nzGutter="24" *ngIf="steps.selecionarCondicaoEvento">
                <nz-col nzSpan="24">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="4">Condição:</nz-form-label>
                        <nz-form-control [nzSpan]="16">
                            <nz-radio-group [(ngModel)]="formEditGatilho.formGroup.value.condicaoEventos"
                                            [ngModelOptions]="{standalone: true}">
                                <label nz-radio nzValue="AND">Todos os eventos ocorrerem</label>
                                <label nz-radio nzValue="OR">Um dos eventos ocorrer</label>
                            </nz-radio-group>
                        </nz-form-control>
                    </nz-form-item>
                </nz-col>
            </nz-row>
            <nz-row nzGutter="24" *ngIf="steps.selecionarAcao">
                <nz-col nzSpan="24">
                    <p nz-typography>
                        <strong>Executar:</strong>
                    </p>
                </nz-col>
            </nz-row>
            <nz-row nzGutter="24" *ngIf="steps.selecionarAcao">
                <nz-col nzSpan="24">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="4">Açõe(s):</nz-form-label>
                        <nz-form-control [nzSpan]="16">
                            <nz-select nzMode="tags" nzPlaceHolder="Selecione as ações"
                                       [(ngModel)]="formEditGatilho.formGroup.value.acoes"
                                       [ngModelOptions]="{standalone: true}"
                                       (ngModelChange)="changeAcao($event)">
                                <nz-option *ngFor="let option of comboAcoes" [nzLabel]="option.label"
                                           [nzValue]="option.value"></nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                </nz-col>
            </nz-row>
            <nz-row nzGutter="24" *ngIf="steps.selecionarUsuarioAprovacao">
                <nz-col nzSpan="24">
                    <p nz-typography>
                        <strong>Para ação de Aprovação:</strong>
                    </p>
                </nz-col>
            </nz-row>
            <nz-row nzGutter="24" *ngIf="steps.selecionarUsuarioAprovacao">
                <nz-col nzSpan="24">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="4">Usuário(s):</nz-form-label>
                        <nz-form-control [nzSpan]="16">
                            <nz-select nzMode="tags" nzPlaceHolder="Informe os usuários"
                                       [(ngModel)]="formEditGatilho.formGroup.value.usuariosAprovacao"
                                       [ngModelOptions]="{standalone: true}"
                                       (ngModelChange)="changeUsuario($event)">
                                <nz-option *ngFor="let option of comboUsuariosAcoes" [nzLabel]="option.label"
                                           [nzValue]="option.value"></nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                </nz-col>
            </nz-row>
            <nz-row nzGutter="24" *ngIf="steps.selecionarUsuarioAprovacao && steps.selecionarCondicaoUsuarioAprovacao">
                <nz-col nzSpan="24">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="4">Condição:</nz-form-label>
                        <nz-form-control [nzSpan]="16">
                            <nz-radio-group [(ngModel)]="formEditGatilho.formGroup.value.condicaoUsuariosAprovacao"
                                            [ngModelOptions]="{standalone: true}"
                                            (ngModelChange)="condicaoHierarquia($event)">
                                <label nz-radio nzValue="AND">Todos aprovarem</label>
                                <label nz-radio nzValue="OR">Qualquer um aprovar</label>
                                <label nz-radio nzValue="ORDER">Por Hierarquia</label>
                            </nz-radio-group>
                        </nz-form-control>
                    </nz-form-item>
                </nz-col>
            </nz-row>
            <nz-row nzGutter="24" *ngIf="steps.selecionarUsuarioNotificacao">
                <nz-col nzSpan="24">
                    <p nz-typography>
                        <strong>Para ação de Notificação:</strong>
                    </p>
                </nz-col>
            </nz-row>
            <nz-row nzGutter="24" *ngIf="steps.selecionarUsuarioNotificacao">
                <nz-col nzSpan="24">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="4">Usuário(s):</nz-form-label>
                        <nz-form-control [nzSpan]="16">
                            <nz-select nzMode="tags" nzPlaceHolder="Informe os usuários"
                                       [(ngModel)]="formEditGatilho.formGroup.value.usuariosNotificacao"
                                       [ngModelOptions]="{standalone: true}">
                                <nz-option *ngFor="let option of comboUsuariosNotificacoes" [nzLabel]="option.label"
                                           [nzValue]="option.value"></nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                </nz-col>
            </nz-row>

            <nz-row nzGutter="18"
                    *ngIf="eventosSelecionadosEditar.length > 0 && !eventosSelecionadosEditar.includes('E15')">
                <nz-col style="margin-left: -8px" nzSpan="1"></nz-col>
                <nz-col nzSpan="20">
                    <nz-form-item *ngIf="comboObrigacoes.length else carregandoObg">
                        <nz-form-label [nzSpan]="4">Obrigações:</nz-form-label>
                        <nz-transfer
                                [nzTitles]="['', 'selecionadas']"
                                [nzItemUnit]="'Obrigações'"
                                [nzItemsUnit]="'Obrigações'"
                                [nzDataSource]="comboObrigacoes"
                                [nzListStyle]="{ 'width.px': 218, 'height.px': 300 }"
                                [nzRender]="render"
                                (nzChange)="changeTranferObrigacoes($event, 'editar')"
                        >
                            <ng-template #render let-item>
                                {{ item.label }}
                            </ng-template>
                        </nz-transfer>
                    </nz-form-item>

                    <ng-template #carregandoObg>
                        <nz-form-label [nzSpan]="4">Obrigações:</nz-form-label>
                        <span style="color: #cdcdcd"> Carregando...</span>
                    </ng-template>
                </nz-col>
            </nz-row>


            <nz-row nzGutter="18"
                    *ngIf="eventosSelecionadosEditar.length > 0 && eventosSelecionadosEditar.includes('E15')">
                <nz-col style="margin-left: -8px" nzSpan="1"></nz-col>
                <nz-col nzSpan="20">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="4">Certidões:</nz-form-label>
                        <nz-transfer
                                [nzTitles]="['', 'selecionadas']"
                                [nzItemUnit]="'Certidões'"
                                [nzItemsUnit]="'Certidões'"
                                [nzDataSource]="comboCertidoes"
                                [nzListStyle]="{ 'width.px': 218, 'height.px': 300 }"
                                [nzRender]="render"
                                (nzChange)="changeTranferCertidoes($event, 'editar')"
                        >
                            <ng-template #render let-item>
                                {{ item.label }}
                            </ng-template>
                        </nz-transfer>
                    </nz-form-item>
                </nz-col>
            </nz-row>

        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="showHideModalEditar(formEditGatilho, false)">Cancelar</button>
        <button [nzLoading]="loadings.editar" nz-button nzType="primary" (click)="editarGatilho()">Editar</button>
    </div>
</nz-modal>

<nz-modal (nzOnCancel)="showModalObrigacoes(false)"
          [nzBodyStyle]="{'padding' : '0'}"
          [(nzVisible)]="modalObrigacoesVisible"
          [nzWidth]="600"
          nzTitle="Obrigações"
          class="modal-table">
    <ng-container *nzModalContent>
        <nz-table #basicTable
                  [nzData]="obrigacoes"
                  [nzFrontPagination]="false"
                  [nzShowPagination]="false"
                  style="overflow: auto; height: auto;">
            <thead>
            <tr>
                <th nzAlign="center" nzColumnKey="descricao">Descrição</th>
                <th nzAlign="center" nzColumnKey="orgao">Orgão</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let obrigacao of basicTable.data">
                <td nzAlign="center">
                    {{obrigacao?.descricao}}
                </td>
                <td nzAlign="center">
                    {{obrigacao?.orgao}}
                </td>
            </tr>
            </tbody>
        </nz-table>
    </ng-container>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="showModalObrigacoes(false)">Fechar</button>
    </div>
</nz-modal>

<nz-modal (nzOnCancel)="showModalUnidades(false)"
          [nzBodyStyle]="{'padding' : '0'}"
          [(nzVisible)]="modalUnidadesVisible"
          [nzWidth]="600"
          nzTitle="Unidades"
          class="modal-table">
    <ng-container *nzModalContent>
        <nz-table #basicTable
                  [nzData]="empresas"
                  [nzFrontPagination]="false"
                  [nzShowPagination]="false"
                  style="overflow: auto; height: auto;">
            <thead>
            <tr>
                <th nzAlign="left" nzColumnKey="empresa">Empresa</th>
                <th style="min-width: 200px" nzAlign="center" nzColumnKey="cnpj">CNPJ</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let unidade of basicTable.data">
                <td style="word-break: break-word" nzAlign="left">
                    {{unidade?.nome}}
                </td>
                <td style="word-break: keep-all;" nzAlign="center">
                    {{unidade?.cnpj | cpfCnpj}}
                </td>
            </tr>
            </tbody>
        </nz-table>
    </ng-container>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="showModalUnidades(false)">Fechar</button>
    </div>
</nz-modal>

<nz-modal (nzOnCancel)="showModalCertidoes(false)"
          [nzBodyStyle]="{'padding' : '0'}"
          [(nzVisible)]="modalCertidoesVisible"
          [nzWidth]="600"
          nzTitle="Certidões"
          class="modal-table">
    <ng-container *nzModalContent>
        <nz-table #basicTable
                  [nzData]="certidoes"
                  [nzFrontPagination]="false"
                  [nzShowPagination]="false"
                  style="overflow: auto; height: auto;">
            <thead>
            <tr>
                <th nzAlign="center" nzColumnKey="descricao">Descrição</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let certidao of basicTable.data">
                <td nzAlign="center">
                    {{certidao?.descricao}}
                </td>
            </tr>
            </tbody>
        </nz-table>
    </ng-container>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="showModalCertidoes(false)">Fechar</button>
    </div>
</nz-modal>
