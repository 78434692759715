<nz-page-header class="site-page-header" nzTitle="Compliance" nzSubtitle="Diagnóstico Fiscal">
</nz-page-header>
<nz-content>

    <ng-template #extraTab>
        <div style="margin: 10px 10px 0 0" class="d-flex align-items-center">
            <nz-alert nzType="info" *ngIf="checkedItems.size > 0 && tabNumber === 1" nzShowIcon
                      [nzMessage]="checkedMessage"
                      style="margin-right: 15px;">
                <ng-template #checkedMessage>
                    <div nz-row nzAlign="middle" nzGutter="24">
                        <div nz-col nzSpan="24">
                            <strong class="text-primary"></strong>
                            {{ checkedItems.size }}
                            <span *ngIf="checkedItems.size === 1">Registro selecionado</span>
                            <span *ngIf="checkedItems.size > 1">Registros selecionados</span>
                            <i nz-icon nzType="close" nzTheme="outline" style="margin-left: 10px; cursor: pointer"
                               (click)="closeSelecionados();"></i>
                        </div>
                    </div>
                </ng-template>
            </nz-alert>

            <nz-button-group *ngIf="tabNumber === 1 else tabZero">
                <button nz-button nzType="primary" nzGhost
                        style="border-top-left-radius: 0.45rem;border-bottom-left-radius: 0.45rem;"
                        (click)="showModal('novoDiagnostico',true);"
                >
                    <i nz-icon nzType="plus" nzTheme="outline"></i>
                    Novo
                </button>

                <button [nzDropdownMenu]="menuAcoes" nzType="primary" nzGhost
                        nz-button
                        nz-dropdown>
                    Opções
                    <em nz-icon nzType="down"></em>
                </button>

                <nz-badge [nzCount]="qtdFiltrosAtivos" [nzStyle]="{ backgroundColor: '#52c41a' }">
                    <button nz-button nzType="primary" nzGhost (click)="showModal('filtrarDiagnosticos',true);"
                            style="border-top-right-radius: 0.45rem;border-bottom-right-radius: 0.45rem;">
                        <i nz-icon nzType="search"></i>
                        Filtros
                    </button>
                </nz-badge>
                <nz-dropdown-menu #menuAcoes="nzDropdownMenu">
                    <ul nz-menu>
                        <li nz-submenu nzTitle="Manutenção Coletiva">
                            <ul>
                                <li nz-menu-item nzDisabled>Ações</li>
                                <li nz-menu-divider></li>
                                <li [nzDisabled]="checkedItems?.size === 0"
                                    nz-menu-item (click)="reprocessar();">
                                    Reprocessar
                                </li>
                                <li [nzDisabled]="checkedItems?.size === 0 || loadings.removendoDiagnostico.length > 0"
                                    nz-menu-item
                                    (click)="confirmRemover([], 'Deseja remover os registros selecionados?');">
                                    Remover
                                </li>

                            </ul>
                        </li>
                    </ul>
                </nz-dropdown-menu>
            </nz-button-group>

            <ng-template #tabZero>
                <nz-button-group>
                    <button nz-button nzType="primary" nzGhost
                            style="border-radius: 0.45rem;"
                            (click)="showModal('novoDiagnostico',true);"
                    >
                        <i nz-icon nzType="plus" nzTheme="outline"></i>
                        Novo
                    </button>
                </nz-button-group>

            </ng-template>
        </div>
    </ng-template>

    <nz-tabset [nzSelectedIndex]="tabNumber" [nzTabBarExtraContent]="extraTab">
        <nz-tab (nzSelect)="changeTabs(0)" nzTitle="Empresas" [nzDisabled]="loadings.empresas">
            <nz-card *ngIf="tabNumber === 0" [nzLoading]="loadings.empresas" [nzTitle]="'Empresas'"
                     [ngClass]="[statusCard ? 'card-fullscreen' : 'ant-card-table']" [nzExtra]="navBar"
                     [nzBodyStyle]="{'padding': loadings.empresas || loadings.diagnosticos ? '15px' : '0px'}">

                <ng-template #navBar>
                    <div class="nav-item d-flex">
                        <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" style="margin-right: 10px;">
                            <input (keydown.enter)="getEmpresas(currentParamsEmpresas, currentSearchEmpresas)"
                                   type="text" nz-input
                                   placeholder="Busca" [(ngModel)]="currentSearchEmpresas">
                        </nz-input-group>
                        <ng-template #suffixIconButton>
                            <button nz-button nzType="primary" nzSearch
                                    (click)="getEmpresas(currentParamsEmpresas, currentSearchEmpresas);">
                                <i nz-icon nzType="search"></i></button>
                        </ng-template>
                        <button nz-button nzType="default" style="margin-right: 10px;"
                                (click)="btnResetSearchEmpresas();">
                            Limpar
                        </button>
                        <button nz-button [nzType]="'default'" (click)="clickEvent()"
                                style="padding-left: 8px; padding-right: 8px;">
                            <i class="fas" [ngClass]="!statusCard ? 'fa-expand' : 'fa-compress'"></i>
                        </button>
                    </div>
                </ng-template>

                <nz-table #tableEmpresas
                          [nzData]="empresas"
                          [nzFrontPagination]="false"
                          [nzTotal]="paginationEmpresas?.total"
                          [nzPageSize]="paginationEmpresas?.per_page"
                          [nzPageIndex]="paginationEmpresas?.current_page"
                          (nzQueryParams)="listByTableEmpresas($event)"
                          [nzShowTotal]="rangeTemplateEmpresas"
                          class="table-small">
                    <thead>
                    <tr>
                        <th nzColumnKey="nome" style="max-width: 200px;" [nzSortFn]="true">Nome</th>
                        <th nzAlign="center" nzColumnKey="cnpj" [nzSortFn]="true">CNPJ</th>
                        <th nzAlign="center" nzColumnKey="qtd_diagnosticos" [nzSortFn]="true">Diagnósticos</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let empresa of tableEmpresas.data">
                        <td style="text-align: left; max-width: 200px;">
                    <span style="cursor: pointer; color: #1e9ff2; word-break: break-word; max-width: 200px;"
                          (click)="openTab('/administracao/empresa/detalhe', empresa.id, {id: empresa.id, cnpj:empresa.cnpj})">
                        {{ empresa.nome }}
                    </span>
                        </td>
                        <td nzAlign="center" style="white-space: nowrap;">{{ empresa.cnpj | cpfCnpj }}</td>
                        <td nzAlign="center">
                            <a *ngIf="empresa.qtd_diagnosticos > 0 else semDiagnosticos" nz-button nzType="link"
                               (click)="filtrarPorEmpresa(empresa.id)"
                               [nzLoading]="loadings.diagnosticosPorEmpresa.includes(empresa.id)">
                                {{ empresa.qtd_diagnosticos }}
                            </a>
                            <ng-template #semDiagnosticos>
                        <span style="color: #8c8c8c">
                            0
                        </span>
                            </ng-template>
                        </td>
                    </tr>
                    </tbody>
                </nz-table>

                <ng-template #rangeTemplateEmpresas let-range="range" let-total>
                    {{ range[0] }}-{{ range[1] }} de {{ total }} Registros
                </ng-template>
            </nz-card>
        </nz-tab>
        <nz-tab (nzSelect)="changeTabs(1)" nzTitle="Diagnósticos" [nzDisabled]="loadings.diagnosticos">
            <div *ngIf="tabNumber === 1 && diagnosticos?.length > 0 else semDados">
                <nz-card *ngFor="let item of diagnosticos" class="card-row" [nzLoading]="loadings.diagnosticos"
                         [ngClass]="checkedItems.has(item.id) && 'selected'">
                    <nz-row [nzGutter]="24" class="row-diagnostico">
                        <nz-col nzXs="24" nzMd="13" nzLg="8"
                                (click)="onCardChecked(item.id)">
                            <div class="col-l">
                                <div class="d-flex align-items-center">
                                    <div style="height: 45px;border: 1px rgba(0,0,0,.45) solid;border-radius: 50%;min-width: 45px;"
                                         class="d-flex justify-content-center align-items-center">
                                        <svg width="40" height="40" [data-jdenticon-value]="item.empresa_nome"></svg>
                                    </div>
                                    <div style="margin-left: 15px;">
                                        <div style="margin-bottom: 10px">
                                            <strong>
                                                {{ item.empresa_nome }}
                                            </strong>
                                            ({{ item?.empresa_cnpj | cpfCnpj }})
                                        </div>
                                        <div style="margin-bottom: 10px">
                                            <span *ngIf="item.dataInicio != item.dataFim else umAno"
                                                  style="margin-right: 5px; font-weight: 500; color: #6b6f82;">
                                                <i class="far fa-calendar"></i>
                                                {{ (item.dataInicio | date: 'yyyy') + ' à ' + (item.dataFim | date: 'yyyy') }}
                                            </span>
                                            <ng-template #umAno>
                                                <span style="margin-right: 5px; font-weight: 500; color: #6b6f82;">
                                                <i class="far fa-calendar"></i>
                                                    {{ (item.dataInicio | date: 'yyyy') }}
                                            </span>
                                            </ng-template>
                                            <nz-tag *ngIf="item.status || item.status?.toString() === '0' else semStatus"
                                                    nzColor="{{statusBook[item.status].color}}">{{ statusBook[item.status].descricao }}
                                            </nz-tag>
                                            <ng-template #semStatus>
                                                <span>-</span>
                                            </ng-template>
                                        </div>
                                        <div>
                                            <span style="font-weight: 500; color: #6b6f82;">{{ item.usuario_nome || '-' }}
                                                solicitou em {{ (item.created_at | date:'dd/MM/yyyy HH:mm') }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </nz-col>
                        <nz-col nzXs="22" nzMd="9" nzLg="15"
                                (click)="onCardChecked(item.id)">
                            <div class="col-r">
                                <div nz-row class="info-card-p">
                                    <div nz-col [nzSpan]="24"
                                         class="d-flex align-items-center justify-content-center"
                                         style="height: 60px">
                                        <i class="far fa-calendar-alt"
                                           style="font-size: 60px; margin-right: 12px; color:#1e9ff2 !important;"></i>
                                        <div style="margin-right: 10px; padding-right: 10px;">
                                            <h4 class="custom-card-title text-muted title-align"
                                                style="margin: 0; line-height: 1; ">
                                                Obrigações
                                            </h4>
                                            <div class="value-align">
                                                <h1 nz-typography class="custom-card-value"
                                                    style="margin: 0; color: #1e9ff2 !important;">
                                                    {{ (item.dados?.obrigacoes | number) || '-' }}
                                                </h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div nz-row class="info-card info-card-a">
                                    <div nz-col [nzSpan]="15"
                                         class="d-flex justify-content-center align-items-center">
                                        <i class="far fa-times-circle"
                                           style="font-size: 60px; margin-right: 15px; color: #ff9149 !important;"></i>
                                        <div>
                                            <h4 class="custom-card-title text-muted title-align"
                                                style="margin: 0; line-height: 1;">
                                                Ausências
                                            </h4>
                                            <h1 nz-typography class="custom-card-value"
                                                style="margin: 0; color: #ff9149 !important;">
                                                {{ (item.dados?.ausencias | number) || '-' }}
                                            </h1>
                                        </div>
                                    </div>
                                    <div nz-col [nzSpan]="9"
                                         class="d-flex justify-content-center align-items-center"
                                         style="padding-left: 25px;">
                                        <nz-progress [nzPercent]="(item.percent_ausencias | number: '1.0-0') || '-' "
                                                     nzType="circle"
                                                     [nzWidth]="60"
                                                     [nzFormat]="formatOne"
                                                     [nzStrokeColor]="'#ff9149'"></nz-progress>
                                    </div>
                                </div>
                                <div nz-row class="info-card">
                                    <div nz-col [nzSpan]="15"
                                         class="d-flex justify-content-center align-items-center justify-content-center">
                                        <i class="fas fa-not-equal"
                                           style="font-size: 60px; color: #ff4961 !important;"></i>
                                        <div style="margin-left: 10px">
                                            <h4 class="custom-card-title text-muted title-align"
                                                style="margin: 0; line-height: 1;">
                                                Divergências
                                            </h4>
                                            <h1 nz-typography class="custom-card-value"
                                                style="margin: 0; color: #ff4961 !important;">
                                                {{ (item.dados?.divergencias | number) || "-" }}
                                            </h1>
                                        </div>
                                    </div>
                                    <div nz-col [nzSpan]="9"
                                         class="d-flex justify-content-center align-items-center"
                                         style="padding-left: 25px;">
                                        <nz-progress [nzPercent]="(item.percent_divergencias | number: '1.0-0') || '-'"
                                                     nzType="circle"
                                                     [nzWidth]="60"
                                                     [nzFormat]="formatOne"
                                                     [nzStrokeColor]="'#ff4961'"></nz-progress>
                                    </div>
                                </div>
                            </div>
                        </nz-col>
                        <nz-col nzXs="1" nzMd="1" nzSm="1"
                                style="display: flex; flex-direction: row; align-items: center; justify-content: space-around; border-left: 1px solid #e0e0e0">
                            <i *ngIf="item.status === 2 && !loadings.baixandoDiagnostico.includes(item.id) else loadingDownload"
                               [nz-tooltip]="'Download'"
                               style="color: #1e9ff2; margin-left: 15px"
                               class="fa fa-download" (click)="baixarDiagnostico(item)"></i>

                            <i *ngIf="item.status != 1 && (item.status != 2 || usuarioLogado.origem == 'console') && !loadings.removendoDiagnostico.includes(item.id) else loading"
                               [nz-tooltip]="'Remover'"
                               style="color: #eb1a2b; margin-left: 15px" class="fa fa-trash"
                               (click)="confirmRemover([item.id], 'Deseja remover o registro selecionado?')"></i>

                            <ng-template #loading>
                                <span *ngIf="item.status != 1" nz-icon nzType="loading" nzTheme="outline"></span>
                            </ng-template>

                            <ng-template #loadingDownload>
                                <span *ngIf="item.status == 2" nz-icon nzType="loading" nzTheme="outline"></span>
                            </ng-template>
                        </nz-col>
                    </nz-row>
                </nz-card>
            </div>

            <nz-pagination *ngIf="diagnosticos?.length > 0" style="float: right; margin-bottom: 50px"
                           (nzPageIndexChange)="changePageD($event)"
                           [nzPageIndex]="paginationDiagnosticos.current_page"
                           [nzTotal]="paginationDiagnosticos.total"></nz-pagination>

            <ng-template #semDados>
                <div *ngIf="tabNumber === 1 && !loadings.diagnosticos"
                     style="font-size: 1.3em; color: #8c8c8c; text-align: center; height: 250px; padding-top: 50px">
                    Sem diagnósticos solicitados
                </div>
                <div *ngIf="tabNumber === 1 && loadings.diagnosticos"
                     style="font-size: 3em; color: #8c8c8c; text-align: center; height: 250px; padding-top: 150px">
                    <span nz-icon nzType="loading" nzTheme="outline"></span>
                </div>
            </ng-template>
        </nz-tab>
    </nz-tabset>

</nz-content>

<nz-modal [(nzVisible)]="modalVisible.filtrarDiagnosticos"
          [nzTitle]="'Filtros Avançados'" [nzClosable]="true"
          (nzOnCancel)="showModal('filtrarDiagnosticos', false)"
          [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzNoColon]="true" [formGroup]="formFiltrar" style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Empresa</nz-form-label>
                <nz-form-control [nzSpan]="15"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.value.empresa">
                    <nz-select nzShowSearch nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="empresa">
                        <nz-option *ngFor="let opt of optionsEmpresas"
                                   [nzLabel]="opt.nome + ' (' + (opt.cnpj | cpfCnpj) + ')'"
                                   [nzValue]="opt.id"
                                   nzCustomContent>
                            {{ opt.nome }}
                            <br><small>({{ opt.cnpj | cpfCnpj }})</small>
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="5">Status</nz-form-label>
                <nz-form-control [nzSpan]="15"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.value.status">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="status">
                        <nz-option *ngFor="let s of statusBook" [nzLabel]="s.descricao"
                                   [nzValue]="s.key"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="5">Ano Início</nz-form-label>
                <nz-form-control [nzSpan]="15" nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.value.dataInicio">
                    <nz-date-picker [nzFormat]="'yyyy'" nzMode="year"
                                    formControlName="dataInicio" style="width: 100%;"></nz-date-picker>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Ano Fim</nz-form-label>
                <nz-form-control [nzSpan]="15" nzValidateStatus="success" [nzHasFeedback]="formFiltrar.value.dataFim">
                    <nz-date-picker [nzFormat]="'yyyy'" nzMode="year"
                                    formControlName="dataFim"
                                    style="width: 100%;"></nz-date-picker>
                </nz-form-control>
            </nz-form-item>

        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="showModal('filtrarDiagnosticos', false)">Cancelar</button>
        <button [nzLoading]="loadings.novoDiagnostico" nz-button nzType="primary" (click)="confirmaFiltrar()">Filtrar
        </button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="modalVisible.novoDiagnostico"
          [nzTitle]="'Solicitar Novo Diagnóstico'" [nzClosable]="true"
          (nzOnCancel)="showModal('novoDiagnostico', false)"
          [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzAutoTips]="autoTips" [nzNoColon]="true" [formGroup]="formNovoDiagnostico" style="width: 100%;">
            <nz-form-item>
                <nz-form-label nzRequired [nzSpan]="5">Empresa</nz-form-label>
                <nz-form-control [nzSpan]="15">
                    <nz-select nzShowSearch nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="empresa_id">
                        <nz-option *ngFor="let opt of optionsEmpresas"
                                   [nzLabel]="opt.nome + ' (' + (opt.cnpj | cpfCnpj) + ')'"
                                   [nzValue]="opt.id"
                                   nzCustomContent>
                            {{ opt.nome }}
                            <br><small>({{ opt.cnpj | cpfCnpj }})</small>
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="5">Intervalo</nz-form-label>
                <nz-form-control [nzSpan]="15">
                    <nz-radio-group formControlName="intervalo">
                        <label nz-radio nzValue="p">Período</label>
                        <label nz-radio nzValue="a">Um Ano</label>
                    </nz-radio-group>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="5">Orgão</nz-form-label>
                <nz-form-control [nzSpan]="15">
                    <nz-select formControlName="orgao">
                        <nz-option [nzValue]="'estadual'" [nzLabel]="'Estadual'"></nz-option>
                        <nz-option [nzValue]="'federal'" [nzLabel]="'Federal'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>


            <div *ngIf="formNovoDiagnostico.value.intervalo === 'p' else umAno">
                <nz-form-item>
                    <nz-form-label nzRequired [nzSpan]="5">Ano Início</nz-form-label>
                    <nz-form-control [nzSpan]="15">
                        <nz-select formControlName="dataInicio" style="width: 100%;">
                            <nz-option *ngFor="let a of ultimosCincoAnos"
                                       [nzLabel]="a" [nzValue]="a"
                            ></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label nzRequired [nzSpan]="5">Ano Fim</nz-form-label>
                    <nz-form-control [nzSpan]="15">
                        <nz-select formControlName="dataFim" style="width: 100%;">
                            <nz-option *ngFor="let a of ultimosCincoAnos"
                                       [nzLabel]="a" [nzValue]="a"
                            ></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
            </div>

            <ng-template #umAno>
                <nz-form-item>
                    <nz-form-label [nzSpan]="5">Ano</nz-form-label>
                    <nz-form-control [nzSpan]="15" nzValidateStatus="success"
                                     [nzHasFeedback]="formNovoDiagnostico.value.dataInicio">
                        <nz-select formControlName="dataInicio" style="width: 100%;">
                            <nz-option *ngFor="let a of ultimosCincoAnos"
                                       [nzLabel]="a" [nzValue]="a"
                            ></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
            </ng-template>

        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="showModal('novoDiagnostico', false)">Cancelar</button>
        <button [nzLoading]="loadings.novoDiagnostico" nz-button nzType="primary" (click)="novoDiagnostico()">
            Solicitar
        </button>
    </div>
</nz-modal>
