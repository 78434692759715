import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AbstractService} from './abstract.service';
import Equipe from '@models/equipe.model';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({providedIn: 'root'})
export class FiltrosService extends AbstractService<Equipe> {

    constructor(http: HttpClient) {
        super(http, '/filtros', Equipe);
    }

    equipes(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/filtros/equipes/select`);
    }

    responsaveis(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/filtros/responsaveis/select`);
    }

}
