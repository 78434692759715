import {Component, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import {Empresa} from '@models/empresa.model';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ConfiguracaoSsoService} from './configuracao-sso.service';
import {environment} from '../../../../../environments/environment';
import {NzUploadFile} from 'ng-zorro-antd/upload';
import {AuthenticationService} from "../../../../core/auth/authentication.service";


interface FormStack {
    modalVisible: boolean;
    formGroup: UntypedFormGroup;
}

@Component({
    selector: 'app-admin-configuracao-sso',
    templateUrl: './configuracao-sso.component.html',
    styleUrls: ['./configuracao-sso.component.scss']
})

export class ConfiguracaoSsoComponent extends AbstractListTable<Empresa> implements OnInit {
    tabAtiva: number;
    configuracoes: {
        ativado: boolean,
        type: string,
        idp_entity_id: string,
        idp_login_url: string,
        idp_logout_url: string,
        idp_x509_cert: string,
        name_x509_cert: string,
        id: string
    } = {
        ativado: false,
        type: '',
        idp_entity_id: '',
        idp_login_url: '',
        idp_logout_url: '',
        idp_x509_cert: '',
        name_x509_cert: '',
        id: ''
    };

    modalAlterar: {
        visible: boolean,
        loadingModal: boolean,
        loadingButton: boolean,
        showUpload: boolean,
        fileName: string,
        formGroup: UntypedFormGroup,
    };
    apiUrl: string = environment.apiUrl;
    fileListCertificado: NzUploadFile[] = [];

    loadings: { dados: boolean, download: boolean } = {dados: false, download: false};
    identificador: string;

    constructor(
        private fb: UntypedFormBuilder,
        private service: ConfiguracaoSsoService,
        private authenticationService: AuthenticationService,
        private toastService: ToastrService) {

        super(service, {}, toastService);

        this.modalAlterar = {
            visible: false,
            loadingModal: false,
            loadingButton: false,
            showUpload: true,
            fileName: '',
            formGroup: this.fb.group({
                status: [0, Validators.required],
                idp_entity_id: [null, Validators.required],
                idp_login_url: [null, Validators.required],
                idp_logout_url: [null, Validators.required],
                type: [null, Validators.required],
            }),
        };

    }

    ngOnInit() {

        this.getSSO();

        this.identificador = this.authenticationService.getIdentificadorByUrl(window.location.href);

    }

    async getSSO(loading: boolean = true) {

        try {
            this.loadings.dados = loading;

            const res = await this.service.getSSO().toPromise();

            this.configuracoes = {
                ativado: (res.existe && res.status === 1) ?? false,
                idp_entity_id: res.idp_entity_id ?? '',
                idp_login_url: res.idp_login_url ?? '',
                idp_logout_url: res.idp_logout_url ?? '',
                idp_x509_cert: res.idp_x509_cert ?? '',
                name_x509_cert: res.name_x509_cert ?? '',
                id: res.id ?? '',
                type: res.type ?? '',
            };


            this.loadings.dados = false;
        } catch (e) {
            this.loadings.dados = false;
            this.toastService.error(e.error.message);

        }


    }

    async onModalAlterar(visible: boolean) {

        this.modalAlterar.visible = visible;
        this.fileListCertificado = [];
        this.modalAlterar.showUpload = true;

        if (visible) {
            this.modalAlterar.loadingModal = true;
            await this.getSSO(false);
            this.modalAlterar.loadingModal = false;
            this.modalAlterar.formGroup.get('status').setValue(this.configuracoes.ativado ? 1 : 0);
            this.modalAlterar.formGroup.get('idp_entity_id').setValue(this.configuracoes.idp_entity_id ?? '');
            this.modalAlterar.formGroup.get('idp_login_url').setValue(this.configuracoes.idp_login_url ?? '');
            this.modalAlterar.formGroup.get('idp_logout_url').setValue(this.configuracoes.idp_logout_url ?? '');
            this.modalAlterar.formGroup.get('type').setValue(this.configuracoes.type ?? '');


            if (this.configuracoes.idp_x509_cert && this.configuracoes.name_x509_cert) {
                const file: any = atob(this.configuracoes.idp_x509_cert);
                this.fileListCertificado.push(file);
                this.modalAlterar.showUpload = false;
                this.modalAlterar.fileName = this.configuracoes.name_x509_cert;
            }


        }

    }

    async downloadFile(base64: any, fileName: any) {
        this.loadings.download = true;
        const src = `data:text/csv;base64,${base64}`;
        const link = document.createElement('a');
        link.href = src;
        link.download = fileName;
        link.click();
        link.remove();
        setTimeout(() => {
            this.loadings.download = false;
        }, 1000);
    }


    beforeUploadCertificado = (file: NzUploadFile): boolean => {

        if (!file.name.toLowerCase().endsWith('.cer')) {
            this.toastService.error('Envie apenas arquivos .cer');
            return false;
        }
        this.fileListCertificado.push(file);
        this.modalAlterar.showUpload = false;
        this.modalAlterar.fileName = file.name;
        return false;
    }

    removerAnexo(): void {
        this.fileListCertificado = [];
        this.modalAlterar.showUpload = true;
        this.modalAlterar.fileName = null;
    }

    confirmaSalvar() {

        this.modalAlterar.loadingButton = true;


        const formData = new FormData();

        this.fileListCertificado.forEach((file: any) => {
            formData.append('cert', file);
        });

        Object.entries(this.modalAlterar.formGroup.value).forEach(([key, value]: [string, any]) => {

            if (key === 'status') {
                value = value ? 1 : 0;
            }
            formData.append(key, value);
        });

        this.service.salvar(formData).subscribe(async (res: any) => {
            await this.getSSO(false);
            this.modalAlterar.loadingButton = false;
            this.toastService.success('Dados alterados com sucesso.');
            this.onModalAlterar(false);
        }, (err) => {
            this.modalAlterar.loadingButton = false;
            this.toastService.error(err.error.message);
        });


    }
}
