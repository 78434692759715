import {inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {Helpers} from '../../../core/helpers';

@Injectable({
    providedIn: 'root'
})
export class NotasFiscaisEntradaService {

    http = inject(HttpClient);

    getCategorias(filtros: any): Observable<any> {
        delete filtros.intervalo;

        const filtorsBody = {};

        Object.entries(filtros).forEach(([key, value]) => {
            if (value === null) {
                delete filtros[key];
            } else if (filtros[key] instanceof Date) {
                filtros[key] = Helpers.dataToFilter(filtros[key]);
            } else if (['chave', 'notas'].includes(key)) {
                const valor = String(value).split(',');
                if (valor[0].length > 0) {
                    filtorsBody[key] = valor;
                }
                delete filtros[key];
            }
        });

        const queryStr = Helpers.montaQueryString(filtros);

        return this.http.post(`${environment.apiUrl}/notas/workflow/cabecalho${queryStr}`, filtorsBody);
    }

    getEntradas(filtros: any): Observable<any> {

        const dados: any = {};

        if (filtros) {


            filtros.forEach(f => {
                if (f) {

                    if (f.value instanceof Date) {
                        f.value = Helpers.dataToFilter(f.value);
                    }
                    dados[f.key] = f.value;
                }
            });

        }


        return this.http.post(`${environment.apiUrl}/notas/workflow/tabela`, dados);
    }

    retornarOptionEmpresas(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/empresasAtivas/select`);
    }

    getMarcadores(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/notas/detalhe/marcadores`);
    }

    getStatusW(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/notas/workflow/status`);
    }

    novoMarcador(marcador: string, cor: string, ids: string[]): Observable<any> {

        const dados: any = {
            marcador,
            ids
        };

        if (cor) {
            dados.cor = cor;
        }


        return this.http.post(`${environment.apiUrl}/notas/detalhe/marcadores`, dados);
    }

    removerMarcador(marcador: string, ids: string[]): Observable<any> {
        return this.http.post(`${environment.apiUrl}/notas/detalhe/marcadores/remover`, {marcador, ids});
    }

    deletarMarcadores(ids: string[]): Observable<any> {
        return this.http.post(`${environment.apiUrl}/notas/detalhe/marcadores/excluir`, {ids});
    }

    exportarDados(params, search: string = null, baseUrl = null): Observable<any> {

        const {pageIndex, pageSize, sort, filter} = params;
        const currentSort = sort.find(item => item.value !== null);
        const sortField = (currentSort && currentSort.key) || null;
        let sortOrder = (currentSort && currentSort.value) || null;

        const queryParams = [];

        queryParams.push(`tipoExportador=xlsx`);
        queryParams.push(`tipo=workflow-notas-entrada`);


        if (pageIndex !== null && typeof pageIndex !== 'undefined') {
            queryParams.push(`page=${pageIndex}`);
        }

        if (pageSize !== null && typeof pageSize !== 'undefined') {
            queryParams.push(`quantidade=${pageSize}`);
        }

        if (sortField !== null && typeof sortField !== 'undefined') {
            if (sortOrder === 'ascend') {
                sortOrder = 'asc';
            }
            if (sortOrder === 'descend') {
                sortOrder = 'desc';
            }
            queryParams.push(`sort[by]=${sortField}`);
            queryParams.push(`sort[order]=${sortOrder}`);
        }

        if (search !== null && typeof search !== 'undefined') {
            queryParams.push(`busca=${search}`);
        }

        if (params.filter) {
            params.filter.forEach((obj) => {
                if (obj.key && obj.value) {
                    queryParams.push(obj.key + '=' + obj.value);
                }
            });
        }

        const queryString = queryParams.length > 0 ? '?' + queryParams.join('&') : '';

        return this.http.post(`${baseUrl}${queryString}`, {});

    }
}
