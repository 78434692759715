<nz-page-header class="site-page-header" nzTitle="Workflow" nzSubtitle="Notas Fiscais de Entrada">
    <nz-page-header-extra>
        <nz-button-group>

            <nz-badge [nzCount]="qtdFiltrosAtivos"
                      [nzStyle]="{ backgroundColor: '#52c41a', 'margin-right' : '15px'}">
                <button disabled="{{loading}}" nz-button nzType="primary" nzGhost
                        style="border-radius: 0.45rem;"
                        (click)="modalFiltrar(true);">
                    <i nz-icon nzType="search"></i>
                    Filtros
                </button>
            </nz-badge>
        </nz-button-group>
    </nz-page-header-extra>
</nz-page-header>
<nz-content>
    <nz-card nzTitle="Categorias de Status" [nzExtra]="navBarNotas"
             [nzBodyStyle]="loadingCardStatus ? {'overflow' : 'hidden', 'padding':'15px'} : {'padding': '5px 0'}"
             [ngClass]="[statusOne ? 'card-fullscreen' : '', !loadingCardStatus ? 'ant-card-table' : '']"
             [nzLoading]="loadingCardStatus">
        <div style="padding-top: 10px; padding-left: 5px;">
            <div nz-card-grid *ngFor="let s of categorias" (click)="filtarStausByCard(s.status_id.toString());"
                 [ngStyle]="{width: (!statusOne && !statusThree ? '32%' :
                          (!statusOne && statusThree ? '23.8%' :
                          (statusOne && !statusThree ? '23.8%' :
                          (statusOne && statusThree ? '23.8%' : '32%')))),
                         textAlign: 'center',
                         height: '30%',
                         margin: '0 0.5% 1% 0.5%',
                         float: 'left',
                         'z-index': s.zIndex,
                         'padding': '15px'}"
                 [ngClass]="statusSelected === s.status_id.toString() ? 'selected' : 'card-status'">
                <nz-content>
                    <div style="height: 44px; margin-bottom: 20px; display: flex; justify-content: space-between; font-size: 1.2em; border-bottom: 1px solid rgba(224,223,223,0.38)">
                                <span>
                                    <div [ngStyle]="{'background': s.cor}"
                                         class="avatar"
                                    >
                                        <i [ngClass]="s.icone"></i>
                                    </div>
                                    <span [ngStyle]="{'color': s.cor}">{{ s?.descricao }}</span>
                                </span>
                        <span [ngStyle]="{'color': s.cor}" style="font-weight: bold;">{{ s?.quantidade }}</span>
                    </div>

                    <div class="lista-etapas">
                        <div *ngFor="let item of s.totais"
                             class="etapa-status">
                        <span style="word-break: break-word; text-align: left">
                            {{ item.descricao }}
                        </span>
                            <span>
                            {{ item.quantidade }}
                        </span>
                        </div>
                    </div>

                </nz-content>
            </div>
        </div>
    </nz-card>
    <ng-template #navBarNotas>
        <div class="nav-item d-flex">
            <button nz-button [nzType]="'default'" (click)="clickEvent('one')"
                    style="padding-left: 8px; padding-right: 8px;">
                <i class="fas" [ngClass]="!statusOne ? 'fa-expand' : 'fa-compress'"></i>
            </button>
        </div>
    </ng-template>

    <div id="cardTable" #cardTable>
        <nz-card nzTitle="Notas"
                 [ngClass]="[!loading ? 'ant-card-table' : '']"
                 [nzLoading]="loading"
                 [nzBodyStyle]="loading ? {'overflow' : 'hidden', 'padding':'15px'} : {'padding': '5px 0'}"
                 [nzExtra]="navBar">
            <ng-template #navBar>
                <div class="nav-item d-flex" style="gap: 10px">
                    <nz-badge [nzCount]="qtdFiltrosTabelaAtivos"
                              [nzStyle]="{ backgroundColor: '#52c41a'}">
                        <button nz-button nzType="primary" [disabled]="tabFilterNumber > 0"
                                [nz-tooltip]="tabFilterNumber > 0 ? 'Disponível quando o filtro superior for por data' : ''"
                                (click)="tabFilterNumber === 0 && modalFiltrarTabela(true)">
                            <i nz-icon nzType="search"></i>
                            Filtros
                        </button>
                    </nz-badge>
                    <button [nzDropdownMenu]="menuAcoes" class="centralized-icon" nz-button nz-dropdown
                            nzType="default">
                        Opções
                        <em nz-icon nzType="down"></em>
                    </button>
                    <nz-dropdown-menu #menuAcoes="nzDropdownMenu">
                        <ul nz-menu>
                            <li nz-menu-item
                                (click)="modalGerenciarMarcadores(true)">
                                Gerenciar marcadores
                            </li>
                            <li nz-submenu nzTitle="Manutenção Coletiva">
                                <ul>
                                    <li nz-menu-item nzDisabled>Ações</li>
                                    <li nz-menu-divider></li>
                                    <li nz-menu-item
                                        (click)="checkedItems?.size > 0 && modalTrocarStatus(true)"
                                        [nzDisabled]="checkedItems?.size === 0">
                                        Alterar Status
                                    </li>
                                    <li [nzDisabled]="checkedItems?.size === 0" nz-submenu
                                        nzTitle="Adicionar marcador">

                                        <ul style="max-height: 300px; overflow-y: auto">
                                            <li *ngFor="let m of arrayMarcadores" nz-menu-item
                                                (click)="novoMarcador(m.marcador)">
                                                <nz-badge nzSize="default" style="margin-right: 5px"
                                                          nzColor="{{m.cor}}"></nz-badge>
                                                {{ m.marcador }}
                                            </li>
                                        </ul>

                                        <button nz-button nzType="link"
                                                (click)="modalNovoMatrcador(true)">
                                            Novo marcador
                                        </button>

                                    </li>
                                </ul>
                            </li>
                            <li nz-menu-item (click)="exportarDados()">Exportar Dados</li>
                        </ul>
                    </nz-dropdown-menu>
                    <button nz-button nzType="default" style="margin-right: 10px;"
                            (click)="btnResetSearch();">
                        Limpar
                    </button>
                </div>
            </ng-template>
            <nz-table #basicTable
                      [nzData]="notas"
                      [nzFrontPagination]="false"
                      [nzTotal]="pagination?.total"
                      [nzPageSize]="pagination?.per_page"
                      [nzShowSizeChanger]="true"
                      [nzPageSizeOptions]="sizeOptions"
                      (nzPageSizeChange)="chagePageSize($event)"
                      [nzPageIndex]="pagination?.current_page"
                      (nzQueryParams)="listByTable($event)"
                      [nzFooter]="footer"
                      nzScroll
                      class="table-small">
                <thead>
                <tr>
                    <th style="width: 25px!important;" nzAlign="center">
                        <div style="max-width: 35px!important; padding: 0!important;">
                            <label nz-checkbox [(ngModel)]="checked" (nzCheckedChange)="onAllChecked($event)"></label>
                        </div>
                    </th>
                    <th nzColumnKey="conta_id" [nzSortFn]="true">Destinatário</th>
                    <th style="width: 55px" nzAlign="center" nzColumnKey="numero" [nzSortFn]="true">Número</th>
                    <th nzAlign="center" nzColumnKey="serie" [nzSortFn]="true">Serie</th>
                    <th nzAlign="center" nzColumnKey="chave" [nzSortFn]="true">Chave</th>
                    <th nzAlign="center" nzColumnKey="dataEmissao" [nzSortFn]="true">Data Emissão</th>
                    <th nzAlign="center" nzColumnKey="valor" [nzSortFn]="true">Valor</th>
                    <th nzAlign="center" nzColumnKey="emitenteDocumento" [nzSortFn]="true">Emitente</th>
                    <th nzAlign="center" nzColumnKey="status_workflow">Status Workflow</th>
                    <th nzAlign="center" nzColumnKey="marcadores">Marcadores</th>
                    <th style="width: 70px!important;"></th>
                </tr>
                </thead>
                <tbody>
                <ng-container *ngFor="let item of basicTable.data">
                    <tr>
                        <td style="width: 25px!important;" nzAlign="center">
                            <div style="max-width: 35px!important; padding: 0!important;">
                                <label nz-checkbox
                                       [nzChecked]="checkedItems.has(item.emissao + '_' + item.modelo_id + '_' + item.chave)"
                                       (nzCheckedChange)="onItemChecked(item.emissao+'_'+item.modelo_id+'_'+item.chave, $event)"></label>
                            </div>
                        </td>
                        <td>
                            <div style="min-width: 150px">
                                {{ item.conta_id | cpfCnpj }}
                            </div>
                        </td>

                        <td nzAlign="center">
                            <div style="width: 55px">
                                {{ item.numero }}
                            </div>
                        </td>

                        <td nzAlign="center">
                            {{ item.serie }}
                        </td>
                        <td nzAlign="center">
                            {{ item.chave }}
                        </td>
                        <td nzAlign="center">
                            {{ item.dataEmissao | date: 'dd/MM/yyyy' }}
                        </td>
                        <td nzAlign="center">
                            {{ item.valor | currency: 'BRL' }}
                        </td>
                        <td nzAlign="center">
                            <div style="min-width: 150px">
                                {{ item.emitenteNome || '-' }}
                                <small style="display: block">
                                    ({{ item.emitenteDocumento | cpfCnpj }})
                                </small>
                            </div>
                        </td>
                        <td nzAlign="center">
                            {{ item.status_workflow }}
                        </td>
                        <td nzAlign="center">
                            <div class="boxMarcadores">
                                <span class="marcador"
                                      [ngStyle]="{color: '#fff', 'background-color': m.cor}"
                                      *ngFor="let m of item?.marcadores">{{ m.marcador }}
                                </span>
                            </div>
                        </td>
                        <td style="width: 70px!important;" nzAlign="center">
                            <a nz-button nzType="link"
                               (click)="openTab('/notas/nfe/terceiros/:id', item.chave, {emissao: item.emissao, modelo: item.modelo_id, chave: item.chave})"
                            >Detalhes</a>
                        </td>
                    </tr>
                </ng-container>
                </tbody>
            </nz-table>
            <ng-template #footer>
                <span *ngIf="notas && notas.length > 0">{{ pagination?.from }}-{{ pagination?.to }}
                    de {{ pagination?.total }} registros</span>
            </ng-template>
        </nz-card>
    </div>
</nz-content>

<nz-modal [(nzVisible)]="modalFiltrarVisible"
          [nzBodyStyle]="{'padding-top': 0}"
          [nzTitle]="'Filtros'" [nzClosable]="true"
          (nzOnCancel)="modalFiltrar(false)"
          [nzWidth]="600">
    <nz-row *nzModalContent>
        <nz-tabset
                [nzSize]="'large'"
                [(nzSelectedIndex)]="tabFilterNumber"
                (nzSelectChange)="changeTabs($event)">
            <nz-tab nzTitle="Data"></nz-tab>
            <nz-tab nzTitle="Nota"></nz-tab>
        </nz-tabset>
        <nz-col [nzSpan]="24">
            <form nz-form [nzNoColon]="false" [formGroup]="formFiltrosCabecalho">
                <div *ngIf="tabFilterNumber === 0 else tabNota">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="5">Mês/Ano</nz-form-label>
                        <nz-form-control [nzSpan]="15">
                            <nz-radio-group formControlName="intervalo">
                                <label nz-radio nzValue="mesAno">Mês/Ano</label>
                                <label nz-radio nzValue="periodo">Período determinado</label>
                            </nz-radio-group>
                        </nz-form-control>
                    </nz-form-item>
                    <div *ngIf="formFiltrosCabecalho.value.intervalo === 'mesAno' else periodo">
                        <nz-form-item>
                            <nz-form-label [nzSpan]="6">Mês</nz-form-label>
                            <nz-form-control [nzSpan]="14" [nzHasFeedback]="formFiltrosCabecalho.value.mes">
                                <nz-select nzShowSearch
                                           nzAllowClear
                                           nzPlaceHolder="Selecione"
                                           formControlName="mes">
                                    <nz-option *ngFor="let m of meses" [nzLabel]="m.descricao"
                                               [nzValue]="m.valor"></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>

                        <nz-form-item>
                            <nz-form-label nzRequired [nzSpan]="6">Ano</nz-form-label>
                            <nz-form-control [nzSpan]="14" [nzHasFeedback]="formFiltrosCabecalho.value.ano">
                                <nz-select nzShowSearch
                                           nzPlaceHolder="Selecione"
                                           formControlName="ano">
                                    <nz-option *ngFor="let a of anos" [nzLabel]="a"
                                               [nzValue]="a"></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>

                    </div>
                    <ng-template #periodo>
                        <nz-form-item>
                            <nz-form-label [nzSpan]="6">De</nz-form-label>
                            <nz-form-control [nzSpan]="14"
                                             [nzHasFeedback]="formFiltrosCabecalho.value.periodoInicio">
                                <nz-date-picker [nzFormat]="'dd/MM/yyyy'"
                                                formControlName="periodoInicio" style="width: 100%;"></nz-date-picker>
                            </nz-form-control>
                        </nz-form-item>
                        <nz-form-item>
                            <nz-form-label [nzSpan]="6">Até</nz-form-label>
                            <nz-form-control [nzSpan]="14"
                                             [nzHasFeedback]="formFiltrosCabecalho.value.periodoFim">
                                <nz-date-picker [nzFormat]="'dd/MM/yyyy'"
                                                formControlName="periodoFim" style="width: 100%;"></nz-date-picker>
                            </nz-form-control>
                        </nz-form-item>
                    </ng-template>
                </div>

                <ng-template #tabNota>

                    <nz-form-item>
                        <nz-form-label [nzSpan]="6">Nota(s)</nz-form-label>
                        <nz-form-control [nzSpan]="14">
                            <nz-select nzMode="tags"
                                       nzPlaceHolder="Informe a(s) notas(s)"
                                       formControlName="notas"
                                       [nzTokenSeparators]="[' ', '-']">
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>

                    <nz-form-item>
                        <nz-form-label [nzSpan]="6">Chave(s)</nz-form-label>
                        <nz-form-control [nzSpan]="14">
                            <nz-select nzMode="tags"
                                       [nzMaxTagCount]="15"
                                       nzPlaceHolder="Informe a(s) chave(s)"
                                       formControlName="chave"
                                       [nzTokenSeparators]="[' ', '-']">
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>

                </ng-template>


            </form>
        </nz-col>
    </nz-row>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalFiltrar(false);">Cancelar</button>
        <button [disabled]="tabFilterNumber === 1 && !formFiltrosCabecalho.get('chave').value
         && !formFiltrosCabecalho.get('notas').value"
                nz-button nzType="primary" (click)="filtrar()">Filtrar
        </button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="modalFiltrarTabelaVisible"
          [nzTitle]="'Filtros Avançados'" [nzClosable]="true"
          (nzOnCancel)="modalFiltrarTabela(false)"
          [nzWidth]="600">
    <nz-row *nzModalContent>
        <nz-col [nzSpan]="24">
            <form [nzAutoTips]="autoTips" nz-form [nzNoColon]="false" [formGroup]="formFiltrosTable">

                <nz-form-item>
                    <nz-form-label [nzSpan]="6">Nota(s)</nz-form-label>
                    <nz-form-control [nzSpan]="14">
                        <nz-select nzMode="tags"
                                   nzPlaceHolder="Informe a(s) notas(s)"
                                   formControlName="notas"
                                   [nzTokenSeparators]="[' ', '-']">
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSpan]="6">Chave(s)</nz-form-label>
                    <nz-form-control [nzSpan]="14">
                        <nz-select nzMode="tags"
                                   [nzMaxTagCount]="15"
                                   nzPlaceHolder="Informe a(s) chave(s)"
                                   formControlName="chave"
                                   [nzTokenSeparators]="[' ', '-']">
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSpan]="6">Emitente</nz-form-label>
                    <nz-form-control [nzSpan]="14">
                        <input mask="{{maskDoc}}" nz-input formControlName="emitente"
                               placeholder="Informe o cnpj do emitente">
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSpan]="6">Destinatário</nz-form-label>
                    <nz-form-control [nzSpan]="14">
                        <input mask="{{maskDoc}}" nz-input formControlName="destinatario"
                               placeholder="Informe o cnpj do destinatário">
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSpan]="6">Marcador</nz-form-label>
                    <nz-form-control [nzSpan]="14">
                        <nz-select nzShowSearch nzMode="multiple" style="min-width: 300px"
                                   nzAllowClear
                                   nzPlaceHolder="Selecione"
                                   formControlName="marcadores">
                            <nz-option *ngFor="let m of arrayMarcadores" [nzLabel]="m.marcador"
                                       [nzValue]="m.marcador"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
            </form>
        </nz-col>
    </nz-row>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalFiltrarTabela(false);">Cancelar</button>
        <button [disabled]="!formFiltrosTable.valid" nz-button nzType="primary" (click)="filtrarTabela()">Filtrar
        </button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="modalTrocarStatusVisible"
          [nzTitle]="'Alterar Status'" [nzClosable]="true"
          (nzOnCancel)="modalTrocarStatus(false)"
          [nzWidth]="600">
    <nz-row *nzModalContent>
        <nz-col [nzSpan]="24">
            <form [nzAutoTips]="autoTips" nz-form [nzNoColon]="false" [formGroup]="formAlterar">

                <nz-form-item>
                    <nz-form-label [nzSpan]="6">Status</nz-form-label>
                    <nz-form-control [nzSpan]="14">
                        <nz-select nzShowSearch
                                   [(ngModel)]="statusSelecionado"
                                   (ngModelChange)="changeStatus()"
                                   nzAllowClear
                                   nzPlaceHolder="Selecione"
                                   formControlName="etapa_id">
                            <nz-option *ngFor="let s of statusWorkflow" [nzLabel]="s.descricao"
                                       [nzValue]="s.id"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item *ngIf="showMotivo">
                    <nz-form-label nzRequired [nzSpan]="6">Motivo</nz-form-label>
                    <nz-form-control [nzSpan]="14">
                        <nz-textarea-count [nzMaxCharacterCount]="100">
                             <textarea rows="3" minlength="15" maxlength="100" nz-input formControlName="motivo"
                                       placeholder="Informe um motivo com no mínimo 15 caracteres...">
                             </textarea>
                        </nz-textarea-count>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item *ngIf="showObs">
                    <nz-form-label nzRequired [nzSpan]="6">Observação</nz-form-label>
                    <nz-form-control [nzSpan]="14">
                        <nz-textarea-count [nzMaxCharacterCount]="25">
                            <textarea rows="3" maxlength="25" nz-input formControlName="observacao"
                                      placeholder="Informe uma observação com no máximo 25 caracteres...">
                            </textarea>
                        </nz-textarea-count>
                    </nz-form-control>
                </nz-form-item>

            </form>
        </nz-col>
    </nz-row>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalTrocarStatus(false);">Cancelar</button>
        <button [nzLoading]="loadingBt" nz-button nzType="primary" (click)="alterarStatus()">Confirmar</button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="modalNovoMarcador"
          [nzTitle]="'Criar Novo Marcador'" [nzClosable]="true"
          (nzOnCancel)="modalNovoMatrcador(false)"
          [nzWidth]="500">
    <div *nzModalContent>
        <div>
            <label style="margin: 0 15px 10px 30px">Marcador</label>
            <input style="width: 280px" nz-input [(ngModel)]="novoMarcadorDescricao"/>
        </div>
        <div style="margin-top: 15px; display: flex; align-items: center;">
            <label style="margin: 0 15px 0 65px;">Cor</label>
            <input style="width: 280px; border: 1px solid #d9d9d9; height: 35px" [(ngModel)]="corNovoMarcador"
                   type="color">
        </div>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalNovoMatrcador(false);">Cancelar</button>
        <button [nzLoading]="this.loadingBt" nz-button nzType="primary" (click)="novoMarcador()">Confirmar</button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="modalGerenciarMarcadoresVisible"
          [nzTitle]="'Gerenciar Marcadores'" [nzClosable]="true"
          (nzOnCancel)="modalGerenciarMarcadores(false)"
          [nzWidth]="500">
    <div *nzModalContent>

        <nz-table #basicTable
                  [nzData]="arrayMarcadores"
                  [nzFrontPagination]="false"
                  [nzLoading]="loadingM"
                  [nzTotal]="pagination?.total"
                  [nzPageSize]="pagination?.per_page"
                  [nzPageIndex]="pagination?.current_page"
                  nzScroll
                  class="table-small">
            <thead>
            <tr>
                <th style="width: 25px!important;" nzAlign="center">
                    <div style="max-width: 35px!important; padding: 0!important;">
                        <label nz-checkbox [(ngModel)]="checkedMarcadores"
                               (nzCheckedChange)="onAllCheckedMarcadores($event)"></label>
                    </div>
                </th>
                <th nzAlign="left" nzColumnKey="marcador">Marcador</th>
                <th style="width: 70px!important;"></th>
            </tr>
            </thead>
            <tbody>
            <ng-container *ngFor="let item of basicTable.data">
                <tr>
                    <td style="width: 25px!important;" nzAlign="left">
                        <div style="max-width: 35px!important; padding: 0!important;">
                            <label *ngIf="item.qtdVinculadas else semVinculo" nz-checkbox
                                   [nzDisabled]="true"
                                   [nz-tooltip]="'Este marcador está vinculado a uma ou mais análises'"
                            ></label>
                            <ng-template #semVinculo>
                                <label nz-checkbox
                                       [nzChecked]="checkedItensMarcadores.has(item.marcador)"
                                       (nzCheckedChange)="onMarcadorChecked(item.marcador, $event)"></label>
                            </ng-template>
                        </div>
                    </td>
                    <td nzAlign="left">
                        <nz-badge nzSize="default" style="margin-right: 5px"
                                  nzColor="{{item.cor}}"></nz-badge>
                        {{ item.marcador }}
                    </td>
                    <td style="width: 70px!important;" nzAlign="center">
                        <a nz-button nzType="link" *ngIf="item.qtdVinculadas else btSemVinculo"
                           [disabled]="true" [nz-tooltip]="'Este marcador está vinculado a uma ou mais análises'"
                        >Remover</a>

                        <ng-template #btSemVinculo>
                            <a nz-button nzType="link" [nzLoading]="loadingMUnico === item.marcador"
                               (click)="!loadingBt && confirmRemoverMarcador(item.marcador)"
                            >Remover</a>
                        </ng-template>
                    </td>
                </tr>
            </ng-container>
            </tbody>
        </nz-table>

    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalGerenciarMarcadores(false);">Fechar</button>
        <button [disabled]="checkedItensMarcadores.size === 0" [nzLoading]="this.loadingBt" nz-button nzType="primary"
                (click)="confirmRemoverMarcador()">Remover
        </button>
    </div>
</nz-modal>
