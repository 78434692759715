<nz-page-header class="site-page-header" nzTitle="Cadastros" nzSubtitle="Cadastro de Obrigações">
</nz-page-header>
<nz-content>
    <nz-card class="ant-card-table fonte-doze" [nzExtra]="navBar" nzTitle="Obrigações"
             [nzLoading]="loading"
             [nzBodyStyle]="loading ? {'overflow' : 'hidden', 'padding':'15px'} : {}">
        <ng-template #navBar>
            <div class="nav-item d-flex">
                <button nz-button nzType="primary" style="margin-right: 10px;" (click)="modalCadastrar(true);">
                    Cadastrar
                </button>
                <nz-badge [nzCount]="qtdFiltrosAtivos"
                          [nzStyle]="{ backgroundColor: '#52c41a', 'margin-right' : '15px'}">
                    <button nz-button nzType="primary" style="margin-right: 10px;" (click)="modalFiltrar(true);">
                        <i nz-icon nzType="search"></i>
                        Filtros
                    </button>
                </nz-badge>
                <button [nzDropdownMenu]="menuAcoes" class="centralized-icon" nz-button
                        nz-dropdown style="margin-right: 10px;"
                        nzType="default">
                    Opções
                    <em nz-icon nzType="down"></em>
                </button>
                <nz-dropdown-menu #menuAcoes="nzDropdownMenu">
                    <ul nz-menu>
                        <li nz-submenu nzTitle="Manutenção Coletiva">
                            <ul>
                                <li [nzDisabled]="checkedItems?.size === 0 || loadingsOpcaoes.vencimentos"
                                    nz-menu-item
                                    (click)="checkedItems?.size === 0 || loadingsOpcaoes.vencimentos ? '' : showConfirmCadastrarVencimentos();">
                                    Cadastrar Vencimentos
                                </li>

                                <li [nzDisabled]="checkedItems?.size === 0 || loadingsOpcaoes.agenda"
                                    nz-menu-item
                                    (click)="checkedItems?.size === 0 || loadingsOpcaoes.agenda ? '' : disponivelAgendaConfirm(confirm, tplFooter)">
                                    Disponivel para Agenda
                                </li>

                                <li [nzDisabled]="checkedItems?.size === 0 || loadingsOpcaoes.agendaConfirmavel"
                                    nz-menu-item
                                    (click)="checkedItems?.size === 0 || loadingsOpcaoes.agendaConfirmavel ? '' : agendaConfirmavelConfirm(agendaConfirmavelTemplate, tplFooterAgendaConfirmavel);">
                                    Agenda Confirmável
                                </li>
                                <li [nzDisabled]="checkedItems?.size === 0 || loadingsOpcaoes.compliance"
                                    nz-menu-item
                                    (click)="checkedItems?.size === 0 || loadingsOpcaoes.compliance ? '' : disponivelComplianceConfirm(confirmCompliance, tplFooterCompliance);">
                                    Disponível para Compliance
                                </li>

                            </ul>
                        </li>
                        <li nz-menu-item (click)="modalExportar(true)">Exportar Planilha</li>
                    </ul>
                </nz-dropdown-menu>
                <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" style="margin-right: 10px;">
                    <input (keydown.enter)="buscar()" type="text" nz-input placeholder="Busca"
                           [(ngModel)]="currentSearch"/>
                </nz-input-group>
                <ng-template #suffixIconButton>
                    <button nz-button nzType="primary" nzSearch
                            (click)="buscar()">
                        <i nz-icon nzType="search"></i></button>
                </ng-template>
                <button nz-button nzType="default" style="margin-right: 10px;"
                        (click)="btnResetSearch();">
                    Limpar
                </button>
            </div>
        </ng-template>
        <nz-alert nzType="info" nzShowIcon [nzMessage]="checkedMessage" style="margin: 15px !important;"
                  *ngIf="checkedItems.size > 0">
            <ng-template #checkedMessage>
                <div nz-row nzAlign="middle" nzGutter="24">
                    <div nz-col nzSpan="24">
                        <strong class="text-primary"></strong>
                        {{ checkedItems.size }} Registros Selecionados
                    </div>
                </div>
            </ng-template>
        </nz-alert>
        <nz-table #basicTable
                  nzSize="small"
                  [nzData]="items"
                  [nzFrontPagination]="false"
                  [nzTotal]="pagination?.total"
                  [nzPageSize]="pagination?.per_page"
                  [nzPageIndex]="pagination?.current_page"
                  (nzQueryParams)="queryTable($event)"
                  style="overflow-x: auto"
                  [nzFooter]="footer"
        >
            <thead>
            <tr>
                <th (nzCheckedChange)="onAllChecked($event)" [nzChecked]="checked" [nzIndeterminate]="indeterminate"
                    id="check-all">
                </th>
                <!--<th nzColumnKey="id" [nzSortFn]="true" nzAlign="center">ID</th>-->
                <th nzColumnKey="descricao" [nzSortFn]="true" nzAlign="center">Descrição</th>
                <th nzColumnKey="tipo" [nzSortFn]="true" nzAlign="center">Tipo</th>
                <th nzColumnKey="orgao" [nzSortFn]="true" nzAlign="center">Orgão</th>
                <th nzColumnKey="categoria" [nzSortFn]="true" nzAlign="center">Categoria</th>
                <th nzColumnKey="uf" [nzSortFn]="true" nzAlign="center">UF</th>
                <th nzColumnKey="municipioNome" [nzSortFn]="true" nzAlign="center">Município</th>
                <th nzColumnKey="prazoExpiracaoConsulta" [nzSortFn]="true" nzAlign="center">Prazo de Expiração</th>
                <th nzColumnKey="utilizacao" [nzSortFn]="false" nzShowSort="false" nzAlign="center">Utilização</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let obrigacao of basicTable.data">
                <td (nzCheckedChange)="onItemChecked(obrigacao.id, $event)"
                    [nzChecked]="checkedItems.has(obrigacao.id)"
                    [nzDisabled]="obrigacao.disabled">
                </td>
                <!--<td nzLeft>{{obrigacao?.id}}</td>-->
                <td>
                    {{ obrigacao?.descricao }}
                    <nz-tag [nzColor]="'#f50'" *ngIf="obrigacao.id.length == 36"
                            style="margin-left: 10px; font-size: 10px">
                        Manual
                    </nz-tag>
                    <nz-tag [nzColor]="'#FF3232'" *ngIf="obrigacao.emManutencao"
                            style="margin-left: 10px; font-size: 10px">
                        Em Manutenção
                    </nz-tag>
                </td>
                <td nzAlign="center">{{ obrigacao?.tipo_descricao }}</td>
                <td nzAlign="center">{{ obrigacao?.orgao }}</td>
                <td nzAlign="center">{{ obrigacao?.categoria | uppercase }}</td>
                <td nzAlign="center">{{ obrigacao?.uf }}</td>
                <td nzAlign="center">{{ obrigacao.municipioCodigo ? obrigacao.municipioCodigo + ' - ' + obrigacao.municipioNome : '' }}</td>
                <td nzAlign="center">
                    <span *ngIf="obrigacao?.prazoExpiracaoConsulta == 1">{{ obrigacao?.prazoExpiracaoConsulta }}
                        Dia</span>
                    <span *ngIf="obrigacao?.prazoExpiracaoConsulta > 1">{{ obrigacao?.prazoExpiracaoConsulta }}
                        Dias</span>
                </td>
                <td nzAlign="center">{{ obrigacao.temAgenda ? 'Disponível para Agenda' : '' }}</td>
                <td *ngIf="obrigacao.id.length == 36; else paraNaoManuais" nzAlign="left">
                    <div style="display: flex; align-items: center;">
                        <a nz-button nzType="link"
                           style="margin-left: 20px"
                           (click)="openTab('/cadastros/obrigacoes/obrigacao-detalhe', obrigacao.id, {id: obrigacao.id})">
                            Editar
                        </a>
                        <a nz-button nzType="link"
                           (click)="showConfirmEmcluirObrigacaoM(obrigacao.id)"
                           [nzLoading]="(excluindoObrigacao.loading && excluindoObrigacao.id === obrigacao.id)">
                            Excluir
                        </a>
                    </div>
                </td>
                <ng-template #paraNaoManuais>
                    <td nzAlign="left">
                        <a nz-button nzType="link"
                           style="margin-left: 20px"
                           (click)="openTab('/cadastros/obrigacoes/obrigacao-detalhe', obrigacao.id, {id: obrigacao.id})">
                            Editar
                        </a>
                    </td>
                </ng-template>
            </tr>
            </tbody>
            <ng-template #footer>
                <span *ngIf="items && items.length > 0">{{ pagination?.from }}-{{ pagination?.to }}
                    de {{ pagination?.total }} registros</span>
            </ng-template>
        </nz-table>
    </nz-card>
</nz-content>
<nz-modal [(nzVisible)]="modalCadastrarVisible"
          [nzTitle]="'Nova Obrigação'" [nzClosable]="true"
          (nzOnCancel)="modalCadastrar(false)"
          [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzAutoTips]="autoTips" [nzNoColon]="true" [formGroup]="formDadosCadastrais.formGroup"
              style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="8" nzRequired>Descrição</nz-form-label>
                <nz-form-control [nzSpan]="10">
                    <input nz-input type="text" formControlName="descricao" maxlength="60">
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="8" nzRequired>Tipo</nz-form-label>
                <nz-form-control [nzSpan]="12">
                    <nz-radio-group formControlName="tipo" [(ngModel)]="radioValuePrincipalAcessoria">
                        <label nz-radio nzValue="P">Principal</label>
                        <label nz-radio nzValue="A">Acessória</label>
                    </nz-radio-group>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="8" nzRequired>Periodicidade</nz-form-label>
                <nz-form-control [nzSpan]="12">
                    <nz-radio-group formControlName="periodicidade" [(ngModel)]="radioValuePeriodicidade">
                        <label nz-radio nzValue="A">Anual</label>
                        <label nz-radio nzValue="M">Mensal</label>
                    </nz-radio-group>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="8">Dia Vencimento</nz-form-label>
                <nz-form-control [nzSpan]="10">
                    <input type="number" min="1" max="31" maxlength="2"
                           nz-input formControlName="vencimentoDia" (keydown)="keyPressNumber($event)"/>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="8" nzRequired>Útil</nz-form-label>
                <nz-form-control [nzSpan]="12">
                    <nz-radio-group formControlName="vencimentoUtil" [(ngModel)]="radioValueVencimentoUtil">
                        <label nz-radio nzValue="1">Sim</label>
                        <label nz-radio nzValue="0">Não</label>
                    </nz-radio-group>
                </nz-form-control>
            </nz-form-item>

            <div *ngIf="formDadosCadastrais.formGroup.get('periodicidade').value === 'A' else periodicidadeM">
                <nz-form-item>
                    <nz-form-label nzRequired [nzSpan]="8">Mês Vencimento</nz-form-label>
                    <nz-form-control [nzSpan]="10">
                        <nz-date-picker style="width: 100%" formControlName="vencimentoMes" nzMode="month"
                                        [nzPlaceHolder]="'Selecione o mês'"
                                        [nzFormat]="'MM'"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
            </div>

            <ng-template #periodicidadeM>

                <nz-form-item *ngIf="formDadosCadastrais.formGroup.get('vencimentoUtil').value == 0">
                    <nz-form-label [nzSpan]="8" nzRequired></nz-form-label>
                    <nz-form-control [nzSpan]="12">
                        <nz-radio-group formControlName="vencimentoModalidade"
                                        [(ngModel)]="radioValueVencimentoModalidade">
                            <label nz-radio nzValue="Antecipa">Antecipa</label>
                            <label nz-radio nzValue="Posterga">Posterga</label>
                        </nz-radio-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSpan]="8" nzRequired>Mês</nz-form-label>
                    <nz-form-control [nzSpan]="12">
                        <nz-radio-group formControlName="vencimentoMesSubsequente"
                                        [(ngModel)]="radioValueVencimentoMesSubsequente">
                            <label nz-radio nzValue="1">1º Subsequente</label>
                            <label nz-radio nzValue="2">2º Subsequente</label>
                            <label nz-radio nzValue="0">Vigente</label>
                        </nz-radio-group>
                    </nz-form-control>
                </nz-form-item>

            </ng-template>


            <nz-form-item>
                <nz-form-label [nzSpan]="8" nzRequired>Orgão</nz-form-label>
                <nz-form-control [nzSpan]="12">
                    <nz-radio-group formControlName="orgao" [(ngModel)]="radioValueOrgao"
                                    (ngModelChange)="toggleOrgao($event)">
                        <label nz-radio nzValue="Federal">Federal</label>
                        <label nz-radio nzValue="Estadual">Estadual</label>
                        <label nz-radio nzValue="Municipal">Municipal</label>
                        <label nz-radio nzValue="Previdenciário">Previdenciário</label>
                    </nz-radio-group>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item *ngIf="radioValueOrgao === 'Estadual'">
                <nz-form-label [nzSpan]="8" nzRequired>UF</nz-form-label>
                <nz-form-control [nzSpan]="12">
                    <nz-select formControlName="uf" nzShowSearch>

                        <nz-option *ngFor="let opt of comboUFs"
                                   [nzLabel]="opt.label"
                                   [nzValue]="opt.key"
                        >
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item *ngIf="radioValueOrgao === 'Municipal'">
                <nz-form-label [nzSpan]="8" nzRequired>Código do Município</nz-form-label>
                <nz-form-control [nzSpan]="12">
                    <nz-select formControlName="municipioCodigo" nzShowSearch>
                        <nz-option *ngFor="let opt of comboTodosMunicipios"
                                   [nzLabel]="opt.label"
                                   [nzValue]="opt.key"
                        >
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item *ngIf="radioValueOrgao === 'Federal' || radioValueOrgao === 'Previdenciário'">
                <nz-form-label [nzSpan]="8" nzRequired>Apenas Unidades Matriz</nz-form-label>
                <nz-form-control [nzSpan]="12">
                    <nz-radio-group formControlName="somenteMatriz" [(ngModel)]="apenasUnidadesMatriz">
                        <label nz-radio nzValue="1">Sim</label>
                        <label nz-radio nzValue="0">Não</label>
                    </nz-radio-group>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item *ngIf="radioValueOrgao === 'Federal' || radioValueOrgao === 'Previdenciário'"
                          style="margin-bottom: 10px;">
                <nz-form-label [nzSpan]="8">Regimes Tibutários</nz-form-label>
                <nz-form-control [nzSpan]="12">
                    <label nz-checkbox formControlName="regimeLP" [(ngModel)]="checkedLucroPresumido">LP - Lucro
                        Presumido</label>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item *ngIf="radioValueOrgao === 'Federal' || radioValueOrgao === 'Previdenciário'"
                          style="margin-bottom: 10px;">
                <div nz-col [nzSpan]="8"></div>
                <nz-form-control [nzSpan]="12">
                    <label nz-checkbox formControlName="regimeLRT" [(ngModel)]="checkedLucroRealTrimestral">LRT
                        - Lucro Real
                        Trimestral</label>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item *ngIf="radioValueOrgao === 'Federal' || radioValueOrgao === 'Previdenciário'"
                          style="margin-bottom: 10px;">
                <div nz-col [nzSpan]="8"></div>
                <nz-form-control [nzSpan]="12">
                    <label nz-checkbox formControlName="regimeLRA" [(ngModel)]="checkedLucroRealAnual">LRA - Lucro
                        Real Anual</label>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item *ngIf="radioValueOrgao === 'Federal' || radioValueOrgao === 'Previdenciário'">
                <div nz-col [nzSpan]="8"></div>
                <nz-form-control [nzSpan]="12">
                    <label nz-checkbox formControlName="regimeSN" [(ngModel)]="checkedSimplesNacional">SN -
                        Simples Nacional</label>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item *ngIf="radioValueOrgao === 'Federal' || radioValueOrgao === 'Previdenciário'"
                          style="margin-bottom: 10px;">
                <nz-form-label [nzSpan]="8" nzRequired>Atividades</nz-form-label>
                <nz-form-control [nzSpan]="12">
                    <label nz-checkbox formControlName="somenteAtividadeIndustrial" [(ngModel)]="checkedIndustria">I -
                        Industria</label>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item *ngIf="radioValueOrgao === 'Federal' || radioValueOrgao === 'Previdenciário'"
                          style="margin-bottom: 10px;">
                <div nz-col [nzSpan]="8"></div>
                <nz-form-control [nzSpan]="12">
                    <label nz-checkbox formControlName="atividadeAtacado" [(ngModel)]="checkedAtacado">A -
                        Atacado</label>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item *ngIf="radioValueOrgao === 'Federal' || radioValueOrgao === 'Previdenciário'"
                          style="margin-bottom: 10px;">
                <div nz-col [nzSpan]="8"></div>
                <nz-form-control [nzSpan]="12">
                    <label nz-checkbox formControlName="atividadeVarejo" [(ngModel)]="checkedVarejo">V - Varejo</label>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item *ngIf="radioValueOrgao === 'Federal'|| radioValueOrgao === 'Previdenciário'"
                          style="margin-bottom: 10px;">
                <div nz-col [nzSpan]="8"></div>
                <nz-form-control [nzSpan]="12">
                    <label nz-checkbox formControlName="atividadeServico" [(ngModel)]="checkedServico">S -
                        Serviço</label>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalCadastrar(false)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="cadastrando" (click)="confirmaCadastro()">Confirmar</button>
    </div>
</nz-modal>


<nz-modal [(nzVisible)]="modalFiltrarVisible"
          [nzTitle]="'Filtros Avançados'" [nzClosable]="true"
          (nzOnCancel)="modalFiltrar(false)"
          [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzAutoTips]="autoTips" [nzNoColon]="true" [formGroup]="formFiltrar.formGroup">
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Descrição</nz-form-label>
                <nz-form-control [nzSpan]="14"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.descricao !== '' && formFiltrar.formGroup.value.descricao !== null">
                    <input nz-input type="text" formControlName="descricao" maxlength="20" size="60"/>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Tipo</nz-form-label>
                <nz-form-control [nzSpan]="14"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.tipo">
                    <nz-select formControlName="tipo" nzAllowClear>
                        <nz-option [nzLabel]="'Principal'" [nzValue]="'P'"></nz-option>
                        <nz-option [nzLabel]="'Acessória'" [nzValue]="'A'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Orgão</nz-form-label>
                <nz-form-control [nzSpan]="14"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.orgao">
                    <nz-select formControlName="orgao" nzAllowClear>
                        <nz-option [nzLabel]="'Federal'" [nzValue]="'Federal'"></nz-option>
                        <nz-option [nzLabel]="'Estadual'" [nzValue]="'Estadual'"></nz-option>
                        <nz-option [nzLabel]="'Municipal'" [nzValue]="'Municipal'"></nz-option>
                        <nz-option [nzLabel]="'Previdenciário'" [nzValue]="'Previdenciário'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Categoria</nz-form-label>
                <nz-form-control [nzSpan]="14"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.categoria">
                    <nz-select formControlName="categoria" nzAllowClear>
                        <nz-option *ngFor="let opt of comboCategoria"
                                   [nzLabel]="opt.label"
                                   [nzValue]="opt.key">
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">UF</nz-form-label>
                <nz-form-control [nzSpan]="14"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.uf">
                    <nz-select formControlName="uf" nzShowSearch
                               (ngModelChange)="filtraComboMunicipio(formFiltrar.formGroup.value.uf)"
                               nzAllowClear>
                        <nz-option *ngFor="let opt of comboUFs"
                                   [nzLabel]="opt.label"
                                   [nzValue]="opt.key"
                        >
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Município</nz-form-label>
                <nz-form-control [nzSpan]="14"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.municipioCodigo">
                    <nz-select formControlName="municipioCodigo" nzShowSearch nzAllowClear>
                        <nz-option *ngFor="let opt of comboMunicipio"
                                   [nzLabel]="opt.label"
                                   [nzValue]="opt.key"
                        >
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Prazo Expiração</nz-form-label>
                <nz-form-control [nzSpan]="14"
                                 nzValidateStatus="success"
                                 nzallowclear
                                 [nzHasFeedback]="formFiltrar.formGroup.value.prazoExpiracaoConsulta !== ''
                                 && formFiltrar.formGroup.value.prazoExpiracaoConsulta !== null">
                    <input nz-input type="number" formControlName="prazoExpiracaoConsulta" maxlength="20"/>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="5">Em Manutenção</nz-form-label>
                <nz-form-control [nzSpan]="14"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.emManutencao">
                    <nz-select formControlName="emManutencao" nzShowSearch nzAllowClear>
                        <nz-option [nzLabel]="'SIM'" [nzValue]="'1'"></nz-option>
                        <nz-option [nzLabel]="'NÃO'" [nzValue]="'0'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalFiltrar(false);">Cancelar</button>
        <button nz-button nzType="primary" (click)="confirmaFiltrar()">Filtrar</button>
    </div>
</nz-modal>

<app-exportar-tabela #componentExport [data]="dataExport"></app-exportar-tabela>

<ng-template #confirm let-ref="modalRef">
    <div style="margin-left: 10px">
        <div style="margin-bottom: 35px; display: flex; gap: 10px; align-items: center; flex-direction: row;">
            <span>Disponível: </span>
            <nz-switch [(ngModel)]="disponivelAgendaValue" [nzCheckedChildren]="checkedTemplate"
                       [nzUnCheckedChildren]="unCheckedTemplate"></nz-switch>
        </div>
        <span>
            *As obrigações selecionadas ficarão <b>{{ disponivelAgendaValue == 1 ? 'disponíveis ' : 'indisponíveis ' }}</b> para agenda.
        </span>
    </div>
</ng-template>

<ng-template #confirmCompliance let-ref="modalRef">
    <div style="margin-left: 10px">
        <div style="margin-bottom: 35px; display: flex; gap: 10px; align-items: center; flex-direction: row;">
            <span>Disponível: </span>
            <nz-switch [(ngModel)]="disponivelComplianceValue" [nzCheckedChildren]="checkedTemplate"
                       [nzUnCheckedChildren]="unCheckedTemplate"></nz-switch>
        </div>
        <span>
        *As obrigações selecionadas ficarão <b>{{ disponivelComplianceValue == 1 ? 'disponíveis ' : 'indisponíveis ' }}</b> para compliance.
    </span>
    </div>
</ng-template>

<ng-template #agendaConfirmavelTemplate let-ref="modalRef">
    <div style="margin-left: 10px; display: flex; gap: 25px; flex-direction: column">
        <span>
            Seleciona o tipo de agenda confirmável para as obrigações selecionadas:
        </span>
        <nz-select [(ngModel)]="agendaConfirmavelValeu"
                   [nzLoading]="loadingsOpcaoes.agendaConfirmavel">
            <nz-option [nzValue]="0" [nzLabel]="'Nenhuma'"></nz-option>
            <nz-option [nzValue]="1" [nzLabel]="'Automático'"></nz-option>
            <nz-option [nzValue]="2" [nzLabel]="'Manual'"></nz-option>
        </nz-select>
    </div>
</ng-template>


<ng-template #checkedTemplate><span nz-icon nzType="check"></span></ng-template>
<ng-template #unCheckedTemplate><span nz-icon nzType="close"></span></ng-template>
<ng-template #tplFooter let-ref="modalRef">
    <button nz-button (click)="ref.destroy()">Cancelar</button>
    <button style="margin-right: 10px" nz-button nzType="primary" [nzLoading]="loadingCadastroEmLote"
            (click)="disponivelAgenda(ref)">Confirmar
    </button>
</ng-template>
<ng-template #tplFooterCompliance let-ref="modalRef">
    <button nz-button (click)="ref.destroy()">Cancelar</button>
    <button style="margin-right: 10px" nz-button nzType="primary" [nzLoading]="loadingCadastroEmLote"
            (click)="disponivelCompliance(ref)">Confirmar
    </button>
</ng-template>

<ng-template #tplFooterAgendaConfirmavel let-ref="modalRef">
    <button nz-button (click)="ref.destroy()">Cancelar</button>
    <button style="margin-right: 10px" nz-button nzType="primary" [nzLoading]="loadingCadastroEmLote"
            (click)="agendaConfirmavel(ref)">Confirmar
    </button>
</ng-template>

<nz-modal [(nzVisible)]="modalVencimentosSugeridosVidible"
          nzWidth="260px"
          [nzBodyStyle]="{'padding': '0 5px'}"
          [nzTitle]="tituloModal" [nzClosable]="true"
          (nzOnCancel)="showModalVencimentosSugeridos(false)">
    <ng-template #tituloModal>
        <h5 style="display: inline-block; width: 200px;">
            Selecione os anos para cadastro
        </h5>
    </ng-template>
    <div nz-row *nzModalContent>
        <nz-table #basicTable
                  nzSize="small"
                  [nzData]="vencimentosSugeridos"
                  [nzFrontPagination]="false"
                  [nzShowPagination]="false"
                  style="width: 100%"
        >
            <thead>
            <tr>
                <th (nzCheckedChange)="onAllCheckedAnos($event)" [nzChecked]="checkedAnos"
                    style="width: 32px"
                    id="check-all">
                </th>
                <th style="text-align: center;">Ano</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let ano of vencimentosSugeridos" (click)="onItemCheckedByClick(ano)"
                style="cursor: pointer">
                <td (nzCheckedChange)="onItemCheckedAnos(ano, $event)"
                    [nzChecked]="checkedItemsAnos.has(ano)">
                </td>
                <td style="text-align: center;">{{ ano }}</td>
            </tr>
            <tr>
                <td colspan="2" nzAlign="center">
                    <button nz-button nzType="dashed" class="add-button" (click)="addFieldAno(ultimoAno)">
                        <span nz-icon nzType="plus"></span>
                        {{ ultimoAno + 1 }}
                    </button>
                </td>
            </tr>
            </tbody>
        </nz-table>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="showModalVencimentosSugeridos(false)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="loadingCadastroEmLote"
                (click)="InsertVencimentosSugeridos()">Confirmar
        </button>
    </div>
</nz-modal>




