import {Injectable} from '@angular/core';
import {Helpers} from '../../helpers';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class ExportarTabelaService {

    constructor(protected http: HttpClient) {
    }

    exportExcel(filtros: any, tipo: string, url: string, dadosCompletos = false, verbo: 'GET' | 'POST'): any {

        filtros.tipoExportacao = tipo;

        filtros.dadosCompletos = dadosCompletos ? 1 : 0;

        const queryStr = Helpers.montaQueryString(filtros);

        if (verbo === 'GET') {
            return this.http.get(`${environment.apiUrl}${url}${queryStr}`,
                {responseType: 'blob'});
        } else {
            return this.http.post(`${environment.apiUrl}${url}${queryStr}`,
                {filtros}, {responseType: 'blob'});
        }


    }
}
