import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AbstractService} from '@services/abstract.service';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {InscricaoRegime} from '@models/inscricaoRegime.model';
import {NzTableQueryParams} from "ng-zorro-antd/table";
import {Helpers} from "../../../core/helpers";

@Injectable({providedIn: 'root'})

export class EditarSATService extends AbstractService<InscricaoRegime> {

    constructor(http: HttpClient) {
        super(http, '/sat/detalhe/', InscricaoRegime);
    }

    retornaDadosCadastrais(empresaId: string): Observable<any> {

        return this.http.get(`${environment.apiUrl}/sat/detalhe/${empresaId}`);

    }

    retornaEquipamentos(empresaId: string, params: NzTableQueryParams): Observable<any> {

        const qStr = Helpers.montaQueryStribgBYNzTable(params);

        return this.http.get(`${environment.apiUrl}/sat/detalhe/${empresaId}/equipamentos${qStr}`);

    }

    retornaLotes(empresaId: string, serieId: string, params: NzTableQueryParams): Observable<any> {

        const qStr = Helpers.montaQueryStribgBYNzTable(params);

        return this.http.get(`${environment.apiUrl}/sat/detalhe/${empresaId}/equipamento/${serieId}/lotes${qStr}`);

    }

    alterarEquipamento(empresaId: string, serieId: string, data): Observable<any> {

        return this.http.post(`${environment.apiUrl}/sat/detalhe/${empresaId}/equipamento/${serieId}`, data);
    }

    alterarSat(data): Observable<any> {

        return this.http.post(`${environment.apiUrl}/sat/gravar`, data);
    }

}
