<div id="profile-container" nz-dropdown [nzPlacement]="'bottomRight'" [nzDropdownMenu]="userMenu" nzTrigger="click"
     style="cursor: pointer;">
    <nz-badge id="profile">
        <nz-avatar *ngIf="!currentUser?.srcAvatar" nzIcon="user" [nzSize]="30" class="mr-sm"></nz-avatar>
        <img *ngIf="currentUser?.srcAvatar"
             nz-image class="imagem-avatar"
             [nzSrc]="currentUser?.srcAvatar"
             alt="{{currentUser.nome}}"
             nzDisablePreview>
    </nz-badge>
    <div *ngIf="currentUser else carregandoUsuario"
         style="min-width: 130px; max-width: 130px; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;">
        {{ this.currentUser?.nome}}
    </div>
    <ng-template #carregandoUsuario>
        <div class="lds-ellipsis" style="width: 118px; margin-left: 12px"
             nz-tooltip="Carregando. Por favor aguarde." nzTooltipPlacement="bottom">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </ng-template>
</div>
<nz-dropdown-menu #userMenu="nzDropdownMenu">
    <div class="seta-para-cima seta-user-menu"></div>
    <div style="background-color: #fff;">
        <ul nz-menu class="user-menu-list" style="border: none;">
            <!--            <li nz-menu-item *ngIf="currentUser?.admin === 1" (click)="screenShot()">-->
            <!--                Teste-->
            <!--            </li>-->
            <li nz-menu-item (click)="currentUser ? openTab('/usuario-logado') : ''" [nzDisabled]="!currentUser"
                nz-menu-item *ngIf="currentUser?.origem !== 'console'">
                <em style="margin-right: 5px; margin-left: -1px" class="far fa-fw fa-id-card"></em> Meu Usuário
            </li>
            <li nz-menu-item (click)="currentUser ? mensagemUserConsole() : '';" [nzDisabled]="!currentUser"
                *ngIf="currentUser?.origem === 'console'">
                <em style="margin-right: 5px; margin-left: -1px" class="far fa-fw fa-id-card"></em> Meu Usuário
            </li>
            <li nz-menu-item *ngIf="currentUser?.admin === 1 || currentUser?.origem == 'console'"
                [nzDisabled]="!currentUser"
                (click)="currentUser ? openTab('/administracao'): ''">
                <em style="margin-right: 5px; margin-left: -1px" class="fas fa-fw fa-cog"></em> Administração
            </li>
            <li nz-submenu [nzTitle]="titleImgBug" [nzDisabled]="!currentUser"
                *ngIf="currentUser?.admin === 1 || currentUser?.suporte === 1 || currentUser?.origem == 'console'">
                <ul class="solicitar-atendimento-button">
                    <li nz-menu-item (click)="currentUser ? modalChamado(true) : ''">
                        Solicitar Atendimento
                    </li>
                    <li nz-menu-item [nzDisabled]="!currentUser?.urlMovidesk"
                        (click)="currentUser ? goToLink(currentUser?.urlMovidesk) : ''">
                        Meus chamados
                    </li>
                </ul>
            </li>
            <ng-template #titleImgBug>
                <div style="display: inline-block">
                    <i style="margin-right: 6px" class="fa fa-bug"></i>
                    <span>Suporte</span>
                </div>
            </ng-template>
            <li nz-menu-item [nzDisabled]="!currentUser"
                (click)="currentUser ? openTab('/downloadCenter') : ''">
                <i style="margin-right: 5px" class="fa-solid fa-download"></i> Central de Downloads
            </li>
            <li nz-menu-item (click)="openTab('/connect/connectDownload')">
                <i style="margin-right: 5px; margin-left: -1px" class="fa fa-robot"></i>
                Agent Desktop
            </li>
            <div style="border-bottom: 1px solid rgba(205,205,205,0.68); margin: 0 4px"></div>
            <li nz-menu-item class="text-muted-list">
                ID: {{currentUser?.identificador}}
            </li>
            <li nz-menu-item class="text-muted-list">
                Classificação: {{currentUser?.conta_classificacao ? labelClassificacao[currentUser?.conta_classificacao] : '-'}}
            </li>
            <div style="border-bottom: 1px solid rgba(205,205,205,0.68); margin: 0 4px"></div>
            <li nz-submenu nzTitle="Versão...">
                <ul>
                    <li nz-menu-item class="text-muted-list">
                        API
                    </li>
                    <li nz-menu-item class="text-muted-list">
                        Branch: {{branch}}
                    </li>
                    <li nz-menu-item class="text-muted-list">
                        Versão: {{ versionDate ? (versionDate | date:'dd/MM/YY - HH:mm:ss') : '' }}
                    </li>
                    <nz-divider></nz-divider>
                    <li nz-menu-item class="text-muted-list">
                        APP
                    </li>
                    <deploy-info></deploy-info>
                </ul>
            </li>
            <div style="border-bottom: 1px solid rgba(205,205,205,0.68); margin: 0 4px"></div>
            <li style="padding-left: 10px" nz-menu-item (click)="logout(true)">
                <em style="margin-right: 5px" class="fas fa-fw fa-power-off"></em> Sair
            </li>
        </ul>
    </div>
</nz-dropdown-menu>
<nz-modal [(nzVisible)]="formChamado.modalVisible"
          [nzTitle]="'Solicitar Atendimento'" [nzClosable]="true"
          (nzOnCancel)="modalChamado(false)"
          [nzWidth]="648"
          class="modalChamado">
    <div nz-row *nzModalContent>
        <form nz-form [nzNoColon]="true" [formGroup]="formChamado.formGroup" style="width: 100%;"
              *ngIf="!chamadoAberto">
            <nz-button-group style="width: 100%; margin-bottom: 10px;">
                <button nz-button [nzType]="tipoChamado == 'erro' ? 'primary' : 'default'"
                        style="width: 25%; height: 125px; margin-left: 1px;" [nzGhost]="tipoChamado == 'erro'"
                        (click)="changeTipo('erro')">
                    <div>
                        <em class="fas fa-fw fa-bug" style="font-size: 30px;" aria-hidden="true"></em>
                    </div>
                    <span>Reportar<br>Erro</span>
                </button>
                <button nz-button [nzType]="tipoChamado == 'duvida' ? 'primary' : 'default'"
                        style="width: 25%; height: 125px; margin-left: 1px;" [nzGhost]="tipoChamado == 'duvida'"
                        (click)="changeTipo('duvida')">
                    <div>
                        <i class="fas fa-question-circle" style="font-size: 30px;"></i>
                    </div>
                    <span>Tirar<br>Duvida</span>
                </button>
                <button nz-button [nzType]="tipoChamado == 'permissao' ? 'primary' : 'default'"
                        style="width: 25%; height: 125px; margin-left: 1px;" [nzGhost]="tipoChamado == 'permissao'"
                        (click)="changeTipo('permissao')">
                    <div>
                        <i class="fas fa-lock-open" style="font-size: 30px;"></i>
                    </div>
                    <span>Permissão<br>de Acesso</span>
                </button>
                <button nz-button [nzType]="tipoChamado == 'melhoria' ? 'primary' : 'default'"
                        style="width: 25%; height: 125px; margin-left: 1px;" [nzGhost]="tipoChamado == 'melhoria'"
                        (click)="changeTipo('melhoria')">
                    <div>
                        <i class="fas fa-file-alt" style="font-size: 30px;"></i>
                    </div>
                    <span>Sugerir<br>Melhoria</span>
                </button>
            </nz-button-group>
            <nz-form-item>
                <nz-form-label>Titulo</nz-form-label>
                <input nz-input placeholder="Digite..." formControlName="titulo">
            </nz-form-item>
            <nz-form-item>
                <nz-form-label>Descrição</nz-form-label>
                <textarea rows="4" nz-input formControlName="descricao"></textarea>
            </nz-form-item>
            <nz-form-item style="align-items: center;">
                <button nz-button (click)="screenShotFunction()" [nzLoading]="loadings.capturarTela">
                    <!--<i class="fas fa-camera" style="margin-right: 5px;"></i>-->
                    {{loadings.capturarTela ? 'Capturando Tela' : 'Capturar Tela'}}
                </button>
                <span style="margin: 0 10px;">ou</span>
            </nz-form-item>
            <nz-form-item>
                <nz-upload [nzMultiple]="true" [(nzFileList)]="fileListChamado" (nzChange)="removerArquivo($event)"
                           [nzBeforeUpload]="beforeUploadChamado">
                    <button nz-button>
                        <i nz-icon nzType="upload"></i>
                        Selecione um Arquivo
                    </button>
                </nz-upload>
            </nz-form-item>
        </form>

        <div *ngIf="chamadoAberto">
            <h2>Chamado enviado com sucesso!</h2>
            <p>Anote o número do tícket para consulta: <strong>{{chamadoAberto}}</strong></p>
        </div>

    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalChamado(false)" *ngIf="!chamadoAberto">Cancelar</button>
        <button nz-button nzType="primary" (click)="enviarChamado()" [nzLoading]="loadings.abrindoChamado"
                *ngIf="!chamadoAberto">Enviar
        </button>

        <button nz-button nzType="link" (click)="outroChamado()" *ngIf="chamadoAberto">Abrir outro chamado</button>
        <button nz-button nzType="default" (click)="modalChamado(false)" *ngIf="chamadoAberto">Fechar</button>
    </div>
</nz-modal>
