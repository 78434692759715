<nz-page-header class="site-page-header" nzTitle="Administração" nzSubtitle="Gerenciamento de Empresas">
</nz-page-header>
<nz-content>
    <nz-card [nzExtra]="navBar" nzTitle="Empresas"
             [ngClass]="[status ? 'card-fullscreen' : 'ant-card-table']">
        <ng-template #navBar>
            <div class="nav-item d-flex">
                <button nz-button nzType="primary"
                        style="margin-right: 10px;"
                        (click)="modalCadastrar(true);">
                    Cadastrar
                </button>
                <nz-badge [nzCount]="qtdFiltrosAtivos"
                          [nzStyle]="{ backgroundColor: '#52c41a', 'margin-right' : '10px'}">
                    <button nz-button nzType="primary"
                            style="margin-right: 10px;"
                            (click)="modalFiltrar(true);">
                        <i nz-icon nzType="search"></i>
                        Filtros
                    </button>
                </nz-badge>
                <button class="centralized-icon" [nzDropdownMenu]="menuAcoes"
                        [nzType]="'default'" nz-button nz-dropdown style="margin-right: 10px;">
                    Opções
                    <em nz-icon nzType="down"></em>
                </button>
                <nz-dropdown-menu #menuAcoes="nzDropdownMenu">
                    <ul nz-menu>
                        <li nz-submenu nzTitle="Manutenção Coletiva">
                            <ul>
                                <li nz-menu-item nzDisabled>Ações</li>
                                <li nz-menu-divider></li>
                                <li (click)="onAlterarAtividades()" [nzDisabled]="checkedItems?.size === 0"
                                    nz-menu-item>
                                    Alterar Atividades
                                </li>
                                <li (click)="onAlterarGrupoEmpresarial()" [nzDisabled]="checkedItems?.size === 0"
                                    nz-menu-item>
                                    Alterar Grupo Empresarial
                                </li>
                                <li (click)="onAlterarVisibilidadeColetiva()" [nzDisabled]="checkedItems?.size === 0"
                                    nz-menu-item>
                                    Alterar Visibilidade
                                </li>
                                <li (click)="modalStatus(true)"
                                    [nzDisabled]="checkedItems?.size === 0" nz-menu-item>
                                    Alterar status Ativo/Inativo
                                </li>
                            </ul>
                        </li>
                        <li nz-menu-item (click)="modalImportar(true);" *ngIf="user?.origem == 'console'">Importar
                            Planilha
                        </li>
                        <li nz-menu-item nz-button (click)="modalExportar(true);">Exportar Planilha</li>
                    </ul>
                </nz-dropdown-menu>
                <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" style="margin-right: 10px;">
                    <input (keydown.enter)="queryTable(currentParams, currentSearch)" type="text" nz-input
                           placeholder="Busca" [(ngModel)]="currentSearch">
                </nz-input-group>
                <ng-template #suffixIconButton>
                    <button nz-button nzType="primary" nzSearch (click)="queryTable(currentParams, currentSearch);">
                        <i nz-icon nzType="search"></i></button>
                </ng-template>
                <button nz-button nzType="default" style="margin-right: 10px;"
                        (click)="btnResetSearch();">
                    Limpar
                </button>
                <button nz-button [nzType]="'default'" (click)="clickEvent()"
                        style="padding-left: 8px; padding-right: 8px;">
                    <i class="fas" [ngClass]="!status ? 'fa-expand' : 'fa-compress'"></i>
                </button>
            </div>
        </ng-template>

        <nz-alert nzType="info" nzShowIcon [nzMessage]="checkedMessage" style="margin: 15px !important;"
                  *ngIf="checkedItems.size > 0">
            <ng-template #checkedMessage>
                <div nz-row nzAlign="middle" nzGutter="24">
                    <div nz-col nzSpan="24">
                        <strong class="text-primary"></strong>
                        {{ checkedItems.size }} Registros Selecionados
                    </div>
                </div>
            </ng-template>
        </nz-alert>
        <nz-table #basicTable
                  [nzData]="items"
                  [nzFrontPagination]="false"
                  [nzLoading]="loading"
                  [nzTotal]="pagination?.total"
                  [nzPageSize]="pagination?.per_page"
                  [nzPageIndex]="pagination?.current_page"
                  (nzQueryParams)="queryTable($event)"
                  [nzShowTotal]="rangeTemplateUsuarios"
                  [nzScroll]="{ x: '1100px' }"
                  class="table-small">
            <thead>
            <tr>
                <th style="width: 50px;" (nzCheckedChange)="onAllChecked($event)" [nzChecked]="checked"
                    id="check-all">
                </th>
                <th nzColumnKey="nome" [nzSortFn]="true">Nome</th>
                <th nzColumnKey="codigo" [nzSortFn]="true">Código</th>
                <th nzColumnKey="cnpj" [nzSortFn]="true">CNPJ</th>
                <th nzColumnKey="grupoEmpresarial_descricao" [nzSortFn]="true">Grupo Empresarial</th>
                <th nzAlign="center" nzColumnKey="atividade" [nzSortFn]="true">Atividade</th>
                <th nzColumnKey="visibilidade_descricao" [nzSortFn]="true">Visibilidade</th>
                <th nzAlign="center" nzColumnKey="created_at" [nzSortFn]="true">Cadastro Data</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let empresa of basicTable.data">
                <td nzLeft (nzCheckedChange)="onItemChecked(empresa.id, $event)"
                    [nzChecked]="checkedItems.has(empresa.id)"
                    [nzDisabled]="empresa.disabled">
                </td>
                <td nzLeft>
                    <span style="cursor: pointer; color: #1e9ff2"
                          (click)="openTab('/administracao/empresa/detalhe', empresa.id, {id: empresa.id, cnpj: modoDemo(empresa.cnpj, 'cnpjNumber')})">
                        {{modoDemo(empresa?.nome, 'empresaCodigoNome')}}
                    </span>
                    <nz-tag [nzColor]="'#f50'" *ngIf="!empresa?.ativo" style="margin-left: 10px; font-size: 10px">
                        Inativo
                    </nz-tag>
                </td>
                <td nzLeft>{{empresa?.codigo ? modoDemo(empresa.codigo, 'total') : '-'}}</td>
                <td nzLeft style="white-space: nowrap;">{{modoDemo(empresa?.cnpj | cpfCnpj)}}</td>
                <td>{{modoDemo(empresa?.grupoEmpresarial_descricao)}}</td>
                <td nzAlign="center">{{empresa?.atividade}}</td>
                <td>
                    <nz-tag *ngIf="empresa?.visibilidade_descricao"
                            [nz-tooltip]="empresa?.visibilidade_descricao.length > 15 ? empresa?.visibilidade_descricao : ''">
                        {{abrevVisibilidade(empresa?.visibilidade_descricao)}}</nz-tag>
                    <nz-tag *ngIf="!empresa?.visibilidade_descricao">
                        Geral
                    </nz-tag>

                </td>
                <td nzAlign="center">{{empresa.created_at ? (empresa.created_at | date:'dd/MM/YY - HH:mm:ss') : '-' }}</td>
                <td nzAlign="left">
                    <a nz-button nzType="link"
                       (click)="openTab('/configuracoes/empresa/', empresa.id, {id: empresa.id, cnpj: empresa.cnpj, link: '/auditor/configuracoes/conta/&conta_id=' + empresa.cnpj, extra:''}, false)">
                        <i class="fas fa-cogs"></i>&nbsp;Configurações
                    </a>
                </td>
            </tr>
            </tbody>
        </nz-table>
        <ng-template #rangeTemplateUsuarios let-range="range" let-total>
            {{ range[0] }}-{{ range[1] }} de {{ total }} Registros
        </ng-template>
    </nz-card>
</nz-content>

<nz-modal [(nzVisible)]="displayGrupoEmpresarialModal" [nzAutofocus]="null" [nzFooter]="modalFooter"
          nzTitle="Alterar Grupo Empresarial"
          (nzOnCancel)="handleCancel()">
    <ng-template nzModalContent>
        <nz-select [(ngModel)]="selectedGrupoEmpresarial"
                   [nzPlaceHolder]="'Selecione'">
            <nz-option *ngFor="let option of grupoEmpresarialOptions" [nzLabel]="option.label"
                       [nzValue]="option.value"></nz-option>
        </nz-select>
    </ng-template>
    <ng-template #modalFooter>
        <a (click)="handleCancel()" nz-button nzType="link">Cancelar</a>
        <button (click)="saveBulkToGruposEmpresariais()" nz-button nzType="primary">Confirmar</button>
    </ng-template>
</nz-modal>

<nz-modal (nzOnCancel)="closeNewVisibilidade()" (nzOnOk)="saveNewVisibilidade()" [(nzVisible)]="newVisibilidadeModal"
          [nzAutofocus]="null" nzTitle="Nova Visibilidade">
    <div *nzModalContent>
        <nz-form-item>
            <label for="novaVisibilidade"></label>
            <input [(ngModel)]="newVisibilidade" id="novaVisibilidade" nz-input placeholder="descrição" type="text">
        </nz-form-item>
    </div>
</nz-modal>

<nz-modal (nzOnCancel)="closeNewGrupoEmpresarial()" (nzOnOk)="saveNewGrupoEmpresarial()"
          [(nzVisible)]="newGrupoEmpresarialModal"
          [nzAutofocus]="null" nzTitle="Novo Grupo Empresarial">
    <div *nzModalContent>
        <nz-form-item>
            <label></label>
            <input [(ngModel)]="newGrupoEmpresarial" nz-input placeholder="descrição" type="text">
        </nz-form-item>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="modalCadastrarVisible" [nzTitle]="'Cadastrar Nova Empresa'" [nzClosable]="true"
          (nzOnCancel)="modalCadastrar(false)" [nzWidth]="648">
    <div *nzModalContent>


        <nz-skeleton *ngIf="loadings.buscandoLimites"></nz-skeleton>

        <nz-result
                *ngIf="erroLicencas && !loadings.buscandoLimites"
                nzStatus="warning"
                nzTitle=""
                nzSubTitle="Esta Conta tem limite de licenças para cadastro de CNPJs e todas as licenças permitidas
                já foram utilizadas. Por favor entrar em contato com o Atendimento para a aquisição de novas licenças."
        ></nz-result>

        <form nz-form [nzDisableAutoTips]="true" [nzNoColon]="true" [formGroup]="formCadastrar"
              *ngIf="!erroLicencas && !loadings.buscandoLimites"
              style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="6" nzRequired>Nome</nz-form-label>
                <nz-form-control [nzSpan]="12" [nzErrorTip]="'Campo Obrigatório'">
                    <input nz-input type="text" formControlName="nome" placeholder="informe o nome da empresa"
                           type="text" maxlength="100"/>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="6">Código</nz-form-label>
                <nz-form-control [nzSpan]="12">
                    <input nz-input type="text" formControlName="codigo" placeholder="informe o código da empresa"
                           type="text" maxlength="100"/>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="6" nzRequired>CNPJ</nz-form-label>
                <nz-form-control [nzSpan]="12" [nzErrorTip]="'Campo Obrigatório'">
                    <input nz-input type="text" formControlName="cnpj" placeholder="informe o CNPJ da empresa"
                           type="text" maxlength="18"/>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item style="margin-bottom: 0;">
                <nz-form-label [nzSpan]="6" nzRequired>Grupo Empresarial</nz-form-label>
                <nz-form-control [nzSpan]="12" [nzErrorTip]="'Campo Obrigatório'">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="grupoEmpresarial_id">
                        <nz-option *ngFor="let opt of grupoEmpresarialOptions"
                                   [nzLabel]="modoDemo(opt.label)"
                                   [nzValue]="opt.value"
                        ></nz-option>

                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item style="margin-top: 0; margin-bottom: 15px; text-align: right;">
                <nz-form-control [nzSpan]="12" [nzOffset]="6" style="text-align: right;">
                    <button (click)="addNewGrupoEmpresarial()" class="new-markup-bnt" nz-button nzType="link"
                            style="float: right; padding-right: 0">
                        <em nz-icon nzType="plus-circle"></em>Novo grupo empresarial
                    </button>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="6" nzRequired>Atividade</nz-form-label>
                <nz-form-control [nzSpan]="12" [nzErrorTip]="'Campo Obrigatório'">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="atividade">
                        <nz-option nzLabel="Indústria" nzValue="Indústria"></nz-option>
                        <nz-option nzLabel="Varejo" nzValue="Varejo"></nz-option>
                        <nz-option nzLabel="Comércio" nzValue="Comércio"></nz-option>
                        <nz-option nzLabel="Serviços" nzValue="Serviços"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item style="margin-bottom: 0;">
                <nz-form-label [nzSpan]="6" nzRequired>Visibilidade</nz-form-label>
                <nz-form-control [nzSpan]="12" [nzErrorTip]="'Campo Obrigatório'">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="visibilidade_id">
                        <nz-option *ngFor="let opt of visibilidadesOptions"
                                   [nzLabel]="opt.label"
                                   [nzValue]="opt.value"
                        ></nz-option>

                    </nz-select>
                </nz-form-control>
            </nz-form-item>


        </form>
    </div>
    <div *nzModalFooter>
        <a nz-button nzType="link" (click)="modalCadastrar(false)">Cancelar</a>
        <button nz-button nzType="primary" *ngIf="!erroLicencas" [nzLoading]="cadastrando"
                (click)="this.confirmAtualizarprocuracoes('Será utilizada procuração de alguma empresa já cadastrada?', 'unico');">
            Confirmar
        </button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formFiltrar.modalVisible" [nzTitle]="'Filtros Avançados'" [nzClosable]="true"
          (nzOnCancel)="modalFiltrar(false)" [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzDisableAutoTips]="true" [nzNoColon]="true" [formGroup]="formFiltrar.formGroup"
              style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Grupo Empresarial</nz-form-label>
                <nz-form-control [nzSpan]="14"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.grupoEmpresarial_id">
                    <nz-select nzShowSearch
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="grupoEmpresarial_id">
                        <nz-option *ngFor="let opt of grupoEmpresarialOptions"
                                   [nzLabel]="modoDemo(opt.label)"
                                   [nzValue]="opt.value"
                        ></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="5">Inativo</nz-form-label>
                <nz-form-control [nzSpan]="14"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.inativo">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="inativo">
                        <nz-option
                                [nzLabel]="'Mostrar somente inativos'" [nzValue]="'somente'">
                        </nz-option>
                        <nz-option
                                [nzLabel]="'Mostrar também inativos'" [nzValue]="'tambem'">
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>


        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalFiltrar(false)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="cadastrando" (click)="confirmaFiltrar()">Confirmar</button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="modalImportarVisible"
          [nzTitle]="'Importar Planilha'" [nzClosable]="true"
          (nzOnCancel)="modalImportar(false)"
          [nzWidth]="648">
    <div *nzModalContent>
        <div>
            <p style="margin: 0px;">Selecione o arquivo (.xls ou .xlsx) para importar.</p>
            <small style="">Clique <a nz-link style="cursor:pointer;" (click)="downloadModelo();">aqui</a> para baixar
                um
                arquivo de modelo.</small>
            <div style="margin-top: 20px;">
                <nz-upload
                        nzType="drag"
                        [nzMultiple]="true"
                        [(nzFileList)]="fileList" [nzBeforeUpload]="beforeUpload">
                    <p class="ant-upload-drag-icon">
                        <i nz-icon nzType="inbox"></i>
                    </p>
                    <p class="ant-upload-text">Clique ou arraste o arquivo para esta área para fazer o upload</p>
                </nz-upload>
            </div>
        </div>
    </div>

    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalImportar(false);">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="cadastrando"
                (click)="confirmarImportar();">
            Enviar arquivo
        </button>
    </div>
</nz-modal>

<app-exportar-tabela #componentExport [data]="dataExport"></app-exportar-tabela>

<nz-modal [(nzVisible)]="formStatus.modalVisible"
          [nzTitle]="'Alterar status Ativo/Inativo'" [nzClosable]="true"
          (nzOnCancel)="modalStatus(false)"
          [nzWidth]="425">
    <div nz-row *nzModalContent>
        <form nz-form [nzNoColon]="true" [formGroup]="formStatus.formGroup" style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="24">Empresa Ativa?</nz-form-label>
                <nz-form-control [nzSpan]="24">
                    <nz-select nzPlaceHolder="Selecione"
                               formControlName="ativo" style="width: 100%;">

                        <nz-option [nzLabel]="'Sim'" [nzValue]="'1'"></nz-option>
                        <nz-option [nzLabel]="'Não'" [nzValue]="'0'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalStatus(false);">Cancelar</button>
        <button nz-button nzType="primary" (click)="confirmaAlterarStatus()" [nzLoading]="loadings.alterandoStatus">
            Confirmar
        </button>
    </div>
</nz-modal>
<nz-modal [(nzVisible)]="formVisibilidade.modalVisible"
          [nzTitle]="'Alterar visibilidade'" [nzClosable]="true"
          (nzOnCancel)="modalVisibilidadeColetiva(false)"
          [nzWidth]="625">
    <div nz-row *nzModalContent>

        <nz-skeleton *ngIf="loadings.getVisibilidades"></nz-skeleton>

        <form nz-form [nzDisableAutoTips]="true"
              [nzLayout]="'vertical'"
              *ngIf="!loadings.getVisibilidades"
              [formGroup]="formVisibilidade.formGroup" style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="16" [nzOffset]="4">Selecione a visibilidade</nz-form-label>
                <nz-form-control [nzSpan]="16" [nzOffset]="4">
                    <nz-select formControlName="visibilidade"
                               [nzPlaceHolder]="'Selecione'" nzAllowClear>
                        <nz-option *ngFor="let option of arrayComboVisibilidades" [nzLabel]="option.label"
                                   [nzValue]="option.value"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalVisibilidadeColetiva(false);">Cancelar</button>
        <button nz-button nzType="primary" (click)="confirmaAlterarVisibilidades()"
                [nzLoading]="loadings.salvandoVisibilidades">
            Confirmar
        </button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="modalAtualizarProcuracoesVisible"
          [nzTitle]="'Selecionar Procurador E-CAC'" [nzClosable]="true"
          (nzOnCancel)="showModalAtualizarProcuracoes(false)"
          [nzWidth]="648">
    <div *nzModalContent>

        <div style="display: flex; align-items: end; padding: 0; margin-bottom: 10px; width: 100%">
            <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" style="margin-right: 10px;">
                <input (keyup)="filtrarProcuradoresFront()" type="text" nz-input placeholder="Busca"
                       [(ngModel)]="buscaInterna"/>
            </nz-input-group>
            <ng-template #suffixIconButton>
                <button nz-button nzType="primary" nzSearch (click)="filtrarProcuradoresFront()">
                    <i nz-icon nzType="search"></i></button>
            </ng-template>
            <button nz-button nzType="default" style="margin-right: 10px;"
                    (click)="btnResetSearchFront();">
                Limpar
            </button>
        </div>

        <nz-skeleton *ngIf="loadingProcuradores"></nz-skeleton>

        <nz-table #basicTable
                  [nzData]="procuradoresFront"
                  *ngIf="!loadingProcuradores"
                  [nzShowPagination]="false"
                  class="table-small"
                  style="font-size: 10px; width: 100%;"
                  [nzScroll]="{ y: '320px', x: 'none'}">
            <thead>
            <tr>
                <th nzColumnKey="nome">Empresa</th>
                <th nzColumnKey="cnpj">CNPJ</th>
            </tr>
            </thead>
            <tbody>

            <tr *ngFor="let data of basicTable.data" (click)="procuracaoChecked(data.id)"
                style="cursor: pointer;">
                <td [ngStyle]="{'background-color': checkedProcuradores.has(data.id) ? '#e6f7ff' : ''}" nzLeft>
                    {{data?.nome}}
                </td>
                <td [ngStyle]="{'background-color': checkedProcuradores.has(data.id) ? '#e6f7ff' : ''}">
                    {{data?.cnpj | cpfCnpj}}
                </td>
            </tr>
            </tbody>
        </nz-table>
    </div>

    <div *nzModalFooter>
        <button nz-button nzType="primary" [nzLoading]="loadinAtualizandoprocuracoes"
                disabled="{{checkedProcuradores.size < 1}}" (click)="atualizarProcuracoes()">Atualizar
        </button>
    </div>
</nz-modal>


<nz-modal [(nzVisible)]="displayAtividadesModal" [nzFooter]="modalFooterAtv"
          (nzOnCancel)="handleCancel()"
          nzTitle="Alterar Atividades">
    <ng-template nzModalContent>
        <nz-select required="required" [(ngModel)]="selectedAtividade"
                   [nzPlaceHolder]="'Selecione'">
            <nz-option *ngFor="let option of atividadesOptions" [nzLabel]="option.label"
                       [nzValue]="option.value"></nz-option>
        </nz-select>
    </ng-template>
    <ng-template #modalFooterAtv>
        <a (click)="handleCancel()" nz-button nzType="link">Cancelar</a>
        <button [nzLoading]="loadings.alterandoAtividade" (click)="saveBulkToAtividades()" nz-button nzType="primary">
            Confirmar
        </button>
    </ng-template>
</nz-modal>
