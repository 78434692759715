import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {AbstractService} from '@services/abstract.service';
import {Observable, Subject} from 'rxjs';


@Injectable({providedIn: 'root'})
export class ArquivosTransitoriosServiceService extends AbstractService<any> {

    constructor(http: HttpClient) {
        super(http, '/arquivos-transitorios/upload', {});
    }


    doUpload(formData: FormData): Observable<any> {
        return this.http.post(`${environment.apiUrl}/drive/obrigacoes/federais/upload`, formData);
    }

    retornarSelectsMotivos(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/arquivos-transitorios/motivos?caminho=agent&pasta=xml`);
    }

    retornaTipos(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/drive/obrigacoes/federais/listar`);
    }

    reprocessarArquivos(arquivos: any): any {
        return this.http.patch(`${environment.apiUrl}/arquivos-transitorios/reprocessar`, {arquivos});
    }

    removerArquivos(arquivos: any): any {
        return this.http.post(`${environment.apiUrl}/arquivos-transitorios/remover`, {arquivos});
    }

    retornaPastasArquivosTransitorios(origem): any {
        return this.http.get(`${environment.apiUrl}/arquivos-transitorios/pastas/${origem}`);
    }

    retornaTabelaArquivosTransitorios(caminho: string, filtros = null): any {

        const queryStr = filtros ? new URLSearchParams(filtros) : '';

        return this.http.get(`${environment.apiUrl}/arquivos-transitorios/listar?${caminho}&${queryStr}`);
    }

    processarArquivo(arquivo: string, caminho: string, pastaTipo: string, origem: string) {

        const data = {
            path: caminho + arquivo,
            obrigacao: pastaTipo,
            origem,
        };

        return this.http.post(`${environment.apiUrl}/arquivos-transitorios/processar`, data);

    }
}
