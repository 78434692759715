import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {Helpers} from '../../../core/helpers';
import {HttpClient} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class ComplianceTributosGruposDetalhesService {

    constructor(private http: HttpClient) {
    }

    retornaEmpresa(id: string, ano: string, ie = null): Observable<any> {

        return this.http.get(`${environment.apiUrl}/compliance/grupos/tributos/detalhe-grupo/${id}/${ano}`);

    }

    retornaObrigacaoParaModal(id: string): Observable<any> {

        return this.http.get(`${environment.apiUrl}/compliance/grupos/tributos/dcomp-web/detalhe/` + id);
    }

    retornaObrigacoesAcessorias(grupo: string, ano: string, ie = null): Observable<any> {

        return this.http.get(`${environment.apiUrl}/compliance/grupos/tributos/obrigacoes-acessorias/${grupo}/${ano}`);
    }

    retornaObrigacoesPagamentos(grupo: string, ano: string, ie = null): Observable<any> {

        return this.http.get(`${environment.apiUrl}/compliance/grupos/tributos/pagamentos-principais/${grupo}/${ano}`);
    }

    retornaTributos(grupo: string, ano: string, imposto: string, ie: string): Observable<any> {
        const ieParam = ie ? `&ie=${ie}` : '';
        return this.http.get(
            `${environment.apiUrl}/compliance/grupos/tributos/${grupo}?ano=${ano}&tributo=${imposto}` + ieParam);
    }

    exportExcel(grupo: string, ano: string, imposto: string, ie: string): any {
        const ieParam = ie ? `&ie=${ie}` : '';
        return this.http.get(
            `${environment.apiUrl}/compliance/grupos/tributos/${grupo}/exportar?ano=${ano}&tributo=${imposto}` + ieParam,
            {responseType: 'blob'});
    }

    retornaDivergencias(ano, filtros): Observable<any> {

        if (filtros.empresa_id) {
            filtros.grupoEmpresarial = filtros.empresa_id;
            delete filtros.empresa_id;
        }

        const queryString = Helpers.montaQueryString(filtros);

        return this.http.get(`${environment.apiUrl}/compliance/obrigacoes/retorna-divergencias/${ano}${queryString}`);

    }

    retornaAusencias(ano, filtros): Observable<any> {

        if (filtros.empresa_id) {
            filtros.grupoEmpresarial = filtros.empresa_id;
            delete filtros.empresa_id;
        }

        const queryString = Helpers.montaQueryString(filtros);
        return this.http.get(`${environment.apiUrl}/compliance/obrigacoes/retorna-ausencias/${ano}${queryString}`);
    }

    retornarSelectsObrigacoes(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/obrigacoes/select`);
    }

    retornarSelectsOcorrencias(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/obrigacoes/ocorrencias/select`);
    }

    retornaObservacao(tipoOcorrencia, idOcorrencia): Observable<any> {

        return this.http.get(`${environment.apiUrl}/compliance/obrigacoes/retorna-observacoes/${tipoOcorrencia}/${idOcorrencia}`);

    }

    removerObservacao(tipoOcorrencia, idObservasao): Observable<any> {

        return this.http.delete(`${environment.apiUrl}/compliance/obrigacoes/excluir-observacao/${tipoOcorrencia}/${idObservasao}`);

    }

    adicionarObservacao(tipoOcorrencia, idOcorrencia, descricao): Observable<any> {

        const body = {
            descricao
        };

        return this.http.post(`${environment.apiUrl}/compliance/obrigacoes/gravar-observacao/${tipoOcorrencia}/${idOcorrencia}`, body);
    }

    editarObservacao(tipoOcorrencia, idOcorrencia, idObservacao, descricao): Observable<any> {

        const body = {
            descricao
        };

        return this.http.put(
            `${environment.apiUrl}/compliance/obrigacoes/gravar-observacao/${tipoOcorrencia}/${idOcorrencia}/${idObservacao}`
            , body);
    }

    atuizarTributosEmpresa(ano: string, grupoEmpresarial: string, tributoId: string): Observable<any> {

        return this.http.get(
            `${environment.apiUrl}/robo/executar/compliance-tributo?ano=${ano}&grupoEmpresarial=${grupoEmpresarial}&tributo_id=${tributoId}`
        );
    }

    retornarOptionGrupos(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/gruposEmpresariais/select`);
    }
}
