import {FormControl} from '@angular/forms';
import {Helpers} from './helpers';

export function isCPF(control: FormControl): { [key: string]: boolean } | null {
    if (!control.value) {
        return null;
    }

    if (Helpers.isValidCPF(control.value)) {
        return null;
    } else {
        return {cnpj: true};
    }
}

export function isCNPJ(control: FormControl): { [key: string]: boolean } | null {
    if (!control.value) {
        return null;
    }

    if (Helpers.isValidCnpj(control.value)) {
        return null;
    } else {
        return {cnpj: true};
    }
}

export function isCEP(control: FormControl): { [key: string]: boolean } | null {
    return Helpers.isValidCEP(control.value) ? null : {cep: true};
}

export function isPhone(control: FormControl): { [key: string]: boolean } | null {
    return Helpers.isPhoneNumber(control.value) ? null : {cep: true};
}

export function isNumber(control: FormControl): { [key: string]: boolean } | null {
    if (!control.value) {
        return null;
    }

    if (Helpers.isNumber(control.value)) {
        return null;
    } else {
        return {notNumber: true};
    }
}

export function notZero(control: FormControl): { [key: string]: boolean } | null {
    if (!control.value) {
        return null;
    }

    if (Helpers.notZero(control.value)) {
        return null;
    } else {
        return {notZero: true};
    }
}

export function isNumberOrComma(control: FormControl): { [key: string]: boolean } | null {
    if (!control.value) {
        return null;
    }

    if (Helpers.isNumberOrComma(control.value)) {
        return null;
    } else {
        return {notNumberOrComma: true};
    }
}

export function isNumberOrCommaOrDot(control: FormControl): { [key: string]: boolean } | null {
    if (!control.value) {
        return null;
    }

    if (Helpers.isNumberOrCommaOrDot(control.value)) {
        return null;
    } else {
        return {notNumberOrCommaOrDot: true};
    }
}
