import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MonitorDeEventosComponent} from './monitor-de-eventos.component';
import {NzPageHeaderModule} from 'ng-zorro-antd/page-header';
import {NzLayoutModule} from 'ng-zorro-antd/layout';
import {NzCardModule} from 'ng-zorro-antd/card';
import {NzTableModule} from 'ng-zorro-antd/table';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {NzAlertModule} from 'ng-zorro-antd/alert';
import {NzGridModule} from 'ng-zorro-antd/grid';
import {NzBadgeModule} from "ng-zorro-antd/badge";
import {NzDropDownModule} from "ng-zorro-antd/dropdown";
import {NzIconModule} from "ng-zorro-antd/icon";
import {NzInputModule} from "ng-zorro-antd/input";
import {NzMenuModule} from "ng-zorro-antd/menu";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {UiModule} from "../../ui.module";
import {NzToolTipModule} from "ng-zorro-antd/tooltip";



@NgModule({
  declarations: [
    MonitorDeEventosComponent
  ],
    imports: [
        CommonModule,
        NzPageHeaderModule,
        NzLayoutModule,
        NzCardModule,
        NzTableModule,
        NzButtonModule,
        NzAlertModule,
        NzGridModule,
        NzBadgeModule,
        NzDropDownModule,
        NzIconModule,
        NzInputModule,
        NzMenuModule,
        ReactiveFormsModule,
        FormsModule,
        NzToolTipModule,
        UiModule,
    ]
})
export class MonitorDeEventosModule { }
