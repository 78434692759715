import {AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {StickyHeaderDirective} from '@components/directive/directive';
import {Pagination} from '@models/pagination.model';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import {EditarCredenciaisAcessoService} from './editar-credenciais-acesso.service';
import {ToastrService} from 'ngx-toastr';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {NzModalService} from 'ng-zorro-antd/modal';
import {NzUploadChangeParam, NzUploadFile} from 'ng-zorro-antd/upload';
import {environment} from '../../../../environments/environment';
import {UserService} from '@services/user.service';
import {DataService} from '@services/data.service';
import {Helpers} from '../../../core/helpers';
import {
    ModalCredencialDeAcessoComponent
} from '@components/modal-credencial-de-acesso/modal-credencial-de-acesso.component';

interface FormStack {
    modalVisible: boolean;
    formGroup: UntypedFormGroup;
}

@Component({
    selector: 'app-cadastros-editar-credenciais-acesso',
    templateUrl: './editar-credenciais-acesso.component.html',
    styleUrls: ['./editar-credenciais-acesso.component.scss'],
})
export class CadastrosEditarCredenciaisAcessoComponent extends AbstractListTable<any> implements OnInit, OnDestroy, AfterViewInit {
    @Input() data;
    dadosCadastrais: any = {};
    dadosDataPrev: any = {};

    listaCretificados: any[] = [];
    listaProcuracoes: any[] = [];
    listaProcuradores: any[] = [];
    listaAcessos: any[] = [];
    listaAcessosEcac: any[] = [];
    listaAcessosMunicipais: any[] = [];
    listaAcessosPrevidenciarios: any[] = [];

    description = '';

    offsetTop = 15;
    target: any;

    obrigacao: any = {};

    formAcessosDataPrev: FormStack;

    formCertificadoA1: FormStack;
    formProcuracoesECAC: FormStack;
    formProcuradoresECAC: FormStack;
    formAcessosEstaduais: FormStack;
    formAcessosMunicipais: FormStack;
    formAcessosPrevidenciarios: FormStack;
    formAdicionarCertificado: FormStack;
    formDataPrev: FormStack;
    formFiltrar: FormStack;
    formFiltrarProcuracoes: FormStack;
    formFiltrarProcuradores: FormStack;

    valueChangesSubscription: any;

    pagination: Pagination = {
        per_page: 10,
        current_page: 1,
        last_page: 50,
        total: 500,
        from: 1,
        to: 10
    };

    paginations: any = {
        certificados: this.pagination,
        estaduais: this.pagination,
        municipais: this.pagination,
        procuracoes: this.pagination,
        procuradores: this.pagination,
        acessosEstaduais: this.pagination,
        acessosMunicipais: this.pagination,
        acessosEcac: this.pagination,
        acessosPrevidenciarios: this.pagination,
    };

    autoTips: Record<string, Record<string, string>> = {
        default: {
            required: 'Campo obrigatório',
        }
    };

    credencialInvalida = null;

    params: NzTableQueryParams = {
        pageIndex: 1,
        pageSize: 10,
        sort: [],
        filter: []
    };

    currentParams: any = {
        certificados: this.params,
        procuracoes: this.params,
        procuradores: this.params,
        acessos: this.params,
        acessosMunicipais: this.params,
        acessosPrevidenciarios: this.params,
        acessosEcac: this.params,
    };
    currentSearch: any = {
        certificados: '',
        procuracoes: '',
        procuradores: '',
        acessos: '',
        acessosPrevidenciarios: ''
    };

    reloadingCert = false;

    loading: any = {
        dadosCadastrais: false,
        certificados: false,
        procuracoes: false,
        procuradores: false,
        acessos: false,
        acessosMunicipais: false,
        acessosEcac: false,
        formAcessos: false,
        formAcessosMunicipais: false,
        formAcessosPrevidenciarios: false,
        repicarMatries: false,
        buscarProcuracoes: false,
        senhaCertificado: false,
        download: false,
        insertCertificado: false,
        updateCertificado: false,
        insertAcesso: false,
        insertAcessoMunicipal: false,
        insertEstadual: false,
        updateAcesso: false,
        updateAcessosMunicipal: false,
        updateAcessosPrevidenciarios: false
    };

    editando: any = {
        certificados: '',
        procuracoes: '',
        procuradores: '',
        acessos: '',
        acessosMunicipais: '',
        acessosPrevidenciarios: null
    };

    erro = false;
    mensagem = '';

    apiUrl = environment.apiUrl;
    fileListCertificado: NzUploadFile[] = [];

    arquivoUpdate: NzUploadFile =
        {
            uid: '1',
            name: 'certificado.pfx',
            status: 'done',
            response: '', // custom error message to show
            url: ''
        };

    comboUFsEstaduais: { key: string, label: any }[] = [];
    comboUFs: { key: string, label: any }[] = [];
    comboUFsMunicipais: { key: string, label: any }[] = [];
    comboObrigacoes: any = [];
    camposFormDataPrev: any = [];
    camposFormDataPrevMunicipais: any = [];
    camposFormDataPrevPrevidencirios: any = [];
    comboMunicipios: any = [];
    comboPortaisPrevidenciarios: any = [];

    arraySelectEmpresa = [];
    arraySelectCnpj = [];

    identificacaoVisible = false;
    senhaVisible = false;

    usaCertificadoMatriz = false;
    usaCertificadoMatrizProcuracao = false;

    user: any;

    qtdFiltrosAtivosProcuracoes = 0;
    qtdFiltrosAtivosProcuradores = 0;
    ultimaConsulta = {
        status: '',
        dataHora: '',
        erroMensagem: ''
    };

    currentUser: any;

    estadualSelecionado: any = null;
    municipalSelecionado: any = null;
    dataPrevSelecionado: any = null;

    termosVisible = false;
    termosAceitos = false;
    checkDisabled = false;
    termosCompletosVisible = false;
    loadingAcessoEcac = false;

    acessoEcacSelecionado: any = {};

    responsaveis = [];
    optionsEmpresas = [];

    selecionarProcurador = false;

    statusToUpdate: number;

    formAcessoEcac: FormStack;

    @ViewChild('modalCredencialDeAcesso') modalCredencialDeAcesso: ModalCredencialDeAcessoComponent;
    certificadoPadrao: string;

    constructor(
        private service: EditarCredenciaisAcessoService,
        private toastService: ToastrService,
        private fb: UntypedFormBuilder,
        private userService: UserService,
        private cdr: ChangeDetectorRef,
        private modalService: NzModalService,
        private dataService: DataService
    ) {
        super(service, null, toastService);

        this.formCertificadoA1 = {
            modalVisible: false,
            formGroup: this.fb.group({
                empresa_id: [null, null],

            })
        };

        this.formAcessoEcac = {
            modalVisible: false,
            formGroup: this.fb.group({
                ids: [[], Validators.required],
                procurador: ['', Validators.required],
                statusAcessoEcac: [1, Validators.required],
            })
        };

        this.formProcuracoesECAC = {
            modalVisible: false,
            formGroup: this.fb.group({
                empresa_id: [null, null],
                pessoa: [null, null],
                documento: [null, null],
                nome: [null, null],
                dataInicio: [null, null],
                dataFim: [null, null],
                cadastrada: [null, null],
            })
        };
        this.formDataPrev = {
            modalVisible: false,
            formGroup: this.fb.group({
                empresa_id: [null, null],
                identificador: [null, null],
                senha: [null, null],
            })
        };

        this.formProcuradoresECAC = {
            modalVisible: false,
            formGroup: this.fb.group({
                empresa_id: [null, null],
                pessoa: [null, null],
                documento: [null, null],
                nome: [null, null],
                dataInicio: [null, null],
                dataFim: [null, null],
                cadastrada: [null, null],
            })
        };

        this.formAcessosEstaduais = {
            modalVisible: false,
            formGroup: this.fb.group({
                uf: [null, Validators.required],
                portal: [null, Validators.required],
            })
        };


        this.formAcessosMunicipais = {
            modalVisible: false,
            formGroup: this.fb.group({
                uf: [null, Validators.required],
                portal: [null, Validators.required],
            })
        };

        this.formAcessosPrevidenciarios = {
            modalVisible: false,
            formGroup: this.fb.group({
                portal: [null, Validators.required],
            })
        };

        this.formAcessosDataPrev = {
            modalVisible: false,
            formGroup: this.fb.group({
                identificacao: [null, Validators.required],
                senha: [null, Validators.required],
            })
        };

        this.formFiltrar = {
            modalVisible: false,
            formGroup: this.fb.group({
                cpf: [null, null],
                nome: [null, null],
                emissor: [null, null],
                validadeInicio: [null, null],
                validadeFim: [null, null],
                dataprev: [null, null]
            })
        };

        this.formFiltrarProcuracoes = {
            modalVisible: false,
            formGroup: this.fb.group({
                situacao: [null, null],
                cadastrada: [null, null]
            })
        };

        this.formFiltrarProcuradores = {
            modalVisible: false,
            formGroup: this.fb.group({
                situacao: [null, null],
                cadastrada: [null, null]
            })
        };

        this.service.retornaComboUFs('estadual').subscribe((retorno: any) => {
            this.comboUFsEstaduais = [];

            Object.entries(retorno).forEach(([key, value]: [string, { key: string, label: string }]) => {
                this.comboUFsEstaduais.push(value);
            });
        });

        this.service.retornaComboUFs('municipal').subscribe((retorno: any) => {
            this.comboUFsMunicipais = [];

            Object.entries(retorno).forEach(([key, value]: [string, { key: string, label: string }]) => {
                this.comboUFsMunicipais.push(value);
            });
        });

        this.service.retornarSelectsEmpresas(
        ).subscribe((retorno: any) => {
            this.arraySelectEmpresa = retorno.empresaNome;
            // this.arraySelectCnpj = retorno.empresaCnpj;
        });

        this.service.retornaPortaisPrevidenciarios().subscribe((retorno: any) => {
            retorno.forEach((value) => {
                value.label = value.descricao;
                value.key = value.id;
            });
            this.comboPortaisPrevidenciarios = retorno;
        });

        this.service.retornarOptionEmpresas().subscribe((retorno: any) => {
            this.optionsEmpresas = [];
            this.optionsEmpresas = retorno;
        });

    }

    ngOnInit(): void {

        if (!this.data || !this.data.id) {
            this.data = {};
            this.data.id = localStorage.getItem('param');
        }

        this.getLoggedUser();
        this.retornaDadosCadastrais(this.data.id);
        this.retornaDadosDataPrev(this.data.id);
        this.retornaUltimaConsultaProcuracoes(this.data.id);

        this.listarCertificados(this.currentParams.certificados, this.currentSearch.certificados);
        this.listarProcuradores(this.currentParams.procuradores);
        this.listarProcuracoes(this.currentParams.procuracoes);
        this.listarAcessosPrevidenciarios(this.currentParams.acessosPrevidenciarios);
        this.listarAcessosMunicipais(this.currentParams.municipais);
        this.listarAcessos(this.currentParams.estaduais);
        this.listarAcessosEcac(this.currentParams.acessosEcac);

        this.dataService.currentUser.subscribe((data) => {
            this.currentUser = data;
            this.cdr.detectChanges();
        });

        this.restornaResponsaveis();

    }


    getLoggedUser() {
        this.userService.usuarioLogado().subscribe(user => this.user = user);
    }

    btnResetSearchProcuracoes() {

        this.currentSearch.procuracoes = '';
        this.currentParams.procuracoes = {
            pageIndex: 1,
            pageSize: 10,
            sort: [],
            filter: [],
        };

        this.formFiltrarProcuracoes.formGroup.reset();

        this.listarProcuracoes(this.currentParams.procuracoes, this.currentSearch.procuracoes);

    }

    btnResetSearchProcuradores() {

        this.currentSearch.procuradores = '';
        this.currentParams.procuradores = {
            pageIndex: 1,
            pageSize: 10,
            sort: [],
            filter: [],
        };

        this.formFiltrarProcuradores.formGroup.reset();

        this.listarProcuradores(this.currentParams.procuradores, this.currentSearch.procuradores);

    }

    modalFiltrarProcuracoes(visible) {

        this.formFiltrarProcuracoes.modalVisible = visible;
    }

    modalFiltrarProcuradores(visible) {

        this.formFiltrarProcuradores.modalVisible = visible;
    }

    confirmaFiltrarProcuracoes(): void {

        this.currentParams.procuracoes.pageIndex = 1;
        this.listarProcuracoes(this.currentParams.procuracoes, this.currentSearch.procuracoes);

        this.modalFiltrarProcuracoes(false);

    }

    confirmaFiltrarProcuradores(): void {

        this.currentParams.procuradores.pageindex = 1;
        this.modalFiltrarProcuradores(false);
        this.listarProcuradores(this.currentParams.procuradores, this.currentSearch.procuradores);
    }

    confirmaBuscarProcuracoes(): void {

        this.currentParams.procuracoes.pageIndex = 1;
        this.listarProcuracoes(this.currentParams.procuracoes, this.currentSearch.procuracoes);
    }

    retornaDadosCadastrais(empresaId: string): void {
        this.loading.dadosCadastrais = true;
        this.service.retornaDadosCadastrais(empresaId).subscribe((response: any) => {
            this.dadosCadastrais = response;
            this.loading.dadosCadastrais = false;
        });
    }

    retornaDadosDataPrev(empresaId: string): void {
        this.service.retornaDadosDataPrev(empresaId).subscribe((retorno: any) => {
            this.dadosDataPrev = retorno;
        });
    }

    ngAfterViewInit() {
        this.target = StickyHeaderDirective.target;
        this.cdr.detectChanges();
    }

    modal(form: FormStack, visible: boolean, dados = null, fonte = ''): void {

        if (!visible) {
            this.zerarForms();
            this.formAcessosEstaduais.formGroup.reset();
            this.formAcessosMunicipais.formGroup.reset();
            this.formAcessosPrevidenciarios.formGroup.reset();
        }

        if (form === this.formAdicionarCertificado) {
            this.modalCredencialDeAcesso.showModal(visible);
        } else {
            form.modalVisible = visible;
        }

        if (fonte === 'estadual') {
            this.estadualSelecionado = dados;
        } else if (fonte === 'municipal') {
            this.municipalSelecionado = dados;
        } else if (fonte === 'dataPrev') {
            this.dataPrevSelecionado = dados;
        }
    }

    modalAcesso(visible: boolean, dados = null): void {

        if (!this.editando.acessos) {
            this.formAcessosEstaduais.formGroup.get('uf').enable();
            this.formAcessosEstaduais.formGroup.get('portal').enable();
        }

        this.formAcessosEstaduais.modalVisible = visible;

        if (!visible) {
            this.editando.acessos = null;
            this.formAcessosEstaduais = {
                modalVisible: false,
                formGroup: this.fb.group({
                    uf: [null, Validators.required],
                    portal: [null, Validators.required],
                })
            };
            this.camposFormDataPrev = [];
            this.cdr.detectChanges();
            this.formAcessosEstaduais.formGroup.reset();
        }

        this.credencialInvalida = null;

        this.estadualSelecionado = dados;

    }

    modalAcessoEcac(visible: boolean, cadastrando = false): void {
        if (cadastrando) {
            this.statusToUpdate = 1;
        }

        this.formAcessoEcac.modalVisible = visible;

        if (!visible) {
            this.formAcessoEcac.formGroup.reset();
            this.formAcessoEcac.formGroup.get('statusAcessoEcac').setValue(1);
        } else {
            this.formAcessoEcac.formGroup.get('ids').setValue(this.data.id);
        }
    }

    modalAcessoMunicipal(visible: boolean, dados = null): void {

        if (!visible) {
            this.editando.acessosMunicipais = null;
        }

        this.formAcessosMunicipais.modalVisible = visible;

        if (!visible) {
            this.formAcessosMunicipais = {
                modalVisible: false,
                formGroup: this.fb.group({
                    uf: [null, Validators.required],
                    portal: [null, Validators.required],
                })
            };
            this.camposFormDataPrevMunicipais = [];
            this.cdr.detectChanges();
        }

        this.municipalSelecionado = dados;

    }

    modalAcessoPrevidenciario(visible: boolean, dados = null): void {

        if (!visible) {
            this.editando.acessosPrevidenciarios = null;
        }

        this.formAcessosPrevidenciarios.modalVisible = visible;

        if (!visible) {
            this.formAcessosPrevidenciarios = {
                modalVisible: false,
                formGroup: this.fb.group({
                    portal: [null, Validators.required],
                })
            };
            this.camposFormDataPrevPrevidencirios = [];
            this.cdr.detectChanges();
        }

        this.dataPrevSelecionado = dados;

    }

    alterarCertificadoA1(item: any): void {

        item.arquivoUpdate = this.arquivoUpdate;

        this.modalCredencialDeAcesso.editandoCert = true;
        this.modalCredencialDeAcesso.item = item;
        this.modalCredencialDeAcesso.showModal(true);
    }

    alterarProcuracoesECAC(item: any): void {
        this.zerarForms();
        this.editando.procuracoes = item.id;

        this.formProcuracoesECAC.formGroup.get('pessoa').setValue(item.pessoa);
        this.formProcuracoesECAC.formGroup.get('documento').setValue(item.documento);
        this.formProcuracoesECAC.formGroup.get('nome').setValue(item.nome);


        if (item.dataInicio) {
            const dataInicio = new Date(item.dataInicio);
            dataInicio.setDate(new Date(item.dataInicio).getDate() + 1);
            this.formProcuracoesECAC.formGroup.get('dataInicio').setValue(dataInicio);
        }
        if (item.dataFim) {
            const dataFim = new Date(item.dataFim);
            dataFim.setDate(new Date(item.dataFim).getDate() + 1);
            this.formProcuracoesECAC.formGroup.get('dataFim').setValue(dataFim);
        }

        this.formProcuracoesECAC.formGroup.get('cadastrada').setValue(item.cadastrada ? '1' : '0');

        this.modal(this.formProcuracoesECAC, true);
    }

    alterarProcuradoresECAC(item: any): void {
        this.zerarForms();
        this.editando.procuradores = item.id;
        this.formProcuradoresECAC.formGroup.get('pessoa').setValue(item.pessoa);
        this.formProcuradoresECAC.formGroup.get('documento').setValue(item.documento);
        this.formProcuradoresECAC.formGroup.get('nome').setValue(item.nome);
        this.formProcuradoresECAC.formGroup.get('cadastrada').setValue(item.cadastrada ? '1' : '0');

        if (item.dataInicio) {
            const dataInicio = new Date(item.dataInicio);
            dataInicio.setDate(new Date(item.dataInicio).getDate() + 1);
            this.formProcuradoresECAC.formGroup.get('dataInicio').setValue(dataInicio);
        }
        if (item.dataFim) {
            const dataFim = new Date(item.dataFim);
            dataFim.setDate(new Date(item.dataFim).getDate() + 1);
            this.formProcuradoresECAC.formGroup.get('dataFim').setValue(dataFim);
        }

        this.modal(this.formProcuradoresECAC, true);
    }

    async alterarAcessosEstaduais(item: any, credencialInvalida?: any) {

        this.editando.acessos = item.id;
        this.credencialInvalida = credencialInvalida;

        this.loading.formAcessos = true;

        this.modal(this.formAcessosEstaduais, true, item, 'estadual');

        await this.filtrarPortais(item.uf).then(async () => {

            await this.filtrarCampos(item.portal);
            this.formAcessosEstaduais.formGroup.get('uf').setValue(item.uf);
            this.formAcessosEstaduais.formGroup.get('portal').setValue(item.portal);
            this.formAcessosEstaduais.formGroup.get('uf').disable();
            this.formAcessosEstaduais.formGroup.get('portal').disable();


            setTimeout(() => {
                if (item.acesso) {
                    const dados = JSON.parse(item.acesso);
                    for (const [key, value] of Object.entries(dados)) {
                        this.formAcessosEstaduais.formGroup.get(key)?.setValue(value ? value.toString().trim() : '');
                    }
                    this.loading.formAcessos = false;
                }
            }, 500);
        });

    }

    async alterarAcessosMunicipais(item: any) {

        this.loading.formAcessosMunicipais = true;
        this.filtrarCamposMunicipal(item.portal);
        this.modal(this.formAcessosMunicipais, true, item, 'municipal');

        this.listMunicipios(item.uf).then(() => {
            this.editando.acessosMunicipais = item.id;
            this.formAcessosMunicipais.formGroup.get('uf').setValue(item.uf);
            this.formAcessosMunicipais.formGroup.get('portal').setValue(item.portal);
            this.formAcessosMunicipais.formGroup.get('uf').disable();
            this.formAcessosMunicipais.formGroup.get('portal').disable();

            setTimeout(() => {
                const dados = JSON.parse(item.acesso);
                for (const [key, value] of Object.entries(dados)) {
                    this.formAcessosMunicipais.formGroup.get(key)?.setValue(value ? value.toString().trim() : '');
                }
                this.loading.formAcessosMunicipais = false;
            }, 2000);
        });

    }

    async alterarAcessosPrevidenciarios(item: any) {
        this.editando.acessosPrevidenciarios = item.id;

        this.loading.formAcessosPrevidenciarios = true;
        this.formAcessosPrevidenciarios.formGroup.get('portal').setValue(item.portal);
        this.filtrarCamposPrevidenciario(item);

        this.modal(this.formAcessosPrevidenciarios, true, item, 'dataPrev');

    }

    listarCertificados(params: NzTableQueryParams, search: string = null): void {

        this.currentParams.certificados = params;
        this.currentSearch.certificados = search;

        if (params?.filter) {
            params.filter.push({key: 'empresa_id', value: this.data.id});
            params.pageSize = 10;
        } else {
            params = this.currentParams;
            params.sort = [];
            params.pageIndex = 1;
            params.filter = [];
        }

        if (params?.filter.length > 0) {

            this.loading.certificados = true;
            this.abstractService.listToTable(params, '', this.service.urlCertificados).subscribe((response) => {

                this.listaCretificados = [];
                this.listaCretificados = response?.data || response;

                if (response?.extra?.usaCertificadoMatriz) {
                    this.usaCertificadoMatriz = response.extra.usaCertificadoMatriz;
                }

                if (response?.extra?.usaCertificadoMatrizProcuracao) {
                    this.usaCertificadoMatrizProcuracao = response.extra.usaCertificadoMatrizProcuracao;
                }

                if (response?.extra?.padrao) {
                    this.certificadoPadrao = response.extra.padrao;
                }

                this.listaCretificados.forEach(value => {
                    switch (value.tipo) {
                        case 'cnpj': {
                            value.tipo_descricao = 'eCNPJ';
                            break;
                        }
                        case 'nfe': {
                            value.tipo_descricao = 'eNFE';
                            break;
                        }
                        case 'cpf': {
                            value.tipo_descricao = 'eCPF';
                            break;
                        }
                    }
                });

                this.paginations.certificados = new Pagination(
                    response?.per_page || 10,
                    response?.current_page || 1,
                    response?.last_page || 1,
                    response?.total || 10);

                // this.refreshCheckedStatus();

                this.loading.certificados = false;
            }, error => {
                this.toastService.error(error.error.message);
                this.loading.certificados = false;
            });

        }

    }

    listarProcuracoes(params: NzTableQueryParams, search: string = null): void {


        this.loading.procuracoes = true;

        if (params.filter) {
            params.filter = [];
            params.filter.push({key: 'empresa_id', value: this.data.id});
            params.pageSize = 10;

            const filtros = this.formFiltrarProcuracoes.formGroup.value;

            this.qtdFiltrosAtivosProcuracoes = 0;
            for (const [chave, valor] of Object.entries(filtros)) {
                if (valor) {
                    params.filter.push({key: chave, value: valor});
                    this.qtdFiltrosAtivosProcuracoes++;
                }
            }
        }

        this.currentParams.procuracoes = params;
        this.currentSearch.procuracoes = search;

        this.abstractService.listToTable(params, search, this.service.urlProcuracoes).subscribe((response) => {

            this.loading.procuracoes = false;

            this.listaProcuracoes = [];
            this.listaProcuracoes = response?.data || response;

            this.paginations.procuracoes = new Pagination(
                response?.per_page || 10,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 50);

        }, (response) => {
            this.loading.procuracoes = false;
        });


    }

    listarProcuradores(params: NzTableQueryParams, search: string = null): void {

        if (params.filter) {
            params.filter = [];
            params.filter.push({key: 'empresa_id', value: this.data.id});
            params.pageSize = 10;

            const filtros = this.formFiltrarProcuradores.formGroup.value;

            this.qtdFiltrosAtivosProcuradores = 0;
            for (const [chave, valor] of Object.entries(filtros)) {
                if (valor) {
                    params.filter.push({key: chave, value: valor});
                    this.qtdFiltrosAtivosProcuradores++;
                }
            }
        }

        this.currentParams.procuradores = params;
        this.currentSearch.procuradores = search;

        this.loading.procuradores = true;
        this.abstractService.listToTable(params, search, this.service.urlProcuradores).subscribe((response) => {

            this.loading.procuradores = false;

            this.listaProcuradores = [];
            this.listaProcuradores = response?.data || response;

            this.paginations.procuradores = new Pagination(
                response?.per_page || 50,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 50);

            // this.refreshCheckedStatus();


        });

    }

    listarAcessos(params: NzTableQueryParams, search: string = null): void {

        this.currentParams.acessos = params;
        this.currentSearch.acessos = search;


        if (params?.filter) {
            params.filter.push({key: 'empresa_id', value: this.data.id});
        } else {
            params = this.currentParams;
            params.sort = [];
            params.pageIndex = 1;
            params.filter = [];
            params.filter.push({key: 'empresa_id', value: this.data.id});
        }
        params.pageSize = 10;

        this.loading.acessos = true;
        this.abstractService.listToTable(params, search, this.service.urlAcessos).subscribe((response) => {

            this.listaAcessos = [];
            this.listaAcessos = response?.data || response;

            this.paginations.acessosEstaduais = new Pagination(
                response?.per_page || 50,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 50);

            // this.refreshCheckedStatus();

            this.loading.acessos = false;
        });

    }

    listarAcessosMunicipais(params: NzTableQueryParams, search: string = null): void {
        this.loading.acessosMunicipais = true;

        this.currentParams.acessosMunicipais = params;
        this.currentSearch.acessosMunicipais = search;

        if (params?.filter) {
            params.filter.push({key: 'empresa_id', value: this.data.id});
        } else {
            params = this.currentParams;
            params.sort = [];
            params.pageIndex = 1;
            params.filter = [];
            params.filter.push({key: 'empresa_id', value: this.data.id});
        }

        params.pageSize = 10;

        this.abstractService.listToTable(params, search, this.service.urlAcessosMunicipais).subscribe((response) => {
            this.loading.acessosMunicipais = false;
            // Descomentar quando tiver endpoint
            this.listaAcessosMunicipais = [];
            this.listaAcessosMunicipais = response?.data || response;

            this.paginations.acessosMunicipais = new Pagination(
                response?.per_page || 50,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 50);

        });


    }

    listarAcessosEcac(params: NzTableQueryParams, search: string = null): void {
        this.loading.acessosEcac = true;

        this.currentParams.acessosEcac = params;
        this.currentSearch.acessosEcac = search;

        if (params?.filter) {
            params.filter = [];
            // params.filter.push({key: 'empresa_id', value: this.data.id});
        } else {
            params = this.currentParams;
            params.sort = [];
            params.pageIndex = 1;
            params.filter = [];
        }

        params.pageSize = 10;

        this.abstractService.listToTable(params, search, this.service.urlAcessosEcac + `/${this.data.id}`).subscribe((response) => {
            this.loading.acessosEcac = false;
            this.listaAcessosEcac = [];
            this.listaAcessosEcac = response?.data || response;

            this.paginations.acessosEcac = new Pagination(
                response?.per_page || 50,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 50);
        });

    }

    listarAcessosPrevidenciarios(params: NzTableQueryParams, search: string = null): void {
        params.filter = [];
        this.loading.acessosPrevidenciarios = true;

        this.currentParams.acessosPrevidenciarios = params;
        this.currentSearch.acessosPrevidenciarios = search;

        if (params?.filter) {
            params.filter.push({key: 'empresa_id', value: this.data.id});
        } else {
            params = this.currentParams;
            params.sort = [];
            params.pageIndex = 1;
        }

        params.pageSize = 10;

        this.abstractService.listToTable(params, search, this.service.urlAcessosPrevidenciarios).subscribe((response) => {
            this.loading.acessosPrevidenciarios = false;
            // Descomentar quando tiver endpoint
            this.listaAcessosPrevidenciarios = [];
            this.listaAcessosPrevidenciarios = response?.data || response;

            this.paginations.acessosPrevidenciarios = new Pagination(
                response?.per_page || 50,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 50);

        }, e => {
            this.loading.acessosPrevidenciarios = false;
            this.toastService.error('Erro ao listar acessos previdenciários: ' + e.error.message);
        });


    }

    cadastrar(form: FormStack): void {
        this.zerarForms();

        if (form === this.formAdicionarCertificado) {
            this.modalCredencialDeAcesso.showModal(true);
        } else {
            form.modalVisible = true;
        }

        setTimeout(() => {
            this.cdr.detectChanges();
        }, 500);

    }

    zerarForms(): void {

        this.editando.procuracoes = null;
        this.editando.procuradores = null;
        this.editando.acessos = null;
        this.editando.acessosMunicipais = null;
        this.formProcuracoesECAC.formGroup.reset();
        this.formProcuradoresECAC.formGroup.reset();
        this.formAcessosEstaduais.formGroup.reset();
        this.formAcessosMunicipais.formGroup.reset();
        this.formDataPrev.formGroup.reset();
        this.editando.certificados = null;
        this.fileListCertificado = [];

        // DataPrev
        this.identificacaoVisible = false;
        this.senhaVisible = false;
        this.cdr.detectChanges();


    }

    insertProcuracoes(): void {

        this.validaFormProcuracoes();

        const dados = this.formProcuracoesECAC.formGroup.value;

        const empresaId = this.data.id;

        if (!this.erro) {

            this.loading.insertProcuracao = true;

            this.service.insertProcuracoes(empresaId, dados).subscribe((response: any) => {
                    this.listarProcuracoes(this.currentParams.procuracoes, this.currentSearch.procuracoes);

                    this.loading.insertProcuracao = false;
                    this.toastrService.success('Procuração E-CAC cadastrada com sucesso!');
                    this.formProcuracoesECAC.modalVisible = false;
                },
                (response) => {
                    this.toastrService.error(response.error.message);
                });
            this.erro = false;
            this.mensagem = '';
        } else {
            this.toastrService.error(this.mensagem);
            this.erro = false;
            this.loading.insertProcuracao = false;
        }
    }

    updateProcuracoes(): void {

        this.validaFormProcuracoes();

        const dados = this.formProcuracoesECAC.formGroup.value;

        dados.empresa_id = this.data.id;

        if (!this.erro) {

            this.service.updateProcuracoes(this.editando.procuracoes, dados).subscribe((response: any) => {
                    this.listarProcuracoes(this.currentParams.procuracoes, this.currentSearch.procuracoes);

                    this.toastrService.success('Procuração E-CAC alterada com sucesso!');
                    this.formProcuracoesECAC.modalVisible = false;
                },
                (response) => {
                    response.error.erros.forEach((erro) => {
                        this.toastrService.error(erro);
                    });
                });
            this.erro = false;
            this.mensagem = '';
        } else {
            this.toastrService.error(this.mensagem);
            this.erro = false;
        }

    }

    updateProcuradores(): void {

        this.validaFormProcuradores();

        const dados = this.formProcuradoresECAC.formGroup.value;

        dados.empresa_id = this.data.id;

        if (!this.erro) {

            this.service.updateProcuradores(this.editando.procuradores, dados).subscribe((response: any) => {
                    this.listarProcuradores(this.currentParams.procuradores, this.currentSearch.procuradores);

                    this.toastrService.success('Procurador E-CAC alterado com sucesso!');
                    this.formProcuradoresECAC.modalVisible = false;
                },
                (response) => {
                    response.error.erros.forEach((erro) => {
                        this.toastrService.error(erro);
                    });
                    // this.toastrService.error(response.error.message);
                });
            this.erro = false;
            this.mensagem = '';
        } else {
            this.toastrService.error(this.mensagem);
            this.erro = false;
        }

    }

    validaFormAcesso() {
        if (this.formAcessosEstaduais.formGroup.get('modo').value === 'Procurador' &&
            this.formAcessosEstaduais.formGroup.get('portal').value === 'DEC SP' &&
            !Helpers.isValidCnpj(this.formAcessosEstaduais.formGroup.get('cnpj').value)) {

            this.erro = true;
            this.mensagem = 'CNPJ inválido!';
        } else {
            this.erro = false;
            this.mensagem = null;
        }
    }

    updateAcessos(): void {

        this.validaFormAcesso();

        const dados = this.formAcessosEstaduais.formGroup.value;

        dados.empresa_id = this.data.id;

        if (!this.erro && this.formAcessosEstaduais.formGroup.valid) {

            this.loading.updateAcesso = true;

            this.service.updateAcessos(this.editando.acessos, dados).subscribe((response: any) => {
                    this.listarAcessos(this.currentParams.acessos, this.currentSearch.acessos);

                    this.toastrService.success('Acesso Estadual alterado com sucesso!');
                    this.modalAcesso(false);
                    this.editando.acessos = null;
                    this.loading.updateAcesso = false;
                },
                (response) => {
                    this.loading.updateAcesso = false;
                    response.error.erros.forEach((erro) => {
                        this.toastrService.error(erro);
                    });
                    // this.toastrService.error(response.error.message);
                });
            this.erro = false;
            this.mensagem = '';
        } else {
            this.toastrService.error(this.mensagem || 'Preencha todos os campos obrigatórios');
            this.erro = false;

            Object.values(this.formAcessosEstaduais.formGroup.controls).forEach(control => {
                if (control.invalid) {
                    control.markAsDirty();
                    control.updateValueAndValidity({onlySelf: true});
                }
            });
        }

    }

    updateAcessosMunicipais(): void {

        const dados = this.formAcessosMunicipais.formGroup.value;

        dados.empresa_id = this.data.id;

        if (!this.erro && this.formAcessosMunicipais.formGroup.valid) {

            this.loading.updateAcessosMunicipal = true;

            this.service.updateAcessosMunicipais(this.editando.acessosMunicipais, dados).subscribe((response: any) => {
                    this.listarAcessosMunicipais(this.currentParams.acessos, this.currentSearch.acessos);

                    this.toastrService.success('Acesso Municipal alterado com sucesso!');
                    this.modalAcessoMunicipal(false);
                    this.editando.acessosMunicipais = null;
                    this.loading.updateAcessosMunicipal = false;
                },
                (response) => {
                    this.loading.updateAcessosMunicipal = false;
                    response.error.erros.forEach((erro) => {
                        this.toastrService.error(erro);
                    });
                });
            this.erro = false;
            this.mensagem = '';
        } else {
            this.toastrService.error('Preencha todos os campos obrigatórios');
            this.erro = false;

            Object.values(this.formAcessosMunicipais.formGroup.controls).forEach(control => {
                if (control.invalid) {
                    control.markAsDirty();
                    control.updateValueAndValidity({onlySelf: true});
                }
            });
        }

    }

    updateAcessosPrevidenciarios(): void {

        const dados = this.formAcessosPrevidenciarios.formGroup.value;

        dados.empresa_id = this.data.id;

        if (!this.erro && this.formAcessosPrevidenciarios.formGroup.valid) {

            this.loading.updateAcessosPrevidenciarios = true;

            this.service.updateAcessosPrevidenciario(this.editando.acessosPrevidenciarios, dados).subscribe((response: any) => {
                    this.listarAcessosPrevidenciarios(this.currentParams.acessosPrevidenciarios, this.currentSearch.acessosPrevidenciarios);

                    this.toastrService.success('Acesso Previdenciário alterado com sucesso!');
                    this.modalAcessoPrevidenciario(false);
                    this.editando.acessosPrevidenciarios = null;
                    this.loading.updateAcessosPrevidenciarios = false;
                },
                (e) => {
                    this.loading.updateAcessosPrevidenciarios = false;
                    this.toastrService.error(e.error);
                    // response.error.erros.forEach((erro) => {
                    //     this.toastrService.error(erro);
                    // });
                });
            this.erro = false;
            this.mensagem = '';
        } else {
            this.toastrService.error('Preencha todos os campos obrigatórios');
            this.erro = false;

            Object.values(this.formAcessosPrevidenciarios.formGroup.controls).forEach(control => {
                if (control.invalid) {
                    control.markAsDirty();
                    control.updateValueAndValidity({onlySelf: true});
                }
            });
        }

    }

    updateDataPrev(): void {

        this.validaFormDataPrev();

        const dados = this.formDataPrev.formGroup.value;

        dados.empresa_id = this.data.id;

        if (!this.erro) {

            this.service.updateDataPrev(dados).subscribe((response: any) => {
                    this.retornaDadosDataPrev(this.data.id);
                    this.toastrService.success('Acessos DataPrev alterado com sucesso!');
                    this.formDataPrev.modalVisible = false;
                    this.senhaVisible = false;
                    this.identificacaoVisible = false;
                },
                (response) => {
                    response.error.erros.forEach((erro) => {
                        this.toastrService.error(erro);
                    });
                    // this.toastrService.error(response.error.message);
                });
            this.erro = false;
            this.mensagem = '';
        } else {
            this.toastrService.error(this.mensagem);
            this.erro = false;
        }

    }

    insertProcuradores(): void {

        this.validaFormProcuradores();

        const dados = this.formProcuradoresECAC.formGroup.value;

        const empresaId = this.data.id;

        if (!this.erro) {

            this.loading.insertProcurador = true;

            this.service.insertProcuradores(empresaId, dados).subscribe((response: any) => {
                    this.listarProcuradores(this.currentParams.procuradores, this.currentSearch.procuradores);

                    this.toastrService.success('Procurador E-CAC cadastrado com sucesso!');
                    this.formProcuradoresECAC.modalVisible = false;
                    this.loading.insertProcurador = false;
                },
                (response) => {
                    this.loading.insertProcurador = false;
                    if (response.error.erros) {
                        response.error.erros.forEach(erro => {
                            this.toastrService.error(erro);
                        });
                    } else {
                        this.toastrService.error(response.error.message);
                    }


                });
            this.erro = false;
            this.mensagem = '';
        } else {
            this.toastrService.error(this.mensagem);
            this.erro = false;
        }

    }

    insertAcessos(): void {

        this.validaFormAcesso();

        this.loading.insertEstadual = true;

        const dados = this.formAcessosEstaduais.formGroup.value;

        const empresaId = this.data.id;

        if (!this.erro && this.formAcessosEstaduais.formGroup.valid) {

            this.service.insertAcessos(empresaId, dados).subscribe((response: any) => {
                    this.listarAcessos(this.currentParams.acessos, this.currentSearch.acessos);

                    this.toastrService.success('Acesso Estadual cadastrado com sucesso!');

                    if (this.dadosCadastrais.estado && this.dadosCadastrais.estado !== dados.uf) {
                        this.toastService.warning('UF diferente do cadastro da empresa (' + this.dadosCadastrais.estado + ')');
                    }

                    this.formAcessosEstaduais = {
                        modalVisible: false,
                        formGroup: this.fb.group({
                            uf: [null, null],
                            portal: [null, null],
                        })
                    };

                    this.loading.insertEstadual = false;

                },
                (response) => {
                    this.loading.insertEstadual = false;
                    this.toastrService.error(response.error.message);
                });
            this.erro = false;
            this.mensagem = '';
        } else {
            this.loading.insertEstadual = false;
            this.toastrService.error(this.mensagem || 'Preencha todos os campos obrigatórios');
            this.erro = false;

            Object.values(this.formAcessosEstaduais.formGroup.controls).forEach(control => {
                if (control.invalid) {
                    control.markAsDirty();
                    control.updateValueAndValidity({onlySelf: true});
                }
            });
        }

    }

    insertAcessosMunicipais(): void {

        // this.validaFormAcessos();

        const dados = this.formAcessosMunicipais.formGroup.value;

        const empresaId = this.data.id;

        if (!this.erro && this.formAcessosMunicipais.formGroup.valid) {

            this.service.insertAcessosMunicipais(empresaId, dados).subscribe((response: any) => {
                    this.listarAcessosMunicipais(this.currentParams.acessos, this.currentSearch.acessos);

                    this.toastrService.success('Acesso Municipal cadastrado com sucesso!');

                    if (this.dadosCadastrais.estado && this.dadosCadastrais.estado !== dados.uf) {
                        this.toastService.warning('UF diferente do cadastro da empresa (' + this.dadosCadastrais.estado + ')');
                    }

                    this.formAcessosMunicipais = {
                        modalVisible: false,
                        formGroup: this.fb.group({
                            uf: [null, Validators.required],
                            portal: [null, Validators.required],
                        })
                    };

                },
                (response) => {
                    this.toastrService.error(response.message);
                });
            this.erro = false;
            this.mensagem = '';
        } else {
            this.toastrService.error('Preencha todos os campos obrigatórios');
            this.erro = false;

            Object.values(this.formAcessosMunicipais.formGroup.controls).forEach(control => {
                if (control.invalid) {
                    control.markAsDirty();
                    control.updateValueAndValidity({onlySelf: true});
                }
            });
        }

    }

    insertAcessosPrevidnciario(): void {

        const dados = this.formAcessosPrevidenciarios.formGroup.value;

        const empresaId = this.data.id;

        if (this.formAcessosPrevidenciarios.formGroup.invalid) {
            this.toastrService.error('Preencha todos os campos obrigatórios');
            this.erro = false;

            Object.values(this.formAcessosPrevidenciarios.formGroup.controls).forEach(control => {
                if (control.invalid) {
                    control.markAsDirty();
                    control.updateValueAndValidity({onlySelf: true});
                }
            });

        } else if (!this.erro) {
            this.loading.acessosPrevidenciarios = true;

            this.service.insertAcessosPrevidenciario(empresaId, dados).subscribe((response: any) => {
                    this.listarAcessosPrevidenciarios(this.currentParams.acessos, this.currentSearch.acessos);

                    this.toastrService.success('Acesso Previdenciário cadastrado com sucesso!');

                    this.formAcessosPrevidenciarios = {
                        modalVisible: false,
                        formGroup: this.fb.group({
                            portal: [null, null],
                        })
                    };

                    this.loading.acessosPrevidenciarios = false;

                    this.formAcessosPrevidenciarios.modalVisible = false;

                },
                (e) => {
                    this.loading.acessosPrevidenciarios = false;
                    this.toastrService.error(e.error.error);
                });
            this.erro = false;
            this.mensagem = '';
        } else {
            this.toastrService.error(this.mensagem);
            this.erro = false;
        }

    }

    validaFormProcuracoes(): void {
        if (!this.formProcuracoesECAC.formGroup.value.pessoa) {
            this.erro = true;
            this.mensagem = 'Campo Pessoa obrigatório';
        }
        if (!this.formProcuracoesECAC.formGroup.value.documento) {
            this.erro = true;
            this.mensagem = 'Campo Documento obrigatório';
        }
        if (!this.formProcuracoesECAC.formGroup.value.nome) {
            this.erro = true;
            this.mensagem = 'Campo Nome obrigatório';
        }
        if (!this.formProcuracoesECAC.formGroup.value.dataInicio) {
            this.erro = true;
            this.mensagem = 'Campo Início obrigatório';
        }

        if (this.formProcuracoesECAC.formGroup.value.dataInicio) {
            // tslint:disable-next-line:max-line-length
            this.formProcuracoesECAC.formGroup.value.dataInicio = Helpers.formatDateDb(this.formProcuracoesECAC.formGroup.value.dataInicio);
        }
        if (this.formProcuracoesECAC.formGroup.value.dataFim) {
            // tslint:disable-next-line:max-line-length
            this.formProcuracoesECAC.formGroup.value.dataFim = Helpers.formatDateDb(this.formProcuracoesECAC.formGroup.value.dataFim);
        }


    }

    validaFormDataPrev(): void {

        if (!this.formDataPrev.formGroup.value.identificador) {
            this.erro = true;
            this.mensagem = 'Campo Identificador obrigatório';
        }
        if (!this.formDataPrev.formGroup.value.senha) {
            this.erro = true;
            this.mensagem = 'Campo Senha obrigatório';
        }


    }

    validaFormProcuradores(): void {
        if (!this.formProcuradoresECAC.formGroup.value.pessoa) {
            this.erro = true;
            this.mensagem = 'Campo Pessoa obrigatório';
        }
        if (!this.formProcuradoresECAC.formGroup.value.documento) {
            this.erro = true;
            this.mensagem = 'Campo Documento obrigatório';
        }
        if (!this.formProcuradoresECAC.formGroup.value.nome) {
            this.erro = true;
            this.mensagem = 'Campo Nome obrigatório';
        }
        if (!this.formProcuradoresECAC.formGroup.value.dataInicio) {
            this.erro = true;
            this.mensagem = 'Campo Início obrigatório';
        }

        if (this.formProcuradoresECAC.formGroup.value.dataInicio) {
            // tslint:disable-next-line:max-line-length
            this.formProcuradoresECAC.formGroup.value.dataInicio = Helpers.formatDateDb(this.formProcuradoresECAC.formGroup.value.dataInicio);
        }
        if (this.formProcuradoresECAC.formGroup.value.dataFim) {
            // tslint:disable-next-line:max-line-length
            this.formProcuradoresECAC.formGroup.value.dataFim = Helpers.formatDateDb(this.formProcuradoresECAC.formGroup.value.dataFim);
        }


    }

    maskPessoa(form: FormStack): string {
        if (form.formGroup.value.pessoa === 'F') {
            return '000.000.000-00';
        } else {
            return '00.000.000/0000-00';
        }
    }

    showConfirm(tipo: string, item: any): void {

        let titulo = '';
        switch (tipo) {
            case 'procuracoes': {
                titulo = 'Deseja remover a Procuração E-CAC?';
                break;
            }
            case 'procuradores': {
                titulo = 'Deseja remover o Procurador E-CAC?';
                break;
            }
            case 'certificados': {
                titulo = item.existeCertificadoResponsavel ? 'Esse certificado também é utilizado por um responsável, ao excluir do cadastro da Empresa, também será excluído do cadastro de Responsável. Deseja continuar?' :
                    'Deseja remover o Certificado A1?';
                break;
            }
            case 'acessos': {
                titulo = 'Deseja remover o Acesso Estadual?';
                break;
            }
            case 'acessosMunicipais': {
                titulo = 'Deseja remover o Acesso Municipal?';
                break;
            }
            case 'acessosPrevidenciarios': {
                titulo = 'Deseja remover o Acesso Previdenciário?';
                break;
            }
            case 'dataprev': {
                titulo = 'Deseja remover o Acesso DataPrev?';
                break;
            }
        }


        this.modalService.confirm({
            nzTitle: titulo,
            nzOkText: 'Remover',
            nzCancelText: 'Cancelar',
            nzOnOk: () =>
                this.remover(tipo, item)
        });
    }

    showConfirmBuscarProcuracao(): void {
        this.modalService.confirm({
            nzTitle: 'Buscar procurações no ECAC?',
            nzOkText: 'Buscar',
            nzCancelText: 'Cancelar',
            nzOnOk: () =>
                this.buscarProcuracoesEcac()
        });
    }

    remover(tipo: string, item: any): void {
        switch (tipo) {
            case 'procuracoes': {
                this.service.removeProcuracoes(item.id).subscribe(
                    (response) => {
                        this.toastrService.success('Procuração E-CAC removida com sucesso!');
                        this.listarProcuracoes(this.currentParams.procuracoes, this.currentSearch.procuracoes);
                    },
                    (response) => {
                        this.toastrService.error(response.error.message);
                    }
                );
                break;
            }
            case 'procuradores': {
                this.service.removeProcuradores(item.id).subscribe(
                    (response) => {
                        this.toastrService.success('Procurador E-CAC removido com sucesso!');
                        this.listarProcuradores(this.currentParams.procuradores, this.currentSearch.procuradores);
                    },
                    (response) => {
                        this.toastrService.error(response.error.message);
                    }
                );
                break;
            }
            case 'certificados': {
                this.service.removeCertificados(item.id).subscribe(
                    (response) => {
                        this.toastrService.success('Certificado A1 removido com sucesso!');
                        this.listarCertificados(this.currentParams.certificados, this.currentSearch.certificados);
                    },
                    (response) => {
                        this.toastrService.error(response.error.message);
                    }
                );
                break;
            }
            case 'acessos': {
                this.service.removeAcessos(item.id).subscribe(
                    (response) => {
                        this.toastrService.success('Acesso Estadual removido com sucesso!');
                        this.listarAcessos(this.currentParams.acessos, this.currentSearch.acessos);
                    },
                    (response) => {
                        this.toastrService.error(response.error.message);
                    }
                );
                break;
            }
            case 'acessosMunicipais': {
                this.service.removeAcessoMunicipal(item.id).subscribe(
                    (response) => {
                        this.toastrService.success('Acesso Municipal removido com sucesso!');
                        this.listarAcessosMunicipais(this.currentParams.acessos, this.currentSearch.acessos);
                    },
                    (response) => {
                        this.toastrService.error(response.error.message);
                    }
                );
                break;
            }
            case 'acessosPrevidenciarios': {
                this.service.removeAcessoMunicipal(item.id).subscribe(
                    (response) => {
                        this.toastrService.success('Acesso Previdenciário removido com sucesso!');
                        this.listarAcessosPrevidenciarios(this.currentParams.acessos, this.currentSearch.acessos);
                    },
                    (response) => {
                        this.toastrService.error(response.error.message);
                    }
                );
                break;
            }
            case 'dataprev': {
                this.service.removeAcessosDataPrev(item.id).subscribe(
                    (response) => {
                        this.toastrService.success('Acesso DataPrev removido com sucesso!');
                        this.retornaDadosDataPrev(this.data.id);
                    },
                    (response) => {
                        this.toastrService.error(response.error.message);
                    }
                );
                break;
            }
        }
    }

    buscarProcuracoesEcac(): void {

        this.loading.buscarProcuracoes = true;
        this.service.buscarProcuracoes(this.dadosCadastrais.id).subscribe(
            (response) => {
                this.loading.buscarProcuracoes = false;
                this.toastrService.success(response.message);

            },
            (response) => {
                this.loading.buscarProcuracoes = false;
                this.toastrService.error(response.error.message);
            }
        );
    }

    handleChange({file, fileList}: NzUploadChangeParam): void {
        // const status = file.status;
        // if (status !== 'uploading') {
        // }
        // if (status === 'done') {
        //     this.msg.success(`${file.name} file uploaded successfully.`);
        // } else if (status === 'error') {
        //     this.msg.error(`${file.name} file upload failed.`);
        // }
    }

    async filtrarPortais(uf: string) {

        this.formAcessosEstaduais.formGroup.get('portal').setValue(null);

        this.formAcessosEstaduais.formGroup.removeControl('senha');
        this.camposFormDataPrev = [];

        if (uf) {
            const retorno = await this.service.retornaPortaisUF(uf).toPromise();
            retorno.forEach((value) => {
                value.label = value.descricao;
                value.key = value.id;
            });
            this.comboObrigacoes = retorno;
        }
    }

    // Portais Municipais
    async listMunicipios(uf: string) {

        this.formAcessosMunicipais.formGroup.get('portal').setValue(null);

        this.camposFormDataPrevMunicipais = [];

        if (uf) {
            this.service.retornaMunicipios(uf).subscribe((retorno: any) => {
                retorno.forEach((value) => {
                    value.label = value.descricao;
                    value.key = value.id;
                });
                this.comboMunicipios = retorno;
            });
        }
    }

    async filtrarCampos(portal: string) {

        this.formAcessosEstaduais.formGroup.removeControl('cnpj');
        this.camposFormDataPrev = [];

        if (portal) {

            const retorno = await this.service.retornaCamposAcessosEstaduais(portal).toPromise();

            this.camposFormDataPrev = retorno;
            retorno.forEach((value, index) => {
                // tslint:disable-next-line:max-line-length
                this.formAcessosEstaduais.formGroup.addControl(value.label, new UntypedFormControl(
                    value.valores[0] && value.valores[0], Validators.required));
            });

            if (this.formAcessosEstaduais.formGroup.get('modo')) {
                this.valueChangesSubscription = this.formAcessosEstaduais.formGroup.get('modo').valueChanges.subscribe(novoValor => {
                    this.removeCampoFormAcesssos();
                });
            }
        }
    }

    removeCampoFormAcesssos() {
        if (this.formAcessosEstaduais.formGroup.get('modo').value !== 'Procurador' &&
            this.formAcessosEstaduais.formGroup.get('portal').value === 'DEC SP' &&
            this.formAcessosEstaduais.formGroup.get('cnpj')
        ) {
            this.formAcessosEstaduais.formGroup.removeControl('cnpj');
            this.camposFormDataPrev.pop();

        } else if (!this.formAcessosEstaduais.formGroup.get('cnpj') &&
            this.formAcessosEstaduais.formGroup.get('portal').value === 'DEC SP') {
            this.formAcessosEstaduais.formGroup.addControl('cnpj', new UntypedFormControl(
                '', Validators.required));
            this.camposFormDataPrev.push({label: 'cnpj', valores: ''});
        }
    }

    async filtrarCamposMunicipal(portal: string) {

        if (portal) {
            this.service.retornaCamposAcessosMunicipais(portal).subscribe((retorno: any) => {
                this.camposFormDataPrevMunicipais = retorno;
                retorno.forEach((value, index) => {
                    // tslint:disable-next-line:max-line-length
                    this.formAcessosMunicipais.formGroup.addControl(value.label, new UntypedFormControl(value.valores[0] && value.valores[0], Validators.required));
                });
            });
        }
    }

    async filtrarCamposPrevidenciario(item: any) {

        if (item.portal) {
            this.service.retornaCamposAcessosPrevidenciarios(item.portal).subscribe((retorno: any) => {
                this.camposFormDataPrevPrevidencirios = retorno;
                retorno.forEach((value, index) => {

                    this.formAcessosPrevidenciarios.formGroup.addControl(
                        value.label, new UntypedFormControl(value.valores[0] && value.valores[0], Validators.required));
                });

                const dados = JSON.parse(item.acesso);
                for (const [key, value] of Object.entries(dados)) {
                    this.formAcessosPrevidenciarios.formGroup.get(key)?.setValue(value ? value.toString().trim() : '');
                }
                this.loading.formAcessosPrevidenciarios = false;
            });
        }
    }

    retornaUltimaConsultaProcuracoes(empresaId: string): void {
        this.service.retornaUltimaConsultaProcuracoes(empresaId).subscribe((response: any) => {
            this.ultimaConsulta = response;
        });
    }

    confirmInativar(): void {

        this.modalService.confirm({
            nzTitle: 'Tem certeza que deseja inativar o acesso integrado E-CAC para essa empresa?',
            nzOkText: 'Inativar',
            nzCancelText: 'Cancelar',
            nzOnOk: () => this.ativarInativarAcessoIntegradoECAC(true)
        });
    }

    ativarInativarAcessoIntegradoECAC(inativar = false, novo = false) {

        this.loadingAcessoEcac = true;

        if (inativar) {
            this.statusToUpdate = 0;
            this.formAcessoEcac.formGroup.get('statusAcessoEcac').setValue(this.statusToUpdate);
            this.formAcessoEcac.formGroup.get('procurador').setValue(this.acessoEcacSelecionado.responsavel_id);
        }

        const dados = this.formAcessoEcac.formGroup.value;

        if (novo) {
            dados.ids = [this.formAcessoEcac.formGroup.get('ids').value];
            this.formAcessoEcac.formGroup.get('statusAcessoEcac').setValue(1);
            dados.statusAcessoEcac = this.formAcessoEcac.formGroup.get('statusAcessoEcac').value;
        }

        if (this.formAcessoEcac.formGroup.valid) {
            this.service.ativarInativarAcessoIntegradoECAC(dados).subscribe((response: any) => {
                    this.toastrService.success('Ação realizada com sucesso!');
                    this.listarAcessosEcac(this.currentParams, this.currentSearch);
                    this.loadingAcessoEcac = false;
                    this.formAcessoEcac.formGroup.get('procurador').setValue(null);
                    this.modalAcessoIntegradoEcac(null, false);
                    this.modalProcurador(0, false);
                    this.modalAcessoEcac(false);
                },
                (response) => {
                    this.toastrService.error(response.error.message);
                    this.loadingAcessoEcac = false;
                });
        } else {
            this.loadingAcessoEcac = false;

            Object.values(this.formAcessoEcac.formGroup.controls).forEach(control => {

                if (control.invalid) {
                    control.markAsDirty();
                    control.updateValueAndValidity({onlySelf: true});
                }
            });
        }
    }

    modalAcessoIntegradoEcac(acesso = null, visible: boolean) {
        if (acesso) {
            this.acessoEcacSelecionado = acesso;
            this.termosAceitos = acesso.ativo === 1;
            this.checkDisabled = acesso.ativo === 1;
        }

        this.termosVisible = visible;

        this.formAcessoEcac.formGroup.get('ids').setValue([this.acessoEcacSelecionado.empresa_id]);

        if (visible) {
            this.termosAceitos = false;
            this.termosCompletosVisible = false;
            this.formAcessoEcac.formGroup.get('procurador').setValidators(Validators.required);
        }
    }

    modalProcurador(ativar, visible: boolean) {

        this.statusToUpdate = ativar;
        this.selecionarProcurador = visible;

        if (!visible) {
            this.termosAceitos = false;
            this.formAcessoEcac.formGroup.reset();
        } else {
            this.modalAcessoIntegradoEcac(this.acessoEcacSelecionado, !visible);
        }
    }

    verTermosCompletos() {
        this.termosCompletosVisible = !this.termosCompletosVisible;
    }

    restornaResponsaveis() {

        this.service.retornarResponsaveis().subscribe((response: any) => {
                this.responsaveis = response.data;
            },
            (response) => {
                this.toastrService.error(response.error.message);
            });
    }

    listByTableProcuradores(params: NzTableQueryParams) {
        let buscar = false;

        if (params.pageIndex === this.paginations.procuradores.current_page) {
            params.sort.forEach(s => {
                if (s.value) {
                    buscar = true;
                }
            });
        }

        if ((buscar || params.pageIndex === 1 && this.paginations.procuradores.current_page > 1)
            || params.pageIndex > 1
            && params.pageIndex !== this.paginations.procuradores.current_page) {
            this.listarProcuradores(params);
            this.paginations.procuradores.current_page = params.pageIndex;
        }

    }

    listByTablePrevidenciarios(params: NzTableQueryParams) {
        let buscar = false;

        if (params.pageIndex === this.paginations.acessosPrevidenciarios.current_page) {
            params.sort.forEach(s => {
                if (s.value) {
                    buscar = true;
                }
            });
        }

        if ((buscar || params.pageIndex === 1 && this.paginations.acessosPrevidenciarios.current_page > 1)
            || params.pageIndex > 1
            && params.pageIndex !== this.paginations.acessosPrevidenciarios.current_page) {
            this.listarAcessosPrevidenciarios(params);
            this.paginations.acessosPrevidenciarios.current_page = params.pageIndex;
        }

    }

    listByTableProcuracoes(params: NzTableQueryParams) {
        let buscar = false;

        if (params.pageIndex === this.paginations.procuracoes.current_page) {
            params.sort.forEach(s => {
                if (s.value) {
                    buscar = true;
                }
            });
        }

        if ((buscar || params.pageIndex === 1 && this.paginations.procuracoes.current_page > 1)
            || params.pageIndex > 1
            && params.pageIndex !== this.paginations.procuracoes.current_page) {
            this.listarProcuracoes(params);
            this.paginations.procuracoes.current_page = params.pageIndex;
        }

    }

    listByTableMunicipais(params: NzTableQueryParams) {
        let buscar = false;

        if (params.pageIndex === this.paginations.municipais.current_page) {
            params.sort.forEach(s => {
                if (s.value) {
                    buscar = true;
                }
            });
        }

        if ((buscar || params.pageIndex === 1 && this.paginations.municipais.current_page > 1)
            || params.pageIndex > 1
            && params.pageIndex !== this.paginations.municipais.current_page) {
            this.listarAcessosMunicipais(params);
            this.paginations.municipais.current_page = params.pageIndex;
        }

    }

    listByTableEstaduais(params: NzTableQueryParams) {
        let buscar = false;

        if (params.pageIndex === this.paginations.estaduais.current_page) {
            params.sort.forEach(s => {
                if (s.value) {
                    buscar = true;
                }
            });
        }

        if ((buscar || params.pageIndex === 1 && this.paginations.estaduais.current_page > 1)
            || params.pageIndex > 1
            && params.pageIndex !== this.paginations.estaduais.current_page) {
            this.listarAcessos(params);
            this.paginations.estaduais.current_page = params.pageIndex;
        }

    }

    listByTableCertificados(params: NzTableQueryParams) {
        let buscar = false;

        if (params.pageIndex === this.paginations.certificados.current_page) {
            params.sort.forEach(s => {
                if (s.value) {
                    buscar = true;
                }
            });
        }

        if ((buscar || params.pageIndex === 1 && this.paginations.certificados.current_page > 1)
            || params.pageIndex > 1
            && params.pageIndex !== this.paginations.certificados.current_page) {
            this.listarCertificados(params);
            this.paginations.certificados.current_page = params.pageIndex;
        }

    }

    listByTableAcessosEcac(params: NzTableQueryParams) {
        let buscar = false;

        if (params.pageIndex === this.paginations.acessosEcac.current_page) {
            params.sort.forEach(s => {
                if (s.value) {
                    buscar = true;
                }
            });
        }

        if ((buscar || params.pageIndex === 1 && this.paginations.acessosEcac.current_page > 1)
            || params.pageIndex > 1
            && params.pageIndex !== this.paginations.acessosEcac.current_page) {
            this.listarAcessosEcac(params);
            this.paginations.acessosEcac.current_page = params.pageIndex;
        }

    }

    ngOnDestroy(): void {
        if (this.valueChangesSubscription) {
            this.valueChangesSubscription.unsubscribe();
        }
    }
}
