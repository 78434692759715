import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {ExportarTabelaService} from '@components/exportar-tabela/exportar-tabela.service';
import * as fileSaver from 'file-saver-es';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-exportar-tabela',
    templateUrl: './exportar-tabela.component.html',
    styleUrls: ['./exportar-tabela.component.scss']
})
export class ExportarTabelaComponent {

    @Input() data: {
        url: string,
        filtros: any,
        name: string,
        tiposAceitos: { key: string, value: string }[]
    };

    @Input() dadosCompletos = false;
    @Input() verbo: 'GET' | 'POST' = 'GET';

    formExportar = this.fb.group({
        tipo: [null, Validators.required],
    });

    visible = false;
    loadingExportar = false;

    autoTips: Record<string, Record<string, string>> = {
        default: {
            required: 'Campo obrigatório',
        }
    };

    constructor(private fb: FormBuilder,
                private toastrService: ToastrService,
                private service: ExportarTabelaService) {
    }

    showModal(visible: boolean) {
        this.visible = visible;
    }

    confirmaExportar(): void {

        if (this.formExportar.valid) {

            const tipo = this.formExportar.value.tipo;

            this.loadingExportar = true;

            const dados: any = {};
            Object.entries(this.data.filtros).forEach(([key, value]) => {
                if (value) {
                    dados[key] = value;
                }
            });

            this.service.exportExcel(dados, tipo, this.data.url, this.dadosCompletos, this.verbo).subscribe((res) => {

                if (this.formExportar.value.tipo) {

                    const blob = new Blob([res], {type: 'text/json; charset=utf-8'});

                    fileSaver.saveAs(blob, this.data.name + '.' + this.formExportar.value.tipo);

                    this.visible = false;

                    this.loadingExportar = false;
                }

            }, err => {
                this.loadingExportar = false;
                this.toastrService.error(err.error.message);
            });

        } else {
            for (const key in this.formExportar.controls) {

                if (key) {

                    const campo = this.formExportar.get(key);

                    campo.markAsDirty();
                    campo.updateValueAndValidity();

                }
            }
        }

    }

}
