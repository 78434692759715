<nz-page-header class="site-page-header" nzTitle="Compliance" nzSubtitle="Grupos">
    <nz-page-header-extra>
        <nz-badge [nzCount]="qtdFiltrosAtivos"
                  [nzStyle]="{ backgroundColor: '#52c41a', 'margin-right' : '10px'}">
            <button (click)="showModalFiltrar(true)" nz-button nzType="primary"
                    nzGhost style="border-radius: 0.45em; margin-right: 10px">
                <i nz-icon nzType="search"></i>
                Filtros
            </button>
        </nz-badge>
    </nz-page-header-extra>
</nz-page-header>
<nz-content>
    <div class="arrows">
        <h1>Grupos</h1>
        <div class="select-ano">

            <a [ngClass]="loadingGrupos ? ['bt-arrow','bt-wait'] : ['bt-arrow']"
               *ngIf="!disableArrow else arrowLDisabled"
               (click)="!loadingGrupos && clickArrow('L')"
               nz-icon nzType="caret-left" nzTheme="outline">
            </a>

            <ng-template #arrowLDisabled>
                <a class="bt-arrow-disabled"
                   nz-icon nzType="caret-left" nzTheme="outline">
                </a>
            </ng-template>

            <div class="anos">
                <span class="font-gray">
                    {{ selectedYear }}
                </span>
                <span class="font-gray">
                    {{ previousYear }}
                </span>
            </div>

            <a [ngClass]="loadingGrupos ? ['bt-arrow','bt-wait'] : ['bt-arrow']"
               (click)="!loadingGrupos && clickArrow('R')"
               nz-icon nzType="caret-right" nzTheme="outline"></a>
        </div>
    </div>
    <nz-card [nzBodyStyle]="loadingGrupos ? {'padding': '15px', 'min-height': '330px'} : {'padding' : '2px'}"
             [nzLoading]="loadingGrupos">

        <nz-table #tableEmpresas
                  [nzData]="gruposBkp"
                  [nzFrontPagination]="false"
                  [nzShowPagination]="false"
                  [nzScroll]="{x: 'auto'}"
                  [ngStyle]="{'max' : '100%'}">
            <tbody>
            <tr *ngFor="let g of tableEmpresas.data">

                <td nzAlign="left">
                    <span>
                       <div class="grupo-info">
                           <div class="ant-avatar" style="{{'background-color:'+ g.backgroundColor}}">
                                <span>{{ g.iniciais }}</span>
                            </div>
                          <div class="font-gray">
                               <h2 nz-tooltip="Ver detalhes"
                                   (click)="openTab('/compliance/complianceTributosGruposDetalhes', '',{id: g.id, ano: anoSelecionado})">
                                   {{ g.descricao }}
                               </h2>
                               <span>{{ g.qtdUnidades === 1 ? '1 Unidade' : g.qtdUnidades + ' Unidades' }}</span>
                           </div>
                       </div>
                    </span>
                </td>
                <td nzAlign="center" *ngFor="let ano of g.anos">
                    <div style="margin: 0 15px">
                        <div class="font-gray flex-bt">
                            <span>Ausências</span>
                            <span>{{ ano.qtdAusencias }}</span>
                        </div>
                        <div class="font-gray flex-bt">
                            <span>Divergências</span>
                            <span>{{ ano.qtdDivergencias }}</span>
                        </div>
                        <div style="width: 100%">
                            <nz-progress [nzPercent]="ano.porcentagem"></nz-progress>
                        </div>
                    </div>

                </td>

            </tr>
            </tbody>
        </nz-table>

    </nz-card>
</nz-content>

<nz-modal [(nzVisible)]="formFiltrar.modalVisible"
          [nzTitle]="'Filtros'" [nzClosable]="true"
          (nzOnCancel)="showModalFiltrar(false)"
          [nzWidth]="648">
    <nz-row *nzModalContent>
        <nz-col [nzSpan]="24">
            <form nz-form [nzNoColon]="true" [formGroup]="formFiltrar.formGroup">
                <nz-form-item>
                    <nz-form-label [nzSpan]="5">Visibilidade:</nz-form-label>
                    <nz-form-control [nzSpan]="14"
                                     nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrar.formGroup.value.visibilidade_id">
                        <nz-select nzShowSearch nzAllowClear
                                   nzPlaceHolder="Selecione"
                                   formControlName="visibilidade_id">
                            <nz-option *ngFor="let opt of visibilidadeOptions"
                                       [nzLabel]="opt.label"
                                       [nzValue]="opt.value"
                            ></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
            </form>
        </nz-col>
    </nz-row>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="showModalFiltrar(false)">Cancelar</button>
        <button [nzLoading]="loadingBt" nz-button nzType="primary" (click)="confirmaFiltrar()">Filtrar</button>
    </div>
</nz-modal>
