<nz-page-header class="site-page-header" nzTitle="Domicílio" nzSubtitle="Eletrônico">
    <nz-page-header-extra *ngIf="showContent">
        <nz-button-group>
            <nz-badge [nzCount]="qtdFiltrosCabecalho" style="margin-right: 15px;"
                      [nzStyle]="{ backgroundColor: '#52c41a' }">
                <button nz-button nzType="primary"
                        (click)="abrirModal(formFiltrosCabecalho)">
                    <i nz-icon nzType="search"></i>
                    Filtros
                </button>
            </nz-badge>
        </nz-button-group>
        <nz-button-group>
            <button nz-button nzType="primary" nzGhost
                    style="border-radius: 0.45rem;"
                    (click)="atualizarTela();"
            >
                <i class="fas fa-redo" style="margin-right: 5px;"></i>
                Atualizar
            </button>
        </nz-button-group>
    </nz-page-header-extra>
</nz-page-header>

<nz-content>
    <div *ngIf="showAlert">
        <nz-row nzGutter="24">
            <nz-alert nzType="warning" nzShowIcon [nzMessage]="checkedMessage"
                      style="margin: 15px !important; width: 100%">
                <ng-template #checkedMessage>
                    <div nz-row nzAlign="middle" nzGutter="24">
                        <div nz-col nzSpan="24">
                            <strong class="text-primary"></strong>
                            O modulo deste produto está desabilitado. Contate o suporte!
                        </div>
                    </div>
                </ng-template>
            </nz-alert>
        </nz-row>
    </div>
    <div *ngIf="showContent">
        <nz-row nzGutter="24" style="margin-bottom: 15px;">
            <nz-col [nzSpan]="statusThree ? 23 : 16" style="max-height: 375px;overflow-y: scroll;">
                <nz-card nzTitle="Caixas de Mensagens" class="dec" [nzExtra]="navBarDomicilios"
                         [ngClass]="[statusOne ? 'card-fullscreen' : '', !loadingCardDecs ? 'ant-card-table' : '']"
                         [nzLoading]="loadingCardDecs">
                    <div style="padding-top: 10px; padding-left: 5px;">
                        <div nz-card-grid *ngFor="let dec of arrayDecs" (click)="onClickDec(dec.id);" [ngStyle]="{width: (!statusOne && !statusThree ? '32%' :
                          (!statusOne && statusThree ? '23.8%' :
                          (statusOne && !statusThree ? '23.8%' :
                          (statusOne && statusThree ? '23.8%' : '32%')))),
                         textAlign: 'center',
                         height: '30%',
                         margin: '0 0.5% 1% 0.5%',
                         float: 'left',
                         'z-index': dec.zIndex,
                         'padding': '15px',
                         'padding-bottom': '0',
                         '-webkit-box-shadow' : '0px 0px 15px 1px rgba(0,0,0,0.1)',
                         '-moz-box-shadow' : '0px 0px 15px 1px rgba(0,0,0,0.1)',
                         'box-shadow' : '0px 0px 15px 1px rgba(0,0,0,0.1)'}"
                             class="ant-card-hoverable">
                            <nz-content>
                                <nz-row style="margin-bottom: 15px;">
                                    <nz-col class="d-flex align-items-center">
                                        <div style="height: 35px;max-width: 35px; margin-right: 15px;"
                                             class="d-flex justify-content-center align-items-center">
                                            <nz-avatar nzText="{{dec.sigla}}"
                                                       style="color: #ffffff;"
                                                       [ngStyle]="{'background': dec.bgColor}"
                                            ></nz-avatar>
                                        </div>
                                        <div class="d-flex align-items-center" style="height: 44px;">
                                            <span style="font-weight: bold;">{{ dec?.descricao }}</span>
                                        </div>
                                    </nz-col>
                                </nz-row>
                                <nz-row>
                                    <nz-col>
                                        <p style="color: rgba(0,0,0,0.6); margin-bottom: 5px;">{{ dec.qtdUnidades }}
                                            {{ dec.qtdUnidades === 1 ? 'Unidade' : 'Unidades' }}
                                        </p>
                                    </nz-col>
                                </nz-row>
                                <nz-row>
                                    <nz-col>
                                        <p style="color: rgba(0,0,0,0.6); margin-bottom: 5px;">{{ dec.qtdeTotal }}
                                            Mensagens</p>
                                    </nz-col>
                                </nz-row>
                                <nz-row>
                                    <nz-col class="d-flex justify-content-between" style="width: 100%;">
                                        <p style="color: rgba(0,0,0,0.6); margin-bottom: 5px; font-weight: bold;">
                                            {{ dec.id === 'DECMG' && dec.qtdeNaoLida ? 'Existem mensagens não lidas' : dec.qtdeNaoLida + ' Não lidas' }}
                                        </p>
                                    </nz-col>
                                </nz-row>
                                <nz-row>
                                    <nz-col class="d-flex justify-content-between align-items-center"
                                            style="width: 100%;">
                                        <p style="color: rgba(0,0,0,0.6); margin-bottom: 5px;">Última Consulta</p>
                                        <p style="color: rgba(0,0,0,0.5); margin-bottom: 5px;">{{ dec?.ultimaConsulta | date :  'dd/MM/yyyy HH:mm' }}


                                            <span nz-icon nzType="info-circle" nzTheme="outline" style="color: #e1ba3d"
                                                  *ngIf="dec.processamentoAtrasado"
                                                  [nz-tooltip]="extraTooltipoAtrasado"></span>
                                            <ng-template #extraTooltipoAtrasado>
                                                Existem consultas desde o
                                                dia {{ dec.processamentoAtrasado | date :  'dd/MM' }}
                                                que não foram realizadas.
                                            </ng-template>


                                    </nz-col>
                                </nz-row>
                            </nz-content>
                        </div>
                    </div>
                </nz-card>
                <ng-template #navBarDomicilios>
                    <div class="nav-item d-flex">
                        <button nz-button [nzType]="'default'" (click)="clickEvent('one')"
                                style="padding-left: 8px; padding-right: 8px;">
                            <i class="fas" [ngClass]="!statusOne ? 'fa-expand' : 'fa-compress'"></i>
                        </button>
                    </div>
                </ng-template>
            </nz-col>
            <nz-col [nzSpan]="statusThree ? 1 : 8">
                <div class="d-flex" style="width: 100%;">
                    <button nz-button [nzType]="'primary'" (click)="toggleCard()"
                            style="padding-left: 8px; padding-right: 8px; margin-top: 17px; z-index: 999;">
                        <i class="fas"
                           [ngClass]="!statusThree ? 'fa-chevron-circle-right' : 'fa-chevron-circle-left'"></i>
                    </button>
                    <nz-card style="width: 100%;"
                             [ngClass]="statusThree ? 'ant-card-table' : ''"
                             [nzLoading]="loadingContadores">
                        <div style="position: relative;">
                        <span style="
                            position: absolute;
                              transform-origin: 0 0;
                              transform: rotate(270deg);
                              font-size: 15px;
                              padding: 2px 4px;
                              background-color: #fff;
                              left: -5px;
                              font-weight: bold;
                              opacity: 0.4;
                              top: 100px;"
                              *ngIf="statusThree">Contadores</span>
                            <ng-container *ngIf="!statusThree">
                                <div class="d-flex justify-content-between align-items-center">
                                    <p style="font-weight: bold;">Total de Domicílios</p>
                                    <p>{{ objectInfos?.totalDomicilios }}</p>
                                </div>

                                <div class="d-flex justify-content-between align-items-center">
                                    <p style="font-weight: bold;">Ativos</p>
                                    <p>{{ objectInfos?.ativos }}</p>
                                </div>
                                <div class="d-flex justify-content-between align-items-center">
                                    <p style="font-weight: bold;">Credencial e/ou Cadastro pendente</p>
                                    <p>{{ objectInfos?.pendentes }}</p>
                                </div>

                                <div class="d-flex justify-content-between align-items-center">
                                    <p style="font-weight: bold;">Nunca visualizados</p>
                                    <p>{{ objectInfos?.naoVisualizados }}</p>
                                </div>
                            </ng-container>
                        </div>
                    </nz-card>
                </div>
            </nz-col>
        </nz-row>
        <nz-row nzGutter="24">
            <nz-col nzSpan="24">
                <nz-card [ngClass]="[!loading ? 'ant-card-table' : '', statusTwo ? 'card-fullscreen' : '']"
                         [nzLoading]="loading">
                    <nz-card-tab>
                        <nz-tabset
                                [nzSize]="'large'"
                                [nzTabBarExtraContent]="navBar"
                                [(nzSelectedIndex)]="tabNumber"
                                (nzSelectChange)="changeTabs($event)">
                            <nz-tab nzTitle="Empresas"></nz-tab>
                            <nz-tab nzTitle="Mensagens"></nz-tab>
                        </nz-tabset>
                    </nz-card-tab>
                    <nz-alert nzType="info" nzShowIcon [nzMessage]="checkedMessage" style="margin: 15px !important;"
                              *ngIf="checkedItems.size > 0">
                        <ng-template #checkedMessage>
                            <div nz-row nzAlign="middle" nzGutter="24">
                                <div nz-col nzSpan="24">
                                    <strong class="text-primary"></strong>
                                    {{ checkedItems.size }} Registros Selecionados
                                </div>
                            </div>
                        </ng-template>
                    </nz-alert>
                    <nz-table #tableOne
                              [nzData]="items"
                              [nzFrontPagination]="false"
                              [nzTotal]="paginationEmpresas?.total"
                              [nzPageSize]="paginationEmpresas?.per_page"
                              [nzPageIndex]="paginationEmpresas?.current_page"
                              (nzQueryParams)="listByTableEmpresas($event)"
                              [nzShowTotal]="rangeTemplateOne"
                              class="fonte-doze table-small"
                              *ngIf="tabNumber === 0">
                        <thead>
                        <tr>
                            <th style="text-align: center;">
                                <label nz-checkbox [(ngModel)]="checked"
                                       (nzCheckedChange)="onAllChecked($event)"></label>
                            </th>
                            <th nzColumnKey="domicilio" [nzSortFn]="true">Domicílio</th>
                            <th nzColumnKey="unidade" [nzSortFn]="true" style="text-align: center;">Unidade</th>
                            <th nzColumnKey="mensagens" [nzSortFn]="true" style="text-align: center;">Mensagens
                            </th>
                            <th nzColumnKey="ultimaCaptura" [nzSortFn]="true" style="text-align: center;">Última
                                Consulta
                            </th>
                            <th nzColumnKey="proximaConsulta" [nzSortFn]="true" style="text-align: center;">Próxima
                                Consulta
                            </th>
                            <th></th>
                            <th *ngIf="currentUser?.origem === 'console'"></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let tasks of tableOne.data">
                            <td nzLeft (nzCheckedChange)="onItemChecked(tasks.id, $event)"
                                [nzChecked]="checkedItems.has(tasks.id)"
                                [nzDisabled]="tasks.disabled">
                            </td>
                            <td nzLeft>{{ tasks?.domicilio }}

                                <span *ngIf="tasks?.emManutencao" style="margin-left: 10px; color: #faad14"
                                      [nz-tooltip]="'Em manutenção'" nz-icon nzType="exclamation-circle"
                                      nzTheme="outline"></span>
                            </td>
                            <td nzLeft>{{ modoDemo(tasks?.unidade, 'empresaCodigoNome') }}
                                <br>{{ modoDemo(tasks?.cnpj | cpfCnpj, 'cnpj') }}
                                <span *ngIf="tasks?.integracaoContadorPendente"
                                      style="margin-left: 10px; color: #ff4d4f; cursor:pointer;"
                                      [nz-tooltip]="'Sem acesso integrado E-CAC'" nz-icon nzType="exclamation-circle"
                                      (click)="openTab('/cadastros/editar-credenciais-acesso/', tasks.empresa_id, {id: tasks.empresa_id, cnpj: tasks.cnpj})"
                                      nzTheme="outline"></span>
                            </td>
                            <td nzAlign="center">
                                <nz-badge [nzCount]="tasks?.mensagensNaoLidas" style="cursor: pointer;"
                                          nzTooltipPlacement="rightBottom" nz-button
                                          [nz-tooltip]="tasks?.mensagensNaoLidas > 1 ?
                                          tasks?.mensagensNaoLidas + ' mensagens não lidas' :
                                          tasks?.mensagensNaoLidas + ' mensagem não lida'">
                                    <a nz-button nzType="link"
                                       (click)="filtrarMensagens(tasks.domicilioEletronico_id, tasks.empresa_id, tasks.id)">{{ tasks?.mensagens }}</a>
                                </nz-badge>
                            </td>
                            <td nzAlign="center">
                                <div class="d-flex justify-content-center align-items-center">
                                    {{ tasks?.ultimaConsulta | date: 'dd/MM/yyyy HH:mm:ss' }}
                                </div>
                            </td>
                            <td nzAlign="center">
                                <div style="min-width: 200px">
                                    <div *ngIf="tasks.exibirPreRequisito else ComPreRequisitos">
                                        <span *ngIf="currentUser.origem === 'console'">{{ tasks?.proximoProcessamento | date: 'dd/MM/yyyy' }}</span>
                                        <span
                                                style="margin-left: 10px; color: #ff4d4f; font-size: 1.3em"
                                                [nz-tooltip]="tasks.preRequisitos ? tasks.preRequisitos : 'Existem pendências de configurações'"
                                                nz-icon
                                                nzType="exclamation-circle"
                                                nzTheme="outline"></span>
                                    </div>

                                    <ng-template #ComPreRequisitos>
                                        <div style="display: inline-block">
                                            {{ tasks?.proximoProcessamento | date: 'dd/MM/yyyy' }}
                                            <i *ngIf="tasks.processamentoStatus === 1"
                                               style="color: #1890ff; cursor: pointer; margin-left: 3px; font-size: 1.2em"
                                               class="fa-solid fa-spinner"
                                               [nz-tooltip]="tasks.processamentoDataInicio ?
                                   'Processando. Iniciado em ' + (tasks.processamentoDataInicio | date: 'dd/MM/yyyy HH:mm') : 'Processando'"></i>
                                        </div>
                                    </ng-template>


                                    <i nz-tooltip="Processamento na fila"
                                       *ngIf="tasks.processamentoNaFila && !tasks.processamentoDataInicio"
                                       style="color: #faad14; font-size: 1.2em; margin-left: 5px"
                                       nz-icon nzType="info-circle" nzTheme="outline"></i>

                                </div>
                            </td>
                            <td nzAlign="center">
                                <nz-tag *ngIf="!tasks.disabled && tasks?.mensagensNaoLidas > 0"
                                        [nzColor]="solicitarLeituraDisabled ? 'rgb(161,161,161)' : (!tasks.leituraSolicitacaoUsuarioNome || tasks.leituraSolicitacaoUsuarioNome === null)  && (!tasks.fazerLeitura || tasks.fazerLeitura === 0 && tasks?.mensagensNaoLidas > 0) ? 'rgb(102, 110, 232)' : 'rgb(40, 208, 148)'"
                                        [ngStyle]="!tasks.fazerLeitura || tasks.fazerLeitura === 0 && tasks?.mensagensNaoLidas > 0 ? {cursor : 'pointer'} : ''"
                                        (click)="solicitarLeituraDisabled ? '' : (!tasks.fazerLeitura || tasks.fazerLeitura === 0 && tasks?.mensagensNaoLidas > 0 ? processarMensagensEmpresaConfirm(tasks.id, tasks.cnpj) : '')">
                                    <span *ngIf="(!tasks.leituraSolicitacaoUsuarioNome || tasks.leituraSolicitacaoUsuarioNome === null) && (!tasks.fazerLeitura || tasks.fazerLeitura === 0)"
                                          [nz-tooltip]="solicitarLeituraDisabled ? 'Perfil sem permissão.' : ''">
                                        <i class="fa-solid fa-lock" style="margin-right: 5px;"
                                           *ngIf="solicitarLeituraDisabled"></i>
                                        Solicitar Leitura</span>
                                    <div *ngIf="tasks.leituraSolicitacaoUsuarioNome">
                                        <span nz-button nz-tooltip [nzTooltipTitle]="solicitacaoDetahes">
                                            Solicitado
                                        </span>
                                        <ng-template #solicitacaoDetahes>
                                            Em {{ tasks.leituraSolicitacaoData | date: 'dd/MM/yyyy HH:mm' }}
                                            <br>
                                            por {{ tasks.leituraSolicitacaoUsuarioNome }}
                                        </ng-template>
                                    </div>
                                </nz-tag>
                            </td>
                            <td nzAlign="center" style="white-space: nowrap;">
                                <a class="fa-solid fa-triangle-exclamation" style="font-size: 16px; color: #ffb330;"
                                   *ngIf="tasks.ultimoProcessamentoStatus === 4"
                                   nz-tooltip="Ação Requerida"
                                   [ngStyle]="tasks.error_message && {cursor : 'pointer', 'padding-rigth': '10px'}"
                                   (click)="modalLog(true, tasks)"></a>
                                <a nz-button nzType="link" *ngIf="currentUser?.origem === 'console' && tasks.ultimoProcessamentoStatus === 3"
                                   (click)="modalLog(true, tasks)"
                                   nz-tooltip="Visualizar Erro (user-console)">
                                    <i nz-icon nzType="warning" nzTheme="outline" style="color: red;"></i>
                                </a>
                            </td>
                        </tr>
                        </tbody>
                        <ng-template #rangeTemplateOne let-range="range" let-total>
                            {{ range[0] }}-{{ range[1] }} de {{ total }} Registros
                        </ng-template>
                    </nz-table>
                    <nz-table #tableTwo
                              [nzData]="itemsMensagens"
                              [nzFrontPagination]="false"
                              [nzShowPagination]="false"
                              [nzTotal]="pagination?.total"
                              [nzPageSize]="pagination?.per_page"
                              [nzPageIndex]="pagination?.current_page"
                              (nzQueryParams)="queryTable($event)"
                              class="fonte-doze table-small"
                              *ngIf="tabNumber === 1">
                        <thead>
                        <tr>
                            <th style="text-align: center;">
                                <label nz-checkbox [(ngModel)]="checked"
                                       (nzCheckedChange)="onAllCheckedMsg($event)"></label>
                            </th>
                            <th nzColumnKey="domicilio" [nzSortFn]="true">Domicílio</th>
                            <th nzColumnKey="unidade" [nzSortFn]="true">Unidade</th>
                            <th nzColumnKey="ie" [nzSortFn]="true" style="text-align: center;">IE</th>
                            <th nzColumnKey="im" [nzSortFn]="true" style="text-align: center;">IM</th>
                            <th nzColumnKey="data" [nzSortFn]="true" style="text-align: center;">Data</th>
                            <th nzColumnKey="assunto" [nzSortFn]="true" style="text-align: center;">Assunto</th>
                            <th nzColumnKey="categoria" [nzSortFn]="true" style="text-align: center;">Categoria</th>
                            <th nzColumnKey="cienciaData" [nzSortFn]="true" style="text-align: center;">Ciência</th>
                            <th style="text-align: center;">Conteúdo</th>
                            <th nzColumnKey="capturaData" style="text-align: center;">Captura Data</th>
                            <th></th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let tasks of itemsMensagens" [ngStyle]="
                        (((!tasks.anexo && !tasks.txtMsg) || (tasks.anexo == 1 && !tasks.cienciaData)) && !tasks.modoLeitura) ? {'font-weight': 'bold'} : {}"
                        >
                            <td nzLeft (nzCheckedChange)="onItemChecked(tasks.id, $event)"
                                [nzChecked]="checkedItems.has(tasks.id)"
                                [nzDisabled]="tasks.disabled">
                            </td>
                            <td nzLeft>{{ tasks?.domicilio }}</td>
                            <td nzLeft>
                                {{ modoDemo(tasks?.unidade, 'empresaCodigoNome') }}
                                <br>{{ modoDemo(tasks?.cnpj | cpfCnpj, 'cnpj') }}
                            </td>
                            <td nzAlign="center">{{ tasks?.ie ? modoDemo(tasks?.ie, 'total') : '-' }}</td>
                            <td nzAlign="center">{{ tasks?.im ? modoDemo(tasks?.im, 'total') : '-' }}</td>
                            <td nzAlign="center">{{ tasks?.data | date: 'dd/MM/yyyy HH:mm' }}</td>
                            <td nzAlign="center">{{ tasks?.assunto ? tasks?.assunto : '-' }}</td>
                            <td nzAlign="center">{{ tasks?.categoria ? tasks?.categoria : '-' }}</td>
                            <td nzAlign="center">{{ tasks?.cienciaData ? (tasks?.cienciaData | date: 'dd/MM/yyyy HH:mm:ss') : '-' }}
                            </td>
                            <td nzAlign="center">
                                <div style="display: flex; margin: 0 auto; gap: 10px; justify-content: center; align-items: center; width: 60px;">
                                    <a nz-button nzType="link" *ngIf="tasks.txtMsg"
                                       style="padding: 0!important;"
                                       [ngStyle]="tasks.statusVisualizacao === 0 && !tasks.vistoEm && tasks.domicilioEletronicoLeitura_id ? {'color': '#e77303'} : {}"
                                       (click)="modalMensagem(true, tasks)">
                                        ver
                                    </a>
                                    <a *ngIf="tasks?.arquivoNome?.startsWith('anexo_')" nz-button nzType="link"
                                       style="padding: 0px!important; margin-right: -8px!important"
                                       (click)="downloadAanexo(tasks)"
                                       [nzLoading]="loadingModalAnexos[tasks.id]">
                                        <i *ngIf="!loadings.downloadAnexo[tasks?.id]" style="font-size: 1em;" nz-icon
                                           nzType="paper-clip"
                                           nzTheme="outline"></i>
                                        <i *ngIf="loadings.downloadAnexo[tasks?.id]" nz-icon nzType="loading"
                                           nzTheme="outline"></i>
                                    </a>
                                </div>
                            </td>
                            <!--                            <td nzAlign="center">{{tasks?.prazoTacito | date: 'dd/MM/yyyy'}}</td>-->
                            <td nzAlign="center">{{ tasks?.created_at ? (tasks?.created_at | date: 'dd/MM/yyyy HH:mm') : '-' }}</td>
                            <td nzAlign="center">
                                <nz-tag *ngIf="!tasks.disabled"
                                        [nzColor]="solicitarLeituraDisabled ? 'rgb(161,161,161)' : (!tasks.statusLeitura || tasks.statusLeitura === 0 ? 'rgb(102, 110, 232)' : 'rgb(40, 208, 148)')"
                                        [ngStyle]="!tasks.statusLeitura || tasks.statusLeitura === 0 ? {cursor : 'pointer'} : ''"
                                        (click)="solicitarLeituraDisabled ? '' : (!tasks.statusLeitura || tasks.statusLeitura === 0 ? processarMensagemConfirm(tasks.id) : '')">
                                    <span *ngIf="!tasks.statusLeitura || tasks.statusLeitura === 0"
                                          [nz-tooltip]="solicitarLeituraDisabled ? 'Perfil sem permissão.' : ''">
                                        <i class="fa-solid fa-lock" style="margin-right: 5px;"
                                           *ngIf="solicitarLeituraDisabled"></i>
                                        Solicitar Leitura</span>
                                    <div style="cursor: pointer" *ngIf="tasks.statusLeitura === 1">
                                    <span nz-button nz-tooltip [nzTooltipTitle]="solicitacaoDetahes">
                                        Solicitado
                                    </span>
                                        <ng-template #solicitacaoDetahes>
                                            Em {{ tasks.leituraSolicitacaoData | date: 'dd/MM/yyyy HH:mm' }}
                                            <br>
                                            por {{ tasks.leituraSolicitacaoUsuarioNome }}
                                        </ng-template>
                                    </div>
                                </nz-tag>
                            </td>
                            <td>
                                <a nz-button nzType="link" *ngIf="tasks.lerNovamente" [nz-tooltip]="'Ler novamente'"
                                   (click)="orLerNOvamente(tasks.id)"
                                >
                                    <span nz-icon nzType="reload" nzTheme="outline"></span>
                                </a>
                            </td>
                        </tr>
                        </tbody>

                    </nz-table>
                    <div class="rodape-table" *ngIf="tabNumber === 1">
                        <nz-button-group>
                            <button nz-button nzType="default" (click)="paginaAnterior()"
                                    [disabled]="currentParams.pageIndex == 1">
                                <span nz-icon nzType="left" nzTheme="outline" style="font-size: 14px"></span> Anterior
                            </button>
                            <span nz-typography class="page"><strong>{{ currentParams.pageIndex }}</strong></span>
                            <button nz-button nzType="default" (click)="paginaProxima()"
                                    [disabled]="disabledProximaPagina">
                                Próxima <span nz-icon nzType="right" nzTheme="outline" style="font-size: 14px"></span>
                            </button>
                        </nz-button-group>
                    </div>
                </nz-card>
            </nz-col>
        </nz-row>
    </div>
</nz-content>
<ng-template #navBar>
    <div class="d-flex align-items-center">
        <nz-badge [nzCount]="qtdFiltrosAtivos" *ngIf="tabNumber == 0" style="margin-right: 10px;"
                  [nzStyle]="{ backgroundColor: '#52c41a' }">
            <button nz-button nzType="primary" (click)="abrirModal(formFiltros)">
                <i nz-icon nzType="search"></i>
                Filtros
            </button>
        </nz-badge>
        <nz-badge [nzCount]="qtdFiltrosAtivosTwo" *ngIf="tabNumber == 1" style="margin-right: 10px;"
                  [nzStyle]="{ backgroundColor: '#52c41a' }">
            <button nz-button nzType="primary" (click)="abrirModal(formFiltrosMensagens)">
                <i nz-icon nzType="search"></i>
                Filtros
            </button>
        </nz-badge>
        <button [nzDropdownMenu]="menuAcoes" style="margin-right: 10px;" class="centralized-icon" nz-button nz-dropdown
                nzType="default">
            Opções
            <em nz-icon nzType="down"></em>
        </button>
        <nz-dropdown-menu #menuAcoes>


            <ul nz-menu>
                <li nz-submenu nzTitle="Manutenção Coletiva">
                    <ul>
                        <li nz-menu-item nzDisabled>Ações</li>
                        <li nz-menu-divider></li>
                        <li nz-menu-item *ngIf="tabNumber === 0"
                            (click)="checkedItems?.size > 0 && !solicitarLeituraDisabled?  processarMensagensEmpresasConfirm() : ''"
                            [nzDisabled]="checkedItems?.size === 0 || solicitarLeituraDisabled"
                            [nz-tooltip]="checkedItems?.size > 0 && solicitarLeituraDisabled ? 'Perfil sem permissão.' : ''">
                            <i class="fa-solid fa-lock" style="margin-right: 5px;"
                               *ngIf="checkedItems?.size > 0 && solicitarLeituraDisabled"></i>
                            Ler Mensagens
                        </li>
                        <li nz-menu-item *ngIf="tabNumber === 1"
                            (click)="checkedItems?.size > 0 && !solicitarLeituraDisabled ? processarMensagensConfirm() : ''"
                            [nzDisabled]="checkedItems?.size === 0 || solicitarLeituraDisabled"
                            [nz-tooltip]="checkedItems?.size > 0 && solicitarLeituraDisabled ? 'Perfil sem permissão.' : ''">
                            <i class="fa-solid fa-lock" style="margin-right: 5px;"
                               *ngIf="checkedItems?.size > 0 && solicitarLeituraDisabled"></i>
                            Ler Mensagens
                        </li>

                        <li nz-submenu nzTitle="Console" *ngIf="currentUser?.origem === 'console'">
                            <ul>
                                <li nz-menu-item *ngIf="tabNumber === 0"
                                    (click)="checkedItems?.size && confirmProcessarMensagensEmpresaUserConsole()"
                                    [nzDisabled]="checkedItems?.size === 0">

                                    Solicitar Leitura
                                </li>
                                <li nz-menu-item *ngIf="tabNumber === 1"
                                    (click)="checkedItems?.size && processarMensagemUserConsoleConfirm()"
                                    [nzDisabled]="checkedItems?.size === 0">
                                    Solicitar Leitura
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li nz-menu-item (click)="tabNumber === 0 ? modalExportar(true) : exportarMensagens();">Exportar
                    Planilha
                </li>
            </ul>
        </nz-dropdown-menu>
        <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" style="margin-right: 10px;">
            <input (keydown.enter)="queryTable(currentParams, currentSearch);" type="text" nz-input placeholder="Busca"
                   [(ngModel)]="currentSearch"/>
        </nz-input-group>
        <ng-template #suffixIconButton>
            <button nz-button nzType="primary" nzSearch
                    (click)="queryTable(currentParams, currentSearch);"><i
                    nz-icon nzType="search"></i></button>
        </ng-template>
        <button nz-button nzType="default" style="margin-right: 10px;" (click)="limparFiltros()">
            Limpar
        </button>
        <button nz-button [nzType]="'default'" (click)="clickEvent('two')"
                style="padding-left: 8px; padding-right: 8px;">
            <i class="fas" [ngClass]="!statusTwo ? 'fa-expand' : 'fa-compress'"></i>
        </button>
    </div>
</ng-template>
<nz-modal [(nzVisible)]="formFiltros.modalVisible"
          [nzTitle]="'Filtros avançados'" [nzClosable]="true"
          (nzOnCancel)="fechar(formFiltros)"
          [nzWidth]="1100">
    <nz-row *nzModalContent>
        <nz-col [nzSpan]="24">
            <form nz-form [nzNoColon]="false" [formGroup]="formFiltros.formGroup">
                <nz-form-item>
                    <nz-form-label [nzSpan]="3">Domicílio</nz-form-label>
                    <nz-form-control [nzSpan]="18" nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltros.formGroup.value.domicilio">
                        <nz-select nzShowSearch nzAllowClear
                                   nzPlaceHolder="Selecione"
                                   formControlName="domicilio"
                                   (ngModelChange)="changeSelectUnidades($event)">
                            <nz-option *ngFor="let opt of arraySelectDomicilios"
                                       [nzLabel]="opt.descricao"
                                       [nzValue]="opt.id"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="3">Unidade</nz-form-label>
                    <nz-form-control [nzSpan]="18" nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltros.formGroup.value.unidade">
                        <nz-select nzShowSearch
                                   nzAllowClear
                                   nzPlaceHolder="Selecione"
                                   formControlName="unidade"
                                   (ngModelChange)="changeSelectDomicilios($event)">
                            <nz-option *ngFor="let opt of arraySelectUnidades"
                                       [nzLabel]="modoDemo(opt.descricao, 'empresaCodigoNome') + ' (' + modoDemo(opt.cnpj | cpfCnpj, 'cnpj') + ')'"
                                       [nzValue]="opt.id"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="3">Última Consulta</nz-form-label>
                    <nz-form-control [nzSpan]="18" nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltros.formGroup.value.ultimaConsulta">
                        <nz-date-picker [nzFormat]="'dd/MM/yyyy'"
                                        formControlName="ultimaConsulta" style="width: 100%;"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSpan]="3">Status</nz-form-label>
                    <nz-form-control [nzSpan]="18" nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltros.formGroup.value.status">
                        <nz-select nzShowSearch
                                   nzPlaceHolder="Selecione"
                                   nzAllowClear
                                   formControlName="status">
                            <nz-option nzLabel="Ativos"
                                       nzValue="1"
                            ></nz-option>
                            <nz-option nzLabel="Inativos"
                                       nzValue="0"
                            ></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>


            </form>
        </nz-col>
    </nz-row>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="fechar(formFiltros)">Cancelar</button>
        <button nz-button nzType="primary" (click)="filtrar()">Filtrar</button>
    </div>
</nz-modal>
<nz-modal [(nzVisible)]="formFiltrosMensagens.modalVisible"
          [nzTitle]="'Filtros avançados'" [nzClosable]="true"
          (nzOnCancel)="fechar(formFiltrosMensagens)"
          [nzWidth]="1100">
    <nz-row *nzModalContent>
        <nz-col [nzSpan]="24">
            <form nz-form [nzNoColon]="false" [formGroup]="formFiltrosMensagens.formGroup">
                <nz-form-item>
                    <nz-form-label [nzSpan]="4">Domicílio</nz-form-label>
                    <nz-form-control [nzSpan]="16" nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrosMensagens.formGroup.value.domicilio">
                        <nz-select nzShowSearch nzAllowClear
                                   nzPlaceHolder="Selecione"
                                   formControlName="domicilio"
                                   (ngModelChange)="changeSelectUnidades($event)">
                            <nz-option *ngFor="let opt of arraySelectDomicilios"
                                       [nzLabel]="opt.descricao"
                                       [nzValue]="opt.id"
                            ></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="4">Unidade</nz-form-label>
                    <nz-form-control [nzSpan]="16" nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrosMensagens.formGroup.value.unidade">
                        <nz-select nzShowSearch
                                   nzAllowClear
                                   nzPlaceHolder="Selecione"
                                   formControlName="unidade"
                                   (ngModelChange)="changeSelectDomicilios($event)">
                            <nz-option *ngFor="let opt of arraySelectUnidades"
                                       [nzLabel]="modoDemo(opt.descricao, 'empresaCodigoNome') + ' (' + modoDemo(opt.cnpj | cpfCnpj, 'cnpj') + ')'"
                                       [nzValue]="opt.id"
                            ></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="4">Inscrição Estadual</nz-form-label>
                    <nz-form-control [nzSpan]="16" nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrosMensagens.formGroup.value.ie !== '' && formFiltrosMensagens.formGroup.value.ie !== null">
                        <input nz-input formControlName="ie" type="text">
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="4">Inscrição Municipal</nz-form-label>
                    <nz-form-control [nzSpan]="16" nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrosMensagens.formGroup.value.im !== '' && formFiltrosMensagens.formGroup.value.im !== null">
                        <input nz-input formControlName="im" type="text">
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="4">Data</nz-form-label>
                    <nz-form-control [nzSpan]="7" [nzHasFeedback]="formFiltrosMensagens.formGroup.value.dataDe">
                        <nz-date-picker [nzFormat]="'dd/MM/yyyy'"
                                        formControlName="dataDe" style="width: 100%;"
                                        nzPlaceHolder="De:"></nz-date-picker>
                    </nz-form-control>
                    <nz-form-control [nzSpan]="2">
                    </nz-form-control>
                    <nz-form-control [nzSpan]="7"
                                     [nzHasFeedback]="formFiltrosMensagens.formGroup.value.dataAte">
                        <nz-date-picker [nzFormat]="'dd/MM/yyyy'"
                                        formControlName="dataAte" style="width: 100%;"
                                        nzPlaceHolder="Até:"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="4">Categoria</nz-form-label>
                    <nz-form-control [nzSpan]="16" nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrosMensagens.formGroup.value.categoria !== '' && formFiltrosMensagens.formGroup.value.categoria !== null">
                        <nz-select nzShowSearch nzAllowClear
                                   nzPlaceHolder="Selecione"
                                   formControlName="categoria">
                            <nz-option *ngFor="let opt of arraySelectCategorias"
                                       [nzLabel]="opt.label"
                                       [nzValue]="opt.value"
                            ></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="4">Prazo Tácito</nz-form-label>
                    <nz-form-control [nzSpan]="16" [nzHasFeedback]="formFiltrosMensagens.formGroup.value.prazoTacito">
                        <nz-date-picker [nzFormat]="'dd/MM/yyyy'"
                                        formControlName="prazoTacito" style="width: 100%;"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSpan]="4">Status Leitura</nz-form-label>
                    <nz-form-control [nzSpan]="16"
                                     nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrosMensagens.formGroup.value.statusLeitura">
                        <nz-select nzShowSearch nzAllowClear
                                   [nzPlaceHolder]="'Selecione'" formControlName="statusLeitura">
                            <nz-option
                                    [nzLabel]="'Mostrar somente mensagens não lidas'" [nzValue]="'nLidas'">
                            </nz-option>
                            <nz-option
                                    [nzLabel]="'Mostrar somente mensagens lidas / expiradas'"
                                    [nzValue]="'lidasExpiradas'">
                            </nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSpan]="4">Status Visualização</nz-form-label>
                    <nz-form-control [nzSpan]="16"
                                     nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrosMensagens.formGroup.value.statusVisualizacao">
                        <nz-select nzShowSearch nzAllowClear
                                   [nzPlaceHolder]="'Selecione'" formControlName="statusVisualizacao">
                            <nz-option
                                    [nzLabel]="'Nunca visto'" [nzValue]="0">
                            </nz-option>
                            <nz-option [nzLabel]="'Já visualizado'" [nzValue]="1">
                            </nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
            </form>
        </nz-col>
    </nz-row>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="fechar(formFiltrosMensagens)">Cancelar</button>
        <button nz-button nzType="primary" (click)="filtrar()">Filtrar</button>
    </div>
</nz-modal>
<nz-modal [(nzVisible)]="formExportar.modalVisible"
          [nzTitle]="'Exportar'" [nzClosable]="true"
          (nzOnCancel)="modalExportar(false)"
          [nzWidth]="425">
    <div nz-row *nzModalContent>
        <form nz-form [nzNoColon]="true" [nzAutoTips]="autoTips" [formGroup]="formExportar.formGroup"
              style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="24">Selecione o formato do arquivo a ser exportado.</nz-form-label>
                <nz-form-control [nzSpan]="24">
                    <nz-select nzPlaceHolder="Selecione"
                               formControlName="tipo" style="width: 100%;">
                        <nz-option [nzLabel]="'.CSV'" [nzValue]="'csv'"></nz-option>
                        <nz-option [nzLabel]="'.XLSX'" [nzValue]="'xlsx'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalExportar(false);">Cancelar</button>
        <button nz-button nzType="primary" (click)="confirmaExportar()" [nzLoading]="loadings.exportar">Exportar
        </button>
    </div>
</nz-modal>
<nz-modal [(nzVisible)]="modalMensagemFlag"
          [nzTitle]="'Mensagem'" [nzClosable]="true"
          (nzOnCancel)="modalMensagem(false)"
          [nzStyle]="{ top: screenModalTop + 'px' }"
          [nzWidth]="screenWidth">
    <div nz-row *nzModalContent>
        <iframe [ngStyle]="{height: screenHeight + 'px'}"
                [src]="iframeUrl" title=""
                style="width: 100%; border: none;"
                *ngIf="isPdf"></iframe>
        <div [innerHTML]="conteudoMensagem" *ngIf="!isPdf" style="width: 100%;"></div>
    </div>
    <div *nzModalFooter>
        <a *ngIf="taskSelected?.arquivoNome?.startsWith('anexo_')" nz-button (click)="downloadAanexo()"
           style="border: none; background-color: transparent; color: #1890ff">
            <i *ngIf="!loadings.downloadAnexo[taskSelected?.id]" nz-icon nzType="download" nzTheme="outline"></i>
            <i *ngIf="loadings.downloadAnexo[taskSelected?.id]" nz-icon nzType="loading" nzTheme="outline"></i>
            Baixar anexo
        </a>
        <span *ngIf="leituraSolicitacaoUsuarioNome && leituraProcessamentoData else msgLidaNoPortal"
              style="float: left;">Leitura solicitada por: {{ leituraSolicitacaoUsuarioNome }}
            em {{ (leituraProcessamentoData | date: "dd/MM/yyyy 'às' HH:mm:ss") }}</span>
        <ng-template #msgLidaNoPortal>
            <span style="float: left; font-weight: bold">
                Mensagem lida diretamente no portal.
                <i style="color: #1890ff"
                   [nz-tooltip]="'Nosso sistema leu o conteúdo dessa mensagem, pois a mesma já estava lida no portal. Quando alguém solicitar a leitura pelo nosso app, um log será criado com o nome do usuário e a data de solicitação.'"
                   class="fa-regular fa-circle-question"></i>
            </span>
        </ng-template>
        <button nz-button nzType="link" (click)="modalMensagem(false);">Fechar</button>
    </div>
</nz-modal>
<nz-modal [(nzVisible)]="modalLogFlag"
          [nzTitle]="registroSelecionado?.ultimoProcessamentoStatus === 4 ?
          'Ação Requerida ' + (ultimoProcessamento | date:'dd/MM/yyyy HH:mm:ss') :
          'Log em ' + (ultimoProcessamento | date:'dd/MM/yyyy HH:mm:ss')" [nzClosable]="true"
          (nzOnCancel)="modalLog(false)"
          [nzWidth]="748">
    <div nz-row *nzModalContent>
        <div [innerHTML]="conteudoLog" class="d-flex justify-content-center"
             style="width: 100%; word-break: break-all;"></div>
    </div>
    <div *nzModalFooter>
        <a *ngIf="currentUser.origem === 'console'" nz-button nzType="link"
           (click)="retornaJson(registroSelecionado?.id)"
           [nzLoading]="this.loadingJson[registroSelecionado?.id]"
           nz-tooltip="Visualizar JSON (user-console)">
            <i nz-icon nzType="info-circle" nzTheme="outline"></i>
        </a>
        <a nz-button nzType="link"
           [nzLoading]="processamentoFlag[registroSelecionado?.id]"
           (click)="showConfirm(registroSelecionado?.id)">
            Repetir Leitura
        </a>
        <button nz-button nzType="link" (click)="modalLog(false);">Fechar</button>
    </div>
</nz-modal>
<nz-modal [(nzVisible)]="textAreaValue.modalVisible"
          [nzTitle]="'Json'" [nzClosable]="true"
          (nzOnCancel)="modalJson(false)"
          [nzWidth]="748">
    <div nz-row *nzModalContent>
        <form nz-form [nzNoColon]="true" [formGroup]="textAreaValue.formGroup" style="width: 100%;">
            <textarea rows="15" nz-input formControlName="json"></textarea>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalJson(false);">Fechar</button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formFiltrosCabecalho.modalVisible"
          [nzTitle]="'Filtros'" [nzClosable]="true"
          (nzOnCancel)="fechar(formFiltrosCabecalho)"
          [nzWidth]="648">
    <nz-row *nzModalContent>
        <nz-col [nzSpan]="24">
            <form nz-form [nzNoColon]="false" [formGroup]="formFiltrosCabecalho.formGroup">
                <nz-form-item>
                    <nz-form-label [nzSpan]="5">Grupos</nz-form-label>
                    <nz-form-control [nzSpan]="14">
                        <nz-select nzShowSearch nzAllowClear nzMode="multiple"
                                   nzPlaceHolder="Selecione"
                                   formControlName="grupoEmpresarial_id">
                            <nz-option *ngFor="let opt of arraySelectGrupos"
                                       [nzLabel]="modoDemo(opt.descricao, 'total')"
                                       [nzValue]="opt.id"
                            ></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
            </form>
        </nz-col>
    </nz-row>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="fechar(formFiltrosCabecalho)">Cancelar</button>
        <button [nzLoading]="loadingContadores || loadingCardDecs" nz-button nzType="primary"
                (click)="filtrarCabecalho()">Filtrar
        </button>
    </div>
</nz-modal>

