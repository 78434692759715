<nz-page-header class="site-page-header" nzTitle="Compliance" nzSubtitle="Tributos">
    <nz-page-header-extra>
        <a nz-button nzType="link" (click)="slideSwiper('left', 6)">
            <i class="fas fa-angle-double-left"></i>
        </a>
        <a nz-button nzType="link" (click)="slideSwiper('left', 3)">
            <i class="fas fa-angle-left"></i>
        </a>
        <a nz-button nzType="link" (click)="slideSwiper('right', 3)">
            <i class="fas fa-angle-right"></i>
        </a>
        <a nz-button nzType="link" (click)="slideSwiper('right', 6)">
            <i class="fas fa-angle-double-right"></i>
        </a>
        <a nz-button nzType="link" (click)="toggleCollapseCard()">
            <span>{{ collapseCard ? 'Expandir' : 'Recolher' }}</span>
        </a>
    </nz-page-header-extra>
</nz-page-header>
<nz-content>
    <div nz-row [nzGutter]="24">
        <div nz-col [nzSpan]="24" style="overflow: hidden;">
            <swiper
                    [(index)]="cardCompetenciaAtivo"
                    (click)="selecionaCardCompetencia($event)"
                    slidesPerView="auto"
                    [spaceBetween]="30"
                    [centeredSlides]="true"
                    [mousewheel]="false"
                    [scrollbar]="false"
                    [navigation]="true"
            >
                <ng-template *ngFor="let item of cardsAnos; index as i"
                             swiperSlide
                             class="swiper-card-competencia"
                >
                    <div class="card-competencia ant-card pull-up card-{{i}}" style="white-space: nowrap"
                         [ngClass]="collapseCard && 'compress'">
                        <div class="ant-card-head">
                            <h2>{{ item.ano }}</h2>
                        </div>
                        <div class="text-center ant-card-body card-{{i}}">
                            <div nz-row>
                                <div nz-col [nzSpan]="10" style="text-align: right; padding-right: 10px;">
                                    {{ item.unidades }}
                                </div>
                                <div nz-col [nzSpan]="14" style="text-align: left; ">
                                    Unidades
                                </div>
                            </div>
                            <div nz-row style="margin-bottom: 15px;">
                                <div nz-col [nzSpan]="10" style="text-align: right; padding-right: 10px;">
                                    {{ item.obrigacoes }}
                                </div>
                                <div nz-col [nzSpan]="14" style="text-align: left; ">
                                    Obrigações
                                </div>
                            </div>
                            <div nz-row>
                                <div nz-col [nzSpan]="24" style="text-align: center; ">
                                    <span nz-typography nzType="secondary">Obrigações</span>
                                    <nz-row>
                                        <nz-col [nzSpan]="24" style="text-align: center; white-space: nowrap;">
                                            <span style="margin-right: 5px;" *ngIf="item.obrigacoesMin < 0">
                                                Min
                                                {{ (item.obrigacoesMin * -1 | currency:'BRL').replace('R$', '') }}
                                                <small>(C)</small>
                                            </span>
                                            <span style="margin-right: 5px;" *ngIf="item.obrigacoesMin > 0">
                                                Min
                                                {{ (item.obrigacoesMin | currency:'BRL').replace('R$', '') }}
                                                <small>(D)</small>
                                            </span>
                                            <span style="margin-right: 5px;" *ngIf="item.obrigacoesMin == 0">
                                                Min
                                                {{ (item.obrigacoesMin | currency:'BRL').replace('R$', '') }}
                                            </span>
                                            <span style="margin-right: 5px;" *ngIf="item.obrigacoesMin ===null">Min (R$) -</span>
                                        </nz-col>
                                    </nz-row>
                                    <nz-row>
                                        <nz-col [nzSpan]="24" style="text-align: center">
                                            <span style="margin-right: 5px;" *ngIf="item.obrigacoesMax < 0">
                                                Max {{ (item.obrigacoesMax * -1 | currency:'BRL').replace('R$', '') }}
                                                <small>(C)</small>
                                            </span>
                                            <span style="margin-right: 5px;" *ngIf="item.obrigacoesMax > 0">
                                                Max {{ (item.obrigacoesMax | currency:'BRL').replace('R$', '') }}
                                                <small>(D)</small>
                                            </span>
                                            <span style="margin-right: 5px;" *ngIf="item.obrigacoesMax == 0">
                                                Max {{ (item.obrigacoesMax | currency:'BRL').replace('R$', '') }}
                                            </span>
                                            <span style="margin-right: 5px;" *ngIf="item.obrigacoesMax == null">Max (R$) -</span>
                                        </nz-col>
                                    </nz-row>
                                </div>
                            </div>
                            <div nz-row>
                                <div nz-col [nzSpan]="24" style="text-align: center; ">
                                    <span nz-typography nzType="secondary">Min x Max</span>
                                    <nz-row>
                                        <nz-col [nzSpan]="24" style="text-align: center">
                                        <span style="margin-right: 5px;" *ngIf="item.minMax < 0"
                                              [ngStyle]="{'color':'red'}">
                                              {{ (item.minMax * -1| currency:'BRL').replace('R$', '') }}

                                        </span>
                                            <span style="margin-right: 5px;" *ngIf="item.minMax > 0"
                                                  [ngStyle]="{'color':'red'}">
                                              {{ (item.minMax | currency:'BRL').replace('R$', '') }}

                                        </span>
                                            <span style="margin-right: 5px;" *ngIf="item.minMax == 0">
                                              {{ (item.minMax | currency:'BRL').replace('R$', '') }}
                                        </span>
                                            <span style="margin-right: 5px;" *ngIf="item.minMax === null">-</span>
                                        </nz-col>
                                    </nz-row>
                                </div>
                            </div>
                            <div nz-row>
                                <div nz-col [nzSpan]="24" style="text-align: center; ">
                                    <span nz-typography nzType="secondary">Pagamentos</span>
                                    <nz-row>
                                        <nz-col [nzSpan]="24" style="text-align: center">
                                            <span style="margin-right: 5px;" *ngIf="item.pagamentos < 0">
                                                {{ (item.pagamentos * -1 | currency:'BRL').replace('R$', '') }}

                                            </span>
                                            <span style="margin-right: 5px;" *ngIf="item.pagamentos > 0">
                                                {{ (item.pagamentos | currency:'BRL').replace('R$', '') }}

                                            </span>
                                            <span style="margin-right: 5px;" *ngIf="item.pagamentos == 0">
                                                {{ (item.pagamentos | currency:'BRL').replace('R$', '') }}
                                            </span>
                                            <span style="margin-right: 5px;" *ngIf="item.pagamentos === null">-</span>
                                        </nz-col>
                                    </nz-row>
                                </div>
                            </div>
                            <div nz-row>
                                <div nz-col [nzSpan]="24" style="text-align: center; ">
                                    <span nz-typography nzType="secondary">Pagto x Max</span>
                                    <nz-row>
                                        <nz-col [nzSpan]="24" style="text-align: center">
                                        <span style="margin-right: 5px;" *ngIf="item.pagtoMax < 0"
                                              [ngStyle]="{'color':'red'}">
                                            {{ (item.pagtoMax * -1 | currency:'BRL').replace('R$', '') }}

                                        </span>
                                            <span style="margin-right: 5px;" *ngIf="item.pagtoMax > 0"
                                                  [ngStyle]="item.pagtoMax > 0 && {'color':'red'}">
                                                {{ (item.pagtoMax | currency:'BRL').replace('R$', '') }}
                                        </span>
                                            <span style="margin-right: 5px;" *ngIf="item.pagtoMax == 0">
                                                {{ (item.pagtoMax | currency:'BRL').replace('R$', '') }}
                                        </span>
                                            <span style="margin-right: 5px;" *ngIf="item.pagtoMax === null">-</span>
                                        </nz-col>
                                    </nz-row>
                                </div>
                            </div>
                            <div nz-row style="margin-top: 10px">
                                <div nz-col [nzSpan]="24" style="text-align: center; ">
                                    <span style="font-size: 0.8em" nz-typography
                                          nzType="secondary">Último Processamento</span>
                                    <nz-row>
                                        <nz-col [nzSpan]="24" style="text-align: center">
                                            <span style="margin-right: 5px; font-size: 0.8em"
                                                  *ngIf="item.processamentoData">
                                                {{ (item.processamentoData | date: 'dd/MM/yyyy HH:mm') }}
                                            </span>
                                            <span style="margin-right: 5px; font-size: 0.8em"
                                                  *ngIf="!item.processamentoData">
                                                -
                                            </span>
                                        </nz-col>
                                    </nz-row>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </swiper>
        </div>
    </div>
    <div nz-row [nzGutter]="24">
        <div nz-col [nzSpan]="10">
            <nz-card class="font-doze"
                     nzTitle="Obrigações Acessórias (Oficiais)"
                     [nzExtra]="labelAno"
                     [ngClass]="!cardLoadingObrigacoesAcessorias ? 'ant-card-table' : ''"
                     [nzLoading]="cardLoadingObrigacoesAcessorias">
                <nz-table #tableObrigacoesAcessoriasOficiais
                          [nzData]="arrayTableObrigacoesAcessoriasOficiais"
                          [nzFrontPagination]="false"
                          [nzShowPagination]="false"
                          nzSize="small">
                    <thead>
                    <tr>
                        <th></th>
                        <th style="text-align: center;">Qtde</th>
                        <th style="text-align: center;">Min (R$)</th>
                        <th style="text-align: center;">Max (R$)</th>
                        <th style="text-align: center;">Min x Max (R$)</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let item of arrayTableObrigacoesAcessoriasOficiais" style="white-space: nowrap;">
                        <td style="">{{ item.obrigacao }}</td>
                        <td style="text-align: center;">
                            {{ item.qtde !== null ? item.qtde : '-' }}
                        </td>
                        <td style="text-align: right;">
                            <div *ngIf="item.obrigacoesMin < 0">
                                {{ item.obrigacoesMin * -1 | number }}
                                <span>(C)</span>
                            </div>
                            <div *ngIf="item.obrigacoesMin > 0">
                                {{ (item.obrigacoesMin | currency : 'BRL').replace('R$', '') }}
                                <span>(D)</span>
                            </div>
                            <div *ngIf="item.obrigacoesMin == 0">
                                {{ (item.obrigacoesMin | currency : 'BRL').replace('R$', '') }}
                            </div>
                            <div *ngIf="item.obrigacoesMin === null">-</div>
                        </td>
                        <td style="text-align: right;">
                            <div *ngIf="item.obrigacoesMax < 0">
                                {{ (item.obrigacoesMax * -1 | currency : 'BRL').replace('R$', '') }}
                                <span>(C)</span>
                            </div>
                            <div *ngIf="item.obrigacoesMax > 0">
                                {{ (item.obrigacoesMax | currency : 'BRL').replace('R$', '') }}
                                <span>(D)</span>
                            </div>
                            <div *ngIf="item.obrigacoesMax == 0">
                                {{ (item.obrigacoesMax | currency : 'BRL').replace('R$', '') }}
                            </div>
                            <div *ngIf="item.obrigacoesMax === null">-</div>
                        </td>
                        <td style="text-align: right;">

                            <span *ngIf="item.tolerancia > 0 && item.minMax <= item.tolerancia"
                                  nz-tooltip
                                  nzTooltipPlacement="bottom"
                                  [nzTooltipTitle]="'Tolerância '+ item.obrigacao + ' de R$ '+ item.tolerancia+',00'">
                                    {{ item.minMax === null ? '-' : (item.minMax | currency : 'BRL').replace('R$', '') }}
                            </span>

                            <span *ngIf="item.tolerancia > 0 && item.minMax > item.tolerancia"
                                  [ngStyle]="{'color': 'red'}"
                                  nz-tooltip
                                  nzTooltipPlacement="bottom"
                                  [nzTooltipTitle]="'Valor excede a tolerância de R$ '+ item.tolerancia + ',00 para '+ item.obrigacao">
                                        {{ item.minMax === null ? '-' : (item.minMax | currency : 'BRL').replace('R$', '') }}
                                    </span>

                            <span *ngIf="item.tolerancia <= 0">
                                {{ item.minMax === null ? '-' : (item.minMax | currency : 'BRL').replace('R$', '') }}
                            </span>
                        </td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr>
                        <th>Total</th>
                        <th></th>
                        <th style="text-align: right;">
                            <div *ngIf="minObrigacoesOficiais < 0">
                                {{ (minObrigacoesOficiais * -1 | currency : 'BRL').replace('R$', '') }}
                                <span>(C)</span>
                            </div>
                            <div *ngIf="minObrigacoesOficiais > 0">
                                {{ (minObrigacoesOficiais | currency : 'BRL').replace('R$', '') }}
                                <span>(D)</span>
                            </div>
                            <div *ngIf="minObrigacoesOficiais == 0">
                                {{ (minObrigacoesOficiais | currency : 'BRL').replace('R$', '') }}
                            </div>
                            <div *ngIf="minObrigacoesOficiais === null">-</div>
                        </th>

                        <th style="text-align: right;">
                            <div *ngIf="maxObrigacoesOficiais < 0">
                                {{ (maxObrigacoesOficiais * -1 | currency : 'BRL').replace('R$', '') }}
                                <span>(C)</span>
                            </div>
                            <div *ngIf="maxObrigacoesOficiais > 0">
                                {{ (maxObrigacoesOficiais | currency : 'BRL').replace('R$', '') }}
                                <span>(D)</span>
                            </div>
                            <div *ngIf="maxObrigacoesOficiais == 0">
                                {{ (maxObrigacoesOficiais | currency : 'BRL').replace('R$', '') }}
                            </div>
                            <div *ngIf="maxObrigacoesOficiais === null">-</div>
                        </th>
                        <th style="text-align: right;">
                            <span *ngIf="minMaxObrigacoesOficiais < 0" [ngStyle]="{'color':'red'}">
                                {{ (minMaxObrigacoesOficiais * -1 | currency : 'BRL').replace('R$', '') }}
                            </span>
                            <span *ngIf="minMaxObrigacoesOficiais > 0"
                                  [ngStyle]="minMaxObrigacoesOficiais > 0 && {'color':'red'}">
                                {{ (minMaxObrigacoesOficiais | currency : 'BRL').replace('R$', '') }}
                            </span>
                            <span *ngIf="minMaxObrigacoesOficiais == 0">
                                {{ (minMaxObrigacoesOficiais | currency : 'BRL').replace('R$', '') }}
                            </span>
                            <span *ngIf="minMaxObrigacoesOficiais === null">-</span>
                        </th>
                    </tr>
                    </tfoot>
                </nz-table>
            </nz-card>
        </div>
        <div nz-col [nzSpan]="14">
            <nz-card class="font-doze"
                     nzTitle="Obrigações Principais - Pagamentos"
                     [nzExtra]="labelAno"
                     [ngClass]="!cardLoadingObrigacoesPrincipais ? 'ant-card-table' : ''"
                     [nzLoading]="cardLoadingObrigacoesPrincipais">
                <div nz-row [nzGutter]="24">
                    <div nz-col [nzSpan]="12" class="d-flex align-items-center">
                        <highcharts-chart
                                [Highcharts]="HighchartsPie"
                                [options]="chartPieOptions"
                                *ngIf="chartOk && HighchartsPie && chartPieOptions"
                                [(update)]="updateFlag"
                                style="width: 100%; height: 310px; display: block;"
                        ></highcharts-chart>
                    </div>
                    <div [nzSpan]="12" nz-col>
                        <nz-table #tableObrigacoesPrincipaisPagamentos
                                  [nzData]="arrayTableObrigacoesPrincipaisPagamentos"
                                  [nzFrontPagination]="false"
                                  [nzShowPagination]="false"
                                  nzSize="small">
                            <thead>
                            <tr>
                                <th></th>
                                <th style="text-align: center;">Qtde</th>
                                <th style="text-align: center;">Valor (R$)</th>
                                <th style="text-align: center;">Pagto x Max (R$)</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let item of arrayTableObrigacoesPrincipaisPagamentos"
                                style="white-space: nowrap;">
                                <td>
                                    <i class="fas fa-circle" [ngStyle]="{'color': item.cor}"></i>
                                    <span>
                                        {{ item.obrigacao }}
                                    </span>
                                </td>
                                <td style="text-align: center;">{{ item.qtde }}</td>
                                <td style="text-align: right;">
                                    <div *ngIf="item.pagamentos < 0">
                                        {{ (item.pagamentos * -1 | currency : 'BRL').replace('R$', '') }}

                                    </div>
                                    <div *ngIf="item.pagamentos > 0">
                                        {{ (item.pagamentos | currency : 'BRL').replace('R$', '') }}

                                    </div>
                                    <div *ngIf="item.pagamentos == 0">
                                        {{ (item.pagamentos | currency : 'BRL').replace('R$', '') }}
                                    </div>
                                    <div *ngIf="item.pagamentos === null">-</div>
                                </td>
                                <td style="text-align: right;">

                                    <span *ngIf="item.tolerancia > 0 && item.pagamentosMax <= item.tolerancia"
                                          nz-tooltip
                                          nzTooltipPlacement="bottom"
                                          [nzTooltipTitle]="'Tolerância '+ item.obrigacao + ' de R$ '+ item.tolerancia+',00'">
                                        {{ !item.minMax || item.minMax === null ? '-' : (item.pagamentosMax | currency : 'BRL').replace('R$', '') }}
                                    </span>

                                    <span *ngIf="item.tolerancia > 0 && item.pagamentosMax > item.tolerancia"
                                          [ngStyle]="{'color': 'red'}"
                                          nz-tooltip
                                          nzTooltipPlacement="bottom"
                                          [nzTooltipTitle]="'Valor excede a tolerância de R$ '+ item.tolerancia + ',00 para '+ item.obrigacao">
                                        {{ item.pagamentosMax === null ? '-' : (item.pagamentosMax | currency : 'BRL').replace('R$', '') }}
                                    </span>

                                    <span *ngIf="item.tolerancia <= 0">
                                        {{ item.pagamentosMax === null ? '-' : (item.pagamentosMax | currency : 'BRL').replace('R$', '') }}
                                    </span>

                                </td>
                            </tr>
                            </tbody>
                            <tfoot>
                            <tr>
                                <th>Total</th>
                                <th></th>
                                <th style="text-align: right;">
                                    <div *ngIf="pagamentosObrigacoes < 0">
                                        {{ (pagamentosObrigacoes * -1 | currency : 'BRL').replace('R$', '') }}

                                    </div>
                                    <div *ngIf="pagamentosObrigacoes > 0">
                                        {{ (pagamentosObrigacoes | currency : 'BRL').replace('R$', '') }}

                                    </div>
                                    <div *ngIf="pagamentosObrigacoes == 0">
                                        {{ (pagamentosObrigacoes | currency : 'BRL').replace('R$', '') }}
                                    </div>
                                    <div *ngIf="pagamentosObrigacoes === null">-</div>
                                </th>
                                <th style="text-align: right;">
                                    <span *ngIf="pagtoMaxObrigacoes < 0" [ngStyle]="{'color':'red'}">
                                        {{ (pagtoMaxObrigacoes * -1 | currency : 'BRL').replace('R$', '') }}

                                    </span>
                                    <span *ngIf="pagtoMaxObrigacoes > 0" [ngStyle]="{'color':'red'}">
                                        {{ (pagtoMaxObrigacoes | currency : 'BRL').replace('R$', '') }}

                                    </span>
                                    <span *ngIf="pagtoMaxObrigacoes == 0">
                                        {{ (pagtoMaxObrigacoes | currency : 'BRL').replace('R$', '') }}
                                    </span>
                                    <span *ngIf="pagtoMaxObrigacoes === null">-</span>
                                </th>
                            </tr>
                            </tfoot>
                        </nz-table>
                    </div>
                </div>
            </nz-card>
        </div>
    </div>
</nz-content>

<nz-card [ngClass]="[status ? 'card-fullscreen' : '', !cardLoadingTabs ? 'ant-card-table' : '']"
         [nzLoading]="cardLoadingTabs">
    <nz-card-tab>
        <nz-tabset [nzSize]="'large'" [nzTabBarExtraContent]="tabsExtra" (click)="changeTabs()"
                   [(nzSelectedIndex)]="tabNumber">
            <nz-tab [nzDisabled]="cardLoadingTabs" nzTitle="{{obrigacao}}"
                    *ngFor="let obrigacao of arrayImpostos"></nz-tab>
        </nz-tabset>
    </nz-card-tab>
    <nz-table #tableInconsistencias
              [nzData]="arrayTableObrigacoesEvolucao"
              [nzFrontPagination]="false"
              [nzShowPagination]="false"
              [nzFooter]="footer"
              nzSize="small" class="font-doze"
              nzBordered>
        <thead>
        <tr>
            <th colspan="2"></th>
            <th colspan="5">Obrigações Acessórias (Oficiais)</th>
            <th colspan="4">Pagamentos</th>
        </tr>
        <tr>
            <th #column1></th>
            <th #column2 nzWidth="34"></th>
            <th #column3 style="text-align: center;">Min (R$)</th>
            <th #column4 style="text-align: center;">Max (R$)</th>
            <th #column5 style="text-align: center;">Qtde</th>
            <th #column6 style="text-align: center;">Diferença (R$)</th>
            <th #column7 style="text-align: center;">Evolução</th>
            <th #column8 style="text-align: center;">Valor (R$)</th>
            <th #column9 style="text-align: center;">Qtde</th>
            <th #column10 style="text-align: center;">Evolução</th>
            <th #column11 style="text-align: center;">Pagto x Max (R$)</th>
        </tr>
        </thead>
        <tbody id="tbody-sparkline">
        <ng-container class="ant-table-tbody" *ngFor="let data of arrayTableObrigacoesEvolucao">
            <tr>
                <td [ngStyle]="{'width': '200px'}">
                    <h5 nz-typography class="ant-card-loading-block" style="display: none;"></h5>
                    <h5 nz-typography>
                        {{ data.grupo }}
                    </h5>
                </td>
                <td [nzExpand]="expandSet.has(data.id)"
                    (nzExpandChange)="onExpandChange(data.id, $event)"
                ></td>
                <td style="text-align: right;">
                    <div *ngIf="data.obrigacoesAcessoriasTotalMin < 0">
                        {{ data.obrigacoesAcessoriasTotalMin * -1 | number }}
                        <span>(C)</span>
                    </div>
                    <div *ngIf="data.obrigacoesAcessoriasTotalMin > 0">
                        {{ data.obrigacoesAcessoriasTotalMin | number }}
                        <span>(D)</span>
                    </div>
                    <div *ngIf="data.obrigacoesAcessoriasTotalMin == 0">
                        {{ data.obrigacoesAcessoriasTotalMin | number }}
                    </div>
                    <div *ngIf="data.obrigacoesAcessoriasTotalMin === null">-</div>
                </td>
                <td style="text-align: right;">

                    <div *ngIf="data.obrigacoesAcessoriasTotalMax < 0">
                        {{ data.obrigacoesAcessoriasTotalMax * -1 | number }}
                        <span>(C)</span>
                    </div>
                    <div *ngIf="data.obrigacoesAcessoriasTotalMax > 0">
                        {{ data.obrigacoesAcessoriasTotalMax | number }}
                        <span>(D)</span>
                    </div>
                    <div *ngIf="data.obrigacoesAcessoriasTotalMax == 0">
                        {{ data.obrigacoesAcessoriasTotalMax | number }}
                    </div>
                    <div *ngIf="data.obrigacoesAcessoriasTotalMax === null">-</div>
                </td>
                <td style="text-align: center;">{{ data.obrigacoesAcessoriasQtde }}</td>
                <td style="text-align: right;">

                     <span *ngIf="data.obrigacoesAcessoriasMinMax <= valorTolerancia" nz-tooltip
                           nzTooltipPlacement="bottom"
                           [nzTooltipTitle]="'Tolerância '+ tipoTolerancia + ' de R$ '+ valorTolerancia+',00'">
                            {{ (data.obrigacoesAcessoriasMinMax == null ? "-" : data.obrigacoesAcessoriasMinMax | currency:'BRL').replace('R$', '') }}
                     </span>

                    <span *ngIf="data.obrigacoesAcessoriasMinMax > valorTolerancia"
                          [ngStyle]="{'color':'red'}">
                            {{ (data.obrigacoesAcessoriasMinMax == null ? "-" : data.obrigacoesAcessoriasMinMax | currency:'BRL').replace('R$', '') }}
                     </span>
                </td>
                <td>
                    <div class="d-flex justify-content-center">
                        <app-spark-line
                                *ngIf="somaValores(data.obrigacoesAcessoriasEvolucao.min) > 0; else sparkLineFake"
                                [name]="'Evolução'"
                                [data]="data.obrigacoesAcessoriasEvolucao.min">
                        </app-spark-line>
                    </div>
                </td>
                <td style="text-align: right;">
                    <div *ngIf="data.pagamentosValor < 0">
                        {{ (data.pagamentosValor * -1 | currency: 'BRL').replace('R$', '') }}

                    </div>
                    <div *ngIf="data.pagamentosValor > 0">
                        {{ (data.pagamentosValor | currency: 'BRL').replace('R$', '') }}

                    </div>
                    <div *ngIf="data.pagamentosValor == 0">
                        {{ (data.pagamentosValor | currency: 'BRL').replace('R$', '') }}
                    </div>
                    <div *ngIf="data.pagamentosValor == null">-</div>
                </td>
                <td style="text-align: center;">{{ data.pagamentosQtde }}</td>
                <td>
                    <div class="d-flex justify-content-center">
                        <app-spark-line *ngIf="somaValores(data.pagamentosEvolucao) > 0; else sparkLineFake"
                                        [name]="'Evolução'" [data]="data.pagamentosEvolucao">

                        </app-spark-line>
                    </div>
                </td>
                <td style="text-align: right;">

                    <span *ngIf="data.pagtoMax < 0" [ngStyle]="{'color':'red'}" nz-typography nzType="danger">
                        {{ (data.pagtoMax | currency: 'BRL').replace('R$', '') }}
                    </span>
                    <span *ngIf="data.pagtoMax > 0">
                        {{ (data.pagtoMax | currency: 'BRL').replace('R$', '') }}
                    </span>
                    <span *ngIf="data.pagtoMax == 0">
                        {{ (data.pagtoMax | currency: 'BRL').replace('R$', '') }}
                    </span>
                    <span *ngIf="data.pagtoMax == null">-</span>
                </td>
            </tr>
            <tr [nzExpand]="expandSet.has(data.id)" *ngFor="let unidade of data.unidades">
                <td [ngStyle]="{
                        'width': '200px',
                        padding: '8px',
                        'overflow-wrap': 'break-word'
                        }">
                    <!--<h5 nz-typography class="ant-card-loading-block" (click)="openTab('ComplianceTributosEmpresasComponent', null, unidade.id)">
                    </h5>-->
                    <span class="link-name"
                          (click)="openTab('ComplianceTributosEmpresasComponent', null, {
                          unidade: unidade.id, ano: anoAtivo, obrigacao: obrigacaoSelecionada, ie: unidade.ie
                          })">
                        {{ modoDemo(unidade.nome, 'empresaCodigoNome') }}
                        <span *ngIf="unidade.ie"
                              style="color: rgb(24, 144, 255); cursor: pointer" nz-icon
                              nzType="info-circle" nzTheme="outline" nz-tooltip="IE: {{unidade.ie}}">
                        </span> <br>
                        ({{ modoDemo(unidade.cnpj, 'cnpj') | cpfCnpj }})
                    </span>
                </td>
                <td [ngStyle]="{
                        'width': column2Width + 'px',
                        padding: '8px',
                        'overflow-wrap': 'break-word'
                        }"></td>
                <td [ngStyle]="{
                        'width': column3Width + 'px',
                        padding: '8px',
                        'overflow-wrap': 'break-word',
                        'text-align': 'right'
                        }">
                    <div *ngIf="unidade.obrigacoesAcessoriasTotalMin < 0">
                        {{ (unidade.obrigacoesAcessoriasTotalMin * -1 | currency:'BRL').replace('R$', '') }}
                        <span>(C)</span>
                    </div>
                    <div *ngIf="unidade.obrigacoesAcessoriasTotalMin > 0">
                        {{ (unidade.obrigacoesAcessoriasTotalMin | currency:'BRL').replace('R$', '') }}
                        <span>(D)</span>
                    </div>
                    <div *ngIf="unidade.obrigacoesAcessoriasTotalMin == 0">
                        {{ (unidade.obrigacoesAcessoriasTotalMin | currency:'BRL').replace('R$', '') }}
                    </div>
                    <div *ngIf="unidade.obrigacoesAcessoriasTotalMin === null">-</div>
                </td>
                <td [ngStyle]="{
                        'width': column4Width + 'px',
                        padding: '8px',
                        'overflow-wrap': 'break-word',
                        'text-align': 'right'
                        }">
                    <div *ngIf="unidade.obrigacoesAcessoriasTotalMax < 0">
                        {{ (unidade.obrigacoesAcessoriasTotalMax * -1 | currency:'BRL').replace('R$', '') }}
                        <span>(C)</span>
                    </div>
                    <div *ngIf="unidade.obrigacoesAcessoriasTotalMax > 0">
                        {{ (unidade.obrigacoesAcessoriasTotalMax | currency:'BRL').replace('R$', '') }}
                        <span>(D)</span>
                    </div>
                    <div *ngIf="unidade.obrigacoesAcessoriasTotalMax == 0">
                        {{ (unidade.obrigacoesAcessoriasTotalMax | currency:'BRL').replace('R$', '') }}
                    </div>
                    <div *ngIf="unidade.obrigacoesAcessoriasTotalMax === null">-</div>
                </td>
                <td [ngStyle]="{
                        'width': column5Width + 'px',
                        padding: '8px',
                        'overflow-wrap': 'break-word',
                        'text-align': 'center'
                        }">
                    <div *ngIf="unidade.obrigacoesAcessoriasQtde < 0">
                        {{ unidade.obrigacoesAcessoriasQtde * -1 }}
                    </div>
                    <div *ngIf="unidade.obrigacoesAcessoriasQtde >= 0">
                        {{ unidade.obrigacoesAcessoriasQtde }}
                    </div>
                    <div *ngIf="unidade.obrigacoesAcessoriasQtde === null">-</div>
                </td>
                <td [ngStyle]="{
                        'width': column6Width + 'px',
                        position: 'relative',
                        padding: '8px',
                        'overflow-wrap': 'break-word',
                        'text-align': 'right'
                        }">

                        <span *ngIf="unidade.obrigacoesAcessoriasMinMax <= valorTolerancia"
                              nz-tooltip
                              nzTooltipPlacement="bottom"
                              [nzTooltipTitle]="'Tolerância '+ tipoTolerancia + ' de R$ '+ valorTolerancia+',00'">
                            {{ (unidade.obrigacoesAcessoriasMinMax === null ? "-" : unidade.obrigacoesAcessoriasMinMax | currency:'BRL').replace('R$', '') }}
                        </span>

                    <span *ngIf="unidade.obrigacoesAcessoriasMinMax > valorTolerancia"
                          [ngStyle]="{'color':'red'}">
                                {{ (unidade.obrigacoesAcessoriasMinMax === null ? "-" : unidade.obrigacoesAcessoriasMinMax | currency:'BRL').replace('R$', '') }}
                        </span>

                </td>
                <td [ngStyle]="{
                        'width': column7Width + 'px',
                        padding: '8px',
                        'overflow-wrap': 'break-word'
                        }">
                    <div class="d-flex justify-content-center">
                        <app-spark-line
                                *ngIf="somaValores(unidade.obrigacoesAcessoriasEvolucao.min) > 0; else sparkLineFake"
                                [name]="'Evolução'" [data]="unidade.obrigacoesAcessoriasEvolucao.min">
                        </app-spark-line>
                    </div>
                </td>
                <td [ngStyle]="{
                        'width': column8Width + 'px',
                        padding: '8px',
                        'overflow-wrap': 'break-word',
                        'text-align': 'right'
                        }">
                    <div *ngIf="unidade.pagamentosValor < 0">
                        {{ (unidade.pagamentosValor * -1 | currency:'BRL').replace('R$', '') }}

                    </div>
                    <div *ngIf="unidade.pagamentosValor > 0">
                        {{ (unidade.pagamentosValor | currency:'BRL').replace('R$', '') }}

                    </div>
                    <div *ngIf="unidade.pagamentosValor == 0">
                        {{ (unidade.pagamentosValor | currency:'BRL').replace('R$', '') }}
                    </div>
                    <div *ngIf="unidade.pagamentosValor === null">-</div>
                </td>
                <td [ngStyle]="{
                        'width': column9Width + 'px',
                        position: 'relative',
                        padding: '8px',
                        'overflow-wrap': 'break-word',
                        'text-align': 'center'
                        }">
                    <div *ngIf="unidade.pagamentosQtde < 0">
                        {{ data.pagamentosQtde * -1 }}
                    </div>
                    <div *ngIf="unidade.pagamentosQtde >= 0">
                        {{ unidade.pagamentosQtde }}
                    </div>
                    <div *ngIf="unidade.pagamentosQtde === null">-</div>
                </td>
                <td [ngStyle]="{
                        'width': column10Width + 'px',
                        position: 'relative',
                        padding: '8px',
                        'overflow-wrap': 'break-word'
                        }">
                    <div class="d-flex justify-content-center">
                        <app-spark-line *ngIf="somaValores(unidade.pagamentosEvolucao) > 0; else sparkLineFake"
                                        [name]="'Evolução'" [data]="unidade.pagamentosEvolucao">
                        </app-spark-line>
                    </div>
                </td>
                <td [ngStyle]="{
                        'width': column11Width + 'px',
                        padding: '8px',
                        'overflow-wrap': 'break-word',
                        'text-align' : 'right'
                        }">
                    <span *ngIf="unidade.pagtoMax <= valorTolerancia && unidade.pagtoMax !== 0"
                          nz-tooltip
                          nzTooltipPlacement="bottom"
                          [nzTooltipTitle]="'Tolerância '+ tipoTolerancia + ' de R$ '+ valorTolerancia+',00'">
                            {{ (unidade.pagtoMax === null ? "-" : unidade.pagtoMax | currency:'BRL').replace('R$', '') }}
                        </span>
                    <span *ngIf="unidade.pagtoMax > valorTolerancia"
                          [ngStyle]="{'color':'red'}">
                                {{ (unidade.pagtoMax === null ? "-" : unidade.pagtoMax | currency:'BRL').replace('R$', '') }}
                        </span>
                    <span *ngIf="unidade.pagtoMax == 0">
                        {{ (unidade.pagtoMax | currency:'BRL').replace('R$', '') }}
                    </span>
                    <span *ngIf="unidade.pagtoMax === null">-</span>
                </td>
            </tr>
        </ng-container>
        </tbody>
        <ng-template style="margin-top: -10px" #footer>{{ totalRegistros }} registros</ng-template>
    </nz-table>
</nz-card>

<div style="height: 10px; background-color: transparent"></div>
<ng-template #tabsExtra>
    <div class="d-flex align-items-center">
        <div nz-col nzFlex="52px">
            <button (click)="exibirTudo(!expandAll)" nz-button>
                <em *ngIf="expandAll" class="fas fa-angle-double-down"></em>
                <em *ngIf="!expandAll" class="fas fa-angle-double-up"></em>
            </button>
        </div>
        <nz-badge [nzCount]="qtdFiltrosAtivos"
                  [nzStyle]="{ backgroundColor: '#52c41a', 'margin-right' : '15px'}">
            <button nz-button nzType="primary" style="margin-right: 10px;" (click)="abrirModal(formFiltrar)">
                <i nz-icon nzType="search"></i>
                Filtros
            </button>
        </nz-badge>
        <button nz-button [nzType]="'primary'"
                style="margin-right: 8px;" (click)="modalExportar(true);">
            <span>Exportar Planilha</span>
        </button>
        <button nz-button [nzType]="'default'" (click)="clickEvent()">
            <i class="fas" [ngClass]="!status ? 'fa-expand' : 'fa-compress'"></i>
        </button>
    </div>
</ng-template>
<ng-template #labelAno>
    <div class="d-flex align-items-center">
        <h3 style="color: rgba(0,0,0,.45);">{{ anoAtivo }}</h3>
    </div>
</ng-template>
<nz-modal [(nzVisible)]="formFiltrar.modalVisible"
          [nzTitle]="'Filtros Avançados'" [nzClosable]="true"
          (nzOnCancel)="fechar(formFiltrar)"
          [nzWidth]="648">
    <nz-row *nzModalContent>
        <nz-col [nzSpan]="24">
            <form nz-form [nzNoColon]="true" [formGroup]="formFiltrar.formGroup">
                <nz-form-item>
                    <nz-form-label [nzSpan]="5">Empresa:</nz-form-label>
                    <nz-form-control [nzSpan]="14"
                                     nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrar.formGroup.value.empresa_id">
                        <nz-select nzShowSearch nzAllowClear
                                   nzPlaceHolder="Selecione"
                                   formControlName="empresa_id"
                                   [(ngModel)]="formFiltrar.formGroup.value.empresa_id">
                            <nz-option *ngFor="let opt of arraySelectEmpresa"
                                       [nzLabel]="opt.descricao"
                                       [nzValue]="opt.id"
                            ></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="5">CNPJ</nz-form-label>
                    <nz-form-control [nzSpan]="14"
                                     nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrar.formGroup.value.empresa_id">
                        <nz-select nzShowSearch
                                   nzAllowClear
                                   nzPlaceHolder="Selecione"
                                   formControlName="empresa_id"
                                   [(ngModel)]="formFiltrar.formGroup.value.empresa_id">
                            <nz-option *ngFor="let opt of arraySelectCnpj"
                                       [nzLabel]="opt.descricao | cpfCnpj"
                                       [nzValue]="opt.id"
                            ></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="5">IE</nz-form-label>
                    <nz-form-control [nzSpan]="14"
                                     nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrar.formGroup.value.ie !== '' && formFiltrar.formGroup.value.ie !== null">
                        <input nz-input type="text" formControlName="ie" maxlength="20" size="60" nzAllowClear/>
                    </nz-form-control>
                </nz-form-item>
            </form>
        </nz-col>
    </nz-row>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="fechar(formFiltrar)">Cancelar</button>
        <button nz-button nzType="primary" (click)="filtrar()">Filtrar</button>
    </div>
</nz-modal>
<nz-modal [(nzVisible)]="isVisible" nzTitle="{{empresaSelecionada}}" (nzOnCancel)="handleCancel()">
    <ng-container *nzModalContent>
        <iframe src="" title="" style="width: 100%;"></iframe>
    </ng-container>
</nz-modal>

<app-exportar-tabela #componentExport [data]="dataExport"></app-exportar-tabela>

<ng-template #sparkLineFake>
    <span style="border-bottom: 1px solid rgba(124,181,236, 0.5); width: 120px; margin-top: 10px"></span>
</ng-template>
