<nz-page-header class="site-page-header" nzTitle="Compliance" nzSubtitle="BI Dashboard">
</nz-page-header>

<nz-content>
    <nz-card [nzBodyStyle]="{'padding': loadings.principal ? '40px' : '0px'}" [nzExtra]="navBar"
             [nzTitle]="'Notas Não Escrituradas'"
             [ngClass]="[statusCard ? 'card-fullscreen' : 'ant-card-table fonte-doze']">

        <ng-template #navBar>
            <div *ngIf="!loadings.principal">
                <nz-badge [nzCount]="qtdFiltrosAtivos" style="margin-right: 10px;"
                          [nzStyle]="{ backgroundColor: '#52c41a' }">
                    <button disabled="{{loadings.principal}}" nz-button nzType="primary"
                            (click)="modalFiltrar(true)">
                        <i nz-icon nzType="search"></i>
                        Filtros
                    </button>
                </nz-badge>
                <button nz-button [nzType]="'default'" (click)="clickEvent()"
                        style="padding-left: 8px; padding-right: 8px;">
                    <i class="fas" [ngClass]="statusCard ? 'fa-compress' : 'fa-expand'"></i>
                </button>
            </div>
        </ng-template>

        <div *ngIf="loadings.principal else graficos">

            <form nz-form [nzAutoTips]="autoTips" [nzNoColon]="true" [formGroup]="formFiltrar"
                  style="width: 100%;">

                <nz-form-item *ngIf="comboResponsaveis?.length">
                    <nz-form-label [nzSpan]="6">Responsável</nz-form-label>
                    <nz-form-control [nzSpan]="15"
                                     [nzHasFeedback]="formFiltrar.value.email">
                        <nz-select nzShowSearch nzAllowClear
                                   [nzPlaceHolder]="'Selecione'" formControlName="email"
                        (ngModelChange)="changeResponsavel()">
                            <nz-option *ngFor="let opt of comboResponsaveis"
                                       [nzLabel]="opt"
                                       [nzValue]="opt"
                            ></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label nzRequired [nzSpan]="6">Período Atual</nz-form-label>
                    <nz-form-control [nzSpan]="15"
                                     [nzHasFeedback]="formFiltrar.value.periodo_atual">
                        <nz-select nzShowSearch nzAllowClear
                                   [nzPlaceHolder]="'Selecione'" formControlName="periodo_atual">
                            <nz-option *ngFor="let opt of periodos"
                                       [nzLabel]="opt.descricao"
                                       [nzValue]="opt.valor"
                            ></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label nzRequired [nzSpan]="6">Período Anterior</nz-form-label>
                    <nz-form-control [nzSpan]="15"
                                     [nzHasFeedback]="formFiltrar.value.periodo_anterior">
                        <nz-select nzShowSearch nzAllowClear
                                   [nzPlaceHolder]="'Selecione'" formControlName="periodo_anterior">
                            <nz-option *ngFor="let opt of periodos"
                                       [nzLabel]="opt.descricao"
                                       [nzValue]="opt.valor"
                            ></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSpan]="6">Grupo Empresarial</nz-form-label>
                    <nz-form-control [nzSpan]="15" [nzHasFeedback]="formFiltrar.value.grupo">
                        <nz-select formControlName="grupo"
                                   [nzPlaceHolder]="'Selecione'" [nzAllowClear]="true">
                            <nz-option *ngFor="let option of grupoEmpresarialOptions" [nzLabel]="option.label"
                                       [nzValue]="option.value"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSpan]="6">Empresa</nz-form-label>
                    <nz-form-control [nzSpan]="15" size="60"
                                     [nzHasFeedback]="formFiltrar.value.empresa">
                        <nz-select nzShowSearch nzAllowClear
                                   nzPlaceHolder="Selecione"
                                   formControlName="empresa"
                        >
                            <nz-option *ngFor="let opt of arraySelectEmpresa"
                                       [nzLabel]="opt.descricao"
                                       [nzValue]="opt.id"
                            ></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>

            </form>

            <div style="padding: 15px 0; width: 100%; text-align: center">
                <button *ngIf="!formFiltrar.value.periodo_atual || !formFiltrar.value.periodo_anterior else btFiltrar"
                        style="width: 450px"
                        [disabled]="true" nz-button nzType="primary"
                        [nz-tooltip]="'Informe pelo menos os períodos para acessar a tela.'"
                        (click)="confirmaFiltrar()">Exibir gráficos
                </button>
                <ng-template #btFiltrar>
                    <button style="width: 450px" nz-button nzType="primary"
                            (click)="confirmaFiltrar()">Exibir gráficos
                    </button>
                </ng-template>
            </div>

        </div>

        <ng-template #graficos>
            <div class="card-main">

                <div class="area-chart" *ngIf="!loadings.porEmpresa else loadingChart">
                    <highcharts-chart class="chart"
                                      [Highcharts]="Highcharts"
                                      [options]="chartOptionsEmpresa"
                                      [(update)]="updateFlag"
                                      *ngIf="Highcharts && chartOptionsEmpresa"
                                      (resize)="highChart?.reflow()"
                    ></highcharts-chart>
                </div>

                <div class="area-chart"  *ngIf="!loadings.porAno else loadingChart">
                    <highcharts-chart class="chart"
                                      [Highcharts]="Highcharts"
                                      [options]="chartOptionsPorAno"
                                      [(update)]="updateFlag"
                                      (resize)="highChart?.reflow()"
                                      *ngIf="chartOptionsPorAno && Highcharts"
                    ></highcharts-chart>
                </div>

                <div class="area-chart"  *ngIf="!loadings.top20 else loadingChart">
                    <highcharts-chart class="chart"
                                      [Highcharts]="Highcharts"
                                      [options]="chartOptionsTop20"
                                      [(update)]="updateFlag"
                                      (resize)="highChart?.reflow()"
                                      *ngIf="chartOptionsTop20 && Highcharts"
                    ></highcharts-chart>
                </div>

                <div class="area-chart" *ngIf="!loadings.porGrupo else loadingChart">
                    <highcharts-chart class="chart"
                                      [Highcharts]="Highcharts"
                                      [options]="chartOptionsGroupo"
                                      [(update)]="updateFlagBassrasAgrupadas"
                                      (resize)="highChart?.reflow()"
                                      *ngIf="chartOptionsGroupo && Highcharts"
                    ></highcharts-chart>
                </div>

                <ng-template #loadingChart>
                    <div class="area-chart" style="display: inline-block; width: 100%">
                        <nz-skeleton></nz-skeleton>
                        <nz-skeleton></nz-skeleton>
                    </div>
                </ng-template>

            </div>
        </ng-template>

    </nz-card>
</nz-content>

<nz-modal [(nzVisible)]="formFilterVisible" [nzTitle]="'Filtros'" [nzClosable]="true"
          (nzOnCancel)="modalFiltrar(false)" [nzWidth]="650">
    <div nz-row *nzModalContent>
        <form nz-form [nzAutoTips]="autoTips" [nzNoColon]="true" [formGroup]="formFiltrar"
              style="width: 100%;">

            <nz-form-item *ngIf="comboResponsaveis?.length">
                <nz-form-label [nzSpan]="6">Responsável</nz-form-label>
                <nz-form-control [nzSpan]="15"
                                 [nzHasFeedback]="formFiltrar.value.email">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="email">
                        <nz-option *ngFor="let opt of comboResponsaveis"
                                   [nzLabel]="opt"
                                   [nzValue]="opt"
                        ></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label nzRequired [nzSpan]="6">Período Atual</nz-form-label>
                <nz-form-control [nzSpan]="15"
                                 [nzHasFeedback]="formFiltrar.value.periodo_atual">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="periodo_atual">
                        <nz-option *ngFor="let opt of periodos"
                                   [nzLabel]="opt.descricao"
                                   [nzValue]="opt.valor"
                        ></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label nzRequired [nzSpan]="6">Período Anterior</nz-form-label>
                <nz-form-control [nzSpan]="15"
                                 [nzHasFeedback]="formFiltrar.value.periodo_anterior">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="periodo_anterior">
                        <nz-option *ngFor="let opt of periodos"
                                   [nzLabel]="opt.descricao"
                                   [nzValue]="opt.valor"
                        ></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="6">Grupo Empresarial</nz-form-label>
                <nz-form-control [nzSpan]="15" [nzHasFeedback]="formFiltrar.value.grupo">
                    <nz-select formControlName="grupo"
                               [nzPlaceHolder]="'Selecione'" [nzAllowClear]="true">
                        <nz-option *ngFor="let option of grupoEmpresarialOptions" [nzLabel]="option.label"
                                   [nzValue]="option.value"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="6">Empresa</nz-form-label>
                <nz-form-control [nzSpan]="15" size="60"
                                 [nzHasFeedback]="formFiltrar.value.empresa">
                    <nz-select nzShowSearch nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="empresa"
                    >
                        <nz-option *ngFor="let opt of arraySelectEmpresa"
                                   [nzLabel]="opt.descricao"
                                   [nzValue]="opt.id"
                        ></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalFiltrar(false)">Cancelar</button>
        <button nz-button nzType="primary" (click)="confirmaFiltrar()">Confirmar</button>
    </div>
</nz-modal>
