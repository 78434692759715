import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {DataService} from '@services/data.service';
import {NotifyService} from '@components/notify/notify.service';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import {AbstractService} from '@services/abstract.service';
import {buildUrl, findComponentByUrl} from '../../../shared/components-helper';
import {Tab} from '@models/tab.model';
import {TabService} from '@services/tab.service';

@Component({
    selector: 'notify-app',
    templateUrl: './notify.component.html',
    styleUrls: ['./notify.component.scss'],
})
export class NotifyComponent extends AbstractService<any> implements OnInit {

    constructor(private router: Router,
                private dataService: DataService,
                private notifyService: NotifyService,
                public http: HttpClient,
                private toastService: ToastrService,
                private tabService: TabService) {
        super(http, '/notify', {});

        NotifyComponent.http = this.http;

    }

    get staticArrayNotify(): any {
        return NotifyComponent.arrayNotify;
    }

    get staticUserAdmin(): any {
        return NotifyComponent.userAdmin;
    }

    get staticConnected(): any {
        return NotifyComponent.connected;
    }

    get staticUltimaAtualizacao(): any {
        return NotifyComponent.ultimaAtualizacao;
    }

    private static userAdmin: boolean;
    private static connected: boolean;
    private static arrayNotify = [];
    private static ultimaAtualizacao: Date;
    private static currentUserId;
    private static http: HttpClient;
    private static toastService: ToastrService;

    static initChanels(): void {

        const paramsUsuario = NotifyService.retornaParametrosUsuario();

        this.currentUserId = paramsUsuario.currentUserId;
        this.userAdmin = paramsUsuario.userAdmin;
        this.connected = paramsUsuario.connected;

        this.retornaNotificacoes();

        this.connectPublicChannel(paramsUsuario.identificador);

        this.connectPrivateChannel(paramsUsuario.currentUserId, paramsUsuario.identificador);

    }


    static connectPublicChannel(identificador) {

        window.Echo.channel(identificador).listen('NotificacaoPublica', (e) => {

            if (e.message.titulo && e.message.mensagem && e.message.id) {

                this.notifyAdd(e);

            }

        });

    }

    static connectPrivateChannel(currentUserId, identificador) {

        window.Echo.private(`usuario-${currentUserId}-${identificador}`).listen(`NotificacaoPrivada`, (e) => {

            if (e.message.titulo && e.message.mensagem && e.message.id) {

                NotifyComponent.notifyAdd(e);

            }

        });

    }

    static notifyAdd(e) {

        const dataNotificacao = new Date(e.message.created_at);

        e.message.diasNotificacao = NotifyComponent.retornaQuantidadeDias(dataNotificacao, new Date());

        const temMensagem = this.arrayNotify.filter((element) => {
            return element.id === e.message.id;
        });

        if (temMensagem.length > 0) {

            this.arrayNotify.forEach((value) => {

                if (temMensagem[0].id === value.id) {

                    value.titulo = e.message.titulo;

                    value.mensagem = e.message.mensagem;

                    value.actions = e.message.actions;

                    value.widget = e.message.widget;

                    value.widget.forEach((valueTwo) => {

                        if (valueTwo.tipo === 'progress_bar') {

                            const progresso = valueTwo.progresso.split('/');

                            valueTwo.progressoMin = Number(progresso[0]);

                            valueTwo.progressoMax = Number(progresso[1]);

                            valueTwo.progressoPorc = Math.round(this.getPercentage(valueTwo.progressoMin, valueTwo.progressoMax));


                        }

                    });

                }

            });

        } else {

            this.arrayNotify.unshift(e.message);

            // this.toastService.info('Você tem uma nova notificação.');

        }

        this.ultimaAtualizacao = new Date();

    }


    static retornaQuantidadeDias(date1, date2) {

        const DifferenceInTime = date2.getTime() - date1.getTime();

        const DifferenceInDays = DifferenceInTime / (1000 * 3600 * 24);

        return Math.round(DifferenceInDays);

    }

    static retornaNotificacoes() {

        this.getNotificacoes(NotifyComponent.currentUserId).subscribe((response: any) => {

            response.forEach((value) => {

                let json;

                if (typeof value.mensagemJson === 'string' || value.mensagemJson instanceof String) {

                    json = JSON.parse(value.mensagemJson);

                } else {

                    json = value.mensagemJson;

                }

                json.created_at = value.created_at;

                json.updated_at = value.updated_at;

                json.widget.forEach((valueTwo) => {

                    if (valueTwo.tipo === 'progress_bar') {

                        const progresso = valueTwo.progresso.split('/');

                        valueTwo.progressoMin = Number(progresso[0]);

                        valueTwo.progressoMax = Number(progresso[1]);

                        valueTwo.progressoPorc = Math.round(this.getPercentage(valueTwo.progressoMin, valueTwo.progressoMax));

                    }

                });

                json.id = value.id;

                const dataNotificacao = new Date(value.created_at);

                json.diasNotificacao = NotifyComponent.retornaQuantidadeDias(dataNotificacao, new Date());

                NotifyComponent.arrayNotify.push(json);

            });

            NotifyComponent.arrayNotify.reverse();

        }, (response) => {

            if (response) {
                this.toastService.error(response.error.message);
            }

        });

    }

    static getNotificacoes(currentUserId) {

        return this.http.get(`${environment.apiUrl}/notificacoes/listar?usuario_id=` + currentUserId);

    }

    static getPercentage(campo1: number, campo2: number): number {
        const maior: any = campo1 > campo2 ? campo1 : campo2;
        const menor: any = campo1 < campo2 ? campo1 : campo2;
        return (menor / maior) * 100;
    }

    ngOnInit(): void {

        this.atualizadoEm();

        this.notifyService.connectNotify();

        this.connectNotifyVerify();

    }

    connectNotifyVerify(reconect = false) {
        if (!this.staticConnected && reconect) {
            console.log('Tentando conexão com websocket.');
            this.notifyService.connectNotify();
            setTimeout(() => {
                this.connectNotifyVerify(true);
            }, 60000);
        }
    }

    public notifyDimiss(notifyId = null): void {

        if (notifyId) {

            this.deletarNotificacao(notifyId);

        }

    }

    generateId() {
        return '_' + Math.random().toString(36).substr(2, 9);
    }

    atualizadoEm() {

        NotifyComponent.ultimaAtualizacao = new Date();

        setTimeout(() => {

            this.atualizadoEm();

        }, 60000);

    }

    deletarNotificacao(notificacaoId) {

        this.deleteNotificacoes(notificacaoId).subscribe((response: { message: string }) => {

                const message = response.message;

                this.toastService.success(message);

                NotifyComponent.arrayNotify = this.staticArrayNotify.filter((element) => {
                    return element.id !== notificacaoId;
                });

            },
            (response) => {

                this.toastService.error(response.error.message);

            }
        );

    }

    deleteNotificacoes(notificacaoId = null) {
        return this.http.delete(`${environment.apiUrl}/notificacoes/remover/${notificacaoId}`);
    }

    formatOne = (percent: number) => `${percent}%`;

    openTab(componentName: string, queryParams?: string, data?: {}) {

        const query = componentName.split('?');

        if (query[1]) {
            const partes = query[1].split('&');
            const filtros = {};
            partes.forEach((parte) => {
                const chave = parte.split('=')[0];
                const valor = parte.split('=')[1];
                filtros[chave] = valor;
            });

            data = filtros;
            componentName = query[0];
        }

        const component = findComponentByUrl(componentName);
        const url = buildUrl(component, queryParams);
        const newTab = new Tab(component.name, component.title, url, component.urlType, data);
        this.tabService.closeAndAddTab(newTab);
    }

}
