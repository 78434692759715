import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ComplianceTributosGruposComponent} from './compliance-tributos-grupos.component';
import {NzPageHeaderModule} from 'ng-zorro-antd/page-header';
import {NzLayoutModule} from "ng-zorro-antd/layout";
import {NzButtonModule} from "ng-zorro-antd/button";
import {NzCheckboxModule} from "ng-zorro-antd/checkbox";
import {NzIconModule} from "ng-zorro-antd/icon";
import {NzTableModule} from "ng-zorro-antd/table";
import {NzTagModule} from "ng-zorro-antd/tag";
import {NzToolTipModule} from "ng-zorro-antd/tooltip";
import {StatusComAnexosModule} from "@components/status-com-anexos/status-com-anexos.module";
import {UiModule} from "../../ui.module";
import {NzProgressModule} from "ng-zorro-antd/progress";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgxJdenticonModule} from "ngx-jdenticon";
import {NzBadgeModule} from "ng-zorro-antd/badge";


@NgModule({
  declarations: [ ComplianceTributosGruposComponent ],
    imports: [
        CommonModule,
        NzPageHeaderModule,
        NzLayoutModule,
        NzButtonModule,
        NzCheckboxModule,
        NzIconModule,
        NzTableModule,
        NzTagModule,
        NzToolTipModule,
        StatusComAnexosModule,
        UiModule,
        NzProgressModule,
        FormsModule,
        ReactiveFormsModule,
        NgxJdenticonModule,
        NzBadgeModule
    ]
})
export class ComplianceTributosGruposModule { }
