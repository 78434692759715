import {Component} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Pagination} from '@models/pagination.model';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {InboxService} from './inbox.service';
import {ToastrService} from 'ngx-toastr';
import {Tab} from '@models/tab.model';
import {TabService} from '@services/tab.service';
import {buildUrl, findComponentByUrl} from '../../shared/components-helper';
import {Subscription} from 'rxjs';

interface FormStack {
    modalVisible: boolean;
    formGroup: FormGroup;
}

@Component({
    selector: 'app-inbox',
    templateUrl: './inbox.component.html',
    styleUrls: ['./inbox.component.scss']
})

export class InboxComponent {

    requestCabecalho: Subscription;
    requestTabela: Subscription;
    requestAnexos: Subscription;

    loadingCabecalho = true;
    loadingAnexos = null;
    loadingReprocessarAnexos = {};
    loadingRemoverAnexos = {};
    statusCard = false;
    qtdFiltrosAtivosCabecalho = 0;
    qtdFiltrosAtivos = 0;

    formFiltrar: FormStack;
    formFiltrarCabecalho: FormStack;

    pagination = new Pagination();
    loadingTable = false;
    currentParams: NzTableQueryParams = {
        pageIndex: 1,
        pageSize: 50,
        sort: [],
        filter: [],
    };
    currentParamsCabecalho: NzTableQueryParams = {
        pageIndex: 1,
        pageSize: 50,
        sort: [],
        filter: [],
    };
    currentSearch: string;

    itemsCabecalho: any = {};
    items = [];
    anexos = [];

    modalAnexosVisible = false;

    statusEmail: any = {
        0: {cor: 'rgba(120,120,120,0.75)', descricao: 'Não processado'},
        1: {cor: 'rgba(255,216,57,0.9)', descricao: 'Em processamento'},
        2: {cor: 'rgba(76,175,80,0.9)', descricao: 'Processado'},
        3: {cor: 'rgba(231,56,56,0.75)', descricao: 'Erro'},
        4: {cor: 'rgba(2,136,209,0.75)', descricao: 'Identificado'},
        5: {cor: 'rgba(255,152,0,0.75)', descricao: 'Não identificado'},
    };


    statusAnexos: any = {
        0: {cor: 'rgba(255,216,57,0.9)', descricao: 'Pendente'},
        2: {cor: 'rgba(2,136,209,0.75)', descricao: 'Identificado'},
        4: {cor: 'rgba(255,152,0,0.75)', descricao: 'Não identificado'},
        5: {cor: 'rgba(231,56,56,0.75)', descricao: 'Ignorado'},
    };

    constructor(
        private fb: FormBuilder,
        private service: InboxService,
        private toastService: ToastrService,
        private tabService: TabService,
    ) {

        this.formFiltrar = {
            modalVisible: false,
            formGroup: this.fb.group({
                status: [null, null],
                dias: [null, null],
                temAnexo: [null, null],
                remetente: [null, null],
            })
        };

        this.formFiltrarCabecalho = {
            modalVisible: false,
            formGroup: this.fb.group({
                dias: [null, null],
                status: [null, null]
            })
        };
    }

    ngOnInit() {
        this.getCabecalho(this.currentParamsCabecalho);
    }

    clickEvent() {
        this.statusCard = !this.statusCard;
    }

    modalFiltrar(visible) {
        this.formFiltrar.modalVisible = visible;
    }

    modalFiltrarCabecalho(visible) {
        this.formFiltrarCabecalho.modalVisible = visible;
    }

    confirmaFiltrar(): void {

        this.currentParams.pageIndex = 1;

        this.queryTable(this.currentParams, this.currentSearch);

        this.modalFiltrar(false);

    }

    confirmaFiltrarCabecalho(): void {

        this.currentParamsCabecalho.filter = [];

        this.currentParamsCabecalho.pageIndex = 1;

        for (const [key, value] of Object.entries(this.formFiltrarCabecalho.formGroup.value)) {

            if (value && key) {
                this.currentParamsCabecalho.filter.push({key, value});
            }
        }

        this.getCabecalho(this.currentParamsCabecalho);

        this.modalFiltrarCabecalho(false);

    }

    buscar() {
        this.currentParams.pageIndex = 1;
        this.queryTable(this.currentParams, this.currentSearch);
    }

    btnResetSearch() {

        this.currentSearch = null;
        this.currentParams = {
            pageIndex: 1,
            pageSize: 50,
            sort: [],
            filter: [],
        };

        this.formFiltrar.formGroup.reset();

        this.queryTable(this.currentParams, this.currentSearch);

    }

    calculaBadgeFiltros(): void {

        this.qtdFiltrosAtivos = 0;

        if (typeof this.formFiltrar !== 'undefined') {
            for (const [chave, valor] of Object.entries(this.formFiltrar.formGroup.value)) {

                if (valor && chave && !['dias'].includes(chave)) {
                    this.qtdFiltrosAtivos += 1;
                }
            }
        }

    }

    calculaBadgeFiltrosCabecalho(): void {

        this.qtdFiltrosAtivosCabecalho = 0;

        if (typeof this.formFiltrarCabecalho !== 'undefined') {
            for (const [chave, valor] of Object.entries(this.formFiltrarCabecalho.formGroup.value)) {

                if (valor && chave) {
                    this.qtdFiltrosAtivosCabecalho += 1;
                }
            }
        }

    }

    listByTable(params: NzTableQueryParams) {
        let buscar = false;

        if (params.pageIndex === this.pagination.current_page) {
            params.sort.forEach(s => {
                if (s.value) {
                    buscar = true;
                }
            });
        }

        if ((buscar || params.pageIndex === 1 && this.pagination.current_page > 1)
            || params.pageIndex > 1
            && params.pageIndex !== this.pagination.current_page) {
            this.queryTable(params);
            this.pagination.current_page = params.pageIndex;
        }

    }


    queryTable(params: NzTableQueryParams, search: string = null): void {

        this.requestTabela?.unsubscribe();

        this.currentParams = params;

        if (search) {
            this.currentSearch = search;
        }

        // Para enviar
        if (this.formFiltrarCabecalho.formGroup.get('status').value) {
            this.formFiltrar.formGroup.get('status').setValue(this.formFiltrarCabecalho.formGroup.get('status').value);
        }

        this.currentParams.filter = [];

        const filtros: any = this.formFiltrar.formGroup.value;

        if (this.formFiltrarCabecalho.formGroup.get('dias').value) {
            filtros.dias = this.formFiltrarCabecalho.formGroup.get('dias').value;
        } else {
            delete filtros.dias;
        }

        for (const [chave, valor] of Object.entries(filtros)) {
            if (valor) {
                this.currentParams.filter.push({key: chave, value: valor});
            }
        }

        // Para não contar
        if (this.formFiltrarCabecalho.formGroup.get('status').value) {
            this.formFiltrar.formGroup.get('status').setValue(null);
        }

        this.calculaBadgeFiltros();

        this.loadingTable = true;

        if (this.currentSearch) {
            this.currentParams.filter.push({key: 'procurar', value: this.currentSearch});
        }

        this.requestTabela = this.service.listar(this.currentParams).subscribe(
            {
                next: (response) => {

                    this.items = [];

                    this.items = response.data;

                    this.loadingTable = false;

                    this.pagination = new Pagination(
                        response?.per_page || 50,
                        response?.current_page || 1,
                        response?.last_page || 1,
                        response?.total || 50,
                        response?.from || 1,
                        response?.to || 1);


                }, error: (error) => {
                    this.loadingTable = false;
                    this.toastService.error(error.error.message);
                }
            });
    }

    getCabecalho(params: NzTableQueryParams) {
        this.loadingCabecalho = true;

        this.requestCabecalho?.unsubscribe();
        this.requestTabela?.unsubscribe();

        this.calculaBadgeFiltrosCabecalho();

        this.requestCabecalho = this.service.getCabecalho(this.currentParamsCabecalho).subscribe({
            next: (res) => {
                this.itemsCabecalho = res;
                this.queryTable(this.currentParams, this.currentSearch);
                this.loadingCabecalho = false;
            },
            error: (err) => {
                this.loadingCabecalho = false;
                this.toastService.error(err.error.message);

            }
        });
    }

    getAnexos(id) {
        this.loadingAnexos = id;

        this.requestAnexos?.unsubscribe();

        this.calculaBadgeFiltrosCabecalho();

        this.requestAnexos = this.service.getAnexos(id).subscribe({
            next: (res) => {
                this.anexos = res;
                this.showModalAnexos(true);
                this.loadingAnexos = null;
            },
            error: (err) => {
                this.loadingAnexos = null;
                this.toastService.error(err.error.message);

            }
        });
    }

    showModalAnexos(visible: boolean) {
        this.modalAnexosVisible = visible;
    }

    filterCabByCard(valor, chave: 'status' | 'dias') {
        return;

        if (this.formFiltrarCabecalho.formGroup.get(chave).value === valor) {
            this.formFiltrarCabecalho.formGroup.get(chave).setValue(null);
        } else {
            this.formFiltrarCabecalho.formGroup.get(chave).setValue(valor);
        }

        this.confirmaFiltrarCabecalho();
    }

    filterTableByCard(valor, chave: 'status') {
        return;

        if (this.formFiltrarCabecalho.formGroup.get('status').value) {
            this.toastService.info('Status filtrado no cabecalho da tela.');
            return;
        }

        if (this.formFiltrar.formGroup.get(chave).value === valor) {
            this.formFiltrar.formGroup.get(chave).setValue(null);
        } else {
            this.formFiltrar.formGroup.get(chave).setValue(valor);
        }

        this.confirmaFiltrar();
    }

    reprocessarAnexo(id: string) {

        this.loadingReprocessarAnexos[id] = true;

        this.service.reprocessarAnexo(id).subscribe({
            next: (res: any) => {

                this.loadingReprocessarAnexos[id] = false;
                this.toastService.success(res.message);

            },
            error: (err) => {

                this.loadingReprocessarAnexos[id] = false;
                this.toastService.error(err.error.message);

            }
        });
    }

    removerAnexo(id: string) {

        this.loadingRemoverAnexos[id] = true;

        this.service.removerAnexo(id).subscribe({
            next: (res: any) => {

                this.loadingRemoverAnexos[id] = false;
                this.toastService.success(res.message);

            },
            error: (err) => {

                this.loadingRemoverAnexos[id] = false;
                this.toastService.error(err.error.message);

            }
        });

    }

    openTab(componentName: string, queryParams?: string, data?: {}) {

        const component = findComponentByUrl(componentName);
        const url = buildUrl(component, queryParams);
        const newTab = new Tab(component.name, component.title, url, component.urlType, data);

        this.showModalAnexos(false);

        this.tabService.closeAndAddTab(newTab);

    }

}
