<nz-card [ngClass]="[status ? 'card-fullscreen' : '', !cardLoadingTabs ? 'ant-card-table' : '']"
         [nzLoading]="cardLoadingTabs">
    <nz-card-tab>
        <nz-tabset [nzSize]="'large'" [nzTabBarExtraContent]="tabsExtra"
                   [(nzSelectedIndex)]="tabNumber" (click)="changeTabs()">
            <nz-tab nzTitle="{{obrigacao}}"
                    *ngFor="let obrigacao of arrayImpostos"></nz-tab>
        </nz-tabset>
    </nz-card-tab>
    <nz-table [nzData]="arrayTableObrigacoesEvolucao"
              [nzFrontPagination]="false"
              [nzShowPagination]="false"
              [nzFooter]="footer"
              [nzScroll]="{ x: 'auto' }"
              nzSize="small" class="font-doze"
              nzBordered>
        <thead>
        <tr>
            <th colspan="2"></th>
            <th colspan="5">Obrigações Acessórias (Oficiais)</th>
            <th colspan="4">Pagamentos</th>
        </tr>
        <tr>
            <th #column1></th>
            <th #column2 nzWidth="34"></th>
            <th #column3 style="text-align: center;">Min (R$)</th>
            <th #column4 style="text-align: center;">Max (R$)</th>
            <th #column5 style="text-align: center;">Qtde</th>
            <th #column6 style="text-align: center;">Diferença (R$)</th>
            <th #column7 style="text-align: center;">Evolução</th>
            <th #column8 style="text-align: center;">Valor (R$)</th>
            <th #column9 style="text-align: center;">Qtde</th>
            <th #column10 style="text-align: center;">Evolução</th>
            <th #column11 style="text-align: center;">Pagto x Max (R$)</th>
        </tr>
        </thead>
        <tbody id="tbody-sparkline">
        <ng-container class="ant-table-tbody" *ngFor="let data of arrayTableObrigacoesEvolucao">
            <tr>
                <td [ngStyle]="{'width': '200px'}">
                    <h5 nz-typography class="ant-card-loading-block" style="display: none;"></h5>
                    <h5 nz-typography>
                        {{ data.grupo }}
                    </h5>
                </td>
                <td [nzExpand]="expandSet.has(data.id)"
                    (nzExpandChange)="onExpandChange(data.id, $event)"
                ></td>
                <td style="text-align: right;">
                    <div *ngIf="data.obrigacoesAcessoriasTotalMin < 0">
                        {{ data.obrigacoesAcessoriasTotalMin * -1 | number }}
                        <span>(C)</span>
                    </div>
                    <div *ngIf="data.obrigacoesAcessoriasTotalMin > 0">
                        {{ data.obrigacoesAcessoriasTotalMin | number }}
                        <span>(D)</span>
                    </div>
                    <div *ngIf="data.obrigacoesAcessoriasTotalMin == 0">
                        {{ data.obrigacoesAcessoriasTotalMin | number }}
                    </div>
                    <div *ngIf="data.obrigacoesAcessoriasTotalMin === null">-</div>
                </td>
                <td style="text-align: right;">

                    <div *ngIf="data.obrigacoesAcessoriasTotalMax < 0">
                        {{ data.obrigacoesAcessoriasTotalMax * -1 | number }}
                        <span>(C)</span>
                    </div>
                    <div *ngIf="data.obrigacoesAcessoriasTotalMax > 0">
                        {{ data.obrigacoesAcessoriasTotalMax | number }}
                        <span>(D)</span>
                    </div>
                    <div *ngIf="data.obrigacoesAcessoriasTotalMax == 0">
                        {{ data.obrigacoesAcessoriasTotalMax | number }}
                    </div>
                    <div *ngIf="data.obrigacoesAcessoriasTotalMax === null">-</div>
                </td>
                <td style="text-align: center;">{{ data.obrigacoesAcessoriasQtde }}</td>
                <td style="text-align: right;">

                     <span *ngIf="data.obrigacoesAcessoriasMinMax <= valorTolerancia" nz-tooltip
                           nzTooltipPlacement="bottom"
                           [nzTooltipTitle]="'Tolerância '+ tipoTolerancia + ' de R$ '+ valorTolerancia+',00'">
                            {{ (data.obrigacoesAcessoriasMinMax == null ? "-" : data.obrigacoesAcessoriasMinMax | currency:'BRL').replace('R$', '') }}
                     </span>

                    <span *ngIf="data.obrigacoesAcessoriasMinMax > valorTolerancia"
                          [ngStyle]="{'color':'red'}">
                            {{ (data.obrigacoesAcessoriasMinMax == null ? "-" : data.obrigacoesAcessoriasMinMax | currency:'BRL').replace('R$', '') }}
                     </span>
                </td>
                <td>
                    <div class="d-flex justify-content-center">
                        <app-spark-line
                                *ngIf="somaValores(data.obrigacoesAcessoriasEvolucao.min) > 0; else sparkLineFake"
                                [name]="'Evolução'"
                                [data]="data.obrigacoesAcessoriasEvolucao.min">
                        </app-spark-line>
                    </div>
                </td>
                <td style="text-align: right;">
                    <div *ngIf="data.pagamentosValor < 0">
                        {{ (data.pagamentosValor * -1 | currency: 'BRL').replace('R$', '') }}

                    </div>
                    <div *ngIf="data.pagamentosValor > 0">
                        {{ (data.pagamentosValor | currency: 'BRL').replace('R$', '') }}

                    </div>
                    <div *ngIf="data.pagamentosValor == 0">
                        {{ (data.pagamentosValor | currency: 'BRL').replace('R$', '') }}
                    </div>
                    <div *ngIf="data.pagamentosValor == null">-</div>
                </td>
                <td style="text-align: center;">{{ data.pagamentosQtde }}</td>
                <td>
                    <div class="d-flex justify-content-center">
                        <app-spark-line *ngIf="somaValores(data.pagamentosEvolucao) > 0; else sparkLineFake"
                                        [name]="'Evolução'" [data]="data.pagamentosEvolucao">

                        </app-spark-line>
                    </div>
                </td>
                <td style="text-align: right;">

                    <span *ngIf="data.pagtoMax < 0" [ngStyle]="{'color':'red'}" nz-typography nzType="danger">
                        {{ (data.pagtoMax | currency: 'BRL').replace('R$', '') }}
                    </span>
                    <span *ngIf="data.pagtoMax > 0">
                        {{ (data.pagtoMax | currency: 'BRL').replace('R$', '') }}
                    </span>
                    <span *ngIf="data.pagtoMax == 0">
                        {{ (data.pagtoMax | currency: 'BRL').replace('R$', '') }}
                    </span>
                    <span *ngIf="data.pagtoMax == null">-</span>
                </td>
            </tr>
            <tr [nzExpand]="expandSet.has(data.id)" *ngFor="let unidade of data.unidades">
                <td [ngStyle]="{
                        'width': '200px',
                        padding: '8px',
                        'overflow-wrap': 'break-word'
                        }">
                    <!--<h5 nz-typography class="ant-card-loading-block" (click)="openTab('ComplianceTributosEmpresasComponent', null, unidade.id)">
                    </h5>-->
                    <span class="link-name"
                          (click)="openTab('ComplianceTributosEmpresasComponent', null, {
                          unidade: unidade.id, ano: anoAtivo, obrigacao: obrigacaoSelecionada, ie: unidade.ie
                          })">
                        {{ unidade.nome }}
                        <span *ngIf="unidade.ie"
                              style="color: rgb(24, 144, 255); cursor: pointer" nz-icon
                              nzType="info-circle" nzTheme="outline" nz-tooltip="IE: {{unidade.ie}}">
                        </span> <br>
                        ({{ unidade.cnpj | cpfCnpj }})
                    </span>
                </td>
                <td [ngStyle]="{
                        'width': column2Width + 'px',
                        padding: '8px',
                        'overflow-wrap': 'break-word'
                        }"></td>
                <td [ngStyle]="{
                        'width': column3Width + 'px',
                        padding: '8px',
                        'overflow-wrap': 'break-word',
                        'text-align': 'right'
                        }">
                    <div *ngIf="unidade.obrigacoesAcessoriasTotalMin < 0">
                        {{ (unidade.obrigacoesAcessoriasTotalMin * -1 | currency:'BRL').replace('R$', '') }}
                        <span>(C)</span>
                    </div>
                    <div *ngIf="unidade.obrigacoesAcessoriasTotalMin > 0">
                        {{ (unidade.obrigacoesAcessoriasTotalMin | currency:'BRL').replace('R$', '') }}
                        <span>(D)</span>
                    </div>
                    <div *ngIf="unidade.obrigacoesAcessoriasTotalMin == 0">
                        {{ (unidade.obrigacoesAcessoriasTotalMin | currency:'BRL').replace('R$', '') }}
                    </div>
                    <div *ngIf="unidade.obrigacoesAcessoriasTotalMin === null">-</div>
                </td>
                <td [ngStyle]="{
                        'width': column4Width + 'px',
                        padding: '8px',
                        'overflow-wrap': 'break-word',
                        'text-align': 'right'
                        }">
                    <div *ngIf="unidade.obrigacoesAcessoriasTotalMax < 0">
                        {{ (unidade.obrigacoesAcessoriasTotalMax * -1 | currency:'BRL').replace('R$', '') }}
                        <span>(C)</span>
                    </div>
                    <div *ngIf="unidade.obrigacoesAcessoriasTotalMax > 0">
                        {{ (unidade.obrigacoesAcessoriasTotalMax | currency:'BRL').replace('R$', '') }}
                        <span>(D)</span>
                    </div>
                    <div *ngIf="unidade.obrigacoesAcessoriasTotalMax == 0">
                        {{ (unidade.obrigacoesAcessoriasTotalMax | currency:'BRL').replace('R$', '') }}
                    </div>
                    <div *ngIf="unidade.obrigacoesAcessoriasTotalMax === null">-</div>
                </td>
                <td [ngStyle]="{
                        'width': column5Width + 'px',
                        padding: '8px',
                        'overflow-wrap': 'break-word',
                        'text-align': 'center'
                        }">
                    <div *ngIf="unidade.obrigacoesAcessoriasQtde < 0">
                        {{ unidade.obrigacoesAcessoriasQtde * -1 }}
                    </div>
                    <div *ngIf="unidade.obrigacoesAcessoriasQtde >= 0">
                        {{ unidade.obrigacoesAcessoriasQtde }}
                    </div>
                    <div *ngIf="unidade.obrigacoesAcessoriasQtde === null">-</div>
                </td>
                <td [ngStyle]="{
                        'width': column6Width + 'px',
                        position: 'relative',
                        padding: '8px',
                        'overflow-wrap': 'break-word',
                        'text-align': 'right'
                        }">

                        <span *ngIf="unidade.obrigacoesAcessoriasMinMax <= valorTolerancia"
                              nz-tooltip
                              nzTooltipPlacement="bottom"
                              [nzTooltipTitle]="'Tolerância '+ tipoTolerancia + ' de R$ '+ valorTolerancia+',00'">
                            {{ (unidade.obrigacoesAcessoriasMinMax === null ? "-" : unidade.obrigacoesAcessoriasMinMax | currency:'BRL').replace('R$', '') }}
                        </span>

                    <span *ngIf="unidade.obrigacoesAcessoriasMinMax > valorTolerancia"
                          [ngStyle]="{'color':'red'}">
                                {{ (unidade.obrigacoesAcessoriasMinMax === null ? "-" : unidade.obrigacoesAcessoriasMinMax | currency:'BRL').replace('R$', '') }}
                        </span>

                </td>
                <td [ngStyle]="{
                        'width': column7Width + 'px',
                        padding: '8px',
                        'overflow-wrap': 'break-word'
                        }">
                    <div class="d-flex justify-content-center">
                        <app-spark-line
                                *ngIf="somaValores(unidade.obrigacoesAcessoriasEvolucao.min) > 0; else sparkLineFake"
                                [name]="'Evolução'" [data]="unidade.obrigacoesAcessoriasEvolucao.min">
                        </app-spark-line>
                    </div>
                </td>
                <td [ngStyle]="{
                        'width': column8Width + 'px',
                        padding: '8px',
                        'overflow-wrap': 'break-word',
                        'text-align': 'right'
                        }">
                    <div *ngIf="unidade.pagamentosValor < 0">
                        {{ (unidade.pagamentosValor * -1 | currency:'BRL').replace('R$', '') }}

                    </div>
                    <div *ngIf="unidade.pagamentosValor > 0">
                        {{ (unidade.pagamentosValor | currency:'BRL').replace('R$', '') }}

                    </div>
                    <div *ngIf="unidade.pagamentosValor == 0">
                        {{ (unidade.pagamentosValor | currency:'BRL').replace('R$', '') }}
                    </div>
                    <div *ngIf="unidade.pagamentosValor === null">-</div>
                </td>
                <td [ngStyle]="{
                        'width': column9Width + 'px',
                        position: 'relative',
                        padding: '8px',
                        'overflow-wrap': 'break-word',
                        'text-align': 'center'
                        }">
                    <div *ngIf="unidade.pagamentosQtde < 0">
                        {{ data.pagamentosQtde * -1 }}
                    </div>
                    <div *ngIf="unidade.pagamentosQtde >= 0">
                        {{ unidade.pagamentosQtde }}
                    </div>
                    <div *ngIf="unidade.pagamentosQtde === null">-</div>
                </td>
                <td [ngStyle]="{
                        'width': column10Width + 'px',
                        position: 'relative',
                        padding: '8px',
                        'overflow-wrap': 'break-word'
                        }">
                    <div class="d-flex justify-content-center">
                        <app-spark-line *ngIf="somaValores(unidade.pagamentosEvolucao) > 0; else sparkLineFake"
                                        [name]="'Evolução'" [data]="unidade.pagamentosEvolucao">
                        </app-spark-line>
                    </div>
                </td>
                <td [ngStyle]="{
                        'width': column11Width + 'px',
                        padding: '8px',
                        'overflow-wrap': 'break-word',
                        'text-align' : 'right'
                        }">
                    <span *ngIf="unidade.pagtoMax <= valorTolerancia && unidade.pagtoMax !== 0"
                          nz-tooltip
                          nzTooltipPlacement="bottom"
                          [nzTooltipTitle]="'Tolerância '+ tipoTolerancia + ' de R$ '+ valorTolerancia+',00'">
                            {{ (unidade.pagtoMax === null ? "-" : unidade.pagtoMax | currency:'BRL').replace('R$', '') }}
                        </span>
                    <span *ngIf="unidade.pagtoMax > valorTolerancia"
                          [ngStyle]="{'color':'red'}">
                                {{ (unidade.pagtoMax === null ? "-" : unidade.pagtoMax | currency:'BRL').replace('R$', '') }}
                        </span>
                    <span *ngIf="unidade.pagtoMax == 0">
                        {{ (unidade.pagtoMax | currency:'BRL').replace('R$', '') }}
                    </span>
                    <span *ngIf="unidade.pagtoMax === null">-</span>
                </td>
            </tr>
        </ng-container>
        </tbody>
        <ng-template style="margin-top: -10px" #footer>{{ totalRegistros }} registros</ng-template>
    </nz-table>
</nz-card>

<ng-template #tabsExtra>
    <div class="d-flex align-items-center">
        <div nz-col nzFlex="52px">
            <button (click)="exibirTudo(!expandAll)" nz-button>
                <em *ngIf="expandAll" class="fas fa-angle-double-down"></em>
                <em *ngIf="!expandAll" class="fas fa-angle-double-up"></em>
            </button>
        </div>
        <nz-badge [nzCount]="qtdFiltrosAtivos"
                  [nzStyle]="{ backgroundColor: '#52c41a', 'margin-right' : '15px'}">
            <button nz-button nzType="primary" style="margin-right: 10px;" (click)="showModalFiltrar(true)">
                <i nz-icon nzType="search"></i>
                Filtros
            </button>
        </nz-badge>
        <button nz-button [nzType]="'primary'"
                style="margin-right: 8px;" (click)="modalExportar(true);">
            <span>Exportar Planilha</span>
        </button>
        <button nz-button [nzType]="'default'" (click)="clickEvent()">
            <i class="fas" [ngClass]="!status ? 'fa-expand' : 'fa-compress'"></i>
        </button>
    </div>
</ng-template>

<app-exportar-tabela #componentExport [data]="dataExport"></app-exportar-tabela>

<ng-template #sparkLineFake>
    <span style="border-bottom: 1px solid rgba(124,181,236, 0.5); width: 120px; margin-top: 10px"></span>
</ng-template>

<nz-modal [(nzVisible)]="formFiltrar.modalVisible"
          [nzTitle]="'Filtros Avançados'" [nzClosable]="true"
          (nzOnCancel)="showModalFiltrar(false)"
          [nzWidth]="648">
    <nz-row *nzModalContent>
        <nz-col [nzSpan]="24">
            <form nz-form [nzNoColon]="true" [formGroup]="formFiltrar.formGroup">
                <nz-form-item>
                    <nz-form-label [nzSpan]="5">Empresa:</nz-form-label>
                    <nz-form-control [nzSpan]="14"
                                     nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrar.formGroup.value.empresa_id">
                        <nz-select nzShowSearch nzAllowClear
                                   nzPlaceHolder="Selecione"
                                   formControlName="empresa_id"
                                   [(ngModel)]="formFiltrar.formGroup.value.empresa_id">
                            <nz-option *ngFor="let opt of arraySelectEmpresa"
                                       [nzLabel]="opt.descricao"
                                       [nzValue]="opt.id"
                            ></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="5">CNPJ</nz-form-label>
                    <nz-form-control [nzSpan]="14"
                                     nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrar.formGroup.value.empresa_id">
                        <nz-select nzShowSearch
                                   nzAllowClear
                                   nzPlaceHolder="Selecione"
                                   formControlName="empresa_id"
                                   [(ngModel)]="formFiltrar.formGroup.value.empresa_id">
                            <nz-option *ngFor="let opt of arraySelectCnpj"
                                       [nzLabel]="opt.descricao | cpfCnpj"
                                       [nzValue]="opt.id"
                            ></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="5">IE</nz-form-label>
                    <nz-form-control [nzSpan]="14"
                                     nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrar.formGroup.value.ie !== '' && formFiltrar.formGroup.value.ie !== null">
                        <input nz-input type="text" formControlName="ie" maxlength="20" size="60" nzAllowClear/>
                    </nz-form-control>
                </nz-form-item>
            </form>
        </nz-col>
    </nz-row>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="showModalFiltrar(false)">Cancelar</button>
        <button nz-button nzType="primary" (click)="filtrar()">Filtrar</button>
    </div>
</nz-modal>