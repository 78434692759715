import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {Utils} from '../../../shared/utils';
import * as fileSaver from 'file-saver-es';
import {Tab} from '@models/tab.model';
import {TabService} from '@services/tab.service';
import {buildUrl, getComponentConfigByName} from '../../../shared/components-helper';
import {TabHandlerInterface} from '../../../shared/interfaces/tab-handler.interface';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import {Response} from '@models/response.model';
import {Usuario} from '@models/usuario.model';
import {UsuarioService} from '@services/usuario.service';
import Visibilidade from '@models/visibilidade.model';
import {VisibilidadeService} from '@services/visibilidade.service';
import {Perfil} from '@models/perfil.model';
import {PerfilService} from '@services/perfil.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {Pagination} from '@models/pagination.model';
import * as Highcharts from 'highcharts/highcharts';
import HighchartsMore from 'highcharts/highcharts-more.src';
import HighchartsSolidGauge from 'highcharts/modules/solid-gauge';
import {CheckEfdContribuicoesTributacaoDetalhesService} from './checkEfdContribuicoesTributacaoDetalhes.service';

HighchartsMore(Highcharts);
HighchartsSolidGauge(Highcharts);

interface FormStack {
    modalVisible: boolean;
    formGroup: UntypedFormGroup;
}

@Component({
    selector: 'app-cadastros-checkEfdContribuicoesTributacaoDetalhes',
    templateUrl: './checkEfdContribuicoesTributacaoDetalhes.component.html',
    styleUrls: ['./checkEfdContribuicoesTributacaoDetalhes.component.scss']
})
export class CheckEfdContribuicoesTributacaoDetalhesComponent extends AbstractListTable<Usuario> implements OnInit, TabHandlerInterface {
    readonly registerLink = '/usuarios/cadastrar';

    @Input() data;
    @Output() generalFilterHandler = new EventEmitter<string>();

    currentParams: NzTableQueryParams = {
        pageIndex: 1,
        pageSize: 50,
        sort: [],
        filter: []
    };

    first = true;

    dataProfileForm: Perfil[];
    profileOptions: { label: string; value: string }[] = [];
    displayProfileModal = false;
    selectedProfile;

    newVisibilidadeModal = false;
    displayVisibilidadeModal = false;
    visibilidadeOptions: { label: string; value: string }[] = [];
    selectedVisibilidades: string[] = [];
    limparVisibilidades = false;
    newVisibilidade = '';
    dataVisibilityForm: Response<Visibilidade>;

    token;
    exportUrl;

    modalCadastrarVisible = false;
    formCadastrar: UntypedFormGroup;
    cadastrando = false;

    HighchartsSolidGauge: typeof Highcharts = Highcharts;

    chartSolidGaugeOptions: Highcharts.Options;
    chartColumnOptions: Highcharts.Options;

    updateFlag: boolean;

    selectedValue = new Date('2017-01-25');
    dadosAnalise: any = {};
    contadores: any = {};

    divergencias: any[] = [];

    formFiltrar: FormStack;

    formExportar: FormStack;
    exportSubItens = false;

    pesquisar = '';

    comboChaves = [];
    comboNumeros = [];
    comboSeries = [];
    comboDestinatariosEmitentes = [];
    comboDocumentos = [];
    comboOperacoes = [];
    comboSituacoes = [];
    comboStatusProcessamento = [];
    comboStatusDivergencia = [];
    comboUFs: any = [];

    cardNotaVisible = true;
    cardQuebraVisible = false;

    quebras: any[] = [];

    expandSet = new Set<number>();
    expandLoading = {};
    arrayTableItensNota = [];

    loadings: { exportando: boolean } = {exportando: false};

    constructor(
        private fb: UntypedFormBuilder,
        private usuarioService: UsuarioService,
        private toastService: ToastrService,
        private visibilidadeService: VisibilidadeService,
        private service: CheckEfdContribuicoesTributacaoDetalhesService,
        private perfilService: PerfilService,
        private cdr: ChangeDetectorRef,
        private tabService: TabService) {

        super(usuarioService, Usuario, toastService);

        this.loading = false;

        this.formFiltrar = {
            modalVisible: false,
            formGroup: this.fb.group({
                emissaoData: [null, null],
                chave: [null, null],
                numero: [null, null],
                serie: [null, null],
                destinatarioEmitente: [null, null],
                documento: [null, null],
                operacao: [null, null],
                situacao: [null, null],
                statusProcessamento: [null, null],
                statusDivergencia: [null, null],
            })
        };

        this.formExportar = {
            modalVisible: false,
            formGroup: this.fb.group({
                tipo: [null, Validators.required],
            })
        };

        this.service.retornaEstados().subscribe((retorno: any) => {
            retorno.estados.forEach((value) => {
                value.label = value.uf + ' - ' + value.nome;
                value.key = value.uf;
            });
            this.comboUFs = retorno.estados;
        });


    }

    ngOnInit() {
        if (!this.data || !this.data.id) {
            this.data = {};
            this.data.id = localStorage.getItem('param');
        }
        this.retornaCabecalho();
        this.retornaCombosFiltros();
    }

    retornaCombosFiltros(): void {

        this.service.retornaCombosFiltros(this.data.id).subscribe((response) => {

            Object.entries(response.chaves).forEach(
                ([key, value]) => {
                    const dados = {id: key, label: value};
                    this.comboChaves.push(dados);
                }
            );

            response.numeros.forEach((value) => {
                    const dados = {id: value, label: value};
                    this.comboNumeros.push(dados);
                }
            );

            response.series.forEach((value) => {
                    const dados = {id: value, label: value};
                    this.comboSeries.push(dados);
                }
            );

            Object.entries(response.destinatarios).forEach(
                ([key, value]) => {
                    const destinatariosEmitentes = {id: key, label: value};
                    this.comboDestinatariosEmitentes.push(destinatariosEmitentes);
                    const documentos = {id: key, label: key};
                    this.comboDocumentos.push(documentos);
                }
            );

            response.operacoes.forEach((value) => {
                    const chave = {id: value, label: value};
                    this.comboOperacoes.push(chave);
                }
            );

            Object.entries(response.situacoes).forEach(
                ([key, value]) => {
                    const destinatariosEmitentes = {id: key, label: value};
                    this.comboSituacoes.push(destinatariosEmitentes);
                }
            );

            response.status.forEach((value, key) => {
                    const status = {id: key, label: value};
                    this.comboStatusProcessamento.push(status);
                }
            );

            Object.entries(response.statusItens).forEach(
                ([key, value]) => {
                    const statusDivergencia = {id: key, label: value};
                    this.comboStatusDivergencia.push(statusDivergencia);
                }
            );

        });

    }

    retornaCabecalho(): void {

        this.service.retornaCabecalho(this.data.id).subscribe((response) => {
            this.dadosAnalise = response;
            this.retornaContadores();
        });

    }

    retornaContadores(): void {

        this.service.retornaContadores(this.data.id).subscribe((response) => {

            this.contadores = response;

            this.divergencias = [];

        });

    }


    loadChartData(): void {

        this.chartColumnOptions = {
            chart: {
                type: 'column'
            },
            title: undefined,
            xAxis: {
                title: {
                    text: 'Entregas no prazo'
                },
                categories: [
                    '5',
                    '10',
                    '15',
                    '20',
                    '25',
                    '30'
                ],
                crosshair: true
            },
            yAxis: {
                title: undefined,
                min: 0
            },
            tooltip: {
                headerFormat: '<span style="font-size:10px">Dia {point.key}</span><table>',
                pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                    '<td style="padding:0"><b>{point.y}</b></td></tr>',
                footerFormat: '</table>',
                shared: true,
                useHTML: true
            },
            legend: {
                enabled: false
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }
            },
            series: [{
                type: undefined,
                name: 'Entregas',
                data: [49, 71, 106, 129, 144, 176]

            }]
        };

        this.chartSolidGaugeOptions = {
            chart: {
                type: 'solidgauge',
                height: 180,
                backgroundColor: 'rgba(0,0,0,0)',
            },
            title: {
                text: undefined,
            },
            pane: {
                center: ['50%', '60%'],
                size: '120%',
                startAngle: -90,
                endAngle: 90,
            },
            tooltip: {
                enabled: false
            },
            plotOptions: {
                solidgauge: {
                    dataLabels: {
                        y: 5,
                        borderWidth: 0,
                        useHTML: true
                    }
                }
            },
            yAxis: {
                min: 0,
                max: 100,
                tickPositions: [0, 100],
                stops: [
                    [0.1, '#547df9'], // light blue
                    [0.5, '#547df9'], // blue
                    [0.9, '#547df9'] // dark blue
                ],
                lineWidth: 1,
                minorTickInterval: null,
                tickAmount: 2,
                title: {
                    y: -50
                },
                labels: {
                    y: 16
                }
            },
            credits: {
                enabled: false
            },
            exporting: {
                enabled: true,
            },
            series: [{
                type: undefined,
                name: '',
                data: [78],
                dataLabels: {
                    format: '<div style="text-align:center; top: 0px;position: relative;">' +
                        '<span style="font-size:28px;color: black;">' +
                        '{y}%' +
                        '</span>' +
                        '</div>',
                    y: 45,
                },
                tooltip: {
                    valueSuffix: ' km/h'
                }
            }]
        };

        this.updateFlag = true;

        this.cdr.detectChanges();

    }


    buildExportUrl() {
        // this.token = this.authenticationService.currentTokenValue;
        // this.exportUrl = `${environment.apiUrl}/user/excel-export?token=${this.token}`;
    }

    async getVisibilidades() {

        return this.visibilidadeService.listToSelect().subscribe((result) => {
            this.visibilidadeOptions = result.map((option: any) => ({
                label: option.descricao,
                value: option.id
            }));
        });
    }

    async getPerfis() {


        return this.perfilService.listToSelect().subscribe((result) => {
            this.profileOptions = result.map((option: any) => ({
                label: option.descricao,
                value: option.id
            }));
        });

    }

    onAlterarPerfilDeAcesso() {
        this.getPerfis();
        this.displayProfileModal = true;
    }

    onAlterarVisibilidadeColetiva() {
        this.getVisibilidades();
        this.selectedVisibilidades = [];
        this.displayVisibilidadeModal = true;
        this.refreshCheckedStatus();
    }

    saveBulkToVisibilities() {

        this.usuarioService.bulkAppend(this.checkedItems, this.selectedVisibilidades, this.limparVisibilidades, 'visibilidades')
            .subscribe(
                () => {
                    this.toastrService.success(`Alteração em massa realizada!`);
                    this.selectedVisibilidades = [];
                    this.displayVisibilidadeModal = false;
                    this.updateTable();
                },
                (response) => {
                    this.toastrService.error(response.error.message);
                }
            );
    }

    saveBulkToProfiles() {
        this.usuarioService.bulkChange(this.checkedItems, {perfil_id: this.selectedProfile, maria: true},
            'alteracao-em-massa').subscribe(
            () => {
                this.toastrService.success(`Alteração em massa realizada!`);
                this.selectedProfile = '';
                this.displayProfileModal = false;
                this.updateTable();
            },
            (response) => {
                this.toastrService.error(response.error.message);
            }
        );
    }

    handleCancel() {
        this.displayVisibilidadeModal = false;
        this.displayProfileModal = false;
    }

    addNewVisibilidade() {
        this.newVisibilidadeModal = true;
    }

    saveNewVisibilidade() {

        if (!Utils.isEmpty(this.newVisibilidade)) {

            this.visibilidadeService.save({descricao: this.newVisibilidade} as Visibilidade).subscribe((res: any) => {

                if (res.hasOwnProperty('success')) {
                    this.toastrService.success(res.success);
                    this.dataVisibilityForm.data.push(res.data);
                    this.selectedVisibilidades.push(res.data.id);
                    this.getVisibilidades();
                    this.newVisibilidadeModal = false;
                }

            }, (response) => {

                this.toastrService.error(response.error.message);
                this.newVisibilidadeModal = false;

            });
        }
    }

    closeNewVisibilidade() {
        this.newVisibilidadeModal = false;
    }

    getStatusLabel(status: number) {
        return status === 1 ? 'Ativo' : 'Inativo';
    }


    openTab(componentName: string, queryParams?: string, data?: {}) {
        const component = getComponentConfigByName(componentName);
        const url = buildUrl(component, queryParams);
        const newTab = new Tab(component.name, component.title, url, component.urlType, data);
        this.tabService.addTab(newTab);
    }

    confirmaCadastro() {

        this.cadastrando = true;

        const dadosUsuario = {};

        for (const key in this.formCadastrar.controls) {

            if (key) {

                const campo = this.formCadastrar.get(key);

                campo.markAsDirty();
                campo.updateValueAndValidity();

                dadosUsuario[key] = campo.value;

            }

        }

        if (this.formCadastrar.valid) {

            this.usuarioService.save(this.formCadastrar.value).subscribe(
                (response) => {

                    this.toastrService.success('Dados atualizados com sucesso!');
                    this.updateTable();
                    this.modalCadastrar(false);
                    this.openTab('/administracao/usuario/detalhe/', response.id, {id: response.id});
                },
                (response) => {

                    this.toastrService.error(response.error.message);
                    this.cadastrando = false;

                }
            );


        } else {

            this.cadastrando = false;

        }

    }

    modalCadastrar(visible) {

        if (visible) {

            this.getPerfis();

            this.formCadastrar = this.fb.group({
                primeiroNome: [null, [Validators.required]],
                sobreNome: [null, [Validators.required]],
                email: [null, [Validators.required, Validators.email]],
                login: [null, [Validators.required]],
                senha: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(12)]],
                confirmaSenha: [null, Validators.required],
                perfil_id: [null, Validators.required]
            });

        }

        this.modalCadastrarVisible = visible;
        this.cadastrando = false;
    }

    listByTable(params: NzTableQueryParams) {
        let buscar = false;

        if (params.pageIndex === this.pagination.current_page) {
            params.sort.forEach(s => {
                if (s.value) {
                    buscar = true;
                }
            });
        }

        if ((buscar || params.pageIndex === 1 && this.pagination.current_page > 1)
            || params.pageIndex > 1
            && params.pageIndex !== this.pagination.current_page) {
            this.queryTable(params);
            this.pagination.current_page = params.pageIndex;
        }

    }

    queryTable(params: NzTableQueryParams, search: string = null, filtrar = false): void {

        this.first = false;

        this.loading = true;
        this.currentParams = params;

        this.service.id = this.data.id;
        this.service.montaUrl(this.service.id);

        if (filtrar) {

            if (this.formFiltrar.formGroup.value.emissaoData) {
                this.formFiltrar.formGroup.value.emissaoData = this.service.formataDateBD(this.formFiltrar.formGroup.value.emissaoData);
            }

            this.currentParams.filter = [];

            const filtros = this.formFiltrar.formGroup.value;

            for (const [chave, valor] of Object.entries(filtros)) {

                if (valor) {
                    this.currentParams.filter.push({key: chave, value: valor});
                }

            }

        }

        this.abstractService.listToTable(this.currentParams, this.currentSearch, this.service.url).subscribe((response) => {

            this.loading = false;

            this.items = [];
            this.items = response?.data || response;

            // Campos Divergentes
            this.items.forEach((item) => {
                this.arrayTableItensNota[item.id] = [];
                item.objDivergentes = item.camposDivergentes ? JSON.parse(item.camposDivergentes) : {};
            });

            this.pagination = new Pagination(
                response?.per_page || 50,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 50,
                response?.from || 1,
                response?.to || 50);

            this.loading = false;
        });
    }

    filtrarClick(status: string): void {

        status = status.replace('status_', '');

        this.currentParams.filter = [];

        this.currentParams.filter.push({key: 'status', value: status});

        this.queryTable(this.currentParams, this.currentSearch);

    }


    modalFiltrar(visible) {
        this.formFiltrar.modalVisible = visible;
        this.cdr.detectChanges();
    }

    cardQuebraSequencia(visible) {
        this.cardQuebraVisible = visible;
        this.cardNotaVisible = !visible;
        this.cdr.detectChanges();
    }

    confirmaFiltrar(): void {

        if (this.formFiltrar.formGroup.value.emissaoData) {
            this.formFiltrar.formGroup.value.emissaoData = this.service.formataDateBD(this.formFiltrar.formGroup.value.emissaoData);
        }

        this.currentParams.filter = [];

        const filtros = this.formFiltrar.formGroup.value;

        for (const [chave, valor] of Object.entries(filtros)) {
            if (valor) {
                this.currentParams.filter.push({key: chave, value: valor});
            }
        }

        this.queryTable(this.currentParams, this.currentSearch, true);

        this.modalFiltrar(false);

    }

    modalExportar(visible: boolean, subItens = false): void {
        this.formExportar.modalVisible = visible;
        this.exportSubItens = subItens;
        this.cdr.detectChanges();
    }


    confirmaExportar(): void {

        const tipo = this.formExportar.formGroup.value.tipo;

        this.loadings.exportando = true;

        const filtros = this.formFiltrar.formGroup.value;

        this.currentParams.filter.forEach(f => {
            if (f.key === 'status') {
                filtros.status = f.value;
            }
        });


        this.service.exportExcel(this.data.id, filtros, tipo, this.exportSubItens).subscribe((res) => {

            if (this.formExportar.formGroup.value.tipo) {

                const blob = new Blob([res], {type: 'text/json; charset=utf-8'});

                const name = 'Notas Detalhes';

                fileSaver.saveAs(blob, name + '.' + this.formExportar.formGroup.value.tipo);

                this.formExportar.modalVisible = false;
                this.cdr.detectChanges();
            }
            this.loadings.exportando = false;

        }, (res) => {
            this.loadings.exportando = false;
        });
    }

    limparFiltros(): void {

        this.zerarFormFiltrar();

        this.currentParams.filter = [];
        this.currentSearch = '';
        this.pesquisar = null;

        this.queryTable(this.currentParams, this.currentSearch);

    }


    zerarFormFiltrar(): void {
        this.formFiltrar.formGroup.value.notaDe = null;
        this.formFiltrar.formGroup.value.notaAte = null;
        this.formFiltrar.formGroup.value.modelo = null;
        this.formFiltrar.formGroup.value.serie = null;
        this.formFiltrar.formGroup.value.emissao = null;
        this.formFiltrar.formGroup.value.tipoXML = null;
        this.formFiltrar.formGroup.value.tipoSPED = null;
        this.formFiltrar.formGroup.value.participante = null;
        this.formFiltrar.formGroup.value.uf = null;
        this.formFiltrar.formGroup.value.situacao = null;
        this.formFiltrar.formGroup.value.dtEmissaoDe = null;
        this.formFiltrar.formGroup.value.dtEmissaoAte = null;
        this.formFiltrar.formGroup.value.dtEntradaDe = null;
        this.formFiltrar.formGroup.value.dtEntradaAte = null;
        this.formFiltrar.formGroup.value.valorDe = null;
        this.formFiltrar.formGroup.value.valorAte = null;
        this.formFiltrar.formGroup.value.icmsValorDe = null;
        this.formFiltrar.formGroup.value.icmsValorAte = null;
        this.formFiltrar.formGroup.value.ipiValorDe = null;
        this.formFiltrar.formGroup.value.ipiValorAte = null;
        this.formFiltrar.formGroup.value.natureza = null;
        this.formFiltrar.formGroup.value.chave = null;
        this.formFiltrar.formGroup.value.competencia = null;
        this.formFiltrar.formGroup.value.status = null;
        this.formFiltrar.formGroup.value.divergencia_chave = null;
        this.formFiltrar.formGroup.value.divergencia_serie = null;
        this.formFiltrar.formGroup.value.divergencia_emissao = null;
        this.formFiltrar.formGroup.value.divergencia_operDoc = null;
        this.formFiltrar.formGroup.value.divergencia_operEscrit = null;
        this.formFiltrar.formGroup.value.divergencia_modelo = null;
        this.formFiltrar.formGroup.value.divergencia_situacao = null;
        this.formFiltrar.formGroup.value.divergencia_emitDest = null;
        this.formFiltrar.formGroup.value.divergencia_dtEmissao = null;
        this.formFiltrar.formGroup.value.divergencia_valor = null;


    }

    search(): void {
        this.currentSearch = this.pesquisar.length > 0 ? this.pesquisar : null;
        this.queryTable(this.currentParams, this.currentSearch);
    }

    onExpandChange(id: number, checked: boolean, notaId: number): void {

        this.expandLoading[id] = true;

        if (checked) {

            this.retornaItensNota(id, notaId, ((response) => {
                this.arrayTableItensNota[notaId] = response;
                this.expandSet.add(notaId);
                this.expandLoading[id] = false;
            }));

        } else {
            this.expandLoading[id] = false;

            this.expandSet.delete(notaId);

        }

    }

    retornaItensNota(analiseId, notaId, callback): void {

        this.service.retornaItensNota(analiseId, notaId, this.currentParams.filter, this.currentSearch).subscribe((response) => {

            callback(response);

        });
    }

}
