import {NgModule} from '@angular/core';
import {HeaderComponent} from '@components/header/header.component';
import {MegaMenuComponent} from '@components/megamenu/megamenu.component';
import {NgZorroModule} from '../shared/ng-zorro.module';
import {HeaderFullScreenComponent} from '@components/header/fullscreen.component';
import {NzTreeModule} from 'ng-zorro-antd/tree';
import {NzBadgeModule} from 'ng-zorro-antd/badge';
import {UserMenuComponent} from '@components/header/user-menu/user-menu.component';
import {NzTabsModule} from 'ng-zorro-antd/tabs';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NzResultModule} from 'ng-zorro-antd/result';
import {NavbarListComponent} from '@components/navbar-list/navbar-list.component';
import {TabsetModule} from '@components/tabset/tabset.module';
import {DeployInfo} from '@components/header/deploy-info/deploy-info';
import {NzTreeViewModule} from 'ng-zorro-antd/tree-view';
import {NzTypographyModule} from 'ng-zorro-antd/typography';
import {NzTagModule} from 'ng-zorro-antd/tag';
import {NzSwitchModule} from 'ng-zorro-antd/switch';
import {NzSpaceModule} from 'ng-zorro-antd/space';
import {CpfCnpjPipe} from '../shared/pipes/cpfCnpj.pipe';
import {CepPipe} from '../shared/pipes/cep.pipe';
import {TelefonePipe} from '../shared/pipes/telefone.pipe';
import {NotifyModule} from '@components/notify/notify.module';
import {TasksModule} from '@components/tasks/tasks.module';
import {SassHelperComponent} from '@components/sassHelper/sassHelper.component';
import {NzListModule} from 'ng-zorro-antd/list';
import {NzImageModule} from 'ng-zorro-antd/image';
import {NzUploadModule} from 'ng-zorro-antd/upload';
import {EditorModule} from "@tinymce/tinymce-angular";
@NgModule({
    imports: [
        NgZorroModule,
        NzTreeModule,
        NzBadgeModule,
        NzTabsModule,
        CommonModule,
        RouterModule,
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        NzResultModule,
        TabsetModule,
        NzTreeViewModule,
        NzTypographyModule,
        NzTagModule,
        NzSwitchModule,
        NzSpaceModule,
        NotifyModule,
        TasksModule,
        NzListModule,
        NzImageModule,
        NzUploadModule,
        EditorModule,
    ],
    providers: [
        MegaMenuComponent,
    ],
    declarations: [
        SassHelperComponent,
        HeaderComponent,
        MegaMenuComponent,
        HeaderFullScreenComponent,
        UserMenuComponent,
        NavbarListComponent,
        DeployInfo,
        DeployInfo,
        CpfCnpjPipe,
        CepPipe,
        TelefonePipe
    ],
    exports: [
        HeaderComponent,
        NgZorroModule,
        HeaderFullScreenComponent,
        UserMenuComponent,
        MegaMenuComponent,
        NavbarListComponent,
        CpfCnpjPipe,
        CepPipe,
        TelefonePipe
    ]
})
export class UiModule {
}
