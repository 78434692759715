import {Component, ElementRef, ViewChild, OnInit, ChangeDetectorRef, HostListener} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {OmissoesSefazService} from './omissoes-sefaz.service';
import {ToastrService} from 'ngx-toastr';
import {Helpers} from '../../../core/helpers';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
    selector: 'app-omissoes-sefaz',
    templateUrl: './omissoes-sefaz.component.html',
    styleUrls: ['./omissoes-sefaz.component.scss']
})
export class OmissoesSefazComponent implements OnInit {

    expandSet = new Set<any>();
    checked = false;

    statusOne = false;

    contadoresCabecalho = {
        omisso: 0,
        nao_omisso: 0,
        erro_consulta: 0,
        percent_omisso: 0,
        percent_nao_omisso: 0
    };

    // utilizando forms tipados da v14
    formFiltrar = this.fb.group({
        grupoEmpresarial: [null],
        status: [null],
        dataInicio: [null],
        dataFim: [null],
    });

    modalVisible = {
        filtrar: false,
        ultimoRelatorio: false,
        modalPngVisible: false
    };

    qtdFiltros = 0;

    expandAll = false;

    currentParams: NzTableQueryParams;
    currentSearch: string;

    arraySelectGrupoEmpresarial = [];

    omissoes = [];

    loadings = {
        cards: false,
        table: false,
        verArquivo: {}
    };

    isPdf = true;

    iframeUrl: SafeResourceUrl = '';

    tamanhosTela = {
        screenHeight: 0,
        screenModalTop: 0,
        screenWidth: 0
    };

    conteudoUltimoRelatorio = null;

    arquivoSelecionado = null;

    @ViewChild('column1') column1: ElementRef;
    column1Width = 0;

    @ViewChild('column2') column2: ElementRef;
    column2Width = 0;

    @ViewChild('column3') column3: ElementRef;
    column3Width = 0;

    @ViewChild('column4') column4: ElementRef;
    column4Width = 0;

    @ViewChild('column5') column5: ElementRef;
    column5Width = 0;

    @ViewChild('column6') column6: ElementRef;
    column6Width = 0;

    @ViewChild('column7') column7: ElementRef;
    column7Width = 0;

    @ViewChild('column8') column8: ElementRef;
    column8Width = 0;

    base64Selecionado = null;
    relatorioSelecionado = null;
    imgModalPng: string;


    constructor(
        private fb: FormBuilder,
        private service: OmissoesSefazService,
        private toastService: ToastrService,
        private cdr: ChangeDetectorRef,
        public sanitizer: DomSanitizer,
    ) {
    }

    @HostListener('window:resize', ['$event'])
    resizeTable(event?) {
        this.toggleParamsCardTabs();
        this.resizeModal();
    }

    ngOnInit() {
        this.getGruposEmpresariais();
        this.getTable(this.currentParams, this.currentSearch);
    }

    getGruposEmpresariais() {
        this.service.retornarGruposEmpresariais().subscribe((retorno: any) => {
            this.arraySelectGrupoEmpresarial = retorno.grupoEmpresarial;
        });
    }

    formatOne = (percent: number) => `${percent}%`;

    clickEvent(card) {

        switch (card) {

            case 'one':
                this.statusOne = !this.statusOne;
                break;
        }

    }

    filtrarByCard(status) {

        if (this.formFiltrar.value.status === status) {
            this.formFiltrar.get('status').setValue(null);
        } else {
            this.formFiltrar.get('status').setValue(status);
        }

        this.confirmaFiltrar();
    }

    getTable(params?: any, search?: any): void {

        this.currentParams = params || {filter: []};

        this.loadings.table = true;

        this.calculaBadgeFiltros();

        const filtros: any = {};

        const datas = ['dataInicio', 'dataFim'];

        this.currentParams.filter.forEach(f => {
            filtros[f.key] = datas.includes(f.key) ? Helpers.formatDateDb(f.value) : f.value;
        });

        if (search) {
            filtros.search = search;
            this.exibirTudo(true);
        }

        this.service.getTable(filtros).subscribe((response: any) => {

            this.omissoes = [];
            this.contadoresCabecalho.omisso = 0;
            this.contadoresCabecalho.nao_omisso = 0;

            this.omissoes = response.data;

            let qtdOmissoes = 0;

            this.omissoes.forEach(grupos => {
                grupos.omissoes.forEach(o => {
                    qtdOmissoes++;
                    this.contadoresCabecalho.nao_omisso += o.statusUltimaEmissao === 1 ? 1 : 0;
                    this.contadoresCabecalho.omisso += o.statusUltimaEmissao === 0 ? 1 : 0;
                });
            });

            this.contadoresCabecalho.percent_omisso = Number((((this.contadoresCabecalho.omisso) * 100) / qtdOmissoes).toFixed(2));
            this.contadoresCabecalho.percent_nao_omisso =
                Number((((this.contadoresCabecalho.nao_omisso) * 100) / qtdOmissoes).toFixed(2));

            this.toggleParamsCardTabs();
            this.loadings.table = false;

        }, error => {
            this.loadings.table = false;
            this.toastService.error(error.erros.message);
        });

    }

    showModal(modal: string, visible: boolean) {
        this.modalVisible[modal] = visible;
    }

    confirmaFiltrar() {

        this.currentParams.filter = [];

        for (const [key, valor] of Object.entries(this.formFiltrar.value)) {
            if (valor || valor === 0) {
                this.currentParams.filter.push({key, value: valor.toString()});
            }
        }

        this.getTable(this.currentParams, this.currentSearch);
        this.modalVisible.filtrar = false;

        this.exibirTudo(true);
    }

    calculaBadgeFiltros(): void {

        this.qtdFiltros = 0;

        if (typeof this.formFiltrar !== 'undefined') {

            for (const [chave, valor] of Object.entries(this.formFiltrar.value)) {
                if (valor && chave !== 'pagina' && valor && chave !== 'quantidade' && chave !== 'search'
                    && chave !== 'ordenar' && chave !== 'sentido') {

                    this.qtdFiltros++;
                }
            }

        }
    }

    limparFiltros() {
        this.currentParams.filter = [];
        this.currentSearch = '';
        this.formFiltrar.reset();
        this.currentParams.pageIndex = 1;
        this.getTable(this.currentParams, this.currentSearch);
    }

    onExpandChange(id: number, checked: boolean): void {

        if (checked) {
            this.expandSet.add(id);
        } else {
            this.expandSet.delete(id);
        }

        this.column1Width = this.column1.nativeElement.offsetWidth;
        this.column2Width = this.column2.nativeElement.offsetWidth;
        this.column3Width = this.column3.nativeElement.offsetWidth;
        this.column4Width = this.column4.nativeElement.offsetWidth;
        this.column5Width = this.column5.nativeElement.offsetWidth;
        this.column6Width = this.column6.nativeElement.offsetWidth;
        this.column7Width = this.column7.nativeElement.offsetWidth;
        this.column8Width = this.column8.nativeElement.offsetWidth;
    }

    exibirTudo(exibir: boolean) {
        this.expandAll = exibir;

        if (this.expandAll) {
            this.omissoes.forEach(o => {
                this.expandSet.add(o.grupoEmpresarialDescricao);
            });
        } else {
            this.expandSet.clear();
        }
    }

    toggleParamsCardTabs() {

        setTimeout(() => {
            this.column1Width = this.column1.nativeElement.offsetWidth;
            this.column2Width = this.column2.nativeElement.offsetWidth;
            this.column3Width = this.column3.nativeElement.offsetWidth;
            this.column4Width = this.column4.nativeElement.offsetWidth;
            this.column5Width = this.column5.nativeElement.offsetWidth;
            this.column6Width = this.column6.nativeElement.offsetWidth;
            this.column7Width = this.column7.nativeElement.offsetWidth;
            this.column8Width = this.column8.nativeElement.offsetWidth;

            this.cdr.detectChanges();
        }, 100);

    }

    resizeModal() {
        this.tamanhosTela.screenHeight = Math.round((window.innerHeight / 100) * 73);
        this.tamanhosTela.screenWidth = Math.round((window.innerWidth / 100) * 90);
        this.tamanhosTela.screenModalTop = Math.round((window.innerHeight / 100) * 9);
    }

    verUltimoRelatorio(data): void {
        // relatório sempre será um htmml que sera aberto em uma nova guia do navegador
        this.resizeModal();

        this.loadings.verArquivo[data.referenciaUltimaEmissao] = true;
        this.iframeUrl = '';
        this.arquivoSelecionado = data.empresaNome;

        this.service.getUltimoRelatorio(data.referenciaUltimaEmissao).subscribe((response) => {

            if (response.arquivo_base64 && response.tipo === 'html') {

                const archive = atob(response.arquivo_base64);
                this.conteudoUltimoRelatorio = decodeURIComponent(escape(archive));
                this.relatorioSelecionado = this.arquivoSelecionado;
                this.isPdf = false;

                this.printRelatorio(this.conteudoUltimoRelatorio);

            }else if (response.arquivo_base64 && response.tipo === 'png'){
                this.imgModalPng = response.arquivo_base64;
                this.modalVisible.modalPngVisible = true;
            }

            this.loadings.verArquivo[data.referenciaUltimaEmissao] = false;


        }, (response) => {
            this.loadings.verArquivo[data.referenciaUltimaEmissao] = false;

            this.toastService.error(response.error.message);

        });

    }

    semRelatoriomsg() {
        this.toastService.warning('Sem arquivo para exibir');
    }

    printRelatorio(content: any) {
        const blob = new Blob([content], {type: 'text/html'});
        const url = URL.createObjectURL(blob);
        window.open(url, '_blank');
        URL.revokeObjectURL(url);
    }

}
