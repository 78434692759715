import {AfterViewInit, Component, OnInit, TemplateRef, DoCheck, ViewChild, ViewEncapsulation} from '@angular/core';
import {AppComponent} from '../../../app.component';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {AuthenticationService} from '../../auth/authentication.service';
import {TabService} from '@services/tab.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit, AfterViewInit, DoCheck {

    miniLogoUrl;
    backgroundColor;
    highlightColor;
    verifyThemeCount = 0;

    currentDeployInfoData;
    deployInfoData;
    notificacaoAtivada = false;

    semTabs = false;
    showNotificacoes = false;

    @ViewChild('notifyAtualization', {static: false}) template?: TemplateRef<{}>;
    @ViewChild('closeIcon', {static: false}) closeIcon?: TemplateRef<void>;

    constructor(
        private notificationService: NzNotificationService,
        private authenticationService: AuthenticationService,
        private tabService: TabService) {

        this.verificaAtualizacao((deployInfoData) => {

            this.currentDeployInfoData = deployInfoData;

            this.LoopVerificaAtualizacao();

        });

    }

    ngOnInit() {

        const element = document.querySelector('.header-tabs');

        element.classList.add('backgroundColor');

        this.verifyTheme();

        this.semTabs = this.tabService.tabs.length === 0 ? true : false;

    }

    exibirNotificacao(){
        this.showNotificacoes = true;
    }

    ngAfterViewInit() {

        AppComponent.createCSSSelector(
            '.backgroundColor .ant-tabs-nav .ant-tabs-tab-active',
            'background-color:' + this.highlightColor + ' !important; border: 1px solid ' + this.highlightColor + ' !important;'
        );

    }

    ngDoCheck() {
        this.semTabs = this.tabService.tabs.length === 0 ? true : false;
    }

    verifyTheme() {

        if (AppComponent.miniLogoUrl && AppComponent.backgroundColor && AppComponent.highlightColor) {
            this.miniLogoUrl = AppComponent.miniLogoUrl;
            this.backgroundColor = AppComponent.backgroundColor;
            this.highlightColor = AppComponent.highlightColor;
            return;
        }

        if (this.verifyThemeCount > 100) {
            return;
        }

        setTimeout(() => {
            this.verifyThemeCount++;
            this.verifyTheme();
        }, 100);

    }

    verificaAtualizacao(callback) {

        const url = window.location.origin;

        fetch(url + '/assets/deploy-info.data.ts').then((r) => {

            r.text().then((d: any) => {

                const formatedDate = this.formatDeployDate(d);

                callback(formatedDate);

            });

        });

    }

    formatDeployDate(deployDate) {

        const contentOne = deployDate.split(',');
        const contentTwo = contentOne[2].split('\'');

        let splitDate = contentTwo[1].split(' ');

        const splitTime = splitDate[1].split(':');

        splitDate = splitDate[0].split('/');

        const currentDay = Number(splitDate[0]);
        const currentMonth = Number(splitDate[1]) - 1;
        const currentYear = new Date().getFullYear();
        const currentHours = Number(splitTime[0]);
        const currentMinutes = Number(splitTime[1]);
        const currentSeconds = Number(splitTime[2]);

        return new Date(currentYear, currentMonth, currentDay, currentHours, currentMinutes, currentSeconds);

    }

    LoopVerificaAtualizacao() {

        this.verificaAtualizacao((deployInfoData) => {

            this.deployInfoData = deployInfoData;

            if (!this.notificacaoAtivada && this.currentDeployInfoData.getTime() < this.deployInfoData.getTime()) {

                this.notificacaoAtivada = true;

                this.notificationService.template(this.template, {
                    nzCloseIcon: this.closeIcon,
                    nzDuration: 0,
                });

            }

            setTimeout(() => {

                this.LoopVerificaAtualizacao();

            }, 300000);

        });

    }

    fecharNotificacao() {
        // this.notificacaoAtivada = false;
    }

    refresh(): void {
        window.location.reload();
    }

    logar() {
        // this.authenticationService.logout();

        this.authenticationService.closeSession(null);

    }

}
