<nz-page-header class="site-page-header" nzTitle="Drive"
                nzSubtitle="Baixas Automáticas">
    <nz-page-header-extra>
        <a nz-button nzType="link" (click)="slideSwiper('left', 6)">
            <i class="fas fa-angle-double-left"></i>
        </a>
        <a nz-button nzType="link" (click)="slideSwiper('left', 3)">
            <i class="fas fa-angle-left"></i>
        </a>
        <a nz-button nzType="link" (click)="slideSwiper('right', 3)">
            <i class="fas fa-angle-right"></i>
        </a>
        <a nz-button nzType="link" (click)="slideSwiper('right', 6)">
            <i class="fas fa-angle-double-right"></i>
        </a>
        <a nz-button nzType="link" (click)="toggleCollapseCard()">
            <span>{{ swiperCollapseCard ? 'Expandir' : 'Recolher' }}</span>
        </a>
    </nz-page-header-extra>
</nz-page-header>
<nz-row [nzGutter]="24">
    <nz-col [nzSpan]="24" style="overflow: hidden;">
        <swiper [(index)]="swiperCardAtivo"
                (click)="selecionaCardCompetencia($event)"
                slidesPerView="auto"
                [spaceBetween]="30"
                [centeredSlides]="true"
                [mousewheel]="false"
                [scrollbar]="false"
                [navigation]="false">
            <ng-template class="swiper-card-competencia" swiperSlide
                         *ngFor="let item of swiperCompetencias; index as i">
                <div class="card-competencia ant-card pull-up card-{{i}}"
                     [ngClass]="swiperCollapseCard && 'compress'">
                    <div class="ant-card-head" style="text-align: center">
                        <h2 style="display: inline-block; margin: 5px 0 0 0;">{{ item.ano }}</h2>
                    </div>
                    <div style="margin-top: 5px; margin-bottom: -5px" class="text-center ant-card-body card-{{i}}">
                        <div nz-row>
                            <div nz-col [nzSpan]="24" style="text-align: center; ">
                                <div>
                                    <span nz-typography nzType="secondary">Unidades</span>
                                </div>
                                <div>
                                    {{ item.unidades ? item.unidades : 0 }}
                                </div>
                            </div>
                        </div>
                        <div nz-row>
                            <div nz-col [nzSpan]="24" style="text-align: center; ">
                                <div>
                                    <span nz-typography nzType="secondary">Obrigações</span>
                                </div>
                                <div>
                                    {{ item.obrigacoes ? (item.obrigacoes |  number: '1.0-0') : 0 }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </swiper>
    </nz-col>
</nz-row>
<nz-content>
    <nz-row [nzGutter]="24">
        <nz-col nzXs="24" nzSm="24" nzMd="8" nzLg="8" nzXl="8">
            <nz-card
                    [nzLoading]="cardsCabecalhoLoading">
                <div nz-row>
                    <div style="min-height: 60px" nz-col [nzSpan]="24">
                        <h4 class="custom-card-title text-muted" style="margin: 0; line-height: 1;">
                            Ano
                        </h4>
                        <div class="d-flex align-items-center justify-content-center">
                            <h1 nz-typography class="custom-card-value"
                                style="margin: 0;">
                                {{ cabecalho.ano }}
                            </h1>
                        </div>
                    </div>
                </div>
            </nz-card>
        </nz-col>
        <nz-col nzXs="24" nzSm="24" nzMd="8" nzLg="8" nzXl="8">
            <nz-card [nzLoading]="cardsCabecalhoLoading">
                <div nz-row>
                    <div nz-col [nzSpan]="24" class="d-flex align-items-center" style="justify-content: center">
                        <i class="far fa-building"
                           style="font-size: 60px; margin-right: 15px; color: #009688 !important;"></i>
                        <div>
                            <h4 class="custom-card-title text-muted" style="margin: 0; line-height: 1;">
                                Unidades
                            </h4>
                            <h1 nz-typography class="custom-card-value"
                                style="margin: 0; color: #009688 !important;">
                                {{ cabecalho.unidades |  number: '1.0-0' }}
                            </h1>
                        </div>
                    </div>
                </div>
            </nz-card>
        </nz-col>
        <nz-col nzXs="24" nzSm="24" nzMd="8" nzLg="8" nzXl="8">
            <nz-card [nzLoading]="cardsCabecalhoLoading">
                <div nz-row>
                    <div nz-col [nzSpan]="24" class="d-flex align-items-center" style="justify-content: center">
                        <i class="far fa-calendar-alt"
                           style="font-size: 60px; margin-right: 15px; color:#1e9ff2 !important;"></i>
                        <div>
                            <h4 class="custom-card-title text-muted" style="margin: 0; line-height: 1;">
                                Obrigações
                            </h4>
                            <h1 nz-typography class="custom-card-value" style="margin: 0; color: #1e9ff2 !important;">
                                {{ cabecalho.obrigacoes | number: '1.0-0' }}
                            </h1>
                        </div>
                    </div>
                </div>
            </nz-card>
        </nz-col>
    </nz-row>

    <nz-row nzGutter="24">
        <nz-col nzSpan="24">
            <nz-card
                    [ngClass]="[(!loadingEmpresas && tabNumber === 0) || (!loadingObrigacoes && tabNumber === 1) ? 'ant-card-table' : '', statusCard ? 'card-fullscreen' : '']"
                    [nzLoading]="((loadingEmpresas && tabNumber === 0) || loadingObrigacoes)">
                <nz-card-tab>
                    <nz-tabset
                            [nzSize]="'large'"
                            [nzTabBarExtraContent]="navBar"
                            [(nzSelectedIndex)]="tabNumber"
                            (nzSelectChange)="changeTabs($event)">
                        <nz-tab nzDisabled="{{(loadingObrigacoes || loadingEmpresas) && tabNumber == 1}}"
                                nzTitle="Empresas"></nz-tab>
                        <nz-tab nzDisabled="{{loadingEmpresas && tabNumber == 0 || empresaSelecionada.empresa_id == null}}"
                                nzTitle="Obrigações"></nz-tab>
                    </nz-tabset>
                </nz-card-tab>

                <nz-table #tableOne
                          [nzData]="empresas"
                          [nzFrontPagination]="false"
                          [nzShowPagination]="false"
                          *ngIf="tabNumber === 0"
                          nzSize="small"
                          nzBordered
                          style="table-layout:fixed; overflow: auto;">
                    <thead>
                    <tr>
                        <th #column1 style="text-align: left; width: 230px">Grupo</th>
                        <th #column2></th>
                        <th #column3 style="text-align: left;">Unidade</th>
                        <th #column4 style="text-align: center;">CNPJ</th>
                        <th #column5 style="text-align: center; width: 200px">IE</th>
                        <th #column6 style="text-align: center; max-width: 40px">Obrigações</th>
                        <th #column7 style="text-align: center; max-width: 60px">Competências</th>
                        <th #column8 style="text-align: center;">Última Consulta</th>
                        <th #column17 style="text-align: center; max-width: 60px">
                            Aguardando Consulta
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <ng-container class="ant-table-tbody" *ngFor="let grupoEmpresarial of tableOne.data">
                        <tr>
                            <td [ngStyle]="{
                        'width': '230px',
                        'min-width': '230px',
                        'word-break': 'breack-word'
                        }">
                                <h5 nz-typography>{{ grupoEmpresarial.grupoEmpresarial_descricao }}</h5>
                            </td>
                            <td nzWidth="34px" style="color: #1e9ff2; width: 34px;"
                                [nzExpand]="expandSetEmpresas.has(grupoEmpresarial.grupoEmpresarial_descricao)"
                                (nzExpandChange)="onExpandChangeEmpresas(grupoEmpresarial.grupoEmpresarial_descricao, $event)"
                            ></td>
                            <td nzAlign="left"></td>
                            <td nzAlign="center"></td>
                            <td nzAlign="center"></td>
                            <td nzAlign="center"></td>
                            <td nzAlign="center"></td>
                            <td nzAlign="center"></td>
                            <td nzAlign="center"></td>
                        </tr>
                        <ng-container *ngIf="grupoEmpresarial.empresas.length > 0">
                            <tr [nzExpand]="expandSetEmpresas.has(grupoEmpresarial.grupoEmpresarial_descricao)"
                                *ngFor="let data of grupoEmpresarial.empresas">
                                <td [ngStyle]="{
                            'width': '220px',
                            'min-width': '220px',
                            position: 'relative',
                            padding: '8px',
                            'overflow-wrap': 'break-word'
                            }">
                                </td>
                                <td [ngStyle]="{
                            'width': column2Width + 'px',
                            'min-width': '34px',
                            position: 'relative',
                            padding: '8px 0',
                            'overflow-wrap': 'break-word',
                            'text-align': 'center'
                            }">
                                </td>
                                <td [ngStyle]="{
                            'width': column3Width + 'px',
                            position: 'relative',
                            padding: '8px',
                            'overflow-wrap': 'break-word',
                            'text-align': 'left'
                            }">
                                    <a (click)="filtrarObrigacoes(data, true)">
                                        {{ modoDemo(data.empresa_nome, 'empresaCodigoNome') }}
                                    </a>
                                    <nz-badge
                                            *ngIf="data.certificado_status || data.certificado_status?.toString() === '0'"
                                            nzColor="{{arrayStatusCertidao[data.certificado_status]?.color}}"

                                            [nz-tooltip]="arrayStatusCertidao[data.certificado_status]?.descricao"
                                            style="margin-left: 5px;"></nz-badge>
                                </td>
                                <td [ngStyle]="{
                            'width': column4Width + 'px',
                            position: 'relative',
                            padding: '8px',
                            'overflow-wrap': 'break-word',
                            'text-align': 'center'
                            }">
                                    {{ modoDemo(data.cnpj | cpfCnpj, 'cnpj') }}
                                </td>
                                <td [ngStyle]="{
                            'width': column5Width + 'px',
                            position: 'relative',
                            padding: '8px',
                            'overflow-wrap': 'break-word',
                            'text-align': 'center'
                            }">
                                    {{ data.ie }}
                                </td>
                                <td [ngStyle]="{
                            'width': column6Width + 'px',
                            position: 'relative',
                            padding: '8px',
                            'text-align': 'center'
                            }">
                                    {{ data.quantidade }}
                                </td>
                                <td [ngStyle]="{
                            'width': column7Width + 'px',
                            position: 'relative',
                            padding: '8px',
                            'text-align': 'center'
                            }">
                                    {{ data.quantidade_competencias }}
                                </td>
                                <td [ngStyle]="{
                            'width': column8Width + 'px',
                            position: 'relative',
                            padding: '8px 4px',
                            'overflow-wrap': 'break-word',
                            'text-align': 'center'
                            }">
                                    <div *ngIf="data.minUltimaConsultaData || data.maxUltimaConsultaData else nunca">

                                        <div *ngIf="data.minUltimaConsultaData === data.maxUltimaConsultaData
                                        || menorQueUmMin(data.minUltimaConsultaData, data.maxUltimaConsultaData) else diferenteUltimaEmpresas">
                                            {{ data.minUltimaConsultaData  | date: 'dd/MM/YYYY HH:mm:ss' }}
                                        </div>

                                        <ng-template #diferenteUltimaEmpresas>
                                            <div>
                                                {{ data.minUltimaConsultaData ? (data.minUltimaConsultaData  | date: 'dd/MM/YYYY HH:mm:ss') : 'Nunca' }}
                                                -
                                                {{ data.maxUltimaConsultaData ? (data.maxUltimaConsultaData  | date: 'dd/MM/YYYY HH:mm:ss') : 'Nunca' }}
                                            </div>
                                        </ng-template>

                                    </div>
                                </td>
                                <td [ngStyle]="{
                                    'width': column17Width + 'px',
                                    position: 'relative',
                                     padding: '8px 4px',
                                    'text-align': 'center'
                                    }">
                                    {{ data.obrigacoesAguardandoConsulta }}
                                </td>
                            </tr>
                        </ng-container>
                        <ng-container
                                *ngIf="grupoEmpresarial.empresas.length === 0 || grupoEmpresarial.empresas === undefined">
                            <tr [nzExpand]="false"
                                style="text-align: center;">
                                Sem Dados
                            </tr>
                        </ng-container>
                    </ng-container>
                    </tbody>
                </nz-table>

                <nz-table #tableTwo
                          [nzData]="obrigacoes"
                          [nzFrontPagination]="false"
                          [nzShowPagination]="false"
                          *ngIf="tabNumber === 1"
                          nzSize="small"
                          nzBordered
                          style="table-layout:fixed; overflow: auto; max-height: 700px;">
                    <thead>
                    <tr>
                        <th colSpan="{{currentUser.origem == 'console' ? '8' : '7'}}">
                            <div style="text-align: left; margin: 5px 2px; color: #333">
                                <span style="margin: 4px">Unidade: {{ empresaSelecionada.empresa_nome }}</span> <br>
                                <span style="margin: 4px">CNPJ: {{ empresaSelecionada.cnpj | cpfCnpj }}</span>
                            </div>
                        </th>
                    </tr>
                    <tr>
                        <th #column9 style="text-align: left; width: 230px">Tipo</th>
                        <th #column10></th>
                        <th #column11 style="text-align: left;">Obrigação</th>
                        <th #column12 style="text-align: center; max-width: 60px">Competência</th>
                        <th #column13 style="text-align: center;">Última Consulta</th>
                        <th #column14 style="text-align: center;">Próxima Consulta</th>
                        <th #column15 style="text-align: center; max-width: 60px">
                            Obrigações
                            <br>
                            Aguardando Consulta
                        </th>
                        <th *ngIf="currentUser.origem == 'console'" #column16
                            style="width: 120px; text-align: center;"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <ng-container class="ant-table-tbody" *ngFor="let obrigacao of tableTwo.data">
                        <tr>
                            <td [ngStyle]="{
                        'width': '230px',
                        'min-width': '230px'
                        }">
                                <h5 nz-typography>{{ obrigacao.tipo === 'A' ? 'Acessória' : 'Principal' }}</h5>
                            </td>
                            <td style="color: #1e9ff2; width: 34px;"
                                [nzExpand]="expandSetObrigacoes.has(obrigacao.tipo)"
                                (nzExpandChange)="onExpandChangeObrigacoes(obrigacao.tipo, $event)"
                            ></td>
                            <td nzAlign="left"></td>
                            <td nzAlign="center"></td>
                            <td nzAlign="center"></td>
                            <td nzAlign="center"></td>
                            <td nzAlign="center"></td>
                            <td *ngIf="currentUser.origem == 'console'" nzAlign="center"></td>
                        </tr>
                        <ng-container *ngIf="obrigacao.obrigacoes.length > 0">
                            <tr [nzExpand]="expandSetObrigacoes.has(obrigacao.tipo)"
                                *ngFor="let data of obrigacao.obrigacoes">
                                <td [ngStyle]="{
                            'width': column9Width + 'px',
                            'min-width': '230px',
                            position: 'relative',
                            padding: '4px',
                            'overflow-wrap': 'break-word'
                            }">
                                </td>
                                <td [ngStyle]="{
                            'width': column10Width + 'px',
                            'min-width': '34px',
                            'padding': '0 4px 6px 0',
                            'overflow-wrap': 'break-word',
                            'text-align': 'left'
                            }"
                                    (nzCheckedChange)="onItemChecked(data.obrigacao_id, $event)"
                                    [nzChecked]="checkedItems.has(data.obrigacao_id)">
                                </td>
                                <td [ngStyle]="{
                            'width': column11Width + 'px',
                            position: 'relative',
                            padding: '8px',
                            'overflow-wrap': 'break-word',
                            'text-align': 'left'
                            }">
                                    {{ data.obrigacao_descricao }}
                                </td>
                                <td [ngStyle]="{
                            'width': column12Width + 'px',
                            position: 'relative',
                            padding: '8px',
                            'overflow-wrap': 'break-word',
                            'text-align': 'center'
                            }">
                                    <button nz-button nzType="link"
                                            nzLoading="{{loadingDetalhes.includes(data.obrigacao_id) || loadingSolicitarBaixa}}"
                                            (click)="showDatelhesObrigacao(true, data.obrigacao_id)">
                                        {{ loadingDetalhes.includes(data.obrigacao_id) || loadingSolicitarBaixa ? '' : data.competencias }}
                                    </button>
                                </td>
                                <td [ngStyle]="{
                            'width': column13Width + 'px',
                            position: 'relative',
                            padding: '8px',
                            'overflow-wrap': 'break-word',
                            'text-align': 'center'
                            }">
                                    <div *ngIf="data.minUltimaConsultaData || data.maxUltimaConsultaData else nunca">

                                        <div *ngIf="data.minUltimaConsultaData === data.maxUltimaConsultaData
                                         || menorQueUmMin(data.minUltimaConsultaData, data.maxUltimaConsultaData) else diferenteUltimaObrigacoes">
                                            {{ data.minUltimaConsultaData | date: 'dd/MM/YYYY HH:mm:ss' }}
                                        </div>

                                        <ng-template #diferenteUltimaObrigacoes>
                                            <div>
                                                {{ data.maxUltimaConsultaData ? (data.maxUltimaConsultaData| date: 'dd/MM/YYYY HH:mm:ss') : 'Nunca' }}
                                                <i *ngIf="data.minUltimaConsultaData" style="color: #ffc107"
                                                   [nz-tooltip]="'Existem consultas desde o dia ' + (data.minUltimaConsultaData | date: 'dd/MM/YYYY HH:mm:ss') + ' que não foram realizada.'"
                                                   nz-icon nzType="info-circle"></i>
                                            </div>
                                        </ng-template>
                                    </div>
                                </td>
                                <td [ngStyle]="{
                            'width': column14Width + 'px',
                            position: 'relative',
                            padding: '8px',
                            'overflow-wrap': 'break-word',
                            'text-align': 'center'
                            }">
                                    <div *ngIf="data.minProximaConsultaData || data.maxProximaConsultaData else nunca">

                                        <div *ngIf="data.minProximaConsultaData === data.maxProximaConsultaData
                                         || menorQueUmMin(data.minProximaConsultaData, data.maxProximaConsultaData) else diferenteProximaObrigacoes">
                                            {{ data.minProximaConsultaData | date: 'dd/MM/YYYY HH:mm:ss' }}
                                        </div>

                                        <ng-template #diferenteProximaObrigacoes>
                                            <div style="display: flex; flex-direction: column">
                                                {{ data.minProximaConsultaData ? (data.minProximaConsultaData | date: 'dd/MM/YYYY') : 'Nunca' }}
                                                -
                                                {{ data.maxProximaConsultaData ? (data.maxProximaConsultaData| date: 'dd/MM/YYYY') : 'Nunca' }}
                                            </div>
                                        </ng-template>
                                    </div>
                                </td>
                                <td [ngStyle]="{
                                    'width': column15Width + 'px',
                                    position: 'relative',
                                     padding: '8px 4px',
                                    'text-align': 'center'
                                    }">
                                    {{ data.obrigacoesAguardandoConsulta }}
                                </td>
                                <td *ngIf="currentUser.origem == 'console'"
                                    [ngStyle]="{
                                        'width': column16Width + 'px',
                                        position: 'relative',
                                        padding: '8px 4px',
                                        'overflow-wrap': 'break-word',
                                        'text-align': 'center'
                                }">
                                    <a nz-button nzType="link" nz-tooltip="user-console"
                                       (click)="openTab('BaixasAutomaticasDetalhesComponent', null,
                                        {obrigacao_id: data.obrigacao_id, unidade: empresaSelecionada.cnpj, periodo: '30'})">
                                        Detalhes
                                    </a>
                                </td>
                            </tr>
                        </ng-container>
                        <ng-container *ngIf="obrigacao.obrigacoes.length === 0 || obrigacao.obrigacoes === undefined">
                            <tr [nzExpand]="false"
                                style="text-align: center;">
                                Sem Dados
                            </tr>
                        </ng-container>
                    </ng-container>
                    </tbody>
                </nz-table>
            </nz-card>
        </nz-col>
    </nz-row>
</nz-content>

<ng-template #nunca>
    Nunca
</ng-template>

<nz-modal [(nzVisible)]="formSolicitarBaixa.modalVisible"
          [nzTitle]="'Escolha o período'" [nzClosable]="true"
          (nzOnCancel)="modalSolicitarBaixa(false)"
          [nzWidth]="648">
    <nz-row *nzModalContent>
        <nz-col nzSpan="24">
            <form nz-form [nzNoColon]="true" [formGroup]="formSolicitarBaixa.formGroup"
                  *ngIf="formSolicitarBaixa.modalVisible">
                <nz-form-item>
                    <nz-form-label nzRequired [nzSpan]="6">Data Inicial</nz-form-label>
                    <nz-form-control nzErrorTip="Campo obrigatório" [nzSpan]="12">
                        <nz-date-picker nzMode="month" style="width: 100%;"
                                        formControlName="competenciaInicial"
                                        nzFormat="MM/yyyy"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label nzRequired [nzSpan]="6">Data Final</nz-form-label>
                    <nz-form-control nzErrorTip="Campo obrigatório" [nzSpan]="12">
                        <nz-date-picker style="width: 100%;"
                                        nzMode="month"
                                        formControlName="competenciaFinal"
                                        nzFormat="MM/yyyy"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
            </form>
        </nz-col>
    </nz-row>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalSolicitarBaixa(false)">Cancelar</button>
        <button [nzLoading]="loadingSolicitarBaixa" nz-button nzType="primary" (click)="solicitarBaixa()">Solicitar
        </button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formSolicitarBaixaDarf.modalVisible"
          [nzTitle]="'Solicitar baixa manualmente para DARF'" [nzClosable]="true"
          (nzOnCancel)="modalSolicitarBaixaDarfManual(false)"
          [nzWidth]="648">
    <nz-row *nzModalContent>
        <nz-col [nzSpan]="24">
            <form nz-form [nzNoColon]="false" [formGroup]="formSolicitarBaixaDarf.formGroup">
                <nz-form-item>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="6">Unidade</nz-form-label>
                    <nz-form-control [nzSpan]="15"
                                     [nzHasFeedback]="formSolicitarBaixaDarf.formGroup.value.cnpj">
                        <nz-input-group>
                            <input disabled="disabled" nz-input type="text" value="{{
                            modoDemo(empresaSelecionada.empresa_nome, 'empresaCodigoNome') + ' (' + (modoDemo(empresaSelecionada.cnpj | cpfCnpj, 'cnpj')) + ')'
                            }}">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="6">DARF Número</nz-form-label>
                    <nz-form-control [nzSpan]="15"
                                     [nzHasFeedback]="formSolicitarBaixaDarf.formGroup.value.nroDarf">
                        <nz-select formControlName="nroDarf" nzMode="tags" nzAllowClear
                                   (keydown)="keyPressNumber($event)"
                                   nzPlaceHolder="Digite o nº da DARF seguido de 'enter'">
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
            </form>
        </nz-col>
    </nz-row>

    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalSolicitarBaixaDarfManual(false)">Cancelar</button>
        <button [nzLoading]="loadingSolicitarBaixaManual" nz-button nzType="primary"
                (click)="solicitarBaixaDarfManual()">Solicitar
        </button>
    </div>
</nz-modal>

<nz-modal nzTitle="Detalhes"
          [(nzVisible)]="modalDetalhesVisible"
          (nzOnCancel)="showDatelhesObrigacao(false)"
          [nzWidth]="1500"
          style="min-height: 950px; max-height: 950px"
          [nzBodyStyle]="{'padding' : '0'}">
    <div *nzModalContent>
        <nz-table #tableDetalhe
                  [nzData]="arrayDetalhes"
                  [nzFrontPagination]="false"
                  [nzLoading]="loadingDetalhes.length > 0"
                  class="tabela-detalhes table-small scrollbar"
                  [nzShowPagination]="false"
                  style="overflow: auto !important;">
            <thead>
            <tr>
                <th nzColumnKey="cfop" nzAlign="center">Obrigação</th>
                <th nzColumnKey="valor" nzAlign="center">Competência</th>
                <th nzColumnKey="valorIsento" nzAlign="center">Última consulta</th>
                <th nzColumnKey="valorIsento" nzAlign="center">Próxima consulta</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of tableDetalhe.data">
                <td nzAlign="center">{{ item.obrigacao_descricao }}</td>
                <td nzAlign="center">{{ pipeDataMesAno(item.competencia) }}</td>
                <td nzAlign="center">
                    <div style="display: flex; justify-content: center">
                        <div *ngIf="item.roboBaixa_status else semStatus"
                             class="alert {{item.roboBaixa_status_class}}" [nz-tooltip]="item.roboBaixa_log"
                             nzTooltipTitle="{{item.roboBaixa_log}}">
                            <span style="font-weight: 700; font-size: 0.9em; word-break: keep-all">{{ item.roboBaixa_status_descricao }}</span>
                            <div>
                                <span *ngIf="item.ultimaConsultaData" style="word-break: keep-all">
                                    {{ item.ultimaConsultaData | date: 'dd/MM/YYYY HH:mm' }}
                                </span>
                            </div>
                        </div>
                        <ng-template #semStatus>
                            <span>-</span>
                        </ng-template>
                    </div>
                </td>
                <td nzAlign="center">
                    <div>
                        {{ item.proximaConsultaData ? (item.proximaConsultaData | date: 'dd/MM/YYYY') : '-' }}
                    </div>
                </td>
            </tr>
            </tbody>
        </nz-table>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="showDatelhesObrigacao(false)">Fechar</button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formFiltrosEmpresas.modalVisible"
          [nzTitle]="'Filtros avançados'" [nzClosable]="true"
          (nzOnCancel)="fechar(formFiltrosEmpresas)"
          [nzWidth]="720">
    <nz-row *nzModalContent>
        <nz-col [nzSpan]="24">
            <form nz-form [nzNoColon]="false" [formGroup]="formFiltrosEmpresas.formGroup">
                <nz-form-item>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label nzRequired [nzSpan]="6">Grupos Empresariais</nz-form-label>
                    <nz-form-control [nzSpan]="15">
                        <nz-select nzShowSearch nzAllowClear
                                   [nzDisabled]="allGroups"
                                   nzMode="multiple"
                                   nzPlaceHolder="{{allGroups ? 'Todos' : 'Selecione'}}"
                                   formControlName="grupos">
                            <nz-option *ngFor="let opt of arraySelectGrupos"
                                       [nzLabel]="opt.descricao"
                                       [nzValue]="opt.id"
                            ></nz-option>
                        </nz-select>
                        <label (nzCheckedChange)="onChecked($event)" style="margin-top: 5px; margin-left: 190px"
                               [(nzChecked)]="allGroups"
                               nz-checkbox>Selecionar todos os grupos</label>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="6">Unidade</nz-form-label>
                    <nz-form-control [nzSpan]="15" nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrosEmpresas.formGroup.value.cnpj">
                        <nz-select nzShowSearch nzAllowClear
                                   nzPlaceHolder="Selecione"
                                   formControlName="cnpj">
                            <nz-option *ngFor="let opt of arraySelectEmpresas"
                                       [nzLabel]="modoDemo(opt.descricao, 'empresaCodigoNome') + ' (' + (modoDemo(opt.empresa_cnpj | cpfCnpj, 'cnpj')) + ')'"
                                       [nzValue]="opt.id"
                            ></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="6">IE</nz-form-label>
                    <nz-form-control [nzSpan]="15" nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrosEmpresas.formGroup.value.ie != null && formFiltrosEmpresas.formGroup.value.ie != ''">
                        <nz-input-group [nzSuffix]="inputClearIE">
                            <input nz-input
                                   formControlName="ie" type="text"
                                   autocomplete="off">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
                <div nz-row>
                    <nz-form-label nz-col [nzSpan]="6">Última Consulta</nz-form-label>
                    <nz-form-item nz-col [nzSpan]="7">
                        <nz-form-control nzValidateStatus="success"
                                         [nzHasFeedback]="formFiltrosEmpresas.formGroup.value.minUltimaConsultaData">
                            <nz-date-picker
                                    [nzShowTime]="{ nzFormat: 'HH:mm' }"
                                    formControlName="minUltimaConsultaData"
                                    nzShowTime
                                    nzFormat="dd/MM/yyyy HH:mm"
                                    nzPlaceHolder="De:"
                            ></nz-date-picker>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item nz-col [nzSpan]="1"></nz-form-item>
                    <nz-form-item nz-col [nzSpan]="7">
                        <nz-form-control nzValidateStatus="success"
                                         [nzHasFeedback]="formFiltrosEmpresas.formGroup.value.maxUltimaConsultaData">
                            <nz-date-picker
                                    [nzShowTime]="{ nzFormat: 'HH:mm' }"
                                    formControlName="maxUltimaConsultaData"
                                    nzShowTime
                                    nzFormat="dd/MM/yyyy HH:mm"
                                    nzPlaceHolder="Até:"
                            ></nz-date-picker>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-row>
                    <nz-form-label nz-col [nzSpan]="6">Quantidade</nz-form-label>
                    <nz-form-item nz-col [nzSpan]="7">
                        <nz-form-control nzValidateStatus="success"
                                         [nzHasFeedback]="formFiltrosEmpresas.formGroup.value.minQuantidade != null && formFiltrosEmpresas.formGroup.value.minQuantidade != ''">
                            <nz-input-group [nzSuffix]="inputClearMinQuantidade">
                                <input nz-input
                                       (keydown)="keyPressNumber($event)"
                                       min="0"
                                       nzPlaceHolder="De:"
                                       formControlName="minQuantidade"
                                       autocomplete="off">
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item nz-col [nzSpan]="1"></nz-form-item>
                    <nz-form-item nz-col [nzSpan]="7">
                        <nz-form-control nzValidateStatus="success"
                                         [nzHasFeedback]="formFiltrosEmpresas.formGroup.value.maxQuantidade != null && formFiltrosEmpresas.formGroup.value.maxQuantidade != ''">
                            <nz-input-group [nzSuffix]="inputClearMaxQuantidade">
                                <input nz-input
                                       (keydown)="keyPressNumber($event)"
                                       nzPlaceHolder="Até:"
                                       formControlName="maxQuantidade"
                                       min="0"
                                       autocomplete="off"
                                >
                            </nz-input-group>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </form>
        </nz-col>
    </nz-row>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="fechar(formFiltrosEmpresas)">Cancelar</button>
        <button nz-button nzType="primary" (click)="filtrarEmpresas()">Filtrar</button>
    </div>
    <ng-template #inputClearIE>
      <span
              nz-icon
              class="ant-input-clear-icon"
              nzTheme="fill"
              nzType="close-circle"
              *ngIf="formFiltrosEmpresas.formGroup.value.ie"
              (click)="formFiltrosEmpresas.formGroup.get('ie').setValue(null)"
      ></span>
    </ng-template>
    <ng-template #inputClearMinQuantidade>
      <span
              nz-icon
              class="ant-input-clear-icon"
              nzTheme="fill"
              nzType="close-circle"
              *ngIf="formFiltrosEmpresas.formGroup.value.minQuantidade"
              (click)="formFiltrosEmpresas.formGroup.get('minQuantidade').setValue(null)"
      ></span>
    </ng-template>
    <ng-template #inputClearMaxQuantidade>
      <span
              nz-icon
              class="ant-input-clear-icon"
              nzTheme="fill"
              nzType="close-circle"
              *ngIf="formFiltrosEmpresas.formGroup.value.maxQuantidade"
              (click)="formFiltrosEmpresas.formGroup.get('maxQuantidade').setValue(null)"
      ></span>
    </ng-template>
</nz-modal>

<nz-modal [(nzVisible)]="formFiltrosObrigacoes.modalVisible"
          [nzTitle]="'Filtros avançados'" [nzClosable]="true"
          (nzOnCancel)="fechar(formFiltrosObrigacoes)"
          [nzWidth]="720">
    <nz-row *nzModalContent>
        <nz-col [nzSpan]="24">
            <form nz-form [nzNoColon]="false" [formGroup]="formFiltrosObrigacoes.formGroup">
                <nz-form-item>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="3">Obrigação</nz-form-label>
                    <nz-form-control [nzSpan]="18" nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrosObrigacoes.formGroup.value.obrigacao">
                        <nz-select nzShowSearch
                                   nzAllowClear
                                   nzPlaceHolder="Selecione"
                                   formControlName="obrigacao">
                            <nz-option *ngFor="let opt of arraySelectObrigacoes"
                                       [nzLabel]="opt.descricao"
                                       [nzValue]="opt.id"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
            </form>
        </nz-col>
    </nz-row>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="fechar(formFiltrosObrigacoes)">Cancelar</button>
        <button nz-button nzType="primary" (click)="filtrarObrigacoes(empresaSelecionada)">Filtrar</button>
    </div>
</nz-modal>

<ng-template #navBar>
    <div class="d-flex align-items-center">
        <button disabled="{{loadingEmpresas || loadingObrigacoes}}" style="margin-right: 10px"
                (click)="exibirTudo(!expandAll)" nz-button>
            <em *ngIf="expandAll" class="fas fa-angle-double-up"></em>
            <em *ngIf="!expandAll" class="fas fa-angle-double-down"></em>
        </button>
        <nz-badge [nzCount]="qtdFiltrosAtivoscardOne" *ngIf="tabNumber == 0" style="margin-right: 10px;"
                  [nzStyle]="{ backgroundColor: '#52c41a' }">
            <button disabled="{{loadingEmpresas}}" nz-button nzType="primary" (click)="abrirModal(formFiltrosEmpresas)">
                <i nz-icon nzType="search"></i>
                Filtros
            </button>
        </nz-badge>
        <nz-badge [nzCount]="qtdFiltrosAtivoscardTwo" *ngIf="tabNumber == 1" style="margin-right: 10px;"
                  [nzStyle]="{ backgroundColor: '#52c41a' }">
            <button disabled="{{loadingObrigacoes}}" nz-button nzType="primary"
                    (click)="abrirModal(formFiltrosObrigacoes)">
                <i nz-icon nzType="search"></i>
                Filtros
            </button>
        </nz-badge>
        <button disabled="{{loadingEmpresas || loadingObrigacoes}}"
                [nzDropdownMenu]="menuAcoes" class="centralized-icon" nz-button nz-dropdown
                nzType="default" style="margin-right: 10px;">
            Opções
            <em nz-icon nzType="down"></em>
        </button>
        <nz-dropdown-menu #menuAcoes="nzDropdownMenu">
            <ul nz-menu>
                <li *ngIf="tabNumber == 1" nz-submenu nzTitle="Manutenção Coletiva">
                    <ul>
                        <li nz-menu-item
                            (click)="checkedItems?.size > 0 && modalSolicitarBaixa(true)"
                            [nzDisabled]="checkedItems?.size === 0 || loadingSolicitarBaixa">
                            Solicitar Baixa
                        </li>
                    </ul>
                </li>
                <li nz-menu-item nz-button (click)="modalExportar(true);">Exportar Planilha</li>
                <li *ngIf="currentUser?.origem === 'console' && tabNumber === 1"
                    nz-menu-item nz-button (click)="modalSolicitarBaixaDarfManual(true);">Solicitar Baixa Manual DARF
                </li>
            </ul>
        </nz-dropdown-menu>
        <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" style="margin-right: 10px;">
            <input type="text" nz-input placeholder="{{tabNumber == 0 ? 'Empresa/CNPJ' : 'Obrigação'}}"
                   [(ngModel)]="currentSearch" (keydown.enter)="buscar()"/>
        </nz-input-group>
        <ng-template #suffixIconButton>
            <button disabled="{{loadingEmpresas || loadingObrigacoes}}" nz-button nzType="primary" nzSearch
                    (click)="buscar()"><i
                    nz-icon nzType="search"></i></button>
        </ng-template>
        <button disabled="{{loadingEmpresas || loadingObrigacoes}}" nz-button nzType="default"
                style="margin-right: 10px; padding-left: 8px; padding-right: 8px;"
                (click)="buscar()"
                title="Atualizar">
            <i nz-icon nzType="reload"></i>
        </button>
        <button disabled="{{loadingEmpresas || loadingObrigacoes}}" nz-button nzType="default"
                style="margin-right: 10px;" (click)="limparFiltros()">
            Limpar
        </button>
        <button nz-button [nzType]="'default'" (click)="clickEvent()"
                style="padding-left: 8px; padding-right: 8px;">
            <i class="fas" [ngClass]="statusCard ? 'fa-compress' : 'fa-expand'"></i>
        </button>
    </div>
</ng-template>

<nz-modal [(nzVisible)]="formExportar.modalVisible"
          [nzTitle]="'Exportar'" [nzClosable]="true"
          (nzOnCancel)="modalExportar(false)"
          [nzWidth]="425">
    <div nz-row *nzModalContent>
        <form nz-form [nzNoColon]="true" [nzAutoTips]="autoTips" [formGroup]="formExportar.formGroup"
              style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="24" nzRequired>
                    Selecione o formato do arquivo a ser exportado.
                </nz-form-label>
                <nz-form-control [nzSpan]="24">
                    <nz-select nzPlaceHolder="Selecione"
                               [disabled]="loadingExportar"
                               formControlName="tipo" style="width: 100%;">
                        <nz-option [nzLabel]="'.CSV'" [nzValue]="'csv'"></nz-option>
                        <nz-option [nzLabel]="'.XLSX'" [nzValue]="'xlsx'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalExportar(false);">Cancelar</button>
        <button [nzLoading]="loadingExportar" nz-button nzType="primary" (click)="confirmaExportar()">Exportar</button>
    </div>
</nz-modal>
