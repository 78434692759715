import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {AbstractService} from '@services/abstract.service';
import {Observable} from 'rxjs';
import {Helpers} from '../../../core/helpers';


@Injectable({providedIn: 'root'})
export class CheckNotasService extends AbstractService<any> {

    url = environment.apiUrl + '/compliance/notas/analises';

    constructor(http: HttpClient) {
        super(http, '/compliance/obrigacoes', {});
    }

    retornarSelectsEmpresas(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/empresa/select`);
    }

    retornaDados(filtros: any): Observable<any> {

        if (filtros.dataInicio) {
            filtros.dataInicio = this.formataDateBD(filtros.dataInicio);
        }
        if (filtros.dataFim) {
            filtros.dataFim = this.formataDateBD(filtros.dataFim);
        }


        const queryStr = Helpers.montaQueryString(filtros);

        return this.http.get(`${environment.apiUrl}/compliance/notas/analises${queryStr}`);

    }

    solicitarAnalise(dados: any): Observable<any> {

        if (dados.dataInicio) {
            dados.dataInicio = this.formataDateBD(dados.dataInicio);
        }
        if (dados.dataFim) {
            dados.dataFim = this.formataDateBD(dados.dataFim);
        }

        return this.http.post(`${environment.apiUrl}/compliance/notas/analise`, dados);

    }

    reprocessar(ids): Observable<any> {
        return this.http.post(`${environment.apiUrl}/compliance/notas/analises/reprocessar`, ids);
    }

    remover(ids): Observable<any> {
        return this.http.post(`${environment.apiUrl}/compliance/notas/analises/remover`, ids);
    }

    formataDateBD(valor: string): string {

        const data = new Date(valor);
        let retorno = '';
        if (valor && typeof data.getMonth === 'function') {
            retorno = data.getFullYear() +
                '-' + this.mesStr(data.getMonth() + 1) +
                '-' + this.mesStr(data.getDate());
        } else {
            retorno = valor.toString();
        }

        return retorno;
    }

    mesStr(mes: number): any {
        let mesStr;
        if (mes < 10) {
            mesStr = '0' + mes;
        } else {
            mesStr = mes.toString();
        }

        return mesStr;
    }

    retornaComboGruposEmpresariais(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/gruposEmpresariais/select`);
    }

    novoMarcador(marcador: string, cor: string, ids: string[]): Observable<any> {

        const dados: any = {
            marcador,
            ids
        };

        if (cor) {
            dados.cor = cor;
        }


        return this.http.post(`${environment.apiUrl}/compliance/notas/analises/marcadores`, dados);
    }

    removerMarcador(marcador: string, ids: string[]): Observable<any> {
        return this.http.post(`${environment.apiUrl}/compliance/notas/analises/marcadores/remover`, {marcador, ids});
    }

    exportExcel(data: any): any {

        if (!data.status.length) {
            delete data.status;
        }

        return this.http.post(`${environment.apiUrl}/exportador`, data);
    }

    getMarcadores(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/compliance/notas/analises/marcadores`);
    }

    deleteMarcador(marcador: string): any {

        return this.http.post(`${environment.apiUrl}/compliance/notas/analises/marcadores/excluir`, {ids: [marcador]});

    }
}
