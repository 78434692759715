import {Input, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import {ComplianceTributosGruposDetalhesComponent} from './compliance-tributos-grupos-detalhes.component';
import {ComplianceTributosEmpresasModule} from '../complianceTributosEmpresas/complianceTributosEmpresas.module';



@NgModule({
  declarations: [ ComplianceTributosGruposDetalhesComponent ],
    imports: [
        CommonModule,
        ComplianceTributosEmpresasModule
    ]
})
export class ComplianceTributosGruposDetalhesModule {

    @Input() data;
}
