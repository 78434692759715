<sass-helper></sass-helper>
<div class="header"
     [ngStyle]="{'background-color': backgroundColor,
     'border-bottom': '5px solid ' + highlightColor}">
    <div class="header-logo">
        <img alt="logo" src="{{miniLogoUrl}}"/>
    </div>
    <app-megamenu class="header-menu"></app-megamenu>
    <Tabset class="header-tabs" [ngClass]="semTabs ? 'esconder' : ''"></Tabset>
 
    <div style="display: flex;" *ngIf="showNotificacoes">
        <notify-app  class="notify"></notify-app>
        <tasks-app *ngIf="showNotificacoes" class="tasks"></tasks-app>
    </div>
   
    <button *ngIf="notificacaoAtivada" nz-button nzType="link" (click)="refresh()" style="margin-right: 25px;"
            nz-tooltip="Atualização disponível, clique aqui para recarregar a página.">
        <i nz-icon nzType="exclamation-circle" nzTheme="outline" style="color: rgb(255, 166, 70); font-size: 24px;"></i>
    </button>
    <div class="header-user">
        <user-menu (criarNotificacao)="exibirNotificacao()"></user-menu>
    </div>
</div>
<ng-template #notifyAtualization id="notifyAtualization">
    <div class="ant-notification-notice-content">
        <div>
            <div class="ant-notification-notice-message">
                <i nz-icon nzType="info-circle" nzTheme="outline"
                   style="color: rgb(255, 166, 70); font-size: 21px; margin-right: 5px;"></i>
                Novas atualizações disponíveis
            </div>
            <div class="ant-notification-notice-description">
                Clique no botão abaixo para recarregar a página.
            </div>
            <span class="ant-notification-notice-btn">
            <button nz-button nzType="primary" nzSize="small" (click)="refresh()"
                    style="background-color: rgb(94, 80, 71); border: none;">
              <span>Recarregar</span>
            </button>
          </span>
        </div>
    </div>
</ng-template>
<ng-template #closeIcon id="closeIcon">
    <button nz-button nzType="text" nzSize="small" (click)="fecharNotificacao()">
        <i nz-icon nzType="close" nzTheme="outline"></i>
    </button>
</ng-template>
