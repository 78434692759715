import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AbstractService} from './abstract.service';
import {Observable, Subject} from 'rxjs';
import {environment} from '../../../environments/environment';
import {Obrigacao} from '@models/obrigacao.model';
import {NzTableQueryParams} from 'ng-zorro-antd/table';

@Injectable({providedIn: 'root'})
export class ObrigacaoService extends AbstractService<Obrigacao> {

    constructor(http: HttpClient) {
        super(http, '/cadastros/obrigacoes', Obrigacao);
    }

    // tslint:disable-next-line:variable-name
    getObrigacoes(params: any, search: string = null, empresa_id: string, ano: string): Observable<any> {

        const {pageSize, pageIndex, sort, filter} = params;
        const currentSort = sort.find(item => item.value !== null);
        const sortField = (currentSort && currentSort.key) || null;
        const sortOrder = (currentSort && currentSort.value) || null;

        const queryParams = [];

        if (pageIndex !== null && typeof pageIndex !== 'undefined') {
            queryParams.push(`pagina=${pageIndex}`);
        }

        if (pageSize !== null && typeof pageSize !== 'undefined') {
            queryParams.push(`quantidade=${pageSize}`);
        } else {
            queryParams.push(`quantidade=10`);
        }

        if (sortField !== null && typeof sortField !== 'undefined') {
            queryParams.push(`ordenar=${sortField}`);
            queryParams.push(`sentido=${sortOrder}`);
        }

        if (search !== null && typeof search !== 'undefined' && search.length > 0) {
            queryParams.push(`procurar=${search}`);
        }

        if (params.filter) {

            params.filter.forEach((obj) => {

                if (obj === 'calendar' || obj === 'check' || obj === 'compliance') {

                    queryParams.push('modulos[]' + '=' + obj);

                } else if (obj.key && obj.value) {

                    queryParams.push(obj.key + '=' + obj.value);

                }

            });

        }

        const queryString = queryParams.length > 0 ? '?' + queryParams.join('&') : '';

        return this.http.get(`${environment.apiUrl}/cadastros/obrigacoes-empresas/vencimentos/empresa/${empresa_id}/${ano}${queryString}`);

    }


    save(campos): Observable<any> {

        return this.http.post(`${environment.apiUrl}/cadastros/obrigacao`, campos);

    }

    update(id, campos): Observable<any> {

        if (campos.bloqueado) {
            campos.bloqueado === true ? campos.bloqueado = 'SIM' : campos.bloqueado = 'NAO';
        }
        if (campos.obrigacaoCompliance) {
            campos.obrigacaoCompliance === true ? campos.obrigacaoCompliance = 'SIM' : campos.obrigacaoCompliance = 'NAO';
        }

        if (campos.temAgenda) {
            campos.cadastrarAgenda = true;
        } else {
            campos.cadastrarAgenda = false;
        }

        return this.http.put(`${environment.apiUrl}/cadastros/obrigacao/detalhe/${id}`, campos);

    }

    remove(id: any): Observable<any> {

        return this.http.delete(`${environment.apiUrl}/cadastros/obrigacao/vencimento/${id}`);

    }

    updateVencimento(idObrigacao: string, campos): Observable<any> {

        campos.agendaConfirmavel === 'Sim' ? campos.agendaConfirmavel = true : campos.agendaConfirmavel = false;

        campos.vencimento = this.formataDateBD(campos.vencimento);


        if (campos.competencia && campos.competencia.length === 7) {
            const mes = campos.competencia.substr(0, 2);
            const ano = campos.competencia.substr(3, 4);
            campos.competencia = ano + '-' + mes;

        } else if (campos.competencia && campos.competencia.length === 6) {
            const mes = campos.competencia.substr(0, 2);
            const ano = campos.competencia.substr(2, 4);
            campos.competencia = ano + '-' + mes;
        }

        return this.http.put(`${environment.apiUrl}/cadastros/obrigacao/vencimento/detalhe/${campos.id}`, campos);

    }

    mesStr(mes: number): any {
        let mesStr;
        if (mes < 10) {
            mesStr = '0' + mes;
        } else {
            mesStr = mes.toString();
        }

        return mesStr;
    }

    formataDateBD(valor: string): string {

        const data = new Date(valor);
        let retorno = '';
        if (valor && typeof data.getMonth === 'function') {
            retorno = data.getFullYear() +
                '-' + this.mesStr(data.getMonth() + 1) +
                '-' + this.mesStr(data.getDate());
        } else {
            retorno = valor.toString();
        }

        return retorno;
    }


    insertVencimento(idObrigacao: string, campos): Observable<any> {

        campos.obrigacao_id = idObrigacao;

        campos.vencimento = this.formataDateBD(campos.vencimento);

        campos.agendaConfirmavel === 'Sim' ? campos.agendaConfirmavel = true : campos.agendaConfirmavel = false;

        if (campos.competencia && campos.competencia.length === 7) {
            const mes = campos.competencia.substr(0, 2);
            const ano = campos.competencia.substr(3, 4);
            campos.competencia = ano + '-' + mes;

        } else if (campos.competencia && campos.competencia.length === 6) {
            const mes = campos.competencia.substr(0, 2);
            const ano = campos.competencia.substr(2, 4);
            campos.competencia = ano + '-' + mes;
        }

        return this.http.post(`${environment.apiUrl}/cadastros/obrigacao/vencimento`, campos);

    }

    // tslint:disable-next-line:variable-name
    insertVencimentosEmLote(obrigacao: string, anos): Observable<any> {

        return this.http.post(`${environment.apiUrl}/cadastros/obrigacao/vencimentos-lote`, {obrigacao, anos});

    }

    findById(id: string): Observable<any> {

        return this.http.get(`${environment.apiUrl}/cadastros/obrigacao/detalhe/${id}`);

    }

    montaQueryString(filtros): string {
        const queryParams = [];
        for (const [key, value] of Object.entries(filtros)) {
            if (value) {
                queryParams.push(key + '=' + value);
            }
        }
        return queryParams.length > 0 ? '?' + queryParams.join('&') : '';
    }


    retornaEstados(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/estados/select`);

    }

    retornaCategorias(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/cadastros/obrigacao/categorias`);

    }

    retornaAnos(obrigacao: any): Observable<any> {

        return this.http.get(`${environment.apiUrl}/cadastros/obrigacao/vencimentos/anos/${obrigacao}`);

    }

    retornaMunicipios(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/cidades/select`);

    }

    retornaComboMunicipios(uf: string = null): Observable<any> {

        if (uf) {
            return this.http.get(`${environment.apiUrl}/filtros/cidades/select/${uf}`);
        } else {
            return this.http.get(`${environment.apiUrl}/filtros/cidades/select`);
        }

    }

    retornaVencimentosSugeridos(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/ultimos-cinco-anos`);
    }

    excluirObrigacaoManual(id: string): Observable<any> {

        return this.http.delete(`${environment.apiUrl}/cadastros/obrigacao/obrigacao-manual/remover/${id}`);

    }

    retornaComboAnos(cnpj: string): Observable<any> {

        return this.http.get(`${environment.apiUrl}/cadastros/obrigacoes-empresas/competencia/anos/${cnpj}`);

    }

    cadastroEmMassa(data): Observable<any> {

        return this.http.put(`${environment.apiUrl}/cadastros/obrigacao/alteracao-em-massa`, data);

    }

    cadastroVencimentosEmLote(data): Observable<any> {

        return this.http.post(`${environment.apiUrl}/cadastros/obrigacao/vencimentos-lote`, data);

    }


}
