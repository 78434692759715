import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import Highcharts from 'highcharts';
import {FormBuilder, Validators} from '@angular/forms';
import {DashboardService} from './dashboard.service';
import {ResponsaveisService} from '../../cadastros/responsaveis/responsaveis.service';
import {GrupoEmpresarialService} from '@services/grupoEmpresarial.service';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

    loadings = {
        principal: true,
        porEmpresa: true,
        porAno: true,
        top20: true,
        porGrupo: true
    };

    filtroRealizado = false;

    statusCard = false;
    qtdFiltrosAtivos = 0;

    periodos = [];
    comboResponsaveis = [];

    highChart: Highcharts.Chart | null;

    chartOptionsEmpresa: Highcharts.Options;
    chartOptionsGroupo: Highcharts.Options;
    chartOptionsPorAno: Highcharts.Options;
    chartOptionsTop20: Highcharts.Options;

    Highcharts: typeof Highcharts = Highcharts;

    updateFlag: boolean;
    updateFlagBassrasAgrupadas: boolean;

    formFiltrar = this.fb.group({
        email: [null],
        periodo_atual: [null, Validators.required],
        periodo_anterior: [null, Validators.required],
        grupo: [null],
        empresa: [null],
    });

    autoTips: Record<string, Record<string, string>> = {
        default: {
            required: 'Campo obrigatório'
        }
    };

    formFilterVisible = false;

    grupoEmpresarialOptions: { label: string; value: string }[] = [];
    arraySelectEmpresa = [];

    constructor(
        private fb: FormBuilder,
        private service: DashboardService,
        private cdr: ChangeDetectorRef,
        private grupoEmpresarialService: GrupoEmpresarialService,
        private responsaveisService: ResponsaveisService,
        private toastrService: ToastrService
    ) {
    }

    ngOnInit() {
        this.getGruposEmpresariais();

        this.service.retornarSelectsEmpresas(
        ).subscribe((retorno: any) => {
            this.arraySelectEmpresa = retorno.empresaNome;
        });

        this.getResponssaveis();
        this.getPeriodos();
    }

    getPeriodos(email: string | null = null) {

        this.service.getPeriodos(email).subscribe({
            next: (res) => {
                this.periodos = res;
            },
            error: (err) => {
                this.toastrService.error(err.error.message);
            }
        });
    }

    async getGruposEmpresariais() {

        return this.grupoEmpresarialService.listToSelect().subscribe((result) => {
            this.grupoEmpresarialOptions = result.map((option: any) => ({
                label: option.descricao,
                value: option.id
            }));
        });

    }

    getResponssaveis() {

        this.responsaveisService.retornaComboResponsaveisEmail().subscribe((retorno: any) => {
            this.comboResponsaveis = retorno;
        });

    }

    clickEvent() {
        this.statusCard = !this.statusCard;
    }

    calculaBadgeFiltros(): void {

        this.qtdFiltrosAtivos = 0;

        Object.entries(this.formFiltrar.value).forEach((value) => {

            if (value[1] && (value[0] !== 'ano' && value[0] !== 'sort' && value[0] !== 'page' && value[0] !== 'chave')) {
                this.qtdFiltrosAtivos += 1;
            }
        });

    }

    getGraficos() {

        this.graficoNFNaoEscrituradasPorEmpresa();

        this.graficoNFNaoEscrituradasPorAno();

        this.graficoNFNaoEscrituradasTop20();

        this.graficoNFNaoEscrituradasPorGrupoEmpresarial();

        this.cdr.detectChanges();
    }

    graficoNFNaoEscrituradasPorEmpresa() {

        this.loadings.porEmpresa = true;

        const filtros = this.ajustaPeriodoParaAPI(this.formFiltrar.value);

        this.service.graficoPorEmpresa(filtros).subscribe({
            next: (res) => {
                const resultEmpresas = {};

                res.periodoAtual.forEach(p => {
                    resultEmpresas[p.empresa_nome] = {};
                    resultEmpresas[p.empresa_nome].periodoAtual = p.quantidade;
                });

                res.periodoAnterior.forEach(p => {
                    if (!resultEmpresas[p.empresa_nome]) {
                        resultEmpresas[p.empresa_nome] = {};
                    }

                    resultEmpresas[p.empresa_nome].periodoAnterior = p.quantidade;
                });

                const empresas = Object.keys(resultEmpresas);

                const responseSeries: any = [
                    {
                        name: 'Período Anterior',
                        type: 'column',
                        stack: 1,
                        data: [],
                        color: 'rgb(238,80,100)'
                    },
                    {
                        name: 'Período Atual',
                        type: 'column',
                        stack: 1,
                        data: [],
                        color: 'rgba(102, 110, 232)'
                    }
                ];

                empresas.forEach(e => {
                    responseSeries[0].data.push(resultEmpresas[e].periodoAnterior ? Number(resultEmpresas[e].periodoAnterior) : 0);
                    responseSeries[1].data.push(resultEmpresas[e].periodoAtual ? Number(resultEmpresas[e].periodoAtual) : 0);
                });

                this.chartOptionsEmpresa = {
                    chart: {
                        type: 'bar',
                        zoomType: 'xy'
                    },
                    title: {
                        text: 'Notas Ficais Não Escrituradas Por Empresa'
                    },
                    xAxis: [{
                        categories: empresas,
                        crosshair: true,
                    }],
                    yAxis: [{
                        title: {
                            text: 'Qtd. Notas',
                            style: {
                                color: Highcharts.getOptions().colors[0]
                            },
                        },
                    }, {
                        title: {
                            text: 'Valor',
                            style: {
                                color: Highcharts.getOptions().colors[0]
                            }
                        },
                        labels: {
                            format: '',
                        },
                        opposite: true
                    }],
                    tooltip: {
                        enabled: false
                    },
                    legend: {
                        layout: 'vertical',
                        align: 'left',
                        x: 60,
                        verticalAlign: 'top',
                        y: 0,
                        floating: true,
                        backgroundColor: '#FFFFFF'
                    },
                    series: []
                };

                responseSeries.forEach((value) => {
                    value.dataLabels = {
                        enabled: true,
                        format: '{point.y}',
                        color: '#303030',
                        borderColor: 'transparent',
                        style: {
                            fontWeight: 'bold',
                            fontSize: '14px',
                            marginTop: '25px',
                            marginRight: '25px',
                        }
                    };
                    this.chartOptionsEmpresa.series.push(value);
                });

                this.loadings.porEmpresa = false;

            }, error: (err) => {
                this.loadings.porEmpresa = false;
                this.toastrService.error(err.error.message);
            }
        });
    }

    graficoNFNaoEscrituradasPorAno() {

        this.loadings.porAno = true;

        const filtros = this.ajustaPeriodoParaAPI(this.formFiltrar.value);

        this.service.graficoPorAno(filtros).subscribe({
            next: (res) => {

                const resultAnos = {};

                res.message.periodoAtual.forEach(p => {
                    resultAnos[p.ano] = {};
                    resultAnos[p.ano].periodoAtual = p.quantidade;
                });

                res.message.periodoAnterior.forEach(p => {
                    if (!resultAnos[p.ano]) {
                        resultAnos[p.ano] = {};
                    }

                    resultAnos[p.ano].periodoAnterior = p.quantidade;
                });

                const anos = Object.keys(resultAnos);

                const responseSeries: any = [
                    {
                        name: 'Período Anterior',
                        type: 'column',
                        stack: 1,
                        data: [],
                        color: 'rgb(238,80,100)'
                    },
                    {
                        name: 'Período Atual',
                        type: 'column',
                        stack: 1,
                        data: [],
                        color: 'rgba(102, 110, 232)'
                    }
                ];

                anos.forEach(a => {
                    responseSeries[0].data.push(resultAnos[a].periodoAnterior ? Number(resultAnos[a].periodoAnterior) : 0);
                    responseSeries[1].data.push(resultAnos[a].periodoAtual ? Number(resultAnos[a].periodoAtual) : 0);
                });

                this.chartOptionsPorAno = {
                    chart: {
                        type: 'column',
                        zoomType: 'xy'
                    },
                    title: {
                        text: 'Notas Ficais Não Escrituradas Por Ano'
                    },
                    xAxis: [{
                        categories: anos,
                        crosshair: true,
                    }],
                    yAxis: [{
                        title: {
                            text: 'Qtd. Notas',
                            style: {
                                color: Highcharts.getOptions().colors[0]
                            },
                        },
                    }, {
                        title: {
                            text: 'Valor',
                            style: {
                                color: Highcharts.getOptions().colors[0]
                            }
                        },
                        labels: {
                            format: '',
                        },
                        opposite: true
                    }],
                    tooltip: {
                        enabled: false
                    },
                    legend: {
                        layout: 'vertical',
                        align: 'left',
                        x: 60,
                        verticalAlign: 'top',
                        y: 0,
                        floating: true,
                        backgroundColor: '#FFFFFF'
                    },
                    series: []
                };

                responseSeries.forEach((value) => {
                    value.dataLabels = {
                        enabled: true,
                        format: '{point.y}',
                        color: '#303030',
                        borderColor: 'transparent',
                        style: {
                            fontWeight: 'bold',
                            fontSize: '14px',
                            marginTop: '25px',
                            marginRight: '25px',
                        }
                    };
                    this.chartOptionsPorAno.series.push(value);
                });

                this.loadings.porAno = false;

            }, error: (err) => {

                this.loadings.porAno = false;
                this.toastrService.error(err.error.message);
            }
        });
    }

    graficoNFNaoEscrituradasTop20() {

        this.loadings.top20 = true;

        const filtros = this.ajustaPeriodoParaAPI(this.formFiltrar.value);

        this.service.graficoTopFiliais(filtros).subscribe({
            next: (res) => {

                const empresas = [];

                const responseSeries: any = [
                    {
                        name: 'qtd',
                        type: 'column',
                        stack: 1,
                        data: [],
                        color: '#ff9828'
                    },

                ];

                res.message.forEach(item => {
                    empresas.push(item.empresa_nome);
                    responseSeries[0].data.push(item.quantidade ? Number(item.quantidade) : 0);
                });

                this.chartOptionsTop20 = {
                    chart: {
                        zoomType: 'xy'
                    },
                    plotOptions: {},
                    title: {
                        text: 'Notas Ficais Não Escrituradas (Top 20 Empresas)'
                    },
                    xAxis: [{
                        categories: empresas,
                        crosshair: true,
                    }],
                    yAxis: [{
                        title: {
                            text: 'Qtd. Notas',
                            style: {
                                color: Highcharts.getOptions().colors[0]
                            },
                        },
                    }],
                    tooltip: {
                        enabled: false
                    },
                    legend: {
                        enabled: false
                    },
                    series: []
                };

                responseSeries.forEach((value) => {
                    value.dataLabels = {
                        enabled: true,
                        format: '{point.y}',
                        color: '#303030',
                        borderColor: 'transparent',
                        style: {
                            fontWeight: 'bold',
                            fontSize: '14px',
                            marginTop: '25px',
                            marginRight: '25px',
                        }
                    };
                    this.chartOptionsTop20.series.push(value);
                });

                this.loadings.top20 = false;

            },
            error: (err) => {

                this.loadings.top20 = false;
                this.toastrService.error(err.error.message);

            }
        });

    }

    graficoNFNaoEscrituradasPorGrupoEmpresarial() {

        this.loadings.porGrupo = true;

        const filtros = this.ajustaPeriodoParaAPI(this.formFiltrar.value);

        this.service.graficoPorGrupoEmpresarial(filtros).subscribe({
            next: (res) => {

                const resultGrupos = {};

                res.message.periodoAtual.forEach(g => {
                    resultGrupos[g.grupoEmpresarial_descricao] = {};
                    resultGrupos[g.grupoEmpresarial_descricao].periodoAtual = g.quantidade;
                });

                res.message.periodoAnterior.forEach(g => {
                    if (!resultGrupos[g.grupoEmpresarial_descricao]) {
                        resultGrupos[g.grupoEmpresarial_descricao] = {};
                    }

                    resultGrupos[g.grupoEmpresarial_descricao].periodoAnterior = g.quantidade;
                });

                const grupos = Object.keys(resultGrupos);

                const responseSeries: any = [
                    {
                        name: 'Período Anterior',
                        type: 'column',
                        stack: 1,
                        data: [],
                        color: 'rgb(238,80,100)'
                    },
                    {
                        name: 'Período Atual',
                        type: 'column',
                        stack: 1,
                        data: [],
                        color: 'rgba(102, 110, 232)'
                    }
                ];

                grupos.forEach(g => {
                    responseSeries[0].data.push(resultGrupos[g].periodoAnterior ? Number(resultGrupos[g].periodoAnterior) : 0);
                    responseSeries[1].data.push(resultGrupos[g].periodoAtual ? Number(resultGrupos[g].periodoAtual) : 0);
                });

                this.chartOptionsGroupo = {
                    chart: {
                        type: 'bar',
                        zoomType: 'xy'
                    },
                    title: {
                        text: 'Notas Ficais Não Escrituradas Por Grupo Empresarial'
                    },
                    xAxis: [{
                        categories: grupos,
                        crosshair: true,
                    }],
                    yAxis: [{
                        title: {
                            text: 'Qtd. Notas',
                            style: {
                                color: Highcharts.getOptions().colors[0]
                            },
                        },
                    }, {
                        title: {
                            text: 'Valor',
                            style: {
                                color: Highcharts.getOptions().colors[0]
                            }
                        },
                        labels: {
                            format: '',
                        },
                        opposite: true
                    }],
                    tooltip: {
                        enabled: false
                    },
                    legend: {
                        layout: 'vertical',
                        align: 'left',
                        x: 60,
                        verticalAlign: 'top',
                        y: 0,
                        floating: true,
                        backgroundColor: '#FFFFFF'
                    },
                    series: []
                };

                responseSeries.forEach((value) => {
                    value.dataLabels = {
                        enabled: true,
                        format: '{point.y}',
                        color: '#303030',
                        borderColor: 'transparent',
                        style: {
                            fontWeight: 'bold',
                            fontSize: '14px',
                            marginTop: '25px',
                            marginRight: '25px',
                        }
                    };
                    this.chartOptionsGroupo.series.push(value);
                });


                this.loadings.porGrupo = false;

            }, error: (err) => {

                this.loadings.porGrupo = false;
                this.toastrService.error(err.error.message);
            }
        });
    }

    modalFiltrar(visible) {

        if (!visible && !this.filtroRealizado) {

            Object.values(this.formFiltrar.controls).forEach(control => {
                if (control.invalid) {
                    control.markAsDirty();
                    control.updateValueAndValidity({onlySelf: true});
                }
            });

            return;
        }

        this.formFilterVisible = visible;
    }

    confirmaFiltrar() {

        if (this.formFiltrar.valid) {

            this.calculaBadgeFiltros();
            this.filtroRealizado = true;
            this.modalFiltrar(false);
            this.getGraficos();
            this.loadings.principal = false;


        } else {
            Object.values(this.formFiltrar.controls).forEach(control => {
                if (control.invalid) {
                    control.markAsDirty();
                    control.updateValueAndValidity({onlySelf: true});
                }
            });

        }
    }

    ajustaPeriodoParaAPI(filtros) {

        filtros.periodoAtualInicio = filtros.periodo_atual.split(',')[0];
        filtros.periodoAtualFim = filtros.periodo_atual.split(',')[1];
        filtros.periodoAnteriorInicio = filtros.periodo_anterior.split(',')[0];
        filtros.periodoAnteriorFim = filtros.periodo_anterior.split(',')[1];

        return filtros;

    }

    changeResponsavel() {
        this.getPeriodos(this.formFiltrar.value.email);
    }
}
