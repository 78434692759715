<nz-page-header class="site-page-header" nzTitle="Caixa de mensagens" nzSubtitle="inbox">
    <nz-page-header-extra>
        <nz-button-group>
            <nz-badge [nzCount]="qtdFiltrosAtivosCabecalho"
                      [nzStyle]="{ backgroundColor: '#52c41a' }">
                <button nz-button nzType="primary"
                        (click)="modalFiltrarCabecalho(true);">
                    <i nz-icon nzType="search"></i>
                    Filtros
                </button>
            </nz-badge>
        </nz-button-group>
    </nz-page-header-extra>
</nz-page-header>

<div class="cabecalho">
    <div class="cards-filters">

        <div (click)="filterTableByCard('0', 'status')" style="color: rgba(120,120,120,0.75)"
             [ngClass]="formFiltrar.formGroup.get('status').value == '0'? ['filter-status', 'selected'] : ['filter-status']">
            <div>
                <i class="fa-solid fa-hourglass-start"></i>
                Não Processado
            </div>

            <span>{{ itemsCabecalho.nao_processados }}</span>

        </div>

        <div (click)="filterCabByCard(3, 'status')" style="color: rgba(231,56,56,0.75)"
             [ngClass]="formFiltrar.formGroup.get('status').value == 3? ['filter-status', 'selected'] : ['filter-status']">
            <div>
                <i class="fa-solid fa-circle-xmark"></i>
                Erro
            </div>
            <span>{{ itemsCabecalho.erro }}</span>
        </div>

        <div (click)="filterCabByCard(2, 'status')" style="color: rgba(2,136,209,0.75)"
             [ngClass]="formFiltrar.formGroup.get('status').value == 2 ? ['filter-status', 'selected'] : ['filter-status']">
            <div>
                <i class="fa-solid fa-circle-check"></i>
                Com anexos identificados
            </div>

            <span>{{ itemsCabecalho.identificados }}</span>
        </div>

        <div (click)="filterCabByCard(1, 'status')" style="color: rgba(255,152,0,0.75)"
             [ngClass]="formFiltrar.formGroup.get('status').value == 1 ? ['filter-status', 'selected'] : ['filter-status']">
            <div>
                <i class="fa-regular fa-circle-xmark"></i>
                <span class="max-w"> Com anexos não identificados </span>
            </div>

            {{ itemsCabecalho.nao_identificados }}
        </div>

    </div>

    <div class="card-filter-msg">
        <h3>Mensagens</h3>
        <div (click)="filterCabByCard(15, 'dias')"
             [ngClass]="formFiltrarCabecalho.formGroup.get('dias').value == 15 ? ['selected'] : []">
            <span>Últimos 15 dias </span>
            <span>{{ itemsCabecalho['15_dias'] }}</span>
        </div>
        <div (click)="filterCabByCard(30, 'dias')"
             [ngClass]="formFiltrarCabecalho.formGroup.get('dias').value == 30 ? ['selected'] : []">
            <span>Últimos 30 dias </span>
            <span>{{ itemsCabecalho['30_dias'] }}</span>
        </div>
        <div (click)="filterCabByCard(60, 'dias')"
             [ngClass]="formFiltrarCabecalho.formGroup.get('dias').value == 60 ? ['selected'] : []">
            <span>Últimos 60 dias </span>
            <span>{{ itemsCabecalho['60_dias'] }}</span>
        </div>

    </div>
</div>

<nz-content>
    <nz-card [nzExtra]="navBar" nzTitle="Inbox" [nzLoading]="loadingTable"
             [nzBodyStyle]="{'padding': loadingTable ? '15px' : '0px'}"
             [ngClass]="[statusCard ? 'card-fullscreen' : 'ant-card-table fonte-doze']">

        <ng-template #navBar>
            <div class="nav-item d-flex">
                <nz-badge [nzCount]="qtdFiltrosAtivos"
                          [nzStyle]="{ backgroundColor: '#52c41a', 'margin-right' : '15px'}">
                    <button [disabled]="loadingTable" nz-button nzType="primary" style="margin-right: 10px;"
                            (click)="modalFiltrar(true);">
                        <i nz-icon nzType="search"></i>
                        Filtros
                    </button>
                </nz-badge>
                <nz-input-group
                        nzSearch [nzAddOnAfter]="suffixIconButton" style="margin-right: 10px;">
                    <input [disabled]="loadingTable" type="text" nz-input placeholder="Busca" (keydown.enter)="buscar()"
                           [(ngModel)]="currentSearch"/>
                </nz-input-group>
                <ng-template #suffixIconButton>
                    <button [disabled]="loadingTable" nz-button nzType="primary" nzSearch
                            (click)="buscar();"><i
                            nz-icon nzType="search"></i></button>
                </ng-template>
                <button [disabled]="loadingTable" nz-button nzType="default" style="margin-right: 10px;"
                        (click)="btnResetSearch();">
                    Limpar
                </button>
                <button nz-button [nzType]="'default'" (click)="clickEvent()"
                        style="padding-left: 8px; padding-right: 8px;">
                    <i class="fas" [ngClass]="statusCard ? 'fa-compress' : 'fa-expand'"></i>
                </button>
            </div>
        </ng-template>
        <nz-table #tableInbox
                  nzSize="small"
                  [nzScroll]="{ x: 'auto' }"
                  [nzData]="items"
                  (nzQueryParams)="listByTable($event)"
                  [nzFrontPagination]="false"
                  [nzTotal]="pagination?.total"
                  [nzPageSize]="pagination?.per_page"
                  [nzPageIndex]="pagination?.current_page"
                  [nzFooter]="footer"
        >
            <thead>
            <tr>
                <th nzColumnKey="remetente_email" [nzSortFn]="true">Remetente</th>
                <th nzAlign="left" nzColumnKey="assunto" style="min-width: 230px" [nzSortFn]="true">Assunto</th>
                <th nzAlign="center" nzColumnKey="anexos" [nzSortFn]="true">Anexo</th>
                <th nzAlign="center" nzColumnKey="identificados" [nzSortFn]="true">Identificado</th>
                <th nzAlign="center" nzColumnKey="nao_identificados" [nzSortFn]="true">Não Identificado</th>
                <th nzAlign="center" nzColumnKey="status" [nzSortFn]="true">Status e-mail</th>
                <th nzAlign="center" nzColumnKey="date" [nzSortFn]="true">Data</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let data of items">
                <td nzAlign="center">{{ data.remetente_email }}</td>
                <td nzAlign="left">{{ data.assunto }}</td>
                <td nzAlign="center">
                    <div class="anexo-bt" (click)="getAnexos(data.id)">
                        <i class="fa-solid fa-paperclip"></i>
                        <span>
                            {{ data.anexos }}
                        </span>
                    </div>
                </td>
                <td nzAlign="center">{{ data.identificados }}</td>
                <td nzAlign="center">{{ data.nao_identificados }}</td>
                <td nzAlign="center">
                    <div class="status-badge" [ngStyle]="{'background-color': statusEmail[data.status_email]?.cor}">
                        {{ statusEmail[data.status_email].descricao }}
                    </div>
                </td>
                <td nzAlign="center">{{ data.date ? (data.date | date: 'dd/MM/YY - HH:mm') : '-' }}</td>
            </tr>
            </tbody>
            <ng-template #footer>
                <span *ngIf="items && items.length > 0">{{ pagination?.from }}-{{ pagination?.to }}
                    de {{ pagination?.total }} registros</span>
            </ng-template>
        </nz-table>
    </nz-card>
</nz-content>

<nz-modal [(nzVisible)]="formFiltrarCabecalho.modalVisible"
          [nzTitle]="'Filtros'" [nzClosable]="true"
          (nzOnCancel)="modalFiltrarCabecalho(false)"
          [nzWidth]="480">
    <div *nzModalContent>
        <form nz-form [nzNoColon]="true" [formGroup]="formFiltrarCabecalho.formGroup" style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="6">Status</nz-form-label>
                <nz-form-control [nzSpan]="15"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrarCabecalho.formGroup.value.status">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="status">
                        <nz-option [nzLabel]="'Não Processado'" [nzValue]="'0'"></nz-option>
                        <nz-option [nzLabel]="'Com anexos identificados'" [nzValue]="6"></nz-option>
                        <nz-option [nzLabel]="'Com anexos não identificados'" [nzValue]="7"></nz-option>
                        <nz-option [nzLabel]="'Erro'" [nzValue]="3"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="6">Data</nz-form-label>
                <nz-form-control [nzSpan]="15"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrarCabecalho.formGroup.value.dias">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="dias">
                        <nz-option [nzLabel]="'Últimos 15 dias'" [nzValue]="15"></nz-option>
                        <nz-option [nzLabel]="'Últimos 30 dias'" [nzValue]="30"></nz-option>
                        <nz-option [nzLabel]="'Últimos 60 dias'" [nzValue]="60"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalFiltrarCabecalho(false);">Cancelar</button>
        <button nz-button nzType="primary" (click)="confirmaFiltrarCabecalho()">Filtrar</button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formFiltrar.modalVisible"
          [nzTitle]="'Filtros Avançados'" [nzClosable]="true"
          (nzOnCancel)="modalFiltrar(false)"
          [nzWidth]="480">
    <div *nzModalContent>
        <form nz-form [nzNoColon]="true" [formGroup]="formFiltrar.formGroup" style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="6">Remetente</nz-form-label>
                <nz-form-control [nzSpan]="15"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.remetente">
                    <nz-input-group nzSearch [nzSuffix]="remetenteClear">
                        <input nz-input placeholder="Nome ou e-mail do remetente" formControlName="remetente"/>
                    </nz-input-group>
                </nz-form-control>

                <ng-template #remetenteClear>
                    <span style="margin-right: 3px"
                          *ngIf="formFiltrar.formGroup.value.remetente"
                          nz-icon
                          class="ant-input-clear-icon"
                          nzTheme="fill"
                          nzType="close-circle"
                          (click)="formFiltrar.formGroup.get('remetente').setValue(null)"
                    ></span>
                </ng-template>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="6">Status e-mail</nz-form-label>
                <nz-form-control [nzSpan]="15"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.status">
                    <nz-select nzShowSearch nzAllowClear
                               [nzDisabled]="this.formFiltrarCabecalho.formGroup.get('status').value"
                               [nz-tooltip]="this.formFiltrarCabecalho.formGroup.get('status').value ? 'Status filtrado no cabeçalho da tela.' : ''"
                               [nzPlaceHolder]="'Selecione'" formControlName="status">
                        <nz-option [nzLabel]="'Processado'" [nzValue]="2"></nz-option>
                        <nz-option [nzLabel]="'Não Processado'" [nzValue]="'0'"></nz-option>
                        <nz-option [nzLabel]="'Erro'" [nzValue]="3"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="6">Anexo</nz-form-label>
                <nz-form-control [nzSpan]="15"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.temAnexo">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="temAnexo">
                        <nz-option [nzLabel]="'Com anexo'" [nzValue]="1"></nz-option>
                        <nz-option [nzLabel]="'Sem anexo'" [nzValue]="'0'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalFiltrar(false);">Cancelar</button>
        <button nz-button nzType="primary" (click)="confirmaFiltrar()">Filtrar</button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="modalAnexosVisible"
          [nzTitle]="'Anexos'" [nzClosable]="true"
          (nzOnCancel)="showModalAnexos(false)"
          [nzBodyStyle]="{'padding': '0 2px'}"
          [nzWidth]="550">
    <div *nzModalContent>
        <nz-table #tableAnexos
                  nzSize="small"
                  class="table-small"
                  [nzScroll]="{ x: 'auto' }"
                  [nzData]="anexos"
                  [nzShowPagination]="false"
        >
            <thead>
            <tr>
                <th style="min-width: 230px">Anexo</th>
                <th nzAlign="center">Status</th>
                <th nzAlign="center">Ação</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let data of anexos">
                <td nzAlign="left">{{ data.file }}</td>
                <td nzAlign="center">
                    <div class="status-badge" [ngStyle]="{'background-color' : statusAnexos[data.status]?.cor}"
                         style="line-height: 15px;">
                        {{ statusAnexos[data.status]?.descricao }}
                    </div>
                </td>
                <td nzAlign="center">
                    <a [nzLoading]="loadingReprocessarAnexos[data.id]" nz-button nzType="link" *ngIf="data.status === 0"
                       (click)="reprocessarAnexo(data.id)">Reprocessar</a>
                    <a nz-button nzType="link" *ngIf="data.status === 2 || data.status === 4"
                       (click)="openTab('/integracoes/arquivo', null, {arquivoId: data.id})">Editar</a>
                    <a [nzLoading]="loadingRemoverAnexos[data.id]" nz-button nzType="link" *ngIf="data.status === 5"
                       (click)="removerAnexo(data.id)">Remover</a>
                </td>
            </tr>
            </tbody>
        </nz-table>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="primary" (click)="showModalAnexos(false);">Fechar</button>
    </div>
</nz-modal>
