<nz-card style="width:100%" nzTitle="Configurações de Login SSO" [nzExtra]="extraCard" class="card-sso">

    <ng-template #extraCard>
        <button nz-button nzType="primary" (click)="onModalAlterar(true);">Alterar</button>

    </ng-template>

    <nz-skeleton *ngIf="loadings.dados"></nz-skeleton>
    <nz-list nzItemLayout="horizontal" *ngIf="!loadings.dados">
        <nz-list-item>
            <nz-list-item-meta nzDescription="Configurações de SSO">
                <nz-list-item-meta-title>
                    <nz-tag [nzColor]="configuracoes.ativado ? 'green' : ''">{{ configuracoes.ativado ? 'Ativado' : 'Desativado' }}</nz-tag>
                </nz-list-item-meta-title>
            </nz-list-item-meta>
        </nz-list-item>
        <nz-list-item *ngIf="configuracoes.ativado">
            <nz-list-item-meta nzDescription="Tipo">
                <nz-list-item-meta-title>
                    {{ configuracoes.type }}
                </nz-list-item-meta-title>
            </nz-list-item-meta>
        </nz-list-item>

        <nz-list-item *ngIf="configuracoes.ativado">
            <nz-list-item-meta nzDescription="ID da identidade (Entity ID)">
                <nz-list-item-meta-title>
                    {{ configuracoes.idp_entity_id }}
                </nz-list-item-meta-title>
            </nz-list-item-meta>
        </nz-list-item>
        <nz-list-item *ngIf="configuracoes.ativado">
            <nz-list-item-meta nzDescription="URL para login">
                <nz-list-item-meta-title>
                    {{ configuracoes.idp_login_url }}
                </nz-list-item-meta-title>
            </nz-list-item-meta>
        </nz-list-item>
        <nz-list-item *ngIf="configuracoes.ativado">
            <nz-list-item-meta nzDescription="URL para logout">
                <nz-list-item-meta-title>
                    {{ configuracoes.idp_logout_url }}
                </nz-list-item-meta-title>
            </nz-list-item-meta>
        </nz-list-item>
        <nz-list-item *ngIf="configuracoes.ativado">
            <nz-list-item-meta nzDescription="Certificado X.509">
                <nz-list-item-meta-title>

                    <button nz-button nzType="primary"
                            [nzLoading]="loadings.download"
                            *ngIf="configuracoes.idp_x509_cert && configuracoes.name_x509_cert"
                            (click)="downloadFile(configuracoes.idp_x509_cert, configuracoes.name_x509_cert)">
                        <i class="fa-solid fa-download"></i>
                        Baixar
                    </button>
                    {{ configuracoes.name_x509_cert }}
                </nz-list-item-meta-title>
            </nz-list-item-meta>
        </nz-list-item>

        <nz-list-item *ngIf="configuracoes.ativado">
            <nz-list-item-meta nzDescription="Identificador (ID da Entidade)">
                <nz-list-item-meta-title>
                    https://backend.mastertax.app/api/saml/{{ identificador }}-{{ configuracoes.type }}/metadata

                </nz-list-item-meta-title>
            </nz-list-item-meta>
        </nz-list-item>

        <nz-list-item *ngIf="configuracoes.ativado">
            <nz-list-item-meta nzDescription="URL de Resposta (URL do Serviço do Consumidor de Declaração)">
                <nz-list-item-meta-title>
                    https://backend.mastertax.app/api/saml/{{ identificador }}-{{ configuracoes.type }}/acs
                </nz-list-item-meta-title>
            </nz-list-item-meta>
        </nz-list-item>
    </nz-list>


</nz-card>

<nz-modal [(nzVisible)]="modalAlterar.visible"
          nzTitle="Alterar configurações" [nzClosable]="true"
          (nzOnCancel)="onModalAlterar(false)"
          [nzWidth]="648">
    <div nz-row *nzModalContent>
        <nz-skeleton *ngIf="modalAlterar.loadingModal"></nz-skeleton>
        <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24" *ngIf="!modalAlterar.loadingModal">
            <form nz-form [formGroup]="modalAlterar.formGroup" [nzLayout]="'vertical'" class="form-editar">
                <div nz-row>
                    <nz-col nzSpan="24">
                        <nz-form-label nzRequired>Configurações de SSO</nz-form-label>
                        <nz-form-control [nzErrorTip]="'Campo Obrigatório'">
                            <nz-switch formControlName="status" nzCheckedChildren="Ativo"
                                       nzUnCheckedChildren="Inativo"></nz-switch>
                        </nz-form-control>
                    </nz-col>
                </div>
                <div nz-row *ngIf="modalAlterar.formGroup.value.status">
                    <nz-col nzSpan="24">
                        <nz-form-label nzRequired>Tipo</nz-form-label>
                        <nz-form-control [nzErrorTip]="'Campo Obrigatório'">
                            <nz-select formControlName="type">
                                <nz-option [nzLabel]="'Google'" [nzValue]="'google'"></nz-option>
                                <nz-option [nzLabel]="'Microsoft'" [nzValue]="'microsoft'"></nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-col>
                </div>

                <div nz-row *ngIf="modalAlterar.formGroup.value.status">
                    <nz-col nzSpan="24">
                        <nz-form-label nzRequired>ID da identidade (Entity ID)</nz-form-label>
                        <nz-form-control [nzErrorTip]="'Campo Obrigatório'">
                            <input nz-input type="text" formControlName="idp_entity_id" placeholder="Entity ID"/>
                        </nz-form-control>
                    </nz-col>
                </div>

                <div nz-row *ngIf="modalAlterar.formGroup.value.status">
                    <nz-col nzSpan="24">
                        <nz-form-label nzRequired>URL para login</nz-form-label>
                        <nz-form-control [nzErrorTip]="'Campo Obrigatório'">
                            <input nz-input type="text" formControlName="idp_login_url" placeholder="URL"/>
                        </nz-form-control>
                    </nz-col>
                </div>

                <div nz-row *ngIf="modalAlterar.formGroup.value.status">
                    <nz-col nzSpan="24">
                        <nz-form-label nzRequired>URL para logout</nz-form-label>
                        <nz-form-control [nzErrorTip]="'Campo Obrigatório'">
                            <input nz-input type="text" formControlName="idp_logout_url" placeholder="URL"/>
                        </nz-form-control>
                    </nz-col>
                </div>

                <div nz-row *ngIf="modalAlterar.formGroup.value.status">
                    <nz-col nzSpan="24">
                        <nz-form-label nzRequired>Certificado</nz-form-label>
                        <nz-form-control [nzErrorTip]="'Campo Obrigatório'">
                            <nz-upload
                                    *ngIf="modalAlterar.showUpload"
                                    nzType="drag"
                                    [nzMultiple]="false"
                                    [(nzFileList)]="fileListCertificado" [nzBeforeUpload]="beforeUploadCertificado">
                                <p class="ant-upload-drag-icon">
                                    <i nz-icon nzType="inbox"></i>
                                </p>
                                <p class="ant-upload-text">Clique ou arraste o arquivo para esta área para fazer o
                                    upload</p>
                            </nz-upload>
                            <p class="ant-upload-text" style="color: #3d3d3d; margin-bottom: 0; margin-top: 5px;"
                               *ngIf="modalAlterar.showUpload">Somente
                                arquivos .cer</p>

                            <p *ngIf="!modalAlterar.showUpload" style="background-color: rgba(241,241,241,0.6)">
                                <span>{{ modalAlterar.fileName }}</span>
                                <a nz-button nzType="link" style="color: red; float: right; line-height: 20px;"
                                   (click)="removerAnexo()"
                                ><i class="fa-regular fa-trash-can"></i></a>
                            </p>

                        </nz-form-control>
                    </nz-col>
                </div>

                <div nz-row *ngIf="modalAlterar.formGroup.value.status && modalAlterar.formGroup.value.type">
                    <nz-col nzSpan="24">
                        <nz-form-label>Identificador (ID da Entidade)</nz-form-label>
                        <nz-form-control>
                            <input nz-input type="text" readonly
                                   [value]="'https://backend.mastertax.app/api/saml/'+identificador+'-'+modalAlterar.formGroup.value.type+'/metadata'">

                        </nz-form-control>
                    </nz-col>
                </div>

                <div nz-row *ngIf="modalAlterar.formGroup.value.status && modalAlterar.formGroup.value.type">
                    <nz-col nzSpan="24">
                        <nz-form-label>URL de Resposta (URL do Serviço do Consumidor de Declaração)</nz-form-label>
                        <nz-form-control>
                            <input nz-input type="text" readonly
                                   [value]="'https://backend.mastertax.app/api/saml/'+identificador+'-'+modalAlterar.formGroup.value.type+'/acs'">

                        </nz-form-control>

                    </nz-col>
                </div>

                <!--                <nz-list-item *ngIf="configuracoes.ativado">-->
                <!--                    <nz-list-item-meta nzDescription="Identificador (ID da Entidade)">-->
                <!--                        <nz-list-item-meta-title>-->
                <!--                            https://backend.mastertax.app/api/saml/{{ identificador }}-{{ configuracoes.type }}/metadata-->

                <!--                        </nz-list-item-meta-title>-->
                <!--                    </nz-list-item-meta>-->
                <!--                </nz-list-item>-->

                <!--                <nz-list-item *ngIf="configuracoes.ativado">-->
                <!--                    <nz-list-item-meta nzDescription="URL de Resposta (URL do Serviço do Consumidor de Declaração)">-->
                <!--                        <nz-list-item-meta-title>-->
                <!--                            https://backend.mastertax.app/api/saml/{{ identificador }}-{{ configuracoes.type }}/acs-->
                <!--                        </nz-list-item-meta-title>-->
                <!--                    </nz-list-item-meta>-->
                <!--                </nz-list-item>-->


            </form>
        </div>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="onModalAlterar(false);">Cancelar</button>
        <button nz-button nzType="primary" (click)="confirmaSalvar()" [nzLoading]="modalAlterar.loadingButton">Salvar
        </button>
    </div>
</nz-modal>

