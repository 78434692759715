import {Injectable} from '@angular/core';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import {AuthenticationService} from '../../auth/authentication.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Subscription} from 'rxjs';
import {DataService} from '@services/data.service';
import {NotifyComponent} from '@components/notify/notify.component';
import {TasksComponent} from '@components/tasks/tasks.component';

declare global {
    interface Window {
        Pusher: any;
        Echo: any;
    }
}

@Injectable({
    providedIn: 'root'
})
export class NotifyService {

    private static currentUser: Subscription;
    private static currentUserId: any;
    private static userAdmin: boolean;
    private static identificador: any;
    private static connected: any;

    constructor(private authenticationService: AuthenticationService,
                private http: HttpClient,
                private dataService: DataService
    ) {

        window.Pusher = Pusher;

    }

    static retornaParametrosUsuario() {
        return {
            currentUserId: this.currentUserId,
            userAdmin: this.userAdmin,
            identificador: this.identificador,
            connected: this.connected,
        };
    }

    connectNotify() {

        const currentToken = this.authenticationService.currentTokenValue;

        NotifyService.currentUser = this.dataService.currentUser.subscribe((data) => {

            try {
                if (data) {

                    const quebraUrl = window.location.hostname.split('.')[1];

                    let urlWebsocket = 'websocket.mastertax.app';
                    let urlApi = 'backend.mastertax.app';
                    let porta = 6001;
                    const key = 'D41D8CD98F00B204E9800998ECF8427E';

                    if (quebraUrl === 'dev' || quebraUrl === 'rc') {
                        porta = 6002;
                        urlWebsocket = 'websocket.dev.mastertax.app';
                        urlApi = 'backend.dev.mastertax.app';
                    }

                    NotifyService.currentUserId = data.id;
                    NotifyService.userAdmin = data.origem === 'console';
                    NotifyService.identificador = data.identificador;

                    window.Echo = new Echo({
                        broadcaster: 'pusher',
                        key,
                        wsHost: urlWebsocket,
                        wsPort: porta,
                        wssPort: porta,
                        forceTLS: true,
                        encrypted: true,
                        disableStats: true,
                        enabledTransports: ['ws', 'wss'],
                        authorizer: (channel, options) => {
                            return {
                                authorize: (socketId, callback) => {
                                    fetch('https://' + urlApi + '/api/broadcasting/auth', {
                                        method: 'POST',
                                        headers: {
                                            'Content-Type': 'application/json',
                                            Accept: 'application/json',
                                            Authorization: 'Bearer ' + currentToken.access_token
                                        },
                                        body: JSON.stringify({
                                            socket_id: socketId,
                                            channel_name: channel.name
                                        })
                                    })
                                        .then(response => response.json())
                                        .then(responseData => {
                                            callback(false, responseData);
                                        })
                                        .catch((error) => {
                                            callback(true, error);
                                        });
                                }
                            };
                        }
                    });

                    // this.verifyConnection();

                    window.Echo.connector.pusher.connection.bind('connecting', (payload) => {

                        NotifyService.connected = false;

                    });

                    window.Echo.connector.pusher.connection.bind('connected', (payload) => {

                        NotifyService.connected = true;

                        NotifyComponent.initChanels();
                        TasksComponent.initChanels();

                    });

                    window.Echo.connector.pusher.connection.bind('unavailable', (payload) => {

                        NotifyService.connected = false;

                    });

                    window.Echo.connector.pusher.connection.bind('failed', (payload) => {

                        NotifyService.connected = false;

                    });

                    window.Echo.connector.pusher.connection.bind('disconnected', (payload) => {

                        NotifyService.connected = false;

                    });

                }
            } catch (e) {
                console.log('Falha na conexão com pusher:' + e.message);
            }


        });

    }


}
