import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {TabService} from '@services/tab.service';
import {EditarNotasFiscaisEletronicasService} from './editarNotasFiscaisEletronicas.service';
import {Pagination} from '@models/pagination.model';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import {StickyHeaderDirective} from '@components/directive/directive';
import {NzModalService} from 'ng-zorro-antd/modal';
import {DataService} from '@services/data.service';
import {buildUrl, findComponentByUrl} from '../../../shared/components-helper';
import {Tab} from '@models/tab.model';

interface FormStack {
    modalVisible: boolean;
    formGroup: FormGroup;
}

@Component({
    selector: 'app-editarNotasFiscaisEletronicas',
    templateUrl: './editarNotasFiscaisEletronicas.component.html',
    styleUrls: ['./editarNotasFiscaisEletronicas.component.scss'],
})
export class EditarNotasFiscaisEletronicasComponent extends AbstractListTable<any> implements OnInit, AfterViewInit {

    @Input() data;

    // Para o card
    configuracoesDFE: any = {};

    pagination: any = [{
        empresas: Pagination,
    }];

    currentParams: any = {
        empresa: this.abstractService.NzTableQueryParams,
    };

    autoTips: Record<string, Record<string, string>> = {
        default: {
            required: 'Campo obrigatório',
        }
    };

    gravando = false;

    empresa: any = {};

    loading = false;
    loadingsConfigDFE = false;

    description = '';

    obrigacao: any = {};

    offsetTop = 15;
    target: any;

    erro = false;
    mensagem = '';


    formConfiguracoesDFE: FormStack;

    currentUser: any;

    constructor(
        private fb: FormBuilder,
        private service: EditarNotasFiscaisEletronicasService,
        private toastService: ToastrService,
        private cdr: ChangeDetectorRef,
        private tabService: TabService,
        private dataService: DataService,
        private modalService: NzModalService,
    ) {
        super(service, null, toastService);

        this.formConfiguracoesDFE = {
            modalVisible: false,
            formGroup: this.fb.group({
                ativo: [null, [Validators.required]],
                horarioInicio: [null],
                horarioFim: [null],
            })
        };

    }

    ngOnInit(): void {
        if (!this.data || !this.data.id) {
            this.data = {};
            this.data.id = localStorage.getItem('param');
        }
        this.retornaDadosCadastrais(this.data.id);
    }

    ngAfterViewInit() {

        this.target = StickyHeaderDirective.target;
        this.cdr.detectChanges();

        this.dataService.currentUser.subscribe((data) => {
            this.currentUser = data;
            this.cdr.detectChanges();
        });

    }

    retornaDadosCadastrais(empresaId: string): void {

        this.loadingsConfigDFE = true;

        this.service.retornaDadosCadastrais(empresaId).subscribe((response: any) => {
            this.empresa = response || {};

            this.configuracoesDFE = {
                ativo: this.empresa.ativo,
                horarioInicio: this.empresa.horarioInicio,
                horarioFim: this.empresa.horarioFim,
            };

            function ajustaData(horaMinuto: string) {

                if (!horaMinuto) {
                    return null;
                }

                const horario = horaMinuto.split(':');

                const data = new Date();
                data.setHours(Number(horario[0]));
                data.setMinutes(Number(horario[1]));

                return data;
            }

            this.formConfiguracoesDFE.formGroup.get('ativo').setValue(this.empresa.ativo);
            this.formConfiguracoesDFE.formGroup.get('horarioInicio').setValue(ajustaData(this.empresa.horarioInicio));
            this.formConfiguracoesDFE.formGroup.get('horarioFim').setValue(ajustaData(this.empresa.horarioFim));

            this.loadingsConfigDFE = false;

        }, error => {
            this.toastService.error(error.error.message);
            this.loadingsConfigDFE = false;
        });
    }

    showModal(formulario): void {
        formulario.modalVisible = !formulario.modalVisible;
    }

    openTab(componentUrl: string, queryParams?: string, data?: {}) {
        const component = findComponentByUrl(componentUrl);
        const url = buildUrl(component, queryParams);
        const newTab = new Tab(component.name, component.title, url, component.urlType, data);
        this.tabService.closeAndAddTab(newTab);
    }

    confirmaAlterarDFE() {

        if (this.formConfiguracoesDFE.formGroup.valid) {

            if (this.formConfiguracoesDFE.formGroup.get('ativo').value) {
                this.modalService.confirm({
                    nzTitle: 'Deseja ativar o manifesto automático para as notas fiscais?',
                    nzOkText: 'Ativar',
                    nzOnOk: () => this.alterarDFE(true),
                    nzOnCancel: () => this.alterarDFE(false)
                });
            } else {
                this.alterarDFE(false);
            }

        } else {
            for (const key in this.formConfiguracoesDFE.formGroup.controls) {

                if (key) {
                    const campo = this.formConfiguracoesDFE.formGroup.get(key);

                    campo.markAsDirty();
                    campo.updateValueAndValidity();
                }
            }
        }

    }


    alterarDFE(manifestarCiencia: boolean): void {

        this.loadingsConfigDFE = true;

        const data: any = {};
        data.manifestarCiencia = manifestarCiencia;
        data.empresa_id = [this.data.id];

        data.ativo = Number(this.formConfiguracoesDFE.formGroup.get('ativo').value);

        if (this.formConfiguracoesDFE.formGroup.get('horarioInicio').value) {
            data.horarioInicio = this.formConfiguracoesDFE.formGroup.get('horarioInicio').value;
            const inicio = new Date(data.horarioInicio).getHours();
            const fim = new Date(data.horarioInicio).getMinutes();
            data.horarioInicio = (inicio < 9 ? '0' + inicio : inicio) + ':' + (fim < 9 ? '0' + fim : fim);
        } else {
            data.horarioInicio = null;
        }

        if (this.formConfiguracoesDFE.formGroup.get('horarioFim').value) {
            data.horarioFim = this.formConfiguracoesDFE.formGroup.get('horarioFim').value;
            const inicio = new Date(data.horarioFim).getHours();
            const fim = new Date(data.horarioFim).getMinutes();
            data.horarioFim = (inicio < 9 ? '0' + inicio : inicio) + ':' + (fim < 9 ? '0' + fim : fim);
        } else {
            data.horarioFim = null;
        }

        this.service.alterar(data).subscribe((response) => {
            this.toastService.success(response.message);
            this.loadingsConfigDFE = false;
            this.checkedItems.clear();
            this.showModal(this.formConfiguracoesDFE);
            this.formConfiguracoesDFE.formGroup.reset();

            this.retornaDadosCadastrais(this.data.id);

        }, error => {
            this.toastService.error(error.error.message);
            this.loadingsConfigDFE = false;

        });
    }
}
