<nz-page-header class="site-page-header" nzTitle="Check" nzSubtitle="Validação & Conciliação Detalhes">
    <nz-page-header-extra>
        <nz-button-group>
            <nz-badge [nzCount]="qtdFiltrosCabecalho"
                      [nzStyle]="{ backgroundColor: '#52c41a'}">
                <button nz-button nzType="primary" nzGhost
                        style="border-radius: 0.45rem;"
                        (click)="abrirModal(formFiltrosCabecalho)">
                    <i nz-icon nzType="search"></i>
                    Filtros
                </button>
            </nz-badge>

        </nz-button-group>
    </nz-page-header-extra>
</nz-page-header>
<nz-alert nzType="info" nzShowIcon [nzMessage]="checkedMessage" style=" margin: 15px !important;">
    <ng-template #checkedMessage>
        <div nz-row nzAlign="middle" nzGutter="24">
            <div nz-col nzSpan="24">
                <strong class="text-primary"></strong>
                Atenção: Os arquivos ficam disponíveis para download por até 12 meses. Após esse período eles serão
                excluídos automaticamente.
            </div>
        </div>
    </ng-template>
</nz-alert>
<nz-content>
    <div nz-row [nzGutter]="24">
        <div nz-col [nzXs]="24" [nzSm]="12" [nzMd]="8" [nzXl]="6">
            <nz-card [nzLoading]="loadingContadores">
                <div nz-row>
                    <div nz-col [nzSpan]="24" class="d-flex align-items-left justify-content-left">
                        <div>
                            <h1 nz-typography class="custom-card-value text-nowrap"
                                style="margin: 0; font-size: 28px; margin-bottom: 10px;">
                                {{ dadosCabecalho.obrigacao }}
                            </h1>
                            <h4 class="custom-card-title text-muted" style="margin: 0; line-height: 1;">
                                {{ dadosCabecalho.orgao }}
                            </h4>
                        </div>
                    </div>
                </div>
            </nz-card>
        </div>
        <div nz-col [nzXs]="24" [nzSm]="12" [nzMd]="8" [nzXl]="4">
            <nz-card [nzLoading]="loadingContadores">
                <div nz-row>
                    <div nz-col [nzSpan]="24" class="d-flex align-items-left justify-content-left">
                        <div>
                            <h4 class="custom-card-title text-muted" style="line-height: 1;">
                                Vencimento:
                            </h4>
                            <h1 nz-typography class="custom-card-value" style="margin: 0; font-size: 30px">
                                {{ dadosCabecalho.mesSelecionado }}<span
                                    style="font-weight: 400;">/</span>{{ dadosCabecalho.anoSelecionado }}
                            </h1>
                        </div>
                    </div>
                </div>
            </nz-card>
        </div>
        <div nz-col [nzXs]="24" [nzSm]="12" [nzMd]="8" [nzXl]="4">
            <nz-card [nzLoading]="loadingContadores">
                <div nz-row>
                    <div nz-col [nzSpan]="24" class="d-flex align-items-center justify-content-center">
                        <i class="far fa-building"
                           style="font-size: 56px; margin-right: 15px; color: #009688 !important;"></i>
                        <div>
                            <h4 class="custom-card-title text-muted" style="margin: 0; line-height: 1;">
                                Unidades
                            </h4>
                            <h1 nz-typography class="custom-card-value"
                                style="margin: 0; color: #009688 !important; font-size: 36px;">
                                {{ dadosCabecalho.qtdUnidades | number: '1.0-0' }}
                            </h1>
                        </div>
                    </div>
                </div>
            </nz-card>
        </div>
        <div nz-col [nzXs]="24" [nzSm]="12" [nzMd]="8" [nzXl]="4">
            <nz-card [nzLoading]="loadingContadores">
                <div nz-row>
                    <div nz-col [nzSpan]="24"
                         class="d-flex align-items-center justify-content-center">
                        <i class="far fa-calendar-alt"
                           style="font-size: 56px; margin-right: 15px; color:#1e9ff2 !important;"></i>
                        <div>
                            <h4 class="custom-card-title text-muted" style="margin: 0; line-height: 1;">
                                Obrigações
                            </h4>
                            <h1 nz-typography class="custom-card-value"
                                style="margin: 0; color: #1e9ff2 !important; font-size: 36px;">
                                {{ dadosCabecalho.qtdObrigacoes | number: '1.0-0' }}
                            </h1>
                        </div>
                    </div>
                </div>
            </nz-card>
        </div>
        <div nz-col [nzXs]="24" [nzSm]="12" [nzMd]="8" [nzXl]="6">
            <nz-card [nzLoading]="loadingContadores">
                <div nz-row>
                    <div nz-col [nzSpan]="24"
                         class="d-flex align-items-center">
                        <i nz-icon nzType="info-circle" nzTheme="outline"
                           style="font-size: 56px; margin-right: 15px; color: #3653a1 !important;"></i>
                        <div>
                            <h1 class="custom-card-title text-muted" style="margin: 0; line-height: 1;">
                                Status
                            </h1>
                            <div class="d-flex text-center justify-content-between">
                                <a class="custom-card-title text-muted"
                                   (click)="filtrarCampo(formFiltrosGrid, 'classificacao', 'G')"
                                   style="margin: 5px 7px 0 0;line-height: 1; width: 100%;">
                                    Graves
                                    <br>
                                    <span class="custom-card-span-valor" style="color: #cf1322;">
                                        {{ dadosCabecalho.status?.graves | number: '1.0-0' }}
                                    </span>
                                </a>
                                <a class="custom-card-title text-muted"
                                   (click)="filtrarCampo(formFiltrosGrid, 'classificacao', 'M')"
                                   style="margin: 5px 7px 0 0;line-height: 1; width: 100%;">
                                    Médias
                                    <br>
                                    <span class="custom-card-span-valor" style="color: #dbb807;">
                                        {{ dadosCabecalho.status?.medias | number: '1.0-0' }}
                                    </span>
                                </a>
                                <a class="custom-card-title text-muted"
                                   (click)="filtrarCampo(formFiltrosGrid, 'classificacao', 'L')"
                                   style="margin: 5px 7px 0 0; line-height: 1; width: 100%;">
                                    Leves
                                    <br>
                                    <span class="custom-card-span-valor" style="color: #28a745;">
                                    {{ dadosCabecalho.status?.leves | number: '1.0-0' }}
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </nz-card>
        </div>
    </div>
</nz-content>
<nz-content>
    <nz-card class="fonte-doze"
             [nzBodyStyle]="{'padding': (tabAtiva > 0 && loadingRegras) || (tabAtiva == 0 && loadingEmpresas) ? '15px' : '0px'}"
             [nzLoading]="(tabAtiva > 0 && loadingRegras) || (tabAtiva == 0 && loadingEmpresas)">
        <nz-card-tab>
            <nz-tabset nzSize="large" [(nzSelectedIndex)]="tabAtiva"
                       (nzSelectChange)="changeTabs($event)"
                       [nzTabBarExtraContent]="navBar">
                <nz-tab nzTitle="Empresas"></nz-tab>
                <nz-tab nzTitle="Regras Graves"></nz-tab>
                <nz-tab nzTitle="Regras Médias"></nz-tab>
                <nz-tab nzTitle="Regras Leves"></nz-tab>
            </nz-tabset>
        </nz-card-tab>
        <ng-template #navBar>
            <div class="nav-item d-flex">
                <nz-badge [nzCount]="qtdFiltrosAtivos"
                          [nzStyle]="{ backgroundColor: '#52c41a', 'margin-right' : '15px'}">
                    <button nz-button nzType="primary" style="margin-right: 10px;" (click)="modalFiltrar(true);">
                        <i nz-icon nzType="search"></i>
                        Filtros
                    </button>
                </nz-badge>
                <button *ngIf="tabAtiva > 0 || (tabAtiva === 0 && currentUser?.origem === 'console')"
                        [nzDropdownMenu]="menuAcoes" class="centralized-icon"
                        nz-button nz-dropdown
                        nzType="default" style="margin-right: 10px;">
                    Opções
                    <em nz-icon nzType="down"></em>
                </button>

                <nz-dropdown-menu #menuAcoes="nzDropdownMenu">
                    <ul nz-menu>
                        <li *ngIf="tabAtiva > 0" nz-submenu nzTitle="Manutenção Coletiva">
                            <ul>
                                <li nz-menu-item nzDisabled>Ações</li>
                                <li nz-menu-divider></li>
                                <li nz-menu-item (click)="exportarDadosRegras()">Exportar Dados</li>
                                <li nz-menu-item
                                    (click)="checkedItems?.size > 0 && liberarEmMassa()"
                                    [nzDisabled]="checkedItems?.size === 0"
                                    *ngIf="boolPodeLiberar"

                                >
                                    Liberar
                                </li>
                            </ul>
                        </li>
                        <li nz-button *ngIf="currentUser?.origem === 'console'" nz-submenu nzTitle="Console">

                            <!-- GUSTAVO EM 24/09/24 (SUST-2102)

                            <ul *ngIf="tabAtiva > 0" nz-menu>-->
<!--                                <li nz-button nz-menu-item-->
<!--                                    (click)="checkedItems.size && abrirModalCadastrarAprovacoes()"-->
<!--                                    [nzDisabled]="checkedItems.size === 0 || loadingCadastrandoAprovacao">-->
<!--                                    {{loadingCadastrandoAprovacao ? 'Cadastrando Aprovação...' : 'Cadastrar Aprovação'}}-->
<!--                                </li>-->
<!--                            </ul>-->

                            <ul *ngIf="tabAtiva === 0" nz-menu>
                                <li nz-button nz-menu-item
                                    (click)="checkedItems.size && showConfirmValidacao()"
                                    [nzDisabled]="checkedItems.size === 0 || loadingValidacoes">
                                    {{loadingValidacoes ? 'Enviar arquivos para validação....' : 'Enviar arquivos para validação'}}
                                </li>
                                <li nz-button nz-menu-item
                                    (click)="checkedItems.size && showConfirmConciliacao()"
                                    [nzDisabled]="checkedItems.size === 0 || loadingConciliacoes">
                                    {{loadingConciliacoes ? 'Enviar arquivos para conciliação....' : 'Enviar arquivos para conciliação'}}
                                </li>
                            </ul>
                        </li>
                    </ul>
                </nz-dropdown-menu>


                <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" style="margin-right: 10px;">
                    <input *ngIf="tabAtiva > 0"
                           (keydown.enter)="queryTableRegras(currentParams, currentSearch, true);" type="text" nz-input
                           placeholder="Busca" [(ngModel)]="currentSearch"/>
                    <input *ngIf="tabAtiva === 0"
                           (keydown.enter)="queryTableEmpresas(currentParams, currentSearch, true);" type="text"
                           nz-input placeholder="Busca" [(ngModel)]="currentSearch"/>
                </nz-input-group>
                <ng-template #suffixIconButton>
                    <button *ngIf="tabAtiva > 0" nz-button nzType="primary" nzSearch
                            (click)="queryTableRegras(currentParams, currentSearch, true);"><i
                            nz-icon nzType="search"></i></button>
                    <button *ngIf="tabAtiva === 0" nz-button nzType="primary" nzSearch
                            (click)="queryTableEmpresas(currentParams, currentSearch, true);"><i
                            nz-icon nzType="search"></i></button>
                </ng-template>
                <button nz-button nzType="default" style="margin-right: 10px;"
                        (click)="btnResetSearch();">
                    Limpar
                </button>
            </div>
        </ng-template>
        <nz-alert nzType="info" nzShowIcon [nzMessage]="checkedMessage" style="margin: 15px !important;"
                  *ngIf="checkedItems.size > 0">
            <ng-template #checkedMessage>
                <div nz-row nzAlign="middle" nzGutter="24">
                    <div nz-col nzSpan="24">
                        <strong class="text-primary"></strong>
                        {{ checkedItems.size }} Registros Selecionados
                    </div>
                </div>
            </ng-template>
        </nz-alert>

        <nz-table #tableEmpresas
                  [nzData]="itemsEmpresas"
                  [nzFrontPagination]="false"
                  [nzShowPagination]="false"
                  [nzTotal]="pagination.empresas?.total"
                  [nzPageSize]="pagination.empresas?.per_page"
                  [nzPageIndex]="pagination.empresas?.current_page"
                  (nzQueryParams)="listByTableEmpresas($event)"
                  class="table-small"
                  [nzScroll]="{x: 'auto'}"
                  *ngIf="tabAtiva === 0"
                  [ngStyle]="{'max-width' : screenWidth + 'px'}">
            <thead>
            <tr>
                <th nzAlign="center">
                    <label nz-checkbox [(ngModel)]="checked" (nzCheckedChange)="onAllChecked($event)"></label>
                </th>
                <th nzColumnKey="grupo" [nzSortFn]="true" nzAlign="center">Grupo</th>
                <th nzColumnKey="empresa" [nzSortFn]="true" nzAlign="center">Empresa</th>
                <th nzColumnKey="competencia" [nzSortFn]="true" nzAlign="center">Competência</th>
                <th nzColumnKey="status" [nzSortFn]="true" nzAlign="center">Status</th>
                <th nzColumnKey="tipo" [nzSortFn]="true" nzAlign="center">Tipo</th>
                <th nzColumnKey="qtdGraves" [nzSortFn]="true" nzAlign="center">Graves</th>
                <th nzColumnKey="qtdMedias" [nzSortFn]="true" nzAlign="center">Médias</th>
                <th nzColumnKey="qtdLeves" [nzSortFn]="true" nzAlign="center">Leves</th>
                <th nzColumnKey="previaApuracao" [nzSortFn]="true" nzAlign="center">Prévia Apuração</th>
                <th>Arquivo</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let arquivo of tableEmpresas.data">
                <td nzAlign="center">
                    <label nz-checkbox
                           [nzChecked]="checkedItems.has(arquivo.id)"
                           (nzCheckedChange)="onItemChecked(arquivo.id, $event)"></label>
                </td>
                <td nzAlign="center">{{ modoDemo(arquivo?.grupo, 'total') }}</td>
                <td>
                    <div class="d-flex justify-content-between" style="width: 100%; min-width: 300px;">
                        <span>{{ modoDemo(arquivo?.nome, 'empresaCodigoNome') }}
                            ({{ modoDemo(arquivo?.cnpj | cpfCnpj, 'cnpj') }})
                            IE: {{modoDemo(arquivo.ie || '-')}}
                        </span>
                        <div>
                            <nz-tag *ngIf="arquivo.empresaNaoOficialEmUso"
                                    [nzColor]="'green'">
                                Em Uso
                            </nz-tag>
                        </div>
                    </div>
                </td>
                <td nzAlign="center">{{ arquivo?.competencia | date : 'MM/yyyy' }}</td>
                <td style="text-align: center">
                    <div style="max-width: 95%; margin: 0 auto">
                        <app-status-com-anexos [arquivo]="arquivo"
                                               [nomeValido]="'relatorio-pva'"></app-status-com-anexos>
                    </div>
                </td>
                <td nzAlign="center">
                    <span nz-tooltip="{{arquivo.tipo === 'V'? 'Validação' : 'Conciliação'}}">
                        {{ arquivo?.tipo }}
                    </span>
                </td>
                <td nzAlign="center">
                    <a (click)="changeTabManual(1,arquivo?.cnpj)">
                        {{ arquivo?.qtdGraves }}
                    </a>
                </td>
                <td nzAlign="center">
                    <a (click)="changeTabManual(2, arquivo?.cnpj)">
                        {{ arquivo?.qtdMedias }}
                    </a>
                </td>
                <td nzAlign="center">
                    <a (click)="changeTabManual(3, arquivo?.cnpj)">
                        {{ arquivo?.qtdLeves }}
                    </a>
                </td>
                <td nzAlign="center">
                    <a *ngIf="arquivo.tipo === 'V'" (click)="carregaDetalheApuracao(arquivo.id)">Detalhes</a>
                </td>
                <td nzAlign="center">
                    <a nz-button nzType="link" *ngIf="arquivo.driveArquivo_id"
                       [nzLoading]="loadings.download.includes(arquivo.driveArquivo_id)"
                       (click)="exportDocumento(arquivo?.driveArquivo_id, arquivo?.nomeArquivo, arquivo?.orgao);">
                        <i nz-icon nzType="download" nzTheme="outline"></i>
                    </a>
                </td>
            </tr>
            </tbody>
        </nz-table>
        <ng-template #rangeTemplate let-range="range" let-total>
            {{ range[0] }}-{{ range[1] }} de {{ total }} Registros
        </ng-template>

        <nz-table #tableRegras
                  [nzData]="itemsRegras"
                  [nzFrontPagination]="false"
                  [nzShowPagination]="false"
                  [nzTotal]="pagination.regras?.total"
                  [nzPageSize]="pagination.regras?.per_page"
                  [nzPageIndex]="pagination.regras?.current_page"
                  class="table-small"
                  (nzQueryParams)="listByTableRegras($event)"
                  [nzScroll]="{x: 'auto'}"
                  *ngIf="tabAtiva > 0"
                  [ngStyle]="{'max-width' : screenWidth + 'px'}">
            <thead>
            <tr>
                <th nzAlign="center">
                    <label nz-checkbox [(ngModel)]="checked" (nzCheckedChange)="onAllChecked($event)"></label>
                </th>
                <th nzColumnKey="obrigacao" [nzSortFn]="true">Obrigação</th>
                <th nzColumnKey="grupo" [nzSortFn]="true">Grupo</th>
                <th nzColumnKey="nome" [nzSortFn]="true">Empresa</th>
                <th nzColumnKey="competencia" [nzSortFn]="true" nzAlign="center">Competência</th>
                <th nzColumnKey="vencimento" [nzSortFn]="true" nzAlign="center">Vencimento</th>
                <th nzColumnKey="classificacao" [nzSortFn]="true" nzAlign="center">Classificação</th>
                <th nzColumnKey="obrigacao" [nzSortFn]="false" nzAlign="center">Liberação</th>
                <th nzColumnKey="bloco" [nzSortFn]="true" nzAlign="center">Registro</th>
                <th nzColumnKey="descricao" [nzSortFn]="true" nzAlign="center">Descrição</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let arquivo of tableRegras.data">
                <td nzAlign="center">
                    <label nz-checkbox
                           [nzChecked]="checkedItems.has(arquivo.id)"
                           (nzCheckedChange)="onItemChecked(arquivo.id, $event)"></label>
                </td>
                <td nzAlign="left" style="white-space: nowrap;">
                    <button nz-button nzType="link"
                            (click)="openTab('/cadastros/obrigacao-empresa-detalhe/', arquivo?.obrigacaoEmpresa_id, {id: arquivo?.obrigacaoEmpresa_id})">
                        {{ arquivo?.obrigacao_descricao }}
                    </button>
                </td>
                <td nzAlign="left">{{ modoDemo(arquivo?.grupo, 'total') }}</td>
                <td nzAlign="left" style="min-width: 300px;">
                    {{ modoDemo(arquivo?.nome, 'empresaCodigoNome') }} ({{ modoDemo(arquivo?.cnpj | cpfCnpj, 'cnpj') }})
                </td>
                <td class="text-center">{{ arquivo?.competencia | date : 'MM/yyyy' }}</td>
                <td class="text-center">{{ arquivo?.vencimento | date : 'dd/MM/yyyy' }}</td>
                <td class="text-center">
                    <nz-alert nzType="success" *ngIf="arquivo?.classificacao === 'L'"
                              nzMessage="{{arquivo?.classificacaoDescricao}}"></nz-alert>
                    <nz-alert nzType="warning" *ngIf="arquivo?.classificacao === 'M'"
                              nzMessage="{{arquivo?.classificacaoDescricao}}"></nz-alert>
                    <nz-alert nzType="error" *ngIf="arquivo?.classificacao === 'G'"
                              nzMessage="{{arquivo?.classificacaoDescricao}}"></nz-alert>
                </td>
                <td class="text-center">
                    <div *ngIf="!arquivo.liberacaoData && !arquivo.liberacaoUsuario_id && arquivo?.classificacao === 'M'">
                        Aguardando
                    </div>
                    <button nz-button nzType="link" *ngIf="!arquivo.disabled"
                            (click)="processarRegrasConfirm(arquivo.checkObrigacaoValidacao_id, arquivo.obrigacaoRegra_id)">Liberar
                    </button>
                    <span style="white-space: nowrap;"
                          *ngIf="arquivo.liberacaoData && arquivo.liberacaoUsuario_id && arquivo?.classificacao === 'M'">
                        Por <strong>{{ arquivo?.nomeUsuario }}</strong><br>
                        em {{ arquivo.liberacaoData | date: 'dd/MM/yyyy HH:mm' }}
                    </span>
                </td>
                <td class="text-center">{{ arquivo?.bloco }}</td>
                <td nzAlign="center">
                    <div class="box-flex">
                         <span class="ellipsis-box" nz-tooltip
                               nzTooltipTitle="{{arquivo?.descricao}}">{{ arquivo?.descricao }}</span>

                        <span class="badge-red"
                              *ngIf="loadingDemaisColunas !== arquivo.obrigacaoEmpresa_id + arquivo.obrigacaoRegra_id else loading"
                              (click)="!loadingDemaisColunas && modalDemaisColunas(true, arquivo, currentParams, true)">
                            {{arquivo.quantidade_agrupados }}
                        </span>

                        <ng-template #loading>
                            <span class="badge-red" nz-icon nzType="loading" nzTheme="outline"></span>
                        </ng-template>
                    </div>
                </td>
            </tr>
            </tbody>
        </nz-table>
        <div class="rodape-table">
            <nz-button-group>
                <button nz-button nzType="default" (click)="paginaAnterior()" [disabled]="currentParams.pageIndex == 1">
                    <span nz-icon nzType="left" nzTheme="outline" style="font-size: 14px"></span> Anterior
                </button>
                <span nz-typography class="page"><strong>{{ currentParams.pageIndex }}</strong></span>
                <button nz-button nzType="default" (click)="paginaProxima()" [disabled]="disabledProximaPagina">
                    Próxima <span nz-icon nzType="right" nzTheme="outline" style="font-size: 14px"></span>
                </button>
            </nz-button-group>
        </div>
    </nz-card>
</nz-content>
<nz-modal [(nzVisible)]="formFiltrosCabecalho.modalVisible"
          [nzTitle]="'Filtros'" [nzClosable]="true"
          (nzOnCancel)="fecharModal(formFiltrosCabecalho)"
          [nzWidth]="648">
    <nz-row *nzModalContent>
        <nz-col [nzSpan]="24">
            <form nz-form [nzNoColon]="false" [formGroup]="formFiltrosCabecalho.formGroup">
                <nz-form-item>
                    <nz-form-label [nzSpan]="5">Vencimento</nz-form-label>
                    <nz-form-control [nzSpan]="14"
                                     nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrosCabecalho.formGroup.value.vencimento">
                        <nz-date-picker [nzMode]="'month'" [nzFormat]="'MM/yyyy'"
                                        [(ngModel)]="formFiltrosCabecalho.formGroup.value.vencimento"
                                        formControlName="vencimento" style="width: 100%;"
                                        [nzAllowClear]="false"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="5">Categoria</nz-form-label>
                    <nz-form-control [nzSpan]="14"
                                     nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrosCabecalho.formGroup.value.categoria">
                        <nz-select nzShowSearch
                                   nzPlaceHolder="Selecione"
                                   formControlName="categoria"
                                   [(ngModel)]="formFiltrosCabecalho.formGroup.value.categoria"
                                   (ngModelChange)="clearInput(formFiltrosCabecalho.formGroup, 'categoria', 'obrigacao')">
                            <nz-option *ngFor="let opt of arraySelectCategorias"
                                       [nzLabel]="opt.descricao"
                                       [nzValue]="opt.id"
                            ></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="5">Obrigação</nz-form-label>
                    <nz-form-control [nzSpan]="14"
                                     nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrosCabecalho.formGroup.value.obrigacao">
                        <nz-select nzShowSearch
                                   nzPlaceHolder="Selecione"
                                   formControlName="obrigacao"
                                   [(ngModel)]="formFiltrosCabecalho.formGroup.value.obrigacao"
                                   (ngModelChange)="clearInput(formFiltrosCabecalho.formGroup, 'obrigacao', 'categoria')">
                            <nz-option *ngFor="let opt of arraySelectObrigacoes"
                                       [nzLabel]="opt.descricao"
                                       [nzValue]="opt.id"
                            ></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
            </form>
        </nz-col>
    </nz-row>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="fecharModal(formFiltrosCabecalho)">Cancelar</button>
        <button nz-button nzType="primary" (click)="filtrarCabecalho()">Filtrar</button>
    </div>
</nz-modal>
<nz-modal [(nzVisible)]="formFiltrosGrid.modalVisible"
          [nzTitle]="'Filtros Avançados'" [nzClosable]="true"
          (nzOnCancel)="fecharModal(formFiltrosGrid)"
          [nzWidth]="648">
    <nz-row *nzModalContent>
        <nz-col [nzSpan]="24">
            <form nz-form [nzNoColon]="false" [formGroup]="formFiltrosGrid.formGroup">
                <nz-form-item>
                    <nz-form-label [nzSpan]="5">Grupo</nz-form-label>
                    <nz-form-control [nzSpan]="14"
                                     nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrosGrid.formGroup.value.grupo">
                        <nz-select nzShowSearch
                                   nzAllowClear
                                   nzPlaceHolder="Selecione"
                                   formControlName="grupo">
                            <nz-option *ngFor="let opt of arraySelectGrupos"
                                       [nzLabel]="modoDemo(opt.descricao, 'total')"
                                       [nzValue]="opt.id"
                            ></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="5">CNPJ</nz-form-label>
                    <nz-form-control [nzSpan]="14" nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrosGrid.formGroup.value.empresa_id">
                        <nz-select nzShowSearch
                                   nzAllowClear
                                   nzPlaceHolder="Selecione"
                                   formControlName="empresa_id"
                                   [(ngModel)]="filtroEmpresaID">
                            <nz-option *ngFor="let opt of arraySelectCnpj"
                                       [nzLabel]="modoDemo(opt.descricao | cpfCnpj, 'cnpj')"
                                       [nzValue]="opt.id"
                            ></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="5">Empresa</nz-form-label>
                    <nz-form-control [nzSpan]="14"
                                     nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrosGrid.formGroup.value.empresa_id">
                        <nz-select nzShowSearch
                                   nzAllowClear
                                   nzPlaceHolder="Selecione"
                                   formControlName="empresa_id"
                                   [(ngModel)]="filtroEmpresaID">
                            <nz-option *ngFor="let opt of arraySelectEmpresa"
                                       [nzLabel]="modoDemo(opt.descricao, 'empresaCodigoNome')"
                                       [nzValue]="opt.id"
                            ></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="5">Competência</nz-form-label>
                    <nz-form-control [nzSpan]="14"
                                     nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrosGrid.formGroup.value.competencia">
                        <nz-date-picker [nzMode]="'month'"
                                        [nzFormat]="'MM/yyyy'"
                                        [(ngModel)]="formFiltrosGrid.formGroup.value.competencia"
                                        formControlName="competencia" style="width: 100%;"
                                        [nzAllowClear]="false"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item *ngIf="tabAtiva === 0">
                    <nz-form-label [nzSpan]="5">Status</nz-form-label>
                    <nz-form-control [nzSpan]="14"
                                     nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrosGrid.formGroup.value.status">
                        <nz-select nzShowSearch
                                   nzAllowClear
                                   nzPlaceHolder="Selecione"
                                   formControlName="status"
                                   [(ngModel)]="formFiltrosGrid.formGroup.value.status">
                            <nz-option [nzLabel]="'Na Fila'" [nzValue]="'0'"></nz-option>
                            <nz-option [nzLabel]="'Em Processamento'" [nzValue]="1"></nz-option>
                            <nz-option [nzLabel]="'Processado'" [nzValue]="2"></nz-option>
                            <nz-option [nzLabel]="'Erro'" [nzValue]="3"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item *ngIf="tabAtiva > 0">
                    <nz-form-label [nzSpan]="5">Liberação</nz-form-label>
                    <nz-form-control [nzSpan]="14"
                                     nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrosGrid.formGroup.value.liberacao">
                        <nz-select nzShowSearch
                                   nzAllowClear
                                   nzPlaceHolder="Selecione"
                                   formControlName="liberacao"
                                   [(ngModel)]="formFiltrosGrid.formGroup.value.liberacao">
                            <nz-option [nzLabel]="'Concluído'" [nzValue]="'concluido'"></nz-option>
                            <nz-option [nzLabel]="'Pendente'" [nzValue]="'pendente'"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item *ngIf="tabAtiva > 0">
                    <nz-form-label [nzSpan]="5">Tipo</nz-form-label>
                    <nz-form-control [nzSpan]="14"
                                     nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrosGrid.formGroup.value.tipo">
                        <nz-select nzShowSearch
                                   nzAllowClear
                                   nzPlaceHolder="Selecione"
                                   formControlName="tipo"
                                   [(ngModel)]="formFiltrosGrid.formGroup.value.tipo">
                            <nz-option [nzLabel]="'Validação'" [nzValue]="'V'"></nz-option>
                            <nz-option [nzLabel]="'Conciliação'" [nzValue]="'C'"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
            </form>
        </nz-col>
    </nz-row>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="fecharModal(formFiltrosGrid)">Cancelar</button>
        <button nz-button nzType="primary" (click)="filtrarGrid()">Filtrar</button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formCadastrarAprovacao.modalVisible"
          [nzTitle]="'Cadastrar Aprovações'" [nzClosable]="true"
          (nzOnCancel)="fecharModal(formCadastrarAprovacao)"
          [nzWidth]="648">
    <nz-row *nzModalContent>
        <nz-col [nzSpan]="24">
            <form nz-form [formGroup]="formCadastrarAprovacao.formGroup">
                <nz-form-item>
                    <nz-form-label [nzSpan]="5" nzRequired>Usuário:</nz-form-label>
                    <nz-form-control [nzSpan]="14" nzErrorTip="Por favor, selecione um usuário!">
                        <nz-select nzShowSearch
                                   nzAllowClear
                                   nzPlaceHolder="Selecione"
                                   formControlName="usuario_id"
                                   [(ngModel)]="formCadastrarAprovacao.formGroup.value.usuario_id">
                            <nz-option *ngFor="let opt of arraySelectUsuarios"
                                       [nzLabel]="opt.nome"
                                       [nzValue]="opt.id"
                            ></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
            </form>
        </nz-col>
    </nz-row>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="fecharModal(formCadastrarAprovacao)">Cancelar</button>
        <button [nzLoading]="loadingCadastrandoAprovacao" nz-button nzType="primary" (click)="cadastrarAprovacao()">
            Cadastrar
        </button>
    </div>
</nz-modal>
<nz-modal [(nzVisible)]="modalPdfVisible"
          [nzTitle]="'Arquivo'" [nzClosable]="true"
          (nzOnCancel)="modalPdf(false)"
          [nzWidth]="848">

    <div *nzModalContent style="height:500px">
        <nz-skeleton *ngIf="!arquivoUrl"></nz-skeleton>
        <object>
            <embed *ngIf="arquivoUrl" [src]="arquivoUrl | safe : 'resourceUrl'" style="width: 100%; height: 100%">
        </object>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalPdf(false)">Cancelar</button>
        <button nz-button nzType="primary" (click)="downloadPdf()">Download</button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formLinha.modalVisible"
          [nzTitle]="'Linha'" [nzClosable]="true"
          (nzOnCancel)="showHideModalLinha(formLinha, false)"
          [nzWidth]="700">
    <nz-row *nzModalContent>
        <nz-col [nzSpan]="24">
            {{ linhaSelecionada }}
        </nz-col>
    </nz-row>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="showHideModalLinha(formLinha, false)">Fechar</button>
    </div>
</nz-modal>

<nz-modal nzTitle="Regras"
          [(nzVisible)]="demaisColunasVisible"
          (nzOnCancel)="modalDemaisColunas(false)"
          [nzWidth]="1350"
          >
    <div *nzModalContent>

        <nz-table #tableRegras
                  [nzLoading]="loadingDemaisColunas"
                  [nzData]="demaisColunas"
                  [nzFrontPagination]="false"
                  [nzShowPagination]="false"
                  [nzTotal]="pagination.demaisColunasRegras?.total"
                  [nzPageSize]="pagination.demaisColunasRegras?.per_page"
                  [nzPageIndex]="pagination.demaisColunasRegras?.current_page"
                  class="table-small fonte-doze"
                  (nzQueryParams)="listByTableDemaisColunasRegras($event)"
                  [nzScroll]="{x: '800px', y: '350px'}">
            <thead>
            <tr>
                <th nzColumnKey="sequencia" nzAlign="center" [nzSortFn]="true" [nzWidth]="'80px'">Linha
                </th>
                <th nzColumnKey="descricao" nzAlign="center" [nzSortFn]="true"[nzWidth]="'250px'">Detalhe</th>
                <th nzColumnKey="valor1" nzAlign="center" [nzSortFn]="true" [nzWidth]="'80px'">Valor 1</th>
                <th nzColumnKey="valor2" nzAlign="center" [nzSortFn]="true" [nzWidth]="'80px'">Valor 2</th>
                <th nzColumnKey="bloco2" nzAlign="center" [nzSortFn]="true" [nzWidth]="'80px'">Registro</th>
                <th nzColumnKey="linha2" nzAlign="center" [nzSortFn]="true" [nzWidth]="'80px'">Linha</th>
                <th nzColumnKey="obrigacao_id_2" [nzSortFn]="true" nzAlign="center" [nzWidth]="'80px'">Obrigação Conciliada</th>
                <th nzColumnKey="diferenca" [nzSortFn]="true" nzAlign="center" [nzWidth]="'80px'">Diferença</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let arquivo of tableRegras.data">
                <td nzAlign="left">
                    <div style="max-width: 100px">
                        <a nz-button nzType="link" *ngIf="arquivo?.linha"
                           (click)="showHideModalLinha(formLinha, true, arquivo?.linha)">
                            {{ arquivo?.sequencia }}
                        </a>
                        <span *ngIf="!arquivo.linha">{{ arquivo?.sequencia }}</span>
                    </div>
                </td>
                <td class="text-center">{{ arquivo?.descricao ?? '-' }}</td>
                <td class="text-center">{{ arquivo?.valor1 }}</td>
                <td class="text-center">{{ arquivo?.valor2 }}</td>
                <td class="text-center">{{ arquivo?.bloco2 }}</td>
                <td nzAlign="left">
                    <div style="max-width: 100px">
                        <a nz-button nzType="link" *ngIf="arquivo?.linha2"
                           (click)="showHideModalLinha(formLinha, true, arquivo?.linha2)">
                            {{ arquivo?.sequencia2 }}
                        </a>
                        <span *ngIf="!arquivo.linha2">{{ arquivo?.sequencia2 }}</span>
                    </div>
                </td>
                <td class="text-center">{{ arquivo?.obrigacao_id_2 }}</td>
                <td class="text-center">{{ arquivo?.diferenca }}</td>
            </tr>
            </tbody>
        </nz-table>
        <div class="rodape-table">
            <nz-button-group>
                <button nz-button nzType="default" (click)="paginaAnterior()" [disabled]="currentParams.pageIndex == 1">
                    <span nz-icon nzType="left" nzTheme="outline" style="font-size: 14px"></span> Anterior
                </button>
                <span nz-typography class="page"><strong>{{ currentParams.pageIndex }}</strong></span>
                <button nz-button nzType="default" (click)="paginaProxima()" [disabled]="disabledProximaPaginaDetalhe">
                    Próxima <span nz-icon nzType="right" nzTheme="outline" style="font-size: 14px"></span>
                </button>
            </nz-button-group>
        </div>

    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalDemaisColunas(false)">Fechar</button>
    </div>
</nz-modal>
