<nz-page-header class="site-page-header" nzTitle="Cadastros" nzSubtitle="Obrigações">
    <nz-page-header-extra>
        <div style="display: flex;">
            <button [nz-tooltip]="'Sumarizar (user-concole)'" nz-button [nzType]="'link'" (click)="atualizarInfo()"
                    [nzLoading]="atualizandoInfo"
                    *ngIf="currentUser.origem === 'console'">
                <span nz-icon nzType="reload" nzTheme="outline"></span>
            </button>
            <div class="d-flex align-items-center">
            <span style="margin-left: 8px; margin-right: 10px;">
                {{ dataProcessamento ? 'Atualizado em ' + (dataProcessamento | date: 'dd/MM/yy HH:mm') : '' }}
            </span>
            </div>
        </div>
    </nz-page-header-extra>
</nz-page-header>
<nz-content>
    <div nz-row [nzGutter]="24">
        <div nz-col nzXs="24" nzSm="24" nzMd="4" nzLg="4" nzXl="4">
            <nz-affix [nzOffsetTop]="offsetTop" [nzTarget]="target">
                <nz-anchor class="transparent-anchor" [nzAffix]="false" [nzShowInkInFixed]="true"
                           [nzContainer]="target">
                    <nz-link nzHref="#federal" nzTitle="Federal" *ngIf="tabAtivaOne === 0"></nz-link>
                    <nz-link nzHref="#estadual" nzTitle="Estadual" *ngIf="tabAtivaOne === 0"></nz-link>
                    <nz-link nzHref="#municipal" nzTitle="Municipal" *ngIf="tabAtivaOne === 0"></nz-link>
                    <nz-link nzHref="#previdenciario" nzTitle="Previdenciário" *ngIf="tabAtivaOne === 0"></nz-link>
                    <nz-link nzHref="#simples-nacional" nzTitle="Simples Nacional" *ngIf="tabAtivaOne === 0"></nz-link>
                    <nz-link nzHref="#cardTabsOne" nzTitle="Obrigações" *ngIf="tabAtivaOne === 1"></nz-link>
                    <nz-link #vencimentoOficialAnchor id="vencimentoOficialAnchor" nzHref="#cardTabsTwo"
                             nzTitle="Vencimento Oficial"
                             *ngIf="tabAtivaTwo === 0 && showTabsTwo"></nz-link>
                    <nz-link nzHref="#cardTabsTwo" nzTitle="Agenda"
                             *ngIf="tabAtivaTwo === 1 && showTabsTwo"></nz-link>
                </nz-anchor>
            </nz-affix>
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="20" nzLg="20" nzXl="20">
            <nz-alert nzType="warning" [nzMessage]="checkedMessage" style="margin-bottom: 15px !important;"
                      *ngIf="quantidadeEmpresasInscricoes > 0">
                <ng-template #checkedMessage>
                    <div *ngIf="quantidadeEmpresasInscricoes > 0" nz-row nzAlign="middle">
                        <div *ngIf="quantidadeEmpresasInscricoes == 1" nz-col nzSpan="24">
                            Existe <strong>{{ quantidadeEmpresasInscricoes }}</strong>
                            empresa sem inscrições, regimes ou situação cadastral definida.
                            <a (click)="openTab('/cadastros/inscricoes-regimes/', '', {somenteSemInscricaoRegime: 1})">
                                Ir para Cadastro de Inscrições e Regimes
                            </a>
                        </div>

                        <div *ngIf="quantidadeEmpresasInscricoes > 1" nz-col nzSpan="24">
                            Existem <strong>{{ quantidadeEmpresasInscricoes }}</strong>
                            empresas sem inscrições, regimes ou situação cadastral definida.
                            <a (click)="openTab('/cadastros/inscricoes-regimes/', '', {somenteSemInscricaoRegime: 1})">
                                Ir para Cadastro de Inscrições e Regimes
                            </a>
                        </div>
                    </div>
                </ng-template>
            </nz-alert>
            <nz-card [ngClass]="[!loadingCardTabsOne ? 'ant-card-table' : '']"
                     [nzLoading]="loadingCardTabsOne || tabAtivaOne === 1 && loadingCardTabsTwo"
                     [nzBodyStyle]="{'padding': loadingCardTabsOne || tabAtivaOne === 1 && loadingCardTabsTwo ? '15px' : '0px'}"
                     id="cardTabsOne">
                <nz-card-tab>
                    <nz-tabset nzSize="large" [(nzSelectedIndex)]="tabAtivaOne"
                               (nzSelectChange)="changeTabs($event, 'one')"
                               [nzTabBarExtraContent]="tabsOneExtra">
                        <nz-tab [nzDisabled]="loadingCardTabsOne" nzTitle="Orgão"></nz-tab>
                        <nz-tab [nzDisabled]="loadingCardTabsOne" nzTitle="Obrigações"></nz-tab>
                    </nz-tabset>
                </nz-card-tab>
                <nz-divider id="federal" nzText="Federal" nzOrientation="left"
                            style="padding: 0 15px; font-weight: bold;"
                            *ngIf="tabAtivaOne === 0"></nz-divider>
                <nz-table #tableFederal
                          nzSize="small"
                          [nzData]="dataFederal"
                          [nzFrontPagination]="false"
                          [nzShowPagination]="false"
                          *ngIf="tabAtivaOne === 0"
                >
                    <thead>
                    <tr>
                        <th colspan="3">Cadastros</th>
                        <th colspan="2">Obrigações</th>
                        <th></th>
                    </tr>
                    <tr>
                        <th nzColumnKey="matriz" nzAlign="center">Unidade</th>
                        <th nzColumnKey="obrigacoesSugeridas" nzAlign="center">Obrigações Disponíveis
                        </th>
                        <th nzColumnKey="empresasCadastradas" nzAlign="center">Empresas Cadastradas
                        </th>
                        <th nzColumnKey="estimadas" nzAlign="center">Estimada(s)</th>
                        <th nzColumnKey="cadastradas" nzAlign="center">Cadastradas(s)</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let item of tableFederal.data">
                        <td nzAlign="center">{{ item.descricao }}</td>
                        <td nzAlign="center">{{ item.cadastros.obrigacoes_sugeridas }}</td>
                        <td nzAlign="center">{{ item.cadastros.empresas_cadastradas }}</td>
                        <td nzAlign="center">{{ item.obrigacoes.estimadas }}</td>
                        <td nzAlign="center">{{ item.obrigacoes.cadastradas }}</td>
                        <td nzAlign="center">
                            <button style="margin-right: 5px" nz-button nzType="link"
                                    (click)="filtrarOrgao('Federal', item.label)">
                                Detalhes
                            </button>
                            <button [nzLoading]="loadingDiferenca[item.descricao + 'Federal']"
                                    nz-tooltip="Exportar diferença" nz-button nzType="link"
                                    (click)="exportarDiferenca(item, 'Federal')">
                                Exportar &ne;
                            </button>
                        </td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr>
                        <td nzAlign="center">Total</td>
                        <td></td>
                        <td nzAlign="center"></td>
                        <td nzAlign="center">{{ totaisFederal.estimadas }}</td>
                        <td nzAlign="center">{{ totaisFederal.cadastradas }}</td>
                        <td></td>
                    </tr>
                    </tfoot>
                </nz-table>

                <nz-divider id="estadual" nzText="Estadual IE normal" nzOrientation="left"
                            style="padding: 0 15px; font-weight: bold;"
                            *ngIf="tabAtivaOne === 0"></nz-divider>
                <nz-table #tableEstadual
                          nzSize="small"
                          [nzData]="dataEstadual"
                          [nzFrontPagination]="false"
                          [nzShowPagination]="false"
                          *ngIf="tabAtivaOne === 0"
                >
                    <thead>
                    <tr>
                        <th colspan="3">Cadastros</th>
                        <th colspan="2">Obrigações</th>
                        <th></th>
                    </tr>
                    <tr>
                        <th
                                nzAlign="center"
                                *ngFor="let column of listOfColumn;"
                                [(nzSortOrder)]="column.sortOrder"
                                [nzSortFn]="column.title === 'UF' ? column.sortFn : column.compare"
                        >
                            {{ column.title }}
                        </th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let item of tableEstadual.data">
                        <td nzAlign="center">{{ item.label }}</td>
                        <td nzAlign="center">{{ item.cadastros.obrigacoes_sugeridas }}</td>
                        <td nzAlign="center">{{ item.cadastros.empresas_cadastradas }}</td>
                        <td nzAlign="center">{{ item.obrigacoes.estimadas }}</td>
                        <td nzAlign="center">{{ item.obrigacoes.cadastradas }}</td>
                        <td nzAlign="center">
                            <button nz-button nzType="link" (click)="filtrarOrgao('Estadual', item.label)">Detalhes
                            </button>
                            <button [nzLoading]="loadingDiferenca[item.descricao + 'Estadual' + item.uf]"
                                    nz-tooltip="Exportar diferença" nz-button nzType="link"
                                    (click)="exportarDiferenca(item, 'Estadual')">
                                Exportar &ne;
                            </button>
                        </td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr>
                        <td nzAlign="center">Total</td>
                        <td></td>

                        <td nzAlign="center">{{ totaisEstaduais.empresasCadastradas }}</td>
                        <td nzAlign="center">{{ totaisEstaduais.estimadas }}</td>
                        <td nzAlign="center">{{ totaisEstaduais.cadastradas }}</td>
                        <td></td>
                    </tr>
                    </tfoot>
                </nz-table>

                <nz-divider id="estadual_st" nzText="Estadual IE ST" nzOrientation="left"
                            style="padding: 0 15px; font-weight: bold;"
                            *ngIf="tabAtivaOne === 0 && showEstadualST"></nz-divider>
                <nz-table #tableEstadualST
                          nzSize="small"
                          [nzData]="dataEstadualST"
                          [nzFrontPagination]="false"
                          [nzShowPagination]="false"
                          *ngIf="tabAtivaOne === 0 && showEstadualST"
                >
                    <thead>
                    <tr>
                        <th colspan="3">Cadastros</th>
                        <th colspan="2">Obrigações</th>
                        <th></th>
                    </tr>
                    <tr>
                        <th
                                nzAlign="center"
                                *ngFor="let column of listOfColumn_ie_st;"
                                [(nzSortOrder)]="column.sortOrder"
                                [nzSortFn]="column.title === 'UF' ? column.sortFn : column.compare"
                        >
                            {{ column.title }}
                        </th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let item of tableEstadualST.data">
                        <td nzAlign="center">{{ item.label }}</td>
                        <td nzAlign="center">{{ item.cadastros.obrigacoes_sugeridas }}</td>
                        <td nzAlign="center">{{ item.cadastros.empresas_cadastradas }}</td>
                        <td nzAlign="center">{{ item.obrigacoes.estimadas }}</td>
                        <td nzAlign="center">{{ item.obrigacoes.cadastradas }}</td>
                        <td nzAlign="center">
                            <button nz-button nzType="link" (click)="filtrarOrgao('Estadual1', item.label)">Detalhes
                            </button>
                            <button [nzLoading]="loadingDiferenca[item.descricao + 'EstadualST' + item.uf]"
                                    nz-tooltip="Exportar diferença" nz-button nzType="link"
                                    (click)="exportarDiferenca(item, 'EstadualST')">
                                Exportar &ne;
                            </button>
                        </td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr>
                        <td nzAlign="center">Total</td>
                        <td></td>

                        <td nzAlign="center">{{ totaisEstaduaisST.empresasCadastradas }}</td>
                        <td nzAlign="center">{{ totaisEstaduaisST.estimadas }}</td>
                        <td nzAlign="center">{{ totaisEstaduaisST.cadastradas }}</td>
                        <td></td>
                    </tr>
                    </tfoot>
                </nz-table>

                <nz-divider id="municipal" nzText="Municipal" nzOrientation="left"
                            style="padding: 0 15px; font-weight: bold;"
                            *ngIf="tabAtivaOne === 0"></nz-divider>
                <nz-table #tableMunicipais
                          nzSize="small"
                          [nzData]="dataMunicipal"
                          [nzFrontPagination]="false"
                          [nzShowPagination]="false"
                          *ngIf="tabAtivaOne === 0"
                          nzBordered
                          style="table-layout:fixed;">
                    <thead>
                    <tr>
                        <th colspan="2"></th>
                        <th colspan="2">Cadastros</th>
                        <th colspan="2">Obrigações</th>
                        <th nzAlign="center"></th>
                    </tr>
                    <tr>
                        <th #column7 style="text-align: center">UF</th>
                        <th #column8 nzAlign="center"></th>
                        <th #column9 style="text-align: center">Obrigações Disponíveis</th>
                        <th #column10 style="text-align: center">Empresas Cadastradas</th>
                        <th #column11 style="text-align: center">Estimada(s)</th>
                        <th #column12 style="text-align: center">Cadastradas</th>
                        <th style="min-width: 150px" #column13 style="text-align: center">
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <ng-container class="ant-table-tbody" style="padding: 0; margin: 0"
                                  *ngFor="let item of tableMunicipais.data">
                        <tr>
                            <td nzAlign="center"
                                [ngStyle]="{
                                'width': '200px',
                                'min-width': '200px'
                            }">
                                {{ item.uf }}
                            </td>
                            <td style="color: #1e9ff2; width: 30px; padding-left: 15px"
                                [nzExpand]="expandSetMunicipal.has(item.uf)"
                                (nzExpandChange)="onExpandChangeMunicipal(item.uf, $event)"
                            ></td>
                            <td nzAlign="center">{{ totaisMunicipais[item.uf].obrigacoes_sugeridas }}</td>
                            <td nzAlign="center">{{ totaisMunicipais[item.uf].empresasCadastradas }}</td>
                            <td nzAlign="center">{{ totaisMunicipais[item.uf].estimadas }}</td>
                            <td nzAlign="center">{{ totaisMunicipais[item.uf].cadastradas }}</td>
                            <td style="min-width: 150px" nzAlign="center">
                                <span style="min-width: 150px"></span>
                            </td>
                        </tr>
                        <ng-container *ngIf="item.municipios.length > 0">
                            <tr [nzExpand]="expandSetMunicipal.has(item.uf)"
                                *ngFor="let m of item.municipios">
                                <td colspan="2"
                                    [ngStyle]="{
                                    'min-width': '242px',
                                    position: 'relative',
                                    padding: '8px',
                                    'text-align': 'right',
                                    'overflow-wrap': 'break-word'
                                    }">
                                    {{ m.municipioNome }}
                                </td>
                                <td [ngStyle]="{
                                    'width': column9Width + 'px',
                                    position: 'relative',
                                    padding: '8px',
                                    'overflow-wrap': 'break-word',
                                    'text-align': 'center'
                                    }">
                                    {{ m.cadastros.obrigacoes_sugeridas }}
                                </td>
                                <td [ngStyle]="{
                                    'width': column10Width + 'px',
                                    position: 'relative',
                                    padding: '8px',
                                    'overflow-wrap': 'break-word',
                                    'text-align': 'center'
                                }">
                                    {{ m.cadastros.empresas_cadastradas }}
                                </td>
                                <td [ngStyle]="{
                                    'width': column11Width + 'px',
                                    position: 'relative',
                                    padding: '8px',
                                    'overflow-wrap': 'break-word',
                                    'text-align': 'center'
                                    }">
                                    {{ m.obrigacoes.estimadas }}
                                </td>
                                <td [ngStyle]="{
                                    'width': column12Width + 'px',
                                    position: 'relative',
                                    padding: '8px',
                                    'overflow-wrap': 'break-word',
                                    'text-align': 'center'
                                    }">
                                    {{ m.obrigacoes.cadastradas }}
                                </td>
                                <td [ngStyle]="{
                                    'width': column13Width + 'px',
                                    position: 'relative',
                                    padding: '8px',
                                    'overflow-wrap': 'break-word',
                                    'text-align': 'center'
                                    }"
                                    nzAlign="center">
                                    <button nz-button nzType="link"
                                            (click)="filtrarOrgao('Municipal', m.municipioCodigo)">
                                        Detalhes
                                    </button>
                                </td>
                            </tr>
                        </ng-container>
                        <ng-container *ngIf="tableMunicipais.data.length === 0 || item.municipios === undefined">
                            <tr [nzExpand]="expandSet.has(item.uf)" style="text-align: center;">
                                Sem Dados
                            </tr>
                        </ng-container>
                    </ng-container>
                    </tbody>
                </nz-table>

                <nz-divider id="previdenciario" nzText="Previdenciário" nzOrientation="left"
                            style="padding: 0 15px; font-weight: bold;"
                            *ngIf="tabAtivaOne === 0"></nz-divider>
                <nz-table #tablePrevidenciario
                          nzSize="small"
                          [nzData]="dataPrevidenciario"
                          [nzFrontPagination]="false"
                          [nzShowPagination]="false"
                          *ngIf="tabAtivaOne === 0">
                    <thead>
                    <tr>
                        <th colspan="3">Cadastros</th>
                        <th colspan="2">Obrigações</th>
                        <th></th>
                    </tr>
                    <tr>
                        <th nzAlign="center">Unidade</th>
                        <th nzAlign="center">Obrigações Disponíveis</th>
                        <th nzAlign="center">Empresas Cadastradas</th>
                        <th nzAlign="center">Estimada(s)</th>
                        <th nzAlign="center">Cadastradas(s)</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let item of tablePrevidenciario.data">
                        <td nzAlign="center">{{ item.descricao }}</td>
                        <td nzAlign="center">{{ item.cadastros.obrigacoes_sugeridas }}</td>
                        <td nzAlign="center">{{ item.cadastros.empresas_cadastradas }}</td>
                        <td nzAlign="center">{{ item.obrigacoes.estimadas }}</td>
                        <td nzAlign="center">{{ item.obrigacoes.cadastradas }}</td>
                        <td nzAlign="center">
                            <button nz-button nzType="link" (click)="filtrarOrgao('Previdenciario', item.label)">
                                Detalhes
                            </button>
                            <button [nzLoading]="loadingDiferenca[item.descricao + 'Previdenciario']"
                                    nz-tooltip="Exportar diferença" nz-button nzType="link"
                                    (click)="exportarDiferenca(item, 'Previdenciario')">
                                Exportar &ne;
                            </button>
                        </td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr>
                        <td nzAlign="center">Total</td>
                        <td></td>
                        <td nzAlign="center"></td>
                        <td nzAlign="center">{{ totaisPrevidenciario.estimadas }}</td>
                        <td nzAlign="center">{{ totaisPrevidenciario.cadastradas }}</td>
                        <td></td>
                    </tr>
                    </tfoot>
                </nz-table>

                <nz-divider id="simples-nacional" nzText="Simples Nacional" nzOrientation="left"
                            style="padding: 0 15px; font-weight: bold;" *ngIf="tabAtivaOne === 0"></nz-divider>
                <nz-table #tableSimplesNacional
                          nzSize="small"
                          [nzData]="dataSimplesNacional"
                          [nzFrontPagination]="false"
                          [nzShowPagination]="false"
                          *ngIf="tabAtivaOne === 0"
                >
                    <thead>
                    <tr>
                        <th colspan="3">Cadastros</th>
                        <th colspan="2">Obrigações</th>
                        <th></th>
                    </tr>
                    <tr>
                        <th nzAlign="center">Unidade</th>
                        <th nzAlign="center">Obrigações Disponíveis</th>
                        <th nzAlign="center">Empresas Cadastradas</th>
                        <th nzAlign="center">Estimada(s)</th>
                        <th nzAlign="center">Cadastradas(s)
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let item of tableSimplesNacional.data">
                        <td nzAlign="center">{{ item.descricao }}</td>
                        <td nzAlign="center">{{ item.cadastros.obrigacoes_sugeridas }}</td>
                        <td nzAlign="center">{{ item.cadastros.empresas_cadastradas }}</td>
                        <td nzAlign="center">{{ item.obrigacoes.estimadas }}</td>
                        <td nzAlign="center">{{ item.obrigacoes.cadastradas }}</td>
                        <td nzAlign="center">
                            <button nz-button nzType="link" (click)="filtrarOrgao('SimplesNacional', item.label)">
                                Detalhes
                            </button>
                            <button [nzLoading]="loadingDiferenca[item.descricao + 'SimplesNacional']"
                                    nz-tooltip="Exportar diferença" nz-button nzType="link"
                                    (click)="exportarDiferenca(item, 'SimplesNacional')">
                                Exportar &ne;
                            </button>
                        </td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr>
                        <td nzAlign="center">Total</td>
                        <td></td>
                        <td nzAlign="center"></td>
                        <td nzAlign="center">{{ totaisSimplesNacional.estimadas }}</td>
                        <td nzAlign="center">{{ totaisSimplesNacional.cadastradas }}</td>
                        <td></td>
                    </tr>
                    </tfoot>
                </nz-table>

                <nz-table #tableObrigacoesDivididas
                          nzSize="small"
                          [nzData]="arrayTableObrigacoesFront"
                          [nzFrontPagination]="false"
                          [nzShowPagination]="false"
                          *ngIf="tabAtivaOne === 1 && !this.filtroObrigacoes.orgao"
                          nzBordered
                          style="table-layout:fixed;">
                    <thead>
                    <tr>
                        <th #column1 style="text-align: center"></th>
                        <th #column2 style="text-align: center"></th>
                        <th #column3 style="text-align: center; padding: 0 30px">
                            Obrigações
                        </th>
                        <th #column6
                            style="text-align:
                            center; padding: 0 30px">
                            Categoria
                        </th>
                        <th #column4 style="text-align: center">
                            Obrigações cadastradas por empresa
                        </th>
                        <th #column5>
                            <span style="padding: 0 50px"></span>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <ng-container class="ant-table-tbody" style="padding: 0; margin: 0"
                                  *ngFor="let data of tableObrigacoesDivididas.data">
                        <tr>
                            <td nzAlign="center"
                                [ngStyle]="{
                                    'width': '200px',
                                    'min-width': '200px'
                                }">
                                {{ data.tipoObg }}
                            </td>
                            <td style="color: #1e9ff2; width: 30px; padding-left: 15px"
                                [nzExpand]="expandSet.has(data.tipoObg)"
                                (nzExpandChange)="onExpandChange(data.tipoObg, $event)"
                            ></td>
                            <td nzAlign="center">
                                {{ data.obrigacoes.length }}
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <ng-container *ngIf="data.obrigacoes.length > 0">
                            <tr [nzExpand]="expandSet.has(data.tipoObg)"
                                *ngFor="let item of data.obrigacoes">
                                <td [ngStyle]="{
                                        'min-width': '242px',
                                        position: 'relative',
                                        padding: '0 8px',
                                        'text-align': 'right',
                                        'overflow-wrap': 'break-word'
                                    }"></td>
                                <td [ngStyle]="{
                                        'width': column3Width + 'px',
                                        position: 'relative',
                                        padding: '0 8px',
                                        'overflow-wrap': 'break-word',
                                        'text-align': 'center'
                                    }">
                                    {{ item.descricao }}
                                </td>

                                <td [ngStyle]="{
                                        'width': column6Width + 'px',
                                        position: 'relative',
                                        padding: '0 8px',
                                        'overflow-wrap': 'break-word',
                                        'text-align': 'center'
                                    }">
                                    {{ item.categoria }}
                                </td>
                                <td [ngStyle]="{
                                        'width': column4Width + 'px',
                                        position: 'relative',
                                        padding: '0 8px',
                                        'overflow-wrap': 'break-word',
                                        'text-align': 'center'
                                    }">
                                    {{ item.qnt_empresas_cadastradas | number:'' :'pt-br' }}
                                </td>
                                <td [ngStyle]="{
                                        'width': column5Width + 'px',
                                        position: 'relative',
                                        padding: '0 8px',
                                        'overflow-wrap': 'break-word',
                                        'text-align': 'center'
                                    }">
                                    <button nz-button nzType="link"
                                            (click)="editarObrigacao(item.obrigacao_id, item.descricao, item.temAgenda)">
                                        Editar
                                    </button>
                                </td>
                            </tr>
                        </ng-container>
                        <ng-container *ngIf="data.obrigacoes.length === 0 || data.obrigacoes === undefined">
                            <tr [nzExpand]="expandSet.has(data.tipoObg)" style="text-align: center;">
                                Sem Dados
                            </tr>
                        </ng-container>
                    </ng-container>
                    </tbody>
                </nz-table>

                <nz-table #tableObrigacoes
                          nzSize="small"
                          [nzData]="arrayTableObrigacoesComFiltroFront"
                          [nzFrontPagination]="false"
                          [nzShowPagination]="false"
                          *ngIf="tabAtivaOne === 1 && this.filtroObrigacoes.orgao">
                    <thead>
                    <tr>
                        <th *ngFor="let column of listOfColumnTwo;"
                            [(nzSortOrder)]="column.sortOrder"
                            [nzSortFn]="column.title === 'Obrigações' ? column.sortFn : column.compare"
                            nzAlign="center">
                            {{ column.title }}

                        </th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let data of tableObrigacoes.data">
                        <td nzAlign="center">
                            {{ data.descricao }}
                        </td>
                        <td nzAlign="center">
                            {{ data.categoria }}
                        </td>
                        <td nzAlign="center">
                            {{ data.qnt_empresas_cadastradas | number:'' :'pt-br' }}
                        </td>
                        <td nzAlign="center">
                            <button nz-button nzType="link" [nzLoading]="loadingEditar.includes(data.obrigacao_id)"
                                    *ngIf="data.obrigacao_id.length < 36 || (data.obrigacao_id.length == 36 && data.temAgenda === 1) else disabled"
                                    (click)="editarObrigacao(data.obrigacao_id, data.descricao, data.temAgenda)">Editar
                            </button>
                            <ng-template #disabled>
                                <button nz-button nzType="link" style="cursor: not-allowed; color: #cdcdcd"
                                        nz-tooltip="Cadastro de obrigação manual não disponível para agenda!">
                                    Editar
                                </button>
                            </ng-template>
                        </td>
                    </tr>
                    </tbody>
                </nz-table>

            </nz-card>
            <div id="cardTabsTwo" #cardTabsTwo>
                <nz-card [ngClass]="[statusTwo ? 'card-fullscreen' : '', !loadingCardTabsTwo ? 'ant-card-table' : '']"
                         [nzLoading]="loadingCardTabsTwo"
                         [nzBodyStyle]="{'padding': loadingCardTabsTwo ? '15px' : '0px'}"
                         *ngIf="showTabsTwo"
                         nzTitle="{{obrigacaoSelecionadaLabel}}"
                         style="width: 100%;"
                         [nzExtra]="cardTabsTwoExtra">
                    <ng-template #cardTabsTwoExtra>
                        <!--<div class="d-flex align-items-center" *ngIf="loadingAdicionarEmpresas">
                            <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
                            <nz-spin nzSimple [nzIndicator]="indicatorTemplate"></nz-spin>
                            <span class="loading" style="margin-left: 8px;">Cadastrando empresas sugeridas</span>
                        </div>-->
                    </ng-template>
                    <nz-card-tab>
                        <nz-tabset nzSize="large" [(nzSelectedIndex)]="tabAtivaTwo" [nzTabBarExtraContent]="tabsExtra"
                                   (nzSelectChange)="changeTabs($event, 'two')">
                            <nz-tab nzTitle="Vencimento Oficial"></nz-tab>
                            <nz-tab nzTitle="Agenda" *ngIf="temAgenda === 1"></nz-tab>
                        </nz-tabset>
                    </nz-card-tab>
                    <nz-alert nzType="info" nzShowIcon [nzMessage]="checkedMessage" style="margin: 15px !important;"
                              *ngIf="checkedItems.size > 0">
                        <ng-template #checkedMessage>
                            <div nz-row nzAlign="middle" nzGutter="24">
                                <div nz-col nzSpan="24">
                                    <strong class="text-primary"></strong>
                                    {{ checkedItems.size }} Registros Selecionados
                                </div>
                            </div>
                        </ng-template>
                    </nz-alert>
                    <nz-table #tableVencimentoOficial
                              [nzScroll]="{x: 'auto'}"
                              [nzData]="arrayTableVencimentoOficial"
                              [nzFrontPagination]="false"
                              *ngIf="tabAtivaTwo == 0"
                              [nzTotal]="pagination.oficiais?.total"
                              [nzPageSize]="pagination.oficiais?.per_page"
                              [nzPageIndex]="pagination.oficiais?.current_page"
                              (nzQueryParams)="retornaEmpresasObrigacoesOficiaisTable($event)"
                              nzSize="small"
                              nzBordered>
                        <thead>
                        <tr>
                            <th colspan="6"></th>
                            <th colspan="{{arrayVencimentoOficialCompetencias.length}}">Competências</th>
                            <th></th>
                        </tr>
                        <tr>
                            <th nzAlign="center"></th>
                            <th style="text-align: center;">
                                <label nz-checkbox [(ngModel)]="checked"
                                       (nzCheckedChange)="onAllChecked($event)"></label>
                            </th>
                            <th nzAlign="center" nzColumnKey="empresa" [nzSortFn]="true">Empresas</th>
                            <th nzAlign="center" nzColumnKey="cnpj">CNPJ</th>
                            <th nzAlign="center" nzColumnKey="grupo" [nzSortFn]="true">Grupo</th>
                            <th nzAlign="center" nzColumnKey="cadastro" [nzSortFn]="true">Cadastro</th>
                            <th nzAlign="center"
                                *ngFor="let data of arrayVencimentoOficialCompetencias">
                            <span *ngIf="data.competencia.length === 4">
                                {{ data.competencia | date: 'yyyy' }}
                            </span>
                                <span *ngIf="data.competencia.length > 4">
                                {{ data.competencia | date: 'MM/yyyy' }}
                            </span>
                            </th>
                            <th *ngIf="arrayVencimentoOficialCompetencias.length === 0"></th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let data of tableVencimentoOficial.data">
                            <td nzAlign="center">
                                <a nz-button nzType="text" nzDanger (click)="showConfirm(data.id, confirm)">
                                    <i class="far fa-trash-alt"></i>
                                </a>
                            </td>
                            <td nzAlign="center" (nzCheckedChange)="onItemChecked(data.id, $event)"
                                [nzChecked]="checkedItems.has(data.id)"
                                [nzDisabled]="data.disabled">
                            </td>
                            <td style="white-space: nowrap;">
                            <span style="margin-right: 5px;">{{ modoDemo(data.empresa, 'empresaCodigoNome') }}
                                <br>
                                <small *ngIf="data.ie">({{ data?.ie }})</small>
                            </span>
                                <nz-tag [nzColor]="'#f50'" *ngIf="!data?.empresa_ativo"
                                        style="margin-left: 10px; font-size: 10px; float: right;">
                                    Inativo
                                </nz-tag>
                            </td>
                            <td style="text-align: center; white-space: nowrap;">{{ modoDemo(data.cnpj | cpfCnpj) }}</td>
                            <td style="white-space: nowrap;">{{ modoDemo(data.grupo, 'empresaCodigoNome') }}</td>
                            <td nzAlign="center" style="white-space: nowrap;">
                                <span style="display: block;">{{ data.data_create | date: 'dd/MM/yyyy HH:mm' }}</span>
                                <small>{{ data.usuario_create }}</small>
                            </td>
                            <td nzAlign="center" *ngFor="let item of arrayVencimentoOficialCompetencias">
                             <span *ngIf="data.objectVencimentos[item.competencia] === 'semVigencia' ">
                                Não obrigatório
                            </span>
                                <span *ngIf="data.objectVencimentos[item.competencia] !== 'semVigencia'">
                                {{ (data.objectVencimentos[item.competencia] | date: 'dd/MM/yyyy') }}
                            </span>
                            </td>
                            <td *ngIf="data.vencimentos.length === 0"></td>
                            <td nzAlign="center">
                                <button nz-button nzType="link"
                                        (click)="modalObrigacaoVencimento(true, data.vencimentos, data.empresa_id, data.empresa)">
                                    Editar
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </nz-table>
                    <nz-table #tableCalendar
                              [nzData]="arrayTableCalendar"
                              *ngIf="tabAtivaTwo == 1"
                              [nzFrontPagination]="false"
                              [nzTotal]="pagination.calendar?.total"
                              [nzPageSize]="pagination.calendar?.per_page"
                              [nzPageIndex]="pagination.calendar?.current_page"
                              (nzQueryParams)="retornaEmpresasObrigacaoCalendar($event, null, true)"
                              nzSize="small"
                              nzBordered
                              class="scrollbar">
                        <thead>
                        <tr>
                            <th colspan="8"></th>
                            <th colspan="{{arrayCalendarCompetencias.length}}">Competências</th>
                            <th></th>
                        </tr>
                        <tr>
                            <th nzAlign="center"></th>
                            <th style="text-align: center;">
                                <label nz-checkbox [(ngModel)]="checked"
                                       (nzCheckedChange)="onAllChecked($event)"></label>
                            </th>
                            <th nzAlign="center" nzColumnKey="empresa" [nzSortFn]="true">Empresas</th>
                            <th nzAlign="center" nzColumnKey="cnpj" [nzSortFn]="true">CNPJ</th>
                            <th nzAlign="center" nzColumnKey="grupo" [nzSortFn]="true">Grupo</th>
                            <th nzAlign="center" nzColumnKey="equipe" [nzSortFn]="true">Equipe</th>
                            <th nzAlign="center" nzColumnKey="responsavel" [nzSortFn]="true">Responsável</th>
                            <th nzAlign="center" nzColumnKey="cadastro" [nzSortFn]="true">Cadastro</th>
                            <th nzAlign="center"
                                *ngFor="let data of arrayCalendarCompetencias">
                                {{ data.competencia | date: 'MM/yyyy' }}
                            </th>
                            <th *ngIf="arrayCalendarCompetencias.length === 0"></th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let data of tableCalendar.data">
                            <td nzAlign="center">
                                <a nz-button nzType="text" nzDanger (click)="showConfirm(data.id, confirm)">
                                    <i class="far fa-trash-alt"></i>
                                </a>
                            </td>
                            <td nzAlign="center" (nzCheckedChange)="onItemChecked(data.id, $event)"
                                [nzChecked]="checkedItems.has(data.id)"
                                [nzDisabled]="data.disabled">
                            </td>
                            <td style="width: 230px;">
                                <span style="margin-right: 5px;">{{ modoDemo(data.empresa, 'empresaCodigoNome') }}</span>
                            </td>
                            <td>
                                {{ modoDemo(data.cnpj | cpfCnpj) }}
                            </td>
                            <td style="white-space: nowrap;">{{ modoDemo(data.grupo, 'total') }}</td>
                            <td nzAlign="center">{{ modoDemo(data.equipe, 'total') }}</td>
                            <td nzAlign="center"
                                style="white-space: nowrap">{{ modoDemo(data.responsavel, 'total') }}
                            </td>
                            <td nzAlign="center" style="white-space: nowrap;">
                                <span style="display: block;">{{ data.data_update | date: 'dd/MM/yyyy HH:mm' }}</span>
                                <small>{{ data.usuario_update }}</small>
                            </td>
                            <td nzAlign="center" *ngFor="let item of arrayCalendarCompetencias">
                            <span *ngIf="data.objectVencimentos[item.competencia]">
                                {{ data.objectVencimentos[item.competencia] | date: 'dd/MM/yyyy' }}
                            </span>
                            </td>
                            <td *ngIf="this.arrayCalendarCompetencias.length === 0"></td>
                            <td nzAlign="center">
                                <button nz-button nzType="link"
                                        (click)="modalObrigacaoVencimento(true, data.vencimentos, data.empresa_id, data.empresa, data.responsavel_id, data.equipe_id, data.cnpj)">
                                    Editar
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </nz-table>
                </nz-card>
            </div>
        </div>
    </div>
</nz-content>
<ng-template #tabsOneExtra>
    <div class="d-flex align-items-center" style="gap: 5px">
        <button nz-button [nzType]="'primary'" (click)="modalCadastrarEmMassa(true)"
                [nzLoading]="loadingSugeridasMassa"
                *ngIf="tabAtivaOne === 0 && hasPermissao(screenPermissoes.cadastrarSugeridas, currentPermissoes)">
            {{ loadingSugeridasMassa ? 'Carregando...' : 'Cadastrar Obrigações Sugeridas' }}
        </button>
        <button nz-button [nzType]="'primary'" (click)="showConfirmlimparSugeridas()"
                [nzLoading]="limparSugeridasMassa"
                *ngIf="tabAtivaOne === 0">
            {{ limparSugeridasMassa ? 'Carregando...' : ' Limpar Obrigações Sugeridas' }}
        </button>
        <div *ngIf="tabAtivaOne === 1" style="width: 230px">
            <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" [nzSuffix]="inputClearTpl"
                            style="margin-right: 10px;">
                <input [disabled]="loadingCardTabsTwo" (keyup)="buscarFront()" type="text" nz-input placeholder="Busca"
                       [(ngModel)]="filtroObrigacoes.procurar"/>
            </nz-input-group>
            <ng-template #suffixIconButton>
                <button [disabled]="loadingCardTabsTwo" nz-button nzType="primary" nzSearch
                        (click)="buscarFront();"><i
                        nz-icon nzType="search"></i></button>
            </ng-template>
            <ng-template #inputClearTpl>
      <span
              nz-icon
              class="ant-input-clear-icon"
              nzTheme="fill"
              nzType="close-circle"
              *ngIf="filtroObrigacoes.procurar"
              (click)="buscarFront(true)"
      ></span>
            </ng-template>
        </div>
        <div class="d-flex align-items-center" *ngIf="tabAtivaOne === 1 && this.filtroObrigacoes.orgao">
            <span style="margin-left: 8px; margin-right: 10px;">
                Orgão: {{ this.filtroObrigacoes.orgao }} {{ this.filtroObrigacoes.fonte ? ' | ' + this.filtroObrigacoes.fonte : '' }}
            </span>
        </div>
        <button nz-button [nzType]="'default'" (click)="limparFiltros()"
                *ngIf="tabAtivaOne === 1 && this.filtroObrigacoes.orgao">
            Exibir Todos
        </button>
    </div>
</ng-template>
<ng-template #tabsExtra>
    <div class="d-flex">
        <nz-badge [nzCount]="qtdFiltrosAtivos"
                  [nzStyle]="{ backgroundColor: '#52c41a', 'margin-right' : '10px'}">
            <button nz-button nzType="primary"
                    style="margin-right: 10px;"
                    (click)="modalFiltrar(true);">
                <i nz-icon nzType="search"></i>
                Filtros
            </button>
        </nz-badge>
        <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" style="margin-right: 10px;">
            <input (keydown.enter)="retornaEmpresasObrigacoesOficiais(currentParams.oficiais, currentSearch.oficiais, false, true);"
                   type="text" nz-input placeholder="Busca" [(ngModel)]="currentSearch.oficiais">
        </nz-input-group>
        <ng-template #suffixIconButton>
            <button nz-button nzType="primary" nzSearch
                    (click)="retornaEmpresasObrigacoesOficiais(currentParams.oficiais, currentSearch.oficiais, false, true);">
                <i nz-icon nzType="search"></i></button>
        </ng-template>
        <button nz-button *ngIf="tabAtivaTwo === 0 || (obrigacaoSelecionada.length > 35)" [nzType]="'primary'"
                style="margin-right: 5px;"
                (click)="modalObrigacaoVencimento(true)">
            Adicionar
        </button>
        <button [nzDropdownMenu]="menuAcoes" style="margin-right: 5px;" class="centralized-icon" nz-button nz-dropdown
                nzType="default">
            Opções
            <em nz-icon nzType="down"></em>
        </button>

        <nz-dropdown-menu #menuAcoes="nzDropdownMenu">
            <ul nz-menu>
                <li nz-submenu nzTitle="Manutenção Coletiva">
                    <ul>
                        <li nz-menu-item nzDisabled>Ações</li>
                        <li nz-menu-divider></li>
                        <li nz-menu-item (click)="showConfirmAlterarEquipe()" *ngIf="tabAtivaTwo === 1">Alterar Equipe
                        </li>
                        <li nz-menu-item *ngIf="tabAtivaTwo === 1" [nzDisabled]="this.checkedItems.size === 0"
                            (click)="this.checkedItems.size > 0 && showConfirmAlterarResponsavel()">Alterar
                            Responsável
                        </li>
                        <li [nzDisabled]="this.checkedItems.size === 0" nz-menu-item
                            (click)="this.checkedItems.size === 0 ? '' : modalAlterarVencimento(true)">Alterar
                            Vencimentos
                        </li>
                        <li [nzDisabled]="this.checkedItems.size === 0" nz-menu-item
                            (click)="this.checkedItems.size === 0 ? '' : showConfirm(null, confirm)">Remover Registros
                        </li>
                    </ul>
                </li>
                <li nz-menu-item nz-button (click)="showConfirmAdicionarEmpresas()">Adicionar Empresas Sugeridas</li>
                <li [nzDisabled]="loadingRestaurarVencimentos" nz-menu-item nz-button
                    (click)="showConfirmRestaurarVencimentos()">
                    {{ loadingRestaurarVencimentos ? 'Restaurando vencimentos...' : 'Restaurar vencimentos oficiais' }}
                </li>
            </ul>
        </nz-dropdown-menu>
        <button style="margin-right: 5px;" nz-button nzType="primary" nz-dropdown
                [nzDropdownMenu]="menu"
                nzLoading="{{loadingCardTabsTwo}}">
            {{ selectedValue }}
            <i nz-icon nzType="down"></i>
        </button>
        <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu>
                <li nz-menu-item *ngFor="let ano of arrayAnos" (click)="changeAno(ano)">
                    <a>{{ ano }}</a>
                </li>
            </ul>
        </nz-dropdown-menu>
        <button nz-button nzType="default" style="margin-right: 5px; padding: 4px 8px;" (click)="reloadCardTwo()">
            <i class="fas fa-redo-alt"></i>
        </button>
        <button nz-button nzType="default" (click)="clickEvent('two')" style="padding: 4px 8px;">
            <i class="fas" [ngClass]="!statusTwo ? 'fa-expand' : 'fa-compress'"></i>
        </button>
    </div>
</ng-template>
<nz-modal [(nzVisible)]="formObrigacaoVencimento.modalVisible || formCalendar.modalVisible"
          [nzTitle]="modalEmpresaLabel" [nzClosable]="true"
          (nzOnCancel)="modalObrigacaoVencimento(false)"
          [nzWidth]="648">
    <nz-row *nzModalContent>
        <nz-col nzSpan="24">
            <form nz-form [nzNoColon]="true" [formGroup]="formObrigacaoVencimento.formGroup"
                  *ngIf="formObrigacaoVencimento.modalVisible">
                <nz-form-item *ngIf="!modalEmpresaEditar">
                    <nz-form-label [nzSpan]="6">Empresa</nz-form-label>
                    <nz-form-control [nzSpan]="16">
                        <nz-select nzShowSearch nzAllowClear
                                   (ngModelChange)="listVigencias(obrigacaoSelecionada, $event)"
                                   [nzPlaceHolder]="'Selecione'" formControlName="empresa">
                            <nz-option *ngFor="let opt of arraySelectEmpresa"
                                       [nzLabel]="opt.descricao + ' (' + (opt.empresa_cnpj | cpfCnpj) + ')'"
                                       [nzValue]="opt.id"
                                       nzCustomContent>
                                {{ opt.descricao }}
                                <span *ngIf="opt.ie"
                                      style="color: rgb(24, 144, 255); cursor: pointer" nz-icon
                                      nzType="info-circle" nzTheme="outline" nz-tooltip="IE: {{opt.ie}}">
                                </span>
                                <br><small>({{ opt.empresa_cnpj | cpfCnpj }})</small>
                            </nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item *ngIf="modalEmpresaEditar">
                    <nz-form-label [nzSpan]="6">Empresa:</nz-form-label>
                    <nz-form-control [nzSpan]="16">
                        <input formControlName="empresa" type="hidden" [readonly]="modalEmpresaEditar">
                        <span>{{ modoDemo(empresaNomeEditar, 'empresaCodigoNome') }}</span>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item *ngFor="let item of arrayVigencias">
                    <nz-form-label [nzSpan]="6">{{ item.competencia | date:'MM/yyyy' }}</nz-form-label>
                    <nz-form-control [nzSpan]="16">
                        <nz-date-picker *ngIf="item.vigencia !== 'semVigencia'" (keyup)="maskNZDatePicker($event)"
                                        style="width: 100%;"
                                        formControlName="{{item.competencia}}"
                                        nzFormat="dd/MM/yyyy"></nz-date-picker>

                        <input [disabled]="true" nz-input *ngIf="item.vigencia === 'semVigencia'" style="width: 100%;"
                               value="Competência anterior ao inicio da inscrição">
                    </nz-form-control>
                </nz-form-item>
            </form>
            <form nz-form [nzNoColon]="true" [formGroup]="formCalendar.formGroup" *ngIf="formCalendar.modalVisible">
                <nz-form-item *ngIf="!modalEmpresaEditar">
                    <nz-form-label [nzSpan]="6">Empresa</nz-form-label>
                    <nz-form-control [nzSpan]="12">
                        <nz-select nzShowSearch
                                   [nzPlaceHolder]="'Selecione'" formControlName="empresa">
                            <nz-option *ngFor="let opt of arraySelectEmpresa"
                                       [nzLabel]="opt.descricao + ' (' + (opt.empresa_cnpj | cpfCnpj) + ')'"
                                       [nzValue]="opt.id">
                            </nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item *ngIf="modalEmpresaEditar">
                    <nz-form-label [nzSpan]="6">Empresa:</nz-form-label>
                    <nz-form-control [nzSpan]="12">
                        <input formControlName="empresa" type="hidden" [readonly]="modalEmpresaEditar">
                        <span>{{ modoDemo(empresaNomeEditar, 'empresaCodigoNome') }}</span>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="6">Equipe</nz-form-label>
                    <nz-form-control [nzSpan]="12">
                        <nz-select nzShowSearch nzAllowClear
                                   [nzPlaceHolder]="'Selecione'" formControlName="equipe_id">
                            <nz-option *ngFor="let opt of comboEquipes"
                                       [nzLabel]="modoDemo(opt.label, 'total')"
                                       [nzValue]="opt.key"
                            >
                            </nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="6">Responsável</nz-form-label>
                    <nz-form-control [nzSpan]="12">
                        <nz-select nzShowSearch nzAllowClear
                                   [nzPlaceHolder]="'Selecione'" formControlName="responsavel_id">
                            <nz-option *ngFor="let opt of comboResponsaveis"
                                       [nzLabel]="modoDemo(opt.label, 'total')"
                                       [nzValue]="opt.key"
                            >
                            </nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item *ngFor="let item of arrayCalendarCompetencias">
                    <nz-form-label [nzSpan]="6">{{ item.competencia | date:'MM/yyyy' }}</nz-form-label>
                    <nz-form-control [nzSpan]="12">
                        <nz-date-picker (keyup)="maskNZDatePicker($event)" style="width: 100%;"
                                        formControlName="{{item.competencia}}"
                                        nzFormat="dd/MM/yyyy"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
            </form>
        </nz-col>
    </nz-row>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalObrigacaoVencimento(false)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="loadingAdicionarEmpresas" *ngIf="!modalEmpresaEditar"
                (click)="insertEmpresas()">Adicionar
        </button>
        <button nz-button nzType="primary" *ngIf="modalEmpresaEditar" [nzLoading]="loadingEditando"
                (click)="editarVencimentos()">Editar
        </button>
    </div>
</nz-modal>
<nz-modal [(nzVisible)]="displayVisibilidadeModal"
          [nzAutofocus]="null" [nzFooter]="modalFooter" nzTitle="Alterar Equipes"
          (nzOnCancel)="handleCancel()">
    <ng-template nzModalContent>
        <div style="margin-bottom: 10px;">
            <nz-select [(ngModel)]="selectedEquipes"
                       [nzPlaceHolder]="'Selecione as equipes'"
                       style="width: 100%;">
                <nz-option *ngFor="let option of equipeOptions" [nzLabel]="option.label"
                           [nzValue]="option.value"></nz-option>
            </nz-select>
        </div>
    </ng-template>
    <ng-template #modalFooter>
        <button (click)="addNewEquipe()" class="new-markup-bnt" nz-button nzType="link">
            <em nz-icon nzType="plus-circle"></em>Nova equipe
        </button>
        <button [nzLoading]="loadingAlterarEquipe" (click)="saveBulkToEquipes()" nz-button nzType="primary">Ok</button>
        <button (click)="handleCancel()" nz-button nzType="default">Cancelar</button>
    </ng-template>
</nz-modal>
<nz-modal (nzOnCancel)="closeNewEquipe()" (nzOnOk)="saveNewEquipe()"
          [nzOkLoading]="loadingAlterarEquipe"
          [nzOkText]="'Adicionar Equipe'"
          [(nzVisible)]="newVisibilidadeModal"
          [nzAutofocus]="null"
          nzTitle="Nova Equipe">
    <ng-template nzModalContent>
        <nz-form-item>
            <label for="novaEquipe"></label>
            <input [(ngModel)]="newEquipe" id="novaEquipe" nz-input placeholder="descrição" type="text">
        </nz-form-item>
    </ng-template>
</nz-modal>
<nz-modal [(nzVisible)]="formVencimento.modalVisible"
          [nzTitle]="'Alterar Vencimentos'" [nzClosable]="true"
          (nzOnCancel)="modalAlterarVencimento(false)"
          [nzWidth]="648">
    <nz-row *nzModalContent>
        <nz-col nzSpan="24">
            <form nz-form [nzNoColon]="true" [formGroup]="formVencimento.formGroup">
                <ng-container *ngIf="tabAtivaTwo === 0">
                    <nz-form-item *ngFor="let item of arrayVencimentoOficialCompetencias">
                        <nz-form-label [nzSpan]="6">{{ item.competencia | date:'MM/yyyy' }}</nz-form-label>
                        <nz-form-control [nzSpan]="12">
                            <nz-date-picker (keyup)="maskNZDatePicker($event)" style="width: 100%;"
                                            formControlName="{{item.competencia}}"
                                            nzFormat="dd/MM/yyyy"></nz-date-picker>
                        </nz-form-control>
                    </nz-form-item>
                </ng-container>
                <ng-container *ngIf="tabAtivaTwo === 1">
                    <nz-form-item *ngFor="let item of arrayCalendarCompetencias">
                        <nz-form-label [nzSpan]="6">{{ item.competencia | date:'MM/yyyy' }}</nz-form-label>
                        <nz-form-control [nzSpan]="12">
                            <nz-date-picker (keyup)="maskNZDatePicker($event)" style="width: 100%;"
                                            formControlName="{{item.competencia}}"
                                            nzFormat="dd/MM/yyyy"></nz-date-picker>
                        </nz-form-control>
                    </nz-form-item>
                </ng-container>
            </form>
        </nz-col>
    </nz-row>
    <div *nzModalFooter>
        <button nz-button nzType="link">Cancelar</button>
        <button nz-button nzType="primary" (click)="perguntaAlterarPendentes()">Alterar</button>
    </div>
</nz-modal>
<nz-modal [(nzVisible)]="formResponsavel.modalVisible"
          [nzTitle]="'Alterar Responsável'" [nzClosable]="true"
          (nzOnCancel)="modalAlterarResponsavel(false)"
          [nzWidth]="648">
    <nz-row *nzModalContent>
        <nz-col nzSpan="24">
            <form nz-form [nzNoColon]="true" [formGroup]="formResponsavel.formGroup">
                <nz-form-item>
                    <nz-form-label [nzSpan]="6">Responsável</nz-form-label>
                    <nz-form-control [nzSpan]="12">
                        <nz-select nzShowSearch nzAllowClear
                                   [nzPlaceHolder]="'Selecione'" formControlName="responsavel_id">
                            <nz-option *ngFor="let opt of comboResponsaveis"
                                       [nzLabel]="modoDemo(opt.label, 'total')"
                                       [nzValue]="opt.key"
                            >
                            </nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
            </form>
        </nz-col>
    </nz-row>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalAlterarResponsavel(false)">Cancelar</button>
        <button [nzLoading]="loadingCardTabsTwo" nz-button nzType="primary" (click)="saveBulkToResponsavel()">
            Adicionar
        </button>
    </div>
</nz-modal>
<nz-modal [(nzVisible)]="formCadastrarEmMassa.modalVisible"
          [nzTitle]="'Cadastrar Obrigações Sugeridas em Massa'" [nzClosable]="true"
          (nzOnCancel)="modalCadastrarEmMassa(false);"
          [nzWidth]="968">
    <div *nzModalContent>
        <div style="border-bottom: 1px rgba(0,0,0,0.2) solid;">
            <nz-steps nzDirection="vertical">
                <nz-step nzTitle="Selecionar Empresas:"
                         nzSubtitle="Selecione as empresas onde serão cadastradas as Obrigações."
                         nzStatus="{{steps[0].step}}"
                         nzIcon="{{steps[0].icon}}"></nz-step>
                <nz-step *ngIf="steps[0].step === 'finish'"
                         nzTitle="Selecionar Módulos:"
                         nzSubtitle="Selecione os módulos que deseja cadastrar as Obrigações."
                         nzStatus="{{steps[1].step}}"
                         nzIcon="{{steps[1].icon}}"></nz-step>
                <nz-step *ngIf="steps[1].step === 'finish'"
                         nzTitle="Selecionar Obrigações:"
                         nzSubtitle="Selecione as Obrigações a serem cadastradas."
                         nzStatus="{{steps[2].step}}"
                         nzIcon="{{steps[2].icon}}"></nz-step>
                <nz-step *ngIf="steps[2].step === 'finish'"
                         nzTitle="Cadastro Finalizado"
                         nzStatus="{{steps[3].step}}"
                         nzIcon="{{steps[3].icon}}"></nz-step>
            </nz-steps>
        </div>
        <form nz-form [nzNoColon]="false" [formGroup]="formStepsObrigacoes.formGroup">
            <nz-content *ngIf="steps[0].step !== 'finish'" style="margin: 20px 0 40px 0;">
                <nz-row nzGutter="24" style="margin-bottom: 20px;">
                    <nz-col nzSpan="24">
                        <nz-radio-group [(ngModel)]="radioValueStepZero" [ngModelOptions]="{standalone: true}">
                            <label nz-radio nzValue="todas">Todas as Empresas</label>
                            <label nz-radio nzValue="algumas">Algumas Empresas</label>
                        </nz-radio-group>
                    </nz-col>
                </nz-row>
                <nz-row nzGutter="24" *ngIf="radioValueStepZero === 'algumas'">
                    <nz-col nzSpan="24">
                        <nz-alert nzType="info" nzShowIcon [nzMessage]="checkedMessageEmpresas"
                                  style="margin: 15px !important;"
                                  *ngIf="checkedItemsEmpresas.size > 0">
                            <ng-template #checkedMessageEmpresas>
                                <div nz-row nzAlign="middle" nzGutter="24">
                                    <div nz-col nzSpan="24">
                                        <strong class="text-primary"></strong>
                                        {{ checkedItemsEmpresas.size }} Registros Selecionados
                                    </div>
                                </div>
                            </ng-template>
                        </nz-alert>
                        <nz-table #tableEmpresas
                                  [nzData]="items"
                                  [nzFrontPagination]="false"
                                  [nzTotal]="pagination?.total"
                                  [nzPageSize]="pagination?.per_page"
                                  [nzPageIndex]="pagination?.current_page"
                                  (nzQueryParams)="retornaObrigacoes($event)"
                                  [nzShowTotal]="rangeTemplateEmpresas"
                                  [nzLoading]="loading"
                                  class="table-small">
                            <thead>
                            <tr>
                                <!--<th (nzCheckedChange)="onAllChecked($event)" [nzChecked]="checked"
                                    [nzIndeterminate]="indeterminate">
                                </th>-->
                                <th></th>
                                <th nzAlign="center" nzColumnKey="codigo" [nzSortFn]="true">Código</th>
                                <th nzAlign="center" nzColumnKey="nome" [nzSortFn]="true">Empresa</th>
                                <th nzAlign="center" nzColumnKey="cnpj" [nzSortFn]="true">CNPJ</th>
                                <th nzAlign="center" nzColumnKey="grupoEmpresarial_descricao" [nzSortFn]="true">Grupo
                                </th>
                                <th nzAlign="center" nzColumnKey="estado" [nzSortFn]="true">UF</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let arquivo of tableEmpresas.data">
                                <td nzAlign="center" (nzCheckedChange)="onItemCheckedEmpresas(arquivo.id, $event)"
                                    [nzChecked]="checkedItemsEmpresas.has(arquivo.id)"
                                    [nzDisabled]="arquivo.disabled">
                                </td>
                                <td nzAlign="center">{{ modoDemo(arquivo?.codigo, 'total') }}</td>
                                <td style="width: 300px;">
                                    {{ modoDemo(arquivo?.nome, 'empresaCodigoNome') }}
                                </td>
                                <td nzAlign="center">
                                    {{ modoDemo(arquivo?.cnpj | cpfCnpj, 'cnpj') }}
                                </td>
                                <td nzAlign="center">{{ arquivo?.grupoEmpresarial_descricao }}</td>
                                <td nzAlign="center">{{ arquivo?.estado }}</td>
                            </tr>
                            </tbody>
                        </nz-table>
                        <ng-template #rangeTemplateEmpresas let-range="range" let-total>
                            {{ range[0] }}-{{ range[1] }} de {{ total }} Registros
                        </ng-template>
                    </nz-col>
                </nz-row>
            </nz-content>
            <nz-content *ngIf="steps[0].step === 'finish' && steps[1].step !== 'finish'" style="margin: 40px 0 40px 0;">
                <nz-row nzGutter="24">
                    <nz-col nzSpan="24">
                        <nz-checkbox-wrapper style="width: 100%;" (nzOnChange)="selecionarModulos($event)">
                            <div nz-row>
                                <div nz-col nzSpan="8">
                                    <label nz-checkbox nzValue="calendar" [(ngModel)]="modelCalendar"
                                           [ngModelOptions]="{standalone: true}">Calendar</label>
                                </div>
                            </div>
                            <div nz-row>
                                <div nz-col nzSpan="8">
                                    <label nz-checkbox nzValue="check" [(ngModel)]="modelCheck"
                                           [ngModelOptions]="{standalone: true}">Check</label>
                                </div>
                            </div>
                            <div nz-row>
                                <div nz-col nzSpan="8">
                                    <label nz-checkbox nzValue="compliance" [(ngModel)]="modelCompliance"
                                           [ngModelOptions]="{standalone: true}">Compliance</label>
                                </div>
                            </div>
                        </nz-checkbox-wrapper>
                    </nz-col>
                </nz-row>
            </nz-content>
            <nz-content *ngIf="steps[1].step === 'finish' && steps[2].step !== 'finish'" style="margin: 20px 0 40px 0;">
                <nz-row nzGutter="24" style="margin-bottom: 20px;">
                    <nz-col nzSpan="24">
                        <nz-radio-group [(ngModel)]="radioValueStepOne" [ngModelOptions]="{standalone: true}"
                                        (ngModelChange)="retornaObrigacoesEmpresasSteps()">
                            <label nz-radio nzValue="todas">Todas as Obrigações</label>
                            <label nz-radio nzValue="algumas">Algumas Obrigações</label>
                        </nz-radio-group>
                    </nz-col>
                </nz-row>
                <nz-row nzGutter="24" *ngIf="radioValueStepOne === 'algumas'">
                    <nz-skeleton *ngIf="loadingBuscandoObrigacoes"></nz-skeleton>
                    <nz-col nzSpan="12" nzOffset="6" style="margin-bottom: 15px;">
                        <div [formGroup]="groupBuscaObrigacoes">

                            <input type="text" nz-input placeholder="Busca"
                                   *ngIf="!loadingBuscandoObrigacoes"
                                   formControlName="buscarObrigacao"
                                   (ngModelChange)="changeBuscaObrigacao()"/>
                        </div>
                    </nz-col>
                    <nz-col nzSpan="24">
                        <nz-alert nzType="info" nzShowIcon [nzMessage]="checkedMessageObrigacoes"
                                  style="margin: 15px !important;"
                                  *ngIf="checkedItemsObrigacoes.size > 0">
                            <ng-template #checkedMessageObrigacoes>
                                <div nz-row nzAlign="middle" nzGutter="24">
                                    <div nz-col nzSpan="24">
                                        <strong class="text-primary"></strong>
                                        {{ checkedItemsObrigacoes.size }} Registros Selecionados
                                    </div>
                                </div>
                            </ng-template>
                        </nz-alert>
                        <nz-table #tableObrigacoes
                                  [nzData]="arrayObrigacoesSearch"
                                  [nzFrontPagination]="true"
                                  [nzShowTotal]="rangeTemplateObrigacao"
                                  *ngIf="!loadingBuscandoObrigacoes"
                                  class="table-small">
                            <thead>
                            <tr>
                                <!--<th (nzCheckedChange)="onAllChecked($event)" [nzChecked]="checked"
                                    [nzIndeterminate]="indeterminate">
                                </th>-->
                                <th></th>
                                <th nzColumnKey="descricao" [nzSortFn]="true" nzAlign="center">Obrigação</th>
                            </tr>
                            </thead>
                            <tbody>
                            <ng-container *ngFor="let arquivo of tableObrigacoes.data">
                                <tr>
                                    <td nzLeft (nzCheckedChange)="onItemCheckedObrigacoes(arquivo.id, $event)"
                                        [nzChecked]="checkedItemsObrigacoes.has(arquivo.id)"
                                        [nzDisabled]="arquivo.disabled">
                                    </td>
                                    <td nzAlign="center">{{ arquivo?.descricao }}</td>
                                </tr>
                            </ng-container>
                            </tbody>
                        </nz-table>
                        <ng-template #rangeTemplateObrigacao let-range="range" let-total>
                            {{ range[0] }}-{{ range[1] }} de {{ total }} Registros
                        </ng-template>
                    </nz-col>
                </nz-row>
            </nz-content>
        </form>

    </div>
    <div *nzModalFooter>
        <div *ngIf="stepNumber === 0" style="display: inline-block; float: left">
            <button nz-button nzType="default" [nzLoading]="loadingLog"
                    (click)="!loadingLog && verLog()">
                {{ logVisible ? 'Ocultar Log' : 'Ver log' }}
            </button>
        </div>
        <div class="d-flex"
             [ngClass]="[stepNumber === 0 ? 'justify-content-end' : '', stepNumber > 0 ? 'justify-content-between' : '']">
            <button nz-button nzType="default"
                    *ngIf="stepNumber !== 0 && stepNumber < 3"
                    (click)="changeStep(this.stepNumber, 'prev')">
                <i nz-icon nzType="double-left" nzTheme="outline"></i>
                Retornar
            </button>
            <button nz-button nzType="primary"
                    *ngIf="stepNumber < 3"
                    (click)="changeStep(this.stepNumber, 'next')" [nzLoading]="stepsLoading">
                {{ nextButtonText }}
                <i nz-icon nzType="double-right" nzTheme="outline"></i>
            </button>
        </div>
        <div [ngClass]="[logVisible ? 'log logExpanded' : 'log']">
            <div *ngIf="log?.length > 0 && logVisible else semLog">
                <div style="margin: 10px; color: #3d3d3d" *ngFor="let l of log">
                    <nz-badge nzStatus="default" nzText="Cadastrado por"></nz-badge>
                    {{ l.nome }}
                    em {{ l.updated_at | date: "dd/MM/yy 'às' HH:mm" }}
                </div>
            </div>
            <ng-template #semLog>
                <span *ngIf="logVisible">
                    Sem log para exibir.
                </span>
            </ng-template>
        </div>
    </div>
</nz-modal>
<ng-template #confirm>
    <label nz-checkbox [(ngModel)]="removerTodosAnos">Remover <b>todos</b> os anos cadastrados</label>
</ng-template>
<nz-modal [(nzVisible)]="formFiltrar.modalVisible" [nzTitle]="'Filtros Avançados'" [nzClosable]="true"
          (nzOnCancel)="modalFiltrar(false)" [nzWidth]="648">
    <div nz-row *nzModalContent>

        <form nz-form [nzDisableAutoTips]="true" [nzNoColon]="true" [formGroup]="formFiltrar.formGroup"
              style="width: 100%;">

            <nz-form-item>
                <nz-form-label [nzSpan]="5">Grupo Empresarial</nz-form-label>
                <nz-form-control [nzSpan]="14"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.grupo">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="grupo">
                        <nz-option *ngFor="let opt of comboGrupos"
                                   [nzLabel]="modoDemo(opt.label, 'total')"
                                   [nzValue]="opt.key"
                        >
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item *ngIf="tabAtivaTwo === 1">
                <nz-form-label [nzSpan]="5">Equipe</nz-form-label>
                <nz-form-control [nzSpan]="14"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.equipe">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="equipe">
                        <nz-option *ngFor="let opt of comboEquipes"
                                   [nzLabel]="modoDemo(opt.label, 'total')"
                                   [nzValue]="opt.key"
                        >
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item *ngIf="tabAtivaTwo === 1">
                <nz-form-label [nzSpan]="5">Responsável</nz-form-label>
                <nz-form-control [nzSpan]="14"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.responsavel">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="responsavel">
                        <nz-option *ngFor="let opt of comboResponsaveis"
                                   [nzLabel]="modoDemo(opt.label, 'total')"
                                   [nzValue]="opt.key"
                        >
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="5">Inativo</nz-form-label>
                <nz-form-control [nzSpan]="14"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.inativo">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="inativo">
                        <nz-option
                                [nzLabel]="'Mostrar somente inativos'" [nzValue]="'somente'">
                        </nz-option>
                        <nz-option
                                [nzLabel]="'Mostrar também inativos'" [nzValue]="'tambem'">
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>


        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalFiltrar(false)">Cancelar</button>
        <button nz-button nzType="primary" (click)="confirmaFiltrar()">Confirmar</button>
    </div>
</nz-modal>
