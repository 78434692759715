import {Component} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {CategoriasEStatusService} from './categorias-e-status.service';
import {ToastrService} from 'ngx-toastr';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {Pagination} from '@models/pagination.model';

@Component({
    selector: 'app-notas-fiscais-entrada',
    templateUrl: './categorias-e-status.component.html',
    styleUrls: ['./categorias-e-status.component.scss']
})
export class CategoriasEStatusComponent {

    autoTips: Record<string, Record<string, string>> = {
        default: {
            required: 'Campo obrigatório'
        }
    };

    loading = false;
    loadingBt = false;
    loadingRemover = {};
    loadingRemoverStatus = {};
    expand = false;
    modalFiltrarVisible = false;
    modalAdicionarVisible = false;
    modalStatusVisible = false;
    modalAddStatusVisible = false;
    modalAlterarStatusVisible = false;
    modalAlterarVisible = false;

    pageIndex = 1;
    pageSize = 50;

    currentParams = {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        sort: [],
        filter: []
    };

    pagination: Pagination = {
        per_page: this.pageSize,
        current_page: this.pageIndex,
        last_page: 10,
        total: 50,
        from: 1,
        to: 50
    };

    currentSearch: string;

    items = [];

    statusOptions = [];

    formFiltros = this.fb.group({
        status_id: [null, null]
    });

    qtdFiltrosAtivos = 0;

    formAdicionarCategoria = this.fb.group({
        descricao: [null, Validators.required],
        cor: ['#000', Validators.required],
        icone: ['', Validators.required],
    });

    formAlterarCategria = this.fb.group({
        descricao: [null, Validators.required],
        categoria_id: [null, Validators.required],
        cor: [null, Validators.required],
        icone: [null],
    });

    categoriaSelecionada: any = null;

    formAddStatusACategoria = this.fb.group({
        categoria_id: [this.categoriaSelecionada?.id || null, Validators.required],
        descricao: [null, Validators.required],
        opcoes: [[]],
        manifestacaoTipo_id: [null, null],
        observacao: [false, null],
    });

    formAlterarStatus = this.fb.group({
        status_id: [this.categoriaSelecionada?.id || null, Validators.required],
        descricao: [null, Validators.required],
        opcoes: [[]],
        manifestacaoTipo_id: [null, null],
        observacao: [false, null],
    });

    icones = [];
    iconeSelecionado: string;

    manifestacoes = [];

    constructor(
        private fb: FormBuilder,
        private service: CategoriasEStatusService,
        private toastService: ToastrService) {
    }

    ngOnInit() {
        this.getStatus();
        this.getManifestacoes();
        this.queryTable(this.currentParams, this.currentSearch);
        this.icones.push('fa-regular fa-file-lines');
        this.icones.push('fa-solid fa-magnifying-glass');
    }

    getStatus() {
        this.service.getStatus().subscribe({
            next: (res) => {
                res.forEach((value: any) => {
                    value.value = value.id;
                    value.label = value.descricao;
                });
                this.statusOptions = res;
            },
            error: (err) => {
                this.toastService.error(err.error.message);
            }
        });
    }

    getManifestacoes() {
        this.service.getManifestacoes().subscribe({
            next: (res) => {
                this.manifestacoes = Object.entries(res);
            },
            error: (err) => {
                this.toastService.error(err.error.message);
            }
        });
    }

    expandEvent() {
        this.expand = !this.expand;
    }

    calculaBadgeFiltros(): void {
        this.qtdFiltrosAtivos = 0;
        if (typeof this.formFiltros !== 'undefined') {
            for (const [chave, valor] of Object.entries(this.formFiltros.value)) {

                if (valor && chave !== 'page') {
                    this.qtdFiltrosAtivos++;
                }
            }
        }
    }

    modalFiltrar(visible) {
        this.modalFiltrarVisible = visible;
    }

    modalAddStatus(visible, cancel = false) {

        this.statusOptions.forEach(s => {
            s.direction = 'left';
        });

        this.modalAddStatusVisible = visible;

        if (!visible) {
            this.formAddStatusACategoria.reset();
            // @ts-ignore
            this.formAddStatusACategoria.get('opcoes').setValue([]);
        } else {
            this.modalStatusVisible = !visible;
        }

        if (cancel) {
            this.modalStatusVisible = true;
        }
    }

    modalAlterarStatus(visible, status: any = {}, cancel = false) {

        this.formAlterarStatus.get('status_id').setValue(status.id);
        this.formAlterarStatus.get('descricao').setValue(status.descricao);
        this.formAlterarStatus.get('manifestacaoTipo_id').setValue(status.manifestacaoTipo_id);
        this.formAlterarStatus.get('observacao').setValue(!!status.observacao);

        this.modalAlterarStatusVisible = visible;

        if (!visible) {
            this.formAlterarStatus.reset();
            // @ts-ignore
            this.formAlterarStatus.get('opcoes').setValue([]);
            this.statusOptions.forEach(s => {
                s.direction = 'left';
            });
        } else {

            this.formAlterarStatus.get('opcoes').setValue(status.opcoes?.split(',') || []);

            this.statusOptions.forEach(s => {

                if (s.id === status.id) {
                    s.disabled = true;
                } else {
                    s.disabled = false;
                }

                // @ts-ignore
                if (this.formAlterarStatus?.get('opcoes')?.value.includes(s.id) && !s.disabled) {
                    s.direction = 'right';
                }
            });

            this.modalStatusVisible = !visible;
        }

        if (cancel) {
            this.modalStatusVisible = true;
        }
    }

    modalAdicionar(visible) {
        this.modalAdicionarVisible = visible;
    }

    modalAlterar(visible, categoria = null) {

        this.categoriaSelecionada = categoria || this.categoriaSelecionada;
        this.iconeSelecionado = this.categoriaSelecionada.icone;

        this.modalAlterarVisible = visible;
        this.formAlterarCategria.get('descricao').setValue(categoria?.descricao);
        this.formAlterarCategria.get('categoria_id').setValue(categoria?.id);
        this.formAlterarCategria.get('cor').setValue(categoria?.cor);
        this.formAlterarCategria.get('icone').setValue(categoria?.icone);
    }

    modalStatus(visible, categoria = null) {
        this.modalStatusVisible = visible;
        this.categoriaSelecionada = categoria;

        if (!visible) {
            this.formAdicionarCategoria.reset();
        }
    }

    listByTable(params: NzTableQueryParams) {
        let buscar = false;

        if (params.pageIndex === this.pagination.current_page) {
            params.sort.forEach(s => {
                if (s.value) {
                    buscar = true;
                }
            });
        }

        if ((buscar || params.pageIndex === 1 && this.pagination.current_page > 1)
            || params.pageIndex > 1
            && params.pageIndex !== this.pagination.current_page) {
            params.filter = this.currentParams.filter;
            this.queryTable(params);
            this.pagination.current_page = params.pageIndex;
        }

    }

    queryTable(params: NzTableQueryParams, search: string = null): void {

        this.items = [];

        this.loading = true;
        this.currentParams = params;
        this.currentSearch = search;

        this.calculaBadgeFiltros();

        this.service.getCategorias(params, this.currentSearch).subscribe((response: any) => {

            this.items = response;

            this.loading = false;
        });

    }

    btnResetSearch() {

        this.currentSearch = null;

        this.currentParams = {
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            sort: [],
            filter: [],
        };

        this.formFiltros.reset();

        this.queryTable(this.currentParams, this.currentSearch);

    }

    filtrar() {

        this.modalFiltrarVisible = false;

        let filtros;

        const params = this.currentParams;
        params.filter = [];
        params.pageIndex = 1;

        filtros = this.formFiltros.value;
        for (const [chave, valor] of Object.entries(filtros)) {
            params.filter.push({key: chave, value: valor});
        }

        this.queryTable(params, this.currentSearch);
    }

    adicionar() {

        if (this.formAdicionarCategoria.valid) {
            this.loadingBt = true;

            this.service.addCategoria({
                cor: this.formAdicionarCategoria.value.cor,
                descricao: this.formAdicionarCategoria.value.descricao,
                icone: this.formAdicionarCategoria.value.icone
            }).subscribe({
                next: (res) => {
                    this.toastService.success('Categoria adicionada com sucesso');
                    this.loadingBt = false;
                    this.modalAdicionar(false);
                    this.queryTable(this.currentParams, this.currentSearch);
                },
                error: (err) => {
                    this.loadingBt = false;
                    this.toastService.error(err.error.message);
                }
            });

        } else {
            Object.values(this.formAdicionarCategoria.controls).forEach(control => {
                if (control.invalid) {
                    control.markAsDirty();
                    control.updateValueAndValidity({onlySelf: true});
                }
            });

            if (!this.formAdicionarCategoria.value.icone) {
                this.toastService.error('Selecione um ícone para a nova categoria');
            }
        }

    }

    alterar() {

        if (this.formAlterarCategria.valid) {
            this.loadingBt = true;

            this.service.alterarCategoria({
                cor: this.formAlterarCategria.value.cor,
                descricao: this.formAlterarCategria.value.descricao,
                icone: this.formAlterarCategria.value.icone
            }, this.formAlterarCategria.value.categoria_id).subscribe({
                next: (res) => {
                    this.toastService.success('Categoria alterada com sucesso');
                    this.loadingBt = false;
                    this.modalAdicionar(false);
                    this.modalAlterar(false);
                    this.queryTable(this.currentParams, this.currentSearch);
                },
                error: (err) => {
                    this.loadingBt = false;
                    this.toastService.error(err.error.message);
                }
            });

        } else {
            Object.values(this.formAdicionarCategoria.controls).forEach(control => {
                if (control.invalid) {
                    control.markAsDirty();
                    control.updateValueAndValidity({onlySelf: true});
                }
            });

            if (!this.formAdicionarCategoria.value.icone) {
                this.toastService.error('Selecione um ícone para a nova categoria');
            }
        }

    }

    adicionarStatus() {

        this.formAddStatusACategoria.get('categoria_id').setValue(this.categoriaSelecionada.id);

        // @ts-ignore
        if (this.formAddStatusACategoria.valid) {
            this.loadingBt = true;

            this.service.addStatus({
                categoria_id: this.formAddStatusACategoria.value.categoria_id,
                descricao: this.formAddStatusACategoria.value.descricao,
                opcoes: this.formAddStatusACategoria.value.opcoes,
                observacao: this.formAddStatusACategoria.value.observacao ? 1 : 0,
                manifestacaoTipo_id: this.formAddStatusACategoria.value.manifestacaoTipo_id
            }).subscribe({
                next: (res) => {
                    this.toastService.success('Categoria alterada com sucesso');
                    this.loadingBt = false;
                    this.modalAlterar(false);
                    this.modalAddStatus(false);
                    this.getStatus();
                    this.queryTable(this.currentParams, this.currentSearch);
                },
                error: (err) => {
                    this.loadingBt = false;
                    this.toastService.error(err.error.message === 'Opcoes não informada!' ?
                        'Informe pelo menos uma etapa permitida para o novo status' : err.error.message);
                }
            });

        } else {
            Object.values(this.formAddStatusACategoria.controls).forEach(control => {
                if (control.invalid) {
                    control.markAsDirty();
                    control.updateValueAndValidity({onlySelf: true});
                }
            });
        }

    }

    removerCategoria(id: string) {
        this.loadingRemover[id] = true;

        this.service.removerCategoria(id).subscribe({
            next: res => {
                this.loadingRemover[id] = false;
                this.toastService.success('Categoria removida com sucesso');
                this.queryTable(this.currentParams, this.currentSearch);
            },
            error: err => {
                this.loadingRemover[id] = false;
                this.toastService.error(err.error.message);
            }
        });
    }

    removerStatus(id: string) {
        this.loadingRemoverStatus[id] = true;

        this.service.removerStatus(id).subscribe({
            next: res => {
                this.loadingRemoverStatus[id] = false;
                this.toastService.success('Status removido com sucesso');

                let index = 0;
                for (const i of this.categoriaSelecionada.itens) {

                    if (i.id === id) {
                        this.categoriaSelecionada.itens.splice(index, 1);
                        break;
                    }

                    index += 1;

                }

                if (!this.categoriaSelecionada?.itens.length) {
                    this.modalStatus(false);
                }
                this.queryTable(this.currentParams, this.currentSearch);
            },
            error: err => {
                this.loadingRemoverStatus[id] = false;
                this.toastService.error(err.error.message);
            }
        });
    }

    alterarStatus() {

        if (this.formAlterarStatus.valid) {
            this.loadingBt = true;

            this.service.alterarStatus({
                categoria_id: this.categoriaSelecionada.id,
                descricao: this.formAlterarStatus.value.descricao,
                opcoes: this.formAlterarStatus.value.opcoes,
                observacao: this.formAlterarStatus.value.observacao ? 1 : 0,
                manifestacaoTipo_id: this.formAlterarStatus.value.manifestacaoTipo_id
            }, this.formAlterarStatus.value.status_id).subscribe({
                next: (res) => {
                    this.modalAlterarStatus(false);
                    this.toastService.success('Status alterado com sucesso');
                    this.loadingBt = false;
                    this.queryTable(this.currentParams);
                },
                error: (err) => {
                    this.loadingBt = false;
                    this.toastService.error(err.error.message === 'Opcoes não informada!' ? 'Informe pelo menos uma etapa permitida para o novo status' : err.error.message);
                }
            });

        } else {
            Object.values(this.formAlterarStatus.controls).forEach(control => {
                if (control.invalid) {
                    control.markAsDirty();
                    control.updateValueAndValidity({onlySelf: true});
                }
            });
        }

    }

    selecionouIcone(iconClass: string) {
        this.iconeSelecionado = iconClass;

        if (this.modalAdicionarVisible) {
            this.formAdicionarCategoria.get('icone').setValue(this.iconeSelecionado);
        } else if (this.modalAlterarVisible) {
            this.formAlterarCategria.get('icone').setValue(this.iconeSelecionado);
        }
    }

    changeTranferMudaPara(event: any, f) {

        if (event.to === 'right') {

            event.list.forEach(o => {
                // @ts-ignore
                f.get('opcoes').value.push(o.id);
            });
        } else {

            event.list.forEach(o => {
                // @ts-ignore
                const index = f.controls.opcoes.value.indexOf(o.id);
                // @ts-ignore
                f.controls.opcoes.value.splice(index, 1);
            });
        }
    }

    log(){
        console.log(this.formAlterarStatus.value);
    }
}


