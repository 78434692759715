import {AfterViewInit, ChangeDetectorRef, Component, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {Pagination} from '@models/pagination.model';
import {EcacService} from './ecac.service';
import * as Highcharts from 'highcharts';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {DataService} from '@services/data.service';
import {NzModalService} from 'ng-zorro-antd/modal';
import {NzUploadFile} from 'ng-zorro-antd/upload';
import {ExportarTabelaComponent} from '@components/exportar-tabela/exportar-tabela.component';
import {TabService} from '@services/tab.service';
import * as fileSaver from 'file-saver-es';
import {GrupoEmpresarialService} from '@services/grupoEmpresarial.service';
import {VisibilidadeService} from '@services/visibilidade.service';
import moment from "moment";

interface FormStack {
    modalVisible: boolean;
    formGroup: UntypedFormGroup;
}


@Component({
    selector: 'app-cadastros-ecac',
    templateUrl: './ecac.component.html',
    styleUrls: ['./ecac.component.scss']
})
export class ConsolidadoEcacComponent extends AbstractListTable<any> implements OnInit, AfterViewInit {

    @Input() data: any[];

    currentUser: any;

    comboObrigacoes = [];
    comboCategorias = [];
    formFiltros: FormStack;
    qtdFiltrosAtivos = 0;
    qtdFiltrosAtivosCabecalho = 0;
    arrayStatus = [
        '',
        'Regular',
        'Exigibilidade Suspensa',
        'Irregular',
    ];

    arrayColorStatus = [
        '',
        '#28d094',
        '#ff9149',
        '#ff4961'
    ];

    expand = false;
    arraySelectEmpresa = [];
    items: any[] = [];

    HighchartsPie: typeof Highcharts = Highcharts;
    chartPieOptions: Highcharts.Options;

    updateFlag: boolean;

    objectCountersValues = {
        qtdUnidades: 0,
        qtdObrigacoes: 0,
        qtdAusencias: 0,
        qtdDivergencias: 0,

        porcRegulares: 0,
        porcIrregulares: 0,
        porcSuspensas: 0,

        mesSelecionado: '',
        anoSelecionado: '',
        updated_at: null,
        qtdRegulares: 0,
        qtdIrregulares: 0,
        qtdSuspensa: 0
    };

    currentParams = {
        pageIndex: 1,
        pageSize: 50,
        sort: [],
        filter: []
    };

    currentParamsCabecalho = {
        pageIndex: 1,
        pageSize: 50,
        sort: [],
        filter: []
    };

    loadingContadores = true;
    dadosCabecalho: any = {};
    displayModal = false;
    isPdf = true;
    base64Selecionado = '';
    iframeUrl: SafeResourceUrl = '';
    screenHeight = 0;
    screenWidth = 0;
    screenModalTop = 0;
    identificador = '';
    loadingsUltimoRelatorio = {};

    modalHistoricoVisible = false;
    loadingHistorico = false;
    itemsHistorico = [];

    cnpjHistorico = '';
    processamentoFlag = {};

    loadingDesbloqueando = {};

    modalImportarVisible = false;

    fileList: NzUploadFile[] = [];
    uploading = false;

    formExportar: FormStack;

    loadings = {
        exportar: false,
        exportarZipPdfs: false,
    };

    apagarFiltro = true;

    arraySelectEmpresaCodigo = [];

    qtdFiltrosCabecalho = 0;
    formFiltrosCabecalho: FormStack;
    grupoEmpresarialOptions: { label: string; value: string }[] = [];
    visibilidadesOptions: { label: string; value: string }[] = [];

    exibirColunas: { visibilidade_descricao: boolean, grupoEmpresarial_descricao: boolean } = {
        visibilidade_descricao: false,
        grupoEmpresarial_descricao: false,
    };

    @ViewChild('componentExport') componentExport: ExportarTabelaComponent;

    constructor(
        private fb: UntypedFormBuilder,
        private service: EcacService,
        private toastService: ToastrService,
        private cdr: ChangeDetectorRef,
        public sanitizer: DomSanitizer,
        private dataService: DataService,
        private grupoEmpresarialService: GrupoEmpresarialService,
        private visibilidadeService: VisibilidadeService,
        private modalService: NzModalService
    ) {
        super(service, '/cadastros/obrigacao/ecac', toastService);

        this.getScreenSize();
        this.formFiltros = {
            modalVisible: false,
            formGroup: this.fb.group({
                empresaNome: [null, null],
                statusEmissao: [null, null],
                dataUltimaEmissao: [null, null],
                empresa_id_codigo: [null, null],
                statusProcessamento: [null, null],
            })
        };

        this.formExportar = {
            modalVisible: false,
            formGroup: this.fb.group({
                tipo: [null, Validators.required],
            })
        };

        this.service.retornarSelectsEmpresasCodigo().subscribe((retorno: any) => {
            this.arraySelectEmpresaCodigo = retorno.empresaCodigo;
        });
        this.currentSearch = '';

        this.formFiltrosCabecalho = {
            modalVisible: false,
            formGroup: this.fb.group({
                grupoEmpresarial_id: [null, null],
                visibilidade_id: [null, null],
            })
        };

    }

    ngOnInit() {

        if (this.data && this.data[0]?.statusEmissao) {
            this.formFiltros.formGroup.get('statusEmissao').setValue(this.data[0].statusEmissao);
            this.filtrar();
        }

        this.identificador = localStorage.getItem('identificador');
        this.service.retornarSelectsEmpresas(this.identificador).subscribe((retorno: any) => {

            this.arraySelectEmpresa = retorno.empresaNome;
        });

        if (this.data && this.data[0]?.statusEmissao) {
            this.formFiltros.formGroup.get('statusEmissao').setValue(this.data[0].statusEmissao);
        }

        this.dataService.currentUser.subscribe((data) => {
            this.currentUser = data;
            this.cdr.detectChanges();
        });

        if (localStorage.getItem('exibirOcultar-situacaoFiscal')) {
            this.exibirColunas = JSON.parse(localStorage.getItem('exibirOcultar-situacaoFiscal'));
        }


        this.getGruposEmpresariais();
        this.getVisibilidades();
    }

    ngAfterViewInit() {
        this.resizeModal();
        this.loadChartData();
        this.queryTable(this.currentParams, this.currentSearch);
    }

    @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
        this.resizeModal();
    }

    modalImportar(visible: boolean): void {
        this.modalImportarVisible = visible;
    }

    beforeUpload = (file: NzUploadFile): boolean => {
        this.fileList = this.fileList.concat(file);
        return false;
    }

    confirmarImportar(): void {

        this.uploading = true;

        const formData = new FormData();

        this.fileList.forEach((file: any) => {
            formData.append('arquivos[]', file);
        });

        this.service.uploadArquivo(formData).subscribe((response: any) => {
                this.toastrService.success(response.message);

                this.fileList = [];

                this.modalImportar(false);

                this.queryTable(this.currentParams, this.currentSearch);
                this.uploading = false;

            },
            (response) => {

                this.uploading = false;
                this.toastrService.error(response.error.message);
            });
    }

    formatOne = (percent: number) => `${percent}%`;


    resizeModal() {
        this.screenHeight = Math.round((window.innerHeight / 100) * 73);
        this.screenWidth = Math.round((window.innerWidth / 100) * 90);
        this.screenModalTop = Math.round((window.innerHeight / 100) * 9);
    }

    handleCancel() {
        this.displayModal = false;
    }

    listByTable(params: NzTableQueryParams) {
        let buscar = false;

        if (params.pageIndex === this.pagination.current_page) {
            params.sort.forEach(s => {
                if (s.value) {
                    buscar = true;
                }
            });
        }

        if ((buscar || params.pageIndex === 1 && this.pagination.current_page > 1)
            || params.pageIndex > 1
            && params.pageIndex !== this.pagination.current_page) {
            params.filter = this.currentParams.filter;
            this.queryTable(params);
            this.pagination.current_page = params.pageIndex;
        }

    }

    queryTable(params: NzTableQueryParams, search: string = null): void {

        this.items = [];

        this.loading = true;
        this.currentParams = params;
        this.currentSearch = search;

        this.calculaBadgeFiltros();

        const url = this.service.urlTabela;
        this.service.listToTable(params, this.currentSearch, url).subscribe((response: any) => {

            response.data.forEach((value, i) => {
                const indexStatus = value.situacao !== null ? value.situacao : 0;
                value.statusDescricao = this.arrayStatus[indexStatus];
                value.statusColor = this.arrayColorStatus[indexStatus];
            });

            this.items = response?.data || response;

            this.items.forEach(ecac => {

                if (ecac.ultimoProcessamentoLog && ecac.ultimoProcessamento){
                    ecac.ultimoProcessamentoLog += '\n' + moment(ecac.ultimoProcessamento).format('DD/MM/YYYY HH:mm');
                }
                const dataSolicitacao = new Date(ecac.ultimoRegistroData);
                // @ts-ignore
                const diffInMs = new Date() - dataSolicitacao;
                ecac.diasUltimaSolicitacao = diffInMs / (1000 * 60 * 60 * 24);
            });

            this.pagination = new Pagination(
                response?.per_page || 50,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 50,
                response?.from || 1,
                response?.to || 1);

            this.loading = false;
            this.apagarFiltro = true;
        });

    }

    calculaBadgeFiltros(): void {
        this.qtdFiltrosAtivos = 0;
        this.qtdFiltrosAtivosCabecalho = 0;
        if (typeof this.formFiltros !== 'undefined') {
            for (const [chave, valor] of Object.entries(this.formFiltros.formGroup.value)) {

                if (valor && chave !== 'page') {
                    this.qtdFiltrosAtivos++;
                }
            }
        }

        if (typeof this.formFiltrosCabecalho !== 'undefined') {
            for (const [chave, valor] of Object.entries(this.formFiltrosCabecalho.formGroup.value)) {

                if (valor) {
                    this.qtdFiltrosAtivosCabecalho++;
                }
            }
        }
    }

    modalFiltrar(visible) {
        this.formFiltros.modalVisible = visible;
    }

    filtrar(): any {

        this.apagarFiltro = false;

        this.formFiltros.modalVisible = false;

        let filtros;
        let filtrosCabecalho;

        const params = this.currentParams;
        params.filter = [];
        params.pageIndex = 1;

        filtros = this.formFiltros.formGroup.value;
        for (const [chave, valor] of Object.entries(filtros)) {
            if (chave === 'dataUltimaEmissao' && valor) {
                params.filter.push({key: chave, value: this.formataDateBD(valor.toString())});
            } else if (chave === 'empresa_id_codigo' && valor) {
                params.filter.push({key: 'empresaNome', value: valor});
            } else {
                params.filter.push({key: chave, value: valor});
            }
        }

        filtrosCabecalho = this.formFiltrosCabecalho.formGroup.value;
        for (const [chave, valor] of Object.entries(filtrosCabecalho)) {
            params.filter.push({key: chave, value: valor});
        }

        this.queryTable(params, this.currentSearch);
    }

    btnResetSearch() {

        this.currentSearch = null;

        this.currentParams = {
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            sort: [],
            filter: [],
        };

        this.formFiltros.formGroup.reset();

        this.queryTable(this.currentParams, this.currentSearch);

    }

    expandEvent() {
        this.expand = !this.expand;
    }

    loadChartData()
        :
        void {

        this.chartPieOptions = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie'
            },
            title: {
                text: undefined
            },
            tooltip: {
                pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
            },
            accessibility: {
                point: {
                    valueSuffix: '%'
                }
            },
            plotOptions: {
                pie: {
                    colors: [
                        'rgb(40, 208, 148)',
                        '#ff9149',
                        '#ff4961',
                    ],
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: false
                    },
                    showInLegend: false,
                    shadow: true
                }
            },
            series: [
                {
                    type: 'pie',
                    name: 'Obrigações',
                    data: [
                        {
                            name: 'Regular',
                            y: 1
                        },
                        {
                            name: 'Irregular',
                            y: 1
                        },
                        {
                            name: 'Ex. Suspensa',
                            y: 1
                        }
                    ]
                }
            ]
        };

        this.updateFlag = true;

        this.cdr.detectChanges();
        this.carregaCabecalho();

    }

    carregaCabecalho(): void {

        this.loadingContadores = true;

        this.calculaBadgeFiltros();


        const filtros: any = {};

        filtros.grupoEmpresarial_id = this.formFiltrosCabecalho.formGroup.value.grupoEmpresarial_id;
        filtros.visibilidade_id = this.formFiltrosCabecalho.formGroup.value.visibilidade_id;

        this.service.retornarCabecalho(filtros).subscribe((response) => {

            this.objectCountersValues.qtdRegulares = response.quantidade_regulares;
            this.objectCountersValues.qtdIrregulares = response.quantidade_irregulares;
            this.objectCountersValues.qtdSuspensa = response.quantidade_suspensas;

            this.objectCountersValues.porcRegulares =
                Math.round(this.getPercentage(this.objectCountersValues.qtdRegulares, response.quantidade_total));
            this.objectCountersValues.porcIrregulares =
                Math.round(this.getPercentage(this.objectCountersValues.qtdIrregulares, response.quantidade_total));
            this.objectCountersValues.porcSuspensas =
                Math.round(this.getPercentage(this.objectCountersValues.qtdSuspensa, response.quantidade_total));
            this.loadingContadores = false;

        });

    }

    getPercentage(campo1: number, campo2: number): number {
        const maior: any = campo1 > campo2 ? campo1 : campo2;
        const menor: any = campo1 < campo2 ? campo1 : campo2;
        return (menor / maior) * 100;
    }


    verPdf(urlRelatorioSitFiscal: any, relatorioId: string, cnpj: string): void {
        this.loadingsUltimoRelatorio[relatorioId] = true;


        this.service.getPDF(relatorioId, cnpj).subscribe((res) => {
            this.iframeUrl = '';
            if (res) {
                this.base64Selecionado = res;
                this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl('data:application/pdf;base64,' + res);
                this.isPdf = true;
                this.displayModal = true;
                this.loadingsUltimoRelatorio[relatorioId] = false;
            }
        }, (res) => {
            this.loadingsUltimoRelatorio[relatorioId] = false;
            this.toastService.error('Falha ao obter relatório.');
        });


        console.log(cnpj);
    }


    onClickDownloadPdf() {
        this.downloadPdf(this.base64Selecionado, 'RelatorioSituacaoFiscal');
    }

    downloadPdf(base64String = null, fileName = null) {

        if (base64String) {

            const source = `data:application/pdf;base64,${base64String}`;
            const link = document.createElement('a');
            link.href = source;
            link.download = fileName ? `${fileName}.pdf` : 'download.pff';
            link.click();

        } else {

            this.toastService.error('Arquivo não encontrado.');

        }

    }

    mesStr(mes: number): any {
        let mesStr;
        if (mes < 10) {
            mesStr = '0' + mes;
        } else {
            mesStr = mes.toString();
        }

        return mesStr;
    }

    formataDateBD(valor: string): string {

        const data = new Date(valor);
        let retorno = '';
        if (valor && typeof data.getMonth === 'function') {
            retorno = data.getFullYear() +
                '-' + this.mesStr(data.getMonth() + 1) +
                '-' + this.mesStr(data.getDate());
        } else {
            if (valor) {
                retorno = valor.toString();
            } else {
                retorno = valor;
            }

        }

        return retorno;
    }

    showModalHistorico(visible: boolean) {
        this.modalHistoricoVisible = visible;
    }

    getHistorico(cnpj): void {
        this.itemsHistorico = [];
        this.loadingHistorico = true;

        this.cnpjHistorico = cnpj;

        this.showModalHistorico(true);

        this.service.getHistorico(cnpj).subscribe((response: any) => {

            response.forEach((value, i) => {
                const indexStatus = value.situacao !== null ? value.situacao : 0;
                value.statusDescricao = this.arrayStatus[indexStatus];
                value.statusColor = this.arrayColorStatus[indexStatus];
            });

            this.itemsHistorico = response;

            this.loadingHistorico = false;
        });

    }

    getHistoricoParams(event): void {

        this.loadingHistorico = true;

        this.service.getHistorico(this.cnpjHistorico, event).subscribe((response: any) => {

            response.forEach((value, i) => {
                const indexStatus = value.situacao !== null ? value.situacao : 0;
                value.statusDescricao = this.arrayStatus[indexStatus];
                value.statusColor = this.arrayColorStatus[indexStatus];
            });

            this.itemsHistorico = response;

            this.loading = false;
        });

    }

    showConfirm(ecac: any): void {
        this.modalService.confirm({
            nzTitle: 'Deseja reprocessar o registro?',
            nzOkText: 'Reprocessar',
            nzCancelText: 'Cancelar',
            nzOnOk: () => this.reprocessarRegistro(ecac.empresa_id)
        });

    }

    reprocessarRegistro(empresaId: string) {

        this.processamentoFlag[empresaId] = true;

        this.service.reprocessarRegistro(empresaId).subscribe((retorno: any) => {

            this.toastService.success(retorno.message);

            this.processamentoFlag[empresaId] = false;

            this.queryTable(this.currentParams, this.currentSearch);

        }, (e) => {
            this.toastrService.error(e.error.message);

            this.processamentoFlag[empresaId] = false;

        });

    }

    desbloquear(empresaId: string) {

        this.loadingDesbloqueando[empresaId] = true;

        this.service.desbloquear(empresaId).subscribe((retorno: any) => {

            this.toastService.success(retorno.message);

            this.loadingDesbloqueando[empresaId] = false;

            this.queryTable(this.currentParams, this.currentSearch);

        }, (e) => {
            this.toastrService.error(e.error.message);

            this.loadingDesbloqueando[empresaId] = false;

        });
    }

    exportarPdfs() {

        this.loadings.exportarZipPdfs = true;
        const filtros: any = this.formFiltros.formGroup.value;

        this.currentParams.filter = [];
        Object.keys(filtros).forEach((key) => {
            if (filtros[key]) {
                this.currentParams.filter.push({key: String(key), value: filtros[key]});
            }
        });

        this.service.exportPdfs(this.currentParams, this.currentSearch).subscribe((res) => {

            const blob = new Blob([res], {type: 'application/zip; charset=utf-8'});

            fileSaver.saveAs(blob, 'ultimos_relatorios_situacao_fiscal_ecac');

            this.loadings.exportarZipPdfs = false;
        }, (res) => {
            this.toastService.error('Falha ao fazer download.');
            this.loadings.exportarZipPdfs = false;
        });

    }

    modalExportar(visible: boolean): void {
        this.formExportar.modalVisible = visible;
    }

    confirmaExportar(): void {

        if (this.formExportar.formGroup.valid) {

            this.loadings.exportar = true;
            const tipo = this.formExportar.formGroup.value.tipo;

            const filtros = this.formFiltros.formGroup.value;
            filtros.pagina = this.pagination.current_page;
            filtros.quantidade = this.pagination.per_page;

            this.service.exportExcel(
                filtros, tipo, this.currentSearch
            ).subscribe((res) => {

                if (this.formExportar.formGroup.value.tipo) {

                    const blob = new Blob([res], {type: 'text/json; charset=utf-8'});

                    const name = 'Situação Fiscal - ECAC';

                    fileSaver.saveAs(blob, name + '.' + this.formExportar.formGroup.value.tipo);

                    this.formExportar.modalVisible = false;
                }
                this.loadings.exportar = false;

            }, (res) => {
                this.loadings.exportar = false;
            });
        } else {
            this.loadings.exportar = false;
            Object.values(this.formExportar.formGroup.controls).forEach(control => {
                if (control.invalid) {
                    control.markAsDirty();
                    control.updateValueAndValidity({onlySelf: true});
                }
            });

        }
    }

    apagaFiltro(toDelete: string) {
        if (this.apagarFiltro && this.formFiltros.formGroup.get(toDelete).value) {
            this.apagarFiltro = false;
            this.formFiltros.formGroup.get(toDelete).setValue(null);
        }

        setTimeout(() => {
            this.apagarFiltro = true;
        }, 200);
    }

    abrirModal(formulario: FormStack): void {
        formulario.modalVisible = true;
    }

    fecharModal(formulario: FormStack): void {
        formulario.modalVisible = false;
    }

    limparFiltrosCabecalho(): void {

        this.formFiltrosCabecalho.formGroup.reset();
        this.filtrarCabecalho();

    }

    async getGruposEmpresariais() {

        return this.grupoEmpresarialService.listToSelect().subscribe((result) => {
            this.grupoEmpresarialOptions = result.map((option: any) => ({
                label: option.descricao,
                value: option.id
            }));
        });

    }

    async getVisibilidades() {

        return this.visibilidadeService.getVisibilidades().subscribe((result) => {
            this.visibilidadesOptions = result;
        });

    }

    filtrarCabecalho(): any {


        this.carregaCabecalho();
        this.filtrar();

        this.fecharModal(this.formFiltrosCabecalho);


    }

    exibirOcultar(campo: 'grupoEmpresarial_descricao' | 'visibilidade_descricao') {
        this.exibirColunas[campo] = !this.exibirColunas[campo];
        localStorage.setItem('exibirOcultar-situacaoFiscal', JSON.stringify(this.exibirColunas));
    }
}
