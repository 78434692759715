import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnInit,
    ViewChild
} from '@angular/core';
import {buildUrl, findComponentByUrl} from '../../../shared/components-helper';
import {Tab} from '@models/tab.model';
import {TabService} from '@services/tab.service';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import {TabHandlerInterface} from '../../../shared/interfaces/tab-handler.interface';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {CertidoesService} from './certidoes.service';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {Pagination} from '@models/pagination.model';
import {StickyHeaderDirective} from '@components/directive/directive';
import {NzI18nService, pt_BR} from 'ng-zorro-antd/i18n';
import {NzModalService} from 'ng-zorro-antd/modal';
import {environment} from '../../../../environments/environment';
import {registerLocaleData} from '@angular/common';
import br from '@angular/common/locales/pt';
import {differenceInCalendarDays} from 'date-fns';

registerLocaleData(br);

interface FormStack {
    modalVisible: boolean;
    formGroup: UntypedFormGroup;
}

@Component({
    selector: 'app-cadastros-certidoes',
    templateUrl: './certidoes.component.html',
    styleUrls: ['./certidoes.component.scss'],
})

export class CadastrosCertidoesComponent extends AbstractListTable<any> implements OnInit, AfterViewInit, TabHandlerInterface {

    dataFederal: any[] = [];
    totaisFederal = {
        empresasCadastradas: 0,
        cadastradas: 0,
        estimadas: 0,
    };
    dataEstadual: any[] = [];
    totaisEstaduais = {
        empresasCadastradas: 0,
        cadastradas: 0,
        estimadas: 0,
    };
    dataPrevidenciario: any[] = [];
    totaisPrevidenciario = {
        empresasCadastradas: 0,
        cadastradas: 0,
        estimadas: 0,
    };

    dataProcessamento: Date = null;

    currentParams: any = {
        oficiais: this.abstractService.NzTableQueryParams,
        calendar: this.abstractService.NzTableQueryParams,
    };
    currentSearch: any = {
        oficiais: '',
        calendar: '',
    };
    pagination: any = {
        oficiais: Pagination,
        calendar: Pagination,
    };

    offsetTop = 15;
    target: any;

    loadingCardTabsOne = false;
    loadingSugeridasMassa = false;
    tabAtivaOne = 0;
    statusOne = false;

    loadingCardTabsTwo = true;
    tabAtivaTwo = 0;
    statusTwo = false;

    arrayTableVencimentoOficial: any = [];
    arrayTableObrigacoes = [];

    formCertidaoVencimento: FormStack;
    formEditarDatas: FormStack;
    formEditarNomeCertManual: FormStack;
    formCalendar: FormStack;
    formVencimento: FormStack;
    formResponsavel: FormStack;

    formCadastrar: FormStack;
    formNovaCertidao: FormStack;
    formCadastrarEmMassa: FormStack;

    heightTableObrigacoes = 0;
    heightTableTabsTwo = 0;

    showTabsTwo = false;
    certidaoSelecionadaLabel = '';
    certidaoSelecionadaId = '';
    certidaoSelecionadaMunicipio = '';
    empresaISelecionadaId = '';
    arraySelectEmpresa = [];
    arraySelectIes = [];
    arraySelectIis = [];
    arraySelectIms = [];

    optionsEmpresas = [];
    optionsCertidoes = [];

    @ViewChild('cardWidth') cardWidth: any;

    cardWidthValue;

    loadingAdicionarEmpresas = false;
    modalEmpresaLabel = 'Adicionar Empresa';
    modalEmpresaEditar = false;

    empresaNomeEditar = '';
    empresaIeEditar = '';
    empresaImEditar = '';
    empresaIiEditar = '';

    filtroObrigacoes = {
        orgao: '',
        fonte: ''
    };

    listaNaoSelecionadas: any[] = [];
    listaSimSelecionadas: any[] = [];
    selectedNaoSeleciodo: any = [];
    selectedSimSeleciodo: any = [];

    loadings = {
        cadastrarCertidaoUnica: false,
        novaCertidao: false,
        editarCertidao: false,
        certidaoSelecionada: [],
        editarDescricaoManual: false,
        editarCertidaoHabilitado: false,
        comboEmpresas: false
    };

    private vencimentoOficialAnchor;

    mostrarCndsSugeridas = false;

    estadual = [];
    mostrarSemIEs = false;
    previdenciario = [];
    mostrarSemEmpresa = false;

    @ViewChild('column1') column1: ElementRef;
    column1Width = 0;

    @ViewChild('column2') column2: ElementRef;
    column2Width = 0;

    @ViewChild('column3') column3: ElementRef;
    column3Width = 0;

    @ViewChild('column4') column4: ElementRef;
    column4Width = 0;

    @ViewChild('column5') column5: ElementRef;
    column5Width = 0;

    @ViewChild('column6') column6: ElementRef;
    column6Width = 0;

    @ViewChild('column7') column7: ElementRef;
    column7Width = 0;

    expandSetPrevidenciario = new Set<number>();

    @ViewChild('vencimentoOficialAnchor') set content(content: ElementRef) {
        if (content) {
            this.vencimentoOficialAnchor = content;
        }
    }

    private cardTabsTwo;

    @ViewChild('cardTabsTwo') set contentCard(content: ElementRef) {
        if (content) {
            this.cardTabsTwo = content;
        }
    }

    today = new Date();

    loadingCndsCadastrarSugeridas = false;

    radioValueOrgao = 'Federal';
    comboUFs: any = [];
    comboTodosMunicipios: any = [];
    apenasUnidadesMatriz = '0';
    qtdFiltrosAtivos = 0;
    formFiltrar: FormStack;

    exibirIE = false;
    exibirII = false;
    exibirIM = false;

    clickDetail = false;

    orgaos = {
        M: 'Municipal',
        E: 'Estadual',
        F: 'Federal',
        I: 'Imobiliária'
    };

    certidaoSelecionadaObj: { id: string, descricao: string, habilitado: boolean };

    constructor(
        private fb: UntypedFormBuilder,
        private CadastroCertidoesService: CertidoesService,
        private toastService: ToastrService,
        private tabService: TabService,
        private cdr: ChangeDetectorRef,
        private i18n: NzI18nService,
        private modalService: NzModalService
    ) {

        super(CadastroCertidoesService, null, toastService);

        this.i18n.setLocale(pt_BR);

        this.formCertidaoVencimento = {
            modalVisible: false,
            formGroup: this.fb.group({
                empresa_id: [null, Validators.required],
                certidao_id: [null, Validators.required],
                dataInicio: [null, Validators.required],
                dataFim: [null, null],
                id: [null, Validators.required],
                ie: [null],
                empresaInscricaoMunicipal_id: [null],
                empresaInscricaoImobiliaria_id: [null],
            })
        };

        this.formEditarDatas = {
            modalVisible: false,
            formGroup: this.fb.group({
                dataInicio: [null, null],
                dataFim: [null, null],
            })
        };

        this.formEditarNomeCertManual = {
            modalVisible: false,
            formGroup: this.fb.group({
                certidao_id: [null, Validators.required],
                descricao: [null, Validators.required]
            })
        };


        this.formCadastrar = {
            modalVisible: false,
            formGroup: this.fb.group({
                empresa_id: [null, Validators.required],
                certidao_id: [null, Validators.required],
                dataInicio: [null, Validators.required],
                dataFim: [null, null],
            })
        };

        this.formNovaCertidao = {
            modalVisible: false,
            formGroup: this.fb.group({
                descricao: [null, Validators.required],
                orgao: [null, Validators.required],
                uf: [null, null],
                municipioCodigo: [null, null],
                somenteMatriz: [null, null],
            })
        };

        this.formCadastrarEmMassa = {
            modalVisible: false,
            formGroup: this.fb.group({
                empresa_id: [null, null],
                certidao_id: [null, null],
            })
        };

        this.formCalendar = {
            modalVisible: false,
            formGroup: this.fb.group({
                empresa: [null, Validators.required],
                equipe_id: [null, Validators.required],
                responsavel_id: [null, Validators.required],
            })
        };

        this.formVencimento = {
            modalVisible: false,
            formGroup: this.fb.group({})
        };

        this.formResponsavel = {
            modalVisible: false,
            formGroup: this.fb.group({
                responsavel_id: [null, Validators.required]
            })
        };

        this.formFiltrar = {
            modalVisible: false,
            formGroup: this.fb.group({
                inativo: [null, null],
                desativado: [null, null],
            })
        };


    }

    changeSelectCnds(empresaId) {

        if (empresaId && empresaId !== 'todas') {

            this.mostrarCndsSugeridas = true;

            this.loadingCndsCadastrarSugeridas = true;

            this.CadastroCertidoesService.retornarSelectsObrigacoes(empresaId).subscribe((retorno: any) => {

                if (retorno.length > 0) {

                    this.listaNaoSelecionadas = [];

                    retorno.forEach(value => {
                        if (value.certidao_id.length < 36) {
                            this.listaNaoSelecionadas.push(value);
                        }
                    });
                    this.loadingCndsCadastrarSugeridas = false;

                } else {

                    this.mostrarCndsSugeridas = false;
                    this.toastService.error('Empresa sem certidões sugeridas');

                }

            });

            if (this.exibirIM) {
                this.getComboIm(empresaId);
                this.formCertidaoVencimento.formGroup.get('empresaInscricaoMunicipal_id').setValue(null);
            }

            if (this.exibirIE) {
                this.getComboIe(empresaId);
                this.formCertidaoVencimento.formGroup.get('ie').setValue(null);
            }

            if (this.exibirII) {
                this.getComboIi(empresaId);
                this.formCertidaoVencimento.formGroup.get('empresaInscricaoImobiliaria_id').setValue(null);
            }

        } else {

            this.mostrarCndsSugeridas = false;

        }

    }

    ngOnInit(): void {

        this.CadastroCertidoesService.retornarSelectsEmpresas(
        ).subscribe((retorno: any) => {
            this.arraySelectEmpresa = retorno;
        });

        this.CadastroCertidoesService.retornarOptionEmpresas().subscribe((retorno: any) => {
            this.optionsEmpresas = [];
            this.optionsEmpresas = retorno;
        });

        this.retornarOptionCertidoes().then();

        this.CadastroCertidoesService.retornaEstados().subscribe((retorno: any) => {

            retorno.estados.forEach((value) => {
                value.label = value.uf + ' - ' + value.nome;
                value.key = value.uf;
            });
            this.comboUFs = retorno.estados;

        });

        this.CadastroCertidoesService.retornaComboMunicipios().subscribe((retorno: any) => {
            retorno.cidades.forEach((value) => {
                value.key = value.ibgeCodigo;
                value.label = value.ibgeCodigo + ' - ' + value.nome;
            });
            this.comboTodosMunicipios = [];
            this.comboTodosMunicipios = retorno.cidades;
        });

    }

    getComboIe(empresaId: string) {
        this.CadastroCertidoesService.retornarSelectsIes(this.certidaoSelecionadaId, empresaId).subscribe((retorno: any) => {
            this.arraySelectIes = retorno;
        });
    }

    getComboIi(empresaId: string) {
        this.CadastroCertidoesService.retornarSelectsIis(this.certidaoSelecionadaId, empresaId).subscribe((retorno: any) => {
            this.arraySelectIis = retorno;
        });

    }

    async retornarOptionCertidoes() {
        this.CadastroCertidoesService.retornarOptionCertidoes().subscribe((retorno: any) => {
            this.optionsCertidoes = [];
            this.optionsCertidoes = retorno;
        });
    }

    getComboIm(empresaId: string) {
        this.CadastroCertidoesService.retornarSelectsIms(this.certidaoSelecionadaMunicipio, empresaId).subscribe((retorno: any) => {
            this.arraySelectIms = retorno;
        });
    }

    ngAfterViewInit() {

        this.target = StickyHeaderDirective.target;

        this.retornaContadoresOrgao();

        this.heightTableObrigacoes = this.target.offsetHeight - (this.target.offsetHeight / 100 * 20);

        this.heightTableTabsTwo = this.target.offsetHeight - (this.target.offsetHeight / 100 * 23);

        this.cardWidthValue = this.cardWidth.nativeElement.offsetWidth;

        this.cdr.detectChanges();

    }

    modalCertidaoVencimento(
        visible,
        dataInicio: string = null,
        dataFim: string = null,
        empresaId: string = null,
        empresaNome: string = null,
        certidaoId: string = null,
        id: string = null,
        acesso: string = null,
        ie: string = null,
        // tslint:disable-next-line:variable-name
        empresaInscricaoImobiliaria_id: string = null,
        ii: string = null,
        im: string = null
    ) {

        if (this.tabAtivaTwo === 0) {
            this.formCertidaoVencimento.modalVisible = visible;
        }

        this.formCertidaoVencimento.formGroup.reset();

        if (empresaId && dataInicio) {

            this.modalEmpresaEditar = true;
            this.modalEmpresaLabel = 'Editar Empresa';

            if (this.tabAtivaTwo === 0) {

                if (empresaId && empresaNome) {
                    this.empresaNomeEditar = empresaNome;
                    this.formCertidaoVencimento.formGroup.get('empresa_id').setValue(empresaId);
                }

                if (ie) {
                    this.empresaIeEditar = ie;
                    this.formCertidaoVencimento.formGroup.get('ie').setValue(ie);
                }

                if (im) {
                    this.empresaImEditar = im;
                    this.formCertidaoVencimento.formGroup.get('empresaInscricaoMunicipal_id').setValue(im);
                }

                if (ii) {
                    this.empresaIiEditar = ii;
                    this.formCertidaoVencimento.formGroup.get('empresaInscricaoImobiliaria_id').setValue(empresaInscricaoImobiliaria_id);
                }

                if (dataInicio) {
                    const diaInicio = dataInicio.split('-');

                    this.formCertidaoVencimento.formGroup.get('dataInicio').setValue(new Date(dataInicio).setDate(Number(diaInicio[2])));
                }

                if (dataFim) {

                    const diaFim = dataFim.split('-');

                    this.formCertidaoVencimento.formGroup.get('dataFim').setValue(new Date(dataFim).setDate(Number(diaFim[2])));
                }

                if (certidaoId) {
                    this.formCertidaoVencimento.formGroup.get('certidao_id').setValue(certidaoId);
                }

                if (id) {
                    this.formCertidaoVencimento.formGroup.get('id').setValue(id);
                }

                /*if (acesso) {
                    this.formCertidaoVencimento.formGroup.get('acesso').setValue(acesso);
                }*/

            }

        } else {

            this.modalEmpresaEditar = false;
            this.modalEmpresaLabel = 'Adicionar Empresa';

        }

    }

    modalEditarCndMassa(visible) {
        this.formEditarDatas.modalVisible = visible;
    }

    clickEvent(card: string) {

        switch (card) {

            case 'one':
                this.statusOne = !this.statusOne;
                break;

            case 'two':
                this.statusTwo = !this.statusTwo;
                break;

        }

    }

    changeTabs(event: any, card: string) {

        this.checkedItems = new Set<string>();

        switch (card) {

            case 'one':

                this.tabAtivaOne = event.index;

                if (this.tabAtivaOne === 0) {
                    this.showTabsTwo = false;
                }

                if (this.tabAtivaOne === 1 && this.arrayTableObrigacoes.length === 0) {
                    this.loadingCardTabsOne = true;

                    if (!this.clickDetail) {
                        this.retornaCertidoes();
                    }
                }

                break;

            case 'two':

                this.tabAtivaTwo = event.index;
                this.loadingCardTabsTwo = true;

                break;

        }

    }

    onAllChecked(checked: boolean): void {

        if (this.tabAtivaTwo === 0) {
            this.arrayTableVencimentoOficial.filter(({disabled}) => !disabled).forEach(({id}) => this.updateCheckedSet(id, checked));
        }

    }

    openTab(componentName: string, queryParams?: string, data?: {}) {

        const component = findComponentByUrl(componentName);
        const url = buildUrl(component, queryParams);
        const newTab = new Tab(component.name, component.title, url, component.urlType, data);

        this.tabService.addTab(newTab);

    }

    retornaCertidoes() {

        this.CadastroCertidoesService.retornaCertidoes(this.filtroObrigacoes).subscribe((retorno: any) => {
            this.arrayTableObrigacoes = [];

            this.arrayTableObrigacoes = retorno;

            const res = this.arrayTableObrigacoes.find(o => o.id === this.certidaoSelecionadaId);

            this.certidaoSelecionadaMunicipio = res?.cidadeCodigo;

            if (this.tabAtivaOne === 1) {
                this.loadingCardTabsOne = false;
            }

        });

    }

    retornaContadoresOrgao() {

        this.loadingCardTabsOne = true;

        this.CadastroCertidoesService.retornaContadoresOrgao().subscribe((response: any) => {

            const retorno = response.sumario;

            this.dataProcessamento = response.dataProcessamento;

            // Dados Orgão Federal
            const federal = [];
            this.totaisFederal.empresasCadastradas = 0;
            this.totaisFederal.cadastradas = 0;
            this.totaisFederal.estimadas = 0;

            Object.entries(retorno.Federal).forEach(([key, value]) => {

                const obj: any = value;

                if (obj.tipo !== 'Total') {

                    obj.label = obj.tipo;
                    federal.push(obj);

                    this.totaisFederal.empresasCadastradas += obj.cadastros.empresas_cadastradas;
                    this.totaisFederal.cadastradas += obj.certidoes.cadastradas;
                    this.totaisFederal.estimadas += obj.certidoes.estimadas;

                }

            });

            this.dataFederal = federal;

            // Dados Orgão Estadual
            this.totaisEstaduais.empresasCadastradas = 0;
            this.totaisEstaduais.cadastradas = 0;
            this.totaisEstaduais.estimadas = 0;
            const estadual = [];

            Object.entries(retorno.Estadual).forEach(([key, value]) => {
                const obj: any = value;

                if (obj.tipo !== 'Total') {

                    obj.label = key;
                    estadual.push(obj);

                    this.totaisEstaduais.empresasCadastradas += obj.cadastros.empresas_cadastradas;
                    this.totaisEstaduais.cadastradas += obj.certidoes.cadastradas;
                    this.totaisEstaduais.estimadas += obj.certidoes.estimadas;

                }
            });

            this.estadual = estadual;

            if (this.mostrarSemIEs) {
                this.dataEstadual = estadual;
            } else {
                this.dataEstadual = [];
                estadual.forEach(item => {
                    if (item.cadastros.empresas_cadastradas > 0) {
                        this.dataEstadual.push(item);
                    }
                });
            }


            // Dados Orgão Previdenciáios
            this.totaisPrevidenciario.empresasCadastradas = 0;
            this.totaisPrevidenciario.cadastradas = 0;
            this.totaisPrevidenciario.estimadas = 0;
            const previdenciario = [];

            Object.entries(retorno.Municipal).forEach(([key, value]) => {

                const obj: any = value;

                if (obj.tipo !== 'Total') {
                    obj.label = obj.tipo;
                    previdenciario.push(obj);
                    this.totaisPrevidenciario.empresasCadastradas += obj.cadastros.empresas_cadastradas;
                    this.totaisPrevidenciario.cadastradas += obj.certidoes.cadastradas;
                    this.totaisPrevidenciario.estimadas += obj.certidoes.estimadas;

                }

            });

            this.dataPrevidenciario = previdenciario;

            if (this.mostrarSemEmpresa) {

                this.montaPrevidenciaria(previdenciario);
            } else {
                const prev = [];

                previdenciario.forEach(item => {
                    if (item.cadastros.empresas_cadastradas > 0) {
                        prev.push(item);
                    }
                });

                this.montaPrevidenciaria(prev);
            }

            this.loadingCardTabsOne = false;

            this.toggleParamsCardTabs(true);

        });

    }

    montaPrevidenciaria(previdenciario: any[]) {

        this.previdenciario = [];
        const ufs = [];

        previdenciario.forEach(item => {
            if (!ufs.includes(item.uf)) {
                ufs.push(item.uf);
            }
        });

        ufs.forEach(uf => {
            this.previdenciario.push({
                uf,
                cnds: [],
                certidoes_sugeridas: 0,
                empresas_cadastradas: 0,
                estimadas: 0,
                cadastradas: 0
            });
        });

        this.previdenciario.forEach(pre => {

            previdenciario.forEach(cndM => {

                if (pre.uf === cndM.uf) {
                    pre.cnds.push(cndM);
                    pre.certidoes_sugeridas += cndM.cadastros.certidoes_sugeridas;
                    pre.empresas_cadastradas += cndM.cadastros.empresas_cadastradas;
                    pre.estimadas += cndM.certidoes.estimadas;
                    pre.cadastradas += cndM.certidoes.cadastradas;
                }
            });
        });
    }

    retornaAnosObrigacoes(certidaoId, certidaoDescricao) {

        this.certidaoSelecionadaId = certidaoId;
        this.certidaoSelecionadaLabel = certidaoDescricao;
        this.formEditarNomeCertManual.formGroup.get('descricao').setValue(certidaoDescricao);
        this.formEditarNomeCertManual.formGroup.get('certidao_id').setValue(certidaoId);
        this.loadings.certidaoSelecionada.push(certidaoId);

        if (this.showTabsTwo) {

            this.retornaEmpresasCertidaoOficiais(this.currentParams.oficiais, this.currentSearch.oficiais, true);

        }

        this.showTabsTwo = true;

        this.scrollContainer();

    }

    scrollContainer(px = 0) {

        setTimeout(() => {
            this.target.scrollTo({
                left: 0,
                top: this.cardTabsTwo.nativeElement.offsetTop + px,
                behavior: 'smooth'
            });

            this.loadings.certidaoSelecionada = [];
        }, 800);

    }

    retornaEmpresasCertidaoOficiais(params: NzTableQueryParams, search: string = null, scroll = false) {

        this.retornaCertidoes();

        this.loadingCardTabsTwo = true;

        this.currentParams.oficiais = params;

        this.currentSearch.oficiais = search;

        this.checkedItems = new Set<string>();

        const url = environment.apiUrl + '/certidoes/controleCertidoesEmpresa/' + this.certidaoSelecionadaId;

        this.calculaBadgeFiltros();

        this.abstractService.listToTable(params, search, url).subscribe((response) => {

            this.arrayTableVencimentoOficial = response.data;

            this.pagination.oficiais = new Pagination(
                response?.per_page || 50,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 50);

            this.loadingCardTabsTwo = false;
            this.loadings.certidaoSelecionada = [];

            this.checked = false;

            this.resetCheckedSet();

            if (scroll) {
                this.scrollContainer(120);
            }

        }, error => {
            this.toastService.error(error.error.messafe);
            this.loadings.certidaoSelecionada = [];
        });

    }

    calculaBadgeFiltros(): void {
        this.qtdFiltrosAtivos = 0;
        if (typeof this.formFiltrar !== 'undefined') {
            for (const [chave, valor] of Object.entries(this.formFiltrar.formGroup.value)) {
                if (valor && chave !== 'desativado') {
                    this.qtdFiltrosAtivos += 1;
                }
            }
        }
    }

    editarCertidao(certidaoId, descricao, orgao, habilitado: boolean) {

        this.loadings.comboEmpresas = true;
        this.CadastroCertidoesService.retornarSelectsEmpresas(certidaoId).subscribe((retorno: any) => {
            this.arraySelectEmpresa = retorno;
            this.loadings.comboEmpresas = false;
        });

        if (certidaoId) {
            this.formCertidaoVencimento.formGroup.get('certidao_id').setValue(certidaoId);
            this.exibirIE = orgao === 'E';
            this.exibirII = orgao === 'I';
            this.exibirIM = orgao === 'M';
        }

        this.certidaoSelecionadaObj = {id: certidaoId, descricao, habilitado};

        this.retornaAnosObrigacoes(certidaoId, descricao);

    }

    editarDescricaoCertidaoManual() {

        this.loadings.editarDescricaoManual = true;

        this.CadastroCertidoesService.editarCertidaoDescricaoManual(this.formEditarNomeCertManual.formGroup.value)
            .subscribe({
                next: (res) => {

                    this.loadings.editarDescricaoManual = false;

                    this.modalEditarDescricao(false);

                    this.retornaAnosObrigacoes(
                        this.certidaoSelecionadaId,
                        this.formEditarNomeCertManual.formGroup.get('descricao').value
                    );

                    this.toastService.success('Descrição atualizada com sucesso.');
                },
                error: (err) => {

                    this.loadings.editarDescricaoManual = false;

                    this.toastService.error(err.error.message);
                }
            });
    }

    excluirCertidao(certidaoId) {

        this.CadastroCertidoesService.excluirCertidaoManual(certidaoId).subscribe((retorno: any) => {
            this.loadingCardTabsOne = true;
            if (retorno.error) {
                this.toastService.error(retorno.message);
                this.loadingCardTabsOne = false;
            } else {
                this.toastService.success(retorno.message);
                this.retornaCertidoes();
            }
        }, err => {
            this.toastService.error(err.message);
        });
    }

    insertEmpresas(): void {

        if (this.tabAtivaTwo === 0) {

            if (this.validaFormVencimentos()) {
                this.loadings.novaCertidao = true;

                this.formCertidaoVencimento.formGroup.get('certidao_id').setValue(this.certidaoSelecionadaId);

                const dados = this.formCertidaoVencimento.formGroup.value;

                this.CadastroCertidoesService.insertEmpresaVencimentos(dados).subscribe((response: any) => {

                    this.toastrService.success('Certidão cadastrada com sucesso!');

                    this.formCertidaoVencimento.modalVisible = false;

                    this.formCertidaoVencimento.formGroup.reset();

                    this.loadings.novaCertidao = false;

                    this.modalEmpresaEditar = false;

                    this.retornaEmpresasCertidaoOficiais(this.currentParams.oficiais, this.currentSearch.oficiais, false);

                }, (response) => {
                    this.loadings.novaCertidao = false;

                    this.toastrService.error(response.error.message);

                    this.loadingAdicionarEmpresas = false;

                });

            }

        }

    }

    showConfirm(id: any): void {

        this.modalService.confirm({
            nzTitle: 'Atenção: Essa ação exclui todas as Certidões já geradas para a(s) empresa(s) selecionada(s).\n' +
                'Essa ação é irreversível. Deseja prosseguir?',
            nzOkText: 'Remover',
            nzCancelText: 'Cancelar',
            nzOnOk: () => this.removeEmpresas(id)
        });

    }

    showConfirmAdicionarCertidoes(): void {

        this.modalService.confirm({
            nzTitle: 'Deseja adicionar as Certidões Sugeridas?',
            nzOkText: 'Adicionar',
            nzCancelText: 'Cancelar',
            nzOnOk: () => this.adicionarCertidoesSugeridas()
        });

    }

    showConfirmRemoverCND(id: any): void {

        this.modalService.confirm({
            nzTitle: 'Deseja mesmo remover essa CND?',
            nzOkText: 'Remover',
            nzCancelText: 'Cancelar',
            nzOnOk: () => this.excluirCertidao(id)
        });

    }

    removeEmpresas(id) {

        this.loadingCardTabsTwo = true;

        const ids = id ? [id] : Array.from(this.checkedItems);

        if (this.tabAtivaTwo === 0) {

            this.CadastroCertidoesService.removeEmpresasOficiais(ids).subscribe((response: any) => {
                this.toastrService.success(response.message);
                if (response.erros) {
                    this.toastrService.error('Não foi possível remover algumas certidões' +
                        ' pois existem arquivos de CND gerados. Contatar o suporte!');

                }

                this.retornaEmpresasCertidaoOficiais(this.currentParams.oficiais, this.currentSearch.oficiais, false);
                this.retornaCertidoes();

            }, (response) => {
                this.toastrService.error(response.error.message);

                this.loadingCardTabsTwo = false;
                this.loadingAdicionarEmpresas = false;

            });

        }

    }

    adicionarCertidoesSugeridas() {

        this.loadingAdicionarEmpresas = true;

        this.CadastroCertidoesService
            .adicionarCertidoesSugeridas(this.certidaoSelecionadaId).subscribe((response: any) => {

            this.loadingAdicionarEmpresas = false;

            this.toastrService.success('Empresas adicionadas com sucesso!');

            this.retornaEmpresasCertidaoOficiais(this.currentParams.oficiais, this.currentSearch.oficiais, false);

            this.retornaCertidoes();

        }, (response) => {

            this.toastrService.error(response.error.message);

            this.loadingAdicionarEmpresas = false;

        });

    }

    editarVencimentos() {

        if (this.tabAtivaTwo === 0) {

            this.loadings.editarCertidao = true;

            const dados = this.formCertidaoVencimento.formGroup.value;

            this.CadastroCertidoesService.editarEmpresaVencimentos(dados).subscribe((response) => {

                    this.toastrService.success(`Empresa editada com sucesso!`);

                    this.formCertidaoVencimento.modalVisible = false;

                    this.loadings.editarCertidao = false;

                    this.retornaEmpresasCertidaoOficiais(this.currentParams.oficiais, this.currentSearch.oficiais, false);

                },
                (response) => {
                    this.loadings.editarCertidao = false;

                    this.toastrService.error(response.error.message);

                }
            );

        }

    }

    validaFormVencimentos() {

        let error = false;

        if (!this.formCertidaoVencimento.formGroup.value.empresa_id && !this.modalEmpresaEditar) {

            this.toastrService.error('Empresa obrigatória');

            error = true;

        }

        if (!this.formCertidaoVencimento.formGroup.value.dataInicio && !this.modalEmpresaEditar) {

            this.toastrService.error('Data Início obrigatória');
            error = true;

        }

        return !error;

    }

    editarVencimentosMassa() {

        if (this.tabAtivaTwo === 0) {

            // this.validaVencimentosMassa()
            if (true) {

                const dados = this.formEditarDatas.formGroup.value;

                if (this.checkedItems.size > 0) {
                    dados.ids = Array.from(this.checkedItems);
                }

                this.CadastroCertidoesService.editarEmpresasVencimentosOficiais(dados).subscribe(() => {

                        this.toastrService.success(`Certidões Editadas com Sucesso.`);

                        this.formEditarDatas.modalVisible = false;

                        this.formEditarDatas.formGroup.reset();

                        this.retornaEmpresasCertidaoOficiais(this.currentParams.oficiais, this.currentSearch.oficiais, false);

                    },
                    (response) => {

                        this.toastrService.error(response.error.message);

                    }
                );

            }

        }

    }

    reloadCardTwo() {

        if (this.tabAtivaTwo === 0) {
            this.retornaEmpresasCertidaoOficiais(this.currentParams.oficiais, this.currentSearch.oficiais);
        }

    }

    filtrarOrgao(orgao: string, fonte: string = null): void {

        this.filtroObrigacoes.orgao = orgao;

        this.filtroObrigacoes.fonte = fonte;

        this.tabAtivaOne = 1;

        this.showTabsTwo = false;

        this.loadingCardTabsOne = true;

        this.clickDetail = true;

        this.retornaCertidoes();

    }

    limparFiltros(): void {
        this.filtrarOrgao(null, null);
    }

    modalCadastrarEmMassa(visible: boolean): void {

        if (visible) {
            this.formCadastrarEmMassa.formGroup.get('empresa_id').setValue('todas');
            this.formCadastrarEmMassa.formGroup.get('certidao_id').setValue('todas');
        }

        this.formCadastrarEmMassa.modalVisible = visible;

    }

    modalCadastrar(visible: boolean): void {
        this.formCadastrar.modalVisible = visible;
    }

    modalEditarDescricao(visible: boolean): void {
        this.formEditarNomeCertManual.modalVisible = visible;
    }

    cadastrarSugeridasMassa(): void {

        const dados = this.formCadastrarEmMassa.formGroup.value;

        if (dados.empresa_id !== 'todas' && this.listaSimSelecionadas.length <= 0) {
            this.toastrService.error('Ao menos uma Certidão deve ser selecionada.');
            return;
        }

        if (this.listaSimSelecionadas.length > 0) {

            dados.certidao_id = [];

            this.listaSimSelecionadas.forEach(value => {
                dados.certidao_id.push(value.certidao_id);
            });

        }

        this.loadingSugeridasMassa = true;
        this.modalCadastrarEmMassa(false);

        this.CadastroCertidoesService.cadastrarSugeridasMassa(dados).subscribe((response) => {
            this.toastrService.success('Certidões Cadastradas com Sucesso.');
            this.loadingSugeridasMassa = false;
        }, (res) => {
            this.toastrService.error(res.error.message);
            this.loadingSugeridasMassa = false;
        });

    }

    clickNaoSelecionado(id: any): void {
        if (this.selectedNaoSeleciodo.includes(id)) {
            this.selectedNaoSeleciodo.splice(this.selectedNaoSeleciodo.indexOf(id), 1);
        } else {
            this.selectedNaoSeleciodo.push(id);
        }
    }

    clickSimSelecionado(id: any): void {
        if (this.selectedSimSeleciodo.includes(id)) {
            this.selectedSimSeleciodo.splice(this.selectedSimSeleciodo.indexOf(id), 1);
        } else {
            this.selectedSimSeleciodo.push(id);
        }

    }

    acaoDobleList(tipoAcao: string): void {

        switch (tipoAcao) {
            case 'right': {
                this.selectedNaoSeleciodo.forEach((selecionado) => {
                    this.listaNaoSelecionadas.forEach((certidao, index) => {
                        if (certidao.certidao_id === selecionado) {
                            this.listaSimSelecionadas.push(this.listaNaoSelecionadas[index]);
                            this.listaNaoSelecionadas.splice(index, 1);
                        }
                    });
                });
                break;
            }
            case 'double-right': {
                this.listaNaoSelecionadas.forEach((value, index) => {
                    this.listaSimSelecionadas.push(value);
                });
                this.listaNaoSelecionadas = [];
                break;
            }
            case 'left': {
                this.selectedSimSeleciodo.forEach((selecionado) => {
                    this.listaSimSelecionadas.forEach((certidao, index) => {
                        if (certidao.certidao_id === selecionado) {
                            this.listaNaoSelecionadas.push(this.listaSimSelecionadas[index]);
                            this.listaSimSelecionadas.splice(index, 1);
                        }
                    });
                });
                break;
            }
            case 'double-left': {
                this.listaSimSelecionadas.forEach((value, index) => {
                    this.listaNaoSelecionadas.push(value);
                });
                this.listaSimSelecionadas = [];
                break;
            }
        }


        this.listaSimSelecionadas.sort((a, b) => (a.id > b.id) ? 1 : -1);
        this.listaNaoSelecionadas.sort((a, b) => (a.id > b.id) ? 1 : -1);

        this.selectedNaoSeleciodo = [];
        this.selectedSimSeleciodo = [];
    }

    disabledDate = (current: Date): boolean => {
        return differenceInCalendarDays(current, this.today) < 0;
    }


    cadastrarCertidaoEmpresa(): void {


        if (this.formCadastrar.formGroup.valid) {

            const dados = this.formCadastrar.formGroup.value;

            dados.tipoEmissao = 'M';

            this.loadings.cadastrarCertidaoUnica = true;

            this.CadastroCertidoesService.cadastrarUnica(dados).subscribe((response) => {
                this.loadings.cadastrarCertidaoUnica = false;
                this.modalCadastrar(false);
                this.formCadastrar.formGroup.reset();
                this.toastrService.success('Certidão Cadastrada com Sucesso.');
                this.retornaContadoresOrgao();

            }, (res) => {
                this.toastrService.error(res.error.message);
                this.loadings.cadastrarCertidaoUnica = false;
            });
        } else {
            Object.values(this.formCadastrar.formGroup.controls).forEach(control => {
                if (control.invalid) {
                    control.markAsDirty();
                    control.updateValueAndValidity({onlySelf: true});
                }
            });
        }
    }

    cadastrarNovaCertidao(): void {

        if (this.formNovaCertidao.formGroup.valid) {

            const dados = this.formNovaCertidao.formGroup.value;

            if ((dados.orgao === 'Municipal' || dados.orgao === 'Imobiliaria') && !dados.municipioCodigo) {
                this.toastService.error('Código do município obrigatório');
            } else if (dados.orgao === 'Estadual' && !dados.uf) {
                this.toastService.error('UF obrigatório');
            } else {


                this.loadings.novaCertidao = true;

                this.CadastroCertidoesService.novaCertidao(dados).subscribe((response) => {

                    this.modalNovaCertidao(false);
                    this.retornarOptionCertidoes().then(() => {
                        this.formCadastrar.formGroup.get('certidao_id').setValue(response.dados.id);
                    });
                    this.toastrService.success('Certidão Cadastrada com Sucesso.');
                    this.loadings.novaCertidao = false;
                    this.formNovaCertidao.formGroup.reset();

                }, (res) => {
                    this.toastrService.error(res.error.message);
                    this.loadings.novaCertidao = false;
                });
            }
        } else {
            Object.values(this.formNovaCertidao.formGroup.controls).forEach(control => {
                if (control.invalid) {
                    control.markAsDirty();
                    control.updateValueAndValidity({onlySelf: true});
                }
            });
        }
    }

    modalNovaCertidao(visible: boolean): void {
        this.formNovaCertidao.modalVisible = visible;
    }

    toggleOrgao(event) {
        this.radioValueOrgao = event;
    }

    modalFiltrar(visible: boolean) {
        this.formFiltrar.modalVisible = visible;
    }

    confirmaFiltrar(): void {

        this.currentParams.oficiais.filter = [];

        this.currentParams.oficiais.pageIndex = 1;

        this.formFiltrar.formGroup.get('desativado').setValue(this.formFiltrar.formGroup.get('inativo').value);

        const filtros = this.formFiltrar.formGroup.value;

        for (const [chave, valor] of Object.entries(filtros)) {
            if (valor) {
                this.currentParams.oficiais.filter.push({key: chave, value: valor});
            }
        }

        this.retornaEmpresasCertidaoOficiais(this.currentParams.oficiais, this.currentSearch.oficiais);

        this.modalFiltrar(false);

    }

    exibirTudoIEs() {
        this.mostrarSemIEs = !this.mostrarSemIEs;

        if (this.mostrarSemIEs) {
            this.dataEstadual = this.estadual;
        } else {
            this.dataEstadual = [];
            this.estadual.forEach(item => {
                if (item.cadastros.empresas_cadastradas > 0) {
                    this.dataEstadual.push(item);
                }
            });
        }
    }

    exibirTudoEmpresas() {
        this.mostrarSemEmpresa = !this.mostrarSemEmpresa;

        if (this.mostrarSemEmpresa) {

            this.montaPrevidenciaria(this.dataPrevidenciario);
        } else {
            const prev: any = [];

            this.dataPrevidenciario.forEach(item => {
                if (item.cadastros.empresas_cadastradas > 0) {
                    prev.push(item);
                }
            });

            this.montaPrevidenciaria(prev);
        }
    }

    onExpandChangePrevidenciario(id: number, checked: boolean): void {

        if (checked) {
            this.expandSetPrevidenciario.add(id);
        } else {
            this.expandSetPrevidenciario.delete(id);
        }

        this.column1Width = this.column1.nativeElement.offsetWidth;
        this.column2Width = this.column2.nativeElement.offsetWidth;
        this.column3Width = this.column3.nativeElement.offsetWidth;
        this.column4Width = this.column4.nativeElement.offsetWidth;
        this.column5Width = this.column5.nativeElement.offsetWidth;
        this.column6Width = this.column6.nativeElement.offsetWidth;
        this.column7Width = this.column7.nativeElement.offsetWidth;
    }

    toggleParamsCardTabs(callback) {

        if (callback) {

            if (this.tabAtivaOne === 0) {

                setTimeout(() => {
                    this.column1Width = this.column1.nativeElement.offsetWidth;
                    this.column2Width = this.column2.nativeElement.offsetWidth;
                    this.column3Width = this.column3.nativeElement.offsetWidth;
                    this.column4Width = this.column4.nativeElement.offsetWidth;
                    this.column5Width = this.column5.nativeElement.offsetWidth;
                    this.column6Width = this.column6.nativeElement.offsetWidth;
                    this.column7Width = this.column7.nativeElement.offsetWidth;
                }, 100);

            }


            this.cdr.detectChanges();

        }

    }

    alterarHabilitado(habilitado: boolean) {
        this.loadings.editarCertidaoHabilitado = true;

        this.CadastroCertidoesService.editarHabilitado(this.certidaoSelecionadaObj, habilitado).subscribe(res => {
            this.loadings.editarCertidaoHabilitado = false;
            this.toastService.success(habilitado ? 'Certidão habilitada com sucesso.' : 'Certidão desabilitada com sucesso.');

            this.arrayTableObrigacoes.forEach(value => {
                if (value.id === this.certidaoSelecionadaObj.id) {
                    value.habilitado = habilitado ? '1' : '0';
                }
            });


        }, (res) => {
            this.loadings.editarCertidaoHabilitado = false;
            this.toastService.error('Erro ao editar certidão.');
        });

    }

}
