import {
    AfterViewInit,
    OnInit,
    ChangeDetectorRef,
    Component,
    Input,
    NgZone
} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {Tab} from '@models/tab.model';
import {TabService} from '@services/tab.service';
import {buildUrl, findComponentByUrl} from '../../../shared/components-helper';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {Pagination} from '@models/pagination.model';
import {NzModalService} from 'ng-zorro-antd/modal';
import {CalendarConfirmacoesService} from './calendarConfirmacoes.service';
import {environment} from '../../../../environments/environment';
import {DataService} from '@services/data.service';
import {DomSanitizer} from '@angular/platform-browser';

interface FormStack {
    modalVisible: boolean;
    formGroup: UntypedFormGroup;
}

@Component({
    selector: 'app-usuarios',
    templateUrl: './calendarConfirmacoes.component.html',
    styleUrls: ['./calendarConfirmacoes.component.scss']
})
export class CalendarConfirmacoesComponent extends AbstractListTable<null> implements AfterViewInit, OnInit {
    @Input() data: any;
    loadingTabela = false;
    loadingContadores = false;
    dadosCabecalho: any = {};
    formFiltrosCabecalho: FormStack;
    formFiltrosGrid: FormStack;
    arrayAno = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
    arraySelectObrigacoes = [];
    arraySelectCategorias = [];
    arraySelectEmpresa = [];
    arraySelectCnpj = [];
    arraySelectGrupos = [];
    arraySelectResponsaveis = [];
    qtdFiltrosAtivos = 0;
    qtdFiltrosAtivosCabecalho = 0;
    currentUser;

    modalDetalhes = false;
    tabDetalhesAtiva = 0;
    arrayResumoCFOP = [];
    arrayApuracaoICMS = [];
    apuracaoICMSLoading = false;
    resumoCFOPLoading = false;

    modalVerAnexosVisible = false;
    listOfAnexos = [];

    btExcluir = false;

    checked = false;

    showCardCategoria = false;
    showModalAprovar = false;
    motivoRejeicao = '';
    statusDesc: string;

    loadingAprovar = false;

    dataAprovarRejeitar: any = {
        ids: null,
        status: null,
        motivo: null
    };

    constructor(
        private fb: UntypedFormBuilder,
        private service: CalendarConfirmacoesService,
        private toastService: ToastrService,
        private cdr: ChangeDetectorRef,
        private modalService: NzModalService,
        private tabService: TabService,
        private dataService: DataService,
        private zone: NgZone,
        public sanitizer: DomSanitizer) {
        super(service, null, toastService);

        this.pagination = new Pagination();

        this.currentParams = {
            pageIndex: 1,
            pageSize: 50,
            sort: [],
            filter: []
        };

        this.formFiltrosCabecalho = {
            modalVisible: false,
            formGroup: this.fb.group({
                vencimento: [null, null],
                obrigacao: [null, null],
                categoria: [null, null],
            })
        };

        this.formFiltrosGrid = {
            modalVisible: false,
            formGroup: this.fb.group({
                grupo: [null, null],
                empresa_id: [null, null],
                competencia: [null, null],
                status: [null, null],
                responsavel: [null, null],
                tipo: [null, null],
            })
        };

        this.service.retornarSelectsEmpresas().subscribe((retorno: any) => {
            this.arraySelectEmpresa = retorno.empresaNome;
            this.arraySelectCnpj = retorno.empresaCnpj;
        });

        this.service.retornaselectsGrupos().subscribe((retorno: any) => {
            this.arraySelectGrupos = retorno.grupoEmpresarial;
        });
    }

    ngOnInit() {
        this.loadingContadores = true;
        this.service.retornarSelectsCategorias().subscribe((response) => {
            response.forEach((value) => {
                this.arraySelectCategorias.push({id: value.categoria, descricao: value.categoria});
            });
        });
    }

    ngAfterViewInit() {

        this.dataService.currentUser.subscribe((data) => {
            this.currentUser = data;
            this.cdr.detectChanges();
        });

        this.decodeSearch([this.formFiltrosCabecalho.formGroup, this.formFiltrosGrid.formGroup]);

    }

    decodeSearch(forms) {

        this.retornaSelectObrigacoes(() => {

            forms.forEach((value) => {

                const urlParams: any = this.queryStringToJSON(window.location.href);

                const haveUrlParams = Object.entries(urlParams).length > 0;

                if (urlParams.competencia) {
                    const competencia = urlParams.competencia.split('-');
                    urlParams.competencia = new Date(competencia[0], Number(competencia[1]) - 1);
                }

                if (urlParams.vencimento) {
                    const vencimento = urlParams.vencimento.split('-');
                    urlParams.vencimento = new Date(vencimento[0], Number(vencimento[1]) - 1);
                }

                if (haveUrlParams) {
                    this.resolveParams(urlParams, value);
                }

                const dataParams: any = Object.entries(this.data).filter((element) => {
                    return element[0] !== 'link' && element[0] !== 'extra';
                });

                if (this.data.competencia && Object.prototype.toString.call(this.data.competencia) !== '[object Date]') {
                    const competencia = this.data.competencia.split('-');
                    this.data.competencia = new Date(competencia[0], Number(competencia[1]) - 1);
                }

                if (this.data.vencimento && Object.prototype.toString.call(this.data.vencimento) !== '[object Date]') {
                    const vencimento = this.data.vencimento.split('-');
                    this.data.vencimento = new Date(vencimento[0], Number(vencimento[1]) - 1);
                }

                const haveDataParams = dataParams.length > 0;

                if (haveDataParams) {
                    this.resolveParams(this.data, value);
                }

                if (!haveUrlParams && !haveDataParams) {

                    const params = {
                        vencimento: new Date()
                    };

                    this.resolveParams(params, value);

                }

            });

            this.carregaCabecalho();

        });

    }

    resolveParams(params, form) {

        Object.entries(params).forEach((value: any) => {

            const input = form.get(value[0]);

            if (input) {
                form.get(value[0]).setValue(value[1]);
            }

        });

    }

    queryStringToJSON(queryString) {

        if (queryString.indexOf('?') > -1) {
            queryString = queryString.split('?')[1];
        }

        const pairs = queryString.split('&');
        const result = {};

        pairs.forEach((pair) => {

            pair = pair.split('=');

            if (pair[0] !== window.location.href) {
                result[pair[0]] = decodeURIComponent(pair[1] || '');
            }

        });

        return result;
    }

    retornaSelectObrigacoes(callback) {

        const competencia = new Date(this.formFiltrosCabecalho.formGroup.value.vencimento);

        let ano = null;
        let mes = null;

        if (this.formFiltrosCabecalho.formGroup.value.vencimento) {
            ano = competencia.getFullYear();
            mes = this.formatMonth(competencia.getMonth() + 1);
        } else {
            ano = new Date().getFullYear();
            mes = new Date().getMonth() + 1;
        }

        this.service.retornarSelectsObrigacoes(mes, ano).subscribe((response: any) => {

            this.arraySelectObrigacoes = [];
            response.forEach(r => {
                this.arraySelectObrigacoes.push({key: r.obrigacao, value: r.obrigacao_id});
            });

            callback();

        });

    }

    retornaSelectObrigacoesChange() {
        const competencia = new Date(this.formFiltrosCabecalho.formGroup.value.vencimento);

        let ano = null;
        let mes = null;

        if (this.formFiltrosCabecalho.formGroup.value.vencimento) {
            ano = competencia.getFullYear();
            mes = this.formatMonth(competencia.getMonth() + 1);
        } else {
            ano = new Date().getFullYear();
            mes = new Date().getMonth() + 1;
        }

        this.service.retornarSelectsObrigacoes(mes, ano).subscribe((response: any) => {

            this.arraySelectObrigacoes = [];
            response.forEach(r => {
                this.arraySelectObrigacoes.push({key: r.obrigacao, value: r.obrigacao_id});
            });
        });
    }

    retornaselectsResponsaveis(competencia) {

        this.service.retornaselectsResponsaveis(competencia).subscribe((retorno: any) => {
            this.arraySelectResponsaveis = retorno;
        });

    }

    openTab(componentName: string, queryParams?: string, data?: {}) {
        this.zone.run(() => {
            const component = findComponentByUrl(componentName);
            const url = buildUrl(component, queryParams);
            const newTab = new Tab(component.name, component.title, url, component.urlType, data);
            this.tabService.closeAndAddTab(newTab);
        });
    }

    listByTable(params: NzTableQueryParams) {
        let buscar = false;

        if (params.pageIndex === this.pagination.current_page) {
            params.sort.forEach(s => {
                if (s.value) {
                    buscar = true;
                }
            });
        }

        if ((buscar || params.pageIndex === 1 && this.pagination.current_page > 1)
            || params.pageIndex > 1
            && params.pageIndex !== this.pagination.current_page) {
            this.queryTable(params);
            this.pagination.current_page = params.pageIndex;
        }

    }

    queryTable(params: NzTableQueryParams, search: string = null, busca = false, table = false): void {

        if (busca) {
            this.currentParams.pageIndex = 1;
        }

        this.currentParams = params;

        this.calculaBadgeFiltros();

        this.currentParams.filter = [];

        const filtros = this.formFiltrosGrid.formGroup.value;

        for (const [chave, valor] of Object.entries(filtros)) {

            if (valor) {
                this.currentParams.filter.push({key: chave, value: valor});
            }

        }

        const competencia = new Date(this.formFiltrosCabecalho.formGroup.value.vencimento);

        let ano = null;
        let mes = null;


        if (competencia) {
            ano = competencia.getFullYear();
            mes = this.formatMonth(competencia.getMonth() + 1);
        }

        let filtro;

        if (this.formFiltrosCabecalho.formGroup.value.categoria) {
            filtro = this.formFiltrosCabecalho.formGroup.value.categoria;
        }

        if (this.formFiltrosCabecalho.formGroup.value.obrigacao) {
            filtro = this.formFiltrosCabecalho.formGroup.value.obrigacao;
        }

        this.loadingTabela = true;

        const obrigacao = filtro ? '/' + filtro : '';

        const baseUrl = `${environment.apiUrl}/calendar/confirmacoes/tabela/${ano}-${mes}${obrigacao}`;

        this.service.table(params, search, baseUrl).subscribe((response) => {

            this.checkedItems.clear();

            this.items = [];

            this.items = response.data || response;

            this.items.forEach((value) => {

                value.statusDescricao = value.status === 'A' ? 'Em confirmação' :
                    (value.status === 'C' ? 'Confirmada' :
                        (value.status === 'R' ? 'Rejeitada' : ''));

            });

            this.pagination = new Pagination(
                response?.per_page || 50,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 50);

            this.loadingTabela = false;

        }, error => {
            this.toastService.error(error.error.message);
            this.loadingTabela = false;
        });

    }

    calculaBadgeFiltros(): void {

        this.qtdFiltrosAtivos = 0;

        if (typeof this.formFiltrosGrid !== 'undefined') {

            for (const [chave, valor] of Object.entries(this.formFiltrosGrid.formGroup.value)) {

                if (valor) {
                    this.qtdFiltrosAtivos++;
                }

            }

        }

    }

    calculaBadgeFiltrosCabecalho() {

        this.qtdFiltrosAtivosCabecalho = 0;

        if (typeof this.formFiltrosCabecalho !== 'undefined') {

            for (const [chave, valor] of Object.entries(this.formFiltrosCabecalho.formGroup.value)) {

                if (valor) {
                    this.qtdFiltrosAtivosCabecalho++;
                }

            }

        }

        this.cdr.detectChanges();

    }

    formatOne = (percent: number) => `${percent}%`;

    fecharModal(formulario: FormStack): void {
        formulario.modalVisible = false;
    }

    abrirModal(formulario: FormStack): void {
        formulario.modalVisible = true;
    }

    filtrarCabecalho(): any {

        this.showCardCategoria = this.formFiltrosCabecalho.formGroup.value.categoria;

        this.carregaCabecalho();

        this.fecharModal(this.formFiltrosCabecalho);

        this.queryTable(this.currentParams, this.currentSearch);

    }

    filtrarGrid(): any {

        this.currentParams.pageIndex = 1;

        this.queryTable(this.currentParams, this.currentSearch);

        this.fecharModal(this.formFiltrosGrid);

    }

    carregaCabecalho(): void {

        this.loadingContadores = true;

        this.calculaBadgeFiltrosCabecalho();

        const competencia = new Date(this.formFiltrosCabecalho.formGroup.value.vencimento);

        let ano = null;
        let mes = null;

        if (competencia) {
            ano = competencia.getFullYear();
            mes = this.formatMonth(competencia.getMonth() + 1);
        }

        this.retornaselectsResponsaveis(ano + '-' + mes);

        let filtro;

        if (this.formFiltrosCabecalho.formGroup.value.categoria) {
            filtro = this.formFiltrosCabecalho.formGroup.value.categoria;
        }

        if (this.formFiltrosCabecalho.formGroup.value.obrigacao) {
            filtro = this.formFiltrosCabecalho.formGroup.value.obrigacao;
        }

        this.service.retornarCabecalho(filtro, ano + '-' + mes).subscribe((response) => {

            this.dadosCabecalho.anoSelecionado = ano;
            this.dadosCabecalho.mesSelecionado = this.arrayAno[Number(mes) - 1].toUpperCase();

            this.dadosCabecalho.obrigacao = response.descricao;
            this.dadosCabecalho.orgao = response.orgao;
            this.dadosCabecalho.qtdUnidades = response.unidades;
            this.dadosCabecalho.qtdObrigacoes = response.obrigacoes;
            this.dadosCabecalho.status = response.status;

            this.loadingContadores = false;

            this.queryTable(this.currentParams, this.currentSearch);

        });

    }

    modalFiltrar(visible: boolean): void {
        this.formFiltrosGrid.modalVisible = visible;
    }

    btnResetSearch() {

        this.checkedItems.clear();
        this.checked = false;

        this.currentSearch = null;

        this.currentParams = {
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            sort: [],
            filter: [],
        };

        this.formFiltrosGrid.formGroup.reset();

        this.queryTable(this.currentParams, this.currentSearch);

    }

    clearInput(form, emitter, target) {

        const inputEmitter = form.get(emitter);
        const inputTarget = form.get(target);

        if (inputEmitter.value) {
            inputTarget.setValue(null);
        }

    }

    formatMonth(month) {

        if (month <= 9) {

            return '0' + month;

        } else {

            return month;

        }

    }

    aprovar() {

        const ids = this.dataAprovarRejeitar.ids ? this.dataAprovarRejeitar.ids : Array.from(this.checkedItems);

        this.loadingAprovar = true;

        this.service.aprovar(ids, this.dataAprovarRejeitar.status, this.motivoRejeicao).subscribe((response: any) => {

            this.loadingAprovar = false;
            this.toastrService.success('Operação finalizada com sucesso!');

            this.showAprovarRejeitar(null, null);
            this.dataAprovarRejeitar = {
                ids: null,
                status: null,
                motivo: null
            };

            this.motivoRejeicao = null;

            this.queryTable(this.currentParams, this.currentSearch);

        }, (response) => {

            this.loadingAprovar = false;
            this.toastrService.error(response.error.message);

        });

    }

    onAllChecked(checked: boolean): void {

        this.items.forEach(
            ({id}) => this.updateCheckedSet(id, checked));

    }

    updateCheckedSet(id: string, checked: boolean): void {

        if (checked) {

            this.checkedItems.add(id);

        } else {

            this.checkedItems.delete(id);

        }

        this.checked = this.checkedItems.size === this.items.length;
    }

    filtrarCampo(form, campo, valor) {
        form.formGroup.get(campo).setValue(valor);
        this.currentParams.pageIndex = 1;
        this.queryTable(this.currentParams, this.currentSearch);
    }

    msgSemHistorico(): void {
        this.toastService.warning('Obrigação sem histórico');
    }

    modalDetalheApruracao(visible) {
        this.modalDetalhes = visible;
    }

    carregaResumoCFOP(id: any): void {
        this.resumoCFOPLoading = true;

        this.service.resumoCFOP(id).subscribe((response) => {

                this.arrayResumoCFOP = response;
                this.resumoCFOPLoading = false;

            },
            (response) => {
                this.resumoCFOPLoading = false;
                this.toastrService.error(response.error.message);

            });

        this.apuracaoICMSLoading = true;

    }

    carregaApuracaoICMS(id: any): void {

        this.apuracaoICMSLoading = true;

        this.service.apuracaoICMS(id).subscribe((response) => {

                this.arrayApuracaoICMS = [response];

                this.apuracaoICMSLoading = false;

            },
            (response) => {
                this.apuracaoICMSLoading = false;
                this.toastrService.error(response.error.message);

            });

        this.modalDetalheApruracao(true);

    }

    carregaDetalheApuracao(id: any): void {

        this.carregaResumoCFOP(id);
        this.carregaApuracaoICMS(id);

        this.modalDetalheApruracao(true);

    }

    changeTabsDetalhes(event: any) {
        this.tabDetalhesAtiva = event.index;
    }

    modalAnexos(visible: boolean, arquivo?: any): void {
        if (visible && (!arquivo?.anexos || arquivo?.anexos.length === 0)) {
            this.listOfAnexos = [];
            this.toastService.warning('Sem arquivos para exibir');
        } else {
            this.btExcluir = !arquivo?.status || arquivo?.status !== 'C';
            this.listOfAnexos = arquivo?.anexos;
            this.modalVerAnexosVisible = visible;
        }
    }

    showAprovarRejeitar(id = null, status) {
        this.dataAprovarRejeitar.ids = id ? [id] : Array.from(this.checkedItems);
        this.dataAprovarRejeitar.motivo = this.motivoRejeicao;
        this.dataAprovarRejeitar.status = status;

        this.statusDesc = status === 'C' ? 'confirmar' : (status === 'R' ? 'rejeitar' : '');
        this.showModalAprovar = !this.showModalAprovar;
    }
}
