import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgZorroModule} from '../../../shared/ng-zorro.module';
import {NzSpaceModule} from 'ng-zorro-antd/space';
import {RouterModule} from '@angular/router';
import {NzRadioModule} from 'ng-zorro-antd/radio';
import {NzAnchorModule} from 'ng-zorro-antd/anchor';
import {UiModule} from '../../ui.module';
import {TransitoriosComponent} from './transitorios.component';
import {NzBreadCrumbModule} from 'ng-zorro-antd/breadcrumb';
import {NzTabsModule} from 'ng-zorro-antd/tabs';
import {NzAlertModule} from 'ng-zorro-antd/alert';
import {TransitoriosRoutingModule} from './transitorios-routing.module';
import {MonitorArquivosTransitoriosComponent} from './arquivos-transitorios/arquivos-transitorios.component';
import {NzPageHeaderModule} from 'ng-zorro-antd/page-header';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import {NzTypographyModule} from 'ng-zorro-antd/typography';
import {NzUploadModule} from 'ng-zorro-antd/upload';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NzCheckboxModule} from 'ng-zorro-antd/checkbox';
import {NzBadgeModule} from 'ng-zorro-antd/badge';
import {NzProgressModule} from "ng-zorro-antd/progress";

@NgModule({
    declarations: [
        TransitoriosComponent,
        MonitorArquivosTransitoriosComponent,
    ],
    imports: [
        TransitoriosRoutingModule,
        CommonModule,
        NgZorroModule,
        NzSpaceModule,
        RouterModule,
        NzRadioModule,
        NzAnchorModule,
        UiModule,
        NzBreadCrumbModule,
        NzTabsModule,
        NzAlertModule,
        NzPageHeaderModule,
        NzNotificationModule,
        NzTypographyModule,
        NzUploadModule,
        ReactiveFormsModule,
        NzCheckboxModule,
        FormsModule,
        NzBadgeModule,
        NzProgressModule
    ],
    exports: [TransitoriosComponent]
})
export class TransitoriosModule {
}
