<nz-card id="st16" class="ant-card-table"
         nzTitle="Obrigações" [nzExtra]="tabAtiva === 'vencimentoOficial' ? extraTabVencimentos : extraTabAgenda"
         [nzBodyStyle]="{'padding-left': '10px', 'padding-right': '10px'}">
    <nz-tabset [(nzSelectedIndex)]="indexTabAtiva" (nzSelectedIndexChange)="changeTab()">
        <nz-tab nzTitle="Vencimento Oficial">
            <nz-table #tableVencimentoOficial
                      [nzScroll]="{x: 'auto'}"
                      [nzData]="dados.vencimentosOficiais"

                      [nzFrontPagination]="false"
                      [nzShowPagination]="true"
                      [nzTotal]="paginations.vencimentosOficiais?.total"
                      [nzPageSize]="paginations.vencimentosOficiais?.per_page"
                      [nzPageIndex]="paginations.vencimentosOficiais?.current_page"
                      (nzQueryParams)="getVencimentosOficiais($event, currentsSearches.vencimentosOficiais)"
                      [nzLoading]="loadings.vencimentoOficial"
                      nzSize="small"
            >
                <thead>
                <tr>
                    <th colspan="2"></th>
                    <th colspan="{{arrayVencimentoOficialCompetencias.length}}">Competências</th>
                    <th></th>
                </tr>
                <tr>
                    <th nzAlign="center"></th>

                    <th style="text-align: center;">
                        <label nz-checkbox [(ngModel)]="checkedObrigacoes.vencimentosOficiais"
                               (nzCheckedChange)="onAllChecked($event, 'vencimentosOficiais')"></label>
                    </th>
                    <th nzAlign="center" nzColumnKey="obrigacao" [nzSortFn]="true">Obrigação</th>
                    <th nzAlign="center"
                        *ngFor="let data of arrayVencimentoOficialCompetencias">
                            <span *ngIf="data.competencia?.length === 4">
                                {{ data.competencia | date: 'yyyy' }}
                            </span>
                        <span *ngIf="data.competencia?.length > 4">
                                {{ data.competencia | date: 'MM/yyyy' }}
                            </span>
                    </th>
                    <th *ngIf="arrayVencimentoOficialCompetencias.length === 0"></th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let data of tableVencimentoOficial.data">
                    <td>
                        <a nz-button nzType="text" nzDanger (click)="showConfirmRemoverVencimento(data)">
                            <i class="far fa-trash-alt"></i>
                        </a>
                    </td>
                    <td nzAlign="center" (nzCheckedChange)="onItemChecked(data.id, $event)"
                        [nzChecked]="checkedItemsObrigacoes.vencimentosOficiais.has(data.id)"
                        [nzDisabled]="data.disabled">
                    </td>
                    <td nzAlign="center" style="white-space: nowrap;">
                        {{ data.obrigacao }}
                    </td>
                    <td nzAlign="center" *ngFor="let item of arrayVencimentoOficialCompetencias">
                             <span *ngIf="data.objectVencimentos[item.competencia] === 'semVigencia' ">
                                Não obrigatório
                            </span>
                        <span *ngIf="data.objectVencimentos[item.competencia] !== 'semVigencia'">
                                {{ (data.objectVencimentos[item.competencia] | date: 'dd/MM/yyyy') }}
                            </span>
                    </td>
                    <td *ngIf="data.vencimentos?.length === 0"></td>
                    <td nzAlign="center">
                        <button nz-button nzType="link"
                                (click)="openTab(
                                   '/cadastros/obrigacoes-empresas', null,
                                   {obrigacao_id: data.obrigacao, empresa_cnpj: empresa.cnpj, obrigacao_descricao: data.obrigacao_descricao || data.obrigacao})">
                            Editar
                        </button>
                    </td>
                </tr>
                </tbody>
            </nz-table>


        </nz-tab>
        <nz-tab nzTitle="Agenda">


            <nz-table #tableAgenda
                      [nzScroll]="{x: 'auto'}"
                      [nzData]="dados.agenda"
                      nzBordered
                      [nzFrontPagination]="false"
                      [nzShowPagination]="true"
                      [nzTotal]="paginations.agenda?.total"
                      [nzPageSize]="paginations.agenda?.per_page"
                      [nzPageIndex]="paginations.agenda?.current_page"
                      (nzQueryParams)="getAgenda($event, currentsSearches.agenda)"
                      [nzLoading]="loadings.agenda"
                      nzSize="small"
            >
                <thead>
                <tr>
                    <th colSpan="6">Obrigação</th>
                    <th colSpan="13">Competências</th>
                    <th></th>
                </tr>
                <tr>
                    <th></th>
                    <th style="text-align: center;">
                        <label nz-checkbox [(ngModel)]="checkedObrigacoes.agenda"
                               (nzCheckedChange)="onAllChecked($event, 'agenda')"></label>
                    </th>
                    <th nzAlign="center">Obrigação</th>
                    <th nzAlign="center">Equipe</th>
                    <th nzAlign="center">Responsável</th>
                    <th nzAlign="center">Cadastro</th>
                    <th nzAlign="center">{{anoSelecionado}}</th>
                    <th nzAlign="center">01/{{anoSelecionado}}</th>
                    <th nzAlign="center">02/{{anoSelecionado}}</th>
                    <th nzAlign="center">03/{{anoSelecionado}}</th>
                    <th nzAlign="center">04/{{anoSelecionado}}</th>
                    <th nzAlign="center">05/{{anoSelecionado}}</th>
                    <th nzAlign="center">06/{{anoSelecionado}}</th>
                    <th nzAlign="center">07/{{anoSelecionado}}</th>
                    <th nzAlign="center">08/{{anoSelecionado}}</th>
                    <th nzAlign="center">09/{{anoSelecionado}}</th>
                    <th nzAlign="center">10/{{anoSelecionado}}</th>
                    <th nzAlign="center">11/{{anoSelecionado}}</th>
                    <th nzAlign="center">12/{{anoSelecionado}}</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let data of tableAgenda.data">
                    <td>
                        <a nz-button nzType="text" nzDanger (click)="showConfirmRemoverAgenda(data)">
                            <i class="far fa-trash-alt"></i>
                        </a>
                    </td>
                    <td nzAlign="center" (nzCheckedChange)="onItemChecked(data.id, $event, 'agenda')"
                        [nzChecked]="checkedItemsObrigacoes.agenda.has(data.id)"
                        [nzDisabled]="data.disabled">
                    </td>
                    <td style="white-space: nowrap">{{data.descricao}}</td>
                    <td style="white-space: nowrap">{{data.equipe_nome ?? ''}}</td>
                    <td style="white-space: nowrap">{{data.responsavel_nome ?? ''}}</td>
                    <td style="white-space: nowrap">{{data.updated_at ? (data.updated_at| date: 'dd/MM/yyyy hh:mm') : '' }}</td>
                    <td nzAlign="center">{{data.vencimentos[anoSelecionado] ? (data.vencimentos[anoSelecionado]| date: 'dd/MM/yyyy') : ''}}</td>
                    <td nzAlign="center">{{data.vencimentos[anoSelecionado + '-01'] ? (data.vencimentos[anoSelecionado + '-01'] | date: 'dd/MM/yyyy') : ''}}</td>
                    <td nzAlign="center">{{data.vencimentos[anoSelecionado + '-02'] ? (data.vencimentos[anoSelecionado + '-02'] | date: 'dd/MM/yyyy') : ''}}</td>
                    <td nzAlign="center">{{data.vencimentos[anoSelecionado + '-03'] ? (data.vencimentos[anoSelecionado + '-03'] | date: 'dd/MM/yyyy') : ''}}</td>
                    <td nzAlign="center">{{data.vencimentos[anoSelecionado + '-04'] ? (data.vencimentos[anoSelecionado + '-04'] | date: 'dd/MM/yyyy') : ''}}</td>
                    <td nzAlign="center">{{data.vencimentos[anoSelecionado + '-05'] ? (data.vencimentos[anoSelecionado + '-05'] | date: 'dd/MM/yyyy') : ''}}</td>
                    <td nzAlign="center">{{data.vencimentos[anoSelecionado + '-06'] ? (data.vencimentos[anoSelecionado + '-06'] | date: 'dd/MM/yyyy') : ''}}</td>
                    <td nzAlign="center">{{data.vencimentos[anoSelecionado + '-07'] ? (data.vencimentos[anoSelecionado + '-07'] | date: 'dd/MM/yyyy') : ''}}</td>
                    <td nzAlign="center">{{data.vencimentos[anoSelecionado + '-08'] ? (data.vencimentos[anoSelecionado + '-08'] | date: 'dd/MM/yyyy') : ''}}</td>
                    <td nzAlign="center">{{data.vencimentos[anoSelecionado + '-09'] ? (data.vencimentos[anoSelecionado + '-09'] | date: 'dd/MM/yyyy') : ''}}</td>
                    <td nzAlign="center">{{data.vencimentos[anoSelecionado + '-10'] ? (data.vencimentos[anoSelecionado + '-10'] | date: 'dd/MM/yyyy') : ''}}</td>
                    <td nzAlign="center">{{data.vencimentos[anoSelecionado + '-11'] ? (data.vencimentos[anoSelecionado + '-11'] | date: 'dd/MM/yyyy') : ''}}</td>
                    <td nzAlign="center">{{data.vencimentos[anoSelecionado + '-12'] ? (data.vencimentos[anoSelecionado + '-12'] | date: 'dd/MM/yyyy') : ''}}</td>
                    <td nzAlign="center">
                        <a nz-button nzType="link" (click)="onEditarAgenda(true, data)">Editar</a>
                    </td>
                </tr>
                </tbody>
            </nz-table>

        </nz-tab>
    </nz-tabset>
</nz-card>
<ng-template #extraTabVencimentos>
    <div class="nav-item d-flex">

        <button style="margin-right: 10px;" nz-button [nzType]="'primary'"
                (click)="modalCadastrarVencimentosSugeridos(true)"
                [nzLoading]="loadings.cadastrandoVencimentosSugeridos">
            {{ loadings.cadastrandoVencimentosSugeridos ? 'Carregando...' : 'Cadastrar Obrigações Sugeridas' }}
        </button>

        <nz-badge [nzCount]="qtdFiltrosAtivos.vencimentosOficiais"
                  [nzStyle]="{ backgroundColor: '#52c41a', 'margin-right' : '15px'}">
            <button nz-button nzType="primary" style="margin-right: 10px;"
                    (click)="modalFiltrarVencimentoOficial(true);">
                <i nz-icon nzType="search"></i>
                Filtros
            </button>
        </nz-badge>
        <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" style="margin-right: 10px;">
            <input (keydown.enter)="queryTable()"
                   type="text" nz-input placeholder="Busca"
                   [(ngModel)]="currentsSearches.vencimentosOficiais">
        </nz-input-group>
        <ng-template #suffixIconButton>
            <button nz-button nzType="primary" nzSearch
                    (click)="queryTable()">
                <i nz-icon nzType="search"></i></button>
        </ng-template>
        <button [nzDropdownMenu]="menuAcoes" style="margin-right: 5px;" class="centralized-icon"
                nz-button nz-dropdown
                nzType="default">
            Opções
            <em nz-icon nzType="down"></em>
        </button>
        <nz-dropdown-menu #menuAcoes="nzDropdownMenu">
            <ul nz-menu>
                <li nz-submenu nzTitle="Manutenção Coletiva">
                    <ul>
                        <li nz-menu-item nzDisabled>Ações</li>
                        <li nz-menu-divider></li>
                        <li *ngIf="!this.loadings.vencimentoOficial else removendoEmpresas"
                            [nzDisabled]="!checkedItemsObrigacoes.vencimentosOficiais.size" nz-menu-item
                            (click)="checkedItemsObrigacoes.vencimentosOficiais.size && showConfirmRemoverMassaOfficiais()">
                            Remover Registros
                        </li>
                        <ng-template #removendoEmpresas>
                            <li [nzDisabled]="true" nz-menu-item>
                                                <span style="padding: 0 8px">
                                                    Removendo...
                                                </span>
                            </li>
                        </ng-template>
                    </ul>
                </li>
            </ul>
        </nz-dropdown-menu>
        <button nz-button nzType="default" style="margin-right: 10px;"
                (click)="btnResetSearchVencimentosOficiais('vencimentosOficiais');">
            Limpar
        </button>
        <button style="margin-right: 5px;" nz-button nzType="primary" nz-dropdown
                [nzDropdownMenu]="menu">
            {{ anoSelecionado }}
            <i nz-icon nzType="down"></i>
        </button>
        <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu>
                <li nz-menu-item *ngFor="let ano of arrayAnos" (click)="changeAno(ano)">
                    <a>{{ ano }}</a>
                </li>
            </ul>
        </nz-dropdown-menu>
    </div>
</ng-template>

<ng-template #extraTabAgenda>
    <div class="nav-item d-flex">
        <button style="margin-right: 10px;" nz-button [nzType]="'primary'"
                (click)="modalCadastrarAgendaSugerida(true)"
                [nzLoading]="loadings.cadastrandoagendasSugeridas">
            {{ loadings.cadastrandoagendasSugeridas ? 'Carregando...' : 'Cadastrar Obrigações Sugeridas' }}
        </button>

        <nz-badge [nzCount]="qtdFiltrosAtivos.vencimentosOficiais"
                  [nzStyle]="{ backgroundColor: '#52c41a', 'margin-right' : '15px'}">
            <button nz-button nzType="primary" style="margin-right: 10px;"
                    (click)="modalFiltrarVencimentoOficial(true);">
                <i nz-icon nzType="search"></i>
                Filtros
            </button>
        </nz-badge>
        <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" style="margin-right: 10px;">
            <input (keydown.enter)="queryTable()"
                   type="text" nz-input placeholder="Busca"
                   [(ngModel)]="currentsSearches.agenda">
        </nz-input-group>
        <ng-template #suffixIconButton>
            <button nz-button nzType="primary" nzSearch
                    (click)="queryTable()">
                <i nz-icon nzType="search"></i></button>
        </ng-template>

        <button [nzDropdownMenu]="menuAcoesAgenda" style="margin-right: 5px;" class="centralized-icon"
                nz-button nz-dropdown
                nzType="default">
            Opções
            <em nz-icon nzType="down"></em>
        </button>
        <nz-dropdown-menu #menuAcoesAgenda="nzDropdownMenu">
            <ul nz-menu>
                <li nz-submenu nzTitle="Manutenção Coletiva">
                    <ul>
                        <li nz-menu-item nzDisabled>Ações</li>
                        <li nz-menu-divider></li>
                        <li *ngIf="!this.loadings.agenda else removendoEmpresasAgenda"
                            [nzDisabled]="!checkedItemsObrigacoes.agenda.size" nz-menu-item
                            (click)="checkedItemsObrigacoes.agenda.size && showConfirmRemoverMassaAgenda()">
                            Remover Registros
                        </li>
                        <ng-template #removendoEmpresasAgenda>
                            <li [nzDisabled]="true" nz-menu-item>
                                                <span style="padding: 0 8px">
                                                    Removendo...
                                                </span>
                            </li>
                        </ng-template>
                    </ul>
                </li>
            </ul>
        </nz-dropdown-menu>

        <button nz-button nzType="default" style="margin-right: 10px;"
                (click)="btnResetSearchVencimentosOficiais('agenda');">
            Limpar
        </button>

        <button style="margin-right: 5px;" nz-button nzType="primary" nz-dropdown
                [nzDropdownMenu]="menu">
            {{ anoSelecionado }}
            <i nz-icon nzType="down"></i>
        </button>
        <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu>
                <li nz-menu-item *ngFor="let ano of arrayAnos" (click)="changeAno(ano)">
                    <a>{{ ano }}</a>
                </li>
            </ul>
        </nz-dropdown-menu>
    </div>
</ng-template>

<nz-modal [(nzVisible)]="visibles.modalFiltrarVencimentosOficiais" [nzTitle]="'Filtros Avançados'" [nzClosable]="true"
          (nzOnCancel)="modalFiltrarVencimentoOficial(false)" [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzDisableAutoTips]="true" [nzNoColon]="true" [formGroup]="forms.vencimentosOficiais"
              style="width: 100%;">

            <nz-form-item>
                <nz-form-label [nzSpan]="5">Inativo</nz-form-label>
                <nz-form-control [nzSpan]="14"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="forms.vencimentosOficiais.value.inativo">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="inativo">
                        <nz-option
                                [nzLabel]="'Mostrar somente inativos'" [nzValue]="'somente'">
                        </nz-option>
                        <nz-option
                                [nzLabel]="'Mostrar também inativos'" [nzValue]="'tambem'">
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>


        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalFiltrarVencimentoOficial(false)">Cancelar</button>
        <button nz-button nzType="primary" (click)="confirmaFiltrarVencimentosOficiais()">Confirmar</button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="visibles.modalFiltrarAgenda" [nzTitle]="'Filtros Avançados'" [nzClosable]="true"
          (nzOnCancel)="modalFiltrarAgenda(false)" [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzDisableAutoTips]="true" [nzNoColon]="true" [formGroup]="forms.agenda"
              style="width: 100%;">

            <nz-form-item>
                <nz-form-label [nzSpan]="5">Inativo</nz-form-label>
                <nz-form-control [nzSpan]="14"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="forms.agenda.value.inativo">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="inativo">
                        <nz-option
                                [nzLabel]="'Mostrar somente inativos'" [nzValue]="'somente'">
                        </nz-option>
                        <nz-option
                                [nzLabel]="'Mostrar também inativos'" [nzValue]="'tambem'">
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>


        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalFiltrarAgenda(false)">Cancelar</button>
        <button nz-button nzType="primary" (click)="confirmaFiltrarAgenda()">Confirmar</button>
    </div>
</nz-modal>


<nz-modal [(nzVisible)]="visibles.modalCadastrarVencimentosMassa"
          [nzTitle]="'Cadastrar Obrigações Sugeridas em Massa'" [nzClosable]="true"
          (nzOnCancel)="modalCadastrarVencimentosSugeridos(false);"
          [nzWidth]="968">
    <div *nzModalContent>
        <div style="border-bottom: 1px rgba(0,0,0,0.2) solid;">
            <nz-steps nzDirection="vertical">
                <nz-step *ngIf="steps[1].step === 'process'"
                         nzTitle="Selecionar Módulos:"
                         nzSubtitle="Selecione os módulos que deseja cadastrar as Obrigações."
                         nzStatus="{{steps[1].step}}"
                         nzIcon="{{steps[1].icon}}"></nz-step>
                <nz-step *ngIf="steps[1].step === 'finish'"
                         nzTitle="Selecionar Obrigações:"
                         nzSubtitle="Selecione as Obrigações a serem cadastradas."
                         nzStatus="{{steps[2].step}}"
                         nzIcon="{{steps[2].icon}}"></nz-step>
                <nz-step *ngIf="steps[2].step === 'finish'"
                         nzTitle="Cadastro Finalizado"
                         nzStatus="{{steps[3].step}}"
                         nzIcon="{{steps[3].icon}}"></nz-step>
            </nz-steps>
        </div>

        <nz-content *ngIf="stepNumber === 1" style="margin: 40px 0 40px 0;">
            <nz-row nzGutter="24">
                <nz-col nzSpan="24">
                    <nz-checkbox-wrapper style="width: 100%;" (nzOnChange)="selecionarModulos($event)">
                        <div nz-row>
                            <div nz-col nzSpan="8">
                                <label nz-checkbox nzValue="calendar"
                                       [(ngModel)]="opcoesModalCadastrarMassa.modelCalendar"
                                       [ngModelOptions]="{standalone: true}">Calendar</label>
                            </div>
                        </div>
                        <div nz-row>
                            <div nz-col nzSpan="8">
                                <label nz-checkbox nzValue="check" [(ngModel)]="opcoesModalCadastrarMassa.modelCheck"
                                       [ngModelOptions]="{standalone: true}">Check</label>
                            </div>
                        </div>
                        <div nz-row>
                            <div nz-col nzSpan="8">
                                <label nz-checkbox nzValue="compliance"
                                       [(ngModel)]="opcoesModalCadastrarMassa.modelCompliance"
                                       [ngModelOptions]="{standalone: true}">Compliance</label>
                            </div>
                        </div>
                    </nz-checkbox-wrapper>
                </nz-col>
            </nz-row>
        </nz-content>
        <nz-content *ngIf="stepNumber === 2" style="margin: 20px 0 40px 0;">
            <nz-row nzGutter="24" style="margin-bottom: 20px;">
                <nz-col nzSpan="24">
                    <nz-radio-group [(ngModel)]="opcoesModalCadastrarMassa.radioQuaisObrigacoes"
                                    [ngModelOptions]="{standalone: true}">
                        <label nz-radio nzValue="todas">Todas as Obrigações</label>
                        <label nz-radio nzValue="algumas">Algumas Obrigações</label>
                    </nz-radio-group>
                </nz-col>
            </nz-row>
            <nz-row nzGutter="24" *ngIf="opcoesModalCadastrarMassa.radioQuaisObrigacoes === 'algumas'">
                <nz-col nzSpan="24">
                    <nz-alert nzType="info" nzShowIcon [nzMessage]="checkedMessageObrigacoes"
                              style="margin: 15px !important;"
                              *ngIf="opcoesModalCadastrarMassa.checkedItemsObrigacoes.size > 0">
                        <ng-template #checkedMessageObrigacoes>
                            <div nz-row nzAlign="middle" nzGutter="24">
                                <div nz-col nzSpan="24">
                                    <strong class="text-primary"></strong>
                                    {{ opcoesModalCadastrarMassa.checkedItemsObrigacoes.size }} Registros Selecionados
                                </div>
                            </div>
                        </ng-template>
                    </nz-alert>
                    <nz-table #tableObrigacoes
                              [nzData]="opcoesModalCadastrarMassa.previaObrigacoes"
                              [nzFrontPagination]="false"
                              [nzTotal]="opcoesModalCadastrarMassa.paginationObrigcoes?.total"
                              [nzPageSize]="opcoesModalCadastrarMassa.paginationObrigcoes?.per_page"
                              [nzPageIndex]="opcoesModalCadastrarMassa.paginationObrigcoes?.current_page"
                              (nzQueryParams)="retornaObrigacoesEmpresasSteps($event)"
                              [nzShowTotal]="rangeTemplateObrigacao"
                              [nzLoading]="loadings.previaObrigacoes"
                              class="table-small">
                        <thead>
                        <tr>
                            <th></th>
                            <th nzColumnKey="descricao" [nzSortFn]="true" nzAlign="center">Obrigação</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let arquivo of tableObrigacoes.data">
                            <td nzLeft (nzCheckedChange)="onItemCheckedObrigacoes(arquivo.id, $event)"
                                [nzChecked]="opcoesModalCadastrarMassa.checkedItemsObrigacoes.has(arquivo.id)"
                                [nzDisabled]="arquivo.disabled">
                            </td>
                            <td nzAlign="center">{{ arquivo?.descricao }}</td>
                        </tr>
                        </tbody>
                    </nz-table>
                    <ng-template #rangeTemplateObrigacao let-range="range" let-total>
                        {{ range[0] }}-{{ range[1] }} de {{ total }} Registros
                    </ng-template>
                </nz-col>
            </nz-row>
        </nz-content>


    </div>
    <div *nzModalFooter>
        <div class="d-flex"
             [ngClass]="[stepNumber === 1 ? 'justify-content-end' : '', stepNumber > 1 ? 'justify-content-between' : '']">
            <button nz-button nzType="default"
                    *ngIf="stepNumber !== 1 && stepNumber < 3"
                    (click)="changeStep(stepNumber, 'prev')">
                <i nz-icon nzType="double-left" nzTheme="outline"></i>
                Retornar
            </button>
            <button nz-button nzType="primary"
                    *ngIf="stepNumber < 3"
                    (click)="changeStep(stepNumber, 'next')" [nzLoading]="loadings.steps">
                {{ nextButtonText }}
                <i nz-icon nzType="double-right" nzTheme="outline"></i>
            </button>
        </div>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="visibles.modalEditarAgenda"
          [nzTitle]="'Editar Obrigação'" [nzClosable]="true"
          (nzOnCancel)="onEditarAgenda(false);">
    <div *nzModalContent>

        <nz-skeleton *ngIf="loadings.modalEditarAgenda"></nz-skeleton>

        <form nz-form [nzDisableAutoTips]="true" [nzNoColon]="true" [formGroup]="forms.editarAgenda"
              style="width: 100%;" *ngIf="!loadings.modalEditarAgenda">

            <nz-form-item>
                <nz-form-label [nzSpan]="5">Obrigação</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    {{opcoesModalEditarAgenda.obrigacao}}
                </nz-form-control>
            </nz-form-item>


            <nz-form-item>
                <nz-form-label [nzSpan]="5">Equipe</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="equipe_id">
                        <nz-option *ngFor="let opt of selectOptions.equipes" [nzLabel]="opt.label"
                                   [nzValue]="opt.value"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="5">Responsável</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="responsavel_id">
                        <nz-option *ngFor="let opt of selectOptions.responsaveis" [nzLabel]="opt.label"
                                   [nzValue]="opt.value"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <div *ngIf="opcoesModalEditarAgenda.periodicidade === 'A'">
                <nz-form-item>
                    <nz-form-label [nzSpan]="5">{{anoSelecionado}}</nz-form-label>
                    <nz-form-control [nzSpan]="14">
                        <nz-date-picker style="width: 100%;"
                                        formControlName="ano"
                                        nzFormat="dd/MM/yyyy"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
            </div>

            <div *ngIf="opcoesModalEditarAgenda.periodicidade === 'M'" style="height: 300px; overflow-y: auto">
                <nz-form-item>
                    <nz-form-label [nzSpan]="5">01/{{anoSelecionado}}</nz-form-label>
                    <nz-form-control [nzSpan]="14">
                        <nz-date-picker style="width: 100%;"
                                        formControlName="01"
                                        nzFormat="dd/MM/yyyy"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="5">02/{{anoSelecionado}}</nz-form-label>
                    <nz-form-control [nzSpan]="14">
                        <nz-date-picker style="width: 100%;"
                                        formControlName="02"
                                        nzFormat="dd/MM/yyyy"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="5">03/{{anoSelecionado}}</nz-form-label>
                    <nz-form-control [nzSpan]="14">
                        <nz-date-picker style="width: 100%;"
                                        formControlName="03"
                                        nzFormat="dd/MM/yyyy"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="5">04/{{anoSelecionado}}</nz-form-label>
                    <nz-form-control [nzSpan]="14">
                        <nz-date-picker style="width: 100%;"
                                        formControlName="04"
                                        nzFormat="dd/MM/yyyy"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="5">05/{{anoSelecionado}}</nz-form-label>
                    <nz-form-control [nzSpan]="14">
                        <nz-date-picker style="width: 100%;"
                                        formControlName="05"
                                        nzFormat="dd/MM/yyyy"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="5">06/{{anoSelecionado}}</nz-form-label>
                    <nz-form-control [nzSpan]="14">
                        <nz-date-picker style="width: 100%;"
                                        formControlName="06"
                                        nzFormat="dd/MM/yyyy"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="5">07/{{anoSelecionado}}</nz-form-label>
                    <nz-form-control [nzSpan]="14">
                        <nz-date-picker style="width: 100%;"
                                        formControlName="07"
                                        nzFormat="dd/MM/yyyy"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="5">08/{{anoSelecionado}}</nz-form-label>
                    <nz-form-control [nzSpan]="14">
                        <nz-date-picker style="width: 100%;"
                                        formControlName="08"
                                        nzFormat="dd/MM/yyyy"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="5">09/{{anoSelecionado}}</nz-form-label>
                    <nz-form-control [nzSpan]="14">
                        <nz-date-picker style="width: 100%;"
                                        formControlName="09"
                                        nzFormat="dd/MM/yyyy"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="5">10/{{anoSelecionado}}</nz-form-label>
                    <nz-form-control [nzSpan]="14">
                        <nz-date-picker style="width: 100%;"
                                        formControlName="10"
                                        nzFormat="dd/MM/yyyy"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="5">11/{{anoSelecionado}}</nz-form-label>
                    <nz-form-control [nzSpan]="14">
                        <nz-date-picker style="width: 100%;"
                                        formControlName="11"
                                        nzFormat="dd/MM/yyyy"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="5">12/{{anoSelecionado}}</nz-form-label>
                    <nz-form-control [nzSpan]="14">
                        <nz-date-picker style="width: 100%;"
                                        formControlName="12"
                                        nzFormat="dd/MM/yyyy"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
            </div>

        </form>

    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="onEditarAgenda(false)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="loadings.editandoAgenda"
                (click)="confirmaEditarAgenda()">Editar
        </button>
    </div>
</nz-modal>


