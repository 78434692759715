import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {InboxComponent} from './inbox.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NzPageHeaderModule} from 'ng-zorro-antd/page-header';
import {NzBadgeModule} from 'ng-zorro-antd/badge';
import {NzLayoutModule} from 'ng-zorro-antd/layout';
import {NzCardModule} from 'ng-zorro-antd/card';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {NzInputModule} from 'ng-zorro-antd/input';
import {NzTableModule} from 'ng-zorro-antd/table';
import {UiModule} from '../ui.module';


@NgModule({
  declarations: [
    InboxComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NzPageHeaderModule,
    NzBadgeModule,
    NzLayoutModule,
    NzCardModule,
    NzButtonModule,
    NzInputModule,
    NzTableModule,
    UiModule
  ]
})
export class InboxModule { }
