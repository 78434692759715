import {
    AfterViewInit,
    ChangeDetectorRef,
    Component, ElementRef,
    Input,
    OnInit, ViewChild,
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {TabService} from '@services/tab.service';
import {EditarSATService} from './editarSAT.service';
import {Pagination} from '@models/pagination.model';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import {StickyHeaderDirective} from '@components/directive/directive';
import {DataService} from '@services/data.service';
import {buildUrl, findComponentByUrl} from '../../../shared/components-helper';
import {Tab} from '@models/tab.model';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {Helpers} from '../../../core/helpers';

interface FormStack {
    modalVisible: boolean;
    formGroup: FormGroup;
}

@Component({
    selector: 'app-editarNotasFiscaisEletronicas',
    templateUrl: './editarSAT.component.html',
    styleUrls: ['./editarSAT.component.scss'],
})
export class EditarSATComponent extends AbstractListTable<any> implements OnInit, AfterViewInit {

    @Input() data;

    // Para o card
    configuracoesSAT: any = {};

    currentParams: any = {
        equipamentos: {
            pageIndex: 1,
            pageSize: 10,
            sort: [],
            filter: []
        },
        lotes: {
            pageIndex: 1,
            pageSize: 10,
            sort: [],
            filter: []
        }
    };

    autoTips: Record<string, Record<string, string>> = {
        default: {
            required: 'Campo obrigatório',
        }
    };

    gravando = false;

    empresa: any = {};
    equipamentos = [];
    lotes = [];

    loteFiltrado = false;

    loadingConfigSAT = false;
    loadingEquipamentos = false;
    loadingAlterarEquipamento = false;
    loadingLotes = false;

    paginationEquip = new Pagination();
    paginationLotes = new Pagination();

    offsetTop = 15;
    target: any;

    formConfiguracoesSAT: FormStack;
    formFiltrarLotes: FormStack;
    formAlterarEquipamento: FormStack;

    currentUser: any;

    currentSearchEquip: string;
    currentSearchLotes: string;

    serieDigitoSelecionado: string;
    serieSelecionado: string;

    qtdFiltrosLotesAtivos = 0;

    private cardLotes;

    @ViewChild('cardLotes') set contentCard(content: ElementRef) {
        if (content) {
            this.cardLotes = content;
        }
    }

    constructor(
        private fb: FormBuilder,
        private service: EditarSATService,
        private toastService: ToastrService,
        private cdr: ChangeDetectorRef,
        private tabService: TabService,
        private dataService: DataService
    ) {
        super(service, null, toastService);

        this.formConfiguracoesSAT = {
            modalVisible: false,
            formGroup: this.fb.group({
                ativo: [null, [Validators.required]],
                intervaloConsulta: [null, [Validators.required]],
                satDownloadData: [null],
            })
        };

        this.formFiltrarLotes = {
            modalVisible: false,
            formGroup: this.fb.group({
                dataInicio: [null, Validators.required],
                dataFim: [null, Validators.required],
            })
        };

        this.formAlterarEquipamento = {
            modalVisible: false,
            formGroup: this.fb.group({
                ultimoDownloadData: [null, Validators.required],
            })
        };

    }

    ngOnInit(): void {
        this.dataService.currentUser.subscribe((data) => {
            this.currentUser = data;
            this.cdr.detectChanges();
        });

        if (!this.data || !this.data.id) {
            this.data = {};
            this.data.id = localStorage.getItem('param');
        }
        this.retornaDadosCadastrais(this.data.id);
        this.retornaEquipamentos(this.currentParams.equipamentos);
    }

    ngAfterViewInit() {

        this.target = StickyHeaderDirective.target;
        this.cdr.detectChanges();

        this.dataService.currentUser.subscribe((data) => {
            this.currentUser = data;
            this.cdr.detectChanges();
        });

    }

    retornaDadosCadastrais(empresaId: string): void {

        this.loadingConfigSAT = true;

        this.service.retornaDadosCadastrais(empresaId).subscribe((response: any) => {
            this.empresa = response || {};

            this.configuracoesSAT = {
                ativo: this.empresa.ativo,
                satDownloadData: this.empresa.satDownloadData,
                intervaloConsulta: this.empresa.intervaloConsulta
            };

            this.formConfiguracoesSAT.formGroup.get('ativo').setValue(this.empresa.ativo);
            this.formConfiguracoesSAT.formGroup.get('satDownloadData').setValue(this.empresa.satDownloadData);
            this.formConfiguracoesSAT.formGroup.get('intervaloConsulta').setValue(this.empresa.intervaloConsulta || 24);

            this.loadingConfigSAT = false;

        }, error => {
            this.toastService.error(error.error.message);
            this.loadingConfigSAT = false;
        });
    }

    buscarEquipamentos() {
        this.currentParams.equipamentos.pageIndex = 1;

        this.retornaEquipamentos(this.currentParams.equipamentos, this.currentSearchEquip);

    }

    retornaEquipamentos(params: NzTableQueryParams, search = null): void {

        this.loadingEquipamentos = true;

        this.currentParams.equipamentos = params;

        if (search) {
            this.currentSearchEquip = search;
        }

        this.currentParams.filter = [];
        const filtros = [];

        for (const [chave, valor] of Object.entries(filtros)) {
            if (valor) {
                this.currentParams.equipamentos.filter.push({key: chave, value: valor});
            }
        }

        params.sort.forEach(s => {
            if (s.value) {
                this.currentParams.equipamentos.filter.push({key: 'ordenar', value: s.key});
                this.currentParams.equipamentos.filter.push({key: 'sentido', value: s.value});
            }
        });

        if (this.currentSearchEquip) {
            this.currentParams.equipamentos.filter.push({key: 'procurar', value: this.currentSearchEquip});
        }

        const id = this.data.id;

        this.service.retornaEquipamentos(id, this.currentParams.equipamentos).subscribe((response: any) => {
            this.equipamentos = response.data;

            this.paginationEquip = new Pagination(
                response?.per_page || 50,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 50,
                response?.from || 1,
                response?.to || 1);

            this.loadingEquipamentos = false;

        }, error => {
            this.toastService.error(error.error.message);
            this.loadingEquipamentos = false;
        });
    }

    listByTableEquip(params: NzTableQueryParams) {
        let buscar = false;

        if (params.pageIndex === this.paginationEquip.current_page) {
            params.sort.forEach(s => {
                if (s.value) {
                    buscar = true;
                }
            });
        }

        if ((buscar || params.pageIndex === 1 && this.paginationEquip.current_page > 1)
            || params.pageIndex > 1
            && params.pageIndex !== this.paginationEquip.current_page) {
            this.retornaEquipamentos(params);
            this.paginationEquip.current_page = params.pageIndex;
        }

    }

    alterarEquiplamento(): void {

        if (this.formAlterarEquipamento.formGroup.valid) {
            this.loadingAlterarEquipamento = true;

            const data = {
                ultimoDownloadData:
                    Helpers.formataDateBD(this.formAlterarEquipamento.formGroup.get('ultimoDownloadData').value) + '  00:00:00',
            };

            this.service.alterarEquipamento(this.data.id, this.serieDigitoSelecionado, data).subscribe({
                next: (res) => {
                    this.loadingAlterarEquipamento = false;
                    this.showModal(this.formAlterarEquipamento);
                    this.toastService.success(res.message);
                    this.retornaEquipamentos(this.currentParams.equipamentos, this.currentSearchEquip);
                },
                error: error => {
                    this.loadingAlterarEquipamento = false;
                    this.toastService.error(error.error.message);
                }
            });
        } else {
            Object.values(this.formAlterarEquipamento.formGroup.controls).forEach(control => {
                if (control.invalid) {
                    control.markAsDirty();
                    control.updateValueAndValidity({onlySelf: true});
                }
            });
            return;
        }
    }

    buscarLotes() {
        this.currentParams.lotes.pageIndex = 1;

        this.retornaLotes(this.currentParams.lotes, this.currentSearchLotes);
    }

    showModalFiltraLotes(visible: boolean, equipamento?: any) {
        if (equipamento) {
            this.serieDigitoSelecionado = equipamento?.numeroSerie + equipamento?.serieDigito;
            this.serieSelecionado = equipamento?.numeroSerie;
        }
        this.formFiltrarLotes.modalVisible = visible;
    }

    filtrarLotes() {

        if (this.formFiltrarLotes.formGroup.invalid) {
            Object.values(this.formFiltrarLotes.formGroup.controls).forEach(control => {
                if (control.invalid) {
                    control.markAsDirty();
                    control.updateValueAndValidity({onlySelf: true});
                }
            });
            return;
        }

        this.currentSearchLotes = null;
        this.currentParams.lotes.pageIndex = 1;

        this.calculaBadgeFiltros();
        this.retornaLotes(this.currentParams.lotes, this.currentSearchLotes);
    }

    calculaBadgeFiltros(): void {

        this.qtdFiltrosLotesAtivos = 0;

        if (typeof this.formFiltrarLotes !== 'undefined') {
            for (const [chave, valor] of Object.entries(this.formFiltrarLotes.formGroup.value)) {

                if (valor && chave) {
                    this.qtdFiltrosLotesAtivos += 1;
                }
            }
        }

    }

    retornaLotes(params: NzTableQueryParams, search = null): void {

        this.loadingLotes = true;

        this.currentParams.lotes = params;

        if (search) {
            this.currentSearchLotes = search;
        }

        this.currentParams.lotes.filter = [];
        const filtros = this.formFiltrarLotes.formGroup.value;

        for (let [chave, valor] of Object.entries(filtros)) {
            if (valor) {
                valor = ['dataInicio', 'dataFim'].includes(chave) ? Helpers.formatDateDb(String(valor)) : valor;
                this.currentParams.lotes.filter.push({key: chave, value: valor});
            }
        }

        params.sort.forEach(s => {
            if (s.value) {
                this.currentParams.lotes.filter.push({key: 'ordenar', value: s.key});
                this.currentParams.lotes.filter.push({key: 'sentido', value: s.value});
            }
        });

        if (this.currentSearchLotes) {
            this.currentParams.lotes.filter.push({key: 'procurar', value: this.currentSearchLotes});
        }

        const id = this.data.id;

        this.service.retornaLotes(id, this.serieDigitoSelecionado, this.currentParams.lotes).subscribe((response: any) => {
            this.lotes = response.data;

            this.paginationLotes = new Pagination(
                response?.per_page || 50,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 50,
                response?.from || 1,
                response?.to || 1);

            this.loadingLotes = false;

            this.showModalFiltraLotes(false);

            this.loteFiltrado = true;

            if (!this.lotes.length) {
                this.toastService.info('Nenhum lote encontrado.');
            } else {
                this.scrollContainer();
            }

        }, error => {
            this.toastService.error(error.error.message);
            this.loadingLotes = false;
        });
    }

    listByTableLotes(params: NzTableQueryParams) {
        let buscar = false;

        if (params.pageIndex === this.paginationLotes.current_page) {
            params.sort.forEach(s => {
                if (s.value) {
                    buscar = true;
                }
            });
        }

        if ((buscar || params.pageIndex === 1 && this.paginationLotes.current_page > 1)
            || params.pageIndex > 1
            && params.pageIndex !== this.paginationLotes.current_page) {
            this.retornaLotes(params);
            this.paginationLotes.current_page = params.pageIndex;
        }

    }

    showModal(formulario, equipamento?: any): void {
        if (equipamento) {
            this.serieDigitoSelecionado = equipamento.numeroSerie + equipamento.serieDigito;
            this.serieSelecionado = equipamento.numeroSerie;
            this.formAlterarEquipamento.formGroup.get('ultimoDownloadData').setValue(equipamento.ultimoDownloadData);
        }

        formulario.modalVisible = !formulario.modalVisible;
    }

    openTab(componentUrl: string, queryParams?: string, data?: {}) {
        const component = findComponentByUrl(componentUrl);
        const url = buildUrl(component, queryParams);
        const newTab = new Tab(component.name, component.title, url, component.urlType, data);
        this.tabService.closeAndAddTab(newTab);
    }

    confirmaAlterarSAT() {

        if (this.formConfiguracoesSAT.formGroup.valid) {

            this.loadingConfigSAT = true;

            const data: any = {};
            data.empresa_id = [this.data.id];

            data.ativo = Number(this.formConfiguracoesSAT.formGroup.get('ativo').value) || 0;
            data.intervaloConsulta = Number(this.formConfiguracoesSAT.formGroup.get('intervaloConsulta').value) || 24;
            data.satDownloadData = this.formConfiguracoesSAT.formGroup.get('satDownloadData').value;

            this.service.alterarSat(data).subscribe((response) => {
                this.toastService.success(response.message);
                this.loadingConfigSAT = false;
                this.checkedItems.clear();
                this.showModal(this.formConfiguracoesSAT);
                this.formConfiguracoesSAT.formGroup.reset();

                this.retornaDadosCadastrais(this.data.id);

            }, error => {
                this.toastService.error(error.error.message);
                this.loadingConfigSAT = false;

            });

        } else {
            for (const key in this.formConfiguracoesSAT.formGroup.controls) {

                if (key) {
                    const campo = this.formConfiguracoesSAT.formGroup.get(key);

                    campo.markAsDirty();
                    campo.updateValueAndValidity();
                }
            }

            this.toastService.error('Informe o intervalo de consuta.');
        }

    }

    scrollContainer(px = 0) {

        setTimeout(() => {
            this.target.scrollTo({
                left: 0,
                top: this.cardLotes.nativeElement.offsetTop + px,
                behavior: 'smooth'
            });
        }, 800);

    }
}
