import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Empresa} from '@models/empresa.model';
import {AbstractService} from '@services/abstract.service';
import {Observable} from 'rxjs';
import {environment} from '../../../../../environments/environment';
import {Helpers} from "../../../../core/helpers";

@Injectable({providedIn: 'root'})
export class ConfiguracaoSsoService extends AbstractService<Empresa> {

    constructor(http: HttpClient) {
        super(http, '/administracao/configuracoes-conta', {});
    }

    getSSO(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/configuracao/sso/dados`);

    }

    salvar(dados: FormData): Observable<any> {
        return this.http.post(`${environment.apiUrl}/configuracao/sso/salvar`, dados);
    }
}
