import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CardTributoPorGrupoComponent} from '@components/card-tributo-por-grupo/card-tributo-por-grupo.component';
import {ExportarTabelaModule} from '@components/exportar-tabela/exportar-tabela.module';
import {NzCardModule} from 'ng-zorro-antd/card';
import {NzTabsModule} from 'ng-zorro-antd/tabs';
import {NzTableModule} from 'ng-zorro-antd/table';
import {NzTypographyModule} from 'ng-zorro-antd/typography';
import {NzToolTipModule} from 'ng-zorro-antd/tooltip';
import {SparkLineModule} from '@components/spark-line/spark-line.module';
import {NzBadgeModule} from 'ng-zorro-antd/badge';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {NzGridModule} from 'ng-zorro-antd/grid';
import {UiModule} from '../../../modules/ui.module';
import {ReactiveFormsModule} from "@angular/forms";


@NgModule({
    declarations: [
        CardTributoPorGrupoComponent
    ],
    exports: [
        CardTributoPorGrupoComponent
    ],
  imports: [
    CommonModule,
    ExportarTabelaModule,
    NzCardModule,
    NzTabsModule,
    NzTableModule,
    NzTypographyModule,
    NzToolTipModule,
    SparkLineModule,
    NzBadgeModule,
    NzButtonModule,
    NzIconModule,
    NzGridModule,
    UiModule,
    ReactiveFormsModule
  ]
})
export class CardTributoPorGrupoModule {
}
