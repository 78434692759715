import {Component} from '@angular/core';
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import {InboxConfigService} from './inboxConfig.service';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-inboxConfig',
    templateUrl: './inboxConfig.component.html',
    styleUrls: ['./inboxConfig.component.scss'],
})
export class InboxConfigComponent {
    loadingConfg = true;
    loadingBt = false;
    formConfiguracao: UntypedFormGroup;
    modalCadastrarVisible = true;

    autoTips: Record<string, Record<string, string>> = {
        default: {
            required: 'Campo obrigatório',
            email: 'Email inválido',
        },
    };

    passwordVisible = false;

    id = null;

    constructor(
        private fb: UntypedFormBuilder,
        private service: InboxConfigService,
        private toastrService: ToastrService
    ) {
        this.formConfiguracao = this.fb.group({
            host: [null, [Validators.required]],
            email: [null, [Validators.required, Validators.email]],
            password: [null, [Validators.required]],
            port: [null, [Validators.required]],
            pasta: [null],
            data: [null],
            status: [0],
        });
    }

    ngOnInit() {
        this.getConfiguracao();
    }

    getConfiguracao() {
        this.service.getConfiguracao().subscribe({
            next: (res) => {
                this.id = res.id;
                this.formConfiguracao.get('host').setValue(res.host);
                this.formConfiguracao.get('email').setValue(res.email);
                this.formConfiguracao.get('password').setValue(res.password);
                this.formConfiguracao.get('port').setValue(res.port);
                this.formConfiguracao.get('pasta').setValue(res.pasta);
                this.formConfiguracao.get('data').setValue(res.data);
                this.formConfiguracao.get('status').setValue(res.status);
                this.loadingConfg = false;
            },
            error: (err) => {
                this.loadingConfg = false;
                this.toastrService.error(err.message);
            },
        });
    }

    maskNZDatePicker(event: any) {
        if (
            event.key !== 'Backspace' &&
            (event.target.value.length === 2 || event.target.value.length === 5)
        ) {
            event.target.value += '/';
        }
    }

    cadastrarConfiguracao() {
        if (this.formConfiguracao.valid) {
            this.loadingBt = true;

            this.service.cadastrarConfiguracao(this.formConfiguracao.value, this.id).subscribe({
                next: (res) => {
                    this.getConfiguracao();
                    this.loadingBt = false;
                    this.toastrService.success(res.message);
                },
                error: (err) => {
                    this.loadingBt = false;
                    this.toastrService.error(err.message);
                },
            });
        } else {
            Object.values(this.formConfiguracao.controls).forEach((control) => {
                if (control.invalid) {
                    control.markAsDirty();
                    control.updateValueAndValidity({onlySelf: true});
                }
            });
        }
    }

    modalCadastrar(visible: boolean) {
        this.modalCadastrarVisible = visible;
    }
}
