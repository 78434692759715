<nz-page-header class="site-page-header" nzTitle="Cadastros" nzSubtitle="Certidões"></nz-page-header>
<nz-content>
    <div nz-row [nzGutter]="24">
        <div nz-col nzXs="24" nzSm="24" nzMd="4" nzLg="4" nzXl="4">
            <nz-affix [nzOffsetTop]="offsetTop" [nzTarget]="target">
                <nz-anchor class="transparent-anchor" [nzAffix]="false" [nzShowInkInFixed]="true"
                           [nzContainer]="target">
                    <nz-link nzHref="#federal" nzTitle="Federal" *ngIf="tabAtivaOne === 0"></nz-link>
                    <nz-link nzHref="#estadual" nzTitle="Estadual" *ngIf="tabAtivaOne === 0"></nz-link>
                    <nz-link nzHref="#municipal" nzTitle="Municipal" *ngIf="tabAtivaOne === 0"></nz-link>
                    <nz-link nzHref="#cardTabsOne" nzTitle="Certidões" *ngIf="tabAtivaOne === 1"></nz-link>
                    <nz-link #vencimentoOficialAnchor id="vencimentoOficialAnchor" nzHref="#cardTabsTwo"
                             nzTitle="Cadastro"
                             *ngIf="tabAtivaTwo === 0 && showTabsTwo"></nz-link>
                </nz-anchor>
            </nz-affix>
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="20" nzLg="20" nzXl="20">
            <nz-card [ngClass]="[!loadingCardTabsOne ? 'ant-card-table' : '']"
                     [nzLoading]="loadingCardTabsOne"
                     id="cardTabsOne">
                <nz-card-tab>
                    <nz-tabset nzSize="large" [(nzSelectedIndex)]="tabAtivaOne"
                               (nzSelectChange)="changeTabs($event, 'one')"
                               [nzTabBarExtraContent]="tabsOneExtra">
                        <nz-tab [nzDisabled]="loadingCardTabsOne" nzTitle="Órgão"></nz-tab>
                        <nz-tab [nzDisabled]="loadingCardTabsOne" nzTitle="Certidões"></nz-tab>
                    </nz-tabset>
                </nz-card-tab>
                <nz-divider id="federal" nzText="Federal" nzOrientation="left"
                            style="padding: 0 15px; font-weight: bold;"
                            *ngIf="tabAtivaOne === 0"></nz-divider>
                <nz-table #tableFederal
                          nzSize="small"
                          [nzData]="dataFederal"
                          [nzFrontPagination]="false"
                          [nzShowPagination]="false"
                          *ngIf="tabAtivaOne === 0"
                >
                    <thead>
                    <tr>
                        <th colspan="3">Cadastros</th>
                        <th colspan="2">Certidões</th>
                        <th></th>
                    </tr>
                    <tr>
                        <th nzColumnKey="matriz" [nzSortFn]="true" nzAlign="center">Unidade</th>
                        <th nzColumnKey="obrigacoesSugeridas" [nzSortFn]="true" nzAlign="center">Certidões
                            Sugeridas<br>
                            (por empresa)
                        </th>
                        <th nzColumnKey="empresasCadastradas" [nzSortFn]="true" nzAlign="center">Empresas Habilitadas
                        </th>
                        <th nzColumnKey="estimadas" [nzSortFn]="true" nzAlign="center">Estimada(s)</th>
                        <th nzColumnKey="cadastradas" [nzSortFn]="true" nzAlign="center">Habilitadas(s)</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let item of tableFederal.data">
                        <td nzAlign="center">{{ item.descricao }}</td>
                        <td nzAlign="center">{{ item.cadastros.certidoes_sugeridas }}</td>
                        <td nzAlign="center">{{ item.cadastros.empresas_cadastradas }}</td>
                        <td nzAlign="center">{{ item.certidoes.estimadas }}</td>
                        <td nzAlign="center">{{ item.certidoes.cadastradas }}</td>
                        <td nzAlign="center">
                            <button nz-button nzType="link" (click)="filtrarOrgao('Federal', item.label)">Detalhes
                            </button>
                        </td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr>
                        <td nzAlign="center">Total</td>
                        <td></td>
                        <td nzAlign="center"></td>
                        <td nzAlign="center">{{ totaisFederal.estimadas }}</td>
                        <td nzAlign="center">{{ totaisFederal.cadastradas }}</td>
                        <td></td>
                    </tr>
                    </tfoot>
                </nz-table>
                <nz-divider id="estadual" nzText="Estadual" nzOrientation="left"
                            style="padding: 0 15px; font-weight: bold;"
                            *ngIf="tabAtivaOne === 0"></nz-divider>
                <nz-table #tableEstadual
                          nzSize="small"
                          [nzData]="dataEstadual"
                          [nzFrontPagination]="false"
                          [nzShowPagination]="false"
                          *ngIf="tabAtivaOne === 0"
                >
                    <thead>
                    <tr>
                        <th colspan="3">Cadastros</th>
                        <th colspan="2">Certidões</th>
                        <th style="text-align: center">
                            <button style="width: 120px" *ngIf="!mostrarSemIEs" (click)="exibirTudoIEs()" nz-button
                                    nzType="default"
                                    [nz-tooltip]="'Exibir também estados sem IEs cadastradas'">
                                Exibir Todos
                            </button>
                            <button style="width: 120px" *ngIf="mostrarSemIEs" (click)="exibirTudoIEs()" nz-button
                                    nzType="default"
                                    [nz-tooltip]="'Exibir somente estados com IEs cadastradas'">
                                Recolher
                            </button>
                        </th>
                    </tr>
                    <tr>
                        <th nzAlign="center">UF</th>
                        <th nzAlign="center">Certidões Sugeridas<br>(por empresa)</th>
                        <th nzAlign="center">Empresas c/ IEs Habilitadas</th>
                        <th nzAlign="center">Estimada(s)</th>
                        <th nzAlign="center">Habilitadas(s)</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let item of tableEstadual.data">
                        <td nzAlign="center">{{ item.label }}</td>
                        <td nzAlign="center">{{ item.cadastros.certidoes_sugeridas }}</td>
                        <td nzAlign="center">{{ item.cadastros.empresas_cadastradas }}</td>
                        <td nzAlign="center">{{ item.certidoes.estimadas }}</td>
                        <td nzAlign="center">{{ item.certidoes.cadastradas }}</td>
                        <td nzAlign="center">
                            <button nz-button nzType="link" (click)="filtrarOrgao('Estadual', item.label)">Detalhes
                            </button>
                        </td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr>
                        <td nzAlign="center">Total</td>
                        <td></td>

                        <td nzAlign="center">{{ totaisEstaduais.empresasCadastradas }}</td>
                        <td nzAlign="center">{{ totaisEstaduais.estimadas }}</td>
                        <td nzAlign="center">{{ totaisEstaduais.cadastradas }}</td>
                        <td></td>
                    </tr>
                    </tfoot>
                </nz-table>
                <nz-divider id="municipal" nzText="Municipal" nzOrientation="left"
                            style="padding: 0 15px; font-weight: bold;"
                            *ngIf="tabAtivaOne === 0"></nz-divider>

                <nz-table #tableMunicipais
                          nzSize="small"
                          [nzData]="previdenciario"
                          [nzFrontPagination]="false"
                          [nzShowPagination]="false"
                          *ngIf="tabAtivaOne === 0"
                          nzBordered
                          style="table-layout:fixed;">
                    <thead>
                    <tr>
                        <th colspan="2"></th>
                        <th colspan="2">Cadastros</th>
                        <th colspan="2">Certidões</th>
                        <th nzAlign="center">
                            <button style="width: 120px" *ngIf="!mostrarSemEmpresa" (click)="exibirTudoEmpresas()"
                                    nz-button nzType="default"
                                    [nz-tooltip]="'Exibir também municipios sem empresas cadastradas'">
                                Exibir Todos
                            </button>
                            <button style="width: 120px" *ngIf="mostrarSemEmpresa" (click)="exibirTudoEmpresas()"
                                    nz-button nzType="default"
                                    [nz-tooltip]="'Exibir somente municipios com empresas cadastradas'">
                                Recolher
                            </button>
                        </th>
                    </tr>
                    <tr>
                        <th #column1 style="text-align: center">UF</th>
                        <th #column2 nzAlign="center"></th>
                        <th #column3 style="text-align: center">Certidões Sugeridas<br>(por empresa)</th>
                        <th #column4 style="text-align: center">Empresas Habilitadas</th>
                        <th #column5 style="text-align: center">Estimada(s)</th>
                        <th #column6 style="text-align: center">Habilitadas</th>
                        <th #column7 style="text-align: center">
                            <span style="width: 120px"></span>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <ng-container class="ant-table-tbody" style="padding: 0; margin: 0"
                                  *ngFor="let item of previdenciario">
                        <tr>
                            <td nzAlign="center"
                                [ngStyle]="{
                                'width': '200px',
                                'min-width': '200px'
                            }">
                                {{ item.uf }}
                            </td>
                            <td style="color: #1e9ff2; width: 30px; padding-left: 15px"
                                [nzExpand]="expandSetPrevidenciario.has(item.uf)"
                                (nzExpandChange)="onExpandChangePrevidenciario(item.uf, $event)"
                            ></td>
                            <td nzAlign="center">{{ item.certidoes_sugeridas }}</td>
                            <td nzAlign="center">{{ item.empresas_cadastradas }}</td>
                            <td nzAlign="center">{{ item.estimadas }}</td>
                            <td nzAlign="center">{{ item.cadastradas }}</td>
                            <td nzAlign="center">
                                <button nz-button nzType="link" (click)="filtrarOrgao('Municipal', item.uf)">
                                    Detalhes
                                </button>
                            </td>
                        </tr>
                        <ng-container *ngIf="item.cnds.length > 0">
                            <tr [nzExpand]="expandSetPrevidenciario.has(item.uf)"
                                *ngFor="let cnd of item.cnds">
                                <td
                                        [ngStyle]="{
                                    'min-width': '242px',
                                    position: 'relative',
                                    padding: '8px',
                                    'text-align': 'right',
                                    'overflow-wrap': 'break-word'
                                    }">
                                    {{ cnd.municipio }}
                                </td>
                                <td [ngStyle]="{
                                    'width': column3Width + 'px',
                                    position: 'relative',
                                    padding: '8px',
                                    'overflow-wrap': 'break-word',
                                    'text-align': 'center'
                                    }">
                                    {{ cnd.cadastros.certidoes_sugeridas }}
                                </td>
                                <td [ngStyle]="{
                                    'width': column4Width + 'px',
                                    position: 'relative',
                                    padding: '8px',
                                    'overflow-wrap': 'break-word',
                                    'text-align': 'center'
                                }">
                                    {{ cnd.cadastros.empresas_cadastradas }}
                                </td>
                                <td [ngStyle]="{
                                    'width': column5Width + 'px',
                                    position: 'relative',
                                    padding: '8px',
                                    'overflow-wrap': 'break-word',
                                    'text-align': 'center'
                                    }">
                                    {{ cnd.certidoes.estimadas }}
                                </td>
                                <td [ngStyle]="{
                                    'width': column6Width + 'px',
                                    position: 'relative',
                                    padding: '8px',
                                    'overflow-wrap': 'break-word',
                                    'text-align': 'center'
                                    }">
                                    {{ cnd.certidoes.cadastradas }}
                                </td>
                                <td [ngStyle]="{
                                    'width': column7Width + 'px',
                                    position: 'relative',
                                    padding: '8px',
                                    'overflow-wrap': 'break-word',
                                    'text-align': 'center'
                                    }"
                                    nzAlign="center">
                                </td>
                            </tr>
                        </ng-container>
                        <ng-container *ngIf="item.cnds.length === 0 || item.cnds === undefined">
                            <tr [nzExpand]="expandSetPrevidenciario.has(item.uf)" style="text-align: center;">
                                Sem Dados
                            </tr>
                        </ng-container>
                    </ng-container>
                    </tbody>
                </nz-table>

                <nz-table #tableObrigacoes
                          nzSize="small"
                          [nzData]="arrayTableObrigacoes"
                          [nzFrontPagination]="false"
                          [nzShowPagination]="false"
                          *ngIf="tabAtivaOne === 1">
                    <thead>
                    <tr>
                        <th nzAlign="center">Certidões</th>
                        <th nzAlign="center">Certidões habilitadas por empresa</th>
                        <th nzAlign="center">Validade</th>
                        <th nzAlign="center">Órgão</th>
                        <th nzAlign="center">UF</th>
                        <th nzAlign="center">Periodicidade de emissão (dias)</th>
                        <th colSpan="2"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let data of tableObrigacoes.data">
                        <td nzAlign="center">
                            <div style="display: flex; align-items: center; justify-content: center;">
                                <span>{{ data.descricao }}</span>
                                <span class="badge-cdn-manual" *ngIf="data.id.length == 36">
                                    CND Manual
                                </span>
                                <span class="badge-cdn-manual" style="white-space: nowrap"
                                      *ngIf="data.habilitado == false">
                                    CND Desabilitada
                                </span>
                            </div>
                        </td>
                        <td nzAlign="center">
                            {{ data.empresas_cadastradas | number:'' :'pt-br' }}
                        </td>

                        <td nzAlign="center">
                            {{ data.validadeDias }}
                        </td>
                        <td nzAlign="center">
                            {{ orgaos[data.orgao] }}
                        </td>
                        <td nzAlign="center">
                            {{ data.uf }}
                        </td>
                        <td nzAlign="center">
                            {{ data.intervaloProcessamento }}
                        </td>

                        <td nzAlign="right">
                            <button nz-button nzType="link" [nzLoading]="loadings.certidaoSelecionada.includes(data.id)"
                                    (click)="editarCertidao(data.id, data.descricao, data.orgao, data.habilitado == 1)">
                                Editar
                            </button>
                        </td>
                        <td nzAlign="left">
                            <button *ngIf="data.id.length == 36" nz-button nzType="link"
                                    (click)="showConfirmRemoverCND(data.id)">Excluir
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </nz-table>


            </nz-card>
            <div #cardWidth></div>
            <div id="cardTabsTwo" #cardTabsTwo>
                <nz-card [ngClass]="[statusTwo ? 'card-fullscreen' : '', !loadingCardTabsTwo ? 'ant-card-table' : '']"
                         [nzLoading]="loadingCardTabsTwo"
                         *ngIf="showTabsTwo"
                         [nzTitle]="extraTitleCertidao"
                         [ngStyle]="{'width': '100%'}"
                         [nzExtra]="cardTabsTwoExtra">
                    <ng-template #extraTitleCertidao>
                        <div style="display: flex; align-items: center; justify-content: start;">
                            <span>{{ certidaoSelecionadaLabel }}
                                <button [nz-tooltip]="'Editar descrição'"
                                        *ngIf="certidaoSelecionadaId.length == 36"
                                        nz-button nzType="link" (click)="modalEditarDescricao(true)"
                                        [nzLoading]="loadings.editarDescricaoManual">
                                    <i nz-icon nzType="edit" nzTheme="outline"> </i>
                                </button>
                            </span>
                            <span class="badge-cdn-manual" *ngIf="certidaoSelecionadaId.length == 36">
                            CND Manual
                        </span>
                        </div>
                    </ng-template>
                    <ng-template #cardTabsTwoExtra>
                        <div class="d-flex align-items-center" *ngIf="loadingAdicionarEmpresas">
                            <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
                            <nz-spin nzSimple [nzIndicator]="indicatorTemplate"></nz-spin>
                            <span class="loading" style="margin-left: 8px;">Cadastrando empresas sugeridas</span>
                        </div>
                        <div class="d-flex align-items-center">
                            <span style="margin-right: 10px;">Habilitada:</span>

                            <nz-switch [(ngModel)]="certidaoSelecionadaObj.habilitado"
                                       (ngModelChange)="alterarHabilitado($event)" nzCheckedChildren="Sim"
                                       nzUnCheckedChildren="Não" [nzLoading]="loadings.editarCertidaoHabilitado"
                            ></nz-switch>
                        </div>
                    </ng-template>
                    <nz-card-tab>
                        <nz-tabset nzSize="large" [(nzSelectedIndex)]="tabAtivaTwo" [nzTabBarExtraContent]="tabsExtra"
                                   (nzSelectChange)="changeTabs($event, 'two')">
                            <nz-tab nzTitle="Cadastro"></nz-tab>
                        </nz-tabset>
                    </nz-card-tab>
                    <nz-alert nzType="info" nzShowIcon [nzMessage]="checkedMessage" style="margin: 15px !important;"
                              *ngIf="checkedItems.size > 0">
                        <ng-template #checkedMessage>
                            <div nz-row nzAlign="middle" nzGutter="24">
                                <div nz-col nzSpan="24">
                                    <strong class="text-primary"></strong>
                                    {{ checkedItems.size }} Registros Selecionados
                                </div>
                            </div>
                        </ng-template>
                    </nz-alert>
                    <nz-table #tableVencimentoOficial
                              [nzData]="arrayTableVencimentoOficial"
                              [nzFrontPagination]="false"
                              *ngIf="tabAtivaTwo == 0"
                              [nzTotal]="pagination.oficiais?.total"
                              [nzPageSize]="pagination.oficiais?.per_page"
                              [nzPageIndex]="pagination.oficiais?.current_page"
                              (nzQueryParams)="retornaEmpresasCertidaoOficiais($event, null, true)"
                              nzBordered
                              [nzShowTotal]="rangeTemplate"
                              class="scrollbar table-small">
                        <thead>
                        <tr>
                            <th nzAlign="center"></th>
                            <th style="text-align: center;">
                                <label nz-checkbox [(ngModel)]="checked"
                                       (nzCheckedChange)="onAllChecked($event)"></label>
                            </th>
                            <th nzAlign="center" nzColumnKey="empresa" [nzSortFn]="true">Empresas</th>
                            <th *ngIf="exibirIM" nzAlign="center" [nzSortFn]="true">IM</th>
                            <th *ngIf="exibirIE" nzAlign="center" [nzSortFn]="true">IE</th>
                            <th *ngIf="exibirII" nzAlign="center" [nzSortFn]="true">II</th>
                            <th nzAlign="center" nzColumnKey="cadastro" [nzSortFn]="true">Cadastro</th>
                            <th nzAlign="center" nzColumnKey="dataInicio" [nzSortFn]="true">Data Início</th>
                            <th nzAlign="center" nzColumnKey="dataFim" [nzSortFn]="true">Data Fim</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let data of tableVencimentoOficial.data">
                            <td nzAlign="center">
                                <a nz-button nzType="text" nzDanger (click)="showConfirm(data.id)">
                                    <i class="far fa-trash-alt"></i>
                                </a>
                            </td>
                            <td nzAlign="center" (nzCheckedChange)="onItemChecked(data.id, $event)"
                                [nzChecked]="checkedItems.has(data.id)"
                                [nzDisabled]="data.disabled">
                            </td>
                            <td>
                                <span style="margin-right: 5px;">{{ modoDemo(data.nome, 'empresaCodigoNome') }}</span>({{ modoDemo(data?.cnpj | cpfCnpj) + ')' }}
                                <nz-tag [nzColor]="'#f50'" *ngIf="!data?.empresa_ativo"
                                        style="margin-left: 10px; font-size: 10px">
                                    Inativo
                                </nz-tag>
                                <nz-tag [nzColor]="'#c5c5c5'" *ngIf="data?.empresaAutomacaoConfiguracao_cnd !== 1"
                                        style="margin-left: 10px; font-size: 10px">
                                    Desabilitado
                                </nz-tag>
                            </td>
                            <td *ngIf="exibirIM" nzAlign="center">
                                <span style="display: block;">{{ data.im }}</span>
                            </td>
                            <td *ngIf="exibirIE" nzAlign="center">
                                <span style="display: block;">{{ data.ie }}</span>
                            </td>
                            <td *ngIf="exibirII" nzAlign="center">
                                <span style="display: block;">{{ data.ii }}</span>
                            </td>
                            <td nzAlign="center">
                                <span style="display: block;">{{ data.created_at | date: 'dd/MM/yyyy HH:mm' }}</span>
                                <small *ngIf="data.usuario_criacao">{{ data.usuario_criacao }}</small>
                            </td>
                            <td nzAlign="center">{{ data.dataInicio | date: 'dd/MM/yyyy' }}</td>
                            <td nzAlign="center">{{ data.dataFim | date: 'dd/MM/yyyy' }}</td>
                            <td nzAlign="center">
                                <button nz-button nzType="link"
                                        (click)="modalCertidaoVencimento(true, data.dataInicio,
                                        data.dataFim, data.empresa_id, data.nome, data.certidao_id, data.id, data.acesso,
                                        data.ie, data.empresaInscricaoImobiliaria_id, data.ii, data.im)">
                                    Editar
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </nz-table>
                    <ng-template #rangeTemplate let-range="range" let-total>
                        {{ range[0] }}-{{ range[1] }} de {{ total }} Registros
                    </ng-template>
                </nz-card>
            </div>
        </div>
    </div>
</nz-content>
<ng-template #tabsOneExtra>
    <div class="d-flex align-items-center">
        <div class="d-flex align-items-center">
            <span style="margin-left: 8px; margin-right: 10px;">
                {{ dataProcessamento ? 'Atualizado em ' + (dataProcessamento | date: 'dd/MM/yy HH:mm') : '' }}
            </span>
        </div>
        <button nz-button nzType="default"
                style="margin-right: 10px; padding-left: 8px; padding-right: 8px;"
                (click)="retornaContadoresOrgao();" title="Atualizar"
                *ngIf="tabAtivaOne === 0">
            <i nz-icon nzType="reload"></i>
        </button>
        <button nz-button [nzType]="'primary'" (click)="modalNovaCertidao(true)"
                style="margin-right: 10px;"
                *ngIf="tabAtivaOne === 0">
            Cadastrar Certidão Manual
        </button>
        <button nz-button [nzType]="'primary'" (click)="modalCadastrarEmMassa(true)"
                [nzLoading]="loadingSugeridasMassa"
                *ngIf="tabAtivaOne === 0">
            {{ loadingSugeridasMassa ? 'Carregando...' : 'Cadastrar Certidões Sugeridas' }}
        </button>
        <div class="d-flex align-items-center" *ngIf="tabAtivaOne === 1 && this.filtroObrigacoes.orgao">
            <span style="margin-left: 8px; margin-right: 10px;">
                Órgão: {{ this.filtroObrigacoes.orgao }} {{ this.filtroObrigacoes.fonte ? ' | ' + this.filtroObrigacoes.fonte : '' }}
            </span>
        </div>
        <button nz-button [nzType]="'default'" (click)="limparFiltros()"
                *ngIf="tabAtivaOne === 1 && this.filtroObrigacoes.orgao">
            Exibir Todos
        </button>
    </div>
</ng-template>
<ng-template #tabsExtra>
    <div class="d-flex align-items-center">
        <nz-badge [nzCount]="qtdFiltrosAtivos"
                  [nzStyle]="{ backgroundColor: '#52c41a', 'margin-right' : '10px'}">
            <button nz-button nzType="primary"
                    style="margin-right: 10px;"
                    (click)="modalFiltrar(true);">
                <i nz-icon nzType="search"></i>
                Filtros
            </button>
        </nz-badge>
        <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" style="margin-right: 10px;">
            <input (keydown.enter)="retornaEmpresasCertidaoOficiais(currentParams.oficiais, currentSearch.oficiais);"
                   type="text" nz-input placeholder="Busca" [(ngModel)]="currentSearch.oficiais">
        </nz-input-group>
        <ng-template #suffixIconButton>
            <button nz-button nzType="primary" nzSearch
                    (click)="retornaEmpresasCertidaoOficiais(currentParams.oficiais, currentSearch.oficiais);">
                <i nz-icon nzType="search"></i></button>
        </ng-template>
        <button nz-button [nzType]="'primary'" style="margin-right: 5px;" (click)="modalCertidaoVencimento(true)">
            Adicionar
        </button>
        <button [nzDropdownMenu]="menuAcoes" style="margin-right: 5px;" class="centralized-icon" nz-button nz-dropdown
                nzType="default">
            Opções
            <em nz-icon nzType="down"></em>
        </button>
        <nz-dropdown-menu #menuAcoes="nzDropdownMenu">
            <ul nz-menu>
                <li nz-submenu nzTitle="Manutenção Coletiva">
                    <ul>
                        <li nz-menu-item nzDisabled>Ações</li>
                        <li nz-menu-divider></li>
                        <li nz-menu-item (click)="checkedItems?.size > 0 && showConfirm(null)"
                            [nzDisabled]="checkedItems?.size === 0">Remover Registros
                        </li>
                        <li nz-submenu nzTitle="Editar Registros">
                            <ul>
                                <li nz-menu-item nzDisabled>Ações</li>
                                <li nz-menu-divider></li>
                                <li nz-menu-item (click)="checkedItems?.size > 0 && modalEditarCndMassa(true)"
                                    [nzDisabled]="checkedItems?.size === 0">Editar Data Inicio ou Fim
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li nz-menu-item nz-button (click)="showConfirmAdicionarCertidoes()">Adicionar Certidões Sugeridas</li>
            </ul>
        </nz-dropdown-menu>
        <button nz-button [nzType]="'default'" (click)="reloadCardTwo()"
                style="padding-left: 8px; padding-right: 8px; margin-right: 5px;">
            <i class="fas fa-redo-alt"></i>
        </button>
        <button nz-button [nzType]="'default'" (click)="clickEvent('two')"
                style="padding-left: 8px; padding-right: 8px;">
            <i class="fas" [ngClass]="!statusTwo ? 'fa-expand' : 'fa-compress'"></i>
        </button>
    </div>
</ng-template>
<nz-modal [(nzVisible)]="formCertidaoVencimento.modalVisible"
          [nzTitle]="modalEmpresaLabel" [nzClosable]="true"
          (nzOnCancel)="modalCertidaoVencimento(false)"
          [nzWidth]="750">
    <nz-row *nzModalContent>
        <nz-col nzSpan="24">
            <form nz-form [nzNoColon]="true" [formGroup]="formCertidaoVencimento.formGroup"
                  *ngIf="formCertidaoVencimento.modalVisible">
                <nz-form-item *ngIf="!modalEmpresaEditar">
                    <nz-form-label [nzSpan]="6" nzRequired>Empresa</nz-form-label>
                    <nz-form-control [nzSpan]="16">
                        <nz-select nzShowSearch
                                   nzPlaceHolder="Selecione"
                                   formControlName="empresa_id"
                                   (ngModelChange)="changeSelectCnds($event)">
                            <nz-option *ngFor="let opt of arraySelectEmpresa"
                                       [nzLabel]="modoDemo(opt.descricao, 'empresaCodigoNome') + ' (' + (modoDemo(opt.cnpj | cpfCnpj, 'cnpj')) + ')'"
                                       [nzValue]="opt.id"
                            ></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item *ngIf="modalEmpresaEditar">
                    <nz-form-label [nzSpan]="6" nzRequired>Empresa:</nz-form-label>
                    <nz-form-control [nzSpan]="16">
                        <input formControlName="empresa_id" type="hidden" [readonly]="modalEmpresaEditar">
                        <span>{{ modoDemo(empresaNomeEditar, 'empresaCodigoNome') }}</span>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item *ngIf="exibirIE && !modalEmpresaEditar">
                    <nz-form-label [nzSpan]="6">IE</nz-form-label>
                    <nz-form-control [nzSpan]="16">
                        <nz-select nzShowSearch
                                   nzPlaceHolder="Selecione"
                                   formControlName="ie">
                            <nz-option *ngFor="let opt of arraySelectIes"
                                       [nzLabel]="opt.ie"
                                       [nzValue]="opt.ie"
                            ></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item *ngIf="exibirIM && !modalEmpresaEditar">
                    <nz-form-label [nzSpan]="6">IM</nz-form-label>
                    <nz-form-control [nzSpan]="16">
                        <nz-select nzShowSearch
                                   nzPlaceHolder="Selecione"
                                   formControlName="empresaInscricaoMunicipal_id">
                            <nz-option *ngFor="let opt of arraySelectIms"
                                       [nzLabel]="opt.im"
                                       [nzValue]="opt.id"
                            ></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item *ngIf="exibirII && !modalEmpresaEditar">
                    <nz-form-label [nzSpan]="6" nzRequired>II</nz-form-label>
                    <nz-form-control [nzSpan]="16">
                        <nz-select nzShowSearch
                                   nzPlaceHolder="Selecione"
                                   formControlName="empresaInscricaoImobiliaria_id">
                            <nz-option *ngFor="let opt of arraySelectIis"
                                       [nzLabel]="opt.ii"
                                       [nzValue]="opt.empresaInscricaoImobiliaria_id"
                            ></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item *ngIf="exibirIE && modalEmpresaEditar">
                    <nz-form-label [nzSpan]="6" nzRequired>IE:</nz-form-label>
                    <nz-form-control [nzSpan]="16">
                        <input formControlName="ie" type="hidden" [readonly]="modalEmpresaEditar">
                        <span>{{ empresaIeEditar }}</span>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item *ngIf="exibirIM && modalEmpresaEditar">
                    <nz-form-label [nzSpan]="6" nzRequired>IM:</nz-form-label>
                    <nz-form-control [nzSpan]="16">
                        <input formControlName="empresaInscricaoMunicipal_id" type="hidden" [readonly]="modalEmpresaEditar">
                        <span>{{ empresaImEditar }}</span>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item *ngIf="exibirII && modalEmpresaEditar">
                    <nz-form-label [nzSpan]="6" nzRequired>II:</nz-form-label>
                    <nz-form-control [nzSpan]="16">
                        <input formControlName="empresaInscricaoImobiliaria_id" type="hidden"
                               [readonly]="modalEmpresaEditar">
                        <span>{{ empresaIiEditar }}</span>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSpan]="6" nzRequired>Data Início</nz-form-label>
                    <nz-form-control [nzSpan]="16">
                        <nz-date-picker (keyup)="maskNZDatePicker($event)" style="width: 100%;"
                                        formControlName="dataInicio"
                                        [nzDisabledDate]="disabledDate"
                                        nzFormat="dd/MM/yyyy"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="6">Data Fim</nz-form-label>
                    <nz-form-control [nzSpan]="16">
                        <nz-date-picker (keyup)="maskNZDatePicker($event)" style="width: 100%;"
                                        formControlName="dataFim"
                                        [nzDisabledDate]="disabledDate"
                                        nzFormat="dd/MM/yyyy"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
                <!--<nz-form-item *ngIf="modalEmpresaEditar">
                    <nz-form-label [nzSm]="6">Acesso</nz-form-label>
                    <nz-form-control [nzSm]="12">
                        <input nz-input formControlName="acesso" id="acesso"/>
                    </nz-form-control>
                </nz-form-item>-->
            </form>
        </nz-col>
    </nz-row>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalCertidaoVencimento(false)">Cancelar</button>
        <button [nzLoading]="loadings.novaCertidao" nz-button nzType="primary" *ngIf="!modalEmpresaEditar"
                (click)="insertEmpresas()">Adicionar
        </button>
        <button [nzLoading]="loadings.editarCertidao" nz-button nzType="primary" *ngIf="modalEmpresaEditar"
                (click)="editarVencimentos()">Editar
        </button>
    </div>
</nz-modal>
<nz-modal [(nzVisible)]="formCadastrarEmMassa.modalVisible"
          [nzTitle]="'Cadastrar Empresas Sugeridas em Massa'" [nzClosable]="true"
          (nzOnCancel)="modalCadastrarEmMassa(false);"
          [nzWidth]="648">
    <nz-row *nzModalContent>
        <nz-col nzSpan="24">
            <form nz-form [nzNoColon]="true" [formGroup]="formCadastrarEmMassa.formGroup" style="width: 100%">
                <nz-form-item>
                    <nz-form-label [nzSpan]="6">Empresa</nz-form-label>
                    <nz-form-control [nzSpan]="12">
                        <nz-select nzShowSearch
                                   nzPlaceHolder="Selecione"
                                   formControlName="empresa_id"
                                   (ngModelChange)="changeSelectCnds($event)">
                            <nz-option [nzLabel]="'Todas'"
                                       [nzValue]="'todas'"></nz-option>
                            <nz-option *ngFor="let opt of arraySelectEmpresa"
                                       [nzLabel]="modoDemo(opt.descricao, 'empresaCodigoNome') + ' (' + modoDemo(opt.cnpj | cpfCnpj, 'cnpj') + ')'"
                                       [nzValue]="opt.id"
                            ></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item style="margin-bottom: 0px;" *ngIf="mostrarCndsSugeridas">
                    <nz-form-label [nzSpan]="12" [nzOffset]="6" style="text-align: center; font-weight: bold;">
                        Certidões
                    </nz-form-label>
                </nz-form-item>
                <nz-form-item style="margin-bottom: 0px;" *ngIf="mostrarCndsSugeridas">
                    <nz-form-label [nzSpan]="10" style="text-align: center">Não selecionadas</nz-form-label>
                    <nz-form-label [nzSpan]="10" [nzOffset]="4" style="text-align: center">Selecionadas</nz-form-label>
                </nz-form-item>
                <nz-form-item *ngIf="mostrarCndsSugeridas">
                    <nz-form-control [nzSpan]="10">
                        <cdk-virtual-scroll-viewport itemSize="73" class="demo-infinite-container">
                            <nz-list nzBordered nzSize="small" style="border: none;"
                                     [nzLoading]="loadingCndsCadastrarSugeridas">
                                <nz-list-item *ngFor="let item of listaNaoSelecionadas"
                                              (click)="clickNaoSelecionado(item.certidao_id);"
                                              [class.itemSelected]="selectedNaoSeleciodo.includes(item.certidao_id)"
                                >
                                    {{ item.certidao_id }}
                                </nz-list-item>
                            </nz-list>
                        </cdk-virtual-scroll-viewport>
                    </nz-form-control>
                    <nz-form-control [nzSpan]="4" style="text-align: center; display: inline-block">
                        <button nzTooltipTitle="Enviar" nz-tooltip nzTooltipPlacement="top"
                                nz-button nzType="link" nzBlock nzSize="small" class="botoesAcoesList"
                                (click)="acaoDobleList('right');">
                            <i nz-icon nzType="right"></i>
                        </button>
                        <button nzTooltipTitle="Enviar todas" nz-tooltip nzTooltipPlacement="top"
                                nz-button nzType="link" nzBlock nzSize="small" class="botoesAcoesList"
                                (click)="acaoDobleList('double-right')">
                            <i nz-icon nzType="double-right"></i>
                        </button>
                        <button nzTooltipTitle="Remover" nz-tooltip nzTooltipPlacement="top"
                                nz-button nzType="link" nzBlock nzSize="small" class="botoesAcoesList"
                                (click)="acaoDobleList('left');">
                            <i nz-icon nzType="left"></i>
                        </button>
                        <button nzTooltipTitle="Remover todas" nz-tooltip nzTooltipPlacement="top"
                                nz-button nzType="link" nzBlock nzSize="small" class="botoesAcoesList"
                                (click)="acaoDobleList('double-left')">
                            <i nz-icon nzType="double-left"></i>
                        </button>
                    </nz-form-control>
                    <nz-form-control [nzSpan]="10">
                        <cdk-virtual-scroll-viewport itemSize="73" class="demo-infinite-container">
                            <nz-list nzBordered nzSize="small" style="border: none;">
                                <nz-list-item *ngFor="let item of listaSimSelecionadas"
                                              (click)="clickSimSelecionado(item.certidao_id);"
                                              [class.itemSelected]="selectedSimSeleciodo.includes(item.certidao_id)">
                                    {{ item.certidao_id }}
                                </nz-list-item>
                            </nz-list>
                        </cdk-virtual-scroll-viewport>
                    </nz-form-control>
                </nz-form-item>
            </form>
        </nz-col>
    </nz-row>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalCadastrarEmMassa(false);">Cancelar</button>
        <button nz-button nzType="primary" (click)="cadastrarSugeridasMassa()">Adicionar</button>
    </div>
</nz-modal>
<nz-modal [(nzVisible)]="formEditarDatas.modalVisible"
          [nzTitle]="'Editar Datas'" [nzClosable]="true"
          (nzOnCancel)="modalEditarCndMassa(false)"
          [nzWidth]="648">
    <nz-row *nzModalContent>
        <nz-col nzSpan="24">
            <form nz-form [nzNoColon]="true" [formGroup]="formEditarDatas.formGroup"
                  *ngIf="formEditarDatas.modalVisible">
                <nz-form-item>
                    <nz-form-label [nzSpan]="6">Data Início</nz-form-label>
                    <nz-form-control [nzSpan]="12">
                        <nz-date-picker (keyup)="maskNZDatePicker($event)"
                                        style="width: 100%;"
                                        formControlName="dataInicio"
                                        [nzDisabledDate]="disabledDate"
                                        nzFormat="dd/MM/yyyy"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="6">Data Fim</nz-form-label>
                    <nz-form-control [nzSpan]="12">
                        <nz-date-picker (keyup)="maskNZDatePicker($event)"
                                        style="width: 100%;"
                                        formControlName="dataFim"
                                        [nzDisabledDate]="disabledDate"
                                        nzFormat="dd/MM/yyyy"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
            </form>
        </nz-col>
    </nz-row>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalEditarCndMassa(false)">Cancelar</button>
        <button nz-button nzType="primary" (click)="editarVencimentosMassa()">Editar</button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formCadastrar.modalVisible"
          [nzTitle]="'Cadastrar Certidão'" [nzClosable]="true"
          (nzOnCancel)="modalCadastrar(false);"
          [nzWidth]="748">
    <nz-row *nzModalContent>
        <nz-col nzSpan="24">
            <form nz-form [nzNoColon]="true" [formGroup]="formCadastrar.formGroup" style="width: 100%">
                <nz-form-item style="margin-bottom: 0;">
                    <nz-form-label [nzSpan]="4" nzRequired>Certidão</nz-form-label>
                    <nz-form-control [nzSpan]="16"
                                     nzErrorTip="Campo Obrigatório">
                        <nz-select nzShowSearch
                                   nzPlaceHolder="Selecione"
                                   formControlName="certidao_id">
                            <nz-option *ngFor="let opt of optionsCertidoes"
                                       [nzLabel]="opt.descricao"
                                       [nzValue]="opt.id">
                            </nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item style="margin-top: 0; margin-bottom: 15px; text-align: right;">
                    <nz-form-control [nzSpan]="16" [nzOffset]="4" style="text-align: right;">
                        <button class="new-markup-bnt" nz-button nzType="link"
                                (click)="modalNovaCertidao(true)"
                                style="float: right; padding-right: 0">
                            <em nz-icon nzType="plus-circle"></em>Nova Certidão
                        </button>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="4" nzRequired>Empresa</nz-form-label>
                    <nz-form-control [nzSpan]="16"
                                     nzErrorTip="Campo Obrigatório">
                        <nz-select nzShowSearch
                                   nzPlaceHolder="Selecione"
                                   formControlName="empresa_id">
                            <nz-option *ngFor="let opt of optionsEmpresas"
                                       [nzLabel]="opt.nome + ' (' + (opt.cnpj | cpfCnpj) + ')'"
                                       [nzValue]="opt.id"
                                       nzCustomContent>
                                {{ opt.nome }}
                                <br><small>({{ opt.cnpj | cpfCnpj }})</small>
                            </nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="4" nzRequired>Data Início</nz-form-label>
                    <nz-form-control [nzSpan]="16"
                                     nzErrorTip="Campo Obrigatório">
                        <nz-date-picker (keyup)="maskNZDatePicker($event)" style="width: 100%;"
                                        formControlName="dataInicio"
                                        [nzDisabledDate]="disabledDate"
                                        nzFormat="dd/MM/yyyy"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="4">Data Fim</nz-form-label>
                    <nz-form-control [nzSpan]="16">
                        <nz-date-picker (keyup)="maskNZDatePicker($event)" style="width: 100%;"
                                        formControlName="dataFim"
                                        [nzDisabledDate]="disabledDate"
                                        nzFormat="dd/MM/yyyy"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
            </form>
        </nz-col>
    </nz-row>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalCadastrar(false);">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="loadings.cadastrarCertidaoUnica"
                (click)="cadastrarCertidaoEmpresa()">Adicionar
        </button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formNovaCertidao.modalVisible"
          [nzTitle]="'Nova Certidão'" [nzClosable]="true"
          (nzOnCancel)="modalNovaCertidao(false);"
          [nzWidth]="648">
    <nz-row *nzModalContent>
        <nz-col nzSpan="24">
            <form nz-form [nzNoColon]="true" [formGroup]="formNovaCertidao.formGroup" style="width: 100%">
                <nz-form-item>
                    <nz-form-label [nzSpan]="8" nzRequired>Descrição</nz-form-label>
                    <nz-form-control [nzSpan]="12"
                                     nzErrorTip="Campo Obrigatório">
                        <input nz-input type="text" formControlName="descricao">
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="8" nzRequired>Órgão</nz-form-label>
                    <nz-form-control [nzSpan]="12" style="margin-top: -10px;"
                                     nzErrorTip="Campo Obrigatório">
                        <nz-radio-group formControlName="orgao" [(ngModel)]="radioValueOrgao"
                                        (ngModelChange)="toggleOrgao($event)">
                            <label nz-radio nzValue="Federal">Federal</label>
                            <label nz-radio nzValue="Estadual">Estadual</label>
                            <label nz-radio nzValue="Municipal">Municipal</label>
                        </nz-radio-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item *ngIf="radioValueOrgao === 'Federal'">
                    <nz-form-label [nzSpan]="8" nzRequired>Matriz</nz-form-label>
                    <nz-form-control [nzSpan]="12"
                                     nzErrorTip="Campo Obrigatório">
                        <nz-radio-group formControlName="somenteMatriz" [(ngModel)]="apenasUnidadesMatriz">
                            <label nz-radio nzValue="1">Sim</label>
                            <label nz-radio nzValue="0">Não</label>
                        </nz-radio-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item *ngIf="radioValueOrgao === 'Estadual'">
                    <nz-form-label [nzSpan]="8" nzRequired>UF</nz-form-label>
                    <nz-form-control [nzSpan]="12"
                                     nzErrorTip="Campo Obrigatório">
                        <nz-select formControlName="uf" nzShowSearch>

                            <nz-option *ngFor="let opt of comboUFs"
                                       [nzLabel]="opt.label"
                                       [nzValue]="opt.key"
                            >
                            </nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item *ngIf="radioValueOrgao === 'Municipal' || radioValueOrgao === 'Imobiliaria'">
                    <nz-form-label [nzSpan]="8" nzRequired>Código do Município</nz-form-label>
                    <nz-form-control [nzSpan]="12" nzErrorTip="Campo Obrigatório">
                        <nz-select formControlName="municipioCodigo" nzShowSearch>
                            <nz-option *ngFor="let opt of comboTodosMunicipios"
                                       [nzLabel]="opt.label"
                                       [nzValue]="opt.key"
                            >
                            </nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>


            </form>
        </nz-col>
    </nz-row>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalNovaCertidao(false);">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="loadings.novaCertidao" (click)="cadastrarNovaCertidao()">
            Adicionar
        </button>
    </div>
</nz-modal>
<nz-modal [(nzVisible)]="formFiltrar.modalVisible" [nzTitle]="'Filtros Avançados'" [nzClosable]="true"
          (nzOnCancel)="modalFiltrar(false)" [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzDisableAutoTips]="true" [nzNoColon]="true" [formGroup]="formFiltrar.formGroup"
              style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Inativo</nz-form-label>
                <nz-form-control [nzSpan]="14"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.inativo">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="inativo">
                        <nz-option
                                [nzLabel]="'Mostrar somente inativas e desabilitadas'" [nzValue]="'somente'">
                        </nz-option>
                        <nz-option
                                [nzLabel]="'Mostrar também inativas e desabilitadas'" [nzValue]="'tambem'">
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>


        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalFiltrar(false)">Cancelar</button>
        <button nz-button nzType="primary" (click)="confirmaFiltrar()">Confirmar</button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formEditarNomeCertManual.modalVisible"
          [nzTitle]="'Editar Descrição'" [nzClosable]="true"
          (nzOnCancel)="modalEditarDescricao(false);"
          [nzWidth]="550">
    <nz-row *nzModalContent>
        <nz-col nzSpan="24">
            <form nz-form [nzNoColon]="true" [formGroup]="formEditarNomeCertManual.formGroup" style="width: 100%">
                <nz-form-item>
                    <nz-form-label [nzSpan]="6" nzRequired>Descrição</nz-form-label>
                    <nz-form-control [nzSpan]="14"
                                     nzErrorTip="Campo Obrigatório">
                        <input nz-input type="text" formControlName="descricao">
                    </nz-form-control>
                </nz-form-item>
            </form>
        </nz-col>
    </nz-row>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalEditarDescricao(false);">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="loadings.editarDescricaoManual"
                (click)="editarDescricaoCertidaoManual()">
            Salvar
        </button>
    </div>
</nz-modal>
