import {Component} from '@angular/core';
import {VisibilidadeService} from '@services/visibilidade.service';
import {FormBuilder, UntypedFormGroup} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {ComplianceTributosGruposService} from './compliance-tributos-grupos.service';
import {Subscription} from 'rxjs';
import {buildUrl, findComponentByUrl} from '../../../shared/components-helper';
import {Tab} from '@models/tab.model';
import {TabService} from '@services/tab.service';
import {Helpers} from '../../../core/helpers';

interface FormStack {
    modalVisible: boolean;
    formGroup: UntypedFormGroup;
}

@Component({
    selector: 'app-compliance-tributos-grupos',
    templateUrl: './compliance-tributos-grupos.component.html',
    styleUrls: ['./compliance-tributos-grupos.component.scss']
})
export class ComplianceTributosGruposComponent {

    gruposBkp = [];
    grupos = [];

    qtdFiltrosAtivos = 0;
    formFiltrar: FormStack;

    selectedYear = new Date().getFullYear();
    previousYear = this.selectedYear - 1;

    disableArrow = this.selectedYear === new Date().getFullYear();

    visibilidadeOptions: { label: string; value: string }[] = [];

    loadingGrupos = false;
    loadingBt = false;

    firstTime = true;

    requestBt: Subscription;

    anoSelecionado = new Date().getFullYear();

    constructor(
        private tabService: TabService,
        private fb: FormBuilder,
        private service: ComplianceTributosGruposService,
        private visibilidadeService: VisibilidadeService,
        private toastService: ToastrService,
    ) {

        this.formFiltrar = {
            modalVisible: false,
            formGroup: this.fb.group({
                visibilidade_id: [null, null]
            })
        };

    }

    ngOnInit() {
        this.getVisibilidades();
        this.getInfoGrupos();
    }

    getInfoGrupos(directon = 'R', anoAnterior?: number) {

        this.requestBt?.unsubscribe();

        this.loadingGrupos = true;

        const filtros = {
            visibilidade_id: this.formFiltrar.formGroup.get('visibilidade_id').value
        };

        this.requestBt = this.service.getInfoGrupos(anoAnterior || this.selectedYear, filtros).subscribe({
            next: (res) => {
                this.grupos = res;

                this.grupos.forEach(g => {

                    g.anos = [];
                    g.anos.push({
                        qtdAusencias: g.qtdAusencias,
                        qtdDivergencias: g.qtdDivergencias,
                        porcentagem: g.porcentagem
                    });


                    g.iniciais = Helpers.getIniciais(g.descricao);
                    g.backgroundColor = Helpers.stringToColour(g.descricao);

                });

                if (this.gruposBkp.length) {
                    this.gruposBkp.forEach(gBkp => {

                        const ano = this.grupos.find(grupo => grupo.id === gBkp.id)?.anos[0];

                        if (ano) {
                            if (gBkp.anos.length === 2) {
                                directon === 'R' ? gBkp.anos.shift() : gBkp.anos.pop();
                            }
                            directon === 'R' ? gBkp.anos.push(ano) : gBkp.anos.unshift(ano);
                        }

                    });
                } else {
                    this.gruposBkp = this.grupos;
                }

                if (this.firstTime) {
                    this.firstTime = false;
                    this.getInfoGrupos('R', this.previousYear);
                } else {
                    this.loadingBt = false;
                    this.showModalFiltrar(false);
                    this.loadingGrupos = false;
                }

            },
            error: (err) => {

                this.toastService.error(err.error.message);
                this.loadingGrupos = false;
                this.loadingBt = false;

            }
        });

    }

    getVisibilidades() {

        return this.visibilidadeService.listToSelect().subscribe((result) => {
            this.visibilidadeOptions = result.map((option: any) => ({
                label: option.descricao,
                value: option.id
            }));
        });
    }

    clickArrow(direction: 'L' | 'R') {

        this.selectedYear = direction === 'R' ? this.selectedYear - 1 : this.selectedYear + 1;
        this.previousYear = this.selectedYear - 1;

        this.disableArrow = this.selectedYear === new Date().getFullYear();

        this.getInfoGrupos(direction, direction === 'R' ? this.previousYear : this.selectedYear);

    }

    showModalFiltrar(visible: boolean) {
        this.formFiltrar.modalVisible = visible;
    }

    calculaBadgeFiltros(): void {

        this.qtdFiltrosAtivos = 0;

        if (typeof this.formFiltrar !== 'undefined') {
            for (const [chave, valor] of Object.entries(this.formFiltrar.formGroup.value)) {

                if (valor && chave) {
                    this.qtdFiltrosAtivos += 1;
                }
            }
        }

    }

    confirmaFiltrar() {
        this.calculaBadgeFiltros();
        this.loadingBt = true;
        this.getInfoGrupos();
    }

    openTab(path: string, queryParams?: string, data?: {}) {

        const component = findComponentByUrl(path);
        const url = buildUrl(component, queryParams);
        const newTab = new Tab(component.name, component.title, url, component.urlType, data);
        this.tabService.closeAndAddTab(newTab);

    }
}
