import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {map} from 'rxjs/operators';
import {Token} from '../../shared/models/token.model';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import jwtDecode from 'jwt-decode';
import {TabService} from '@services/tab.service';
import {Title} from '@angular/platform-browser';

@Injectable({providedIn: 'root'})
export class AuthenticationService {
    public currentTokenSubject: BehaviorSubject<Token>;
    public currentToken: Observable<Token>;
    returnUrl = null;
    urlSearch = {};

    constructor(
        private http: HttpClient,
        private router: Router,
        private toastr: ToastrService,
        private titleService: Title,
        private tabService: TabService
    ) {
        const mastertaxStorage = JSON.parse(localStorage.getItem('app_mastertax'));
        this.currentTokenSubject = new BehaviorSubject<Token>(mastertaxStorage ? mastertaxStorage : null);
        this.currentToken = this.currentTokenSubject.asObservable();
    }

    public get currentTokenValue(): Token {
        return this.currentTokenSubject.value;
    }

    login(login: string, senha: string, identificador: string, codigoMFA: string = null) {

        const authObj = {
            login,
            senha,
            identificador,
            codigoMFA
        };

        this.returnUrl = localStorage.getItem('returnUrl');

        this.urlSearch = JSON.parse(localStorage.getItem('urlSearch'));


        return this.http.post<any>(`${environment.apiUrl}/auth/login`, authObj).pipe(map(token => {
            // this.store.dispatch(Add(jwtDecode(token.access_token), 'user'));
            if (token.senhaExpirada) {
                return {
                    senhaExpirada: true,
                    token: token.token ? token.token : null,
                    usuario_nome: token.usuario_nome ? token.usuario_nome : null,
                    usuario_email: token.usuario_email ? token.usuario_email : null,
                    usuario_login: token.usuario_login ? token.usuario_login : null,
                };
            }

            if (token.autenticacaoDoisFatores) {
                return {autenticacaoDoisFatores: true, email: token.email ? token.email : null};
            }
            if (token.senhaInvalida) {
                return {
                    senhaInvalida: true,
                    tentativasRestantes: token.tentativasRestantes ? token.tentativasRestantes : null,
                    loginBloqueado: token.loginBloqueado ? token.loginBloqueado : false,
                };
            }
            localStorage.removeItem('app-mastertax');
            localStorage.setItem('app_mastertax', JSON.stringify(token));
            this.currentTokenSubject.next(token);
            return {url: this.returnUrl, search: this.urlSearch};
        }));

    }

    logout(message?: string, redirect = true, click = false) {

        if (this.currentTokenSubject.value) {

            this.http.post<any>(`${environment.apiUrl}/auth/logout`, {}).subscribe(obj => {

                this.closeSession(message, click);

                setTimeout(() => {
                    if (redirect) {
                        this.router.navigate(['/login']);
                    }
                }, 1000);

            }, (error) => {

                if (error.statusText === 'Unauthorized') {

                    if (this.currentTokenSubject.value) {

                        this.closeSession(message, click);

                        this.router.navigate(['/login']);

                    }

                }

            });

        }

    }

    reenviarCodigoMFA(login: string, identificador: string) {

        return this.http.post(`${environment.apiUrl}/auth/reenviar-codigo-two-factor`, {login, identificador});
    }

    queryStringToJSON(queryString) {

        if (queryString.indexOf('?') > -1) {
            queryString = queryString.split('?')[1];
        }

        const pairs = queryString.split('&');
        const result = {};

        pairs.forEach((pair) => {

            pair = pair.split('=');

            if (pair[0] !== window.location.href) {
                result[pair[0]] = decodeURIComponent(pair[1] || '');
            }

        });

        return result;

    }

    closeSession(message, click = false) {

        if (click) {
            this.returnUrl = '/home';
            this.urlSearch = {};

            localStorage.setItem('returnUrl', this.returnUrl);
            localStorage.setItem('urlSearch', JSON.stringify(this.urlSearch));
            localStorage.removeItem('param');

        } else {

            const url: any = window.location.href;
            this.returnUrl = window.location.pathname;

            const index: any = url.indexOf(window.location.pathname);
            let param: any = url.substring(index);
            param = param.split('/');
            param = param[param.length - 1];

            // Para verificar se é um uuid válido
            const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

            if (param.length === 36 && regexExp.test(param)) {
                localStorage.setItem('param', param);
            } else {
                localStorage.removeItem('param');
            }

            this.urlSearch = this.queryStringToJSON(window.location.href);

            localStorage.setItem('returnUrl', this.returnUrl);
            localStorage.setItem('urlSearch', JSON.stringify(this.urlSearch));

        }

        localStorage.removeItem('app_mastertax');
        localStorage.removeItem('identificador');
        localStorage.removeItem('conta_nome');
        // this.titleService.setTitle(environment.applicationName);
        this.currentTokenSubject.next(null);
        this.tabService.closeAll();
        this.toastr.success(click ? 'Deslogado com sucesso!' : 'Sessão expirada.', message);

    }

    isLogged = () => {
        const mastertaxStorage = JSON.parse(localStorage.getItem('app_mastertax'));
        return mastertaxStorage && mastertaxStorage.access_token != null;
    }

    decodePayloadJWT() {
        try {
            return jwtDecode(this.currentTokenValue.access_token);
        } catch (error) {
            return null;
        }
    }

    resetPassword(email: string, identificador: string = null) {
        return this.http.post(`${environment.apiUrl}/auth/reset-password`, {email, identificador});
    }

    changePassword(email: string, passwordToken: string, password: string, identificador: string = null) {
        return this.http.post(`${environment.apiUrl}/auth/change-password`, {
            email,
            passwordToken,
            password,
            identificador
        });
    }

    resolve() {
        this.logout();
        this.router.navigate(['change-password']);
    }

    getIdentificadorByUrl(urlIn: string) {

        let url = urlIn.replace('https://', '');

        url = url.replace('http://', '');

        if (url.search('mastertax.app') !== -1 || url.search('mastertax.local') !== -1) {

            const arr = url.split('.');

            if (arr[0] !== 'mastertax' && arr[0] !== 'app' && arr[0] !== 'dev' && arr[0] !== 'test') {
                return arr[0];
            }

        }

        return null;

    }

    getAmbienteByUrl(urlIn: string): string | null {

        let url = urlIn.replace('https://', '');

        url = url.replace('http://', '');


        const arr = url.split('.');

        if (arr[1] === 'dev' || arr[1] === 'rc') {
            return arr[1];
        }


        return null;

    }

    getLogoByIdentificador(identificador: string): Observable<any> {

        return this.http.get(`${environment.apiUrl}/agent/logotipo/${identificador}`);

    }

    getContaNomeByIdentificador(identificador: string): Observable<any> {

        return this.http.get(`${environment.apiUrl}/agent/contaNome/${identificador}`);

    }

    loginSSO(dados: { email: string, token: string, identificador: string }): Observable<any> {
        return this.http.post(`${environment.apiUrl}/auth/login/console/token`, dados);
    }
}
