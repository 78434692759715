import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {Helpers} from '../../helpers';
import {HttpClient} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class CamposPreviewArquivoServiceService {

    constructor(protected http: HttpClient) {
    }

    gravarArquivo(arquivoId, formData: any): Observable<any> {

        return this.http.post(`${environment.apiUrl}/inbox/dashboard/anexos/gravar/${arquivoId}`, formData);

    }

    retornaArquivo(id): Observable<any> {
        return this.http.get(`${environment.apiUrl}/inbox/dashboard/anexos/detalhe/${id}`);
    }

}
