import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {Tab} from '@models/tab.model';
import {TabService} from '@services/tab.service';
import {buildUrl, findComponentByUrl} from '../../../shared/components-helper';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import {Response} from '@models/response.model';
import Visibilidade from '@models/visibilidade.model';
import {Perfil} from '@models/perfil.model';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {Pagination} from '@models/pagination.model';
import {NzModalService} from 'ng-zorro-antd/modal';
import {ConciliacoesDetalhesService} from './conciliacoesDetalhes.service';
import {environment} from '../../../../environments/environment';
import * as fileSaver from 'file-saver-es';
import {DataService} from '@services/data.service';
import {AppComponent} from '../../../app.component';
import {Helpers} from '../../../core/helpers';
import {Subscription} from 'rxjs';


interface FormStack {
    modalVisible: boolean;
    formGroup: UntypedFormGroup;
}

@Component({
    selector: 'app-usuarios',
    templateUrl: './conciliacoesDetalhes.component.html',
    styleUrls: ['./conciliacoesDetalhes.component.scss']
})
export class ConciliacoesDetalhesComponent extends AbstractListTable<null> implements OnInit, AfterViewInit {

    readonly registerLink = '/usuarios/cadastrar';

    itemsRegras: any[];
    itemsEmpresas: any[];

    loadingRegras = true;
    loadingEmpresas = true;

    dataProfileForm: Perfil[];
    displayProfileModal = false;
    selectedProfile;

    newVisibilidadeModal = false;
    displayVisibilidadeModal = false;
    equipeOptions: { label: string; value: string }[] = [];
    limparEquipes = false;
    newEquipe = '';
    dataVisibilityForm: Response<Visibilidade>;

    token;
    exportUrl;

    checked = false;

    modalFiltrarVisible = false;
    modalPdfVisible = false;
    formFiltrar: UntypedFormGroup;
    cadastrando = false;

    expandSet = new Set<number>();

    column1: ElementRef;
    column1Width = 0;
    column2Width = 0;
    column3Width = 0;
    column4Width = 0;
    column5Width = 0;

    loadingContadores = true;

    dadosCabecalho: any = {};

    formFiltrosCabecalho: FormStack;
    formFiltrosGrid: FormStack;
    formLinha: FormStack;
    filtroEmpresaID: '';

    anoAtivo = 0;
    mesAtivo = 0;

    backgroundColor;
    miniLogoUrl;
    highlightColor;
    verifyThemeCount = 0;

    arrayAno = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai',
        'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];

    arraySelectObrigacoes = [];
    arraySelectCategorias = [];
    arraySelectEmpresa = [];
    arraySelectCnpj = [];
    arraySelectUsuarios = [];
    arraySelectGrupos = [];

    qtdFiltrosAtivos = 0;
    qtdFiltrosCabecalho = 0;

    @Input() data: any;

    situacao = null;

    tabAtiva = 0;
    linhaSelecionada;
    linhaSelecionadaVisible = {};

    currentUser;

    formCadastrarAprovacao: FormStack;
    screenWidth = 0;

    arquivoUrl: string;

    loadings = {
        pdf: false,
        zip: false,
        download: []
    };

    currentParams = {
        pageIndex: 1,
        pageSize: 50,
        sort: [],
        filter: []
    };

    pdfSelecionado: any;
    pdfSelecionadoNome = '';

    modalDetalhes = false;
    arrayResumoCFOP = [];
    arrayApuracaoICMS = [];

    tabDetalhesAtiva = 0;

    statusOne = false;
    apuracaoICMSLoading = false;
    resumoCFOPLoading = false;

    loadingConciliacoes = false;
    loadingValidacoes = false;

    pagination: any = [{
        regras: Pagination,
        empresas: Pagination,
        demaisColunasRegras: Pagination
    }];

    disabledProximaPagina = false;
    disabledProximaPaginaDetalhe = false;

    tipo: any = null;

    requestBt: Subscription;

    loadingCadastrandoAprovacao = false;

    arquivoSelecionado = null;
    demaisColunasVisible = false;
    loadingDemaisColunas = null;
    demaisColunas = [];
    boolPodeLiberar: boolean = false;

    constructor(
        private fb: UntypedFormBuilder,
        private service: ConciliacoesDetalhesService,
        private toastService: ToastrService,
        private cdr: ChangeDetectorRef,
        private modalService: NzModalService,
        private tabService: TabService,
        private dataService: DataService) {
        super(service, null, toastService);

        this.resizeTable();

        this.pagination.regras = new Pagination();
        this.pagination.empresas = new Pagination();
        this.pagination.demaisColunasVisible = new Pagination();

        this.formFiltrosCabecalho = {
            modalVisible: false,
            formGroup: this.fb.group({
                vencimento: [null, null],
                obrigacao: [null, null],
                categoria: [null, null],
            })
        };

        this.formFiltrosGrid = {
            modalVisible: false,
            formGroup: this.fb.group({
                grupo: [null, null],
                empresa_id: [null, null],
                competencia: [null, null],
                classificacao: [null, null],
                status: [null, null],
                liberacao: [null, null],
                tipo: [null, null],
            })
        };

        this.formCadastrarAprovacao = {
            modalVisible: false,
            formGroup: this.fb.group({
                usuario_id: [null, Validators.required],
                obrigacaoEmpresa_id: [[], null],
            })
        };

        this.formLinha = {
            modalVisible: false,
            formGroup: null
        };

        this.service.retornarSelectsEmpresas().subscribe((retorno: any) => {
            this.arraySelectEmpresa = retorno.empresaNome;
            this.arraySelectCnpj = retorno.empresaCnpj;
        });

        this.service.retornaselectsGrupos().subscribe((retorno: any) => {
            this.arraySelectGrupos = retorno.grupoEmpresarial;
        });

        this.service.retornaSelectsUsuarios().subscribe((retorno: any) => {
            this.arraySelectUsuarios = retorno;
        });

    }

    @HostListener('window:resize', ['$event'])
    resizeTable() {
        this.dispachResizeTable();
    }

    ngOnInit() {

        if (this.data.tabAtiva) {
            this.tabAtiva = this.data.tabAtiva;
        }

        if (this.data.categoria) {
            this.formFiltrosCabecalho.formGroup.get('categoria').setValue(this.data.categoria);
        }

        if (this.data.vencimento) {
            this.formFiltrosCabecalho.formGroup.get('vencimento').setValue(this.data.vencimento);
        }

        if (this.data.classificacao) {
            this.formFiltrosGrid.formGroup.get('classificacao').setValue(this.data.classificacao);
            this.tabAtiva = this.data.classificacao === 'G' ? 1 : this.data.classificacao === 'M' ?
                2 : 3;
        }

        this.decodeSearch([this.formFiltrosCabecalho.formGroup, this.formFiltrosGrid.formGroup]);
    }

    ngAfterViewInit() {

        this.dataService.currentUser.subscribe((data) => {
            this.currentUser = data;
            this.cdr.detectChanges();
        });

        this.dispachResizeTable();

    }

    dispachResizeTable() {
        this.screenWidth = Math.round(window.innerWidth - 70);
    }

    decodeSearch(forms) {

        this.retornaSelectObrigacoes(() => {

            forms.forEach((value) => {

                const urlParams: any = this.queryStringToJSON(window.location.href);

                const haveUrlParams = Object.entries(urlParams).length > 0;

                if (urlParams.competencia) {
                    const competencia = urlParams.competencia.split('-');
                    urlParams.competencia = new Date(competencia[0], Number(competencia[1]) - 1);
                }

                if (urlParams.vencimento) {
                    const vencimento = urlParams.vencimento.split('-');
                    urlParams.vencimento = new Date(vencimento[0], Number(vencimento[1]) - 1);
                }

                if (haveUrlParams) {
                    this.resolveParams(urlParams, value);
                }

                const dataParams: any = Object.entries(this.data).filter((element) => {
                    return element[0] !== 'link' && element[0] !== 'extra';
                });

                if (this.data.competencia && Object.prototype.toString.call(this.data.competencia) !== '[object Date]') {
                    const competencia = this.data.competencia.split('-');
                    this.data.competencia = new Date(competencia[0], Number(competencia[1]) - 1);
                }

                if (this.data.vencimento && Object.prototype.toString.call(this.data.vencimento) !== '[object Date]') {
                    const vencimento = this.data.vencimento.split('-');
                    this.data.vencimento = new Date(vencimento[0], Number(vencimento[1]) - 1);
                }

                const haveDataParams = dataParams.length > 0;

                if (haveDataParams) {
                    this.resolveParams(this.data, value);
                }

                if (!haveUrlParams && !haveDataParams) {

                    const params = {
                        vencimento: new Date(),
                        categoria: this.arraySelectCategorias[0].id
                    };

                    this.resolveParams(params, value);

                }

            });

            this.carregaCabecalho();

        });

    }

    onAllEmpresasChecked(checked: boolean): void {

        this.itemsEmpresas.forEach(item => {
            this.updateCheckedSet(item.id, checked);
        });

    }

    onAllChecked(checked: boolean): void {

        this.itemsRegras.forEach(item => {
            this.updateCheckedSet(item.id, checked);
        });

    }

    resolveParams(params, form) {

        Object.entries(params).forEach((value: any) => {

            const input = form.get(value[0]);

            if (input) {
                form.get(value[0]).setValue(value[1]);
            }

        });

    }

    queryStringToJSON(queryString) {

        if (queryString.indexOf('?') > -1) {
            queryString = queryString.split('?')[1];
        }

        const pairs = queryString.split('&');
        const result = {};

        pairs.forEach((pair) => {

            pair = pair.split('=');

            if (pair[0] !== window.location.href) {
                result[pair[0]] = decodeURIComponent(pair[1] || '');
            }

        });

        return result;
    }

    retornaSelectObrigacoes(callback) {

        this.service.retornarSelectsObrigacoes().subscribe((retorno: any) => {

            this.arraySelectObrigacoes = retorno.obrigacoes;
            this.arraySelectCategorias = retorno.categorias;

            callback();

        });

    }

    openTab(componentName: string, queryParams?: string, data?: {}) {
        const component = findComponentByUrl(componentName);
        const url = buildUrl(component, queryParams);
        const newTab = new Tab(component.name, component.title, url, component.urlType, data);
        this.tabService.closeAndAddTab(newTab);
    }

    listByTableRegras(params: NzTableQueryParams) {
        let buscar = false;

        if (params.pageIndex === this.pagination.regras.current_page) {
            params.sort.forEach(s => {
                if (s.value) {
                    buscar = true;
                }
            });
        }

        if ((buscar || params.pageIndex === 1 && this.pagination.regras.current_page > 1)
            || params.pageIndex > 1
            && params.pageIndex !== this.pagination.regras.current_page) {
            this.queryTableRegras(params);
            this.pagination.regras.current_page = params.pageIndex;
        }

    }

    queryTableRegras(params: NzTableQueryParams, search: string = null, buscar = false): void {

        if (buscar) {
            this.currentParams.pageIndex = 1;
        }

        const ano = this.dadosCabecalho.anoSelecionado;
        const mes = this.dadosCabecalho.mesNumSelecionado;

        this.loadingRegras = true;

        this.currentParams = params;

        this.currentSearch = search;

        this.calculaBadgeFiltros();

        this.currentParams.filter = [];

        const filtros = this.formFiltrosGrid.formGroup.value;

        for (const [key, valor] of Object.entries(filtros)) {

            // mandando classificacao na url
            if (valor && key !== 'classificacao') {
                this.currentParams.filter.push({key, value: valor});
            }

        }

        let filtro;

        if (this.formFiltrosCabecalho.formGroup.value.categoria) {
            filtro = this.formFiltrosCabecalho.formGroup.value.categoria;
        }

        if (this.formFiltrosCabecalho.formGroup.value.obrigacao) {
            filtro = this.formFiltrosCabecalho.formGroup.value.obrigacao;
        }

        this.tipo = this.tabAtiva === 1 ? 'G' : this.tabAtiva === 2 ? 'M' : 'L';

        const baseUrl = `${environment.apiUrl}/check/conciliacoes/regras/${filtro}/${ano}-${mes}/${this.tipo}`;

        if (mes && ano && this.tabAtiva) {
            this.requestBt = this.abstractService.newListToTable(this.currentParams, search, baseUrl).subscribe(
                {
                    next: (response) => {

                        this.checkedItems.clear();

                        this.itemsRegras = [];

                        this.itemsRegras = response.data || response;

                        this.boolPodeLiberar = false;
                        this.itemsRegras.forEach((value) => {

                            value.qtdSequencia = value.sequencia?.split(',').length || 0;

                            value.disabled = value.classificacao !== 'M' ||
                                value.classificacao === 'M' && value.podeLiberar === false ||
                                value.classificacao === 'M' && value.liberacaoData && value.liberacaoUsuario_id;

                            value.disabled = false;

                            if (!value.disabled && !this.boolPodeLiberar) {
                                this.boolPodeLiberar = true;
                            }

                            value.classificacaoDescricao = value.classificacao === 'L' ? 'Leve' :
                                (value.classificacao === 'M' ? 'Média' :
                                    (value.classificacao === 'G' ? 'Grave' : ''));

                        });

                        this.pagination.regras = new Pagination(
                            response?.per_page || 50,
                            response?.current_page || 1,
                            response?.last_page || 1,
                            response?.total || 50);

                        this.disabledProximaPagina = !response?.next_page_url;
                        this.loadingRegras = false;

                    },
                    error: (err) => {
                        this.toastService.error(err.error.message);
                        this.loadingRegras = false;
                    }
                });
        }

    }

    listByTableEmpresas(params: NzTableQueryParams) {
        let buscar = false;

        if (params.pageIndex === this.pagination.empresas.current_page) {
            params.sort.forEach(s => {
                if (s.value) {
                    buscar = true;
                }
            });
        }

        if ((buscar || params.pageIndex === 1 && this.pagination.empresas.current_page > 1)
            || params.pageIndex > 1
            && params.pageIndex !== this.pagination.empresas.current_page) {
            this.queryTableEmpresas(params);
            this.pagination.empresas.current_page = params.pageIndex;
        }

    }

    queryTableEmpresas(params: any, search: string = null, buscar = false, table = false): void {

        if (buscar) {
            params.pageIndex = 1;
        }

        this.currentParams = params || {filter: []};

        this.loadingEmpresas = true;

        this.currentSearch = search;

        this.calculaBadgeFiltros();

        this.currentParams.filter = [];

        const filtros = this.formFiltrosGrid.formGroup.value;

        for (const [chave, valor] of Object.entries(filtros)) {

            if (valor) {
                this.currentParams.filter.push({key: chave, value: valor});
            }

        }

        const ano = this.dadosCabecalho.anoSelecionado;
        const mes = this.dadosCabecalho.mesNumSelecionado;

        let filtro;

        if (this.formFiltrosCabecalho.formGroup.value.categoria) {
            filtro = this.formFiltrosCabecalho.formGroup.value.categoria;
        }

        if (this.formFiltrosCabecalho.formGroup.value.obrigacao) {
            filtro = this.formFiltrosCabecalho.formGroup.value.obrigacao;
        }

        if (ano && mes) {
            const baseUrl = `${environment.apiUrl}/check/conciliacoes/empresas/${filtro}/${ano}-${mes}`;

            this.requestBt = this.abstractService.newListToTable(this.currentParams, search, baseUrl).subscribe((response) => {

                this.checkedItems.clear();

                this.itemsEmpresas = [];

                this.itemsEmpresas = response.data || response;


                this.itemsEmpresas.forEach((value) => {
                    if (value.processamentoInicio && value.processamentoFim && value.status === 2) {
                        value.tempoProcessamento = Helpers.timeDiff(value.processamentoInicio, value.processamentoFim);
                    } else if (value.processamentoInicio && value.status === 1) {
                        value.tempoProcessamento = Helpers.timeDiff(value.processamentoInicio);
                    }

                    value.statusDescricao = value.status === 0 ? 'Na Fila' :
                        value.status === 1 ? 'Em Processamento' :
                            value.status === 2 ? 'Processado' :
                                value.status === 3 ? 'Erro ao Processar' :
                                    value.status === 4 ? 'Armazenando' : '';

                    if (value.processamentoLog) {

                        const logArray = value.processamentoLog.match(/^Passo [(](\d+)[\/](\d+)[)]: (\D+)$/);

                        if (logArray) {
                            value.processPercentage = Math.round(
                                this.getPercentage(
                                    Number(logArray[1]), Number(logArray[2])
                                )
                            );
                        }
                    }

                });

                this.disabledProximaPagina = !response?.next_page_url;
                this.loadingEmpresas = false;

            });
        }

    }

    formatMonth(month) {

        if (month <= 9) {

            return '0' + month;

        } else {

            return month;

        }

    }

    calculaBadgeFiltros(): void {

        this.qtdFiltrosAtivos = 0;

        if (typeof this.formFiltrosGrid !== 'undefined') {

            for (const [key, valor] of Object.entries(this.formFiltrosGrid.formGroup.value)) {
                if (valor && key !== 'classificacao') {
                    this.qtdFiltrosAtivos++;
                }

            }

        }

    }

    calculaBadgeFiltrosCabecalho(): void {

        this.qtdFiltrosCabecalho = 0;

        if (typeof this.formFiltrosCabecalho !== 'undefined') {

            for (const [key, valor] of Object.entries(this.formFiltrosCabecalho.formGroup.value)) {

                if (valor) {
                    this.qtdFiltrosCabecalho++;
                }

            }

        }

    }


    onExpandChange(id: number, checked: boolean): void {
        if (checked) {
            this.expandSet.add(id);
        } else {
            this.expandSet.delete(id);
        }
        this.cdr.detectChanges();
    }

    formatOne = (percent: number) => `${percent}%`;


    fecharModal(formulario: FormStack): void {
        formulario.modalVisible = false;
    }

    abrirModal(formulario: FormStack): void {
        formulario.modalVisible = true;
    }

    showHideLinha(numLinha: number, linha = null) {
        this.linhaSelecionada = linha;
        this.linhaSelecionadaVisible[numLinha] = !this.linhaSelecionadaVisible[numLinha];
    }

    filtrarCabecalho(): any {

        this.carregaCabecalho();

        this.fecharModal(this.formFiltrosCabecalho);

    }

    filtrarGrid(): any {

        this.currentParams.pageIndex = 1;

        if (this.tabAtiva === 0) {
            this.queryTableEmpresas(this.currentParams, this.currentSearch);
        } else if (this.tabAtiva > 0) {
            this.queryTableRegras(this.currentParams, this.currentSearch);

        }

        this.fecharModal(this.formFiltrosGrid);

    }

    carregaCabecalho(): void {

        this.loadingContadores = true;
        this.loading = true;

        this.calculaBadgeFiltrosCabecalho();

        const vencimento = new Date(this.formFiltrosCabecalho.formGroup.value.vencimento);

        let ano = null;
        let mes = null;

        if (vencimento) {
            ano = vencimento.getFullYear();
            mes = this.formatMonth(vencimento.getMonth() + 1);
        }

        let filtro;

        if (this.formFiltrosCabecalho.formGroup.value.categoria) {
            filtro = this.formFiltrosCabecalho.formGroup.value.categoria;
        }

        if (this.formFiltrosCabecalho.formGroup.value.obrigacao) {
            filtro = this.formFiltrosCabecalho.formGroup.value.obrigacao;
        }

        this.dadosCabecalho.anoSelecionado = ano;
        this.dadosCabecalho.mesSelecionado = this.arrayAno[Number(mes) - 1].toUpperCase();
        this.dadosCabecalho.mesNumSelecionado = mes;

        this.service.retornarCabecalho(filtro, ano + '-' + mes).subscribe((response) => {

            this.dadosCabecalho.obrigacao = response.descricao;
            this.dadosCabecalho.orgao = response.orgao;
            this.dadosCabecalho.qtdUnidades = response.unidades;
            this.dadosCabecalho.qtdObrigacoes = response.obrigacoes;
            this.dadosCabecalho.status = response.status;

            this.loadingContadores = false;
        }, error => {
            this.loadingContadores = false;
            this.toastService.error(error.error.message);
        });

        this.queryTableRegras(this.currentParams, this.currentSearch);
        this.queryTableEmpresas(this.currentParams, this.currentSearch);

    }

    modalFiltrar(visible: boolean): void {
        this.formFiltrosGrid.modalVisible = visible;
    }

    btnResetSearch() {
        this.checkedItems.clear();
        this.checked = false;

        this.currentSearch = null;

        this.currentParams = {
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            sort: [],
            filter: [],
        };

        this.formFiltrosGrid.formGroup.reset();

        if (this.tabAtiva === 0) {
            this.queryTableEmpresas(this.currentParams, this.currentSearch);
        } else if (this.tabAtiva > 0) {
            this.queryTableRegras(this.currentParams, this.currentSearch);

        }

    }

    changeTabs(event: any) {
        this.requestBt?.unsubscribe();

        this.checkedItems = new Set<string>();
        this.currentParams.pageIndex = 1;

        this.tabAtiva = event.index;
        this.data.tabAtiva = event.index;

        if (this.tabAtiva > 0) {
            this.queryTableRegras(this.currentParams, this.currentSearch);
        }

        if (this.tabAtiva === 0) {
            this.formFiltrosGrid.formGroup.get('classificacao').setValue(null);
            this.filtroEmpresaID = null;
            this.formFiltrosGrid.formGroup.get('status').setValue(null);
            this.queryTableEmpresas(this.currentParams, this.currentSearch);
        }

    }

    clearInput(form, emitter, target) {
        const inputEmitter = form.get(emitter);
        const inputTarget = form.get(target);
        if (inputEmitter.value) {
            inputTarget.setValue(null);
        }
    }

    processarRegrasConfirm(checkObrigacaoValidacao_id: string, obrigacaoRegra_id: string) {

        this.modalService.confirm({
            nzTitle: 'Deseja realmente realizar a liberação da(s) regra(s) selecionada(s)?',
            nzOkText: 'Processar',
            nzCancelText: 'Cancelar',
            nzOnOk: () => this.liberarRegras(checkObrigacaoValidacao_id, obrigacaoRegra_id)
        });

    }

    exportDocumento(id, arquivoNome, orgao) {

        this.loadings.download.push(id);

        this.service.exportDocumento(id, orgao).subscribe((res) => {

            const blob = new Blob([res], {type: 'text/json; charset=utf-8'});
            fileSaver.saveAs(blob, arquivoNome + '.zip');

            this.loadings.download = [];

        }, (error) => {
            this.loadings.download = [];
            this.toastService.error(error.error.message);
        });
    }

    liberarRegras(checkObrigacaoValidacaoId: string, obrigacaoRegraId: string) {

        const ids: { checkObrigacaoValidacaoId: string, obrigacaoRegraId: string }[] = [];


        ids.push({checkObrigacaoValidacaoId, obrigacaoRegraId});

        this.service.liberarRegras(ids, this.tipo).subscribe((response: any) => {

            this.toastrService.success('Regras(s) liberadas(s) com sucesso!');

            this.queryTable(this.currentParams, this.currentSearch);

        }, (response) => {

            this.toastrService.error(response.error.message);

        });

    }

    cadastrarAprovacao() {

        if (this.formCadastrarAprovacao.formGroup.valid) {

            this.loadingCadastrandoAprovacao = true;

            this.service.cadastrarAprovacao(this.formCadastrarAprovacao.formGroup.value).subscribe((response: any) => {

                this.toastrService.success('Aprovação cadastrada com sucesso!');

                this.fecharModal(this.formCadastrarAprovacao);

                this.loadingCadastrandoAprovacao = false;

                this.formCadastrarAprovacao.formGroup.reset();
                this.checkedItems.clear();

                this.queryTable(this.currentParams, this.currentSearch);

            }, (response) => {

                this.loadingCadastrandoAprovacao = false;

                this.toastrService.error(response.error.message);

            });

        } else {

            Object.values(this.formCadastrarAprovacao.formGroup.controls).forEach(control => {

                if (control.invalid) {

                    control.markAsDirty();
                    control.updateValueAndValidity({onlySelf: true});

                }

            });

        }

    }

    abrirModalCadastrarAprovacoes(): void {

        const arrayLiberacaoUsuarioId = [];
        this.itemsRegras.forEach(i => {
            if (this.checkedItems.has(i.id) && i.obrigacaoEmpresa_id) {
                arrayLiberacaoUsuarioId.push(i.obrigacaoEmpresa_id);
            }
        });

        this.formCadastrarAprovacao.modalVisible = true;

        this.formCadastrarAprovacao.formGroup.reset();

        this.formCadastrarAprovacao.formGroup.get('obrigacaoEmpresa_id').setValue(arrayLiberacaoUsuarioId);

    }

    filtrarCampo(form, campo, valor) {

        if (campo === 'classificacao') {
            this.tabAtiva = valor === 'G' ? 1 : valor === 'M' ? 2 : 3;
        } else {
            form.formGroup.get(campo).setValue(valor);
        }

        this.filtrarGrid();
    }

    downloadPdf() {
        fileSaver.saveAs(this.pdfSelecionado, this.pdfSelecionadoNome);
    }

    modalPdf(visible: boolean): void {
        this.modalPdfVisible = visible;
    }

    getPercentage(campo1: number, campo2: number): number {
        const maior: any = campo1 > campo2 ? campo1 : campo2;
        const menor: any = campo1 < campo2 ? campo1 : campo2;
        return (menor / maior) * 100;
    }

    modalDetalheApruracao(visible) {
        this.modalDetalhes = visible;
    }

    carregaDetalheApuracao(id: any): void {

        this.carregaResumoCFOP(id);
        this.carregaApuracaoICMS(id);

        this.modalDetalheApruracao(true);

    }

    carregaResumoCFOP(id: any): void {
        this.resumoCFOPLoading = true;

        this.service.resumoCFOP(id).subscribe((response) => {

                this.arrayResumoCFOP = response;
                this.resumoCFOPLoading = false;

            },
            (response) => {
                this.resumoCFOPLoading = false;
                this.toastrService.error(response.error.message);

            });

        this.apuracaoICMSLoading = true;

    }

    carregaApuracaoICMS(id: any): void {

        this.apuracaoICMSLoading = true;

        this.service.apuracaoICMS(id).subscribe((response) => {

                this.arrayApuracaoICMS = [response];

                this.apuracaoICMSLoading = false;

            },
            (response) => {
                this.apuracaoICMSLoading = false;
                this.toastrService.error(response.error.message);

            });

        this.modalDetalheApruracao(true);

    }

    changeTabsDetalhes(event: any) {
        this.tabDetalhesAtiva = event.index;
    }

    showConfirmConciliacao(): void {

        const arrayMd5 = [];

        this.itemsEmpresas.forEach(i => {

            if (this.checkedItems.has(i.id) && i.MD5) {
                arrayMd5.push(i.MD5);
            }
        });

        this.modalService.confirm({
            nzTitle: 'Essa ação irá direcionar os arquivos para conciliação. Deseja confirmar?',
            nzOkText: 'Ok',
            nzCancelText: 'Cancelar',
            nzOnOk: () => this.iniciarConciliacao(arrayMd5)
        });

    }

    iniciarConciliacao(arrayMd5) {

        this.loadingConciliacoes = true;

        this.service.iniciarConciliacao(arrayMd5).subscribe((retorno: any) => {

            this.toastService.success(retorno.message);

            this.loadingConciliacoes = false;
            this.checkedItems.clear();

        }, (retorno) => {

            this.toastrService.error(retorno.error.message);

            this.loadingConciliacoes = false;

        });

    }

    showConfirmValidacao(): void {

        const arrayMd5 = [];

        this.itemsEmpresas.forEach(i => {

            if (this.checkedItems.has(i.id) && i.MD5) {
                arrayMd5.push(i.MD5);
            }
        });

        this.modalService.confirm({
            nzTitle: `Essa ação irá direcionar o arquivos para validação. Deseja confirmar?`,
            nzOkText: 'Ok',
            nzCancelText: 'Cancelar',
            nzOnOk: () => this.reprocessarRegistro(arrayMd5)
        });

    }

    reprocessarRegistro(arrayMd5) {

        this.loadingValidacoes = true;

        this.service.reprocessarRegistro(arrayMd5).subscribe((retorno: any) => {

            this.toastService.success(retorno.message);

            this.loadingValidacoes = false;
            this.checkedItems.clear();

        }, (retorno) => {

            this.toastrService.error(retorno.error.message);

            this.loadingValidacoes = false;

        });

    }

    verifyTheme() {

        if (AppComponent.miniLogoUrl && AppComponent.backgroundColor && AppComponent.highlightColor) {
            this.miniLogoUrl = AppComponent.miniLogoUrl;
            this.backgroundColor = AppComponent.backgroundColor;
            this.highlightColor = AppComponent.highlightColor;
            return;
        }

        if (this.verifyThemeCount > 100) {
            return;
        }

        setTimeout(() => {
            this.verifyThemeCount++;
            this.verifyTheme();
        }, 100);

    }

    onItemChecked(id: string, checked: boolean): void {

        this.updateCheckedSet(id, checked);

    }

    updateCheckedSet(id: string, checked: boolean): void {

        if (checked) {

            this.checkedItems.add(id);

        } else {

            this.checkedItems.delete(id);

        }

        const length = this.tabAtiva === 0 ? this.itemsEmpresas?.length : this.itemsRegras?.length;

        if (length !== this.checkedItems.size) {
            this.checked = false;
        } else {
            this.checked = true;
        }
    }

    exportarDadosRegras() {


        const params = this.currentParams;

        const ano = this.dadosCabecalho.anoSelecionado;
        const mes = this.dadosCabecalho.mesNumSelecionado;
        params.filter.push({key: 'anoMesVencimento', value: ano + '-' + mes});

        if (this.formFiltrosCabecalho.formGroup.value.categoria) {
            params.filter.push({key: 'obrigacaoId', value: this.formFiltrosCabecalho.formGroup.value.categoria});
        }

        if (this.formFiltrosCabecalho.formGroup.value.obrigacao) {
            params.filter.push({key: 'obrigacaoId', value: this.formFiltrosCabecalho.formGroup.value.obrigacao});
        }

        const url = `${environment.apiUrl}/exportador`;


        switch (this.tabAtiva) {
            case 1:
                params.filter.push({key: 'gravidade', value: 'G'});
                break;
            case 2:
                params.filter.push({key: 'gravidade', value: 'M'});
                break;
            case 3:
                params.filter.push({key: 'gravidade', value: 'L'});
                break;
        }

        this.service.exportarDadosRegras(params, this.currentSearch, url).subscribe((res) => {

            const message = res.message + ' Acompanhe na central de downloads.';

            this.toastService.success(message);

            this.openTab('/downloadCenter', '', {});

        }, (res) => {
            this.toastService.error(res.error.message);
        });

    }

    paginaAnterior() {
        this.checkedItems.clear();
        this.checked = false;

        this.currentParams.pageIndex--;

        if (this.tabAtiva > 0) {
            if (this.demaisColunasVisible) {
                this.modalDemaisColunas(true, this.arquivoSelecionado, this.currentParams);
            } else {
                this.queryTableRegras(this.currentParams, this.currentSearch);
            }
        } else {
            this.queryTableEmpresas(this.currentParams, this.currentSearch);
        }

    }

    paginaProxima() {
        this.checkedItems.clear();
        this.checked = false;

        this.currentParams.pageIndex++;

        if (this.tabAtiva > 0) {
            if (this.demaisColunasVisible) {
                this.modalDemaisColunas(true, this.arquivoSelecionado, this.currentParams);
            } else {
                this.queryTableRegras(this.currentParams, this.currentSearch);
            }
        } else {
            this.queryTableEmpresas(this.currentParams, this.currentSearch);
        }

    }

    changeTabManual(tab: number, cnpj: string) {
        const empresa: any = this.arraySelectCnpj.filter(opt => opt.descricao === cnpj);
        this.formFiltrosGrid.formGroup.get('empresa_id').setValue(empresa?.[0]?.id);
        this.filtroEmpresaID = empresa?.[0]?.id;
        this.formFiltrosGrid.formGroup.controls.empresa_id.updateValueAndValidity();

        this.tabAtiva = tab;
    }

    listByTableDemaisColunasRegras(params: NzTableQueryParams) {
        let buscar = false;

        if (params.pageIndex === this.pagination.demaisColunasVisible.current_page) {
            params.sort.forEach(s => {
                if (s.value) {
                    buscar = true;
                }
            });
        }

        if ((buscar || params.pageIndex === 1 && this.pagination.demaisColunasVisible.current_page > 1)
            || params.pageIndex > 1
            && params.pageIndex !== this.pagination.demaisColunasVisible.current_page) {
            this.modalDemaisColunas(true, this.arquivoSelecionado, params);
            this.pagination.demaisColunasVisible.current_page = params.pageIndex;
        }

    }

    modalDemaisColunas(visible: boolean, arquivo = null, params = this.currentParams, firstPage: boolean = false) {

        this.arquivoSelecionado = arquivo;

        if (visible) {

            this.loadingDemaisColunas = arquivo.obrigacaoEmpresa_id + arquivo.obrigacaoRegra_id;

            this.currentParams = params;

            this.currentParams.filter = [];

            const filtros = this.formFiltrosGrid.formGroup.value;

            for (const [chave, valor] of Object.entries(filtros)) {

                if (valor) {
                    this.currentParams.filter.push({key: chave, value: valor});
                }
            }
            if (this.requestBt) {
                this.requestBt?.unsubscribe();
            }

            if (firstPage){
                this.currentParams.pageIndex = 1;
            }


            this.requestBt = this.service.retornaDemaisColunas(
                arquivo.obrigacaoEmpresa_id, arquivo.obrigacaoRegra_id, this.currentParams, this.currentSearch)
                .subscribe({
                    next: (response) => {
                        this.demaisColunas = response.data;
                        this.loadingDemaisColunas = null;
                        this.demaisColunasVisible = true;

                        this.pagination.demaisColunasRegras = new Pagination(
                            response?.per_page || 50,
                            response?.current_page || 1,
                            response?.last_page || 1,
                            response?.total || 50);

                        this.disabledProximaPaginaDetalhe = !response?.next_page_url;
                    },
                    error: (err) => {
                        this.toastService.error(err.error.message);
                        this.loadingDemaisColunas = null;
                    }
                });

        } else {
            this.demaisColunasVisible = false;
        }
    }

    showHideModalLinha(formulario: FormStack, visible, linha = null) {
        this.linhaSelecionada = linha;
        formulario.modalVisible = visible;
        this.demaisColunasVisible = !visible;
    }

    liberarEmMassa() {

        const ids: { checkObrigacaoValidacaoId: string, obrigacaoRegraId: string }[] = [];
        Array.from(this.checkedItems).forEach(value => {
            this.itemsRegras.forEach(regra => {
                if (regra.id === value) {
                    ids.push({
                        checkObrigacaoValidacaoId: regra.checkObrigacaoValidacao_id,
                        obrigacaoRegraId: regra.obrigacaoRegra_id
                    });
                }
            });
        });

        if (ids) {
            this.service.liberarRegras(ids, this.tipo).subscribe((response: any) => {

                this.toastrService.success('Regras(s) liberadas(s) com sucesso!');

                this.queryTable(this.currentParams, this.currentSearch);

            }, (response) => {

                this.toastrService.error(response.error.message);

            });
        }


    }
}
