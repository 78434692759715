import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    ViewChild, Input
} from '@angular/core';
import {buildUrl, findComponentByUrl} from '../../../shared/components-helper';
import {Tab} from '@models/tab.model';
import {TabService} from '@services/tab.service';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import {Obrigacao} from '@models/obrigacao.model';
import {TabHandlerInterface} from '../../../shared/interfaces/tab-handler.interface';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {ObrigacoesEmpresasService} from './obrigacoes-empresas.service';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {Pagination} from '@models/pagination.model';
import {StickyHeaderDirective} from '@components/directive/directive';
import {NzI18nService, pt_BR} from 'ng-zorro-antd/i18n';
import {NzModalRef, NzModalService} from 'ng-zorro-antd/modal';
import {Response} from '@models/response.model';
import Visibilidade from '@models/visibilidade.model';
import {EquipeService} from '@services/equipe.service';
import {environment} from '../../../../environments/environment';
import {registerLocaleData} from '@angular/common';
import br from '@angular/common/locales/pt';
import {UntypedFormControl} from '@angular/forms';
import * as fileSaver from 'file-saver-es';
import {StepsObrigacoesEmpresasService} from '../steps-obrigacoes-empresas/steps-obrigacoes-empresas.service';
import {DataService} from '@services/data.service';
import {Helpers} from '../../../core/helpers';
import {NzTableFilterValue, NzTableSortOrder} from 'ng-zorro-antd/table/src/table.types';

registerLocaleData(br);

interface FormStack {
    modalVisible: boolean;
    formGroup: UntypedFormGroup;
}

interface DataItem {
    label: string;
    cadastros: {
        obrigacoes_sugeridas: number;
        empresas_cadastradas: number;
    };
    obrigacoes: {
        estimadas: number;
        cadastradas: number;
    };
}

interface DataItemTwo {
    categoria: string;
    descricao: string;
    qnt_empresas_cadastradas: number;
}

@Component({
    selector: 'app-cadastros-obrigacoes-empresas',
    templateUrl: './obrigacoes-empresas.component.html',
    styleUrls: ['./obrigacoes-empresas.component.scss'],
})
// tslint:disable-next-line:max-line-length
export class CadastrosObrigacoesEmpresasComponent extends AbstractListTable<Obrigacao> implements AfterViewInit, TabHandlerInterface {

    @Input() data: any;

    dataFederal: any[] = [];
    totaisFederal = {
        empresasCadastradas: 0,
        cadastradas: 0,
        estimadas: 0,
    };
    dataEstadual: any[] = [];
    dataMunicipal: any[] = [];
    dataEstadualST: any = [];
    showEstadualST = true;
    totaisEstaduais = {
        empresasCadastradas: 0,
        cadastradas: 0,
        estimadas: 0,
    };
    totaisMunicipais: any = {};
    totaisEstaduaisST = {
        empresasCadastradas: 0,
        cadastradas: 0,
        estimadas: 0,
    };
    dataPrevidenciario: any[] = [];
    totaisPrevidenciario = {
        empresasCadastradas: 0,
        cadastradas: 0,
        estimadas: 0,
    };
    dataSimplesNacional: any[] = [];
    totaisSimplesNacional = {
        empresasCadastradas: 0,
        cadastradas: 0,
        estimadas: 0,
    };

    dataProcessamento: Date = null;

    description = '';
    currentParams: any = {
        oficiais: this.abstractService.NzTableQueryParams,
        calendar: this.abstractService.NzTableQueryParams,
    };

    paginaAtualOficiais = 1;

    currentSearch: any = {
        oficiais: '',
        calendar: ''
    };
    loading: any = {
        matrizes: false,
        regimes: false,
        ies: false
    };
    pagination: any = {
        oficiais: Pagination,
        calendar: Pagination,
        empresas: Pagination,
        obrigacoes: Pagination,
    };

    offsetTop = 15;
    target: any;

    loadingCardTabsOne = false;
    loadingSugeridasMassa = false;
    limparSugeridasMassa = false;
    tabAtivaOne = 0;
    statusOne = false;

    loadingCardTabsTwo = true;
    loadingAlterarEquipe = false;
    tabAtivaTwo = 0;
    statusTwo = false;
    loadingEditar = [];

    arrayTableVencimentoOficial: any = [];
    arrayVencimentoOficialCompetencias: any = [];
    arrayVigencias: any = [];
    arrayTableCalendar = [];
    arrayCalendarCompetencias = [];
    arrayTableObrigacoes = [];
    arrayTableObrigacoesComFiltro = [];

    arrayTableObrigacoesFront = [];
    arrayTableObrigacoesComFiltroFront = [];

    formObrigacaoVencimento: FormStack;
    formCalendar: FormStack;
    formVencimento: FormStack;
    formResponsavel: FormStack;

    formCadastrarEmMassa: FormStack;

    heightTableObrigacoes = 0;
    heightTableTabsTwo = 0;
    selectedValue = '';
    arrayAnos: any[] = [];

    showTabsTwo = false;
    obrigacaoSelecionadaLabel = '';
    obrigacaoSelecionada = '';
    arraySelectEmpresa = [];
    arraySelectCompetencias = [];

    comboGrupos = [];
    comboEquipes = [];
    comboResponsaveis = [];

    erro = false;
    mensagem = '';

    newVisibilidadeModal = false;
    displayVisibilidadeModal = false;
    equipeOptions: { label: string; value: string }[] = [];
    selectedEquipes: string[] = [];
    limparEquipes = false;
    newEquipe = '';
    dataVisibilityForm: Response<Visibilidade>;

    vencimentos = {};
    vencimentosHeader = {};

    loadingAdicionarEmpresas = false;
    loadingRestaurarVencimentos = false;

    modalEmpresaLabel = 'Adicionar Empresa';
    modalEmpresaEditar = false;

    empresaNomeEditar = '';

    quantidadeEmpresasInscricoes = 0;

    filtroObrigacoes = {
        orgao: '',
        fonte: '',
        procurar: null
    };

    screenPermissoes = {
        cadastrarSugeridas: 'f26783e02240'
    };

    @ViewChild('column1') column1: ElementRef;
    column1Width = 0;

    @ViewChild('column2') column2: ElementRef;
    column2Width = 0;

    @ViewChild('column3') column3: ElementRef;
    column3Width = 0;

    @ViewChild('column4') column4: ElementRef;
    column4Width = 0;

    @ViewChild('column5') column5: ElementRef;
    column5Width = 0;

    @ViewChild('column6') column6: ElementRef;
    column6Width = 0;

    @ViewChild('column7') column7: ElementRef;
    column7Width = 0;

    @ViewChild('column8') column8: ElementRef;
    column8Width = 0;

    @ViewChild('column9') column9: ElementRef;
    column9Width = 0;

    @ViewChild('column10') column10: ElementRef;
    column10Width = 0;

    @ViewChild('column11') column11: ElementRef;
    column11Width = 0;

    @ViewChild('column12') column12: ElementRef;
    column12Width = 0;

    @ViewChild('column13') column13: ElementRef;
    column13Width = 0;

    expandSet = new Set<string>();
    expandSetMunicipal = new Set<string>();

    private vencimentoOficialAnchor;

    listOfColumn = [
        {
            title: 'UF',
            sortFn: (a: DataItem, b: DataItem) => a.label.localeCompare(b.label),
            sortOrder: 'ascend',
            priority: 1
        },
        {
            title: 'Obrigações disponíveis',
            compare: (a: DataItem, b: DataItem) => a.cadastros.obrigacoes_sugeridas - b.cadastros.obrigacoes_sugeridas,
            priority: 2
        },
        {
            title: 'Empresas c/ IEs Cadastradas',
            compare: (a: DataItem, b: DataItem) => a.cadastros.empresas_cadastradas - b.cadastros.empresas_cadastradas,
            priority: 3
        },
        {
            title: 'Estimada(s)',
            compare: (a: DataItem, b: DataItem) => a.obrigacoes.estimadas - b.obrigacoes.estimadas,
            priority: 4
        },
        {
            title: 'Cadastradas(s)',
            compare: (a: DataItem, b: DataItem) => a.obrigacoes.cadastradas - b.obrigacoes.cadastradas,
            priority: 5
        },
    ];

    // tslint:disable-next-line:variable-name
    listOfColumn_ie_st = [
        {
            title: 'UF',
            sortFn: (a: DataItem, b: DataItem) => a.label.localeCompare(b.label),
            sortOrder: 'ascend',
            priority: 1
        },
        {
            title: 'Obrigações Sugeridas\n(por empresa)',
            compare: (a: DataItem, b: DataItem) => a.cadastros.obrigacoes_sugeridas - b.cadastros.obrigacoes_sugeridas,
            priority: 2
        },
        {
            title: 'Empresas c/ IEs ST Cadastradas',
            compare: (a: DataItem, b: DataItem) => a.cadastros.empresas_cadastradas - b.cadastros.empresas_cadastradas,
            priority: 3
        },
        {
            title: 'Estimada(s)',
            compare: (a: DataItem, b: DataItem) => a.obrigacoes.estimadas - b.obrigacoes.estimadas,
            priority: 4
        },
        {
            title: 'Cadastradas(s)',
            compare: (a: DataItem, b: DataItem) => a.obrigacoes.cadastradas - b.obrigacoes.cadastradas,
            priority: 5
        },
    ];

    listOfColumnTwo: any = [
        {
            title: 'Obrigações',
            sortFn: (a: DataItemTwo, b: DataItemTwo) => a?.descricao.localeCompare(b?.descricao),
            sortOrder: 'ascend',
            priority: 1
        },
        {
            title: 'Categoria',
            sortFn: (a: DataItemTwo, b: DataItemTwo) => {
                if (a.categoria == null) {
                    a.categoria = '';
                }
                if (b.categoria == null) {
                    b.categoria = '';
                }
                a?.categoria.localeCompare(b?.categoria);
            },
            sortOrder: true,
            priority: 2
        },
        {
            title: 'Obrigações cadastradas por empresa',
            compare: (a: DataItemTwo, b: DataItemTwo) => a.qnt_empresas_cadastradas - b.qnt_empresas_cadastradas,
            priority: 3
        },
    ];


    @ViewChild('vencimentoOficialAnchor') set content(content: ElementRef) {
        if (content) { // initially setter gets called with undefined
            this.vencimentoOficialAnchor = content;
        }
    }

    temAgenda;

    private cardTabsTwo;

    @ViewChild('cardTabsTwo') set contentCard(content: ElementRef) {
        if (content) { // initially setter gets called with undefined
            this.cardTabsTwo = content;
        }
    }

    currentUser: any;

    steps = [
        {
            icon: 'calendar',
            step: 'process',
        },
        {
            icon: 'apartment',
            step: 'wait',
        },
        {
            icon: 'branches',
            step: 'wait',
        },
        {
            icon: 'check',
            step: 'finish',
        },
    ];

    icons = [
        'calendar',
        'apartment',
        'branches',
        'schedule',
        'check',
    ];

    stepNumber = 0;
    radioValueStepZero = 'todas';
    radioValueStepOne = 'todas';
    stepsLoading = false;

    formStepsObrigacoes: FormStack;

    modelCalendar = false;
    modelCheck = false;
    modelCompliance = false;

    checkedItemsEmpresas = new Set<string>();

    checkedItemsObrigacoes = new Set<string>();

    nextButtonText = 'Avançar';

    removerTodosAnos = false;
    qtdFiltrosAtivos = 0;
    formFiltrar: FormStack;

    loadingDiferenca = {};

    atualizandoInfo = false;
    loadingEditando = false;

    alterarResObgPendente = false;
    loadingAlterarRespLote = false;

    alterarEquipeObgPendente = false;

    loadingLog = false;
    logVisible = false;
    log = [];
    groupBuscaObrigacoes: UntypedFormGroup;
    arrayObrigacoesSearch: any[];
    loadingBuscandoObrigacoes: boolean;

    cnpjSelecionadoEditarVencimentos = null;

    constructor(
        private fb: UntypedFormBuilder,
        private obrigacoesEmpresaService: ObrigacoesEmpresasService,
        private toastService: ToastrService,
        private tabService: TabService,
        private cdr: ChangeDetectorRef,
        private i18n: NzI18nService,
        private modalService: NzModalService,
        private equipeService: EquipeService,
        private dataService: DataService,
        private stepsObrigacoesEmpresaService: StepsObrigacoesEmpresasService,
    ) {
        super(obrigacoesEmpresaService, Obrigacao, toastService);

        this.getPermissoesPerfil();

        this.i18n.setLocale(pt_BR);

        this.currentParams = {
            oficiais: {
                pageIndex: 1,
                pageSize: 25,
                sort: [],
                filter: []
            },
            calendar: {
                pageIndex: 1,
                pageSize: 25,
                sort: [],
                filter: []
            }
        };

        this.formObrigacaoVencimento = {
            modalVisible: false,
            formGroup: this.fb.group({
                empresa: [null, Validators.required],
                grupo: [null, Validators.required],
            })
        };

        this.formCadastrarEmMassa = {
            modalVisible: false,
            formGroup: this.fb.group({
                competencia: [null, null],
                empresa_id: [null, null],
                obrigacao_id: [null, null],
            })
        };

        this.formCalendar = {
            modalVisible: false,
            formGroup: this.fb.group({
                empresa: [null, Validators.required],
                equipe_id: [null, Validators.required],
                responsavel_id: [null, Validators.required],
            })
        };

        this.groupBuscaObrigacoes = this.fb.group({
            buscarObrigacao: [null, null]
        });

        this.formVencimento = {
            modalVisible: false,
            formGroup: this.fb.group({})
        };

        this.formResponsavel = {
            modalVisible: false,
            formGroup: this.fb.group({
                responsavel_id: [null, Validators.required]
            })
        };

        this.formStepsObrigacoes = {
            modalVisible: false,
            formGroup: this.fb.group({})
        };

        this.obrigacoesEmpresaService.retornaComboGrupos().subscribe((retorno: any) => {

            retorno.grupoEmpresarial.forEach((value) => {
                value.label = value.descricao;
                value.key = value.id;
            });

            this.comboGrupos = retorno.grupoEmpresarial;

        });

        this.obrigacoesEmpresaService.retornaComboEquipes().subscribe((retorno: any) => {

            retorno.equipes.forEach((value) => {
                value.label = value.equipe;
                value.key = value.id;
            });

            this.comboEquipes = retorno.equipes;

        });

        this.dataService.currentUser.subscribe((data) => {
            this.currentUser = data;
        });


        this.obrigacoesEmpresaService.retornarSelectsEmpresas(
        ).subscribe((retorno: any) => {
            this.arraySelectEmpresa = retorno.empresaNome;
        });

        this.obrigacoesEmpresaService.retornaComboResponsaveis().subscribe((retorno: any) => {

            retorno.forEach((value) => {
                value.label = value.nome;
                value.key = value.id;
            });

            this.comboResponsaveis = retorno;

        });

        this.obrigacoesEmpresaService.retornarSelectsCompetencias(
        ).subscribe((retorno: any) => {
            this.arraySelectCompetencias = retorno;
        });

        this.formFiltrar = {
            modalVisible: false,
            formGroup: this.fb.group({
                inativo: [null, null],
                equipe: [null, null],
                responsavel: [null, null],
                grupo: [null, null],
            })
        };

    }

    ngAfterViewInit() {

        if (this.data && this.data.empresa_cnpj && this.data.obrigacao_id) {
            this.loadingCardTabsOne = true;
            this.tabAtivaOne = 1;
            this.showTabsTwo = true;
            this.currentSearch.oficiais = Helpers.maskCnpjCpf(this.data.empresa_cnpj);
            this.retornaAnosObrigacoes(this.data.obrigacao_id, this.data.obrigacao_descricao);
        }

        this.target = StickyHeaderDirective.target;

        this.retornaContadoresOrgao();

        this.retornaAlerta();

        this.heightTableObrigacoes = this.target.offsetHeight - (this.target.offsetHeight / 100 * 20);

        this.heightTableTabsTwo = this.target.offsetHeight - (this.target.offsetHeight / 100 * 23);

        this.cdr.detectChanges();

    }

    modalObrigacaoVencimento(
        visible,
        vencimentos: any = [],
        empresaId: string = null,
        empresaNome: string = null,
        responsavel: string = null,
        equipe: string = null,
        cnpj = null) {

        this.cnpjSelecionadoEditarVencimentos = cnpj;

        this.arrayVigencias = [];

        if (this.tabAtivaTwo === 0) {
            this.formObrigacaoVencimento.modalVisible = visible;
        }

        if (this.tabAtivaTwo === 1) {
            this.formCalendar.modalVisible = visible;
        }

        if (vencimentos.length > 0) {

            this.modalEmpresaEditar = true;
            this.modalEmpresaLabel = 'Editar Empresa';

            if (this.tabAtivaTwo === 0) {

                this.formObrigacaoVencimento.formGroup.reset();

                if (empresaId && empresaNome) {
                    this.empresaNomeEditar = empresaNome;
                    this.formObrigacaoVencimento.formGroup.get('empresa').setValue(empresaId);
                }

                vencimentos.forEach((element) => {

                    if (element[1]) {

                        const competencia = new Date(element[1]);

                        competencia.setDate(competencia.getDate() + 1);

                        this.arrayVigencias.push({competencia: element[0], vigencia: element[1]});

                        this.formObrigacaoVencimento.formGroup.get(element[0]).setValue(competencia);

                    }

                });

            }

            if (this.tabAtivaTwo === 1) {

                this.formCalendar.formGroup.reset();

                if (empresaId && empresaNome) {
                    this.empresaNomeEditar = empresaNome;
                    this.formCalendar.formGroup.get('empresa').setValue(empresaId);
                }

                if (responsavel) {
                    this.formCalendar.formGroup.get('responsavel_id').setValue(responsavel);
                }

                if (equipe) {
                    this.formCalendar.formGroup.get('equipe_id').setValue(equipe);
                }

                vencimentos.forEach((element) => {

                    if (element[1]) {

                        const competencia = new Date(element[1]);

                        competencia.setDate(competencia.getDate() + 1);

                        this.formCalendar.formGroup.addControl(element[0], new UntypedFormControl(null));
                        this.formCalendar.formGroup.get(element[0]).setValue(competencia);

                    }

                });

            }

        } else {

            this.modalEmpresaEditar = false;
            this.modalEmpresaLabel = 'Adicionar Empresa';

            vencimentos = Object.entries(this.vencimentosHeader);

            if (this.tabAtivaTwo === 0) {

                this.formObrigacaoVencimento.formGroup.reset();

                vencimentos.forEach((element) => {

                    const competencia = new Date(element[1]);

                    competencia.setDate(competencia.getDate() + 1);

                    this.formObrigacaoVencimento.formGroup.get(element[0]).setValue(competencia);

                });

            }

            if (this.tabAtivaTwo === 1) {

                this.formCalendar.formGroup.reset();

                vencimentos.forEach((element) => {

                    const competencia = new Date(element[1]);

                    competencia.setDate(competencia.getDate() + 1);

                    this.formCalendar.formGroup.get(element[0]).setValue(competencia);

                });

            }
        }

    }

    modalAlterarVencimento(visible) {

        this.formVencimento.modalVisible = visible;

        const vencimentos = Object.entries(this.vencimentosHeader);

        vencimentos.forEach((element) => {

            this.formVencimento.formGroup.addControl(element[0], new UntypedFormControl(''));

        });

    }

    modalAlterarResponsavel(visible) {
        this.formResponsavel.modalVisible = visible;
    }

    clickEvent(card: string) {

        switch (card) {

            case 'one':
                this.statusOne = !this.statusOne;
                break;

            case 'two':
                this.statusTwo = !this.statusTwo;
                break;

        }

    }

    changeTabs(event: any, card: string) {

        this.checkedItems = new Set<string>();

        switch (card) {

            case 'one':

                this.tabAtivaOne = event.index;

                if (this.tabAtivaOne === 0) {
                    this.showTabsTwo = false;
                }

                if (this.tabAtivaOne === 1 && this.arrayTableObrigacoes.length === 0) {
                    this.loadingCardTabsOne = true;
                    if (event?.index === 1) {
                        this.retornaObrigacoesEmpresas();
                    }
                }

                break;

            case 'two':

                this.tabAtivaTwo = event.index;
                this.formFiltrar.formGroup.reset();
                this.calculaBadgeFiltros();

                if (event.index === 0) {
                    this.retornaEmpresasObrigacoesOficiais(this.currentParams.oficiais, this.currentSearch.oficiais, true);
                } else {
                    this.retornaEmpresasObrigacaoCalendar(this.currentParams.calendar, this.currentSearch.calendar);
                }

                break;

        }

    }

    onAllChecked(checked: boolean): void {

        if (this.tabAtivaTwo === 0) {
            this.arrayTableVencimentoOficial.filter(({disabled}) => !disabled).forEach(({id}) => this.updateCheckedSet(id, checked));
        }

        if (this.tabAtivaTwo === 1) {
            this.arrayTableCalendar.filter(({disabled}) => !disabled).forEach(({id}) => this.updateCheckedSet(id, checked));
        }

    }

    openTab(componentName: string, queryParams?: string, data?: {}) {

        const component = findComponentByUrl(componentName);
        const url = buildUrl(component, queryParams);
        const newTab = new Tab(component.name, component.title, url, component.urlType, data);

        this.tabService.addTab(newTab);

    }

    buscarFront(limpar = false) {

        if (!this.filtroObrigacoes.procurar || limpar) {
            this.arrayTableObrigacoesComFiltroFront = this.arrayTableObrigacoesComFiltro;
            this.arrayTableObrigacoesFront = this.arrayTableObrigacoes;
            this.filtroObrigacoes.procurar = null;
            return;
        }

        if (this.filtroObrigacoes.orgao) {

            const result = [];

            this.arrayTableObrigacoesComFiltro.map(
                value => {
                    if (value.descricao?.toUpperCase().includes(this.filtroObrigacoes.procurar.toUpperCase()) ||
                        value.categoria?.toUpperCase().includes(this.filtroObrigacoes.procurar.toUpperCase())) {
                        result.push(value);
                    }
                });

            this.arrayTableObrigacoesComFiltroFront = result;

        } else {

            const retornoA = [];
            const retornoP = [];

            this.arrayTableObrigacoes.forEach(value => {
                value.obrigacoes.forEach(o => {

                    if (o.descricao?.toUpperCase().includes(this.filtroObrigacoes.procurar.toUpperCase()) ||
                        o.categoria?.toUpperCase().includes(this.filtroObrigacoes.procurar.toUpperCase())) {
                        if (value.tipoObg === 'Principais') {
                            retornoP.push(o);
                        } else {
                            retornoA.push(o);
                        }
                    }
                });
            });

            this.arrayTableObrigacoesFront = [
                {obrigacoes: retornoP, tipoObg: 'Principais'},
                {obrigacoes: retornoA, tipoObg: 'Acessórias'}
            ];
        }

    }


    retornaObrigacoesEmpresas() {

        this.loadingCardTabsTwo = true;

        this.obrigacoesEmpresaService.retornaObrigacoesEmpresas(this.filtroObrigacoes).subscribe((retorno: any) => {

            this.arrayTableObrigacoes = [
                {obrigacoes: retorno.P, tipoObg: 'Principais'},
                {obrigacoes: retorno.A, tipoObg: 'Acessórias'}
            ];

            this.arrayTableObrigacoesComFiltro = [...retorno.P, ...retorno.A];

            this.arrayTableObrigacoesFront = [
                {obrigacoes: retorno.P, tipoObg: 'Principais'},
                {obrigacoes: retorno.A, tipoObg: 'Acessórias'}
            ];

            this.arrayTableObrigacoesComFiltroFront = [...retorno.P, ...retorno.A];

            if (this.tabAtivaOne === 1) {
                this.loadingCardTabsOne = false;
            }

            this.loadingCardTabsTwo = false;


        }, error => {
            this.loadingCardTabsTwo = false;
            this.toastService.error(error.erro.message);
        });

    }

    retornaContadoresOrgao() {

        this.loadingCardTabsOne = true;

        this.obrigacoesEmpresaService.retornaContadoresOrgao().subscribe((response: any) => {

                const retorno = response.sumario;

                this.dataProcessamento = response.dataProcessamento;

                // Dados Orgão Federal
                const federal = [];
                this.totaisFederal.empresasCadastradas = 0;
                this.totaisFederal.cadastradas = 0;
                this.totaisFederal.estimadas = 0;

                Object.entries(retorno.Federal).forEach(([key, value]) => {
                    // tslint:disable-next-line:no-shadowed-variable
                    const obj: any = value;
                    if (obj.tipo !== 'Total') {
                        obj.label = obj.tipo;
                        obj.obrigacoes.estimadas = obj.cadastros.empresas_cadastradas * obj.cadastros.obrigacoes_sugeridas;
                        federal.push(obj);

                        this.totaisFederal.empresasCadastradas += obj.cadastros.empresas_cadastradas;
                        this.totaisFederal.cadastradas += obj.obrigacoes.cadastradas;
                        this.totaisFederal.estimadas += obj.obrigacoes.estimadas;
                    }
                });

                this.dataFederal = federal;

                // Dados Orgão Estadual
                this.totaisEstaduais.empresasCadastradas = 0;
                this.totaisEstaduais.cadastradas = 0;
                this.totaisEstaduais.estimadas = 0;
                const estadual = [];

                Object.entries(retorno.Estadual).forEach(([key, value]) => {
                    // tslint:disable-next-line:no-shadowed-variable
                    const obj: any = value;
                    obj.label = key;
                    obj.obrigacoes.estimadas = obj.cadastros.empresas_cadastradas * obj.cadastros.obrigacoes_sugeridas;
                    estadual.push(obj);

                    this.totaisEstaduais.empresasCadastradas += obj.cadastros.empresas_cadastradas;
                    this.totaisEstaduais.cadastradas += obj.obrigacoes.cadastradas;
                    this.totaisEstaduais.estimadas += obj.obrigacoes.estimadas;
                });

                this.dataEstadual = estadual;


                const estadual1 = [];

                if (retorno.Estadual1) {
                    Object.entries(retorno.Estadual1).forEach(([key, value]) => {
                        // tslint:disable-next-line:no-shadowed-variable
                        const obj: any = value;
                        obj.label = key;
                        obj.obrigacoes.estimadas = obj.cadastros.empresas_cadastradas * obj.cadastros.obrigacoes_sugeridas;
                        estadual1.push(obj);

                        this.totaisEstaduaisST.empresasCadastradas += obj.cadastros.empresas_cadastradas;
                        this.totaisEstaduaisST.cadastradas += obj.obrigacoes.cadastradas;
                        this.totaisEstaduaisST.estimadas += obj.obrigacoes.estimadas;
                    });
                } else {
                    this.showEstadualST = false;
                }

                this.dataEstadualST = estadual1;

                if (this.dataEstadualST.length === 0) {
                    this.showEstadualST = false;
                }

                // Dados Orgão Municipal
                this.totaisMunicipais = {};
                const municipal = [];

                if (retorno.Municipal) {
                    Object.entries(retorno.Municipal).forEach(([key, value]) => {
                        const obj: any = {};
                        obj.uf = key;
                        obj.municipios = [];

                        this.totaisMunicipais[obj.uf] = {};
                        this.totaisMunicipais[obj.uf].empresasCadastradas = 0;
                        this.totaisMunicipais[obj.uf].cadastradas = 0;
                        this.totaisMunicipais[obj.uf].obrigacoes_sugeridas = 0;
                        this.totaisMunicipais[obj.uf].estimadas = 0;


                        Object.entries(value).forEach(([chave, valor]) => {
                            if (typeof valor !== 'string') {
                                valor.obrigacoes.estimadas = valor.cadastros.empresas_cadastradas * valor.cadastros.obrigacoes_sugeridas;
                                obj.municipios.push(valor);
                                this.totaisMunicipais[obj.uf].empresasCadastradas += valor.cadastros.empresas_cadastradas;
                                this.totaisMunicipais[obj.uf].cadastradas += valor.obrigacoes.cadastradas;
                                this.totaisMunicipais[obj.uf].obrigacoes_sugeridas += valor.cadastros.obrigacoes_sugeridas;
                                this.totaisMunicipais[obj.uf].estimadas += valor.obrigacoes.estimadas;
                            }
                        });

                        municipal.push(obj);
                    });
                }

                this.dataMunicipal = municipal;

                // Dados Orgão Previdenciáios
                this.totaisPrevidenciario.empresasCadastradas = 0;
                this.totaisPrevidenciario.cadastradas = 0;
                this.totaisPrevidenciario.estimadas = 0;
                const previdenciario = [];

                Object.entries(retorno.Previdenciario).forEach(([key, value]) => {
                    const obj: any = value;
                    if (obj.tipo !== 'Total') {
                        obj.label = obj.tipo;
                        obj.obrigacoes.estimadas = obj.cadastros.empresas_cadastradas * obj.cadastros.obrigacoes_sugeridas;
                        previdenciario.push(obj);

                        this.totaisPrevidenciario.empresasCadastradas += obj.cadastros.empresas_cadastradas;
                        this.totaisPrevidenciario.cadastradas += obj.obrigacoes.cadastradas;
                        this.totaisPrevidenciario.estimadas += obj.obrigacoes.estimadas;
                    }
                });

                this.dataPrevidenciario = previdenciario;


                // Dados Orgão Simples Nacionnal
                this.totaisSimplesNacional.empresasCadastradas = 0;
                this.totaisSimplesNacional.cadastradas = 0;
                this.totaisSimplesNacional.estimadas = 0;
                const simplesNacional = [];
                Object.entries(retorno.SimplesNacional).forEach(([key, value]) => {
                    // tslint:disable-next-line:no-shadowed-variable
                    const obj: any = value;
                    if (obj.tipo !== 'Total') {
                        obj.label = obj.tipo;
                        obj.obrigacoes.estimadas = obj.cadastros.empresas_cadastradas * obj.cadastros.obrigacoes_sugeridas;
                        simplesNacional.push(obj);

                        this.totaisSimplesNacional.empresasCadastradas += obj.cadastros.empresas_cadastradas;
                        this.totaisSimplesNacional.cadastradas += obj.obrigacoes.cadastradas;
                        this.totaisSimplesNacional.estimadas += obj.obrigacoes.estimadas;
                    }
                });

                this.dataSimplesNacional = simplesNacional;

                if (this.tabAtivaOne === 0) {
                    this.loadingCardTabsOne = false;
                }

            }
        );

    }

    retornaAnosObrigacoes(obrigacaoId, obrigacaoDescricao) {
        this.loadingCardTabsTwo = true;

        this.obrigacoesEmpresaService.retornaAnosObrigacoes(obrigacaoId).subscribe((retorno: any) => {

            this.arrayAnos = retorno;
            this.selectedValue = new Date().getFullYear().toString();

            this.obrigacaoSelecionada = obrigacaoId;
            this.obrigacaoSelecionadaLabel = obrigacaoDescricao;

            this.retornaEmpresasObrigacoesOficiais(this.currentParams.oficiais, this.currentSearch.oficiais, true);

            this.showTabsTwo = true;
            this.scrollContainer();

            this.loadingCardTabsTwo = false;
            this.loadingEditar = [];

            if (obrigacaoId.length === 36) {
                this.tabAtivaTwo = 1;
            }

        });

    }

    scrollContainer(px = 0) {

        setTimeout(() => {
            this.target.scrollTo({
                left: 0,
                top: this.cardTabsTwo.nativeElement.offsetTop + px,
                behavior: 'smooth'
            });
        }, 800);

    }

    retornaEmpresasObrigacoesOficiaisTable(params: NzTableQueryParams) {
        let buscar = false;

        if (params.pageIndex === this.paginaAtualOficiais) {
            params.sort.forEach(s => {
                if (s.value) {
                    buscar = true;
                }
            });
        }

        if ((buscar || params.pageIndex === 1 && this.paginaAtualOficiais > 1)
            || params.pageIndex > 1
            && params.pageIndex !== this.paginaAtualOficiais) {
            this.retornaEmpresasObrigacoesOficiais(params, null, true);
            this.paginaAtualOficiais = params.pageIndex;
            this.currentParams.oficiais.pageIndex = params.pageIndex;
        }

    }

    retornaEmpresasObrigacoesOficiais(params: NzTableQueryParams, search: string = null, scroll = false, busca = false) {

        this.loadingCardTabsTwo = true;

        this.currentParams.oficiais = params || {};

        if (search && busca) {
            this.currentSearch.oficiais = search;
            this.resetPageOficiais();
        }

        this.checkedItems = new Set<string>();

        const url = (this.tabAtivaTwo === 0 ? 'oficiais' : 'calendar') + '/' + this.obrigacaoSelecionada + '/' + this.selectedValue;

        this.calculaBadgeFiltros();

        this.obrigacoesEmpresaService.retornaEmpresasObrigacoesOficiais(
            this.currentParams.oficiais, this.currentSearch.oficiais, url)
            .subscribe((response) => {

                this.arrayTableCalendar = response.empresas.data;

                this.vencimentos = {};

                this.vencimentos = response.vencimentos;

                this.vencimentosHeader = {};

                this.vencimentosHeader = response.vencimentos;

                const vencimentos = Object.entries(response.vencimentos);

                this.arrayVencimentoOficialCompetencias = [];

                vencimentos.forEach((element) => {

                    this.arrayVencimentoOficialCompetencias.push({
                        competencia: element[0],
                        index: element[1]
                    });

                    const competencia = new Date(element[1].toString());

                    competencia.setDate(competencia.getDate() + 1);

                    this.formObrigacaoVencimento.formGroup.addControl(element[0], new UntypedFormControl(''));

                    this.formObrigacaoVencimento.formGroup.get(element[0]).setValue(competencia);

                });

                response.empresas.data.forEach((element) => {
                    element.objectVencimentos = element.vencimentos;
                    element.vencimentos = Object.entries(element.vencimentos);
                });

                this.arrayTableVencimentoOficial = response.empresas.data;

                this.pagination.oficiais = new Pagination(
                    response?.empresas.per_page || 50,
                    response?.empresas.current_page || 1,
                    response?.empresas.last_page || 1,
                    response?.empresas.total || 50);

                this.pagination.calendar = new Pagination(
                    response?.empresas.per_page || 50,
                    response?.empresas.current_page || 1,
                    response?.empresas.last_page || 1,
                    response?.empresas.total || 50);

                this.loadingCardTabsTwo = false;

                this.checked = false;

                this.resetCheckedSet();

                if (scroll) {
                    this.scrollContainer(120);
                }
            });

    }

    listVigencias(obrigacao: string, empresaId) {

        this.arrayVigencias = [];

        this.abstractService.retornaVigencias(obrigacao, empresaId, this.selectedValue).subscribe((response) => {

            Object.entries(response).forEach((element) => {

                this.arrayVigencias.push({
                    competencia: element[0],
                    vigencia: element[1]
                });

            });

        });
    }

    calculaBadgeFiltros(): void {
        this.qtdFiltrosAtivos = 0;
        if (typeof this.formFiltrar !== 'undefined') {
            for (const [chave, valor] of Object.entries(this.formFiltrar.formGroup.value)) {
                if (valor) {
                    this.qtdFiltrosAtivos += 1;
                }
            }
        }
    }

    retornaEmpresasObrigacaoCalendar(params: NzTableQueryParams, search: string = null, scroll = false, busca = false) {

        this.loadingCardTabsTwo = true;

        this.currentParams.calendar = params;

        if (search) {
            this.currentSearch.calendar = search;
        }

        if (busca) {
            this.currentParams.calendar.pageIndex = 1;
        }

        const url = environment.apiUrl + '/cadastros/obrigacoes-empresas/vencimentos/empresa/' +
            (this.tabAtivaTwo === 0 ? 'oficiais' : 'calendar') +
            '/' +
            this.obrigacaoSelecionada + '/' + this.selectedValue;

        this.abstractService.listToTable(this.currentParams.calendar, this.currentSearch.calendar, url).subscribe((response) => {

            this.vencimentos = {};

            this.vencimentos = response.vencimentos;

            this.vencimentosHeader = {};

            this.vencimentosHeader = response.vencimentos;

            const vencimentos = Object.entries(response.vencimentos);

            this.arrayCalendarCompetencias = [];

            vencimentos.forEach((element) => {

                this.arrayCalendarCompetencias.push({
                    competencia: element[0],
                    index: element[1]
                });

                const competencia = new Date(element[1].toString());

                competencia.setDate(competencia.getDate() + 1);

                this.formCalendar.formGroup.addControl(element[0], new UntypedFormControl(''));

                this.formCalendar.formGroup.get(element[0]).setValue(competencia);

            });

            response.empresas.data.forEach((element) => {
                if (element.vencimentos) {
                    element.objectVencimentos = element.vencimentos;
                    element.vencimentos = Object.entries(element.vencimentos);
                }
            });

            this.arrayTableCalendar = response.empresas.data;

            this.pagination.calendar = new Pagination(
                response?.empresas.per_page || 50,
                response?.empresas.current_page || 1,
                response?.empresas.last_page || 1,
                response?.empresas.total || 50);

            this.loadingCardTabsTwo = false;

            this.checked = false;

            this.resetCheckedSet();

            if (scroll) {
                this.scrollContainer(120);
            }

        });

    }

    changeAno(ano: any) {

        this.selectedValue = ano;

        if (this.tabAtivaTwo === 0) {
            this.retornaEmpresasObrigacoesOficiais(this.currentParams.oficiais, this.currentSearch.oficiais, false);
        }

        if (this.tabAtivaTwo === 1) {
            this.retornaEmpresasObrigacaoCalendar(this.currentParams.calendar, this.currentSearch.calendar);
        }

    }

    resetPageOficiais() {
        this.currentParams.oficiais.pageIndex = 1;
        this.paginaAtualOficiais = 1;
        this.pagination.oficiais.current_page = 1;
    }

    editarObrigacao(obrigacaoId, obrigacaoDescricao, temAgenda = null) {
        this.temAgenda = temAgenda;

        this.loadingEditar.push(obrigacaoId);

        if (this.currentParams.oficiais) {
            this.currentParams.oficiais.filter = [];
            this.resetPageOficiais();
        }

        this.retornaAnosObrigacoes(obrigacaoId, obrigacaoDescricao);
    }

    insertEmpresas(): void {
        this.loadingAdicionarEmpresas = true;

        if (this.tabAtivaTwo === 0) {

            this.validaFormVencimentos();

            if (!this.erro) {

                const dados = Object.entries(this.formObrigacaoVencimento.formGroup.value);

                const vencimentos = dados.filter((el) => {
                    return el[0] !== 'empresa';
                });

                const vencimentosPreenchidos = [];

                vencimentos.forEach((value) => {

                    if (value[1]) {

                        vencimentosPreenchidos.push({
                            competencia: value[0],
                            data: value[1]
                        });

                    }

                });

                const dadosFinais: any = {
                    empresa_id: this.formObrigacaoVencimento.formGroup.value.empresa,
                    obrigacao_id: this.obrigacaoSelecionada,
                    vencimentos: vencimentosPreenchidos,
                };

                this.obrigacoesEmpresaService.insertEmpresaVencimentos(dadosFinais).subscribe((response: any) => {

                    this.toastrService.success('Empresa cadastrada com sucesso!');

                    this.formObrigacaoVencimento.modalVisible = false;
                    this.loadingAdicionarEmpresas = false;

                    this.retornaEmpresasObrigacoesOficiais(this.currentParams.oficiais, this.currentSearch.oficiais, false);

                }, (response) => {

                    this.toastrService.error(response.error.message);

                    this.loadingAdicionarEmpresas = false;

                });

                this.erro = false;
                this.mensagem = '';

            } else {
                this.loadingAdicionarEmpresas = false;

                this.toastrService.error(this.mensagem);
                this.erro = false;

            }

        }

        if (this.tabAtivaTwo === 1) {

            this.validaFormCalendar();

            if (!this.erro) {

                const dados = Object.entries(this.formCalendar.formGroup.value);

                const vencimentos = dados.filter((el) => {
                    return el[0] !== 'empresa' && el[0] !== 'equipe' && el[0] !== 'responsavel_id';
                });

                const vencimentosPreenchidos = [];

                vencimentos.forEach((value) => {

                    if (value[1]) {

                        vencimentosPreenchidos.push({
                            competencia: value[0],
                            data: value[1]
                        });

                    }

                });

                const dadosFinais = {
                    empresa_id: this.formCalendar.formGroup.value.empresa,
                    obrigacao_id: this.obrigacaoSelecionada,
                    responsavel_id: this.formCalendar.formGroup.value.responsavel_id,
                    equipe_id: this.formCalendar.formGroup.value.equipe_id,
                    vencimentos: vencimentosPreenchidos,
                };

                this.obrigacoesEmpresaService.insertEmpresaCadastros(dadosFinais).subscribe((response: any) => {

                    this.toastrService.success('Empresa cadastrada com sucesso!');

                    this.formCalendar.modalVisible = false;

                    this.loadingCardTabsTwo = true;
                    this.loadingAdicionarEmpresas = false;

                    this.retornaEmpresasObrigacaoCalendar(this.currentParams.calendar, this.currentSearch.calendar);

                }, (response) => {

                    this.toastrService.error(response.error.message);

                    this.loadingAdicionarEmpresas = false;

                });

                this.erro = false;
                this.mensagem = '';

            } else {
                this.loadingAdicionarEmpresas = false;

                this.toastrService.error(this.mensagem);
                this.erro = false;

            }

        }


        this.arrayVigencias = [];

    }

    validaFormVencimentos(): void {

        if (!this.formObrigacaoVencimento.formGroup.value.empresa && !this.modalEmpresaEditar) {
            this.erro = true;
            this.mensagem = 'Empresa obrigatória';
        }

    }

    validaFormCalendar(): void {

        if (!this.formCalendar.formGroup.value.empresa && !this.modalEmpresaEditar) {
            this.erro = true;
            this.mensagem = 'Empresa obrigatória';
        }
    }

    showConfirm(id: any, html: any): void {

        this.modalService.confirm({
            nzTitle: 'Deseja remover a(s) Empresa(s)?',
            nzContent: html,
            nzOkText: 'Remover',
            nzCancelText: 'Cancelar',
            nzOnOk: () => this.removeEmpresas(id)
        });

    }

    showConfirmAdicionarEmpresas(): void {

        this.modalService.confirm({
            nzTitle: 'Deseja adicionar as Empresas Sugeridas?',
            nzOkText: 'Adicionar',
            nzCancelText: 'Cancelar',
            nzOnOk: () => new Promise(async resolve => {
                await this.adicionarEmpresasSugeridas();
                setTimeout(resolve, 300);
            }),
        });

    }

    showConfirmRestaurarVencimentos(): void {

        this.modalService.confirm({
            nzTitle: 'Ao confirmar a opção de restaurar vencimento na tab agenda, todos os vencimentos oficiais serão copiados para atividades de Agenda.',
            nzOkText: 'Confirmar',
            nzCancelText: 'Cancelar',
            nzOnOk: () => this.restaurarVencimentosOficiais()
        });

    }

    removeEmpresas(id = null) {

        this.loadingCardTabsTwo = true;

        const ids: string[] = id ? [id] : Array.from(this.checkedItems);


        if (this.tabAtivaTwo === 0) {

            this.obrigacoesEmpresaService.removeEmpresas(ids, this.removerTodosAnos).subscribe((response: any) => {

                this.removerTodosAnos = false;

                this.toastrService.success('Empresa(s) removida(s) com sucesso!');

                this.retornaEmpresasObrigacoesOficiais(this.currentParams.oficiais, this.currentSearch.oficiais, false);

                this.retornaObrigacoesEmpresas();

            }, (response) => {

                this.toastrService.error(response.error.message);

                this.loadingAdicionarEmpresas = false;
                this.loadingCardTabsTwo = false;

            });

        } else if (this.tabAtivaTwo === 1) {

            this.obrigacoesEmpresaService.removeEmpresas(ids, this.removerTodosAnos).subscribe((response: any) => {

                this.toastrService.success('Empresa(s) removida(s) com sucesso!');

                this.retornaEmpresasObrigacaoCalendar(this.currentParams.calendar, this.currentSearch.calendar);

            }, (response) => {

                this.toastrService.error(response.error.message);

                this.loadingAdicionarEmpresas = false;
                this.loadingCardTabsTwo = false;

            });

        }

    }

    async adicionarEmpresasSugeridas() {

        this.loadingAdicionarEmpresas = true;

        const response = await this.obrigacoesEmpresaService
            // tslint:disable-next-line:no-shadowed-variable
            .adicionarEmpresasSugeridas(this.obrigacaoSelecionada, this.selectedValue).toPromise().catch((response) => {
                this.toastrService.error(response.error.message);

                this.loadingAdicionarEmpresas = false;
            });

        this.loadingAdicionarEmpresas = false;

        this.toastrService.success('Empresas adicionadas com sucesso!');

        this.retornaEmpresasObrigacoesOficiais(this.currentParams.oficiais, this.currentSearch.oficiais, false);

        this.retornaEmpresasObrigacaoCalendar(this.currentParams.calendar, this.currentSearch.calendar);

        this.retornaObrigacoesEmpresas();


    }

    async limparEmpresasSugeridas() {

        this.limparSugeridasMassa = true;

        this.obrigacoesEmpresaService
            .limparEmpresasSugeridas().subscribe({
            next: (res) => {

                this.limparSugeridasMassa = false;

                this.toastrService.success(res.messsage);
            },
            error: (err) => {
                this.toastrService.error(err.error.message);

                this.limparSugeridasMassa = false;
            }
        });

    }

    async restaurarVencimentosOficiais() {

        this.loadingRestaurarVencimentos = true;

        this.obrigacoesEmpresaService
            .restaurarVencimentos(this.obrigacaoSelecionada, this.selectedValue).subscribe({
            next: (res) => {
                this.loadingRestaurarVencimentos = false;

                this.toastrService.success(res.message);

                this.retornaEmpresasObrigacoesOficiais(this.currentParams.oficiais, this.currentSearch.oficiais, false);

                this.retornaEmpresasObrigacaoCalendar(this.currentParams.calendar, this.currentSearch.calendar);

                this.retornaObrigacoesEmpresas();
            },
            error: (err) => {
                this.toastrService.error(err.error.message);

                this.loadingRestaurarVencimentos = false;
            }
        });
    }

    addNewEquipe() {
        this.newVisibilidadeModal = true;
    }

    saveBulkToEquipes() {

        this.loadingCardTabsTwo = true;
        this.loadingAlterarEquipe = true;

        this.obrigacoesEmpresaService
            .vincularEquipesCalendar(
                this.checkedItems, this.selectedEquipes, this.limparEquipes, this.alterarEquipeObgPendente
            ).subscribe(() => {

                this.toastrService.success(`Equipes vinculadas com sucesso!`);

                this.selectedEquipes = [];

                this.loadingAlterarEquipe = false;
                this.loadingCardTabsTwo = false;
                this.displayVisibilidadeModal = false;

                this.retornaEmpresasObrigacaoCalendar(this.currentParams.calendar, this.currentSearch.calendar);

            },
            (response) => {
                this.loadingAlterarEquipe = false;
                this.loadingCardTabsTwo = false;
                this.toastrService.error(response.error.message);

            }
        );

    }

    handleCancel() {
        this.displayVisibilidadeModal = false;
    }

    closeNewEquipe() {
        this.newVisibilidadeModal = false;
    }

    async saveNewEquipe() {
        this.loadingAlterarEquipe = true;


        this.obrigacoesEmpresaService.cadastrarEquipe(this.newEquipe).subscribe(async (res) => {
            await this.getEquipes();

            this.newEquipe = '';
            this.loadingAlterarEquipe = false;
            this.newVisibilidadeModal = false;
            this.toastService.success('Equipe cadastrada com sucesso!');
        });


    }

    onAlterarEquipe() {
        this.getEquipes();
        this.selectedEquipes = [];
        this.displayVisibilidadeModal = true;
        this.checked = false;
    }

    async getEquipes() {

        const result = await this.equipeService.listToSelect().toPromise();

        this.equipeOptions = result.map((option: any) => ({
            label: option.nome,
            value: option.id
        }));
    }

    saveBulkToResponsavel() {

        this.loadingCardTabsTwo = true;

        const responsavel = this.formResponsavel.formGroup.value.responsavel_id;

        this.obrigacoesEmpresaService.vincularResponsavelCalendar(
            this.checkedItems, responsavel, this.alterarResObgPendente)
            .subscribe((response) => {

                    this.toastrService.success(`Responsável vinculado com sucesso!`);

                    this.formResponsavel.modalVisible = false;

                    this.loadingCardTabsTwo = false;

                    this.alterarResObgPendente = false;

                    this.retornaEmpresasObrigacaoCalendar(this.currentParams.calendar, this.currentSearch.calendar);

                },
                (response) => {

                    this.loadingCardTabsTwo = false;
                    this.toastrService.error(response.error.message);

                }
            );

    }

    editarVencimentos() {


        if (this.tabAtivaTwo === 0) {

            const dados = Object.entries(this.formObrigacaoVencimento.formGroup.value);

            const vencimentos = dados.filter((el) => {
                return el[0] !== 'empresa' && el[0] !== 'equipe' && el[0] !== 'responsavel' && el[0] !== 'grupo';
            });

            const vencimentosPreenchidos = [];

            vencimentos.forEach((value) => {
                if (value[0].length === 7) {
                    vencimentosPreenchidos.push({
                        competencia: value[0],
                        data: value[1] ?? null
                    });
                }

            });

            const dadosFinais = {
                empresa_id: this.formObrigacaoVencimento.formGroup.value.empresa,
                obrigacao_id: this.obrigacaoSelecionada,
                vencimentos: vencimentosPreenchidos,
                modo: 'oficial'
            };

            this.loadingEditando = true;
            this.obrigacoesEmpresaService.editarEmpresaVencimentos(dadosFinais).subscribe((response) => {

                    this.loadingEditando = false;

                    this.toastrService.success(`Empresa editada com sucesso!`);

                    this.formObrigacaoVencimento.modalVisible = false;

                    this.retornaEmpresasObrigacoesOficiais(this.currentParams.oficiais, this.currentSearch.oficiais, false);

                },
                (response) => {

                    this.loadingEditando = false;
                    this.toastrService.error(response.error.message);

                }
            );

        }

        if (this.tabAtivaTwo === 1) {

            const dados = Object.entries(this.formCalendar.formGroup.value);

            const vencimentos = dados.filter((el) => {
                return el[0] !== 'empresa' && el[0] !== 'equipe' && el[0] !== 'responsavel' && el[0] !== 'grupo';
            });

            const vencimentosPreenchidos = [];

            vencimentos.forEach((value) => {
                if (value[0].length === 7) {
                    vencimentosPreenchidos.push({
                        competencia: value[0],
                        data: value[1] ?? null
                    });
                }
            });

            const dadosFinais = {
                empresa_id: this.formCalendar.formGroup.value.empresa,
                obrigacao_id: this.obrigacaoSelecionada,
                responsavel_id: this.formCalendar.formGroup.value.responsavel_id,
                equipe_id: this.formCalendar.formGroup.value.equipe_id,
                vencimentos: vencimentosPreenchidos,
                modo: 'agenda'
            };

            const data = {
                obrigacaoId: this.obrigacaoSelecionada,
                cnpj: [this.cnpjSelecionadoEditarVencimentos],
                ano: this.selectedValue,
            };

            this.loadingEditando = true;

            this.obrigacoesEmpresaService.verificaSePossuiObgPendente(data).subscribe({
                next: (res: any) => {
                    this.loadingEditando = false;

                    if (res.temPendente) {

                        const modalE: NzModalRef = this.modalService.create({
                            nzTitle: 'Alterar Equipe',
                            // tslint:disable-next-line:max-line-length
                            nzContent: `Deseja realizar a alteração de equipe das atividades da agenda para obrigações que estão pendentes?`,
                            nzFooter: [
                                {
                                    label: 'Não',
                                    type: 'default',
                                    onClick: () => {
                                        modalE.destroy();
                                        this.alterarEquipeObgPendente = false;
                                        modalR();
                                    },
                                },
                                {
                                    label: 'Sim',
                                    type: 'primary',
                                    onClick: () => {
                                        modalE.destroy();
                                        this.alterarEquipeObgPendente = true;
                                        modalR();
                                    }
                                },
                            ]
                        });

                        const modalR = () => {
                            const modal: NzModalRef = this.modalService.create({
                                nzTitle: 'Alterar Responsável',
                                nzContent: `Deseja realizar a alteração de responsável das atividades da agenda para obrigações que estão pendentes?`,
                                nzFooter: [
                                    {
                                        label: 'Não',
                                        type: 'default',
                                        onClick: () => {
                                            this.alterarResObgPendente = false;
                                            modal.destroy();
                                            this.editarEmpCalendarPt2(dadosFinais);
                                        },
                                    },
                                    {
                                        label: 'Sim',
                                        type: 'primary',
                                        onClick: () => {
                                            this.alterarResObgPendente = true;
                                            modal.destroy();
                                            this.editarEmpCalendarPt2(dadosFinais);
                                        }
                                    },
                                ]
                            });
                        };

                    } else {
                        this.alterarResObgPendente = false;
                        this.editarEmpCalendarPt2(dadosFinais);
                    }
                },
                error: (err) => {
                    this.toastService.error(err.error.message);
                }
            });


        }

    }

    editarEmpCalendarPt2(dadosFinais) {
        this.loadingEditando = true;

        console.log(dadosFinais)

        this.obrigacoesEmpresaService.editarEmpresaCalendar(
            dadosFinais, this.alterarResObgPendente, this.alterarEquipeObgPendente
        ).subscribe((response) => {

                this.loadingEditando = false;
                this.toastrService.success(`Empresa editada com sucesso!`);

                this.formCalendar.modalVisible = false;

                this.retornaEmpresasObrigacaoCalendar(this.currentParams.calendar, this.currentSearch.calendar);

            },
            (response) => {

                this.loadingEditando = false;
                this.toastrService.error(response.error.message);

            }
        );
    }

    perguntaAlterarPendentes() {
        this.modalService.confirm({
            nzWidth: 520,
            nzTitle: 'Deseja alterar também nas atividades pendentes do Calendar?',
            nzOkText: 'Sim',
            nzCancelText: 'Não',
            nzOnOk: () => {
                this.editarVencimentosMassa(true);
            },
            nzOnCancel: () => {
                this.editarVencimentosMassa(false);
            }
        });

        if (this.tabAtivaTwo === 1) {
        } else {
            this.editarVencimentosMassa();
        }
    }

    editarVencimentosMassa(alterarPendente: boolean = false) {

        const dados = Object.entries(this.formVencimento.formGroup.value);

        const vencimentosPreenchidos = [];

        dados.forEach((value) => {

            if (value[1]) {

                vencimentosPreenchidos.push({
                    competencia: value[0],
                    data: value[1]
                });

            }

        });

        if (this.tabAtivaTwo === 0) {

            this.obrigacoesEmpresaService.editarEmpresasVencimentosOficiais(vencimentosPreenchidos, this.checkedItems).subscribe(() => {

                    this.toastrService.success(`Empresa(s) editada(s) com sucesso!`);

                    this.formVencimento.modalVisible = false;

                    this.retornaEmpresasObrigacoesOficiais(this.currentParams.oficiais, this.currentSearch.oficiais, false);

                },
                (response) => {
                    this.loadingEditando = false;
                    this.toastrService.error(response.error.message);

                }
            );

        }

        if (this.tabAtivaTwo === 1) {

            // tslint:disable-next-line:max-line-length
            this.obrigacoesEmpresaService.editarEmpresasVencimentosCalendar(vencimentosPreenchidos, this.checkedItems, alterarPendente).subscribe(() => {

                    this.toastrService.success(`Empresa(s) editada(s) com sucesso!`);

                    this.formVencimento.modalVisible = false;

                    this.retornaEmpresasObrigacaoCalendar(this.currentParams.calendar, this.currentSearch.calendar);

                },
                (response) => {

                    this.loadingEditando = false;
                    this.toastrService.error(response.error.message);

                }
            );

        }

    }

    exportarDiferenca(item = null, orgao: string) {
        const toLoading = item.uf ? item.descricao + orgao + item.uf : item.descricao + orgao;
        this.loadingDiferenca[toLoading] = true;

        const matriz = item.label === 'Matriz' ? 1 : 0;

        this.obrigacoesEmpresaService.exportarDiferenca(matriz, orgao, item.uf).subscribe((res) => {

            const blob = new Blob([res], {type: 'text/json; charset=utf-8'});

            const name = 'Diferença';

            fileSaver.saveAs(blob, name + '.xlsx');

            this.loadingDiferenca[toLoading] = false;

        }, (res) => {
            this.toastService.error(res.message);
            this.loadingDiferenca[toLoading] = false;
        });
    }

    reloadCardTwo() {
        this.currentSearch.oficiais = '';
        this.formFiltrar.formGroup.reset();
        this.qtdFiltrosAtivos = 0;

        if (this.tabAtivaTwo === 0) {
            this.resetPageOficiais();
            this.retornaEmpresasObrigacoesOficiais(this.currentParams.oficiais, this.currentSearch.oficiais);
        }

        if (this.tabAtivaTwo === 1) {
            this.retornaEmpresasObrigacaoCalendar(this.currentParams.calendar, this.currentSearch.calendar);
        }

    }

    retornaAlerta() {

        this.obrigacoesEmpresaService.retornaAlerta().subscribe((response) => {

                this.quantidadeEmpresasInscricoes = response;

            },
            (response) => {

                this.toastrService.error(response.error.message);

            }
        );

    }


    filtrarOrgao(orgao: string, fonte: string = null): void {

        this.filtroObrigacoes.orgao = orgao;
        this.filtroObrigacoes.fonte = fonte;

        this.tabAtivaOne = 1;

        this.showTabsTwo = false;

        this.loadingCardTabsOne = true;

        this.retornaObrigacoesEmpresas();

    }


    limparFiltros(): void {
        this.filtrarOrgao(null, null);
        this.buscarFront(true);
    }

    atualizarInfo() {
        this.atualizandoInfo = true;

        this.obrigacoesEmpresaService.atualizarInfo().subscribe((response) => {

            this.atualizandoInfo = false;

            this.toastService.success('Processo iniciado com sucesso');

        }, err => {
            this.toastService.error(err.error.message);
            this.atualizandoInfo = false;
        });

    }

    modalCadastrarEmMassa(visible: boolean): void {

        if (visible) {
            this.formCadastrarEmMassa.formGroup.get('empresa_id').setValue('todas');
            this.formCadastrarEmMassa.formGroup.get('obrigacao_id').setValue('todas');
        } else {
            this.logVisible = false;
        }


        this.formCadastrarEmMassa.modalVisible = visible;

    }

    showConfirmlimparSugeridas(): void {

        this.modalService.confirm({
            nzTitle: 'Deseja limpar as Obrigações Sugeridas?',
            nzOkText: 'Confirmar',
            nzCancelText: 'Cancelar',
            nzOnOk: () => this.limparEmpresasSugeridas()
        });

    }

    changeStep(stepNumber, direction) {

        this.stepNumber = stepNumber;
        this.logVisible = false;

        if (direction === 'next') {

            if (this.stepNumber === 0) {

                this.formStepsObrigacoes.formGroup.removeControl('empresas');

                if (this.radioValueStepZero === 'todas') {

                    this.formStepsObrigacoes.formGroup.addControl('empresas', new UntypedFormControl());
                    this.formStepsObrigacoes.formGroup.get('empresas').setValue('todas');

                }

                if (this.radioValueStepZero === 'algumas') {

                    const empresas: any = Array.from(this.checkedItemsEmpresas);

                    if (empresas.length === 0) {
                        this.toastService.error('Por favor, selecione ao menos uma empresa.');
                        return;
                    }

                    this.formStepsObrigacoes.formGroup.addControl('empresas', this.fb.array(empresas));

                }

                // this.checkedItems.clear();

            }

            if (this.stepNumber === 1) {

                if (!this.formStepsObrigacoes.formGroup.value.modulos || this.formStepsObrigacoes.formGroup.value.modulos.length === 0) {
                    this.toastService.error('Por favor, selecione ao menos um módulo.');
                    return;
                }

            }

            if (this.stepNumber === 2) {

                this.formStepsObrigacoes.formGroup.removeControl('obrigacoes');

                if (this.radioValueStepOne === 'todas') {

                    this.formStepsObrigacoes.formGroup.addControl('obrigacoes', new UntypedFormControl());
                    this.formStepsObrigacoes.formGroup.get('obrigacoes').setValue('todas');

                }

                if (this.radioValueStepOne === 'algumas') {

                    const obrigacoes: any = Array.from(this.checkedItemsObrigacoes);

                    if (obrigacoes.length === 0) {
                        this.toastService.error('Por favor, selecione ao menos uma obrigação.');
                        return;
                    }

                    this.formStepsObrigacoes.formGroup.addControl('obrigacoes', this.fb.array(obrigacoes));


                }

                // this.checkedItems.clear();

            }

            this.stepsLoading = true;

            if (this.stepNumber === 2) {
                this.gravarSteps();
            }

        }

        if (direction === 'prev') {

            this.stepNumber--;

            this.steps[this.stepNumber].step = 'wait';
            this.steps[this.stepNumber].icon = this.icons[this.stepNumber];

            this.stepsLoading = false;

            if (this.stepNumber === 1) {

                this.formStepsObrigacoes.formGroup.value.modulos.forEach((value) => {

                    if (value === 'calendar') {
                        this.modelCalendar = true;
                    }

                    if (value === 'check') {
                        this.modelCheck = true;
                    }

                    if (value === 'compliance') {
                        this.modelCompliance = true;
                    }

                });

                this.nextButtonText = 'Avançar';

            }

        }

        if (this.stepNumber < 2) {

            if (direction === 'next') {

                this.steps[this.stepNumber].step = 'process';
                this.steps[this.stepNumber].icon = 'loading';

                setTimeout(() => {

                    this.steps[this.stepNumber].step = 'finish';
                    this.steps[this.stepNumber].icon = 'check-circle';

                    this.stepNumber++;

                    this.steps[this.stepNumber].step = 'process';

                    this.stepsLoading = false;

                    if (this.stepNumber === 2) {
                        this.nextButtonText = 'Concluir';
                    }

                }, 1000);

            }

        }

    }

    selecionarModulos(value: object[]): void {
        this.formStepsObrigacoes.formGroup.removeControl('modulos');
        this.formStepsObrigacoes.formGroup.addControl('modulos', this.fb.array(value));
    }

    retornaObrigacoes(params: NzTableQueryParams, search: string = null) {

        this.loading = true;

        this.currentParams = params;

        this.items = [];

        let url = '';

        if (this.steps[0].step !== 'finish') {
            url = `${environment.apiUrl}/administracao/empresas`;
        }

        this.abstractService.listToTable(params, search, url).subscribe((response) => {

            this.items = response?.data || response;

            this.pagination = new Pagination(
                response?.per_page || 50,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 50,
                response?.from || 1,
                response?.to || 1);

            this.loading = false;

        });

    }

    retornaObrigacoesEmpresasSteps() {

        this.loadingBuscandoObrigacoes = true;

        this.items = [];

        const dados = {
            modulos: this.formStepsObrigacoes.formGroup.value.modulos,
            empresas: this.formStepsObrigacoes.formGroup.value.empresas
        };

        this.stepsObrigacoesEmpresaService.retornaObrigacoesEmpresas(dados).subscribe((response) => {

            this.items = response?.data || response;
            this.arrayObrigacoesSearch = response?.data || response;
            this.groupBuscaObrigacoes.get('buscarObrigacao').setValue(null);
            this.loadingBuscandoObrigacoes = false;

        });

    }

    gravarSteps(): void {

        this.steps[this.stepNumber].step = 'process';
        this.steps[this.stepNumber].icon = 'loading';

        this.stepsObrigacoesEmpresaService.gravarSteps(this.formStepsObrigacoes.formGroup.value).subscribe((response: any) => {

                this.steps[this.stepNumber].step = 'finish';
                this.steps[this.stepNumber].icon = 'check-circle';

                this.stepNumber++;

                this.steps[this.stepNumber].step = 'process';

                this.stepsLoading = false;

                this.formStepsObrigacoes.formGroup.reset();

                this.checkedItemsEmpresas.clear();

                this.modelCalendar = false;
                this.modelCheck = false;
                this.modelCompliance = false;

                this.checkedItemsObrigacoes.clear();

                this.log = [];

                this.toastService.success('Processo de cadastro iniciado.');

                setTimeout(() => {
                    this.stepNumber = 0;
                    this.modalCadastrarEmMassa(false);
                    this.nextButtonText = 'Avançar';
                }, 1000);

            },
            (response) => {
                this.toastrService.error(response.error.message);
                setTimeout(() => {
                    this.steps = [
                        {
                            icon: 'calendar',
                            step: 'process',
                        },
                        {
                            icon: 'apartment',
                            step: 'wait',
                        },
                        {
                            icon: 'branches',
                            step: 'wait',
                        },
                        {
                            icon: 'check',
                            step: 'finish',
                        },
                    ];
                    this.stepNumber = 0;
                    this.nextButtonText = 'Avançar';
                    this.stepsLoading = false;
                    this.modalCadastrarEmMassa(false);
                }, 1000);

            }
        );

    }

    updateCheckedSetEmpresas(id: string, checked: boolean): void {

        if (checked) {

            this.checkedItemsEmpresas.add(id);

        } else {

            this.checkedItemsEmpresas.delete(id);

        }
    }

    updateCheckedSetObrigacoes(id: string, checked: boolean): void {

        if (checked) {

            this.checkedItemsObrigacoes.add(id);

        } else {

            this.checkedItemsObrigacoes.delete(id);

        }
    }

    onItemCheckedEmpresas(id: string, checked: boolean): void {

        this.updateCheckedSetEmpresas(id, checked);

    }

    onItemCheckedObrigacoes(id: string, checked: boolean): void {

        this.updateCheckedSetObrigacoes(id, checked);

    }

    modalFiltrar(visible: boolean) {
        this.formFiltrar.modalVisible = visible;
    }


    confirmaFiltrar(): void {

        this.currentParams.oficiais.filter = [];

        this.resetPageOficiais();

        const filtros = this.formFiltrar.formGroup.value;

        for (const [chave, valor] of Object.entries(filtros)) {
            if (valor) {
                this.currentParams.oficiais.filter.push({key: chave, value: valor});
            }
        }

        this.retornaEmpresasObrigacoesOficiais(this.currentParams.oficiais, this.currentSearch.oficiais);

        this.modalFiltrar(false);

    }

    onExpandChange(tipoObg: string, checked: boolean): void {

        if (checked) {
            this.expandSet.add(tipoObg);
        } else {
            this.expandSet.delete(tipoObg);
        }

        this.column1Width = this.column1.nativeElement.offsetWidth;
        this.column2Width = this.column2.nativeElement.offsetWidth;
        this.column3Width = this.column3.nativeElement.offsetWidth;
        this.column4Width = this.column4.nativeElement.offsetWidth;
        this.column5Width = this.column5.nativeElement.offsetWidth;
        this.column6Width = this.column6.nativeElement.offsetWidth;

    }

    onExpandChangeMunicipal(tipoObg: string, checked: boolean): void {

        if (checked) {
            this.expandSetMunicipal.add(tipoObg);
        } else {
            this.expandSetMunicipal.delete(tipoObg);
        }

        this.column7Width = this.column7.nativeElement.offsetWidth;
        this.column8Width = this.column8.nativeElement.offsetWidth;
        this.column9Width = this.column9.nativeElement.offsetWidth;
        this.column10Width = this.column10.nativeElement.offsetWidth;
        this.column11Width = this.column11.nativeElement.offsetWidth;
        this.column12Width = this.column12.nativeElement.offsetWidth;
        this.column13Width = this.column13.nativeElement.offsetWidth;

    }

    showConfirmAlterarResponsavel(): void {

        this.loadingAlterarRespLote = true;

        const data = {
            obrigacaoId: this.obrigacaoSelecionada,
            cnpj: [],
            ano: this.selectedValue,
        };

        this.arrayTableCalendar.forEach(i => {
            if (this.checkedItems.has(i.id)) {
                data.cnpj.push(i.cnpj);
            }
        });

        this.obrigacoesEmpresaService.verificaSePossuiObgPendente(data).subscribe({
            next: (res: any) => {

                this.loadingAlterarRespLote = false;

                if (res.temPendente) {
                    const modal: NzModalRef = this.modalService.create({
                        nzTitle: 'Alterar Responsável',
                        nzContent: `Deseja realizar a alteração de responsável das atividades da agenda para obrigações que estão pendentes?`,
                        nzFooter: [
                            {
                                label: 'Não',
                                type: 'default',
                                onClick: () => {
                                    this.alterarResObgPendente = false;
                                    modal.destroy();
                                    this.modalAlterarResponsavel(true);
                                },
                            },
                            {
                                label: 'Sim',
                                type: 'primary',
                                onClick: () => {
                                    this.alterarResObgPendente = true;
                                    modal.destroy();
                                    this.modalAlterarResponsavel(true);
                                }
                            },
                        ]
                    });

                } else {
                    this.loadingAlterarRespLote = false;
                    this.modalAlterarResponsavel(true);
                }
            },
            error: (err) => {
                this.alterarResObgPendente = false;
                this.toastService.error(err.error.message);
            }
        });
    }

    showConfirmAlterarEquipe(): void {

        const data = {
            obrigacaoId: this.obrigacaoSelecionada,
            cnpj: [],
            ano: this.selectedValue,
        };

        this.arrayTableCalendar.forEach(i => {
            if (this.checkedItems.has(i.id)) {
                data.cnpj.push(i.cnpj);
            }
        });

        this.obrigacoesEmpresaService.verificaSePossuiObgPendente(data).subscribe({
            next: (res: any) => {

                if (res.temPendente) {
                    const modal: NzModalRef = this.modalService.create({
                        nzTitle: 'Alterar Equipe',
                        nzContent: `Deseja realizar a alteração de equipe das atividades da agenda para obrigações que estão pendentes?`,
                        nzFooter: [
                            {
                                label: 'Não',
                                type: 'default',
                                onClick: () => {
                                    modal.destroy();
                                    this.alterarEquipeObgPendente = false;
                                    this.onAlterarEquipe();
                                },
                            },
                            {
                                label: 'Sim',
                                type: 'primary',
                                onClick: () => {
                                    modal.destroy();
                                    this.alterarEquipeObgPendente = true;
                                    this.onAlterarEquipe();
                                }
                            },
                        ]
                    });

                } else {
                    this.alterarEquipeObgPendente = false;
                    this.onAlterarEquipe();
                }
            },
            error: (err) => {
                this.alterarResObgPendente = false;
                this.toastService.error(err.error.message);
            }
        });
    }

    verLog() {

        if (this.logVisible) {
            this.logVisible = false;
        } else {
            if (!this.log.length) {
                this.loadingLog = true;
                this.obrigacoesEmpresaService.verLog().subscribe({
                    next: (res) => {
                        this.log = res || [];
                        this.loadingLog = false;
                        this.logVisible = true;
                    },
                    error: (err) => {
                        this.loadingLog = false;
                        this.toastService.error(err.error.message);
                    }
                });
            } else {
                this.logVisible = true;
            }
        }

    }


    changeBuscaObrigacao() {
        if (this.groupBuscaObrigacoes.get('buscarObrigacao').value) {
            this.arrayObrigacoesSearch = [];
            this.items.forEach(value => {
                if (value.descricao.toLowerCase().includes(this.groupBuscaObrigacoes.get('buscarObrigacao').value.toLowerCase())) {
                    this.arrayObrigacoesSearch.push(value);
                }
            });
        } else {
            this.arrayObrigacoesSearch = this.items;
        }

    }
}
