import {Component, ElementRef, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {NotasFiscaisEntradaService} from './notas-fiscais-entrada.service';
import {ToastrService} from 'ngx-toastr';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {Pagination} from '@models/pagination.model';
import {StickyHeaderDirective} from '@components/directive/directive';
import {buildUrl, findComponentByUrl} from '../../../shared/components-helper';
import {Tab} from '@models/tab.model';
import {TabService} from '@services/tab.service';
import {Helpers} from '../../../core/helpers';
import {NfeTerceirosService} from '../../notas/nfe-terceiros/nfe-terceiros.service';
import {NzModalService} from 'ng-zorro-antd/modal';
import {environment} from "../../../../environments/environment";
import {style} from "@angular/animations";

@Component({
    selector: 'app-notas-fiscais-entrada',
    templateUrl: './notas-fiscais-entrada.component.html',
    styleUrls: ['./notas-fiscais-entrada.component.scss']
})
export class NotasFiscaisEntradaComponent {

    autoTips: Record<string, Record<string, string>> = {
        default: {
            required: 'Campo obrigatório',
            notNumber: 'Informe apenas números',
            notNumberOrComma: 'Informe apenas números ou vígula',
        }
    };

    Helpers = Helpers;

    loading = false;
    loadingComboCategorias = false;
    loadingComboStatus = false;
    loadingM = false;
    loadingMUnico = null;
    loadingBt = false;
    loadingCardStatus = false;
    modalFiltrarVisible = false;
    modalFiltrarTabelaVisible = false;

    pageIndex = 1;
    pageSize = 100;

    currentParams = {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        sort: [],
        filter: []
    };

    pagination: Pagination = {
        per_page: this.pageSize,
        current_page: this.pageIndex,
        last_page: 10,
        total: 100,
        from: 1,
        to: 100
    };

    meses = [
        {descricao: 'Janeiro', valor: '01'},
        {descricao: 'Fevereiro', valor: '02'},
        {descricao: 'Março', valor: '03'},
        {descricao: 'Abril', valor: '04'},
        {descricao: 'Maio', valor: '05'},
        {descricao: 'Junho', valor: '06'},
        {descricao: 'Julho', valor: '07'},
        {descricao: 'Agosto', valor: '08'},
        {descricao: 'Setembro', valor: '09'},
        {descricao: 'Outubro', valor: '10'},
        {descricao: 'Novembro', valor: '11'},
        {descricao: 'Dezembro', valor: '12'},
    ];

    anos = [];

    currentSearch: string;

    categorias = [];
    notas = [];
    statusWorkflow = [];

    statusSelecionado = null;
    showMotivo = false;
    showObs = false;

    statusOne = false;
    statusTwo = false;

    statusThree = false;

    empresasSelect = [];

    formFiltrosCabecalho = this.fb.group({
        empresa_id: [null, null],
        periodoInicio: [null, null],
        periodoFim: [null, null],
        mes: [null, null],
        ano: [null, null],
        intervalo: ['mesAno', null],
        notas: [],
        chave: [],
    });

    formFiltrosTable = this.fb.group({
        notas: [],
        chave: [],
        status: [null, null],
        categoria: [null, null],
        emitente: [null, null],
        destinatario: [null, null],
        periodoInicio: [null, null],
        periodoFim: [null, null],
        mes: [null, null],
        ano: [null, null],
        marcadores: [null, null],
    });

    observacao = new FormControl(null, [Validators.required]);

    formAlterar = this.fb.group({
        notas: [null, null],
        etapa_id: [null, Validators.required],
        motivo: [null],
        observacao: this.observacao
    });

    qtdFiltrosAtivos = 0;
    qtdFiltrosTabelaAtivos = 0;

    expandSet = new Set<number>();

    categoriaSelecionada = null;

    target: any;

    maskDoc = '00.000.000/0000-00';

    private cardTable;

    checkedItems = new Set<string>();
    checkedItensMarcadores = new Set<string>();

    checked = false;
    checkedMarcadores = false;

    modalTrocarStatusVisible = false;

    arrayMarcadores = [];
    arrayCategorias = [];
    arrayStatus = [];

    novoMarcadorDescricao = null;
    corNovoMarcador = '#000';

    modalNovoMarcador = false;

    mesAtual = new Date().getMonth() + 1;

    modalGerenciarMarcadoresVisible = false;
    sizeOptions = [];

    @ViewChild('cardTable') set contentCard(content: ElementRef) {
        if (content) {
            this.cardTable = content;
        }
    }


    tabFilterNumber = 0;

    constructor(
        private fb: FormBuilder,
        private service: NotasFiscaisEntradaService,
        private toastrService: ToastrService,
        private nfeTerceirosService: NfeTerceirosService,
        private tabService: TabService,
        private modalService: NzModalService) {
    }

    ngOnInit() {

        this.formFiltrosCabecalho.get('mes').setValue(this.mesAtual < 10 ? '0' + this.mesAtual : this.mesAtual + '');
        this.formFiltrosCabecalho.get('ano').setValue(new Date().getFullYear());

        this.calculaBadgeFiltros();

        const anoAtual = new Date().getFullYear();

        this.anos.push(anoAtual);

        for (let i = 1; i <= 40; i++) {
            this.anos.push(anoAtual - i);
        }

        this.getComboCategorias();
        this.getComboStatus();
        this.getStatus();
        this.getCategorias();
        this.getEmpresas();
        this.getMarcadores();

        this.formFiltrosTable.get('categoria').valueChanges.subscribe(categoriaId => {
            this.getComboStatus(categoriaId);
        });
    }

    getComboCategorias() {

        this.loadingComboCategorias = true;

        this.service.getComboCategorias().subscribe({
            next: res => {
                this.arrayCategorias = res;
                this.loadingComboCategorias = false;
            },
            error: error => {
                this.toastrService.error(error.error.message);
                this.loadingComboCategorias = false;
            }
        });

    }

    getComboStatus(categoriaId?: string) {
        this.loadingComboStatus = true;
        this.formFiltrosTable.controls.status.setValue(null);

        this.service.getComboStatus(categoriaId).subscribe({
            next: res => {
                this.arrayStatus = res;
                this.loadingComboStatus = false;
            },
            error: error => {
                this.toastrService.error(error.error.message);
                this.loadingComboStatus = false;
            }
        });

    }

    ngAfterViewInit() {

        this.target = StickyHeaderDirective.target;

    }

    changeTabs(event: any = null) {
        // this.formFiltrosCabecalho.reset();
    }

    getEmpresas() {
        this.loadingCardStatus = true;

        this.service.retornarOptionEmpresas().subscribe({
                next: (response) => {
                    this.empresasSelect = response;

                },
                error: (response) => {
                    this.toastrService.error(response.error.message);

                }
            }
        );

    }

    getCategorias() {
        this.loadingCardStatus = true;

        this.loading = true;

        this.service.getCategorias(this.formFiltrosCabecalho.value).subscribe({
                next: (response) => {

                    this.categorias = response;

                    let index = response.length;

                    this.categorias.forEach((value) => {
                        value.zIndex = index;
                        index--;
                    });

                    this.loadingCardStatus = false;

                    this.filtrarTabela();

                    this.scrollContainer(100);

                },
                error: (response) => {
                    this.toastrService.error(response.error.message);

                }
            }
        );

    }

    getMarcadores() {

        this.loadingM = true;

        this.checkedItensMarcadores.clear();

        this.service.getMarcadores().subscribe((response) => {
            this.arrayMarcadores = response;
            this.loadingM = false;
        }, (err) => {
            this.loadingM = false;
            this.toastrService.error(err.error.message);
        });
    }

    getStatus() {
        this.service.getStatusW().subscribe((res) => {
                this.statusWorkflow = res;
            },
            (response) => {
                this.toastrService.error(response.error.message);

            }
        );
    }

    filtarCategoriaByCard(id: string) {

        if (this.categoriaSelecionada === id) {
            this.formFiltrosTable.get('categoria').setValue(null);
        } else {
            this.formFiltrosTable.get('categoria').setValue(id);
        }

        this.filtrarTabela();

        this.scrollContainer(100);

    }

    filtrarStatusByCard(id: string) {

        if (this.categoriaSelecionada === id) {
            this.formFiltrosTable.get('status').setValue(null);
        } else {
            this.formFiltrosTable.get('status').setValue(id);
        }

        this.filtrarTabela();

        this.scrollContainer(100);

    }

    calculaBadgeFiltros(): void {
        this.qtdFiltrosAtivos = 0;
        if (typeof this.formFiltrosCabecalho !== 'undefined') {
            for (const [chave, valor] of Object.entries(this.formFiltrosCabecalho.value)) {

                if (valor && !['status_id', 'intervalo'].includes(chave)) {
                    this.qtdFiltrosAtivos++;
                }
            }
        }
    }

    calculaBadgeFiltrosTabela(): void {
        this.qtdFiltrosTabelaAtivos = 0;

        if (this.tabFilterNumber === 1) {
            return;
        }

        if (typeof this.formFiltrosTable !== 'undefined') {
            for (const [chave, valor] of Object.entries(this.formFiltrosTable.value)) {

                if (valor && !['status_id', 'intervalo', 'page', 'periodoInicio', 'periodoFim', 'mes', 'ano'].includes(chave)) {
                    this.qtdFiltrosTabelaAtivos++;
                }
            }
        }
    }

    modalFiltrar(visible) {
        this.modalFiltrarVisible = visible;

        if (!this.formFiltrosCabecalho.get('chave').value?.length &&
            !this.formFiltrosCabecalho.get('notas').value?.length) {
            this.tabFilterNumber = 0;
        }
    }

    clearFiltros() {
        this.currentSearch = null;
        this.formFiltrosTable.reset();
        this.formFiltrosCabecalho.reset();
        this.formFiltrosCabecalho.get('intervalo').setValue('mesAno');
        this.formFiltrosCabecalho.get('ano').setValue(new Date().getFullYear());
        this.tabFilterNumber = 0;

        this.filtrar();
    }


    modalFiltrarTabela(visible) {
        this.modalFiltrarTabelaVisible = visible;
    }

    listByTable(params: NzTableQueryParams) {
        let buscar = false;

        if (params.pageIndex === this.pagination.current_page) {
            params.sort.forEach(s => {
                if (s.value) {
                    buscar = true;
                }
            });
        }

        if ((buscar || params.pageIndex === 1 && this.pagination.current_page > 1)
            || params.pageIndex > 1
            && params.pageIndex !== this.pagination.current_page) {
            params.filter = this.currentParams.filter;
            this.queryTable(params);
            this.pagination.current_page = params.pageIndex;
        }

    }

    queryTable(params: NzTableQueryParams, search: string = null): void {

        this.checkedItems.clear();
        this.notas = [];

        this.loading = true;
        this.currentParams = params;
        this.currentSearch = search;
        this.calculaBadgeFiltrosTabela();

        const filtros = params.filter.map(f => {
            if (f?.value) {
                return f;
            }
        });

        const {pageSize, pageIndex, sort} = params;
        const currentSort = sort.find(item => item.value !== null);
        const sortField = (currentSort && currentSort.key) || null;
        const sortOrder = (currentSort && currentSort.value) || null;

        if (pageIndex !== null && typeof pageIndex !== 'undefined') {
            filtros.push({key: `pagina`, value: pageIndex});
        }

        if (pageSize !== null && typeof pageSize !== 'undefined') {
            filtros.push({key: `quantidade`, value: pageSize});
        }

        if (sortField !== null && typeof sortField !== 'undefined') {
            filtros.push({key: `ordenar`, value: sortField});
            filtros.push({key: `sentido`, value: sortOrder});
        }

        this.service.getEntradas(filtros).subscribe({
            next: (response) => {

                this.notas = response?.data;

                this.pagination = new Pagination(
                    response?.per_page || 100,
                    response?.current_page || 1,
                    response?.last_page || 1,
                    response?.total || 50,
                    response?.from || 1,
                    response?.to || 1);

                this.sizeOptions = [50, 100, 200, 300, 500];

                let remove = this.sizeOptions[this.sizeOptions.length - 1] > this.pagination?.total;

                while (remove) {

                    this.sizeOptions.pop();

                    remove = this.sizeOptions[this.sizeOptions.length - 1] > this.pagination?.total;

                }

                this.sizeOptions.push(this.pagination?.total);

                this.loading = false;
            }, error: (err) => {
                this.loading = false;
                this.toastrService.error(err.error.message);
            }
        });

    }

    btnResetSearch() {

        this.currentSearch = null;

        this.currentParams = {
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            sort: [],
            filter: [],
        };

        this.formFiltrosTable.reset();

        this.formFiltrosTable.get('periodoInicio').setValue(this.formFiltrosCabecalho.get('periodoInicio').value);
        this.formFiltrosTable.get('periodoFim').setValue(this.formFiltrosCabecalho.get('periodoFim').value);
        this.formFiltrosTable.get('mes').setValue(this.formFiltrosCabecalho.get('mes').value);
        this.formFiltrosTable.get('ano').setValue(this.formFiltrosCabecalho.get('ano').value);

        for (const [chave, valor] of Object.entries(this.formFiltrosTable.value)) {
            this.currentParams.filter.push({key: chave, value: valor});
        }

        this.calculaBadgeFiltrosTabela();

        this.queryTable(this.currentParams, this.currentSearch);

    }

    resetFiltroData() {
        if (this.formFiltrosCabecalho.value.intervalo === 'mesAno') {
            this.formFiltrosCabecalho.get('periodoInicio').setValue(null);
            this.formFiltrosCabecalho.get('periodoFim').setValue(null);
            this.formFiltrosTable.get('periodoInicio').setValue(null);
            this.formFiltrosTable.get('periodoFim').setValue(null);

        } else {
            this.formFiltrosCabecalho.get('mes').setValue(null);
            this.formFiltrosCabecalho.get('ano').setValue(null);
            this.formFiltrosTable.get('mes').setValue(null);
            this.formFiltrosTable.get('ano').setValue(null);
        }
    }

    filtrar(): any {

        this.formFiltrosTable.reset();
        this.currentParams.pageIndex = 1;

        if (this.tabFilterNumber === 1) {
            this.formFiltrosCabecalho.get('empresa_id').setValue(null);
            this.formFiltrosCabecalho.get('periodoInicio').setValue(null);
            this.formFiltrosCabecalho.get('periodoFim').setValue(null);
            this.formFiltrosCabecalho.get('mes').setValue(null);
            this.formFiltrosCabecalho.get('ano').setValue(null);
        } else {
            this.formFiltrosCabecalho.get('chave').setValue(null);
            this.formFiltrosCabecalho.get('notas').setValue(null);
        }

        this.calculaBadgeFiltros();

        this.modalFiltrarVisible = false;

        this.formFiltrosTable.get('notas').setValue(this.formFiltrosCabecalho.get('notas').value);
        this.formFiltrosTable.get('chave').setValue(this.formFiltrosCabecalho.get('chave').value);

        this.resetFiltroData();

        this.getCategorias();

    }

    filtrarTabela(): any {

        this.currentParams.pageIndex = 1;

        this.formFiltrosTable.get('periodoInicio').setValue(this.formFiltrosCabecalho.get('periodoInicio').value);
        this.formFiltrosTable.get('periodoFim').setValue(this.formFiltrosCabecalho.get('periodoFim').value);
        this.formFiltrosTable.get('mes').setValue(this.formFiltrosCabecalho.get('mes').value);
        this.formFiltrosTable.get('ano').setValue(this.formFiltrosCabecalho.get('ano').value);

        this.modalFiltrarTabelaVisible = false;

        let filtros;

        const params = this.currentParams;
        params.filter = [];
        params.pageIndex = 1;

        filtros = this.formFiltrosTable.value;

        this.categoriaSelecionada = null;

        for (const [chave, valor] of Object.entries(filtros)) {

            if (chave === 'categoria') {
                this.categoriaSelecionada = valor;
            }

            params.filter.push({key: chave, value: valor});

        }


        this.queryTable(params, this.currentSearch);
    }

    clickEvent(card) {

        switch (card) {

            case 'one':
                this.statusOne = !this.statusOne;
                break;

            case 'two':
                this.statusTwo = !this.statusTwo;
                break;

        }
    }

    onExpandChange(id: number, checked: boolean): void {
        if (checked) {
            this.expandSet.add(id);
        } else {
            this.expandSet.delete(id);
        }
    }

    scrollContainer(px = 0) {

        setTimeout(() => {
            this.target.scrollTo({
                left: 0,
                top: this.cardTable.nativeElement.offsetTop + px,
                behavior: 'smooth'
            });
        }, 300);

    }

    openTab(componentName: string, queryParams?: string, data?: any) {
        const component = findComponentByUrl(componentName);
        const url = buildUrl(component, queryParams);
        const newTab = new Tab(component.name, component.title, url, component.urlType, data);
        this.tabService.closeAndAddTab(newTab);

    }

    onItemChecked(id: string, checked: boolean): void {

        this.updateCheckedSet(id, checked);
    }

    onMarcadorChecked(id: string, checked: boolean): void {

        this.updateCheckedSetMarcadores(id, checked);
    }

    updateCheckedSet(id: string, checked: boolean): void {

        if (checked) {

            this.checkedItems.add(id);

        } else {

            this.checkedItems.delete(id);

        }

        if (this.notas.length !== this.checkedItems.size) {
            this.checked = false;
        } else {
            this.checked = true;
        }
    }

    updateCheckedSetMarcadores(id: string, checked: boolean): void {

        if (checked) {

            this.checkedItensMarcadores.add(id);

        } else {

            this.checkedItensMarcadores.delete(id);

        }

        if (this.arrayMarcadores.length !== this.checkedItensMarcadores.size) {
            this.checkedMarcadores = false;
        } else {
            this.checkedMarcadores = true;
        }
    }

    onAllChecked(checked: boolean): void {
        this.notas.forEach(item => {
            this.updateCheckedSet(item.emissao + '_' + item.modelo_id + '_' + item.chave, checked);
        });
    }

    onAllCheckedMarcadores(checked: boolean): void {

        this.arrayMarcadores.forEach(item => {
            this.updateCheckedSetMarcadores(item.marcador, checked);
        });

    }

    modalTrocarStatus(visible: boolean) {

        this.modalTrocarStatusVisible = visible;
    }

    alterarStatus() {

        this.formAlterar.get('notas').setValue(Array.from(this.checkedItems));

        if (this.formAlterar.valid) {
            this.loadingBt = true;

            const data: any = {
                ids: Array.from(this.checkedItems),
                etapa: this.formAlterar.get('etapa_id').value
            };

            if (this.formAlterar.get('motivo').value) {
                data.motivo = this.formAlterar.get('motivo').value;
            }

            if (this.formAlterar.get('observacao').value) {
                data.observacao = this.formAlterar.get('observacao').value;
            }

            this.nfeTerceirosService.alterarNota(data).subscribe({
                next: (res) => {
                    this.loadingBt = false;
                    this.modalTrocarStatus(false);
                    this.toastrService.success(res.message);
                    if (res.errors) {
                        res.errors.forEach(error => {
                            this.toastrService.error(error);
                        });
                    }
                    this.checkedItems.clear();
                    this.checked = false;
                    this.getCategorias();
                    this.formAlterar.reset();

                }, error: (err) => {
                    this.loadingBt = false;
                    this.toastrService.error(err.error.message);
                }
            });

        } else {
            Object.values(this.formAlterar.controls).forEach(control => {
                if (control.invalid) {
                    control.markAsDirty();
                    control.updateValueAndValidity({onlySelf: true});
                }
            });
        }
    }

    modalNovoMatrcador(visible) {
        this.modalNovoMarcador = visible;
    }

    novoMarcador(existente: string = null): void {

        this.novoMarcadorDescricao = existente ? existente : this.novoMarcadorDescricao;

        if (existente) {
            this.arrayMarcadores?.forEach(m => {
                if (m.marcador === existente) {
                    this.corNovoMarcador = m.cor;
                }
            });
        }

        if (this.novoMarcadorDescricao) {
            this.loadingBt = true;
            this.service.novoMarcador(this.novoMarcadorDescricao, this.corNovoMarcador, Array.from(this.checkedItems))
                .subscribe((response) => {
                    this.toastrService.success(response.message || 'Ação realizada com sucesso.');
                    this.checkedItems.clear();
                    this.queryTable(this.currentParams, this.currentSearch);
                    this.novoMarcadorDescricao = null;
                    this.corNovoMarcador = '#000';
                    this.modalNovoMatrcador(false);
                    this.getMarcadores();
                    this.checkedItems.clear();
                    this.loadingBt = false;
                }, error => {
                    this.loadingBt = false;
                    this.toastrService.error(error.error.message);
                });
        } else {
            this.toastrService.error('Marcador inválido!');
        }
    }

    changeStatus() {

        const manifestacaoTipo_id = this.statusWorkflow.find(s => s.id === this.statusSelecionado)?.manifestacaoTipo_id;

        if (manifestacaoTipo_id === 3) {
            this.showMotivo = true;
            this.formAlterar.get('motivo').setValidators(Validators.required);
        } else {
            this.showMotivo = false;
            this.formAlterar.get('motivo').clearValidators();
            this.formAlterar.get('motivo').setValue(null);
        }

        this.formAlterar.get('motivo').updateValueAndValidity();

        const obs = this.statusWorkflow.find(s => s.id === this.formAlterar.get('etapa_id').value)?.observacao;

        if (obs) {
            this.observacao.enable();
            this.observacao.setValidators([Validators.required]);
            this.showObs = true;
        } else {
            this.observacao.disable();
            this.observacao.setValidators(null);
            this.showObs = false;
        }

        this.observacao.updateValueAndValidity();
    }

    modalGerenciarMarcadores(visible: boolean) {
        if (visible) {
            this.getMarcadores();
        }
        this.modalGerenciarMarcadoresVisible = visible;
    }

    confirmRemoverMarcador(marcador = null) {
        this.modalService.confirm({
            nzTitle: !marcador ? 'Deseja remover os marcadores selecionados?' : `Deseja remover o marcador ${marcador}?`,
            nzOkText: 'Remover',
            nzCancelText: 'Cancelar',
            nzOnOk: () => this.removerMarcador(marcador)
        });
    }

    removerMarcador(marcador = null) {

        marcador ? this.loadingMUnico = marcador : this.loadingBt = true;

        const ids = marcador ? [marcador] : Array.from(this.checkedItensMarcadores);

        this.service.deletarMarcadores(ids).subscribe({
            next: (res) => {
                this.loadingBt = false;
                this.loadingMUnico = null;
                this.toastrService.success(res.message);
                this.getMarcadores();
            },
            error: (err) => {
                this.loadingBt = false;
                this.loadingMUnico = null;
                this.toastrService.error(err.error.message);
            }
        });

    }

    chagePageSize(porPagina: number) {

        this.currentParams.pageSize = porPagina;
        this.currentParams.pageIndex = 1;

        this.queryTable(this.currentParams, this.currentSearch);
    }

    exportarDados() {


        const params = this.currentParams;

        const url = `${environment.apiUrl}/exportador`;

        this.service.exportarDados(params, this.currentSearch, url).subscribe((res) => {

            const message = res.message + ' Acompanhe na central de downloads.';

            this.toastrService.success(message);

            this.openTab('/downloadCenter', '', {});

        }, (res) => {
            this.toastrService.error(res.error.message);
        });
    }


    protected readonly style = style;
}
