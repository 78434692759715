import {ChangeDetectorRef, Component, ElementRef, Input, NgZone, OnChanges, OnInit, ViewChild} from '@angular/core';
import {ComplianceTributosService} from '../../../modules/compliance/complianceTributos/complianceTributos.service';
import {TabService} from '@services/tab.service';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {buildUrl, getComponentConfigByName} from '../../../shared/components-helper';
import {Tab} from '@models/tab.model';
import {ExportarTabelaComponent} from '@components/exportar-tabela/exportar-tabela.component';
import {Subscription} from "rxjs";

interface FormStack {
    modalVisible: boolean;
    formGroup: UntypedFormGroup;
}

@Component({
    selector: 'app-card-tributo-por-grupo',
    templateUrl: './card-tributo-por-grupo.component.html',
    styleUrls: ['./card-tributo-por-grupo-component.scss']
})
export class CardTributoPorGrupoComponent implements OnInit, OnChanges {

    @Input() data;

    anoAtivo = new Date().getFullYear();

    status = false;
    cardLoadingTabs = false;
    tabNumber = 0;
    lastTabNumber = 1;
    valorTolerancia = 10;
    tipoTolerancia = 'federal';

    formFiltrar: FormStack;

    qtdFiltrosAtivos = 0;
    totalRegistros = 0;

    obrigacaoSelecionada;

    arrayImpostos = ['', ''];

    cardTributosLoading = true;

    arrayTableObrigacoesEvolucao: any = [];

    idsObrigacoesEvolucao = [];

    expandSet = new Set<number>();

    expandAll = false;

    limiteTentativas = 0;

    dataExport: any;

    arraySelectEmpresa = [];
    arraySelectCnpj = [];
    arraySelectIe = [];

    @ViewChild('column1') column1: ElementRef;
    column1Width = 0;

    @ViewChild('column2') column2: ElementRef;
    column2Width = 0;

    @ViewChild('column3') column3: ElementRef;
    column3Width = 0;

    @ViewChild('column4') column4: ElementRef;
    column4Width = 0;

    @ViewChild('column5') column5: ElementRef;
    column5Width = 0;

    @ViewChild('column6') column6: ElementRef;
    column6Width = 0;

    @ViewChild('column7') column7: ElementRef;
    column7Width = 0;

    @ViewChild('column8') column8: ElementRef;
    column8Width = 0;

    @ViewChild('column9') column9: ElementRef;
    column9Width = 0;

    @ViewChild('column10') column10: ElementRef;
    column10Width = 0;

    @ViewChild('column11') column11: ElementRef;
    column11Width = 0;

    @ViewChild('componentExport') componentExport: ExportarTabelaComponent;

    requestObg: Subscription;
    requestTributo: Subscription;

    constructor(
        private complianceTributos: ComplianceTributosService,
        private cdr: ChangeDetectorRef,
        private tabService: TabService,
        private fb: UntypedFormBuilder,
        private zone: NgZone,
    ) {
        this.formFiltrar = {
            modalVisible: false,
            formGroup: this.fb.group({
                empresa_id: [null, null],
                ie: [null, null],
                imposto: [null, null],
            })
        };

        this.complianceTributos.retornarSelectsEmpresas(
        ).subscribe((retorno: any) => {
            this.arraySelectEmpresa = retorno.empresaNome;
            this.arraySelectCnpj = retorno.empresaCnpj;
            this.arraySelectIe = retorno.empresaIe;

        });

    }

    ngOnInit() {
        this.anoAtivo = this.data.anoAtivo || this.anoAtivo;
        this.retornaObrigacoes(this.anoAtivo);
    }

    ngOnChanges(): void {
        this.anoAtivo = this.data.anoAtivo || this.anoAtivo;
        this.retornaObrigacoes(this.anoAtivo);
    }

    clickEvent() {
        this.status = !this.status;
    }

    changeTabs(filtrar = false) {

        this.requestTributo?.unsubscribe();

        if (this.tabNumber === this.lastTabNumber && !filtrar) {
            return;
        }

        this.lastTabNumber = this.tabNumber;

        if (this.tabNumber === 4 || this.tabNumber === 5) {
            this.valorTolerancia = 1;
            this.tipoTolerancia = 'estadual';
        } else {
            this.valorTolerancia = 10;
            this.tipoTolerancia = 'federal';
        }

        if (this.cardLoadingTabs === false) {
            this.cardLoadingTabs = true;
        }

        this.calculaBadgeFiltros();
        const imposto = this.arrayImpostos[this.tabNumber];

        this.obrigacaoSelecionada = imposto;

        const ano = this.anoAtivo;

        // Impostos por Grupo
        const filtros = this.formFiltrar.formGroup.value;

        if (this.data.grupoId) {
            filtros.grupoEmpresarial = this.data.grupoId;
        }

        this.cardTributosLoading = true;

        this.requestTributo = this.complianceTributos.retornaObrigacoesGrupo(ano, imposto, filtros).subscribe((retorno: any) => {

            this.zone.run(() => {
                this.arrayTableObrigacoesEvolucao = retorno.dados?.data;

                if (this.arrayTableObrigacoesEvolucao) {
                    for (const [index, item] of this.arrayTableObrigacoesEvolucao.entries()) {
                        this.idsObrigacoesEvolucao.push(item.id);
                        if (item.unidades.length < 15) {
                            this.expandSet.add(item.id);
                        }
                    }
                }

                if (this.expandSet.size === 0) {
                    this.expandAll = false;
                } else {
                    this.expandAll = true;
                }

                this.totalRegistros = 0;
                this.arrayTableObrigacoesEvolucao?.forEach(value => {
                    this.totalRegistros += value.unidades.length;
                });
            });

            this.cardLoadingTabs = false;

            setTimeout(() => {
                this.largurasTable();
            }, 300);

        }, (res) => {
            this.cardLoadingTabs = false;
        });

    }

    openTab(componentName: string, queryParams?: string, data?: {}) {
        const component = getComponentConfigByName(componentName);
        const url = buildUrl(component, queryParams);
        const newTab = new Tab(component.name, component.title, url, component.urlType, data);
        this.tabService.closeAndAddTab(newTab);
        setTimeout(() => {
            this.cdr.detectChanges();
        }, 1000);

    }

    showModalFiltrar(visible: boolean): void {
        this.formFiltrar.modalVisible = visible;
    }

    filtrar(): any {

        this.changeTabs(true);

        this.formFiltrar.modalVisible = false;
    }

    modalExportar(visible: boolean): void {
        this.formFiltrar.formGroup.get('imposto').setValue(this.arrayImpostos[this.tabNumber]);
        this.dataExport.filtros = this.formFiltrar.formGroup.value;
        this.dataExport.name = this.arrayImpostos[this.tabNumber] + ' em ' + this.anoAtivo;
        this.dataExport.filtros.tributo = this.arrayImpostos[this.tabNumber];

        this.componentExport.visible = visible;
    }

    exibirTudo(exibir: boolean) {
        this.expandAll = exibir;

        if (this.expandAll) {
            this.idsObrigacoesEvolucao.forEach(id => {
                this.expandSet.add(id);
            });
        } else {
            this.idsObrigacoesEvolucao.forEach(id => {
                this.expandSet.delete(id);
            });
        }
    }

    somaValores(arr: number[]) {

        const result: number = arr.reduce((partialSum, a) => partialSum + a, 0);

        return result;
    }

    onExpandChange(id: number, checked: boolean): void {
        this.zone.run(() => {
            if (checked) {
                this.expandSet.add(id);
            } else {
                this.expandSet.delete(id);
            }
        });

        if (this.expandSet.size === 0) {
            this.expandAll = false;
        } else {
            this.expandAll = true;
        }

    }

    calculaBadgeFiltros(): void {
        let qtd = 0;

        if (this.formFiltrar.formGroup.value.empresa_id) {
            qtd += 2;
        }
        if (this.formFiltrar.formGroup.value.ie) {
            qtd += 1;
        }

        this.qtdFiltrosAtivos = qtd;
    }

    retornaObrigacoes(ano: number): void {

        this.requestObg?.unsubscribe();

        this.anoAtivo = ano;

        this.requestObg = this.complianceTributos.retornaObrigacoesAcessorias(ano).subscribe((retorno: any) => {

            this.zone.run(() => {
                this.arrayImpostos = [];

                for (const dado of Object.entries(retorno.dados)) {
                    const obj: any = dado[1];
                    this.arrayImpostos.push(obj.obrigacao);
                }
            });

            this.tabNumber = 0;

            this.changeTabs(true);

            setTimeout(() => {
                this.largurasTable();
                this.cdr.detectChanges();
            }, 300);
        });
    }

    largurasTable() {

        let verifyWidth = false;

        if (this.column1Width !== this.column1.nativeElement.offsetWidth) {
            this.column1Width = this.column1.nativeElement.offsetWidth;
            verifyWidth = true;
        }

        if (this.column2Width !== this.column2.nativeElement.offsetWidth) {
            this.column2Width = this.column2.nativeElement.offsetWidth;
            verifyWidth = true;
        }

        if (this.column3Width !== this.column3.nativeElement.offsetWidth) {
            this.column3Width = this.column3.nativeElement.offsetWidth;
            verifyWidth = true;
        }

        if (this.column4Width !== this.column4.nativeElement.offsetWidth) {
            this.column4Width = this.column4.nativeElement.offsetWidth;
            verifyWidth = true;
        }

        if (this.column5Width !== this.column5.nativeElement.offsetWidth) {
            this.column5Width = this.column5.nativeElement.offsetWidth;
            verifyWidth = true;
        }

        if (this.column6Width !== this.column6.nativeElement.offsetWidth) {
            this.column6Width = this.column6.nativeElement.offsetWidth;
            verifyWidth = true;
        }

        if (this.column7Width !== this.column7.nativeElement.offsetWidth) {
            this.column7Width = this.column7.nativeElement.offsetWidth;
            verifyWidth = true;
        }

        if (this.column8Width !== this.column8.nativeElement.offsetWidth) {
            this.column8Width = this.column8.nativeElement.offsetWidth;
            verifyWidth = true;
        }

        if (this.column9Width !== this.column9.nativeElement.offsetWidth) {
            this.column9Width = this.column9.nativeElement.offsetWidth;
            verifyWidth = true;
        }

        if (this.column10Width !== this.column10.nativeElement.offsetWidth) {
            this.column10Width = this.column10.nativeElement.offsetWidth;
            verifyWidth = true;
        }

        if (this.column11Width !== this.column11.nativeElement.offsetWidth) {
            this.column11Width = this.column11.nativeElement.offsetWidth;
            verifyWidth = true;
        }

        this.cdr.detectChanges();

        if (verifyWidth === true) {

            /*if (this.limiteTentativas === 3) {
                this.limiteTentativas = 0;
                return;
            }*/

            this.limiteTentativas++;

            setTimeout(() => {
                this.largurasTable();
            }, 100);

        }

        this.dataExport = {
            url: `/compliance/tributos/retorna-obrigacoes-grupo/${this.anoAtivo}/exportar`,
            filtros: this.formFiltrar.formGroup.value,
            name: 'Obrigações',
            tiposAceitos: [
                {key: '.CSV', value: 'csv'},
                {key: '.XLSX', value: 'xlsx'}]
        };

    }

}
