<nz-page-header class="site-page-header" nzTitle="Compliance" nzSubtitle="Tributos">
    <nz-page-header-extra>
        <a nz-button nzType="link" (click)="slideSwiper('left', 6)">
            <i class="fas fa-angle-double-left"></i>
        </a>
        <a nz-button nzType="link" (click)="slideSwiper('left', 3)">
            <i class="fas fa-angle-left"></i>
        </a>
        <a nz-button nzType="link" (click)="slideSwiper('right', 3)">
            <i class="fas fa-angle-right"></i>
        </a>
        <a nz-button nzType="link" (click)="slideSwiper('right', 6)">
            <i class="fas fa-angle-double-right"></i>
        </a>
        <a nz-button nzType="link" (click)="toggleCollapseCard()">
            <span>{{ collapseCard ? 'Expandir' : 'Recolher' }}</span>
        </a>
    </nz-page-header-extra>
</nz-page-header>
<nz-content>
    <div nz-row [nzGutter]="24">
        <div nz-col [nzSpan]="24" style="overflow: hidden;">
            <swiper
                    [(index)]="cardCompetenciaAtivo"
                    (click)="selecionaCardCompetencia($event)"
                    slidesPerView="auto"
                    [spaceBetween]="30"
                    [centeredSlides]="true"
                    [mousewheel]="false"
                    [scrollbar]="false"
                    [navigation]="true"
            >
                <ng-template *ngFor="let item of cardsAnos; index as i"
                             swiperSlide
                             class="swiper-card-competencia"
                >
                    <div class="card-competencia ant-card pull-up card-{{i}}" style="white-space: nowrap"
                         [ngClass]="collapseCard && 'compress'">
                        <div class="ant-card-head">
                            <h2>{{ item.ano }}</h2>
                        </div>
                        <div class="text-center ant-card-body card-{{i}}">
                            <div nz-row>
                                <div nz-col [nzSpan]="10" style="text-align: right; padding-right: 10px;">
                                    {{ item.unidades }}
                                </div>
                                <div nz-col [nzSpan]="14" style="text-align: left; ">
                                    Unidades
                                </div>
                            </div>
                            <div nz-row style="margin-bottom: 15px;">
                                <div nz-col [nzSpan]="10" style="text-align: right; padding-right: 10px;">
                                    {{ item.obrigacoes }}
                                </div>
                                <div nz-col [nzSpan]="14" style="text-align: left; ">
                                    Obrigações
                                </div>
                            </div>
                            <div nz-row>
                                <div nz-col [nzSpan]="24" style="text-align: center; ">
                                    <span nz-typography nzType="secondary">Obrigações</span>
                                    <nz-row>
                                        <nz-col [nzSpan]="24" style="text-align: center; white-space: nowrap;">
                                            <span style="margin-right: 5px;" *ngIf="item.obrigacoesMin < 0">
                                                Min
                                                {{ (item.obrigacoesMin * -1 | currency:'BRL').replace('R$', '') }}
                                                <small>(C)</small>
                                            </span>
                                            <span style="margin-right: 5px;" *ngIf="item.obrigacoesMin > 0">
                                                Min
                                                {{ (item.obrigacoesMin | currency:'BRL').replace('R$', '') }}
                                                <small>(D)</small>
                                            </span>
                                            <span style="margin-right: 5px;" *ngIf="item.obrigacoesMin == 0">
                                                Min
                                                {{ (item.obrigacoesMin | currency:'BRL').replace('R$', '') }}
                                            </span>
                                            <span style="margin-right: 5px;" *ngIf="item.obrigacoesMin ===null">Min (R$) -</span>
                                        </nz-col>
                                    </nz-row>
                                    <nz-row>
                                        <nz-col [nzSpan]="24" style="text-align: center">
                                            <span style="margin-right: 5px;" *ngIf="item.obrigacoesMax < 0">
                                                Max {{ (item.obrigacoesMax * -1 | currency:'BRL').replace('R$', '') }}
                                                <small>(C)</small>
                                            </span>
                                            <span style="margin-right: 5px;" *ngIf="item.obrigacoesMax > 0">
                                                Max {{ (item.obrigacoesMax | currency:'BRL').replace('R$', '') }}
                                                <small>(D)</small>
                                            </span>
                                            <span style="margin-right: 5px;" *ngIf="item.obrigacoesMax == 0">
                                                Max {{ (item.obrigacoesMax | currency:'BRL').replace('R$', '') }}
                                            </span>
                                            <span style="margin-right: 5px;" *ngIf="item.obrigacoesMax == null">Max (R$) -</span>
                                        </nz-col>
                                    </nz-row>
                                </div>
                            </div>
                            <div nz-row>
                                <div nz-col [nzSpan]="24" style="text-align: center; ">
                                    <span nz-typography nzType="secondary">Min x Max</span>
                                    <nz-row>
                                        <nz-col [nzSpan]="24" style="text-align: center">
                                        <span style="margin-right: 5px;" *ngIf="item.minMax < 0"
                                              [ngStyle]="{'color':'red'}">
                                              {{ (item.minMax * -1| currency:'BRL').replace('R$', '') }}

                                        </span>
                                            <span style="margin-right: 5px;" *ngIf="item.minMax > 0"
                                                  [ngStyle]="{'color':'red'}">
                                              {{ (item.minMax | currency:'BRL').replace('R$', '') }}

                                        </span>
                                            <span style="margin-right: 5px;" *ngIf="item.minMax == 0">
                                              {{ (item.minMax | currency:'BRL').replace('R$', '') }}
                                        </span>
                                            <span style="margin-right: 5px;" *ngIf="item.minMax === null">-</span>
                                        </nz-col>
                                    </nz-row>
                                </div>
                            </div>
                            <div nz-row>
                                <div nz-col [nzSpan]="24" style="text-align: center; ">
                                    <span nz-typography nzType="secondary">Pagamentos</span>
                                    <nz-row>
                                        <nz-col [nzSpan]="24" style="text-align: center">
                                            <span style="margin-right: 5px;" *ngIf="item.pagamentos < 0">
                                                {{ (item.pagamentos * -1 | currency:'BRL').replace('R$', '') }}

                                            </span>
                                            <span style="margin-right: 5px;" *ngIf="item.pagamentos > 0">
                                                {{ (item.pagamentos | currency:'BRL').replace('R$', '') }}

                                            </span>
                                            <span style="margin-right: 5px;" *ngIf="item.pagamentos == 0">
                                                {{ (item.pagamentos | currency:'BRL').replace('R$', '') }}
                                            </span>
                                            <span style="margin-right: 5px;" *ngIf="item.pagamentos === null">-</span>
                                        </nz-col>
                                    </nz-row>
                                </div>
                            </div>
                            <div nz-row>
                                <div nz-col [nzSpan]="24" style="text-align: center; ">
                                    <span nz-typography nzType="secondary">Pagto x Max</span>
                                    <nz-row>
                                        <nz-col [nzSpan]="24" style="text-align: center">
                                        <span style="margin-right: 5px;" *ngIf="item.pagtoMax < 0"
                                              [ngStyle]="{'color':'red'}">
                                            {{ (item.pagtoMax * -1 | currency:'BRL').replace('R$', '') }}

                                        </span>
                                            <span style="margin-right: 5px;" *ngIf="item.pagtoMax > 0"
                                                  [ngStyle]="item.pagtoMax > 0 && {'color':'red'}">
                                                {{ (item.pagtoMax | currency:'BRL').replace('R$', '') }}
                                        </span>
                                            <span style="margin-right: 5px;" *ngIf="item.pagtoMax == 0">
                                                {{ (item.pagtoMax | currency:'BRL').replace('R$', '') }}
                                        </span>
                                            <span style="margin-right: 5px;" *ngIf="item.pagtoMax === null">-</span>
                                        </nz-col>
                                    </nz-row>
                                </div>
                            </div>
                            <div nz-row style="margin-top: 10px">
                                <div nz-col [nzSpan]="24" style="text-align: center; ">
                                    <span style="font-size: 0.8em" nz-typography
                                          nzType="secondary">Último Processamento</span>
                                    <nz-row>
                                        <nz-col [nzSpan]="24" style="text-align: center">
                                            <span style="margin-right: 5px; font-size: 0.8em"
                                                  *ngIf="item.processamentoData">
                                                {{ (item.processamentoData | date: 'dd/MM/yyyy HH:mm') }}
                                            </span>
                                            <span style="margin-right: 5px; font-size: 0.8em"
                                                  *ngIf="!item.processamentoData">
                                                -
                                            </span>
                                        </nz-col>
                                    </nz-row>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </swiper>
        </div>
    </div>
    <div nz-row [nzGutter]="24">
        <div nz-col [nzSpan]="10">
            <nz-card class="font-doze"
                     nzTitle="Obrigações Acessórias (Oficiais)"
                     [nzExtra]="labelAno"
                     [ngClass]="!cardLoadingObrigacoesAcessorias ? 'ant-card-table' : ''"
                     [nzLoading]="cardLoadingObrigacoesAcessorias">
                <nz-table #tableObrigacoesAcessoriasOficiais
                          [nzData]="arrayTableObrigacoesAcessoriasOficiais"
                          [nzFrontPagination]="false"
                          [nzShowPagination]="false"
                          [nzScroll]="{ x: 'auto' }"
                          nzSize="small">
                    <thead>
                    <tr>
                        <th></th>
                        <th style="text-align: center;">Qtde</th>
                        <th style="text-align: center;">Min (R$)</th>
                        <th style="text-align: center;">Max (R$)</th>
                        <th style="text-align: center;">Min x Max (R$)</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let item of arrayTableObrigacoesAcessoriasOficiais" style="white-space: nowrap;">
                        <td style="">{{ item.obrigacao }}</td>
                        <td style="text-align: center;">
                            {{ item.qtde !== null ? item.qtde : '-' }}
                        </td>
                        <td style="text-align: right;">
                            <div *ngIf="item.obrigacoesMin < 0">
                                {{ item.obrigacoesMin * -1 | number }}
                                <span>(C)</span>
                            </div>
                            <div *ngIf="item.obrigacoesMin > 0">
                                {{ (item.obrigacoesMin | currency : 'BRL').replace('R$', '') }}
                                <span>(D)</span>
                            </div>
                            <div *ngIf="item.obrigacoesMin == 0">
                                {{ (item.obrigacoesMin | currency : 'BRL').replace('R$', '') }}
                            </div>
                            <div *ngIf="item.obrigacoesMin === null">-</div>
                        </td>
                        <td style="text-align: right;">
                            <div *ngIf="item.obrigacoesMax < 0">
                                {{ (item.obrigacoesMax * -1 | currency : 'BRL').replace('R$', '') }}
                                <span>(C)</span>
                            </div>
                            <div *ngIf="item.obrigacoesMax > 0">
                                {{ (item.obrigacoesMax | currency : 'BRL').replace('R$', '') }}
                                <span>(D)</span>
                            </div>
                            <div *ngIf="item.obrigacoesMax == 0">
                                {{ (item.obrigacoesMax | currency : 'BRL').replace('R$', '') }}
                            </div>
                            <div *ngIf="item.obrigacoesMax === null">-</div>
                        </td>
                        <td style="text-align: right;">

                            <span *ngIf="item.tolerancia > 0 && item.minMax <= item.tolerancia"
                                  nz-tooltip
                                  nzTooltipPlacement="bottom"
                                  [nzTooltipTitle]="'Tolerância '+ item.obrigacao + ' de R$ '+ item.tolerancia+',00'">
                                    {{ item.minMax === null ? '-' : (item.minMax | currency : 'BRL').replace('R$', '') }}
                            </span>

                            <span *ngIf="item.tolerancia > 0 && item.minMax > item.tolerancia"
                                  [ngStyle]="{'color': 'red'}"
                                  nz-tooltip
                                  nzTooltipPlacement="bottom"
                                  [nzTooltipTitle]="'Valor excede a tolerância de R$ '+ item.tolerancia + ',00 para '+ item.obrigacao">
                                        {{ item.minMax === null ? '-' : (item.minMax | currency : 'BRL').replace('R$', '') }}
                                    </span>

                            <span *ngIf="item.tolerancia <= 0">
                                {{ item.minMax === null ? '-' : (item.minMax | currency : 'BRL').replace('R$', '') }}
                            </span>
                        </td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr>
                        <th>Total</th>
                        <th></th>
                        <th style="text-align: right;">
                            <div *ngIf="minObrigacoesOficiais < 0">
                                {{ (minObrigacoesOficiais * -1 | currency : 'BRL').replace('R$', '') }}
                                <span>(C)</span>
                            </div>
                            <div *ngIf="minObrigacoesOficiais > 0">
                                {{ (minObrigacoesOficiais | currency : 'BRL').replace('R$', '') }}
                                <span>(D)</span>
                            </div>
                            <div *ngIf="minObrigacoesOficiais == 0">
                                {{ (minObrigacoesOficiais | currency : 'BRL').replace('R$', '') }}
                            </div>
                            <div *ngIf="minObrigacoesOficiais === null">-</div>
                        </th>

                        <th style="text-align: right;">
                            <div *ngIf="maxObrigacoesOficiais < 0">
                                {{ (maxObrigacoesOficiais * -1 | currency : 'BRL').replace('R$', '') }}
                                <span>(C)</span>
                            </div>
                            <div *ngIf="maxObrigacoesOficiais > 0">
                                {{ (maxObrigacoesOficiais | currency : 'BRL').replace('R$', '') }}
                                <span>(D)</span>
                            </div>
                            <div *ngIf="maxObrigacoesOficiais == 0">
                                {{ (maxObrigacoesOficiais | currency : 'BRL').replace('R$', '') }}
                            </div>
                            <div *ngIf="maxObrigacoesOficiais === null">-</div>
                        </th>
                        <th style="text-align: right;">
                            <span *ngIf="minMaxObrigacoesOficiais < 0" [ngStyle]="{'color':'red'}">
                                {{ (minMaxObrigacoesOficiais * -1 | currency : 'BRL').replace('R$', '') }}
                            </span>
                            <span *ngIf="minMaxObrigacoesOficiais > 0"
                                  [ngStyle]="minMaxObrigacoesOficiais > 0 && {'color':'red'}">
                                {{ (minMaxObrigacoesOficiais | currency : 'BRL').replace('R$', '') }}
                            </span>
                            <span *ngIf="minMaxObrigacoesOficiais == 0">
                                {{ (minMaxObrigacoesOficiais | currency : 'BRL').replace('R$', '') }}
                            </span>
                            <span *ngIf="minMaxObrigacoesOficiais === null">-</span>
                        </th>
                    </tr>
                    </tfoot>
                </nz-table>
            </nz-card>
        </div>
        <div nz-col [nzSpan]="14">
            <nz-card class="font-doze"
                     nzTitle="Obrigações Principais - Pagamentos"
                     [nzExtra]="labelAno"
                     [ngClass]="!cardLoadingObrigacoesPrincipais ? 'ant-card-table' : ''"
                     [nzLoading]="cardLoadingObrigacoesPrincipais">
                <div nz-row [nzGutter]="24">
                    <div nz-col [nzSpan]="12" class="d-flex align-items-center">
                        <highcharts-chart
                                [Highcharts]="HighchartsPie"
                                [options]="chartPieOptions"
                                *ngIf="chartOk && HighchartsPie && chartPieOptions"
                                [(update)]="updateFlag"
                                style="width: 100%; height: 310px; display: block;"
                        ></highcharts-chart>
                    </div>
                    <div [nzSpan]="12" nz-col>
                        <nz-table #tableObrigacoesPrincipaisPagamentos
                                  [nzData]="arrayTableObrigacoesPrincipaisPagamentos"
                                  [nzFrontPagination]="false"
                                  [nzShowPagination]="false"
                                  [nzScroll]="{ x: 'auto' }"
                                  nzSize="small">
                            <thead>
                            <tr>
                                <th></th>
                                <th style="text-align: center;">Qtde</th>
                                <th style="text-align: center;">Valor (R$)</th>
                                <th style="text-align: center;">Pagto x Max (R$)</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let item of arrayTableObrigacoesPrincipaisPagamentos"
                                style="white-space: nowrap;">
                                <td>
                                    <i class="fas fa-circle" [ngStyle]="{'color': item.cor}"></i>
                                    <span>
                                        {{ item.obrigacao }}
                                    </span>
                                </td>
                                <td style="text-align: center;">{{ item.qtde }}</td>
                                <td style="text-align: right;">
                                    <div *ngIf="item.pagamentos < 0">
                                        {{ (item.pagamentos * -1 | currency : 'BRL').replace('R$', '') }}

                                    </div>
                                    <div *ngIf="item.pagamentos > 0">
                                        {{ (item.pagamentos | currency : 'BRL').replace('R$', '') }}

                                    </div>
                                    <div *ngIf="item.pagamentos == 0">
                                        {{ (item.pagamentos | currency : 'BRL').replace('R$', '') }}
                                    </div>
                                    <div *ngIf="item.pagamentos === null">-</div>
                                </td>
                                <td style="text-align: right;">

                                    <span *ngIf="item.tolerancia > 0 && item.pagamentosMax <= item.tolerancia"
                                          nz-tooltip
                                          nzTooltipPlacement="bottom"
                                          [nzTooltipTitle]="'Tolerância '+ item.obrigacao + ' de R$ '+ item.tolerancia+',00'">
                                        {{ !item.minMax || item.minMax === null ? '-' : (item.pagamentosMax | currency : 'BRL').replace('R$', '') }}
                                    </span>

                                    <span *ngIf="item.tolerancia > 0 && item.pagamentosMax > item.tolerancia"
                                          [ngStyle]="{'color': 'red'}"
                                          nz-tooltip
                                          nzTooltipPlacement="bottom"
                                          [nzTooltipTitle]="'Valor excede a tolerância de R$ '+ item.tolerancia + ',00 para '+ item.obrigacao">
                                        {{ item.pagamentosMax === null ? '-' : (item.pagamentosMax | currency : 'BRL').replace('R$', '') }}
                                    </span>

                                    <span *ngIf="item.tolerancia <= 0">
                                        {{ item.pagamentosMax === null ? '-' : (item.pagamentosMax | currency : 'BRL').replace('R$', '') }}
                                    </span>

                                </td>
                            </tr>
                            </tbody>
                            <tfoot>
                            <tr>
                                <th>Total</th>
                                <th></th>
                                <th style="text-align: right;">
                                    <div *ngIf="pagamentosObrigacoes < 0">
                                        {{ (pagamentosObrigacoes * -1 | currency : 'BRL').replace('R$', '') }}

                                    </div>
                                    <div *ngIf="pagamentosObrigacoes > 0">
                                        {{ (pagamentosObrigacoes | currency : 'BRL').replace('R$', '') }}

                                    </div>
                                    <div *ngIf="pagamentosObrigacoes == 0">
                                        {{ (pagamentosObrigacoes | currency : 'BRL').replace('R$', '') }}
                                    </div>
                                    <div *ngIf="pagamentosObrigacoes === null">-</div>
                                </th>
                                <th style="text-align: right;">
                                    <span *ngIf="pagtoMaxObrigacoes < 0" [ngStyle]="{'color':'red'}">
                                        {{ (pagtoMaxObrigacoes * -1 | currency : 'BRL').replace('R$', '') }}

                                    </span>
                                    <span *ngIf="pagtoMaxObrigacoes > 0" [ngStyle]="{'color':'red'}">
                                        {{ (pagtoMaxObrigacoes | currency : 'BRL').replace('R$', '') }}

                                    </span>
                                    <span *ngIf="pagtoMaxObrigacoes == 0">
                                        {{ (pagtoMaxObrigacoes | currency : 'BRL').replace('R$', '') }}
                                    </span>
                                    <span *ngIf="pagtoMaxObrigacoes === null">-</span>
                                </th>
                            </tr>
                            </tfoot>
                        </nz-table>
                    </div>
                </div>
            </nz-card>
        </div>
    </div>
</nz-content>

<app-card-tributo-por-grupo *ngIf="anoAtivo" [data]="{anoAtivo}"></app-card-tributo-por-grupo>

<div style="height: 10px; background-color: transparent"></div>

<ng-template #labelAno>
    <div class="d-flex align-items-center">
        <h3 style="color: rgba(0,0,0,.45);">{{ anoAtivo }}</h3>
    </div>
</ng-template>

<nz-modal [(nzVisible)]="isVisible" nzTitle="{{empresaSelecionada}}" (nzOnCancel)="handleCancel()">
    <ng-container *nzModalContent>
        <iframe src="" title="" style="width: 100%;"></iframe>
    </ng-container>
</nz-modal>
