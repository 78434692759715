import {
    ChangeDetectorRef,
    Component,
    Input,
    AfterViewInit,
    LOCALE_ID, OnInit
} from '@angular/core';
import {TabService} from '@services/tab.service';
import {Tab} from '@models/tab.model';
import {buildUrl, findComponentByUrl} from '../../../shared/components-helper';
import {CalendarObrigacoesService} from './calendarObrigacoes.service';
import * as Highcharts from 'highcharts/highcharts';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import {ToastrService} from 'ngx-toastr';
import {Helpers} from '../../../core/helpers';
import {Pagination} from '@models/pagination.model';
import {UsuarioLogado} from "@models/usuarioLogado.model";
import {UserService} from "@services/user.service";

@Component({
    selector: 'app-obrigacoes-dashboard',
    templateUrl: './calendarObrigacoes.component.html',
    providers: [
        {
            provide: LOCALE_ID,
            useValue: 'pt-BR'
        }
    ],
    styleUrls: ['./calendarObrigacoes.component.scss']
})

export class CalendarObrigacoesComponent extends AbstractListTable<any> implements OnInit, AfterViewInit {
    @Input() data;

    cardLoadingObrigacoesConcluidas = true;
    cardLoadingStatus = true;
    cardLoadingConfirmacoesAutomaticas = true;
    cardLoadingEficiencia = true;
    cardLoadingObrigacoes = true;
    cardLoadingNaoAtribuidos = true;
    cardLoadingEquipes = true;
    cardLoadingResponsaveis = true;

    arrayMesesLabel = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

    arrayCompetencias = [];
    cardCompetenciaAtivo: number;
    collapseCard = false;

    arrayCorStatus = [
        '#fff5ef',
        '#ffeef1',
        '#e6f7ff',
        '#efefff',
    ];

    arrayFontCorStatus = [
        '#ff9149',
        '#ff4961',
        '#1565c0',
        '#666ee8',
    ];

    arrayTableObrigacoes = [];
    arrayTableObrigacoesFront = [];
    tableObrigacoesConcluidas = [];
    tableStatus: any = {};
    tableConfirmacoesAutimaticas: any = {};
    arrayTableResponsaveis = [];
    arrayTableEquipes = [];

    anoAtivo: number = null;
    mesAtivo: number = null;

    tabNumber = 0;

    arrayColors = [
        '#666ee8',
        '#ff4961',
        '#28d094',
        '#ff9149',
        '#1e9ff2',
        '#f44336',
        '#666ee8',
        '#ff4961',
        '#28d094',
        '#ff9149',
        '#1e9ff2',
        '#f44336'
    ];

    HighchartsGauge: typeof Highcharts = Highcharts;
    HighchartsSolidGauge: typeof Highcharts = Highcharts;
    HighchartsPie: typeof Highcharts = Highcharts;
    HighchartsColumn: typeof Highcharts = Highcharts;

    chartPieOptions: Highcharts.Options;
    chartGaugeOptions: Highcharts.Options;
    chartSolidGaugeOptions: Highcharts.Options;
    chartColumnOptions: Highcharts.Options;

    naoAtribuidoPorcObrigacoes = 0;
    minNaoAtribuidoObrigacoes = 0;
    maxNaoAtribuidoObrigacoes = 0;

    naoAtribuidoPorcUnidades = 0;
    minNaoAtribuidoUnidades = 0;
    maxNaoAtribuidoUnidades = 0;

    updateFlag = false;

    seriesObrigacoesConcluidas: any[] = [];
    seriesEficiencia: any[] = [];
    diasEficiencia: any[] = [];
    seriesStatus: any[] = [];

    graficoEficiencia: {
        porcentagem: number,
        totalObrigacoes: number,
        totalConcluidas: number,
    } = {
        porcentagem: 0,
        totalObrigacoes: 0,
        totalConcluidas: 0
    };

    cardGrupoLoading = false;
    grupos = [];

    statusCard = false;

    pagination: any = [{
        grupos: Pagination
    }];

    currentSearchObrigacoes: string;

    usuarioLogado: UsuarioLogado;

    constructor(
        private calendarObrigacoes: CalendarObrigacoesService,
        private cdr: ChangeDetectorRef,
        private tabService: TabService,
        private userService: UserService,
        private toastService: ToastrService) {
        super(calendarObrigacoes, null, toastService);

    }

    ngOnInit() {

        this.userService.usuarioLogado().subscribe((user) => {
            this.usuarioLogado = user;
        });
    }

    carregaMes(mes: number, ano: number) {

        this.carregaCabecalho(mes, ano);
        this.carregaObrigacoes(mes, ano);
        this.carregaGrupos(mes, ano);
        this.carregaNaoAtibuidas(mes, ano);
        this.carregaResponsaveis(mes, ano);
        this.carregaEquipes(mes, ano);

        this.cdr.detectChanges();

    }

    carregaNaoAtibuidas(mes: number, ano: number): void {

        this.cardLoadingNaoAtribuidos = true;

        this.calendarObrigacoes.retornaNaoAtribuidos(mes, ano).subscribe((response: any) => {

            this.naoAtribuidoPorcObrigacoes = (response.obrigacoes.min * 100) / response.obrigacoes.max;
            this.minNaoAtribuidoObrigacoes = response.obrigacoes.min;
            this.maxNaoAtribuidoObrigacoes = response.obrigacoes.max;

            this.naoAtribuidoPorcUnidades = (response.unidades.min * 100) / response.unidades.max;
            this.minNaoAtribuidoUnidades = response.unidades.min;
            this.maxNaoAtribuidoUnidades = response.unidades.max;

            this.cardLoadingNaoAtribuidos = false;

        });

    }

    carregaResponsaveis(mes: number, ano: number): void {

        this.cardLoadingResponsaveis = true;

        this.calendarObrigacoes.retornaResponsaveis(mes, ano).subscribe((response: any) => {

            this.arrayTableResponsaveis = response;

            for (const responsavel of this.arrayTableResponsaveis) {
                responsavel.porcObrigacoes = (responsavel.obrigacoes.min * 100) / responsavel.obrigacoes.max;
                responsavel.porcUnidades = (responsavel.unidades.min * 100) / responsavel.unidades.max;
                responsavel.iniciais = this.calendarObrigacoes.retornaIniciais(responsavel.nome);
            }

            this.cardLoadingResponsaveis = false;

        });

    }

    carregaEquipes(mes: number, ano: number): void {

        this.cardLoadingEquipes = true;

        this.calendarObrigacoes.retornaEquipes(mes, ano).subscribe((response: any) => {

            this.arrayTableEquipes = response;

            for (const equipe of this.arrayTableEquipes) {
                equipe.porcObrigacoes = (equipe.obrigacoes.min * 100) / this.maxNaoAtribuidoObrigacoes;
                equipe.porcUnidades = (equipe.unidades.min * 100) / this.maxNaoAtribuidoUnidades;
            }

            this.cardLoadingEquipes = false;

        });

    }

    carregaGrupos(mes: number, ano: number): void {

        this.cardGrupoLoading = true;

        this.calendarObrigacoes.retornaGrupos(mes, ano, this.currentSearch).subscribe({
            next: (response) => {
                this.grupos = response.data || response;

                this.pagination.grupos = new Pagination(
                    response?.per_page || 50,
                    response?.current_page || 1,
                    response?.last_page || 1,
                    response?.total || 50);

                this.cardGrupoLoading = false;

            },
            error: (err) => {
                this.toastService.error(err.error.message);
                this.cardGrupoLoading = false;
            }
        });

    }

    montaDataGraficos(response: any): void {

        // Gráfico Obrigações Concluídas
        let radius = 137;
        let innerRadius = 113;
        this.seriesObrigacoesConcluidas = [];

        const colors = [
            '#ff9149',
            '#ffa063',
            '#ffbf96',
        ];

        response.obrigacoesConcluidas.forEach((obg, index) => {

            radius = radius - 25;
            innerRadius = innerRadius - 25;

            const tempObject = {
                type: undefined,
                name: obg.label,
                data: [{
                    color: colors[index],
                    radius: radius.toString() + '%',
                    innerRadius: innerRadius.toString() + '%',
                    y: Math.round(obg.itens.real * 100 / obg.itens.total)
                }]
            };

            this.seriesObrigacoesConcluidas.push(tempObject);

        });

        // Gráfico Status
        this.seriesStatus = [{
            type: 'pie',
            name: 'Obrigações',
            data: [{
                name: 'Pendentes',
                y: Number(response.status.qtdPendentes)
            }, {
                name: 'Atrasadas',
                y: Number(response.status.qtdAtrasados)
            }, {
                name: 'Concluídas',
                y: Number(response.status.qtdConcluidos)
            }, {
                name: 'Concluídas com Atraso',
                y: Number(response.status.qtdConcluidoAtrasadas)
            }]
        }];

        // Gráfico Eficiência
        const max = Number(response.status.qtdConcluidos) +
            Number(response.status.qtdConcluidoAtrasadas) +
            Number(response.status.qtdAtrasados) +
            Number(response.status.qtdPendentes);

        const min = Number(response.status.qtdConcluidos);
        const total = Math.trunc((100 * min) / max);

        this.graficoEficiencia = {
            porcentagem: total,
            totalObrigacoes: max,
            totalConcluidas: min
        };


        this.seriesEficiencia = [];
        this.diasEficiencia = [];

        response.eficiencia.forEach((value) => {
            this.seriesEficiencia.push(value.qntConcluidas);
            this.diasEficiencia.push(Helpers.monthStr(value.conclusaoData));
        });


    }

    carregaCabecalho(mes: number, ano: number): void {

        this.cardLoadingObrigacoesConcluidas = true;
        this.cardLoadingStatus = true;
        this.cardLoadingConfirmacoesAutomaticas = true;
        this.cardLoadingEficiencia = true;

        this.calendarObrigacoes.retornaCabecalho(mes, ano).subscribe((response: any) => {

            this.montaDataGraficos(response);

            this.tableObrigacoesConcluidas = response.obrigacoesConcluidas;
            this.tableStatus = response.status;
            this.tableStatus.qtdTotal = Number(this.tableStatus.qtdPendentes) +
                Number(this.tableStatus.qtdAtrasados) +
                Number(this.tableStatus.qtdConcluidos) +
                Number(this.tableStatus.qtdConcluidoAtrasadas);
            this.tableConfirmacoesAutimaticas = response.confirmacoesAutomaticas;

            this.cardLoadingObrigacoesConcluidas = false;
            this.cardLoadingStatus = false;
            this.cardLoadingConfirmacoesAutomaticas = false;
            this.cardLoadingEficiencia = false;

            setTimeout(() => {
                this.loadChartData();
            }, 200);

        });

    }

    carregaObrigacoes(mes: number, ano: number): void {

        this.cardLoadingObrigacoes = true;

        this.calendarObrigacoes.retornaObrigacoes(mes, ano).subscribe((response: any) => {

            this.arrayTableObrigacoes = [];
            Object.entries(response).forEach(([chave, value]: any) => {
                value.porcentagem = Number((value.quantidadeConcluidas * 100) / value.quantidadeObrigacao).toFixed(0);
                this.arrayTableObrigacoes.push(value);
            });

            this.arrayTableObrigacoesFront = this.arrayTableObrigacoes;

            this.cardLoadingObrigacoes = false;

        });

    }

    iniciar(): void {

        this.calendarObrigacoes.retornaCompetencias().subscribe((response: any) => {

            this.arrayCompetencias = response;

            if (this.arrayCompetencias.length > 0) {

                const mesAtual = new Date().getMonth() + 1;
                const anoAtual = new Date().getFullYear();

                this.arrayCompetencias.forEach((value, key) => {
                    value.position = key;
                });

                const cardAtual = this.arrayCompetencias.filter((value, key) => {
                    return Number(value.mes) === mesAtual && Number(value.ano) === anoAtual;
                });

                setTimeout(() => {

                    this.cardCompetenciaAtivo = cardAtual.length > 0 ? cardAtual[0].position : this.arrayCompetencias.length - 1;

                    setTimeout(() => {

                        this.collapseCard = true;

                        this.mesAtivo = this.arrayCompetencias[this.cardCompetenciaAtivo].mes;
                        this.anoAtivo = this.arrayCompetencias[this.cardCompetenciaAtivo].ano;

                        this.carregaMes(this.mesAtivo, this.anoAtivo);

                    }, 500);

                }, 1000);

            } else {

                this.toastrService.warning('Não há dados para exibição.');

            }

        }, (response) => {

            this.toastrService.error(response.error.message);

        });

    }

    ngAfterViewInit() {
        this.iniciar();
    }

    public cardDimiss(target: HTMLElement): void {

        let barElement: HTMLElement | null = target;

        while (barElement && !barElement.classList.contains('ant-card')) {

            barElement = barElement.parentElement;

        }

        barElement.remove();

    }

    public cardCompress(target: HTMLElement): void {

        let barElement: HTMLElement | null = target;

        while (barElement && !barElement.classList.contains('ant-card')) {

            barElement = barElement.parentElement;

        }

        const iconPlus = barElement.querySelector('.anticon-plus');
        const iconMinus = barElement.querySelector('.anticon-minus');

        barElement = barElement.querySelector('.ant-card-body');

        if (barElement.classList.contains('compress')) {

            iconPlus.classList.remove('d-inline-block');
            iconPlus.classList.add('d-none');

            iconMinus.classList.remove('d-none');
            iconMinus.classList.add('d-inline-block');

            barElement.classList.remove('compress');

        } else {

            iconMinus.classList.remove('d-inline-block');
            iconMinus.classList.add('d-none');

            iconPlus.classList.remove('d-none');
            iconPlus.classList.add('d-inline-block');

            barElement.classList.add('compress');

        }
    }

    toggleCollapseCard() {
        this.collapseCard = !this.collapseCard;
    }

    formatOne = (percent: number) => `${percent}%`;

    clickEvent() {
        this.statusCard = !this.statusCard;
    }

    loadChartData(): void {

        this.chartColumnOptions = {
            chart: {
                type: 'column'
            },
            title: undefined,
            xAxis: {
                title: {
                    text: 'Concluídos por data'
                },
                categories: this.diasEficiencia,
                crosshair: true
            },
            yAxis: {
                title: undefined,
                min: 0
            },
            tooltip: {
                headerFormat: '<span style="font-size:10px">Dia {point.key}</span><table>',
                pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                    '<td style="padding:0"><b>{point.y}</b></td></tr>',
                footerFormat: '</table>',
                shared: true,
                useHTML: true
            },
            legend: {
                enabled: false
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }
            },
            series: [{
                type: undefined,
                name: 'Entregas',
                data: this.seriesEficiencia

            }]
        };

        this.chartSolidGaugeOptions = {
            chart: {
                type: 'solidgauge',
                height: 100,
                backgroundColor: 'rgba(0,0,0,0)',
            },
            title: {
                text: undefined,
            },
            pane: {
                center: ['50%', '85%'],
                size: '120%',
                startAngle: -90,
                endAngle: 90,
            },
            tooltip: {
                enabled: false
            },
            plotOptions: {
                solidgauge: {
                    dataLabels: {
                        y: 5,
                        borderWidth: 0,
                        useHTML: true
                    }
                }
            },
            yAxis: {
                min: 0,
                max: 100,
                tickPositions: [0, 100],
                stops: [
                    [0.1, '#547df9'], // light blue
                    [0.5, '#547df9'], // blue
                    [0.9, '#547df9'] // dark blue
                ],
                lineWidth: 1,
                minorTickInterval: null,
                tickAmount: 2,
                title: {
                    y: -70
                },
                labels: {
                    y: 16
                }
            },
            credits: {
                enabled: false
            },
            exporting: {
                enabled: true,
            },
            series: [{
                type: undefined,
                name: '',
                data: [this.graficoEficiencia.porcentagem],
                dataLabels: {
                    format: '<div style="text-align:center; top: -15px; position: relative;">' +
                        '<span style="font-size:10px;color: black;">' +
                        '{y}%' +
                        '</span>' +
                        '</div>',
                    y: 45,
                },
                tooltip: {
                    valueSuffix: ' km/h'
                }
            }]
        };

        this.chartGaugeOptions = {
            chart: {
                type: 'solidgauge',
                marginTop: 20
            },
            title: {
                text: undefined
            },
            tooltip: {
                borderWidth: 0,
                backgroundColor: 'none',
                shadow: false,
                style: {
                    fontSize: '16px'
                },
                pointFormat: '<span style="font-size: 12px;">{series.name}</span><br><span style="' +
                    'font-size:10px;' +
                    ' color: {point.color}; font-weight: bold">{point.y}%</span>',
                positioner: (labelWidth) => {
                    return {
                        x: 100 - labelWidth / 2,
                        y: 70
                    };
                }
            },
            pane: {
                startAngle: 0,
                endAngle: 360,
                background: [{
                    outerRadius: '112%',
                    innerRadius: '88%',
                    backgroundColor: new Highcharts.Color(Highcharts.getOptions().colors[0])
                        .setOpacity(0.3)
                        .get(),
                    borderWidth: 0
                }, {
                    outerRadius: '87%',
                    innerRadius: '63%',
                    backgroundColor: new Highcharts.Color(Highcharts.getOptions().colors[1])
                        .setOpacity(0.3)
                        .get(),
                    borderWidth: 0
                }, {
                    outerRadius: '62%',
                    innerRadius: '38%',
                    backgroundColor: new Highcharts.Color(Highcharts.getOptions().colors[2])
                        .setOpacity(0.3)
                        .get(),
                    borderWidth: 0
                }]
            },
            yAxis: {
                min: 0,
                max: 100,
                lineWidth: 0,
                tickPositions: []
            },
            plotOptions: {
                solidgauge: {
                    dataLabels: {
                        enabled: false
                    },
                    linecap: 'round',
                    stickyTracking: false,
                    rounded: true
                }
            },
            series: this.seriesObrigacoesConcluidas
        };

        this.chartPieOptions = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie'
            },
            title: {
                text: undefined
            },
            tooltip: {
                pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
            },
            accessibility: {
                point: {
                    valueSuffix: '%'
                }
            },
            plotOptions: {
                pie: {
                    colors: [
                        '#ff9149',
                        '#ff4961',
                        '#1565c0',
                        '#666ee8',
                    ],
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        format: '{point.percentage:.1f}%',
                        distance: -30,
                        style: {
                            fontSize: '0.9em',
                            textOutline: 'none',
                            opacity: 0.7
                        },
                        filter: {
                            operator: '>',
                            property: 'percentage',
                            value: 15
                        }
                    },
                    showInLegend: false,
                    shadow: true,
                }
            },
            series: this.seriesStatus
        };

        this.cdr.detectChanges();

        this.updateFlag = true;

    }

    generateId() {
        return Math.random().toString(36).substr(2, 9);
    }

    getPercentage(campo1: number, campo2: number): number {
        const maior: any = campo1 > campo2 ? campo1 : campo2;
        const menor: any = campo1 < campo2 ? campo1 : campo2;
        return (menor / maior) * 100;
    }

    selecionaCardCompetencia(event: any) {

        if (!(event.clickedIndex === undefined)) {

            this.cardCompetenciaAtivo = event.clickedIndex;

            const anoAtivo = this.arrayCompetencias[event.clickedIndex].ano;
            const mesativo = this.arrayCompetencias[event.clickedIndex].mes;

            this.anoAtivo = anoAtivo;
            this.mesAtivo = mesativo;


            this.carregaMes(mesativo, anoAtivo);

        }

    }


    openTab(path: string, queryParams?: string, data?: {}) {
        const component = findComponentByUrl(path);
        const url = buildUrl(component, queryParams);
        const newTab = new Tab(component.name, component.title, url, component.urlType, data);
        this.tabService.closeAndAddTab(newTab);
    }

    slideSwiper(direction: string, swipNumber: number) {

        switch (direction) {

            case 'left':

                this.cardCompetenciaAtivo = this.cardCompetenciaAtivo - swipNumber;

                if (this.cardCompetenciaAtivo < 0) {
                    this.cardCompetenciaAtivo = 0;
                }

                break;

            case 'right':

                this.cardCompetenciaAtivo = this.cardCompetenciaAtivo + swipNumber;

                if (this.cardCompetenciaAtivo > this.arrayCompetencias.length - 1) {
                    this.cardCompetenciaAtivo = this.arrayCompetencias.length - 1;
                }

                break;

        }

        const anoAtivo = this.arrayCompetencias[this.cardCompetenciaAtivo].ano;
        const mesAtivo = this.arrayCompetencias[this.cardCompetenciaAtivo].mes;

        this.anoAtivo = anoAtivo;
        this.mesAtivo = mesAtivo;

        this.carregaMes(mesAtivo, anoAtivo);

    }

    filtrar(filtroIn: string, valorIn: any): void {

        const arrayFiltros: any[] = [];

        arrayFiltros.push({
            filtro: filtroIn,
            valor: valorIn
        });

        arrayFiltros.push({
            filtro: 'mes',
            valor: this.mesAtivo
        });

        arrayFiltros.push({
            filtro: 'ano',
            valor: this.anoAtivo
        });

        this.openTab('/calendar/calendarTasks', null, arrayFiltros);

    }

    filtrarConcluidas(valor: string): void {

        const arrayFiltros: any[] = [];

        switch (valor) {
            case 'minhas': {
                arrayFiltros.push({filtro: 'responsavel_id', valor: this.usuarioLogado.id});
                break;
            }
            case 'equipe': {
                arrayFiltros.push({filtro: 'equipe_id', valor: this.usuarioLogado.equipe_id});
                break;
            }
        }

        arrayFiltros.push({filtro: 'status', valor: 'C'});

        arrayFiltros.push({
            filtro: 'mes',
            valor: this.mesAtivo
        });

        arrayFiltros.push({
            filtro: 'ano',
            valor: this.anoAtivo
        });

        this.openTab('/calendar/calendarTasks', null, arrayFiltros);

    }

    abrevEquipe(equipe: string) {
        if (equipe?.length > 40) {
            return equipe.substring(0, 37) + '...';
        } else {
            return equipe;
        }
    }

    filtrarCalendar(filtroIn: string, valorIn: any, grupoEmpresarialId: string): void {

        const arrayFiltros: any[] = [];

        arrayFiltros.push({
            filtro: filtroIn,
            valor: valorIn
        });

        const mes = Number(this.mesAtivo);

        arrayFiltros.push({
            filtro: 'mes',
            valor: mes
        });

        arrayFiltros.push({
            filtro: 'ano',
            valor: this.anoAtivo
        });

        arrayFiltros.push({
            filtro: 'grupoEmpresarial_id',
            valor: grupoEmpresarialId
        });

        this.openTab('/calendar/calendarTasks/', null, arrayFiltros);

    }

    buscar() {

        if (this.currentParams) {
            this.currentParams.pageIndex = 1;
        }

        this.carregaGrupos(this.mesAtivo, this.anoAtivo);
    }

    resetSearch() {
        if (this.currentParams) {
            this.currentParams.pageIndex = 1;
        }
        this.currentSearch = null;
        this.carregaGrupos(this.mesAtivo, this.anoAtivo);
    }

    buscarFront(limpar = false) {

        if (!this.currentSearchObrigacoes || limpar) {
            this.arrayTableObrigacoesFront = this.arrayTableObrigacoes;
            this.currentSearchObrigacoes = null;
            return;
        }

        const result = [];

        this.arrayTableObrigacoes.map(
            value => {
                if (value.obrigacao?.toUpperCase().includes(this.currentSearchObrigacoes.toUpperCase())) {
                    result.push(value);
                }
            });

        this.arrayTableObrigacoesFront = result;


    }
}


