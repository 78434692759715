import {BrowserModule, Title} from '@angular/platform-browser';
import {NgModule, LOCALE_ID} from '@angular/core';
import {AppComponent} from './app.component';
import {FormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NzI18nService, NZ_DATE_LOCALE, NZ_I18N, pt_BR} from 'ng-zorro-antd/i18n';
import {ptBR} from 'date-fns/locale';
import {registerLocaleData} from '@angular/common';
import pt from '@angular/common/locales/pt';
import {JwtInterceptor} from './core/interceptors/jwt.interceptor';
import {ErrorInterceptor} from './core/interceptors/error.interceptor';
import {AppRoutingModule} from './app-routing.module';
import {NZ_ICONS} from 'ng-zorro-antd/icon';
import {IconDefinition} from '@ant-design/icons-angular';
import * as AllIcons from '@ant-design/icons-angular/icons';
import {ToastrModule} from 'ngx-toastr';
import {NzLayoutModule} from 'ng-zorro-antd/layout';
import {HomeComponent} from './modules/home/pages/home.component';
import {UiModule} from './modules/ui.module';
import {NzBadgeModule} from 'ng-zorro-antd/badge';
import {NzTabsModule} from 'ng-zorro-antd/tabs';
import {IConfig, NgxMaskModule} from 'ngx-mask';
import {ActionReducerMap, StoreModule} from '@ngrx/store';
import {Reducer} from '@reducers/reducer';
import {TabReducer} from '@reducers/tab.reducer';

import {TabsetModule} from '@components/tabset/tabset.module';
import {ExternalPageModule} from './modules/external-page/external-page.module';
import {LogModule} from './modules/log/log.module';
import {TransitoriosModule} from './modules/drive/transitorios/transitorios.module';
import {ObrigacoesDashboardModule} from './modules/obrigacoes/obrigacoesDashboard/obrigacoesDashboard.module';
import {ComplianceObrigacoesModule} from './modules/compliance/complianceObrigacoes/complianceObrigacoes.module';
import {ObrigacoesFederaisModule} from './modules/drive/obrigacoesFederais/obrigacoesFederais.module';
import {MenuModule} from './modules/menu/menu.module';
import {ProfileModule} from './modules/profile/profile.module';
import {UsuarioLogadoModule} from './modules/usuario-logado/usuario-logado.module';
import {UsuarioDetalheModule} from './modules/administracao/usuarios/usuario-detalhe/usuario-detalhe.module';
import {UsuariosModule} from './modules/administracao/usuarios/usuarios.module';
import {EmpresasModule} from './modules/administracao/empresas/empresas.module';
import {DataService} from '@services/data.service';
import {EmpresaDetalheModule} from './modules/administracao/empresas/empresa-detalhe/empresa-detalhe.module';
import {AdministracaoModule} from './modules/administracao/administracao.module';
import {PerfisModule} from './modules/administracao/perfis/perfis.module';
import {PerfilDetalheModule} from './modules/administracao/perfis/perfil-detalhe/perfil-detalhe.module';

import {CadastrosInscricoesRegimesModule} from './modules/cadastros/inscricoes-regimes/inscricoes-regimes.module';
import {
    CadastrosEditarInscricoesRegimesModule
} from './modules/cadastros/editar-inscricoes-regimes/editar-inscricoes-regimes.module';
import {CadastrosCredenciaisAcessoModule} from './modules/cadastros/credenciais-acesso/credenciais-acesso.module';
import {
    CadastrosEditarCredenciaisAcessoModule
} from './modules/cadastros/editar-credenciais-acesso/editar-credenciais-acesso.module';
import {CadastrosEditarResponsaveisModule} from './modules/cadastros/editar-responsaveis/editar-responsaveis.module';
import {EquipesModule} from './modules/cadastros/equipes/equipes.module';
import {RegrasPisCofinsModule} from './modules/cadastros/regras-pis-cofins/regras-pis-cofins.module';
import {CadastroResponsaveisModule} from './modules/cadastros/responsaveis/responsaveis.module';
import {
    ImportarRegrasPisCofinsModule
} from './modules/cadastros/regras-pis-cofins-importar-movimento/regras-pis-cofins-importar-movimento.module';
import {CadastrosObrigacoesEmpresasModule} from './modules/cadastros/obrigacoes-empresas/obrigacoes-empresas.module';
import {CadastrosObrigacoesFederaisModule} from './modules/cadastros/obrigacoes-federais/obrigacoes-federais.module';
import {CadastrosObrigacoesEstaduaisModule} from './modules/cadastros/obrigacoes-estaduais/obrigacoes-estaduais.module';
import {
    CadastrosObrigacoesPrevidenciarioModule
} from './modules/cadastros/obrigacoes-previdenciario/obrigacoes-previdenciario.module';
import {ObrigacoesRegrasModule} from './modules/check/obrigacoes-regras/obrigacoes-regras.module';
import {ObrigacoesEditarRegrasModule} from './modules/check/editar-obrigacoes-regras/editar-obrigacoes-regras.module';

import {CheckObrigacoesModule} from './modules/check/checkObrigacoes/checkObrigacoes.module';
import {ComplianceTributosModule} from './modules/compliance/complianceTributos/complianceTributos.module';
import {
    ComplianceTributosEmpresasModule
} from './modules/compliance/complianceTributosEmpresas/complianceTributosEmpresas.module';

import {CadastrosObrigacoesModule} from './modules/cadastros/obrigacoes/obrigacoes.module';
import {ObrigacaoDetalheModule} from './modules/cadastros/obrigacoes/obrigacao-detalhe/obrigacao-detalhe.module';
import {
    ObrigacaoEmpresaDetalheModule
} from './modules/cadastros/obrigacoes-empresas/obrigacao-empresa-detalhe/obrigacao-empresa-detalhe.module';
import {ComplianceCalendarModule} from './modules/compliance/complianceCalendar/complianceCalendar.module';
import {MonitorProcessamentosModule} from './modules/monitoramento/processamentos/monitorProcessamentos.module';
import {CalendarTasksModule} from './modules/calendar/calendarTasks/calendarTasks.module';
import {CalendarObrigacoesModule} from './modules/calendar/calendarObrigacoes/calendarObrigacoes.module';
import {CheckNotasModule} from './modules/compliance/spedNotas/checkNotas.module';
import {CheckNotasDetalhesModule} from './modules/compliance/spedNotasDetalhes/checkNotasDetalhes.module';
import {TransitoriosUploadArquivosModule} from './modules/drive/transitorios/uploadArquivos/uploadArquivos.module';
import {StickyHeaderDirective} from '@components/directive/directive';
import {ObrigacoesEstaduaisModule} from './modules/drive/obrigacoesEstaduais/obrigacoesEstaduais.module';
import {ObrigacoesPrincipaisModule} from './modules/drive/obrigacoesPrincipais/obrigacoesPrincipais.module';
import {SimplesNacionalModule} from './modules/terceiros/simplesNacional/simplesNacional.module';
import {BaixasAutomaticasModule} from './modules/drive/baixas-automaticas/baixas-automaticas.module';
import {OmissoesSefazModule} from './modules/consultas/omissoes-sefaz/omissoes-sefaz.module';
import {
    BaixasAutomaticasDetalhesModule
} from './modules/drive/baixas-automaticas-detalhes/baixas-automaticas-detalhes.module';
import {
    CheckEfdContribuicoesTributacaoModule
} from './modules/check/checkEfdContribuicoesTributacao/checkEfdContribuicoesTributacao.module';
import {
    CheckEfdContribuicoesTributacaoDetalhesModule
} from './modules/check/checkEfdContribuicoesTributacaoDetalhes/checkEfdContribuicoesTributacaoDetalhes.module';

import {FlowObrigacoesModule} from './modules/flow/flowObrigacoes/flowObrigacoes.module';

import {GuiasArquivosModule} from './modules/integracoes/guiasArquivos/guiasArquivos.module';
import {ErpModule} from './modules/integracoes/erp/erp.module';
import {GuiasLotesModule} from './modules/integracoes/guiasLotes/guiasLotes.module';
import {SafePipeModule} from 'safe-pipe';
import {NotifyModule} from '@components/notify/notify.module';
import {TasksModule} from '@components/tasks/tasks.module';

import {VisibilidadesModule} from './modules/administracao/visibilidades/visibilidades.module';
import {GruposEmpresariaisModule} from './modules/administracao/grupos-empresariais/grupos-empresariais.module';
import {
    VisibilidadeDetalheModule
} from './modules/administracao/visibilidades/visibilidade-detalhe/visibilidade-detalhe.module';
import {CadastrosCertidoesModule} from './modules/cadastros/certidoes/certidoes.module';
import {CadastrosDomicilioEletronicoModule} from './modules/cadastros/domicilioEletronico/domicilioEletronico.module';
import {
    NotasFiscaisEletronicasModule
} from './modules/configuracoes/notasFiscaisEletronicas/notasFiscaisEletronicas.module';
import {
    EditarNotasFiscaisEletronicasModule
} from './modules/configuracoes/editarNotasFiscaisEletronicas/editarNotasFiscaisEletronicas.module';

import {DecCndModule} from './modules/decCnd/dashboard/decCnd.module';
import {ComplianceBaixasEAutomacoesModule} from './modules/configuracoes/compliance-baixas-e-automacoes/compliance-baixas-e-automacoes.module';
import {CalendarConfirmacoesModule} from './modules/calendar/calendarConfirmacoes/calendarConfirmacoes.module';
import {ControleDeCertificadosModule} from './modules/dashboards/controle-de-certificados/controle-de-certificados.module';
import { InboxConfigModule } from './modules/configuracoes/inboxConfig/inboxConfig.module';

const antDesignIcons = AllIcons as {
    [key: string]: IconDefinition;
};
const icons: IconDefinition[] = Object.keys(antDesignIcons).map(key => antDesignIcons[key]);
const maskConfig: Partial<IConfig> = {
    validation: false,
};
registerLocaleData(pt);

const reducers: ActionReducerMap<any> = {
    tabs: TabReducer,
    forms: Reducer,
};

import {NotifyService} from '@components/notify/notify.service';
import {NotifyTestModule} from './modules/notificacoes/notifyTest/notifyTest.module';

import {ConnectDownloadModule} from './modules/connect/connectDownload/connectDownload.module';
import {ControleCertidoesModule} from './modules/certidoes/controleCertidoes/controleCertidoes.module';
import {DomicilioEletronicoModule} from './modules/domicilio/domicilioEletronico/domicilioEletronico.module';
import {MessagesListComponent} from '@components/messages-list/messages-list.component';

import {SefazErpModule} from './modules/notas/sefazErp/sefazErp.module';
import {SefazErpDetalhesModule} from './modules/notas/sefazErpDetalhes/sefazErpDetalhes.module';
import {NotasEmitidasModule} from './modules/notas/notasEmitidas/notasEmitidas.module';
import {NotasEmitidasEmpresaModule} from './modules/notas/notasEmitidasEmpresa/notasEmitidasEmpresa.module';
import {
    CadastrosStepsObrigacoesEmpresasModule
} from './modules/cadastros/steps-obrigacoes-empresas/steps-obrigacoes-empresas.module';
import {CadastrosGatilhosModule} from './modules/cadastros/gatilhos/gatilhos.module';
import {CadastrosEditarGatilhosModule} from './modules/cadastros/editar-gatilhos/editar-gatilhos.module';
import {FlowAprovacoesModule} from './modules/flow/flowAprovacoes/flowAprovacoes.module';
import {CadastrosCategoriasModule} from './modules/cadastros/categorias/categorias.module';
import {ReceitaFederalModule} from './modules/receita/receitaFederal/receitaFederal.module';
import {TransmissoesDetalhesModule} from './modules/delivery/transmissoesDetalhes/transmissoesDetalhes.module';
import {ConciliacoesModule} from './modules/check/conciliacoes/conciliacoes.module';
import {ConciliacoesDetalhesModule} from './modules/check/conciliacoesDetalhes/conciliacoesDetalhes.module';
import {ArmazenamentosModule} from './modules/check/armazenamentos/armazenamentos.module';
import {ArmazenamentosDetalhesModule} from './modules/check/armazenamentosDetalhes/armazenamentosDetalhes.module';
import {DadosEmpresaModule} from './modules/empresa/dados-empresa/dados-empresa.module';
import {DetalhesDadosEmpresaModule} from './modules/empresa/detalhes-dados-empresa/detalhes-dados-empresa.module';

import {DownloadCenterModule} from './modules/downloadCenter/downloadCenter.module';
import {DatePipe} from '@angular/common';
import {TransmissoesModule} from './modules/delivery/transmissoes/transmissoes.module';
import {ConsolidadoEcacModule} from './modules/situacaoFiscal/ecac/ecac.module';

import {CurrencyMaskModule} from 'ng2-currency-mask';

import {LoadingModule} from './modules/loading/loading.module';
import {ConfiguracoesModule} from './modules/integracoes/configuracoes/configuracoes.module';
import {DiagnosticoFiscalModule} from './modules/compliance/diagnostico-fiscal/diagnostico-fiscal.module';
import {LogsAlteracoesModule} from './modules/administracao/logsAlteracoes/logsAlteracoes.module';
import {HistoricoDeAcessosModule} from './modules/monitoramento/historico-de-acesso/historico-de-acessos.module';
import {DashboardModule} from './modules/compliance/dashboard/dashboard.module';
import {XmlXSpedModule} from './modules/compliance/regras-desconsiderações/regras-desconsideracoes.module';
import {MonitorDeEventosModule} from './modules/cadastros/monitor-de-eventos/monitor-de-eventos.module';
import {CnpjMaskDirectveModule} from './core/diretivas/cnpj-mask-directve.module';
import {ComplianceConfiguracoesModule} from './modules/compliance/configuracoes/compliance-configuracoes.module';
import {CategoriasEStatusModule} from './modules/workflow/categorias-e-status/categorias-e-status.module';
import {NotasFiscaisEntradaModule} from './modules/workflow/notas-fiscais-entrada/notas-fiscais-entrada.module';
import {NfeTerceirosModule} from './modules/notas/nfe-terceiros/nfe-terceiros.module';
import {IconListFaModule} from '@components/icon-list-fa/icon-list-fa.module';
import {
    RegrasAnaliseXmlSaidaModule
} from './modules/cadastros/regras-analise-xml-saida/regras-analise-xml-saida.module';
import {AnalisesXMLSaidasModule} from './modules/notas/AnalisesXMLSaidas/AnalisesXMLSaidas.module';
import {
    AnalisesXMLSaidasDetalhesModule
} from './modules/notas/AnalisesXMLSaidasDetalhesComponent/AnalisesXMLSaidasDetalhes.module';
import {InboxModule} from './modules/inbox/inbox.module';
import {CamposPreviewArquivoModule} from '@components/campos-preview-arquivo/campos-preview-arquivo.module';
import {
    ComplianceTributosGruposDetalhesModule
} from './modules/compliance/compliance-tributos-grupos-detalhes/compliance-tributos-grupos-detalhes.module';
import {
    ComplianceTributosGruposModule
} from './modules/compliance/compliance-tributos-grupos/compliance-tributos-grupos.module';
import {EditarSATModule} from './modules/configuracoes/editarSAT/editarSAT.module';

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        StickyHeaderDirective,
        MessagesListComponent,
    ],
    imports: [
        CnpjMaskDirectveModule,
        HistoricoDeAcessosModule,
        LoadingModule,
        BaixasAutomaticasModule,
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        NzLayoutModule,
        UiModule,
        NzBadgeModule,
        NzTabsModule,
        NgxMaskModule.forRoot(maskConfig),
        ExternalPageModule,
        TabsetModule,
        LogModule,
        AdministracaoModule,
        ObrigacoesDashboardModule,
        ComplianceObrigacoesModule,
        ObrigacoesFederaisModule,
        SimplesNacionalModule,
        UsuariosModule,
        EquipesModule,
        RegrasPisCofinsModule,
        CadastroResponsaveisModule,
        ImportarRegrasPisCofinsModule,
        UsuarioDetalheModule,
        UsuarioLogadoModule,
        EmpresasModule,
        EmpresaDetalheModule,
        TransitoriosModule,
        MonitorProcessamentosModule,
        MenuModule,
        NotasFiscaisEletronicasModule,
        EditarNotasFiscaisEletronicasModule,
        ProfileModule,
        PerfisModule,
        PerfilDetalheModule,
        NotasEmitidasModule,
        BaixasAutomaticasDetalhesModule,
        StoreModule.forRoot(reducers, {
            runtimeChecks: {
                strictStateImmutability: false,
                strictActionImmutability: false,
            }
        }),
        ToastrModule.forRoot({
            positionClass: 'toast-bottom-right',
            preventDuplicates: true,
        }),
        CadastrosInscricoesRegimesModule,
        CadastrosEditarInscricoesRegimesModule,
        CadastrosCredenciaisAcessoModule,
        CadastrosEditarCredenciaisAcessoModule,
        CadastrosEditarResponsaveisModule,
        CadastrosObrigacoesEmpresasModule,
        CadastrosObrigacoesFederaisModule,
        CadastrosObrigacoesEstaduaisModule,
        ObrigacoesPrincipaisModule,
        CadastrosObrigacoesPrevidenciarioModule,
        ObrigacoesRegrasModule,
        ObrigacoesEditarRegrasModule,
        CheckObrigacoesModule,
        ComplianceTributosModule,
        ComplianceTributosEmpresasModule,
        CadastrosObrigacoesModule,
        ObrigacaoDetalheModule,
        ObrigacaoEmpresaDetalheModule,
        ComplianceCalendarModule,
        CalendarTasksModule,
        CalendarObrigacoesModule,
        CheckNotasModule,
        CheckNotasDetalhesModule,
        TransitoriosUploadArquivosModule,
        ObrigacoesEstaduaisModule,
        CheckEfdContribuicoesTributacaoModule,
        CheckEfdContribuicoesTributacaoDetalhesModule,
        FlowObrigacoesModule,
        GuiasArquivosModule,
        ErpModule,
        GuiasLotesModule,
        SafePipeModule,
        NotifyModule,
        TasksModule,
        VisibilidadesModule,
        GruposEmpresariaisModule,
        VisibilidadeDetalheModule,
        NotifyTestModule,
        ConnectDownloadModule,
        ControleCertidoesModule,
        DomicilioEletronicoModule,
        CadastrosCertidoesModule,
        CadastrosDomicilioEletronicoModule,
        DecCndModule,
        SefazErpModule,
        SefazErpDetalhesModule,
        NotasEmitidasEmpresaModule,
        CadastrosStepsObrigacoesEmpresasModule,
        CadastrosGatilhosModule,
        CadastrosEditarGatilhosModule,
        FlowAprovacoesModule,
        CadastrosCategoriasModule,
        ReceitaFederalModule,
        CadastrosCategoriasModule,
        TransmissoesDetalhesModule,
        ConciliacoesModule,
        ConciliacoesDetalhesModule,
        ArmazenamentosModule,
        ArmazenamentosDetalhesModule,
        DownloadCenterModule,
        TransmissoesModule,
        DownloadCenterModule,
        DadosEmpresaModule,
        DetalhesDadosEmpresaModule,
        CurrencyMaskModule,
        ConsolidadoEcacModule,
        ConfiguracoesModule,
        OmissoesSefazModule,
        DiagnosticoFiscalModule,
        ComplianceBaixasEAutomacoesModule,
        LogsAlteracoesModule,
        CalendarConfirmacoesModule,
        XmlXSpedModule,
        DashboardModule,
        MonitorDeEventosModule,
        ComplianceConfiguracoesModule,
        NotasFiscaisEntradaModule,
        NfeTerceirosModule,
        ComplianceConfiguracoesModule,
        IconListFaModule,
        ControleDeCertificadosModule,
        RegrasAnaliseXmlSaidaModule,
        ControleDeCertificadosModule,
        AnalisesXMLSaidasModule,
        AnalisesXMLSaidasDetalhesModule,
        InboxModule,
        CamposPreviewArquivoModule,
        AnalisesXMLSaidasDetalhesModule,
        ComplianceTributosGruposModule,
        ComplianceTributosGruposDetalhesModule,
        EditarSATModule
    ],
    providers: [
        {provide: NZ_DATE_LOCALE, useValue: ptBR},
        {provide: NZ_I18N, useValue: pt_BR},
        {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
        {provide: NZ_ICONS, useValue: icons},
        {provide: LOCALE_ID, useValue: 'pt'},
        DataService,
        Title,
        NotifyService,
        DatePipe
    ],
    bootstrap: [AppComponent],
    exports: []
})
export class AppModule {
    constructor(private i18n: NzI18nService) {
        this.i18n.setLocale(pt_BR);
    }
}
