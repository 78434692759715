import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {EditarInscricoesRegimesService} from './editar-inscricoes-regimes.service';
import {Pagination} from '@models/pagination.model';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import {InscricaoRegime} from '@models/inscricaoRegime.model';
import {StickyHeaderDirective} from '@components/directive/directive';
import {NzModalService} from 'ng-zorro-antd/modal';
import {DataService} from '@services/data.service';
import {Helpers} from "../../../core/helpers";

interface FormStack {
    modalVisible: boolean;
    formGroup: UntypedFormGroup;
}

@Component({
    selector: 'app-cadastros-editar-inscricoes-regimes',
    templateUrl: './editar-inscricoes-regimes.component.html',
    styleUrls: ['./editar-inscricoes-regimes.component.scss'],
})
export class CadastrosEditarInscricoesRegimesComponent extends AbstractListTable<InscricaoRegime> implements OnInit, AfterViewInit {

    @Input() data;

    dadosReceita: any = {};
    dadosCadastrais: any = {};

    pagination: any = [{
        ifs: Pagination,
        matrizes: Pagination,
        empresas: Pagination,
        ies: Pagination,
        ims: Pagination,
    }];

    currentSearchImobiliarias: string;
    currentSearchEstaduais: string;
    currentSearchMunicipais: string;

    formMatriz: FormStack;
    formIf: FormStack;
    formRegime: FormStack;
    formIe: FormStack;
    formIm: FormStack;
    formImobiliaria: FormStack;
    formReceita: FormStack;

    comboRegimes: any = [];
    comboUFs: any = [];
    comboMunicipio: any = [];
    comboSituacaoCadastral: any = ['Ativa', 'Inativa', 'Baixada', 'Inapta'];

    editandoIf = null;
    editandoMatriz = null;

    editandoRegime = null;

    editandoIe = null;

    editandoIm = null;

    editandoImobiliaria = null;

    editarDocumentoReceita = '';
    editarSituacaoCadstralReceita = 'Ativo';

    currentParams: any = {
        ifs: this.abstractService.NzTableQueryParams,
        matrizes: this.abstractService.NzTableQueryParams,
        regimes: this.abstractService.NzTableQueryParams,
        ies: this.abstractService.NzTableQueryParams,
        ims: this.abstractService.NzTableQueryParams,
        imobiliarias: this.abstractService.NzTableQueryParams
    };

    loadingIf = false;
    loadingMatrizes = false;
    loadingRegime = false;
    loadingIe = false;
    loadingIm = false;
    loadingImombiliarias = false;
    loadingRFB = false;

    showBuscarImobiliarias = false;
    showBuscarEstaduais = false;
    showBuscarMunicipais = false;

    autoTips: Record<string, Record<string, string>> = {
        default: {
            required: 'Campo obrigatório',
        }
    };

    gravando = false;
    gravandoImobiliaria = false;

    ifs: any[] = [];
    matrizes: any[] = [];
    regimes: any[] = [];
    ies: any[] = [];
    ims: any[] = [];
    iImobiliarias: any[] = [];

    loading = false;
    loadingReceita = false;
    description = '';

    obrigacao: any = {};

    offsetTop = 15;
    target: any;

    isVisible = false;
    removendoId = null;

    erro = false;
    mensagem = '';

    loadings = {
        importando: false,
        exportando: false,
        gravandoMatriz: false,
        gravandoRegime: false,
        gravandoReceita: false,
        gravandoIF: false,
        gravandoIE: false,
        gravandoIM: false,
        gravandoII: false,
        loadingDadosCadastrais: false
    };

    disabledReceita: any = false;

    currentUser: any;

    constructor(
        private fb: UntypedFormBuilder,
        private editarInscricoesRegimesService: EditarInscricoesRegimesService,
        private toastService: ToastrService,
        private cdr: ChangeDetectorRef,
        private modalService: NzModalService,
        private dataService: DataService
    ) {

        super(editarInscricoesRegimesService, InscricaoRegime, toastService);

        this.editarInscricoesRegimesService.retornaCodigosReceitas().subscribe((retorno: any) => {
            this.comboRegimes = retorno.regimesTributarios;
        });

        this.formIf = {
            modalVisible: false,
            formGroup: this.fb.group({
                documento: [null, null],
                dataInicio: [null, null],
                dataFim: [null, null],
                situacaoCadastral: [null, null],
            })
        };

        this.formMatriz = {
            modalVisible: false,
            formGroup: this.fb.group({
                cnpj: [null, null],
                dataInicio: [null, null],
                dataFim: [null, null]
            })
        };

        this.formRegime = {
            modalVisible: false,
            formGroup: this.fb.group({
                regimeTributario_id: [null, null],
                dataInicio: [null, null],
                dataFim: [null, null]
            })
        };

        this.formIe = {
            modalVisible: false,
            formGroup: this.fb.group({
                isento: [null, null],
                ie: [null, null],
                uf: [null, null],
                tipo: [null, null],
                dataInicio: [null, null],
                dataFim: [null, null],
                situacaoCadastral: [null, null],
            })
        };

        this.formIm = {
            modalVisible: false,
            formGroup: this.fb.group({
                im: [null, null],
                municipioCodigo: [null, null],
                uf: [null, null],
                dataInicio: [null, null],
                dataFim: [null, null],
                situacaoCadastral: [null, null],
                tipo: [null, null],
            })
        };

        this.formImobiliaria = {
            modalVisible: false,
            formGroup: this.fb.group({
                empresa_id: [null],
                ii: [null, [Validators.required]],
                uf: [null, [Validators.required]],
                municipioCodigo: [null, [Validators.required]],
                situacaoCadastral: [null, [Validators.required]],
                dataInicio: [null, [Validators.required]],
                tipo: [null, [Validators.required]],
                setor: [null, null],
                descricao: [null, null],
            })
        };

        this.formReceita = {
            modalVisible: false,
            formGroup: this.fb.group({
                empresa_id: [null, null],
                documento: [null, null],
                situacaoCadastral: [null, null],
                regimeTributario_id: [null, null],
            })
        };


    }

    ngOnInit(): void {
        if (!this.data || !this.data.id) {
            this.data = {};
            this.data.id = localStorage.getItem('param');
        }
        this.retornaDadosCadastrais(this.data.id);
    }

    ngAfterViewInit() {

        this.target = StickyHeaderDirective.target;
        this.cdr.detectChanges();

        this.dataService.currentUser.subscribe((data) => {
            this.currentUser = data;
            this.cdr.detectChanges();
        });

    }

    retornaDadosCadastrais(empresaId: string): void {
        this.loadings.loadingDadosCadastrais = true;
        this.editarInscricoesRegimesService.retornaDadosCadastrais(empresaId).subscribe((response: any) => {
            this.dadosCadastrais = response;
            this.loadings.loadingDadosCadastrais = false;
        }, error => {
            this.loadings.loadingDadosCadastrais = false;
            this.toastService.error(error.error.message);
        });
    }

    retornaDadosReceitaFederal(empresaId: string): void {
        this.editarInscricoesRegimesService.retornaReceitaFederal(empresaId).subscribe((response: any) => {
            this.dadosReceita = response;
        });
    }

    listarMatrizes(event: any) {

        this.loadingMatrizes = true;

        this.currentParams.matrizes = event;

        if (event.filter) {
            event.filter.push({key: 'empresa_id', value: this.data.id});
            event.pageSize = 10;
        }

        this.abstractService.listToTable(event, this.currentSearch,

            this.editarInscricoesRegimesService.baseUrlMatrizes).subscribe((response) => {

            this.matrizes = [];

            this.matrizes = response?.data || response;

            this.pagination.matrizes = new Pagination(
                response?.per_page || 10,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 10);

            this.loadingMatrizes = false;

            this.cdr.detectChanges();
        }, error => {
            this.loadingMatrizes = false;
            this.toastService.error(error.error.message);
        });

    }

    listarRegimes(event: any) {

        this.loadingRegime = true;

        this.currentParams.regimes = event;

        if (event.filter) {
            event.filter.push({key: 'empresa_id', value: this.data.id});
            event.pageSize = 10;
        }

        this.abstractService.listToTable(event, this.currentSearch,
            this.editarInscricoesRegimesService.baseUrlRegimes).subscribe((response) => {

            this.regimes = [];
            this.regimes = response?.data || response;

            this.pagination.regimes = new Pagination(
                response?.per_page || 10,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 10);

            this.loadingRegime = false;
        }, error => {
            this.loadingRegime = false;
            this.toastService.error(error.error.message);
        });

    }

    listarIfs(event: any) {

        this.loadingIf = true;

        this.currentParams.ifs = event;

        if (event?.filter) {
            event.filter.push({key: 'empresa_id', value: this.data.id});
            event.pageSize = 10;
        }

        this.abstractService.listToTable(event, this.currentSearch,

            this.editarInscricoesRegimesService.baseUrlIfs).subscribe((response) => {

            this.ifs = [];

            this.ifs = response?.data || response;

            this.pagination.ifs = new Pagination(
                response?.per_page || 10,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 10);

            this.loadingIf = event?.pageIndex > 0 ? false : true;
        }, error => {
            this.loadingIf = false;
            this.toastService.error(error.error.message);
        });
    }

    insertIf(): void {

        this.validaFormularioIf();

        const dados = this.formIf.formGroup.value;

        const empresaId = this.data.id;

        if (!this.erro) {

            this.loadings.gravandoIF = true;

            this.editarInscricoesRegimesService.insertIf(empresaId, dados).subscribe((response: any) => {
                this.loadings.gravandoIF = false;
                this.listarIfs(this.currentParams.ifs);
                this.toastrService.success('Inscrição Federal cadastrada com sucesso!');
                this.formIf.modalVisible = false;
            }, (response) => {
                this.toastrService.error(response.error.message);
                this.loadings.gravandoIF = false;
            });

            this.erro = false;
            this.mensagem = '';

        } else {

            this.toastrService.error(this.mensagem);
            this.erro = false;

        }
    }

    validaFormularioIf(): void {
        if (this.formIf.formGroup.value.documento.length < 14) {
            this.erro = true;
            this.mensagem = 'Documento inválido';
        }

        if (!this.formIf.formGroup.value.dataInicio) {
            this.erro = true;
            this.mensagem = 'Data de início obrigatório';
        }
    }

    alterarIf(item: any): void {

        this.zerarForms();

        this.editandoIf = item.id;
        this.formIf.formGroup.get('documento').setValue(item.documento);
        if (item.situacaoCadastral) {
            this.formIf.formGroup.get('situacaoCadastral').setValue(item.situacaoCadastral);
        }

        if (item.dataInicio) {
            const dtInicio = new Date(item.dataInicio).setDate(new Date(item.dataInicio).getDate() + 1);
            this.formIf.formGroup.get('dataInicio').setValue(dtInicio);
        }

        if (item.dataFim) {
            const dtFim = new Date(item.dataFim).setDate(new Date(item.dataFim).getDate() + 1);
            this.formIf.formGroup.get('dataFim').setValue(dtFim);
        }

        this.formIf.modalVisible = true;

    }

    updateIf(): void {

        this.validaFormularioIf();

        const dados = this.formIf.formGroup.value;

        dados.empresa_id = this.data.id;

        const id = this.editandoIf;

        if (!this.erro) {

            this.loadings.gravandoIF = true;
            this.editarInscricoesRegimesService.updateIf(id, dados).subscribe((response: any) => {

                    this.loadings.gravandoIF = false;
                    this.formIf.modalVisible = false;

                    this.listarIfs(this.currentParams.ifs);

                    this.toastrService.success('Inscricao Federal atualizada com sucesso!');
                },
                (response) => {

                    this.toastrService.error(response.error.message);
                    this.loadings.gravandoIF = false;

                });
        } else {
            this.toastrService.error(this.mensagem);
            this.erro = false;
        }

    }

    listarIes(event: any) {

        this.loadingIe = true;

        this.currentParams.ies = event;

        if (event.filter) {
            event.filter.push({key: 'empresa_id', value: this.data.id});
            event.pageSize = 10;
        }

        this.abstractService.listToTable(event, this.currentSearchEstaduais,

            this.editarInscricoesRegimesService.baseUrlIes).subscribe((response) => {

            this.ies = [];

            this.ies = response?.data || response;

            this.pagination.ies = new Pagination(
                response?.per_page || 10,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 10);

            if (!this.showBuscarEstaduais && response.total > 10) {
                this.showBuscarEstaduais = true;
            }

            this.loadingIe = false;
        }, error => {
            this.toastService.error(error.error.message);
            this.loadingIe = false;
        });

    }

    listarIms(event: any) {

        this.loadingIm = true;

        this.currentParams.ims = event;

        if (event.filter) {
            event.filter.push({key: 'empresa_id', value: this.data.id});
            event.pageSize = 10;
        }

        this.abstractService.listToTable(event, this.currentSearchMunicipais,

            this.editarInscricoesRegimesService.baseUrlIms).subscribe((response) => {

            this.ims = [];

            this.ims = response?.data || response;

            this.ims.forEach(value => {
                if (value.dataFim <= Helpers.formataDateBD((new Date()).toString())) {
                    value.situacaoCadastral = 'Baixada';
                }
            });




            this.pagination.ims = new Pagination(
                response?.per_page || 10,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 10);

            if (!this.showBuscarMunicipais && response.total > 10) {
                this.showBuscarMunicipais = true;
            }

            this.loadingIm = false;

        }, error => {
            this.loadingIm = false;
            this.toastService.error(error.error.message);
        });

    }

    listarImobiliarias(event: any) {

        this.loadingImombiliarias = true;

        this.currentParams.imobiliarias = event;

        if (event?.filter) {
            event.filter.push({key: 'empresa_id', value: this.data.id});
            event.pageSize = 10;
        }

        this.abstractService.listToTable(event, this.currentSearchImobiliarias,

            this.editarInscricoesRegimesService.baseUrlImobiliarias).subscribe((response) => {

            this.iImobiliarias = [];

            this.iImobiliarias = response?.data || response;

            this.pagination.imobiliarias = new Pagination(
                response?.per_page || 10,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 10);

            if (!this.showBuscarImobiliarias && response.total > 10) {
                this.showBuscarImobiliarias = true;
            }

            this.loadingImombiliarias = false;

            this.loading = false;

        }, error => {
            this.toastService.error(error.error.message);
            this.loadingImombiliarias = false;
        });

    }

    cadastrar(formulario, verificar: boolean = false): void {

        this.editandoMatriz = null;
        this.editandoIe = null;
        this.editandoRegime = null;
        this.editandoIe = null;
        this.editandoIm = null;
        this.editandoIf = null;
        this.editandoImobiliaria = null;

        this.zerarForms();

        this.editarInscricoesRegimesService.retornaComboUFs().subscribe((retorno: any) => {
            retorno.estados.forEach((value) => {
                value.label = value.uf + ' - ' + value.nome;
                value.key = value.uf;
            });
            this.comboUFs = [];
            this.comboUFs = retorno.estados;
        });

        formulario.modalVisible = true;
    }

    atualizarViaRFB(): void {
        this.loadingRFB = true;
        this.disabledReceita = 'disabled';
        this.editarInscricoesRegimesService.atualizaViaRFB(this.data.id).subscribe((retorno: any) => {
                this.retornaDadosReceitaFederal(this.data.id);
                this.loadingRFB = false;
                this.disabledReceita = false;
                this.toastrService.success('Dados RFB via portal atualizados com sucesso');
            },
            (response) => {
                this.toastrService.error(response.error.message);
                this.loadingRFB = false;
                this.disabledReceita = false;

            });
    }

    zerarForms(): void {
        this.formIf.formGroup.reset();
        this.formMatriz.formGroup.reset();
        this.formRegime.formGroup.reset();
        this.formIe.formGroup.reset();
        this.formIm.formGroup.reset();
    }

    fechar(formulario): void {
        formulario.modalVisible = false;
        this.editandoImobiliaria = null;
        this.formImobiliaria.formGroup.reset();
    }

    insertMatriz(): void {

        this.validaFormularioMatriz();

        const dados = this.formMatriz.formGroup.value;

        const empresaId = this.data.id;

        if (!this.erro) {

            this.loadings.gravandoMatriz = true;

            this.editarInscricoesRegimesService.insertMatriz(empresaId, dados).subscribe((response: any) => {
                    this.loadings.gravandoMatriz = false;
                    this.listarMatrizes(this.currentParams.matrizes);
                    this.toastrService.success('Matriz cadastrada com sucesso!');
                    this.formMatriz.modalVisible = false;
                },
                (response) => {

                    this.toastrService.error(response.error.message);
                    this.loadings.gravandoMatriz = false;

                });
            this.erro = false;
            this.mensagem = '';
        } else {
            this.toastrService.error(this.mensagem);
            this.erro = false;
        }
    }

    validaFormularioMatriz(): void {
        if (this.formMatriz.formGroup.value.cnpj.length < 14) {
            this.erro = true;
            this.mensagem = 'CNPJ inválido';
        }

        if (!this.formMatriz.formGroup.value.dataInicio) {
            this.erro = true;
            this.mensagem = 'Data de início obrigatório';
        }
    }

    validaFormularioRegime(): void {
        if (!this.formRegime.formGroup.value.regimeTributario_id) {
            this.erro = true;
            this.mensagem = 'Regime tributário obrigatório!';
        }
        if (!this.formRegime.formGroup.value.dataInicio) {
            this.erro = true;
            this.mensagem = 'Data de início obrigatório!';
        }
    }

    validaFormularioIE(): void {
        if (!this.formIe.formGroup.value.isento) {

            if (!this.formIe.formGroup.value.ie) {
                this.erro = true;
                this.mensagem = 'Campo IE obrigatório!';
            }
            if (!this.formIe.formGroup.value.uf) {
                this.erro = true;
                this.mensagem = 'Campo UF obrigatório!';
            }
            if (!this.formIe.formGroup.value.tipo) {
                this.erro = true;
                this.mensagem = 'Campo Tipo obrigatório!';
            }
            if (!this.formIe.formGroup.value.dataInicio) {
                this.erro = true;
                this.mensagem = 'Campo Data Início obrigatório!';
            }
            if (!this.formIe.formGroup.value.situacaoCadastral) {
                this.erro = true;
                this.mensagem = 'Campo Situação obrigatório!';
            }


        }

    }

    validaFormularioIM(): void {

        if (!this.formIm.formGroup.value.im) {
            this.erro = true;
            this.mensagem = 'Campo IM obrigatório!';
        }

        if (!this.formIm.formGroup.value.municipioCodigo) {
            this.erro = true;
            this.mensagem = 'Campo Município obrigatório!';
        }

        if (!this.formIm.formGroup.value.dataInicio) {
            this.erro = true;
            this.mensagem = 'Campo Data Início obrigatório!';
        }

        if (this.formIm.formGroup.value.situacaoCadastral === 'Baixado' && !this.formIm.formGroup.value.dataFim) {
            this.erro = true;
            this.mensagem = 'Campo Data Fim obrigatório para essa situação Cadastral!';
        }

        if (!this.formIm.formGroup.value.situacaoCadastral) {
            this.erro = true;
            this.mensagem = 'Campo Data Situação obrigatório!';
        }

        if (!this.formIm.formGroup.value.tipo) {
            this.erro = true;
            this.mensagem = 'Campo Tipo obrigatório!';
        }

    }

    updateMatriz(): void {

        this.validaFormularioMatriz();

        const dados = this.formMatriz.formGroup.value;

        dados.empresa_id = this.data.id;

        const id = this.editandoMatriz;

        if (!this.erro) {
            this.loadings.gravandoMatriz = true;
            this.editarInscricoesRegimesService.updateMatriz(id, dados).subscribe((response: any) => {

                    this.loadings.gravandoMatriz = false;
                    this.formMatriz.modalVisible = false;

                    this.listarMatrizes(this.currentParams.matrizes);

                    this.toastrService.success('Matriz atualizada com sucesso!');
                },
                (response) => {

                    this.toastrService.error(response.error.message);
                    this.loadings.gravandoMatriz = false;

                });
        } else {
            this.toastrService.error(this.mensagem);
            this.erro = false;
        }

    }

    updateReceita(): void {
        const dados = this.formReceita.formGroup.value;

        const empresaId = this.data.id;

        const inscricaoFederalID = this.dadosReceita.id;

        this.loadings.gravandoReceita = true;

        this.editarInscricoesRegimesService.updateReceita(empresaId, inscricaoFederalID, dados).subscribe((response: any) => {


                this.loadings.gravandoReceita = false;

                this.formReceita.modalVisible = false;

                this.retornaDadosReceitaFederal(this.data.id);

                this.toastrService.success('Receita Federal atualizada com sucesso!');
            },
            (response) => {

                this.toastrService.error(response.error.message);
                this.loadings.gravandoReceita = false;

            });
    }

    updateRegime(): void {

        this.validaFormularioRegime();

        const dados = this.formRegime.formGroup.value;

        dados.empresa_id = this.data.id;

        const id = this.editandoRegime;

        if (!this.erro) {
            this.loadings.gravandoRegime = true;
            this.editarInscricoesRegimesService.updateRegime(id, dados).subscribe((response: any) => {

                    this.loadings.gravandoRegime = false;
                    this.formRegime.modalVisible = false;

                    this.listarRegimes(this.currentParams.regimes);

                    this.toastrService.success('Regime Tributário atualizado com sucesso!');
                },
                (response) => {

                    this.toastrService.error(response.error.message);
                    this.loadings.gravandoRegime = false;

                });
        } else {
            this.toastrService.error(this.mensagem);
            this.erro = false;
        }
    }

    confirmInsertIe() {
        if (this.formIe.formGroup.value.uf === 'AM' && !this.formIe.formGroup.value.dataFim
            && this.formIe.formGroup.value.tipo === 'Normal' && this.formIe.formGroup.value.situacaoCadastral === 'Ativo') {
            let jaExiste = false;

            this.ies.forEach(ie => {
                if (this.formIe.formGroup.value.uf === ie.uf && ie.tipo === 'Normal' && !ie.dataFim && ie.situacaoCadastral === 'Ativo') {
                    jaExiste = true;
                }
            });

            if (jaExiste) {
                this.modalService.confirm({
                    nzTitle: 'Já existem inscrições normais e ativas cadastradas para esta UF. ' +
                        'A inclusão de nova inscrição implica em criação de novo registro para armazenamento e análises. ' +
                        'Deseja mesmo incluir esse registro',
                    nzOkText: 'Confirmar',
                    nzCancelText: 'Cancelar',
                    nzOnOk: () =>
                        this.insertIe()
                });
            } else {
                this.insertIe();
            }

        } else {
            this.insertIe();
        }
    }

    updateIe(): void {
        this.validaFormularioIE();

        let dados: any = {};
        if (this.formIe.formGroup.value.isento) {
            dados.isento = true;
            dados.situacaoCadastral = null;
            dados.ie = null;
            dados.uf = null;
            dados.tipo = null;
            dados.dataInicio = null;
            dados.dataFim = null;

        } else {
            dados = this.formIe.formGroup.value;
        }

        dados.empresa_id = this.data.id;

        const id = this.editandoIe;

        if (!this.erro) {

            this.loadings.gravandoIE = true;

            this.editarInscricoesRegimesService.updateIe(id, dados).subscribe((response: any) => {

                    this.formIe.modalVisible = false;

                    this.listarIes(this.currentParams.ies);

                    this.toastrService.success('Inscrição Estadual atualizada com sucesso!');

                    this.loadings.gravandoIE = false;

                },
                (response) => {

                    this.toastrService.error(response.error.message);
                    this.loadings.gravandoIE = false;

                });
        } else {
            this.toastrService.error(this.mensagem);
            this.erro = false;
        }
    }

    updateIm(): void {

        this.validaFormularioIM();

        const dados = this.formIm.formGroup.value;

        dados.im = dados.im.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

        dados.empresa_id = this.data.id;

        const id = this.editandoIm;

        if (!this.erro) {

            this.loadings.gravandoIM = true;

            this.editarInscricoesRegimesService.updateIm(id, dados).subscribe((response: any) => {

                    this.formIm.modalVisible = false;

                    this.listarIms(this.currentParams.ies);

                    this.toastrService.success('Inscrição Municipal atualizado com sucesso!');

                    this.editandoIm = null;

                    this.loadings.gravandoIM = false;

                },
                (response) => {

                    this.toastrService.error(response.error.message);
                    this.loadings.gravandoIM = false;

                });

        } else {

            this.toastrService.error(this.mensagem);
            this.erro = false;

        }

    }

    updateImobiliarias(): void {

        if (this.formImobiliaria.formGroup.valid) {

            const dados = this.formImobiliaria.formGroup.value;

            dados.empresa_id = this.data.id;

            const id = this.editandoImobiliaria;

            this.loadings.gravandoII = true;

            this.editarInscricoesRegimesService.updateImobiliaria(id, dados).subscribe((response: any) => {
                    this.loadings.gravandoII = false;

                    this.formImobiliaria.modalVisible = false;
                    this.formImobiliaria.formGroup.reset();

                    this.listarImobiliarias(this.currentParams.imobiliarias);

                    this.toastrService.success('Inscrição Imobiliária atualizado com sucesso!');

                },
                (response) => {

                    this.toastrService.error(response.error.message);
                    this.loadings.gravandoII = false;

                });

        } else {
            Object.values(this.formImobiliaria.formGroup.controls).forEach(control => {
                if (control.invalid) {
                    control.markAsDirty();
                    control.updateValueAndValidity({onlySelf: true});
                }
            });
        }

    }

    insertRegime(): void {

        this.validaFormularioRegime();

        const dados = this.formRegime.formGroup.value;

        const empresaId = this.data.id;

        if (!this.erro) {
            this.loadings.gravandoRegime = true;
            this.editarInscricoesRegimesService.insertRegime(empresaId, dados).subscribe((response: any) => {
                this.loadings.gravandoRegime = false;
                this.listarRegimes(this.currentParams.regimes);
                this.toastrService.success('Regime Tributário cadastrado com sucesso!');
                this.formRegime.modalVisible = false;
            }, (err) => {
                this.toastrService.error(err.error.message);
                this.loadings.gravandoRegime = false;
            });
        } else {
            this.toastrService.error(this.mensagem);
            this.erro = false;
            this.loadings.gravandoRegime = false;
        }
    }

    insertIe(): void {

        this.validaFormularioIE();

        let dados: any = {};
        if (this.formIe.formGroup.value.isento) {
            dados.isento = true;
            dados.situacaoCadastral = null;
            dados.ie = null;
            dados.uf = null;
            dados.tipo = null;
            dados.dataInicio = null;
            dados.dataFim = null;
        } else {
            dados = this.formIe.formGroup.value;
        }

        const empresaId = this.data.id;

        if (!this.erro) {
            this.loadings.gravandoIE = true;
            this.editarInscricoesRegimesService.insertIe(empresaId, dados).subscribe((response: any) => {
                    this.listarIes(this.currentParams.ies);
                    this.toastrService.success('Inscrição Estadual cadastrada com sucesso!');
                    this.formIe.modalVisible = false;
                    this.loadings.gravandoIE = false;
                },
                (response) => {
                    this.toastrService.error(response.error.message);
                    this.loadings.gravandoIE = false;
                });
        } else {
            this.toastrService.error(this.mensagem);
            this.erro = false;
        }
    }

    insertIm(): void {

        this.validaFormularioIM();

        const dados = this.formIm.formGroup.value;

        dados.im = dados.im.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

        const empresaId = this.data.id;

        if (!this.erro) {
            this.loadings.gravandoIM = true;
            this.editarInscricoesRegimesService.insertIm(empresaId, dados).subscribe((response: any) => {
                    this.listarIms(this.currentParams.ims);
                    this.toastrService.success('Inscrição Municipal cadastrada com sucesso!');
                    this.formIm.modalVisible = false;
                    this.loadings.gravandoIM = false;
                },
                (err) => {
                    this.toastrService.error(err.error.message);
                    this.loadings.gravandoIM = false;
                });
        } else {
            this.toastrService.error(this.mensagem);
            this.erro = false;
        }
    }

    insertImobiliaria(): void {

        if (this.formImobiliaria.formGroup.valid) {

            const dados = this.formImobiliaria.formGroup.value;

            const empresaId = this.data.id;

            this.loadings.gravandoII = true;

            this.editarInscricoesRegimesService.insertImobiliaria(empresaId, dados).subscribe((response: any) => {

                    this.toastrService.success('Inscrição imobiliária cadastrada com sucesso!');
                    this.formImobiliaria.modalVisible = false;
                    this.formImobiliaria.formGroup.reset();
                    this.loadings.gravandoII = false;
                    this.listarImobiliarias(this.currentParams.imobiliarias);
                },
                (response) => {
                    this.toastrService.error(response.error.message);
                    this.loadings.gravandoII = false;
                });
        } else {
            Object.values(this.formImobiliaria.formGroup.controls).forEach(control => {
                if (control.invalid) {
                    control.markAsDirty();
                    control.updateValueAndValidity({onlySelf: true});
                }
            });
        }
    }

    alterarMatriz(item: any): void {

        this.zerarForms();

        this.editandoMatriz = item.id;
        this.formMatriz.formGroup.get('cnpj').setValue(item.cnpj);

        if (item.dataInicio) {
            const editarDataInicioMatriz = new Date(item.dataInicio);
            editarDataInicioMatriz.setDate(editarDataInicioMatriz.getDate() + 1);
            this.formMatriz.formGroup.get('dataInicio').setValue(editarDataInicioMatriz);
        }

        if (item.dataFim) {
            const editarDataFimMatriz = new Date(item.dataFim);
            editarDataFimMatriz.setDate(editarDataFimMatriz.getDate() + 1);
            this.formMatriz.formGroup.get('dataFim').setValue(editarDataFimMatriz);
        }

        this.formMatriz.modalVisible = true;


    }

    alterarRegime(item: any): void {

        this.zerarForms();

        this.editandoRegime = item.id;

        this.formRegime.formGroup.get('regimeTributario_id').setValue(item.regimeTributario_id);

        if (item.dataInicio) {
            const editarDataInicioRegime = new Date(item.dataInicio);
            editarDataInicioRegime.setDate(editarDataInicioRegime.getDate() + 1);
            this.formRegime.formGroup.get('dataInicio').setValue(editarDataInicioRegime);
        }

        if (item.dataFim) {
            const editarDataFimRegime = new Date(item.dataFim);
            editarDataFimRegime.setDate(editarDataFimRegime.getDate() + 1);
            this.formRegime.formGroup.get('dataFim').setValue(editarDataFimRegime);
        }

        this.formRegime.modalVisible = true;

    }

    alterarIe(item: any): void {

        this.zerarForms();

        this.editarInscricoesRegimesService.retornaComboUFs().subscribe((retorno: any) => {
            retorno.estados.forEach((value) => {
                value.label = value.uf + ' - ' + value.nome;
                value.key = value.uf;
            });
            this.comboUFs = [];
            this.comboUFs = retorno.estados;
        });

        this.editandoIe = item.id;

        if (!item.ie) {
            this.formIe.formGroup.get('isento').setValue(true);
        } else {
            this.formIe.formGroup.get('isento').setValue(false);
        }

        this.formIe.formGroup.get('ie').setValue(item.ie);
        this.formIe.formGroup.get('tipo').setValue(item.tipo);
        this.formIe.formGroup.get('uf').setValue(item.uf);
        this.formIe.formGroup.get('situacaoCadastral').setValue(item.situacaoCadastral);

        if (item.dataInicio) {
            const editarDataInicioIe = new Date(item.dataInicio);
            editarDataInicioIe.setDate(editarDataInicioIe.getDate() + 1);
            this.formIe.formGroup.get('dataInicio').setValue(editarDataInicioIe);
        }
        if (item.dataFim) {
            const editarDataFimIe = new Date(item.dataFim);
            editarDataFimIe.setDate(editarDataFimIe.getDate() + 1);
            this.formIe.formGroup.get('dataFim').setValue(editarDataFimIe);
        }

        this.formIe.modalVisible = true;

    }

    alterarIm(item: any): void {

        this.zerarForms();

        this.editarInscricoesRegimesService.retornaComboUFs().subscribe((retorno: any) => {
            retorno.estados.forEach((value) => {
                value.label = value.uf + ' - ' + value.nome;
                value.key = value.uf;
            });
            this.comboUFs = [];
            this.comboUFs = retorno.estados;
        });

        this.filtraComboMunicipio(item.estado_uf);

        this.editandoIm = item.id;
        this.formIm.formGroup.get('situacaoCadastral').setValue(item.situacaoCadastral);
        this.formIm.formGroup.get('municipioCodigo').setValue(item.municipioCodigo);
        this.formIm.formGroup.get('uf').setValue(item.estado_uf);
        this.formIm.formGroup.get('im').setValue(item.im);
        this.formIm.formGroup.get('tipo').setValue(item.tipo);

        if (item.dataInicio) {
            const editarDataInicioIm = new Date(item.dataInicio);
            editarDataInicioIm.setDate(editarDataInicioIm.getDate() + 1);
            this.formIm.formGroup.get('dataInicio').setValue(editarDataInicioIm);
        }

        if (item.dataFim) {
            const editarDataFimIm = new Date(item.dataFim);
            editarDataFimIm.setDate(editarDataFimIm.getDate() + 1);
            this.formIm.formGroup.get('dataFim').setValue(editarDataFimIm);
        }

        this.formIm.modalVisible = true;

    }

    alterarImobiliaria(item: any): void {

        this.zerarForms();

        this.editarInscricoesRegimesService.retornaComboUFs().subscribe((retorno: any) => {
            retorno.estados.forEach((value) => {
                value.label = value.uf + ' - ' + value.nome;
                value.key = value.uf;
            });
            this.comboUFs = [];
            this.comboUFs = retorno.estados;
        });

        this.filtraComboMunicipio(item.estado_uf);

        this.editandoImobiliaria = item.id;
        this.formImobiliaria.formGroup.get('situacaoCadastral').setValue(item.situacaoCadastral);
        this.formImobiliaria.formGroup.get('municipioCodigo').setValue(item.municipioCodigo);
        this.formImobiliaria.formGroup.get('uf').setValue(item.estado_uf);
        this.formImobiliaria.formGroup.get('ii').setValue(item.ii);
        this.formImobiliaria.formGroup.get('setor').setValue(item.setor);
        this.formImobiliaria.formGroup.get('descricao').setValue(item.descricao);

        this.changeMunicipio();

        if (item.dataInicio) {
            const editarDataInicioIm = new Date(item.dataInicio);
            editarDataInicioIm.setDate(editarDataInicioIm.getDate() + 1);
            this.formImobiliaria.formGroup.get('dataInicio').setValue(editarDataInicioIm);
        }

        this.formImobiliaria.modalVisible = true;

    }

    filtraComboMunicipio(uf: string): void {
        this.editarInscricoesRegimesService.retornaComboMunicipios(uf).subscribe((retorno: any) => {
            retorno.cidades.forEach((value) => {
                value.key = value.ibgeCodigo;
                value.label = value.ibgeCodigo + ' - ' + value.nome;
            });
            this.comboMunicipio = [];
            this.comboMunicipio = retorno.cidades;
        });
    }

    alterarReceita(cnpj: string): void {
        this.loadingReceita = true;
        this.editarInscricoesRegimesService.atualziarRFB(cnpj).subscribe((response) => {
                this.toastrService.success('Dados atualizados');
                this.loadingReceita = false;
                this.retornaDadosReceitaFederal(this.data.id);
                this.disabledReceita = false;
            },
            (response) => {
                this.toastrService.error(response.error.message);
                this.loadingReceita = false;
                this.retornaDadosReceitaFederal(this.data.id);
            });
    }

    remover(tipo: string, item: any): void {
        this.removendoId = item.id;
        switch (tipo) {
            case 'if': {
                this.editarInscricoesRegimesService.removeIf(item.id).subscribe(
                    (response) => {
                        this.toastrService.success('Inscrição Federal removida com sucesso!');
                        this.listarIfs(this.currentParams.ifs);
                        this.removendoId = null;
                    },
                    (response) => {
                        this.removendoId = null;
                        this.toastrService.error(response.error.message);
                        this.gravando = false;
                    }
                );
                break;
            }
            case 'matriz': {
                this.editarInscricoesRegimesService.removeMatriz(item.id).subscribe(
                    (response) => {
                        this.toastrService.success('Matriz removida com sucesso!');
                        this.listarMatrizes(this.currentParams.matrizes);
                        this.removendoId = null;
                    },
                    (response) => {
                        this.removendoId = null;
                        this.toastrService.error(response.error.message);
                        this.gravando = false;

                    }
                );
                break;
            }
            case 'regime': {
                this.removendoId = item.id;
                this.editarInscricoesRegimesService.removeRegime(item.id).subscribe(
                    (response) => {
                        this.toastrService.success('Regime Tributário removido com sucesso!');
                        this.listarRegimes(this.currentParams.regimes);
                        this.removendoId = null;
                    },
                    (response) => {
                        this.removendoId = null;
                        this.toastrService.error(response.error.message);
                        this.gravando = false;

                    }
                );
                break;
            }
            case 'ie': {
                this.editarInscricoesRegimesService.removeIe(item.id).subscribe(
                    (response) => {
                        this.toastrService.success('Inscrição Estadual removida com sucesso!');
                        this.listarIes(this.currentParams.ies);
                        this.removendoId = null;
                    },
                    (response) => {
                        this.removendoId = null;
                        this.toastrService.error(response.error.message);
                        this.gravando = false;

                    }
                );
                break;
            }
            case 'im': {
                this.editarInscricoesRegimesService.removeIm(item.id).subscribe(
                    (response) => {
                        this.toastrService.success('Inscrição Municipal removida com sucesso!');
                        this.listarIms(this.currentParams.ims);
                        this.removendoId = null;
                    },
                    (response) => {
                        this.removendoId = null;
                        this.toastrService.error(response.error.message);
                        this.gravando = false;

                    }
                );
                break;
            }
            case 'imobiliaria': {
                this.editarInscricoesRegimesService.removeImobiliaria(item.id).subscribe(
                    (response) => {
                        this.toastrService.success('Inscrição Imobiliária removida com sucesso!');
                        this.listarImobiliarias(this.currentParams.imobiliarias);
                        this.removendoId = null;
                    },
                    (response) => {
                        this.removendoId = null;
                        this.toastrService.error(response.error.message);
                        this.gravando = false;

                    }
                );
                break;
            }
        }
    }

    handleCancel(): void {
        this.isVisible = false;
    }

    showConfirm(tipo: string, item: any): void {

        let titulo = '';
        switch (tipo) {
            case 'if': {
                titulo = 'Deseja remover a Inscrição Federal?';
                break;
            }
            case 'matriz': {
                titulo = 'Deseja remover a Matriz?';
                break;
            }
            case 'regime': {
                titulo = 'Deseja remover o Regime Tributário?';
                break;
            }
            case 'ie': {
                titulo = 'Deseja remover a Inscrição Estadual?';
                break;
            }
            case 'im': {
                titulo = 'Deseja remover a Inscrição Municipal?';
                break;
            }
            case 'imobiliaria': {
                titulo = 'Deseja remover a Inscrição Imobiliária?';
                break;
            }
        }


        this.modalService.confirm({
            nzTitle: titulo,
            nzOkText: 'Remover',
            nzCancelText: 'Cancelar',
            nzOnOk: () =>
                this.remover(tipo, item)
        });
    }

    sincronizarEmbedded(acao: string): void {

        switch (acao) {

            case 'importar': {

                this.loadings.importando = true;

                this.editarInscricoesRegimesService.sincronizarEmbedded(acao, this.data.id).subscribe((res) => {

                    this.retornaDadosCadastrais(this.data.id);
                    this.listarIfs(this.currentParams.ifs);
                    this.listarRegimes(this.currentParams.regimes);
                    this.listarIes(this.currentParams.ies);
                    this.listarIms(this.currentParams.ims);
                    this.listarImobiliarias(this.currentParams.imobiliarias);
                    this.listarMatrizes(this.currentParams.matrizes);


                    this.toastService.success('Dados importados com sucesso!');
                    this.loadings.importando = false;
                }, (res) => {
                    this.toastService.error(res.error.message);
                    this.loadings.importando = false;
                });
                break;
            }
            case 'exportar': {

                this.loadings.exportando = true;

                this.editarInscricoesRegimesService.sincronizarEmbedded(acao, this.data.id).subscribe((res) => {
                    this.toastService.success('Dados exportados com sucesso!');
                    this.loadings.exportando = false;
                }, (res) => {
                    this.toastService.error(res.error.message);
                    this.loadings.exportando = false;
                });
                break;
            }


        }

    }

    charUnidentified(tipo: string) {
        const form: FormStack = tipo === 'im' ? this.formIm : this.formImobiliaria;
        const inscricao = form.formGroup.get(tipo).value;

        if (inscricao && '`´~^"'.includes(inscricao[inscricao.length - 1]) || inscricao[inscricao.length - 1] === '¨') {
            form.formGroup.get(tipo).setValue(inscricao.substring(0, inscricao.length - 1));
        }
    }

    changeMunicipio() {
        if (this.formImobiliaria.formGroup.get('municipioCodigo').value === '3556206') {
            this.formImobiliaria.formGroup.get('setor').setValidators(Validators.required);
        } else {
            this.formImobiliaria.formGroup.get('setor').setValidators(null);
            this.formImobiliaria.formGroup.get('setor').setErrors(null);
        }
    }

    buscarImobiliarias(limpar = false) {
        if (limpar) {
            this.currentSearchImobiliarias = null;
        }
        this.currentParams.imobiliarias.pageIndex = 1;
        this.listarImobiliarias(this.currentParams.imobiliarias);
    }

    buscarEstaduais(limpar = false) {
        if (limpar) {
            this.currentSearchEstaduais = null;
        }
        this.currentParams.ies.pageIndex = 1;
        this.listarIes(this.currentParams.ies);
    }

    buscarMunicipais(limpar = false) {
        if (limpar) {
            this.currentSearchMunicipais = null;
        }
        this.currentParams.ims.pageIndex = 1;
        this.listarIms(this.currentParams.ims);
    }


}
