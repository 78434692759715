import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CamposPreviewArquivoComponent} from '@components/campos-preview-arquivo/campos-preview-arquivo.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NzPageHeaderModule} from 'ng-zorro-antd/page-header';
import {NzFormModule} from 'ng-zorro-antd/form';
import {NzSelectModule} from 'ng-zorro-antd/select';
import {NzDatePickerModule} from 'ng-zorro-antd/date-picker';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {NzInputModule} from 'ng-zorro-antd/input';
import {NgxMaskModule} from 'ngx-mask';
import {NzLayoutModule} from 'ng-zorro-antd/layout';
import {NzCardModule} from "ng-zorro-antd/card";

@NgModule({
  declarations: [
      CamposPreviewArquivoComponent
  ],
  exports: [
    CamposPreviewArquivoComponent
  ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NzPageHeaderModule,
        NzFormModule,
        NzSelectModule,
        NzDatePickerModule,
        NzButtonModule,
        NzInputModule,
        NgxMaskModule,
        NzLayoutModule,
        NzCardModule
    ]
})
export class CamposPreviewArquivoModule { }
