<nz-page-header class="site-page-header" nzTitle="Certidões" nzSubtitle="Controle">
    <nz-page-header-extra>
        <nz-button-group *ngIf="showContent">
            <button disabled="{{loading}}" nz-button nzType="primary" nzGhost
                    style="border-radius: 0.45em; margin-right: 10px;"
                    (click)="atualizarTela();">
                <i class="fas fa-redo" style="margin-right: 5px;"></i>
                Atualizar
            </button>
            <nz-badge [nzCount]="qtdFiltrosCabecalho"
                      [nzStyle]="{ backgroundColor: '#52c41a'}"
                      style="margin-right: 10px;">
                <button disabled="{{loading}}" nz-button nzType="primary" nzGhost
                        style="border-radius: 0.45em;"
                        (click)="abrirModal(formFiltrosCabecalho)">
                    <i nz-icon nzType="search"></i>
                    Filtros
                </button>
            </nz-badge>
            <button disabled="{{loading}}" nz-button nzType="primary" nzGhost
                    style="border-radius: 0.45em;"
                    (click)="limparFiltrosCabecalho()">
                Limpar
            </button>
        </nz-button-group>
    </nz-page-header-extra>
</nz-page-header>

<div *ngIf="showAlert">
    <nz-row nzGutter="24">
        <nz-alert nzType="warning" nzShowIcon [nzMessage]="checkedMessage" style="margin: 15px !important; width: 100%">
            <ng-template #checkedMessage>
                <div nz-row nzAlign="middle" nzGutter="24">
                    <div nz-col nzSpan="24">
                        <strong class="text-primary"></strong>
                        O modulo deste produto está desabilitado. Contate o suporte!
                    </div>
                </div>
            </ng-template>
        </nz-alert>
    </nz-row>
</div>

<div *ngIf="showContent">
    <nz-content>
        <nz-row [nzGutter]="24">
            <nz-col nzXs="24" nzMd="12" nzSm="24">
                <nz-card nzTitle="Certidões" class="custom-card-height"
                         [ngClass]="!cardCabecalhoLoading ? 'ant-card-table' : ''"
                         [nzLoading]="cardCabecalhoLoading">
                    <div nz-row style="height: 14.6em;">
                        <div nz-col [nzSpan]="12" class="d-flex align-items-center">
                            <highcharts-chart
                                    [Highcharts]="HighchartsPie"
                                    [options]="chartPieOptions"
                                    [(update)]="updateFlag"
                                    *ngIf="HighchartsPie && chartPieOptions"
                                    style="width: 100%; height: 180px; display: block;"
                            ></highcharts-chart>
                        </div>
                        <div [nzSpan]="12" nz-col>
                            <ul nz-list nzBordered style="margin: 10px; cursor: pointer;">
                                <nz-list-item (click)="!loading && addFiltro('statusUltimaEmissao', '1')"
                                              class="ant-card-hoverable"
                                              [ngStyle]="loading && {'cursor' : 'not-allowed'}"
                                              [class.activeFiltro]="filtroStatus && filtroStatus == '1'"
                                              style="padding: 15px;">
                                    <h5 nz-typography class="custom-card-value" style="margin-bottom: 0;">
                                        <i class="fas fa-circle" [ngStyle]="{color: arrayColorStatus[1]}"></i> Negativa
                                    </h5>
                                    <a style="margin-bottom: 0;">{{ tableStatus.status_1 }}</a>
                                </nz-list-item>
                                <nz-list-item (click)="!loading && addFiltro('statusUltimaEmissao', '2')"
                                              class="ant-card-hoverable" style="padding: 15px;"
                                              [ngStyle]="loading && {'cursor' : 'not-allowed'}"
                                              [class.activeFiltro]="filtroStatus && filtroStatus == '2'">
                                    <h5 nz-typography class="custom-card-value" style="margin-bottom: 0;">
                                        <i class="fas fa-circle" [ngStyle]="{color: arrayColorStatus[2]}"></i> Positiva
                                        com
                                        Efeito de Negativa
                                    </h5>
                                    <a style="margin-bottom: 0;">{{ tableStatus.status_2 }}</a>
                                </nz-list-item>
                                <nz-list-item (click)="!loading && addFiltro('statusUltimaEmissao', '3')"
                                              class="ant-card-hoverable" style="padding: 15px;"
                                              [ngStyle]="loading && {'cursor' : 'not-allowed'}"
                                              [class.activeFiltro]="filtroStatus && filtroStatus == '3'">
                                    <h5 nz-typography class="custom-card-value" style="margin-bottom: 0;">
                                        <i class="fas fa-circle" [ngStyle]="{color: arrayColorStatus[3]}"></i> Positiva
                                    </h5>
                                    <a style="margin-bottom: 0;">{{ tableStatus.status_3 }}</a>
                                </nz-list-item>
                                <nz-list-item (click)="!loading && addFiltro('statusUltimaEmissao', '0')"
                                              class="ant-card-hoverable"
                                              [ngStyle]="loading && {'cursor' : 'not-allowed'}"
                                              [class.activeFiltro]="filtroStatus && filtroStatus == '0'"
                                              style="padding: 15px;">
                                    <h5 nz-typography class="custom-card-value" style="margin-bottom: 0;">
                                        <i class="fas fa-circle" [ngStyle]="{color: arrayColorStatus[0]}"></i> Nunca
                                        Emitida
                                    </h5>
                                    <a style="margin-bottom: 0;">{{ tableStatus.status_0 }}</a>
                                </nz-list-item>
                            </ul>
                        </div>
                    </div>
                </nz-card>
            </nz-col>
            <nz-col nzXs="24" nzMd="12" nzSm="24">
                <nz-card class="custom-card-height" [ngClass]="!cardCabecalhoLoading ? 'ant-card-table' : ''"
                         nzTitle="Validade (Última CND)" [nzLoading]="cardCabecalhoLoading">
                    <nz-content style="height: 100%;">
                        <nz-row style="height: 100%;">
                            <nz-col [nzSpan]="24" style="height: 100%;"
                                    class="d-flex justify-content-center align-items-center">
                                <highcharts-chart
                                        class="validade-chart"
                                        [Highcharts]="HighchartsBasic"
                                        [options]="chartBasicOptions"
                                        [(update)]="updateFlag"
                                        *ngIf="HighchartsBasic && chartBasicOptions"
                                        style="height: 200px; display: block;"
                                        (resize)="highChart?.reflow()"
                                ></highcharts-chart>
                            </nz-col>
                        </nz-row>
                    </nz-content>
                </nz-card>
            </nz-col>
        </nz-row>
    </nz-content>
    <nz-content style="overflow:hidden !important;">
        <nz-card nzTitle="Certidões" [ngClass]="[!loading ? 'ant-card-table' : '', statusOne ? 'card-fullscreen' : '']"
                 [nzExtra]="navBar" [nzLoading]="loading" style="overflow:hidden !important;">
            <nz-alert nzType="info" nzShowIcon [nzMessage]="checkedMessage" style="margin: 15px !important;"
                      *ngIf="checkedItems.size > 0">
                <ng-template #checkedMessage>
                    <div nz-row nzAlign="middle" nzGutter="24">
                        <div nz-col nzSpan="24">
                            <strong class="text-primary"></strong>
                            {{ checkedItems.size }} Registros Selecionados
                        </div>
                    </div>
                </ng-template>
            </nz-alert>
            <nz-table #basicTable
                      [nzData]="items"
                      [nzFrontPagination]="false"
                      [nzTotal]="pagination?.total"
                      [nzPageSize]="pagination?.per_page"
                      [nzShowSizeChanger]="true"
                      [nzPageSizeOptions]="sizeOptions"
                      [nzPageIndex]="pagination?.current_page"
                      (nzQueryParams)="queryTable($event, currentSearch)"
                      [nzFooter]="footer"
                      [nzScroll]="{ y: scrollYvh, x: 'auto'}"
                      class="fonte-doze table-small">
                <thead>
                <tr>
                    <!--<th style="text-align: center;">
                        <label nz-checkbox [(ngModel)]="checked" (nzCheckedChange)="onAllChecked($event)"></label>
                    </th>-->
                    <th nzColumnKey="unidade" [nzSortFn]="true">Unidade</th>
                    <th nzColumnKey="grupo" [nzSortFn]="true" nzAlign="center"  *ngIf="exibirColunas.grupo_descricao">Grupo</th>
                    <th nzColumnKey="descricao" [nzSortFn]="true" style="text-align: center;">Certidão</th>
                    <th nzColumnKey="identificador" [nzSortFn]="true" style="text-align: center;">Identificador</th>
                    <th nzColumnKey="tipoEmissao" [nzSortFn]="true" style="text-align: center;">Tipo de Emissão</th>
                    <th nzColumnKey="statusUltimaEmissao" [nzSortFn]="true" style="text-align: center;">
                        Último Status de Consulta
                    </th>
                    <th [nzShowSort]="false" style="text-align: center;">Última Consulta
                    </th>
                    <th nzColumnKey="proximoProcessamento" [nzSortFn]="false" [nzShowSort]="false"
                        style="text-align: center;">Próxima Consulta
                    </th>
                    <th nzColumnKey="emissaoUltimaCnd" [nzSortFn]="true" style="text-align: center;">Última Certidão
                        Negativa
                    </th>
                    <th nzColumnKey="validadeUltimaCnd" [nzSortFn]="true" style="text-align: center;">Validade da Última
                        Negativa
                    </th>
                    <th></th>
                    <th *ngIf="currentUser?.origem === 'console'"></th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let tasks of basicTable.data">
                    <!--<td nzLeft (nzCheckedChange)="onItemChecked(tasks.id, $event)"
                        [nzChecked]="checkedItems.has(tasks.id)"
                        [nzDisabled]="tasks.disabled">
                    </td>-->
                    <td nzLeft style="word-break: break-word;">{{ modoDemo(tasks?.unidade, 'empresaCodigoNome') }}

                        <span class="badge" *ngIf="exibirColunas.visibilidade_descricao && tasks.visibilidade_descricao"
                              style="background-color: #ffb700;">
                            {{tasks.visibilidade_descricao}}
                        </span>
                        <br>{{ modoDemo(tasks?.cnpj | cpfCnpj, 'cnpj') }}
                        <br>
                        <small *ngIf="tasks.ie && (tasks.orgao === 'E' || tasks.orgao === 'F')">{{ '(IE: ' + tasks.ie + ')' }}</small>
                        <small *ngIf="tasks.im && tasks.orgao === 'M'">{{ '(IM: ' + tasks.im + ')' }}</small>
                        <small *ngIf="tasks.ii && tasks.orgao === 'I'">{{ '(II: ' + tasks.ii + ')' }}</small>
                        <div *ngIf="tasks.descricao">
                            <small *ngIf="tasks.ii_descricao">{{ tasks.ii_descricao }}</small>
                        </div>
                    </td>
                    <td nzAlign="center" style="white-space: nowrap" *ngIf="exibirColunas.grupo_descricao">{{tasks?.grupoEmpresarial_descricao ?? '-'}}</td>
                    <td style="text-align: center;">{{ tasks?.descricao }}
                        <span *ngIf="tasks?.emManutencao" style="margin-left: 10px; color: #faad14"
                              [nz-tooltip]="'Em manutenção'" nz-icon nzType="exclamation-circle"
                              nzTheme="outline"></span></td>
                    <td style="text-align: center;">{{ tasks?.identificador }}</td>
                    <td style="text-align: center;">
                        <div *ngIf="!tasks.processamento_adiado else processamentoAdiado">
                            <i class="fas fa-sync-alt" style="font-size: 16px; color: #1565c0 !important;"
                               *ngIf="tasks.tipoEmissao === 'A' && tasks.certidao_id.length < 36"
                               nz-tooltip="Automáticas"
                               [ngStyle]="tasks.statusUltimaEmissao !== 3 && tasks.error_message && {cursor : 'pointer'}"
                               (click)="tasks.statusUltimaEmissao !== 3 && tasks.error_message &&
                       exibirErro(tasks.error_message, tasks?.certidao_id, tasks.ultimoProcessamento)"></i>
                            <i class="fas fa-times" style="font-size: 16px; color: #f44336;"
                               *ngIf="tasks.tipoEmissao === 'FC' && tasks.certidao_id.length < 36"
                               nz-tooltip="Falha na consulta"
                               [ngStyle]="tasks.error_message && {cursor : 'pointer'}"
                               (click)="tasks.error_message && exibirErro(tasks.error_message,  tasks?.certidao_id, tasks.ultimoProcessamento)"></i>

                            <i class="fa-solid fa-triangle-exclamation" style="font-size: 16px; color: #ffb330;"
                               *ngIf="tasks.tipoEmissao === 'AR'"
                               nz-tooltip="Ação Requerida"
                               [ngStyle]="tasks.error_message && {cursor : 'pointer'}"
                               (click)="tasks.error_message && exibirErro(tasks.error_message,  tasks?.certidao_id, tasks.ultimoProcessamento)"></i>

                            <i class="fa-solid fa-hands-holding" style="font-size: 16px; color: #333333 !important;"
                               *ngIf="tasks.tipoEmissao === 'M' || tasks.certidao_id.length >= 36"
                               nz-tooltip="Manuais"></i>
                        </div>
                        <ng-template #processamentoAdiado>
                            <i class="fas fa-hourglass" style="font-size: 16px; color: #9a9a9a;"
                               nz-tooltip="Processamento adiado"
                               [ngStyle]="{cursor : 'pointer'}"
                               (click)="exibirErro(tasks.error_message,  tasks?.certidao_id, tasks.ultimoProcessamento)"></i>
                        </ng-template>

                    </td>
                    <td style="text-align: center;">
                        <div style="min-width: 180px;">
                            <nz-tag [nzColor]="tasks.statusColor"
                                    [ngStyle]="(tasks.statusUltimaEmissao === 2 || tasks.statusUltimaEmissao === 3)
                                        && (tasks.error_message || tasks?.urlRelatorioSitFiscal && tasks?.certidao_id === 'RFB')
                                        && {cursor : 'pointer'}"
                                    (click)="(tasks.statusUltimaEmissao === 2 || tasks.statusUltimaEmissao === 3)
                                    && (tasks.error_message || tasks?.urlRelatorioSitFiscal)
                                    && exibirErro(tasks.error_message, tasks?.certidao_id, tasks.ultimoProcessamento,
                                     tasks?.urlRelatorioSitFiscal)">
                                {{ tasks.statusDescricao }}
                            </nz-tag>
                        </div>
                    </td>
                    <td style="text-align: center;">
                        {{ tasks?.dataUltimaEmissao | date: 'dd/MM/yyyy HH:mm' }}
                    </td>
                    <td style="text-align: center">

                        <div style="min-width: 95px" *ngIf="tasks.exibirPreRequisito else ComPreRequisitos">
                            <span *ngIf="currentUser.origem === 'console'">{{ tasks?.proximoProcessamento | date: 'dd/MM/yyyy' }}</span>
                            <span
                                    style="margin-left: 10px; color: #ff4d4f; font-size: 1.3em"
                                    [nz-tooltip]="tasks.preRequisitos"
                                    nz-icon
                                    nzType="exclamation-circle"
                                    nzTheme="outline"></span>
                        </div>

                        <ng-template #ComPreRequisitos>
                            <div style="display: inline-block;">
                                {{ tasks?.proximoProcessamento | date: 'dd/MM/yyyy' }}
                                <i *ngIf="tasks.processamentoStatus === 1"
                                   style="color: #1890ff; cursor: pointer; margin-left: 3px; font-size: 1.2em"
                                   class="fa-solid fa-spinner"
                                   [nz-tooltip]="tasks.processamentoDataInicio ?
                                   'Processando. Iniciado em ' + (tasks.processamentoDataInicio | date: 'dd/MM/yyyy HH:mm') : 'Processando'"></i>
                            </div>
                        </ng-template>


                    </td>
                    <td style="text-align: center; white-space: nowrap;">
                        <span *ngIf="tasks?.emissaoUltimaCnd && tasks?.ultimoHistorico_id">{{ tasks?.emissaoUltimaCnd | date: 'dd/MM/yyyy' }}</span>
                        <a nz-button nzType="link"
                           *ngIf="tasks.ultimoHistorico_temArquivo && ((tasks?.emissaoUltimaCnd && !tasks.error_message && tasks?.ultimoHistorico_id) || tasks.exibirCNDValida)"
                           [nzLoading]="loadingsUltimaCertidao[tasks.ultimoHistorico_id]"
                           (click)="verCnd(tasks.ultimoHistorico_id, tasks.descricao, 'ultima')">
                            <i class="fa fa-file-text" aria-hidden="true"></i>
                        </a>
                    </td>
                    <td style="text-align: center;">
                        {{ tasks?.validadeUltimaCnd | date: 'dd/MM/yyyy' }}
                    </td>
                    <td nzAlign="center">
                        <a nz-button nzType="link" (click)="retornaHistorico(tasks.id)">
                            Histórico
                        </a>
                    </td>
                    <td>
                        <a nz-button nzType="link"
                           *ngIf="tasks.statusUltimaEmissao === 3"
                           [nzLoading]="agendamentoFlag[tasks.certidao_id + tasks.empresa_id]"
                           nz-tooltip="Agendar Processamento"
                           (click)="showConfirmAgendar(tasks.certidao_id, tasks.empresa_id)">
                            <i nz-icon nzType="sync" nzTheme="outline"
                               [nzSpin]="agendamentoFlag[tasks.certidao_id + tasks.empresa_id]"></i>
                        </a>
                    </td>
                    <td *ngIf="currentUser?.origem === 'console'" style="white-space: nowrap;">
                        <a nz-button nzType="link"
                           *ngIf="tasks.tipoEmissao !== 'M' && tasks.certidao_id.length < 36"
                           (click)="retornaJson(tasks.processamento_id)"
                           nz-tooltip="Visualizar JSON (user-console)">
                            <i nz-icon nzType="info-circle" nzTheme="outline"></i>
                        </a>
                        <a nz-button nzType="link"
                           *ngIf="tasks.tipoEmissao !== 'M' && tasks.certidao_id.length < 36"
                           [nzLoading]="processamentoFlag[tasks.controleCertidaoEmpresa_id]"
                           nz-tooltip="Reprocessar Registro (user-console)"
                           (click)="showConfirm(tasks.controleCertidaoEmpresa_id)">
                            <i nz-icon nzType="reload" nzTheme="outline"
                               [nzSpin]="processamentoFlag[tasks.controleCertidaoEmpresa_id]"></i>
                        </a>
                    </td>
                </tr>
                </tbody>
                <ng-template #footer>
                <span *ngIf="items && items.length > 0">{{ pagination?.from }}-{{ pagination?.to }}
                    de {{ pagination?.total }} registros</span>
                </ng-template>
            </nz-table>
        </nz-card>
    </nz-content>
    <ng-template #navBar>
        <div class="d-flex align-items-center">
            <button disabled="{{loading}}" nz-button nzType="primary" style="margin-right: 10px;"
                    (click)="modalAdicionar(true)">
                Adicionar
            </button>

            <nz-badge [nzCount]="qtdFiltrosAtivos" style="margin-right: 10px;"
                      [nzStyle]="{ backgroundColor: '#52c41a' }">
                <button disabled="{{loading}}" nz-button nzType="primary" (click)="abrirModal(formFiltrosGrid)">
                    <i nz-icon nzType="search"></i>
                    Filtros
                </button>
            </nz-badge>
            <button disabled="{{loading}}" [nzDropdownMenu]="menuAcoes" style="margin-right: 10px;"
                    class="centralized-icon" nz-button
                    nz-dropdown
                    nzType="default">
                Opções
                <em nz-icon nzType="down"></em>
            </button>
            <nz-dropdown-menu #menuAcoes>
                <ul nz-menu>
                    <li nz-menu-item (click)="modalExportar(true);">Exportar Planilha</li>
                    <li nz-menu-item (click)="exportarPdfs();">Exportar PDFs</li>
                    <li nz-submenu nzTitle="Exibir / Ocultar">
                        <ul>
                            <li nz-menu-item (click)="exibirOcultar('visibilidade_descricao')">
                                    <span nz-icon nzType="check" nzTheme="outline"
                                          style="margin-right: 10px;"

                                          [ngStyle]="!exibirColunas.visibilidade_descricao ? {'visibility' : 'hidden'} : {}"
                                    ></span> Visibilidade
                            </li>
                            <li nz-menu-item (click)="exibirOcultar('grupo_descricao')">
                                    <span nz-icon nzType="check" nzTheme="outline"
                                          style="margin-right: 10px;"

                                          [ngStyle]="!exibirColunas.grupo_descricao ? {'visibility' : 'hidden'} : {}"
                                    ></span> Grupo Empresarial
                            </li>
                        </ul>
                    </li>
                </ul>
            </nz-dropdown-menu>
            <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" style="margin-right: 10px;">
                <input (keydown.enter)="buscar();" type="text" nz-input
                       placeholder="Busca" [(ngModel)]="currentSearch"/>
            </nz-input-group>
            <ng-template #suffixIconButton>
                <button disabled="{{loading}}" nz-button nzType="primary" nzSearch
                        (click)="buscar();"><i
                        nz-icon nzType="search"></i></button>
            </ng-template>
            <button disabled="{{loading}}" nz-button nzType="default" style="margin-right: 10px;"
                    (click)="limparFiltros()">
                Limpar
            </button>
            <button nz-button [nzType]="'default'" (click)="clickEvent('one')"
                    style="padding-left: 8px; padding-right: 8px;">
                <i class="fas" [ngClass]="!statusOne ? 'fa-expand' : 'fa-compress'"></i>
            </button>
        </div>
    </ng-template>

</div>
<nz-modal [(nzVisible)]="formFiltrosCabecalho.modalVisible"
          [nzTitle]="'Filtros'" [nzClosable]="true"
          (nzOnCancel)="fecharModal(formFiltrosCabecalho)"
          [nzWidth]="1100">
    <nz-row *nzModalContent>
        <nz-col [nzSpan]="24">
            <form nz-form [nzNoColon]="false" [formGroup]="formFiltrosCabecalho.formGroup">
                <nz-form-item>
                    <nz-form-label [nzSpan]="4">Visibilidade</nz-form-label>
                    <nz-form-control [nzSpan]="16" nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrosCabecalho.formGroup.value.visibilidade_id">
                        <nz-select nzShowSearch
                                   nzMode="multiple"
                                   nzAllowClear
                                   nzPlaceHolder="Selecione"
                                   formControlName="visibilidade_id">
                            <nz-option *ngFor="let opt of visibilidadesOption"
                                       [nzLabel]="modoDemo(opt.label)"
                                       [nzValue]="opt.value"
                            ></nz-option>

                        </nz-select>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSpan]="4">Grupo Empresarial</nz-form-label>
                    <nz-form-control [nzSpan]="16" nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrosCabecalho.formGroup.value.grupoEmpresarial_id">
                        <nz-select nzShowSearch
                                   nzAllowClear
                                   nzPlaceHolder="Selecione"
                                   formControlName="grupoEmpresarial_id">
                            <nz-option *ngFor="let opt of grupoEmpresarialOptions"
                                       [nzLabel]="modoDemo(opt.label)"
                                       [nzValue]="opt.value"
                            ></nz-option>

                        </nz-select>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSpan]="4">Status Última Emissão</nz-form-label>
                    <nz-form-control [nzSpan]="16" nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrosCabecalho.formGroup.value.statusUltimaEmissao">
                        <nz-select [nzPlaceHolder]="'Selecione'"
                                   nzAllowClear
                                   formControlName="statusUltimaEmissao">
                            <nz-option [nzLabel]="arrayStatus[0]" [nzValue]="'0'"></nz-option>
                            <nz-option [nzLabel]="arrayStatus[1]" [nzValue]="'1'"></nz-option>
                            <nz-option [nzLabel]="arrayStatus[2]" [nzValue]="'2'"></nz-option>
                            <nz-option [nzLabel]="arrayStatus[3]" [nzValue]="'3'"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSpan]="4">Validade última CND</nz-form-label>
                    <nz-form-control [nzSpan]="16" nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrosCabecalho.formGroup.value.validadeUltimaCnd">

                        <nz-select [nzPlaceHolder]="'Selecione'"
                                   nzAllowClear
                                   formControlName="validadeUltimaCnd">
                            <nz-option [nzLabel]="'Superior a 60 Dias'" [nzValue]="'100'"></nz-option>
                            <nz-option [nzLabel]="'Em 60 dias'" [nzValue]="'60'"></nz-option>
                            <nz-option [nzLabel]="'Em 30 dias'" [nzValue]="'30'"></nz-option>
                            <nz-option [nzLabel]="'Em 15 dias'" [nzValue]="'15'"></nz-option>
                            <nz-option [nzLabel]="'Vencida'" [nzValue]="'0'"></nz-option>
                            <nz-option [nzLabel]="'Não Vencida'" [nzValue]="'1'"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="4">Em manutenção</nz-form-label>
                    <nz-form-control [nzSpan]="16" nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrosCabecalho.formGroup.value.emManutencao">

                        <nz-select [nzPlaceHolder]="'Selecione'"
                                   nzAllowClear
                                   formControlName="emManutencao">
                            <nz-option [nzLabel]="'SIM'" [nzValue]="'1'"></nz-option>
                            <nz-option [nzLabel]="'NÃO'" [nzValue]="'0'"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
            </form>
        </nz-col>
    </nz-row>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="fecharModal(formFiltrosCabecalho)">Cancelar</button>
        <button nz-button nzType="primary" (click)="filtrarCabecalho()">Filtrar</button>
    </div>
</nz-modal>
<nz-modal [(nzVisible)]="formFiltrosGrid.modalVisible"
          [nzTitle]="'Filtros avançados'" [nzClosable]="true"
          (nzOnCancel)="fechar(formFiltrosGrid)"
          [nzWidth]="1100">
    <nz-row *nzModalContent>
        <nz-col [nzSpan]="24">
            <form nz-form [nzNoColon]="false" [formGroup]="formFiltrosGrid.formGroup">
                <nz-form-item>
                    <nz-form-label [nzSpan]="4">Código</nz-form-label>
                    <nz-form-control [nzSpan]="16" size="60" nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrosGrid.formGroup.value.empresa_id_codigo">
                        <nz-select nzShowSearch nzAllowClear (ngModelChange)="apagaFiltro('empresa_id')"
                                   nzPlaceHolder="Selecione"
                                   formControlName="empresa_id_codigo">
                            <nz-option *ngFor="let opt of arraySelectEmpresaCodigo"
                                       [nzLabel]="modoDemo(opt.empresa_nome, 'empresaCodigoNome') + ' (' + opt.descricao + ')'"
                                       [nzValue]="opt.id"
                            ></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="4">Unidade</nz-form-label>
                    <nz-form-control [nzSpan]="16" size="60" nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrosGrid.formGroup.value.empresa_id">
                        <nz-select nzShowSearch nzAllowClear
                                   nzPlaceHolder="Selecione"
                                   formControlName="empresa_id"
                                   (ngModelChange)="apagaFiltro('empresa_id_codigo')"
                        >
                            <nz-option *ngFor="let opt of arraySelectEmpresa"
                                       [nzLabel]="modoDemo(opt.descricao, 'empresaCodigoNome') + ' (' + (modoDemo(opt.cnpj | cpfCnpj, 'cnpj')) + ')'"
                                       [nzValue]="opt.id"
                            ></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="4">Certidão</nz-form-label>
                    <nz-form-control [nzSpan]="16" size="60" nzValidateStatus="success"
                                     [nzHasFeedback]="arrayFiltroCertidao.length > 0">
                        <nz-select nzShowSearch nzAllowClear
                                   nzMode="multiple"
                                   nzPlaceHolder="Selecione"
                                   [(ngModel)]="arrayFiltroCertidao"
                                   formControlName="certidao">
                            <nz-option *ngFor="let opt of arraySelectCertidao"
                                       [nzLabel]="opt.descricao"
                                       [nzValue]="opt.id"
                            ></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="4">Tipo de Emissão</nz-form-label>
                    <nz-form-control [nzSpan]="16" nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrosGrid.formGroup.value.tipoEmissao">
                        <nz-select [nzPlaceHolder]="'Selecione'"
                                   nzAllowClear
                                   formControlName="tipoEmissao">
                            <nz-option [nzLabel]="labelEmissoes['A']" [nzValue]="'A'"></nz-option>
                            <nz-option [nzLabel]="labelEmissoes['M']" [nzValue]="'M'"></nz-option>
                            <nz-option [nzLabel]="labelEmissoes['FC']" [nzValue]="'FC'"></nz-option>
                            <nz-option [nzLabel]="labelEmissoes['AR']" [nzValue]="'AR'"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="4">Data Última Consulta</nz-form-label>
                    <nz-form-control [nzSpan]="16" nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrosGrid.formGroup.value.dataUltimaEmissao">
                        <nz-date-picker [nzFormat]="'dd/MM/yyyy'"
                                        formControlName="dataUltimaEmissao" style="width: 100%;"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="4">Última CND Emitida</nz-form-label>
                    <nz-form-control [nzSpan]="16" nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrosGrid.formGroup.value.ultimaCnd">
                        <nz-date-picker [nzFormat]="'dd/MM/yyyy'"
                                        formControlName="ultimaCnd" style="width: 100%;"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="4">Órgão</nz-form-label>
                    <nz-form-control [nzSpan]="16" [nzHasFeedback]="formFiltrosGrid.formGroup.value.orgao">
                        <nz-select [nzPlaceHolder]="'Selecione'"
                                   nzAllowClear
                                   formControlName="orgao">
                            <nz-option [nzLabel]="'Federal'" [nzValue]="'F'"></nz-option>
                            <nz-option [nzLabel]="'Estadual'" [nzValue]="'E'"></nz-option>
                            <nz-option [nzLabel]="'Municipal'" [nzValue]="'M'"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSpan]="4">UF</nz-form-label>
                    <nz-form-control [nzSpan]="16" [nzHasFeedback]="formFiltrosGrid.formGroup.value.uf">
                        <nz-select [nzPlaceHolder]="'Selecione'"
                                   nzAllowClear
                                   formControlName="uf">
                            <nz-option *ngFor="let opt of optionsUF" [nzLabel]="opt.uf + ' - ' + opt.nome"
                                       [nzValue]="opt.uf"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
            </form>
        </nz-col>
    </nz-row>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="fechar(formFiltrosGrid)">Cancelar</button>
        <button nz-button nzType="primary" (click)="filtrar()">Filtrar</button>
    </div>
</nz-modal>

<app-exportar-tabela #componentExport [data]="dataExport"></app-exportar-tabela>

<nz-modal nzTitle="Histórico"
          class="modal-table"
          [(nzVisible)]="displayHistoricoModal"
          (nzOnCancel)="modalHistorico(false)"
          [nzWidth]="900"
          [nzBodyStyle]="{'padding' : '0'}">
    <div *nzModalContent>
        <nz-alert nzType="info" nzShowIcon [nzMessage]="checkedMessage" style=" margin: 15px !important;">
            <ng-template #checkedMessage>
                <div nz-row nzAlign="middle" nzGutter="24">
                    <div nz-col nzSpan="24">
                        <strong class="text-primary"></strong>
                        Atenção: Os arquivos ficam disponíveis para download por até 90 dias. Após esse período eles
                        serão
                        excluídos automaticamente.
                    </div>
                </div>
            </ng-template>
        </nz-alert>
        <nz-table #historyTable
                  [nzData]="arrayTableHistorico"
                  [nzFrontPagination]="false"
                  [nzShowPagination]="false"
                  (nzQueryParams)="retornaHistorico(historyId)"
                  class="fonte-doze table-small"
                  nzLoading="{{loadingHistory}}">
            <thead>
            <tr>
                <th
                        *ngFor="let column of listOfColumn;"
                        [(nzSortOrder)]="column.sortOrder"
                        [nzSortFn]="column.sortFn"
                        [ngStyle]="{'text-align' : column.align}"
                >
                    {{ column.title }}
                </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let history of historyTable.data">
                <td nzAlign="center">{{ history?.tipoEmissao === 'A' ? 'Automática' : (history?.tipoEmissao === 'M' ? 'Manual' : history?.tipoEmissao) }}</td>
                <td nzAlign="center">{{ history?.emissao | date: 'dd/MM/yyyy' }}</td>
                <td nzAlign="center">{{ history?.identificador }}</td>
                <td nzAlign="center">{{ arrayStatus[history?.status] }}</td>
                <td nzAlign="center">{{ history?.created_at | date : 'dd/MM/yyyy HH:mm' }}</td>
                <td nzAlign="center" style="white-space: nowrap">
                    <a nz-button nzType="link" *ngIf="history.tipoEmissao !== 'M'"
                       [nzLoading]="loadingsHistorico[history.id]"
                       [disabled]="history.temArquivo != 1"
                       (click)="history.temArquivo == 1 && verCnd(history.id, history.descricao, 'historico')"
                       [nz-tooltip]="!history.temArquivo ? 'Emissão não gerou arquivo.' : ''"
                    >
                        Ver
                    </a>
                    <a nz-button nzType="link" *ngIf="history.tipoEmissao == 'M'"
                       style="color: #3d3d3d"
                       nz-tooltip="true"
                       nzTooltipTitle="Ver CND"
                       [nzLoading]="loadingsHistorico[history.id]"
                       (click)="verCnd(history.id, history.descricao, 'historico')">
                        <i class="fa-regular fa-eye"></i>
                    </a>
                    <nz-divider nzType="vertical" *ngIf="history.tipoEmissao == 'M'"></nz-divider>
                    <a nz-button nzType="link" *ngIf="history.tipoEmissao == 'M'"
                       nz-tooltip="true"
                       nzTooltipTitle="Editar"
                       [nzLoading]="loadingsHistoricoEditar[history.id]"
                       (click)="editarHistorico(history.id)">
                        <i class="fa-regular fa-pen-to-square"></i>
                    </a>
                    <nz-divider nzType="vertical" *ngIf="history.tipoEmissao == 'M'"></nz-divider>
                    <a nz-button nzType="link" *ngIf="history.tipoEmissao == 'M'"
                       nzDanger
                       nz-tooltip="true"
                       nzTooltipTitle="Remover"
                       [nzLoading]="loadingsHistoricoExcluir[history.id]"
                       (click)="showConfirmExcluirHistorico(history.id)">
                        <i class="far fa-trash-alt"></i>
                    </a>
                </td>
                <td nzAlign="center">
                    <div *ngIf="history.status == 2 || history.status == 3">
                        <button nz-button nzType="link"
                                (click)="modalJustificativa(true, history)">
                        <span *ngIf="history.justificativa else semJustificativa" nz-icon nzType="edit"
                              nzTheme="outline"
                              [nz-tooltip]="'Ver/Alterar justificativa'"></span>
                            <ng-template #semJustificativa>
                                <button nz-button nzType="link" [nz-tooltip]="'Adicionar justificativa'">
                                    <span (click)="modalJustificativa(true, history)" nz-icon nzType="plus-circle"
                                          nzTheme="outline"></span>
                                </button>
                            </ng-template>
                        </button>
                    </div>
                </td>
            </tr>
            </tbody>
        </nz-table>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalHistorico(false)">Fechar</button>
    </div>
</nz-modal>

<nz-modal (nzOnCancel)="handleCancel()"
          [nzBodyStyle]="{'padding' : '0'}"
          [(nzVisible)]="displayModalCnd"
          [nzStyle]="{ top: screenModalTop + 'px' }"
          [nzWidth]="screenWidth"
          nzTitle="Certidão"
          class="modal-table">
    <ng-container *nzModalContent>
        <iframe [ngStyle]="{height: screenHeight + 'px', width: '100%', border: 'none'}"
                [src]="iframeUrl"
                title=""
                *ngIf="isPdf"></iframe>
        <div *ngIf="!isPdf" class="d-flex justify-content-center" style="width: 100%;">
            <div [innerHTML]="conteudoUltimaCnd" style="width: 60%;"></div>
        </div>
    </ng-container>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="handleCancel()">Fechar</button>
        <button nz-button nzType="primary" *ngIf="isPdf" (click)="onClickDownloadPdf()">Download</button>
    </div>
</nz-modal>
<nz-modal [(nzVisible)]="modalJsonFlag"
          [nzTitle]="'Json'" [nzClosable]="true"
          (nzOnCancel)="modalJson(false)"
          [nzWidth]="748">
    <div nz-row *nzModalContent>
        <textarea rows="15" nz-input [(ngModel)]="textAreaValue"></textarea>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalJson(false);">Fechar</button>
    </div>
</nz-modal>
<nz-modal [(nzVisible)]="modalStatusFlag"
          [nzTitle]="modalStatusTitle" [nzClosable]="true"
          (nzOnCancel)="modalStatus(false)"
          [nzWidth]="748">
    <div nz-row *nzModalContent>
        <div [innerHTML]="conteudoStatus" class="d-flex justify-content-center" style="width: 100%;"></div>
        <a *ngIf="urlRelatorio" [href]="urlRelatorio" style="width: 100%; text-align: center; margin-top: 30px;"
           target="_blank">Relatório de Situação Fiscal RFB</a>
    </div>
    <div *nzModalFooter class="d-flex justify-content-between">
        <div>Processado em: {{ ultimoProcessamentoErro | date : 'dd/MM/yyyy HH:mm' }}</div>
        <button nz-button nzType="link" (click)="modalStatus(false);">Fechar</button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formAdicionar.modalVisible"
          [nzTitle]="'Adicionar Certidão'" [nzClosable]="true"
          (nzOnCancel)="modalAdicionar(false)"
          [nzWidth]="648">
    <nz-row *nzModalContent>
        <nz-col [nzSpan]="24">
            <nz-skeleton *ngIf="loadings.comboCertidoesManuais"></nz-skeleton>
            <form nz-form [nzNoColon]="false" [formGroup]="formAdicionar.formGroup"
                  *ngIf="!loadings.comboCertidoesManuais">
                <nz-form-item style="margin-bottom: 0;">
                    <nz-form-label [nzSpan]="4" nzRequired>Certidão</nz-form-label>
                    <nz-form-control [nzSpan]="16"
                                     nzErrorTip="Campo Obrigatório">
                        <nz-select nzShowSearch
                                   nzPlaceHolder="Selecione"
                                   formControlName="certidao_id">
                            <nz-option *ngFor="let opt of optionsCertidoesManuais"
                                       [nzLabel]="opt.descricao"
                                       [nzValue]="opt.id">
                            </nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item style="margin-top: 0; margin-bottom: 15px; text-align: right;">
                    <nz-form-control [nzSpan]="16" [nzOffset]="4" style="text-align: right;">
                        <button class="new-markup-bnt" nz-button nzType="link"
                                (click)="modalNovaCertidao(true)"
                                style="float: right; padding-right: 0">
                            <em nz-icon nzType="plus-circle"></em>Cadastrar Certidão Manual
                        </button>
                    </nz-form-control>
                </nz-form-item>


                <nz-form-item>
                    <nz-form-label [nzSpan]="4" nzRequired>Empresa</nz-form-label>
                    <nz-form-control [nzSpan]="16" *ngIf="loadings.comboEmpresasCndsManuais">
                        <nz-skeleton-element [nzActive]="true" nzType="input" style="width:100%;"></nz-skeleton-element>
                    </nz-form-control>
                    <nz-form-control [nzSpan]="16" *ngIf="!loadings.comboEmpresasCndsManuais"
                                     nzErrorTip="Campo Obrigatório">
                        <nz-select nzShowSearch
                                   nzPlaceHolder="Selecione"
                                   formControlName="empresa_id">
                            <nz-option *ngFor="let opt of optionsEmpresas"
                                       [nzLabel]="opt.nome + ' (' + (opt.cnpj | cpfCnpj) + ')'"
                                       [nzValue]="opt.id"
                                       nzCustomContent>
                                {{ opt.nome }}
                                <br><small>({{ opt.cnpj | cpfCnpj }})</small>
                            </nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSpan]="4" nzRequired>Data Emissão</nz-form-label>
                    <nz-form-control [nzSpan]="16"
                                     nzErrorTip="Campo Obrigatório">
                        <nz-date-picker style="width: 100%;"
                                        nzShowTime
                                        formControlName="emissao" nzFormat="dd/MM/yyyy HH:mm"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSpan]="4">Identificador</nz-form-label>
                    <nz-form-control [nzSpan]="16">
                        <input nz-input formControlName="identificador">
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSpan]="4" nzRequired>Validade</nz-form-label>
                    <nz-form-control [nzSpan]="16"
                                     nzErrorTip="Campo Obrigatório">
                        <nz-date-picker style="width: 100%;"
                                        formControlName="validade" nzFormat="dd/MM/yyyy"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSpan]="4" nzRequired>Status</nz-form-label>
                    <nz-form-control [nzSpan]="16"
                                     nzErrorTip="Campo Obrigatório">
                        <nz-select [nzPlaceHolder]="'Selecione'" formControlName="status">
                            <nz-option [nzLabel]="arrayStatus[1]" [nzValue]="'1'"></nz-option>
                            <nz-option [nzLabel]="arrayStatus[2]" [nzValue]="'2'"></nz-option>
                            <nz-option [nzLabel]="arrayStatus[3]" [nzValue]="'3'"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSpan]="4" nzRequired>Anexo</nz-form-label>
                    <nz-form-control [nzSpan]="16">
                        <p style="margin-top: 5px">Selecione o arquivo (.pdf) para importar.</p>
                        <div style="margin-top: 20px;">
                            <nz-upload
                                    nzType="drag"
                                    [nzAccept]="'.pdf'"
                                    nzAction="{{apiUrl}}/arquivos-transitorios/upload"
                                    [(nzFileList)]="fileListAdicionar" [nzBeforeUpload]="beforeUploadAdicionar">
                                <p class="ant-upload-drag-icon">
                                    <i nz-icon nzType="inbox"></i>
                                </p>
                                <p class="">Clique ou arraste o arquivo para esta área para fazer o upload</p>
                            </nz-upload>
                        </div>
                    </nz-form-control>
                </nz-form-item>
            </form>
        </nz-col>
    </nz-row>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalAdicionar(false)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="loadings.adicionarCndManual" (click)="adicionarCndManual()">
            Adicionar
        </button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formEditarHistorico.modalVisible"
          [nzTitle]="'Editar Histórico da Certidão'" [nzClosable]="true"
          (nzOnCancel)="modalEditarHistorico(false)"
          [nzWidth]="648">
    <nz-row *nzModalContent>
        <nz-col [nzSpan]="24">
            <form nz-form [nzNoColon]="false" [formGroup]="formEditarHistorico.formGroup">
                <nz-form-item>
                    <nz-form-label [nzSpan]="4">Certidão</nz-form-label>
                    <nz-form-control [nzSpan]="16">
                        <nz-form-text>{{ editandoHistorico.certidao_descricao }}</nz-form-text>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="4">Empresa</nz-form-label>
                    <nz-form-control [nzSpan]="16">
                        <nz-form-text>{{ editandoHistorico.empresa_nome }}
                            ({{ editandoHistorico.empresa_cnpj | cpfCnpj }}
                            )
                        </nz-form-text>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSpan]="4" nzRequired>Data Emissão</nz-form-label>
                    <nz-form-control [nzSpan]="16"
                                     nzErrorTip="Campo Obrigatório">
                        <nz-date-picker style="width: 100%;"
                                        nzShowTime
                                        formControlName="emissao" nzFormat="dd/MM/yyyy HH:mm"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSpan]="4">Identificador</nz-form-label>
                    <nz-form-control [nzSpan]="16">
                        <input nz-input formControlName="identificador">
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSpan]="4" nzRequired>Validade</nz-form-label>
                    <nz-form-control [nzSpan]="16"
                                     nzErrorTip="Campo Obrigatório">
                        <nz-date-picker style="width: 100%;"
                                        formControlName="validade" nzFormat="dd/MM/yyyy"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSpan]="4" nzRequired>Status</nz-form-label>
                    <nz-form-control [nzSpan]="16"
                                     nzErrorTip="Campo Obrigatório">
                        <nz-select [nzPlaceHolder]="'Selecione'" formControlName="status">
                            <nz-option [nzLabel]="arrayStatus[1]" [nzValue]="'1'"></nz-option>
                            <nz-option [nzLabel]="arrayStatus[2]" [nzValue]="'2'"></nz-option>
                            <nz-option [nzLabel]="arrayStatus[3]" [nzValue]="'3'"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>

            </form>
        </nz-col>
    </nz-row>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalEditarHistorico(false)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="loadings.editarHistoricoUnico"
                (click)="confirmarEditarHistorico()">Confirmar
        </button>
    </div>
</nz-modal>
<nz-modal [(nzVisible)]="formNovaCertidao.modalVisible"
          [nzTitle]="'Nova Certidão'" [nzClosable]="true"
          (nzOnCancel)="modalNovaCertidao(false);"
          [nzWidth]="648">
    <nz-row *nzModalContent>
        <nz-col nzSpan="24">
            <form nz-form [nzNoColon]="true" [formGroup]="formNovaCertidao.formGroup" style="width: 100%">
                <nz-form-item>
                    <nz-form-label [nzSpan]="8" nzRequired>Descrição</nz-form-label>
                    <nz-form-control [nzSpan]="12"
                                     nzErrorTip="Campo Obrigatório">
                        <input nz-input type="text" formControlName="descricao">
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="8" nzRequired>Órgão</nz-form-label>
                    <nz-form-control [nzSpan]="12" style="margin-top: -10px;"
                                     nzErrorTip="Campo Obrigatório">
                        <nz-radio-group formControlName="orgao" [(ngModel)]="radioValueOrgao"
                                        (ngModelChange)="toggleOrgao($event)">
                            <label nz-radio nzValue="Federal">Federal</label>
                            <label nz-radio nzValue="Estadual">Estadual</label>
                            <label nz-radio nzValue="Municipal">Municipal</label>
                        </nz-radio-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item *ngIf="radioValueOrgao === 'Federal'">
                    <nz-form-label [nzSpan]="8" nzRequired>Matriz</nz-form-label>
                    <nz-form-control [nzSpan]="12"
                                     nzErrorTip="Campo Obrigatório">
                        <nz-radio-group formControlName="somenteMatriz" [(ngModel)]="apenasUnidadesMatriz">
                            <label nz-radio nzValue="1">Sim</label>
                            <label nz-radio nzValue="0">Não</label>
                        </nz-radio-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item *ngIf="radioValueOrgao === 'Estadual'">
                    <nz-form-label [nzSpan]="8" nzRequired>UF</nz-form-label>
                    <nz-form-control [nzSpan]="12"
                                     nzErrorTip="Campo Obrigatório">
                        <nz-select formControlName="uf" nzShowSearch>

                            <nz-option *ngFor="let opt of comboUFs"
                                       [nzLabel]="opt.label"
                                       [nzValue]="opt.key"
                            >
                            </nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item *ngIf="radioValueOrgao === 'Municipal'">
                    <nz-form-label [nzSpan]="8" nzRequired>Código do Município</nz-form-label>
                    <nz-form-control [nzSpan]="12" nzErrorTip="Campo Obrigatório">
                        <nz-select formControlName="municipioCodigo" nzShowSearch>
                            <nz-option *ngFor="let opt of comboTodosMunicipios"
                                       [nzLabel]="opt.label"
                                       [nzValue]="opt.key"
                            >
                            </nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>


            </form>
        </nz-col>
    </nz-row>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalNovaCertidao(false);">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="loadings.novaCertidao" (click)="cadastrarNovaCertidao()">
            Adicionar
        </button>
    </div>
</nz-modal>

<nz-modal (nzOnCancel)="modalJustificativa(false)"
          [nzBodyStyle]="{'padding' : '0'}"
          [(nzVisible)]="formEditarJustificativa.modalVisible"
          [nzStyle]="{ top: screenModalTop + 'px' }"
          [nzWidth]="700"
          nzTitle="Editar Justificativa"
          class="modal-table">
    <ng-container *nzModalContent>
        <form nz-form [nzNoColon]="false" [nzAutoTips]="autoTips" [formGroup]="formEditarJustificativa.formGroup">
            <nz-form-item style="margin-top: 20px">
                <nz-form-label [nzSpan]="4">Justificativa</nz-form-label>
                <nz-form-control [nzSpan]="16">
                    <nz-textarea-count [nzMaxCharacterCount]="500">
                        <textarea style="min-height: 150px; width: 100%;" nz-input rows="10"
                                  formControlName="justificativa"
                                  placeholder="Informe a justificativa."></textarea>
                    </nz-textarea-count>
                </nz-form-control>
            </nz-form-item>
        </form>
    </ng-container>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalJustificativa(false)">Fechar</button>
        <button [nzLoading]="loadings.editandoJustificativa" nz-button nzType="primary" (click)="updateJustificativa()">
            Salvar
        </button>
    </div>
</nz-modal>
