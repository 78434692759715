import {Component, Input, OnInit, AfterViewInit, OnDestroy} from '@angular/core';
import {Form, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {ObrigacoesService} from './obrigacoes.service';
import {ToastrService} from 'ngx-toastr';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import {Pagination} from '@models/pagination.model';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {NzModalService} from 'ng-zorro-antd/modal';
import {buildUrl, findComponentByUrl} from '../../../../../shared/components-helper';
import {Tab} from '@models/tab.model';
import {TabService} from '@services/tab.service';
import {FiltrosService} from '@services/filtros.service';
import {Helpers} from '../../../../../core/helpers';

interface FormStack {
    modalVisible: boolean;
    formGroup: UntypedFormGroup;
}

@Component({
    selector: 'app-empresa-detalhe-tab-obrigacoes',
    templateUrl: './obrigacoes.component.html',
    styleUrls: ['./obrigacoes.component.scss']
})

export class ObrigacoesComponent extends AbstractListTable<any> implements OnInit, AfterViewInit {

    @Input() empresa: { id: string, cnpj: string };

    tabAtiva: 'vencimentoOficial' | 'agenda' = 'vencimentoOficial';
    indexTabAtiva = 0;
    arrayVencimentoOficialCompetencias: any[] = [];

    dados: { vencimentosOficiais: any[], agenda: any[] } = {vencimentosOficiais: [], agenda: []};
    paginations: { vencimentosOficiais: Pagination, agenda: Pagination } = {
        vencimentosOficiais: new Pagination(10, 1, 1, 10),
        agenda: new Pagination()
    };
    arrayAnos: number[] = [];
    anoSelecionado: number = new Date().getFullYear();
    loadings: {
        vencimentoOficial: boolean,
        agenda: boolean,
        cadastrandoVencimentosSugeridos: boolean,
        cadastrandoagendasSugeridas: boolean,
        steps: boolean,
        previaObrigacoes: boolean,
        modalEditarAgenda: boolean,
        editandoAgenda: boolean,
    } = {
        vencimentoOficial: false,
        agenda: false,
        cadastrandoVencimentosSugeridos: false,
        cadastrandoagendasSugeridas: false,
        steps: false,
        previaObrigacoes: false,
        modalEditarAgenda: false,
        editandoAgenda: false
    };

    currentsParams: {
        vencimentosOficiais: NzTableQueryParams,
        agenda: NzTableQueryParams
    } = {
        vencimentosOficiais: {pageIndex: 1, pageSize: 50, sort: [], filter: []},
        agenda: {pageIndex: 1, pageSize: 50, sort: [], filter: []},
    };
    currentsSearches: {
        vencimentosOficiais: string,
        agenda: string
    } = {
        vencimentosOficiais: '',
        agenda: '',
    };

    checkedObrigacoes: { vencimentosOficiais: boolean, agenda: boolean } = {vencimentosOficiais: false, agenda: false};

    checkedItemsObrigacoes: { vencimentosOficiais: any, agenda: any } = {
        vencimentosOficiais: new Set<string>(),
        agenda: new Set<string>()
    };
    qtdFiltrosAtivos: { vencimentosOficiais: number, agenda: number } = {vencimentosOficiais: 0, agenda: 0};
    visibles: {
        modalEditarAgenda: boolean;
        modalFiltrarVencimentosOficiais: boolean,
        modalFiltrarAgenda: boolean,
        modalCadastrarVencimentosMassa: boolean
    } = {
        modalEditarAgenda: false,
        modalFiltrarVencimentosOficiais: false,
        modalFiltrarAgenda: false,
        modalCadastrarVencimentosMassa: false
    };

    forms: {
        vencimentosOficiais: UntypedFormGroup,
        agenda: UntypedFormGroup,
        cadastrarEmMassa: UntypedFormGroup,
        editarAgenda: UntypedFormGroup
    } = {
        vencimentosOficiais: this.fb.group({
            inativo: [null, null]
        }),
        agenda: this.fb.group({
            inativo: [null, null]
        }),
        cadastrarEmMassa: this.fb.group({
            competencia: [null, null],
            empresa_id: [null, null],
            obrigacao_id: [null, null],
        }),
        editarAgenda: this.fb.group({
            equipe_id: [null, null],
            responsavel_id: [null, null]
        })
    };


    steps: { icon: string, step: string }[] = [
        {
            icon: 'calendar',
            step: 'finish',
        },
        {
            icon: 'apartment',
            step: 'process',
        },
        {
            icon: 'branches',
            step: 'wait',
        },
        {
            icon: 'check',
            step: 'finish',
        },
    ];

    stepNumber = 1;
    nextButtonText = 'Avançar';

    opcoesModalEditarAgenda: {
        periodicidade: 'A' | 'M',
        obrigacao: string,
        temPendente: boolean,
        alterarEquipePendentes: boolean,
        alterarResponsavelPendentes: boolean,
    } = {
        periodicidade: 'M',
        obrigacao: '',
        temPendente: false,
        alterarEquipePendentes: false,
        alterarResponsavelPendentes: false,
    };

    opcoesModalCadastrarMassa: {
        modelCalendar: boolean,
        modelCheck: boolean,
        radioQuaisObrigacoes: 'todas' | 'algumas',
        modelCompliance: boolean,
        icons: string[],
        previaObrigacoes: any[],
        paginationObrigcoes: Pagination,
        checkedItemsObrigacoes: any,
        currentParams: NzTableQueryParams
    } = {
        radioQuaisObrigacoes: 'todas',
        modelCalendar: false,
        modelCheck: false,
        modelCompliance: false,
        icons: [
            'calendar',
            'apartment',
            'branches',
            'schedule',
            'check',
        ],
        previaObrigacoes: [],
        paginationObrigcoes: new Pagination(),
        checkedItemsObrigacoes: new Set<string>(),
        currentParams: {
            pageIndex: 1,
            pageSize: 1,
            filter: [],
            sort: []
        }
    };

    selectOptions: {
        equipes: { label: string, value: string }[],
        responsaveis: { label: string, value: string }[],
    } = {
        equipes: [],
        responsaveis: []
    };

    constructor(
        private service: ObrigacoesService,
        private toastService: ToastrService,
        private modalService: NzModalService,
        private fb: UntypedFormBuilder,
        private tabService: TabService,
        private filtrosService: FiltrosService
    ) {
        super(service, {}, toastService);
    }

    async ngOnInit() {

        this.getComboAnos();

    }

    ngAfterViewInit() {

    }

    getVencimentosOficiais(params: NzTableQueryParams, search: string = '') {
        this.currentsParams.vencimentosOficiais = params;
        this.currentsSearches.vencimentosOficiais = search;
        this.loadings.vencimentoOficial = true;

        this.calculaBadgesFiltros();

        const filtros = this.forms.vencimentosOficiais.value;

        for (const [chave, valor] of Object.entries(filtros)) {
            if (valor) {
                this.currentsParams.vencimentosOficiais.filter.push({key: chave, value: valor});
            }
        }

        // tslint:disable-next-line:max-line-length
        this.service.getVencimentosOficiais(this.currentsParams.vencimentosOficiais, search, this.empresa.id, this.anoSelecionado).subscribe((response) => {
            this.loadings.vencimentoOficial = false;
            const vencimentos = Object.entries(response.vencimentos);

            this.arrayVencimentoOficialCompetencias = [];

            vencimentos.forEach((element) => {
                this.arrayVencimentoOficialCompetencias.push({
                    competencia: element[0],
                    index: element[1]
                });
                const competencia = new Date(element[1].toString());
                competencia.setDate(competencia.getDate() + 1);
            });

            response.empresas.data.forEach((element) => {
                element.objectVencimentos = element.vencimentos;
                element.vencimentos = Object.entries(element.vencimentos);
            });

            this.dados.vencimentosOficiais = response.empresas.data;

            this.paginations.vencimentosOficiais = new Pagination(
                response?.per_page,
                response?.current_page,
                response?.last_page,
                response?.total,
                response?.from,
                response?.to);

        }, (res) => {
            this.loadings.vencimentoOficial = false;
            this.toastService.error(res.error.message);
        });

    }

    changeAno(anoEscolhido: number) {
        this.anoSelecionado = anoEscolhido;
        this.queryTable();
    }

    queryTable(): void {
        switch (this.tabAtiva) {
            case 'vencimentoOficial': {
                this.getVencimentosOficiais(this.currentsParams.vencimentosOficiais, this.currentsSearches.vencimentosOficiais);
                break;
            }
            case 'agenda': {
                this.getAgenda(this.currentsParams.agenda, this.currentsSearches.agenda);
                break;
            }
        }
    }

    async getComboAnos() {
        this.service.retornaComboAnos(this.empresa.cnpj).subscribe((retorno: any) => {
            this.arrayAnos = retorno;
        });
    }

    changeTab() {
        if (this.indexTabAtiva === 0) {
            this.tabAtiva = 'vencimentoOficial';
        } else {
            this.tabAtiva = 'agenda';
        }
        this.queryTable();
    }

    onAllChecked(checked: boolean, table: 'vencimentosOficiais' | 'agenda' = 'vencimentosOficiais') {
        this.dados[table].forEach(({id}) => this.updateCheckedSet(id, checked, table));
    }

    updateCheckedSet(id: string, checked: boolean, table: 'vencimentosOficiais' | 'agenda' = 'vencimentosOficiais'): void {

        if (checked) {
            this.checkedItemsObrigacoes[table].add(id);
        } else {
            this.checkedItemsObrigacoes[table].delete(id);
        }
        // tslint:disable-next-line:max-line-length
        this.checkedObrigacoes[table] = this.checkedItemsObrigacoes[table].length === this.checkedItemsObrigacoes[table].size;
    }

    onItemChecked(id, checked: boolean, table: 'vencimentosOficiais' | 'agenda' = 'vencimentosOficiais') {
        this.updateCheckedSet(id, checked, table);
    }

    showConfirmRemoverVencimento(data: any) {
        this.modalService.confirm({
            nzTitle: 'Deseja remover a obrigação?',
            nzOkText: 'Remover',
            nzCancelText: 'Cancelar',
            nzOnOk: () =>
                this.removerVencimentos([data.id])
        });
    }

    showConfirmRemoverAgenda(data: any) {
        this.modalService.confirm({
            nzTitle: 'Deseja remover a obrigação?',
            nzOkText: 'Remover',
            nzCancelText: 'Cancelar',
            nzOnOk: () =>
                this.removerAgenda([data.id])
        });
    }

    removerVencimentos(ids: string[]) {
        this.loadings.vencimentoOficial = true;
        this.service.removerVencimentosOficiais(ids, true).subscribe((response: any) => {
            this.toastrService.success('Vencimentos removidos com sucesso!');
            this.queryTable();
        }, (response) => {
            this.toastrService.error(response.error.message);
        });
    }

    removerAgenda(ids: string[]) {
        this.loadings.agenda = true;
        this.service.removerAgendas(ids, true).subscribe((response: any) => {
            this.toastrService.success('Agendas removidas com sucesso!');
            this.queryTable();
        }, (response) => {
            this.toastrService.error(response.error.message);
        });
    }

    showConfirmRemoverMassaOfficiais() {
        this.modalService.confirm({
            nzTitle: 'Deseja remover as obrigações selecionadas?',
            nzOkText: 'Remover',
            nzCancelText: 'Cancelar',
            nzOnOk: () =>
                this.removerVencimentos(Array.from(this.checkedItemsObrigacoes.vencimentosOficiais))
        });

    }

    showConfirmRemoverMassaAgenda() {
        this.modalService.confirm({
            nzTitle: 'Deseja remover as obrigações selecionadas?',
            nzOkText: 'Remover',
            nzCancelText: 'Cancelar',
            nzOnOk: () =>
                this.removerAgenda(Array.from(this.checkedItemsObrigacoes.agenda))
        });

    }

    modalFiltrarVencimentoOficial(visible: boolean) {
        this.visibles.modalFiltrarVencimentosOficiais = visible;
    }

    modalFiltrarAgenda(visible: boolean) {
        this.visibles.modalFiltrarAgenda = visible;
    }

    confirmaFiltrarVencimentosOficiais() {
        this.currentsParams.vencimentosOficiais.filter = [];
        this.currentsParams.vencimentosOficiais.pageIndex = 1;


        this.queryTable();

        this.modalFiltrarVencimentoOficial(false);
    }

    confirmaFiltrarAgenda() {
        this.currentsParams.agenda.filter = [];
        this.currentsParams.agenda.pageIndex = 1;


        this.queryTable();

        this.modalFiltrarAgenda(false);
    }

    private calculaBadgesFiltros() {
        this.qtdFiltrosAtivos.vencimentosOficiais = 0;
        this.qtdFiltrosAtivos.agenda = 0;
        if (typeof this.forms.vencimentosOficiais.value !== 'undefined') {

            for (const [chave, valor] of Object.entries(this.forms.vencimentosOficiais.value)) {

                if (valor) {
                    this.qtdFiltrosAtivos.vencimentosOficiais++;
                }

            }
        }
        if (typeof this.forms.agenda.value !== 'undefined') {

            for (const [chave, valor] of Object.entries(this.forms.agenda.value)) {

                if (valor) {
                    this.qtdFiltrosAtivos.agenda++;
                }

            }
        }
    }

    btnResetSearchVencimentosOficiais(table: 'vencimentosOficiais' | 'agenda') {
        this.forms[table].reset();
        this.currentsParams[table].filter = [];
        this.currentsSearches[table] = null;

        this.queryTable();
    }

    openTab(componentUrl: string, queryParams?: string, data?: {}) {
        const component = findComponentByUrl(componentUrl);
        const url = buildUrl(component, queryParams);
        const newTab = new Tab(component.name, component.title, url, component.urlType, data);
        this.tabService.closeAndAddTab(newTab);
    }

    modalCadastrarVencimentosSugeridos(visible: boolean) {
        this.visibles.modalCadastrarVencimentosMassa = visible;


    }

    modalCadastrarAgendaSugerida(visible: boolean) {


        this.modalService.confirm({
            nzTitle: 'Deseja adicionar as Obrigações Sugeridas?',
            nzOkText: 'Adicionar',
            nzCancelText: 'Cancelar',
            nzOnOk: () => new Promise(async resolve => {
                await this.adicionarAgedasSugeridas();
                setTimeout(resolve, 300);
            }),
        });


    }

    async adicionarAgedasSugeridas() {
        this.loadings.cadastrandoagendasSugeridas = true;

        const response = await this.service
            // tslint:disable-next-line:no-shadowed-variable
            .adicionarAgedasSugeridas(this.empresa.id, this.anoSelecionado).toPromise().catch((response) => {
                this.toastrService.error(response.error.message);

                this.loadings.cadastrandoagendasSugeridas = false;
            });

        this.loadings.cadastrandoagendasSugeridas = false;

        this.toastrService.success('Empresas adicionadas com sucesso!');

        this.queryTable();
    }

    changeStep(stepNumber, direction) {

        this.stepNumber = stepNumber;

        if (direction === 'next') {

            if (this.stepNumber === 1) {

                if (!this.forms.cadastrarEmMassa.value.modulos || this.forms.cadastrarEmMassa.value.modulos.length === 0) {
                    this.toastService.error('Por favor, selecione ao menos um módulo.');
                    return;
                }

            }

            if (this.stepNumber === 2) {

                this.forms.cadastrarEmMassa.removeControl('obrigacoes');

                if (this.opcoesModalCadastrarMassa.radioQuaisObrigacoes === 'todas') {

                    this.forms.cadastrarEmMassa.addControl('obrigacoes', new UntypedFormControl());
                    this.forms.cadastrarEmMassa.get('obrigacoes').setValue('todas');

                }

                if (this.opcoesModalCadastrarMassa.radioQuaisObrigacoes === 'algumas') {

                    const obrigacoes: any = Array.from(this.opcoesModalCadastrarMassa.checkedItemsObrigacoes);

                    if (obrigacoes.length === 0) {
                        this.toastService.error('Por favor, selecione ao menos uma obrigação.');
                        return;
                    }

                    this.forms.cadastrarEmMassa.addControl('obrigacoes', this.fb.array(obrigacoes));

                }

            }

            this.loadings.steps = true;

            if (this.stepNumber === 2) {
                this.gravarSteps();
            }

        }

        if (direction === 'prev') {

            this.stepNumber--;

            this.steps[this.stepNumber].step = 'wait';
            this.steps[this.stepNumber].icon = this.opcoesModalCadastrarMassa.icons[this.stepNumber];

            this.loadings.steps = false;

            if (this.stepNumber === 1) {

                this.forms.cadastrarEmMassa.value.modulos.forEach((value) => {

                    if (value === 'calendar') {
                        this.opcoesModalCadastrarMassa.modelCalendar = true;
                    }

                    if (value === 'check') {
                        this.opcoesModalCadastrarMassa.modelCheck = true;
                    }

                    if (value === 'compliance') {
                        this.opcoesModalCadastrarMassa.modelCompliance = true;
                    }

                });

                this.nextButtonText = 'Avançar';

            }

        }

        if (this.stepNumber < 2) {

            if (direction === 'next') {

                this.steps[this.stepNumber].step = 'process';
                this.steps[this.stepNumber].icon = 'loading';

                setTimeout(() => {

                    this.steps[this.stepNumber].step = 'finish';
                    this.steps[this.stepNumber].icon = 'check-circle';

                    this.stepNumber++;

                    this.steps[this.stepNumber].step = 'process';

                    this.loadings.steps = false;

                    if (this.stepNumber === 2) {
                        this.nextButtonText = 'Concluir';
                    }

                }, 1000);

            }
        }
    }

    selecionarModulos(value: object[]): void {
        this.forms.cadastrarEmMassa.removeControl('modulos');
        this.forms.cadastrarEmMassa.addControl('modulos', this.fb.array(value));
    }

    onItemCheckedObrigacoes(id: string, checked: boolean): void {

        this.updateCheckedSetObrigacoes(id, checked);

    }

    updateCheckedSetObrigacoes(id: string, checked: boolean): void {

        if (checked) {

            this.opcoesModalCadastrarMassa.checkedItemsObrigacoes.add(id);

        } else {

            this.opcoesModalCadastrarMassa.checkedItemsObrigacoes.delete(id);

        }

        // this.opcoesModalCadastrarMassa.checkedObrigacoes = this.obrigacoes.length === this.checkedItemsObrigacoes.size;
    }

    retornaObrigacoesEmpresasSteps(params: NzTableQueryParams) {

        this.loadings.previaObrigacoes = true;
        this.opcoesModalCadastrarMassa.currentParams = params;

        this.opcoesModalCadastrarMassa.previaObrigacoes = [];

        const dados = {
            modulos: this.forms.cadastrarEmMassa.value.modulos,
            empresas: this.forms.cadastrarEmMassa.value.empresas
        };

        this.service.retornaObrigacoesEmpresas(dados).subscribe((response) => {

            this.opcoesModalCadastrarMassa.previaObrigacoes = response?.data || response;

            this.opcoesModalCadastrarMassa.paginationObrigcoes = new Pagination(
                response?.per_page || 50,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 50,
                response?.from || 1,
                response?.to || 1);

            this.loadings.previaObrigacoes = false;

        });

    }

    gravarSteps(): void {

        this.steps[this.stepNumber].step = 'process';
        this.steps[this.stepNumber].icon = 'loading';

        this.service
            .gravarSteps(this.forms.cadastrarEmMassa.value, this.empresa.id).subscribe((response: any) => {

                this.steps[this.stepNumber].step = 'finish';
                this.steps[this.stepNumber].icon = 'check-circle';

                this.stepNumber++;

                this.steps[this.stepNumber].step = 'process';

                this.loadings.steps = false;

                this.forms.cadastrarEmMassa.reset();

                this.opcoesModalCadastrarMassa = {
                    radioQuaisObrigacoes: 'todas',
                    modelCalendar: false,
                    modelCheck: false,
                    modelCompliance: false,
                    icons: [
                        'calendar',
                        'apartment',
                        'branches',
                        'schedule',
                        'check',
                    ],
                    previaObrigacoes: [],
                    paginationObrigcoes: new Pagination(),
                    checkedItemsObrigacoes: new Set<string>(),
                    currentParams: {
                        pageIndex: 1,
                        pageSize: 1,
                        filter: [],
                        sort: []
                    }
                };

                this.toastService.success('Processo de cadastro iniciado.');

                setTimeout(() => {
                    this.stepNumber = 1;
                    this.visibles.modalCadastrarVencimentosMassa = false;
                    this.steps = [
                        {
                            icon: 'calendar',
                            step: 'finish',
                        },
                        {
                            icon: 'apartment',
                            step: 'process',
                        },
                        {
                            icon: 'branches',
                            step: 'wait',
                        },
                        {
                            icon: 'check',
                            step: 'finish',
                        },
                    ];

                    this.retornaObrigacoesEmpresasSteps(this.opcoesModalCadastrarMassa.currentParams);
                    this.nextButtonText = 'Avançar';
                }, 1000);

            },
            (response) => {
                this.toastrService.error(response.error.message);
                setTimeout(() => {
                    this.steps = [
                        {
                            icon: 'calendar',
                            step: 'process',
                        },
                        {
                            icon: 'apartment',
                            step: 'wait',
                        },
                        {
                            icon: 'branches',
                            step: 'wait',
                        },
                        {
                            icon: 'check',
                            step: 'finish',
                        },
                    ];
                    this.stepNumber = 0;
                    this.nextButtonText = 'Avançar';
                    this.loadings.steps = false;
                    this.modalCadastrarVencimentosSugeridos(false);
                }, 1000);

            }
        );

    }

    getAgenda(params: NzTableQueryParams, search: string = '') {
        this.currentsParams.agenda = params;
        this.currentsSearches.agenda = search;
        this.loadings.agenda = true;

        this.calculaBadgesFiltros();

        const filtros = this.forms.agenda.value;

        for (const [chave, valor] of Object.entries(filtros)) {
            if (valor) {
                this.currentsParams.agenda.filter.push({key: chave, value: valor});
            }
        }

        // tslint:disable-next-line:max-line-length
        this.service.getAgenda(this.currentsParams.agenda, search, this.empresa.id, this.anoSelecionado).subscribe((response) => {
            this.loadings.agenda = false;

            console.log(response);
            this.dados.agenda = response.data;

            this.paginations.agenda = new Pagination(
                response?.per_page,
                response?.current_page,
                response?.last_page,
                response?.total,
                response?.from,
                response?.to);

        }, (res) => {
            this.loadings.agenda = false;
            this.toastService.error(res.error.message);
        });

    }

    async onEditarAgenda(visible: boolean, agenda: any = {}) {


        this.forms.editarAgenda.reset();
        this.visibles.modalEditarAgenda = visible;


        if (visible) {
            this.opcoesModalEditarAgenda = {
                periodicidade: agenda.periodicidade,
                obrigacao: agenda.descricao,
                temPendente: false,
                alterarEquipePendentes: false,
                alterarResponsavelPendentes: false
            };

            this.loadings.modalEditarAgenda = true;

            try {
                const resposta = await this.filtrosService.equipes().toPromise();
                this.selectOptions.equipes = [];
                resposta.equipes.forEach(value => {
                    this.selectOptions.equipes.push({label: value.equipe, value: value.id});
                });
                if (agenda.equipe_id) {
                    this.forms.editarAgenda.get('equipe_id').setValue(agenda.equipe_id);
                }
            } catch (error) {
                this.toastService.error(error.error.message);
                this.visibles.modalEditarAgenda = false;
                this.loadings.modalEditarAgenda = false;
                return;
            }

            try {
                const resposta = await this.filtrosService.responsaveis().toPromise();

                this.selectOptions.responsaveis = [];
                resposta.forEach(value => {
                    this.selectOptions.responsaveis.push({label: value.nome, value: value.id});
                });
                if (agenda.responsavel_id) {
                    this.forms.editarAgenda.get('responsavel_id').setValue(agenda.responsavel_id);
                }
            } catch (error) {
                this.toastService.error(error.error.message);
                this.visibles.modalEditarAgenda = false;
                this.loadings.modalEditarAgenda = false;
                return;
            }


            if (this.opcoesModalEditarAgenda.periodicidade === 'A') {
                if (!agenda.vencimentos[this.anoSelecionado]) {
                    this.toastService.error('Problemas ao identificar vencimento da obrigação anual.');
                    this.visibles.modalEditarAgenda = false;
                    this.loadings.modalEditarAgenda = false;
                }
                await this.forms.editarAgenda.addControl('ano', new UntypedFormControl());
                const data = new Date(agenda.vencimentos[this.anoSelecionado]);
                data.setDate(data.getDate() + 1);
                this.forms.editarAgenda.get('ano').setValue(data);

                this.loadings.modalEditarAgenda = false;
            } else if (this.opcoesModalEditarAgenda.periodicidade === 'M') {


                for (let mes = 1; mes <= 12; mes++) {
                    const stringMes = mes < 10 ? '0' + mes : mes.toString();

                    await this.forms.editarAgenda.addControl(stringMes, new UntypedFormControl());
                    if (agenda.vencimentos[this.anoSelecionado + '-' + stringMes]) {
                        const data = new Date(agenda.vencimentos[this.anoSelecionado + '-' + stringMes]);
                        data.setDate(data.getDate() + 1);
                        this.forms.editarAgenda.get(stringMes).setValue(data);
                    }
                }


                this.loadings.modalEditarAgenda = false;
            }


        }


    }

    async confirmaEditarAgenda() {
        this.loadings.editandoAgenda = true;

        const respostaTemPendente: any = await this.service.temPendente
        (this.anoSelecionado, this.empresa.cnpj, this.opcoesModalEditarAgenda.obrigacao).toPromise();

        this.opcoesModalEditarAgenda.temPendente = respostaTemPendente.temPendente ?? false;

        if (this.opcoesModalEditarAgenda.temPendente) {
            this.modalRealizarAlteracaoEquipeAgenda();
        } else {
            this.finalizarEditarAgenda();
        }


    }

    modalRealizarAlteracaoEquipeAgenda() {
        const modal = this.modalService.create({
            nzTitle: 'Alterar Equipe',
            // tslint:disable-next-line:max-line-length
            nzContent: `Deseja realizar a alteração de equipe das atividades da agenda para obrigações que estão pendentes?`,
            nzFooter: [
                {
                    label: 'Não',
                    type: 'default',
                    onClick: () => {
                        this.opcoesModalEditarAgenda.alterarEquipePendentes = false;
                        modal.destroy();
                        this.modalRealizarAlteracaoResponsavelAgenda();

                    },
                },
                {
                    label: 'Sim',
                    type: 'primary',
                    onClick: () => {
                        this.opcoesModalEditarAgenda.alterarEquipePendentes = true;
                        modal.destroy();
                        this.modalRealizarAlteracaoResponsavelAgenda();

                    }
                },
            ]
        });
    }

    modalRealizarAlteracaoResponsavelAgenda() {
        const modal = this.modalService.create({
            nzTitle: 'Alterar Responsável',
            nzContent: `Deseja realizar a alteração de responsável das atividades da agenda para obrigações que estão pendentes?`,
            nzFooter: [
                {
                    label: 'Não',
                    type: 'default',
                    onClick: () => {
                        this.opcoesModalEditarAgenda.alterarResponsavelPendentes = true;
                        modal.destroy();
                        this.finalizarEditarAgenda();
                    },
                },
                {
                    label: 'Sim',
                    type: 'primary',
                    onClick: () => {
                        this.opcoesModalEditarAgenda.alterarResponsavelPendentes = true;
                        modal.destroy();
                        this.finalizarEditarAgenda();
                    }
                },
            ]
        });
    }

    finalizarEditarAgenda() {


        const vencimentos: { competencia: string, data: string | null }[] = [];
        if (this.opcoesModalEditarAgenda.periodicidade === 'M') {

            for (let mes = 1; mes <= 12; mes++) {
                const stringMes = mes < 10 ? '0' + mes : mes.toString();
                let data: string | null = null;
                if (this.forms.editarAgenda.value[stringMes]) {
                    data = Helpers.formatDateDb(new Date(this.forms.editarAgenda.value[stringMes]).toString());
                }
                vencimentos.push({competencia: this.anoSelecionado + '-' + stringMes, data});
            }
        } else {
            const data = Helpers.formatDateDb(new Date(this.forms.editarAgenda.value.ano).toString());
            vencimentos.push({competencia: this.anoSelecionado.toString(), data});
        }


        const dadosFinais: {
            alterarEquipeObgPendente: boolean,
            alterarResObgPendente: boolean,
            empresa_id: string,
            obrigacao_id: string,
            equipe_id: string,
            responsavel_id: string,
            vencimentos: { competencia: string, data: string | null }[]
        } = {
            alterarEquipeObgPendente: this.opcoesModalEditarAgenda.alterarEquipePendentes,
            alterarResObgPendente: this.opcoesModalEditarAgenda.alterarResponsavelPendentes,
            empresa_id: this.empresa.id,
            obrigacao_id: this.opcoesModalEditarAgenda.obrigacao,
            equipe_id: this.forms.editarAgenda.value.equipe_id,
            responsavel_id: this.forms.editarAgenda.value.responsavel_id,
            vencimentos
        };

        this.service.editarAgenda(dadosFinais).subscribe(res => {
            this.onEditarAgenda(false);
            this.toastService.success('Dados alterados com sucesso.');
            this.queryTable();
            this.loadings.editandoAgenda = false;
        }, (res) => {
            this.loadings.editandoAgenda = false;
            this.toastService.error(res.error.message);
        });


    }

}
