<nz-page-header class="site-page-header" nzTitle="Situacao Fiscal" nzSubtitle="ECAC">

    <nz-page-header-extra>
        <nz-button-group>
            <nz-badge [nzCount]="qtdFiltrosAtivosCabecalho"
                      [nzStyle]="{ backgroundColor: '#52c41a'}"
                      style="margin-right: 10px;">
                <button disabled="{{loading}}" nz-button nzType="primary" nzGhost
                        style="border-radius: 0.45em;"
                        (click)="abrirModal(formFiltrosCabecalho)">
                    <i nz-icon nzType="search"></i>
                    Filtros
                </button>
            </nz-badge>
            <button disabled="{{loading}}" nz-button nzType="primary" nzGhost
                    style="border-radius: 0.45em;"
                    (click)="limparFiltrosCabecalho()">
                Limpar
            </button>
        </nz-button-group>
    </nz-page-header-extra>


</nz-page-header>
<nz-content>
    <nz-row nzGutter="24">
        <div nz-col [nzXs]="24" [nzSm]="12" [nzMd]="8" [nzXl]="8">
            <nz-card [nzLoading]="loadingContadores">
                <nz-content>
                    <div nz-row>
                        <div nz-col [nzSpan]="15"
                             class="d-flex justify-content-center align-items-center">
                            <i class="fa-solid fa-circle-check"
                               style="font-size: 60px; margin-right: 15px; color: rgb(40, 208, 148);"></i>
                            <div style="width: 86.40px;">
                                <h4 class="custom-card-title text-muted" style="margin: 0; line-height: 1;">
                                    Regular
                                </h4>
                                <h1 nz-typography class="custom-card-value"
                                    style="margin: 0; color: rgb(40, 208, 148);">
                                    {{ objectCountersValues.qtdRegulares | number }}
                                </h1>
                            </div>
                        </div>
                        <div nz-col [nzSpan]="9"
                             class="d-flex justify-content-center align-items-center" style="padding-left: 25px;">
                            <nz-progress [nzPercent]="objectCountersValues.porcRegulares | number: '1.0-0'"
                                         nzType="circle"
                                         [nzWidth]="60"
                                         [nzFormat]="formatOne"
                                         [nzStrokeColor]="'#28D094FF'"></nz-progress>
                        </div>
                    </div>
                </nz-content>
            </nz-card>
        </div>
        <div nz-col [nzXs]="24" [nzSm]="12" [nzMd]="8" [nzXl]="8">
            <nz-card [nzLoading]="loadingContadores">
                <nz-content>
                    <div nz-row>
                        <div nz-col [nzSpan]="15"
                             class="d-flex justify-content-center align-items-center">
                            <i class="fa-solid fa-circle-xmark"
                               style="font-size: 60px; margin-right: 15px; color: #ff4961 !important;"></i>
                            <div style="width: 86.40px;">
                                <h4 class="custom-card-title text-muted" style="margin: 0; line-height: 1;">
                                    Irregular
                                </h4>
                                <h1 nz-typography class="custom-card-value"
                                    style="margin: 0; color: #ff4961 !important;">
                                    {{ objectCountersValues.qtdIrregulares | number }}
                                </h1>
                            </div>
                        </div>
                        <div nz-col [nzSpan]="9"
                             class="d-flex justify-content-center align-items-center" style="padding-left: 25px;">
                            <nz-progress [nzPercent]="objectCountersValues.porcIrregulares | number: '1.0-0' "
                                         nzType="circle"
                                         [nzWidth]="60"
                                         [nzFormat]="formatOne"
                                         [nzStrokeColor]="'#ff4961'"></nz-progress>
                        </div>
                    </div>
                </nz-content>
            </nz-card>
        </div>
        <div nz-col [nzXs]="24" [nzSm]="12" [nzMd]="8" [nzXl]="8">
            <nz-card [nzLoading]="loadingContadores">
                <nz-content>
                    <div nz-row>
                        <div nz-col [nzSpan]="15"
                             class="d-flex justify-content-center align-items-center justify-content-center">
                            <i class="fa-solid fa-circle-exclamation"
                               style="font-size: 60px; margin-right: 15px; color: #ff9149 !important;"></i>
                            <div>
                                <h4 class="custom-card-title text-muted"
                                    style="margin: 0; line-height: 1; white-space: nowrap;">
                                    Exigibilidade Suspensa
                                </h4>
                                <h1 nz-typography class="custom-card-value"
                                    style="margin: 0; color: #ff9149 !important;">
                                    {{ objectCountersValues.qtdSuspensa | number }}
                                </h1>
                            </div>
                        </div>
                        <div nz-col [nzSpan]="9"
                             class="d-flex justify-content-center align-items-center" style="padding-left: 25px;">
                            <nz-progress [nzPercent]="objectCountersValues.porcSuspensas | number: '1.0-0'"
                                         nzType="circle"
                                         [nzWidth]="60"
                                         [nzFormat]="formatOne"
                                         [nzStrokeColor]="'#ff9149'"></nz-progress>
                        </div>
                    </div>
                </nz-content>
            </nz-card>
        </div>
    </nz-row>
    <nz-card [ngClass]="[!loading ? 'ant-card-table' : '', expand ? 'card-fullscreen' : '']"
             [nzLoading]="loading"
             [nzBodyStyle]="loading ? {'overflow' : 'hidden', 'padding':'15px'} : {'padding': '5px 0'}"
             [nzExtra]="navBar">
        <ng-template #navBar>
            <div class="d-flex">
                <button *ngIf="currentUser?.origem === 'console'" nz-button nzType="primary" style="margin-right: 10px;"
                        (click)="uploading ? '' : modalImportar(true);"
                        [disabled]="uploading" nz-tooltip="user-console">
                    Upload
                </button>
                <nz-badge [nzCount]="qtdFiltrosAtivos"
                          [nzStyle]="{ backgroundColor: '#52c41a', 'margin-right' : '15px'}">
                    <button nz-button nzType="primary" style="margin-right: 10px;" (click)="modalFiltrar(true);">
                        <i nz-icon nzType="search"></i>
                        Filtros
                    </button>
                </nz-badge>
                <button disabled="{{loading}}" [nzDropdownMenu]="menuAcoes" style="margin-right: 10px;"
                        class="centralized-icon" nz-button nz-dropdown
                        nzType="default">
                    Opções
                    <em nz-icon nzType="down"></em>
                </button>
                <nz-dropdown-menu #menuAcoes>
                    <ul nz-menu>
                        <li [nzDisabled]="loadings.exportar || items.length === 0" nz-menu-item
                            (click)="!loadings.exportar && items.length > 0 ? modalExportar(true) : '';">
                            {{ !loadings.exportar ? 'Exportar Planilha' : 'Exportando planilha...' }}
                        </li>
                        <li [nzDisabled]="loadings.exportarZipPdfs || items.length === 0" nz-menu-item
                            (click)="!loadings.exportarZipPdfs && items.length > 0 ? exportarPdfs() : '';">
                            {{ !loadings.exportarZipPdfs ? 'Exportar PDF' : 'Exportando PDFs...' }}
                        </li>
                        <li nz-submenu nzTitle="Exibir / Ocultar">
                            <ul>
                                <li nz-menu-item (click)="exibirOcultar('visibilidade_descricao')">
                                    <span nz-icon nzType="check" nzTheme="outline"
                                          style="margin-right: 10px;"

                                          [ngStyle]="!exibirColunas.visibilidade_descricao ? {'visibility' : 'hidden'} : {}"
                                    ></span> Visibilidade
                                </li>
                                <li nz-menu-item (click)="exibirOcultar('grupoEmpresarial_descricao')">
                                    <span nz-icon nzType="check" nzTheme="outline"

                                          style="margin-right: 10px;"
                                          [ngStyle]="!exibirColunas.grupoEmpresarial_descricao ? {'visibility' : 'hidden'} : {}"
                                    ></span>Grupo Empresarial
                                </li>

                            </ul>
                        </li>
                    </ul>
                </nz-dropdown-menu>
                <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" style="margin-right: 10px;">
                    <input (keydown.enter)="queryTable(currentParams, currentSearch);" type="text" nz-input
                           placeholder="Busca" [(ngModel)]="currentSearch"/>
                </nz-input-group>
                <ng-template #suffixIconButton>
                    <button nz-button nzType="primary" nzSearch (click)="queryTable(currentParams, currentSearch);"><i
                            nz-icon nzType="search"></i></button>
                </ng-template>
                <button nz-button nzType="default" style="margin-right: 10px;"
                        (click)="btnResetSearch();">
                    Limpar
                </button>
                <button nz-button [nzType]="'default'" (click)="expandEvent()" style="padding: 4px 8px;">
                    <i class="fas" [ngClass]="!expand ? 'fa-expand' : 'fa-compress'"></i>
                </button>
            </div>
        </ng-template>
        <nz-alert nzType="info" nzShowIcon [nzMessage]="checkedMessage" style="margin: 15px !important;"
                  *ngIf="checkedItems.size > 0">
            <ng-template #checkedMessage>
                <div nz-row nzAlign="middle" nzGutter="24">
                    <div nz-col nzSpan="24">
                        <strong class="text-primary"></strong>
                        {{ checkedItems.size }} Registros Selecionados
                    </div>
                </div>
            </ng-template>
        </nz-alert>
        <nz-table #basicTable
                  [nzData]="items"
                  [nzFrontPagination]="false"
                  [nzTotal]="pagination?.total"
                  [nzPageSize]="pagination?.per_page"
                  [nzPageIndex]="pagination?.current_page"
                  (nzQueryParams)="listByTable($event)"
                  [nzFooter]="footer"
                  class="table-small">
            <thead>
            <tr>
                <th nzAlign="center" nzColumnKey="empresa" [nzSortFn]="true">Unidade</th>
                <th nzAlign="center" nzColumnKey="grupoEmpresarial_descricao" [nzSortFn]="true"
                    *ngIf="exibirColunas.grupoEmpresarial_descricao">Grupo Empresarial
                </th>
                <th nzAlign="center" [nzShowSort]="false">Último Relatório Situação Fiscal</th>
                <th nzAlign="center" [nzShowSort]="false">Último Status de Emissão</th>
                <th nzAlign="center" nzColumnKey="processamentoFim" [nzSortFn]="true">Última Emissão</th>
                <th nzAlign="center" nzColumnKey="proximaConsulta" [nzSortFn]="true">Próxima Consulta</th>
                <th colSpan="4" [nzShowSort]="false"></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let ecac of basicTable.data">
                <td>{{ modoDemo(ecac?.nome, 'empresaCodigoNome') }}
                    <span class="badge" *ngIf="exibirColunas.visibilidade_descricao && ecac.visibilidade_descricao"
                          style="background-color: #ffb700;">
                            {{ ecac.visibilidade_descricao }}
                        </span>
                    <br><small>({{ modoDemo(ecac?.cnpj | cpfCnpj, 'cnpj') }})</small></td>

                <td nzAlign="center" *ngIf="exibirColunas.grupoEmpresarial_descricao">
                    {{ ecac.grupoEmpresarial_descricao }}
                </td>

                <td nzAlign="center">
                    <button [nzLoading]="loadingsUltimoRelatorio[ecac.id]"
                            (click)="verPdf(ecac.urlRelatorioSitFiscal, ecac.id, ecac.cnpj)" nzTooltipPlacement="right"
                            nz-button
                            nzType="link" nz-tooltip nzTooltipTitle="PDF">
                        <i class="far fa-file-pdf"></i>
                    </button>
                </td>

                <td nzAlign="center">
                    <nz-tag [nzColor]="ecac.statusColor">
                        {{ ecac.statusDescricao }}
                    </nz-tag>
                </td>
                <td nzAlign="center">{{ ecac?.processamentoFim | date: 'dd/MM/yyyy HH:mm' }}</td>
                <td nzAlign="center">
                         <span *ngIf="ecac.ultimoProcessamentoLog else dataProximaConsulta"
                               style="margin-left: 10px; color: #ff4d4f; font-size: 1.2em"
                               [nz-tooltip]="ecac.ultimoProcessamentoLog || '-'" nz-icon nzType="exclamation-circle"
                               nzTheme="outline"></span>
                    <ng-template #dataProximaConsulta>
                        {{ ecac?.proximaConsulta | date: 'dd/MM/yyyy HH:mm' }}
                    </ng-template>
                </td>
                <td>
                    <div style="padding-left: 10px" *ngIf="[2,3,4].includes(ecac.ultimoRegistroStatus)">
                        <i class="fa-solid fa-check" style="font-size: 16px; color: rgb(40, 208, 148);"
                           *ngIf="ecac.ultimoRegistroStatus === 2"></i>

                        <i class="fa-solid fa-xmark" style="font-size: 16px; color: #ff4961;"
                           nz-tooltip="{{ecac.ultimoProcessamentoLog}}" *ngIf="ecac.ultimoRegistroStatus === 3"></i>

                        <i class="fa-solid fa-triangle-exclamation" style="font-size: 16px; color: #ffb330;"
                           nz-tooltip="{{ecac.ultimoProcessamentoLog}}" *ngIf="ecac.ultimoRegistroStatus === 4"></i>
                    </div>
                </td>
                <td style="white-space: nowrap;">
                    <div *ngIf="true">
                        <button *ngIf="currentUser?.origem === 'console' && ecac.bloqueioMensagem"
                                nz-button nzType="link"
                                [nzLoading]="loadingDesbloqueando[ecac.empresa_id]"
                                nz-tooltip [nzTooltipTitle]="titleTemplate"
                                (click)="desbloquear(ecac.empresa_id)">
                            <i nz-icon nzType="lock" nzTheme="outline"
                               style="color: #ff9149"
                               *ngIf="!loadingDesbloqueando[ecac.empresa_id]"
                               [nzSpin]="processamentoFlag[ecac.empresa_id]"></i>
                        </button>
                        <ng-template #titleTemplate let-thing>
                            <span> {{ ecac.bloqueioMensagem }} </span>
                            <br>
                            <span>CLIQUE PARA DESBLOQUEAR</span>
                        </ng-template>
                    </div>
                </td>
                <td style="white-space: nowrap;">
                    <!--                    Para desabilitar botão-->
                    <div *ngIf="ecac.diasUltimaSolicitacao < 1 else habilitado">
                        <button *ngIf="currentUser?.origem !== 'console' && (ecac.statusDescricao === 'Irregular' || ecac.statusDescricao === 'Exigibilidade Suspensa')"
                                nz-button nzType="link"
                                nzTooltipTitle="Consulta agendada" nz-tooltip>
                            <i nz-icon nzType="reload" nzTheme="outline"
                               style="color: rgba(0, 0, 0, 0.25)"
                               [nzSpin]="processamentoFlag[ecac.empresa_id]"></i>
                        </button>
                        <button *ngIf="currentUser?.origem === 'console' && (ecac.statusDescricao === 'Irregular' || ecac.statusDescricao === 'Exigibilidade Suspensa)')"
                                nz-button nzType="link"
                                nzTooltipTitle="Consulta agendada" nz-tooltip>
                            <i nz-icon nzType="reload" nzTheme="outline"
                               style="color: rgba(0, 0, 0, 0.25)"
                               [nzSpin]="processamentoFlag[ecac.empresa_id]"></i>
                        </button>
                        <button *ngIf="currentUser?.origem === 'console' && ecac.statusDescricao !== 'Irregular' && ecac.statusDescricao !== 'Exigibilidade Suspensa'"
                                nz-button nzType="link">
                            <i nz-icon nzType="reload" nzTheme="outline"
                               style="color: rgba(0, 0, 0, 0.25)"
                               [nzSpin]="processamentoFlag[ecac.empresa_id]"></i>
                        </button>
                    </div>

                    <ng-template #habilitado>
                        <button *ngIf="currentUser?.origem !== 'console' && (ecac.statusDescricao === 'Irregular' || ecac.statusDescricao === 'Exigibilidade Suspensa')"
                                nz-button nzType="link"
                                nzTooltipTitle="Reprocessar Registro" nz-tooltip
                                [nzLoading]="processamentoFlag[ecac.empresa_id]"
                                (click)="showConfirm(ecac)">
                            <i nz-icon nzType="reload" nzTheme="outline"
                               [nzSpin]="processamentoFlag[ecac.empresa_id]"></i>
                        </button>
                        <button *ngIf="currentUser?.origem === 'console'"
                                nz-button nzType="link"
                                nzTooltipTitle="Reprocessar Registro (user-console)" nz-tooltip
                                [nzLoading]="processamentoFlag[ecac.empresa_id]"
                                (click)="showConfirm(ecac)">
                            <i nz-icon nzType="reload" nzTheme="outline"
                               [nzSpin]="processamentoFlag[ecac.empresa_id]"></i>
                        </button>

                    </ng-template>
                </td>
                <td nzAlign="center">
                    <a (click)="getHistorico(ecac.cnpj)">Histórico</a>
                </td>
            </tr>
            </tbody>
        </nz-table>
        <ng-template #footer>
                <span *ngIf="items && items.length > 0">{{ pagination?.from }}-{{ pagination?.to }}
                    de {{ pagination?.total }} registros</span>
        </ng-template>
    </nz-card>
</nz-content>
<nz-modal [(nzVisible)]="formFiltros.modalVisible"
          [nzTitle]="'Filtros Avançados'" [nzClosable]="true"
          (nzOnCancel)="modalFiltrar(false)"
          [nzWidth]="1100">
    <nz-row *nzModalContent>
        <nz-col [nzSpan]="24">
            <form nz-form [nzNoColon]="false" [formGroup]="formFiltros.formGroup">
                <nz-form-item>
                    <nz-form-label [nzSpan]="4">Código</nz-form-label>
                    <nz-form-control [nzSpan]="16" size="60" nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltros.formGroup.value.empresa_id_codigo">
                        <nz-select nzShowSearch nzAllowClear (ngModelChange)="apagaFiltro('empresaNome')"
                                   nzPlaceHolder="Selecione"
                                   formControlName="empresa_id_codigo">
                            <nz-option *ngFor="let opt of arraySelectEmpresaCodigo"
                                       [nzLabel]="modoDemo(opt.empresa_nome, 'empresaCodigoNome') + ' (' + opt.descricao + ')'"
                                       [nzValue]="opt.id"
                            ></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="4">Unidade</nz-form-label>
                    <nz-form-control [nzSpan]="16" size="60" nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltros.formGroup.value.empresaNome">
                        <nz-select nzShowSearch nzAllowClear
                                   nzPlaceHolder="Selecione"
                                   (ngModelChange)="apagaFiltro('empresa_id_codigo')"
                                   formControlName="empresaNome">
                            <nz-option *ngFor="let opt of arraySelectEmpresa"
                                       [nzLabel]="modoDemo(opt.descricao, 'empresaCodigoNome') + ' (' + (modoDemo(opt.empresa_cnpj | cpfCnpj, 'cnpj')) + ')'"
                                       [nzValue]="opt.id"
                            ></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="4">Status de Emissão</nz-form-label>
                    <nz-form-control [nzSpan]="16" nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltros.formGroup.value.statusEmissao">
                        <nz-select [nzPlaceHolder]="'Selecione'"
                                   nzAllowClear
                                   formControlName="statusEmissao">
                            <nz-option [nzLabel]="'Regular'" [nzValue]="'1'"></nz-option>
                            <nz-option [nzLabel]="'Exigibilidade Suspensa'" [nzValue]="'2'"></nz-option>
                            <nz-option [nzLabel]="'Irregular'"
                                       [nzValue]="'3'"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="4">Última Emissão</nz-form-label>
                    <nz-form-control [nzSpan]="16" nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltros.formGroup.value.dataUltimaEmissao">
                        <nz-date-picker [nzFormat]="'dd/MM/yyyy'"
                                        formControlName="dataUltimaEmissao" style="width: 100%;"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="4">Status Consulta</nz-form-label>
                    <nz-form-control [nzSpan]="16" nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltros.formGroup.value.statusProcessamento">
                        <nz-select [nzPlaceHolder]="'Selecione'"
                                   nzAllowClear
                                   formControlName="statusProcessamento">
                            <nz-option [nzLabel]="'Sucesso'" [nzValue]="2"></nz-option>
                            <nz-option [nzLabel]="'Erro'" [nzValue]="3"></nz-option>
                            <nz-option [nzLabel]="'Aviso de procuração'"
                                       [nzValue]="4"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
            </form>
        </nz-col>
    </nz-row>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalFiltrar(false);">Cancelar</button>
        <button nz-button nzType="primary" (click)="filtrar()">Filtrar</button>
    </div>
</nz-modal>

<nz-modal (nzOnCancel)="handleCancel()"
          [nzBodyStyle]="{'padding' : '0'}"
          [(nzVisible)]="displayModal"
          [nzStyle]="{ top: screenModalTop + 'px' }"
          [nzWidth]="screenWidth"
          nzTitle="Certidão"
          class="modal-table">
    <ng-container *nzModalContent>
        <iframe [ngStyle]="{height: screenHeight + 'px'}"
                [src]="iframeUrl" title=""
                style="width: 100%; border: none;"
                *ngIf="isPdf"></iframe>
    </ng-container>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="handleCancel()">Fechar</button>
        <button nz-button nzType="primary" *ngIf="isPdf" (click)="onClickDownloadPdf()">Download</button>
    </div>
</nz-modal>

<nz-modal (nzOnCancel)="showModalHistorico(false)"
          [nzBodyStyle]="{'padding' : '0'}"
          [(nzVisible)]="modalHistoricoVisible"
          [nzWidth]="800"
          nzTitle="Histórico"
          class="modal-table">
    <ng-container *nzModalContent>
        <nz-table #basicTable
                  [nzData]="itemsHistorico"
                  [nzFrontPagination]="false"
                  (nzQueryParams)="getHistoricoParams($event)"
                  [nzLoading]="loadingHistorico"
                  class="table-small"
                  style="overflow: auto">
            <thead>
            <tr>
                <th nzAlign="center" nzColumnKey="emissao" [nzSortFn]="true">Emissão</th>
                <th nzAlign="center" nzColumnKey="status" [nzSortFn]="true">Status</th>
                <th nzAlign="center" nzColumnKey="criadoEm" [nzSortFn]="true">Criado Em</th>
                <th nzAlign="center" nzColumnKey="relatorio">Relatório</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let relatorio of basicTable.data">
                <td nzAlign="center">{{ relatorio?.created_at | date: 'dd/MM/yyyy' }}</td>
                <td nzAlign="center">
                    <nz-tag [nzColor]="relatorio.statusColor">
                        {{ relatorio?.statusDescricao }}
                    </nz-tag>
                </td>
                <td nzAlign="center">{{ relatorio?.created_at | date: 'dd/MM/yyyy HH:mm' }}</td>
                <td nzAlign="center">
                    <button (click)="verPdf(relatorio.urlRelatorioSitFiscal, relatorio.id, relatorio.cnpj)" nz-button
                            nzType="link"
                            nz-tooltip="true" nzTooltipTitle="PDF">
                        Ver
                    </button>
                </td>
            </tr>
            </tbody>
        </nz-table>
    </ng-container>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="showModalHistorico(false)">Fechar</button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="modalImportarVisible"
          [nzTitle]="'Importar Situação Fiscal'" [nzClosable]="true"
          (nzOnCancel)="modalImportar(false)"
          [nzWidth]="648">


    <div *nzModalContent>
        <div>
            <p style="margin: 0px;">Selecione os arquivos (.pdf) para importar.</p>
            <div style="margin-top: 20px;">
                <nz-upload
                        nzType="drag"
                        [nzMultiple]="true"
                        [(nzFileList)]="fileList" [nzBeforeUpload]="beforeUpload">
                    <p class="ant-upload-drag-icon">
                        <i nz-icon nzType="inbox"></i>
                    </p>
                    <p class="ant-upload-text">Clique ou arraste o arquivo para esta área para fazer o upload</p>
                </nz-upload>
            </div>
        </div>
    </div>

    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalImportar(false);">Cancelar</button>
        <button [nzLoading]="uploading" nz-button nzType="primary" (click)="confirmarImportar()">Enviar arquivo</button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formExportar.modalVisible"
          [nzTitle]="'Exportar Planilha'" [nzClosable]="true"
          (nzOnCancel)="modalExportar(false)"
          [nzWidth]="425">
    <div nz-row *nzModalContent>
        <form nz-form [nzNoColon]="true" [nzAutoTips]="autoTips" [formGroup]="formExportar.formGroup"
              style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="24">Selecione o formato do arquivo a ser exportado.</nz-form-label>
                <nz-form-control [nzSpan]="24">
                    <nz-select nzPlaceHolder="Selecione"
                               formControlName="tipo" style="width: 100%;">
                        <nz-option [nzLabel]="'.CSV'" [nzValue]="'csv'"></nz-option>
                        <nz-option [nzLabel]="'.XLSX'" [nzValue]="'xlsx'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalExportar(false);">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="loadings.exportar" (click)="confirmaExportar()">Exportar
        </button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formFiltrosCabecalho.modalVisible"
          [nzTitle]="'Filtros'" [nzClosable]="true"
          (nzOnCancel)="fecharModal(formFiltrosCabecalho)" [nzWidth]="750">
    <nz-row *nzModalContent>
        <nz-col [nzSpan]="24">
            <form nz-form [nzNoColon]="false" [formGroup]="formFiltrosCabecalho.formGroup">
                <nz-form-item>
                    <nz-form-label [nzSpan]="6">Grupo Empresarial</nz-form-label>
                    <nz-form-control [nzSpan]="12" nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrosCabecalho.formGroup.value.grupoEmpresarial_id">
                        <nz-select nzShowSearch
                                   nzAllowClear
                                   nzPlaceHolder="Selecione"
                                   formControlName="grupoEmpresarial_id">
                            <nz-option *ngFor="let opt of grupoEmpresarialOptions"
                                       [nzLabel]="modoDemo(opt.label)"
                                       [nzValue]="opt.value"
                            ></nz-option>

                        </nz-select>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSpan]="6">Visibilidade</nz-form-label>
                    <nz-form-control [nzSpan]="12" nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltrosCabecalho.formGroup.value.visibilidade_id">
                        <nz-select nzShowSearch
                                   [nzMode]="'multiple'"
                                   nzAllowClear
                                   nzPlaceHolder="Selecione"
                                   formControlName="visibilidade_id">
                            <nz-option *ngFor="let opt of visibilidadesOptions"
                                       [nzLabel]="opt.label"
                                       [nzValue]="opt.value"
                            ></nz-option>

                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
            </form>
        </nz-col>
    </nz-row>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="fecharModal(formFiltrosCabecalho)">Cancelar</button>
        <button nz-button nzType="primary" (click)="filtrarCabecalho()">Filtrar</button>
    </div>
</nz-modal>
