<nz-page-header class="site-page-header" nzTitle="Cadastros" nzSubtitle="Detalhes Obrigações"
                xmlns="http://www.w3.org/1999/html"></nz-page-header>
<nz-content>
    <div nz-row [nzGutter]="24">
        <div nz-col nzXs="24" nzSm="24" nzMd="6" nzLg="6" nzXl="6">
            <nz-affix [nzOffsetTop]="offsetTop" [nzTarget]="target">
                <nz-card class="ant-card-meta-reduce">
                    <nz-card-meta [nzTitle]="obrigacao.descricao"
                                  [nzDescription]="obrigacao.id ? 'ID: ' + obrigacao.id : ''"></nz-card-meta>
                    <nz-divider></nz-divider>
                    <nz-list>
                        <nz-list-item>
                            <nz-list-item-meta nzDescription="Criado em">
                                <nz-list-item-meta-title>
                                    {{obrigacao.created_at ? (obrigacao.created_at | date:'dd/MM/YY - HH:mm:ss') : '-' }}
                                    <small *ngIf="obrigacao.criado_por"><br>Por <b>{{obrigacao.criado_por}}</b></small>
                                </nz-list-item-meta-title>
                            </nz-list-item-meta>
                        </nz-list-item>
                    </nz-list>
                    <nz-list>
                        <nz-list-item>
                            <nz-list-item-meta nzDescription="Última alteração">
                                <nz-list-item-meta-title>
                                    {{obrigacao.updated_at ? (obrigacao.updated_at | date:'dd/MM/YY - HH:mm:ss') : '-' }}
                                    <small *ngIf="obrigacao.atualizado_por"><br>Por <b>{{obrigacao.atualizado_por}}</b></small>
                                </nz-list-item-meta-title>
                            </nz-list-item-meta>
                        </nz-list-item>
                    </nz-list>
                </nz-card>
                <nz-anchor class="transparent-anchor" [nzAffix]="false" [nzShowInkInFixed]="true"
                           [nzContainer]="target">
                    <nz-link nzHref="#dadosCadastrais" nzTitle="Dados Cadastrais"></nz-link>
                    <nz-link nzHref="#vencimentos" nzTitle="Vencimentos"></nz-link>
                    <nz-link nzHref="#sistema" nzTitle="Sistema"></nz-link>
                </nz-anchor>
            </nz-affix>
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="18" nzLg="18" nzXl="18">
            <nz-card id="dadosCadastrais" nzTitle="Dados Cadastrais" [nzExtra]="extraInfCadastrais">
                <nz-list>
                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Descrição">
                            <nz-list-item-meta-title>
                                {{obrigacao.descricao}}
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                    </nz-list-item>
                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Tipo">
                            <nz-list-item-meta-title>
                                {{obrigacao.tipo_descricao}}
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                    </nz-list-item>
                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Periodicidade">
                            <nz-list-item-meta-title>
                                {{obrigacao.periodicidade ? (obrigacao.periodicidade === 'A' ? 'Anual' : 'Mensal') : '-'}}
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                    </nz-list-item>
                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Orgão">
                            <nz-list-item-meta-title>
                                {{obrigacao.orgao}}
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                    </nz-list-item>
                </nz-list>
                <!--<nz-divider *ngIf="obrigacao.orgao === 'Estadual'" nzText="Estadual" nzOrientation="left"></nz-divider>-->
                <nz-list *ngIf="obrigacao.orgao === 'Estadual'">
                    <nz-list-item>
                        <nz-list-item-meta nzDescription="UF">
                            <nz-list-item-meta-title>
                                {{obrigacao.uf}} - {{obrigacao.estado}}
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                    </nz-list-item>
                </nz-list>
                <!--<nz-divider *ngIf="obrigacao.orgao === 'Municipal'" nzText="Municipal" nzOrientation="left"></nz-divider>-->
                <nz-list *ngIf="obrigacao.orgao === 'Municipal'">
                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Município">
                            <nz-list-item-meta-title>
                                {{obrigacao.municipioCodigo + ' - ' + obrigacao.municipioNome}}{{obrigacao.uf ? ', ' + obrigacao.uf : ''}}
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                    </nz-list-item>
                </nz-list>
                <!--<nz-divider *ngIf="obrigacao.orgao === 'Federal'" nzText="Federal" nzOrientation="left"></nz-divider>-->
                <nz-list *ngIf="obrigacao.orgao === 'Federal' || radioValueOrgao === 'Previdenciário'">
                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Apenas Unidades Matriz">
                            <nz-list-item-meta-title>
                                {{obrigacao.somenteMatriz ? 'Sim' : 'Não'}}
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                    </nz-list-item>
                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Regimes Tibutários">
                            <nz-list-item-meta-title>
                                {{obrigacao.regimesTricutarios}}
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                    </nz-list-item>
                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Atividades">
                            <nz-list-item-meta-title>
                                {{obrigacao.atividades}}
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                    </nz-list-item>
                </nz-list>
            </nz-card>
            <ng-template #extraInfCadastrais>
                <button nz-button nzType="primary" (click)="editar(formDadosCadastrais)"
                        [nz-tooltip]="usuarioLogado?.origem == 'conta' && obrigacao.bloqueado"
                        [nzTooltipTitle]="usuarioLogado?.origem == 'conta' && obrigacao.bloqueado ? 'Obrigação Bloqueada' : ''"
                        [disabled]="usuarioLogado?.origem == 'conta' && obrigacao.bloqueado">Alterar
                </button>
            </ng-template>
            <nz-card id="vencimentos" class="ant-card-table" nzTitle="Vencimentos" [nzExtra]="st1Extra"
                     [nzLoading]="loadingVencimentos">
                <ng-template #st1Extra>
                    <button nz-button nzType="primary" style="margin-right: 20px;"
                            (click)="showConfirmSugerirVencimento()">
                        Cadastrar
                    </button>

                    <button nz-button nzType="primary" nz-dropdown [nzDropdownMenu]="menu">
                        {{selectedValue}}<i nz-icon nzType="down"></i>
                    </button>
                    <nz-dropdown-menu #menu="nzDropdownMenu">
                        <ul nz-menu>
                            <li nz-menu-item *ngFor="let item of arrayAnos" (click)="alterarAnoVencimentos(item)">
                                <a>{{item}}</a>
                            </li>
                        </ul>
                    </nz-dropdown-menu>
                </ng-template>
                <nz-table #tableVendimentos
                          nzSize="small"
                          [nzData]="vencimentos"
                          [nzFrontPagination]="false"
                          [nzLoading]="loading"
                          [nzTotal]="pagination?.total"
                          [nzPageSize]="pagination?.per_page"
                          [nzPageIndex]="pagination?.current_page"
                          (nzQueryParams)="listarVencimentos($event)"
                          style="padding-left: 2%;padding-right: 2%;"
                >
                    <thead>
                    <tr>
                        <th nzColumnKey="competencia" [nzSortFn]="true">Competencia</th>
                        <th nzColumnKey="vencimento" [nzSortFn]="true">Vencimento</th>
                        <!--<th nzColumnKey="agendaConfirmavel" [nzSortFn]="true">Agenda
                            Confirmável
                        </th>-->
                        <th style="text-align: center;" [nzSortFn]="false" nzSortOrder="false"></th>

                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let item of tableVendimentos.data">
                        <td style="">{{item.competencia?.length === 7 ? (item.competencia | date: 'MM/yyyy') : item.competencia}}</td>
                        <td style="">{{item.vencimento | date: 'dd/MM/yyyy'}}</td>
                        <!--<td style="">{{item.agendaConfirmavel ? 'Sim' : 'Não'}}</td>-->
                        <td style="text-align: right;">
                            <button nz-button nzType="default" style="margin-right: 20px;"
                                    (click)="modalVencimento(item.id, item.competencia, item.vencimento, item.agendaConfirmavel)">
                                Alterar
                            </button>
                            <!--                            Falta Endpoint-->
                            <button nz-button nzType="default"
                                    (click)="removerVencimento(item.id);">
                                Remover
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </nz-table>
            </nz-card>
            <nz-card id="sistema" nzTitle="Sistema">
                <nz-list>
                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Disponível para Agenda">
                            <nz-list-item-meta-title>
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                        <ul nz-list-item-actions>
                            <nz-list-item-action>
                                <nz-switch [(ngModel)]="obrigacao.temAgenda"
                                           nzCheckedChildren="Sim" nzUnCheckedChildren="Não"
                                           (ngModelChange)="alterarSistema('temAgenda')"
                                           [nzLoading]="gravando"
                                ></nz-switch>
                            </nz-list-item-action>
                        </ul>
                    </nz-list-item>
                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Agenda Confirmável">
                            <nz-list-item-meta-title>
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                        <ul nz-list-item-actions>
                            <nz-list-item-action>
                                <nz-select [disabled]="!obrigacao.temAgenda" style="width: 110px" [(ngModel)]="obrigacao.agendaConfirmavel"
                                           (ngModelChange)="alterarSistema('agendaConfirmavel')"
                                           [nzLoading]="gravando">
                                    <nz-option [nzValue]="0" [nzLabel]="'Nenhuma'"></nz-option>
                                    <nz-option [nzValue]="1" [nzLabel]="'Automático'"></nz-option>
                                    <nz-option [nzValue]="2" [nzLabel]="'Manual'"></nz-option>

                                </nz-select>
                            </nz-list-item-action>
                        </ul>
                    </nz-list-item>
                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Disponível para Compliance">
                            <nz-list-item-meta-title>
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                        <ul nz-list-item-actions>
                            <nz-list-item-action>
                                <nz-switch [(ngModel)]="obrigacao.temCompliance"
                                           nzCheckedChildren="Sim" nzUnCheckedChildren="Não"

                                           [disabled]="!obrigacao.obrigacaoCompliance"
                                           (ngModelChange)="alterarSistema('temCompliance')"
                                           [nzLoading]="gravando"
                                ></nz-switch>
                            </nz-list-item-action>
                        </ul>
                    </nz-list-item>

                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Disponível para Pré-Validação">
                            <nz-list-item-meta-title>
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                        <ul nz-list-item-actions>
                            <nz-list-item-action>
                                <nz-switch [(ngModel)]="obrigacao.temValidacao"
                                           nzCheckedChildren="Sim" nzUnCheckedChildren="Não"
                                           (ngModelChange)="alterarSistema('temValidacao')"
                                           [nzLoading]="gravando" disabled
                                ></nz-switch>
                            </nz-list-item-action>
                        </ul>
                    </nz-list-item>
                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Disponível para Aprovação">
                            <nz-list-item-meta-title>
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                        <ul nz-list-item-actions>
                            <nz-list-item-action>
                                <nz-switch [(ngModel)]="obrigacao.temAprovacao"
                                           nzCheckedChildren="Sim" nzUnCheckedChildren="Não"
                                           (ngModelChange)="alterarSistema('temAprovacao')"
                                           [nzLoading]="gravando"
                                ></nz-switch>
                            </nz-list-item-action>
                        </ul>
                    </nz-list-item>
                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Disponível para Transmissão">
                            <nz-list-item-meta-title>
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                        <ul nz-list-item-actions>
                            <nz-list-item-action>
                                <nz-switch [(ngModel)]="obrigacao.temTransmissao"
                                           nzCheckedChildren="Sim" nzUnCheckedChildren="Não"
                                           (ngModelChange)="alterarSistema('temTransmissao')"
                                           [nzLoading]="gravando" disabled
                                ></nz-switch>
                            </nz-list-item-action>
                        </ul>
                    </nz-list-item>

                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Prazo de Expiração (em dias)">
                            <nz-list-item-meta-title>
                                {{obrigacao.prazoExpiracaoConsulta}} dias
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                        <ul nz-list-item-actions>
                            <nz-list-item-action>
                                <button nz-button nzType="primary"
                                        [disabled]="obrigacao?.id?.length >= 36"
                                        [nz-tooltip]="obrigacao?.id?.length >= 36 ? 'Não é permitido realizar a alteração para Obrigações manuais' : ''"
                                        (click)="editar(formPrazo)">Alterar</button>
                            </nz-list-item-action>
                        </ul>
                    </nz-list-item>

                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Bloqueado">
                            <nz-list-item-meta-title>
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                        <ul nz-list-item-actions>
                            <nz-list-item-action>
                                <nz-switch [(ngModel)]="obrigacao.bloqueado"
                                           nzCheckedChildren="Sim" nzUnCheckedChildren="Não"
                                           (ngModelChange)="alterarSistema('bloqueado')"
                                           [nzLoading]="gravando" disabled
                                ></nz-switch>
                            </nz-list-item-action>
                        </ul>
                    </nz-list-item>

                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Em manutenção">
                            <nz-list-item-meta-title>
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                        <ul nz-list-item-actions>
                            <nz-list-item-action>
                                <nz-switch [(ngModel)]="obrigacao.emManutencao"
                                           nzCheckedChildren="Sim" nzUnCheckedChildren="Não"
                                           (ngModelChange)="alterarSistema('emManutencao')"
                                           [nzLoading]="gravando" disabled
                                ></nz-switch>
                            </nz-list-item-action>
                        </ul>
                    </nz-list-item>

                </nz-list>
            </nz-card>
        </div>
    </div>
</nz-content>
<nz-modal [(nzVisible)]="formDadosCadastrais.modalVisible"
          [nzTitle]="'Alterar Dados'" [nzClosable]="true"
          (nzOnCancel)="fechar(formDadosCadastrais)"
          [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzAutoTips]="autoTips" [nzNoColon]="true" [formGroup]="formDadosCadastrais.formGroup"
              style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="8" nzRequired>Descrição</nz-form-label>
                <nz-form-control [nzSpan]="10">
                    <input nz-input type="text" formControlName="descricao" maxlength="60"
                           ngModel="{{obrigacao.descricao}}"/>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="8" nzRequired>Tipo</nz-form-label>
                <nz-form-control [nzSpan]="12">
                    <nz-radio-group formControlName="tipo" ngModel="{{obrigacao.tipo}}">
                        <label nz-radio nzValue="P">Principal</label>
                        <label nz-radio nzValue="A">Acessória</label>
                    </nz-radio-group>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="8" nzRequired>Periodicidade</nz-form-label>
                <nz-form-control [nzSpan]="12">
                    <nz-radio-group formControlName="periodicidade"
                                    ngModel="{{obrigacao.periodicidade ? obrigacao.periodicidade : 'A'}}">
                        <label nz-radio nzValue="A">Anual</label>
                        <label nz-radio nzValue="M">Mensal</label>
                    </nz-radio-group>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="8">Dia Vencimento</nz-form-label>
                <nz-form-control [nzSpan]="10">
                    <input type="number" min="1" max="31" maxlength="2" [(ngModel)]="obrigacao.vencimentoDia"
                           nz-input formControlName="vencimentoDia" (keydown)="keyPressNumber($event)"/>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="8" nzRequired>Útil</nz-form-label>
                <nz-form-control [nzSpan]="12">
                    <nz-radio-group formControlName="vencimentoUtil"
                                    ngModel="{{obrigacao.vencimentoUtil === 1 ? '1' : obrigacao.vencimentoUtil === 0 ? '0' : null}}">
                        <label nz-radio nzValue="1">Sim</label>
                        <label nz-radio nzValue="0">Não</label>
                    </nz-radio-group>
                </nz-form-control>
            </nz-form-item>

            <div *ngIf="formDadosCadastrais.formGroup.get('periodicidade').value === 'A' else periodicidadeM">
                <nz-form-item>
                    <nz-form-label nzRequired [nzSpan]="8">Mês Vencimento</nz-form-label>
                    <nz-form-control [nzSpan]="10">
                        <nz-date-picker style="width: 100%" formControlName="vencimentoMes" nzMode="month"
                                        [nzPlaceHolder]="'Selecione o mês'"
                                        [(ngModel)]="obrigacao.vencimentoMes"
                                        [nzFormat]="'MM'"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
            </div>

            <ng-template #periodicidadeM>

                <nz-form-item *ngIf="formDadosCadastrais.formGroup.get('vencimentoUtil').value == 0">
                    <nz-form-label [nzSpan]="8" nzRequired></nz-form-label>
                    <nz-form-control [nzSpan]="12">
                        <nz-radio-group formControlName="vencimentoModalidade"
                                        ngModel="{{obrigacao.vencimentoModalidade === 'Antecipa' ? 'Antecipa' : obrigacao.vencimentoModalidade === 'Posterga' ? 'Posterga' : null}}">
                            <label nz-radio nzValue="Antecipa">Antecipa</label>
                            <label nz-radio nzValue="Posterga">Posterga</label>
                        </nz-radio-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSpan]="8" nzRequired>Mês</nz-form-label>
                    <nz-form-control [nzSpan]="12">
                        <nz-radio-group formControlName="vencimentoMesSubsequente"
                                        ngModel="{{obrigacao.vencimentoMesSubsequente == 1 ? '1' : obrigacao.vencimentoMesSubsequente == 2 ? '2' : null}}"
                        >
                            <label nz-radio nzValue="1">1º Subsequente</label>
                            <label nz-radio nzValue="2">2º Subsequente</label>
                        </nz-radio-group>
                    </nz-form-control>
                </nz-form-item>

            </ng-template>

            <nz-form-item>
                <nz-form-label [nzSpan]="8" nzRequired>Orgão</nz-form-label>
                <nz-form-control [nzSpan]="12">
                    <nz-radio-group formControlName="orgao" ngModel="{{obrigacao.orgao}}"
                                    (ngModelChange)="toggleOrgao($event)">
                        <label nz-radio nzValue="Federal">Federal</label>
                        <label nz-radio nzValue="Estadual">Estadual</label>
                        <label nz-radio nzValue="Municipal">Municipal</label>
                        <label nz-radio nzValue="Previdenciário">Previdenciário</label>
                    </nz-radio-group>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item *ngIf="radioValueOrgao === 'Estadual'">
                <nz-form-label [nzSpan]="8" nzRequired>UF</nz-form-label>
                <nz-form-control [nzSpan]="12">
                    <nz-select formControlName="uf" ngModel="{{obrigacao.uf}}">
                        <nz-option *ngFor="let uf of comboUFs"
                                   [nzLabel]="uf.label" [nzValue]="uf.key"></nz-option>

                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item *ngIf="radioValueOrgao === 'Municipal'">
                <nz-form-label [nzSpan]="8" nzRequired>UF</nz-form-label>
                <nz-form-control [nzSpan]="12">
                    <nz-select formControlName="uf"
                               (ngModelChange)="filtraComboMunicipio(formDadosCadastrais.formGroup.value.uf)"
                               ngModel="{{obrigacao.uf}}">
                        <nz-option *ngFor="let uf of comboUFs"
                                   [nzLabel]="uf.label" [nzValue]="uf.key"></nz-option>

                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item *ngIf="radioValueOrgao === 'Municipal'">
                <nz-form-label [nzSpan]="8" nzRequired>Código do município</nz-form-label>
                <nz-form-control [nzSpan]="12">
                    <nz-select formControlName="municipioCodigo"
                               ngModel="{{obrigacao.municipioCodigo}}">
                        <nz-option *ngFor="let municipio of comboMunicipio"
                                   [nzLabel]="municipio.label" [nzValue]="municipio.key"></nz-option>

                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item *ngIf="radioValueOrgao === 'Federal'|| radioValueOrgao === 'Previdenciário'">
                <nz-form-label [nzSpan]="8" nzRequired>Apenas Unidades Matriz</nz-form-label>
                <nz-form-control [nzSpan]="12">
                    <nz-radio-group formControlName="somenteMatriz" [(ngModel)]="checkedSomenteMatriz">
                        <label nz-radio nzValue="1">Sim</label>
                        <label nz-radio nzValue="0">Não</label>
                    </nz-radio-group>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item *ngIf="radioValueOrgao === 'Federal'|| radioValueOrgao === 'Previdenciário'"
                          style="margin-bottom: 10px;">
                <nz-form-label [nzSpan]="8">Regimes Tibutários</nz-form-label>
                <nz-form-control [nzSpan]="12">
                    <label nz-checkbox formControlName="regimeLP" [(ngModel)]="checkedLucroPresumido">LP - Lucro
                        Presumido</label>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item *ngIf="radioValueOrgao === 'Federal'|| radioValueOrgao === 'Previdenciário'"
                          style="margin-bottom: 10px;">
                <div nz-col [nzSpan]="8"></div>
                <nz-form-control [nzSpan]="12">
                    <label nz-checkbox formControlName="regimeLRT" [(ngModel)]="checkedLucroRealTrimestral">LRT
                        - Lucro Real
                        Trimestral</label>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item *ngIf="radioValueOrgao === 'Federal'|| radioValueOrgao === 'Previdenciário'"
                          style="margin-bottom: 10px;">
                <div nz-col [nzSpan]="8"></div>
                <nz-form-control [nzSpan]="12">
                    <label nz-checkbox formControlName="regimeLRA" [(ngModel)]="checkedLucroRealAnual">LRA - Lucro
                        Real Anual</label>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item *ngIf="radioValueOrgao === 'Federal'|| radioValueOrgao === 'Previdenciário'">
                <div nz-col [nzSpan]="8"></div>
                <nz-form-control [nzSpan]="12">
                    <label nz-checkbox formControlName="regimeSN" [(ngModel)]="checkedSimplesNacional">SN -
                        Simples Nacional</label>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item *ngIf="radioValueOrgao === 'Federal'|| radioValueOrgao === 'Previdenciário'"
                          style="margin-bottom: 10px;">
                <nz-form-label [nzSpan]="8" nzRequired>Atividades</nz-form-label>
                <nz-form-control [nzSpan]="12">
                    <label nz-checkbox formControlName="atividadeIndustria" [(ngModel)]="checkedIndustria">I -
                        Industria</label>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item *ngIf="radioValueOrgao === 'Federal' || radioValueOrgao === 'Previdenciário'"
                          style="margin-bottom: 10px;">
                <div nz-col [nzSpan]="8"></div>
                <nz-form-control [nzSpan]="12">
                    <label nz-checkbox formControlName="atividadeAtacado" [(ngModel)]="checkedAtacado">A -
                        Atacado</label>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item *ngIf="radioValueOrgao === 'Federal'|| radioValueOrgao === 'Previdenciário'"
                          style="margin-bottom: 10px;">
                <div nz-col [nzSpan]="8"></div>
                <nz-form-control [nzSpan]="12">
                    <label nz-checkbox formControlName="atividadeVarejo" [(ngModel)]="checkedVarejo">V - Varejo</label>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item *ngIf="radioValueOrgao === 'Federal'|| radioValueOrgao === 'Previdenciário'"
                          style="margin-bottom: 10px;">
                <div nz-col [nzSpan]="8"></div>
                <nz-form-control [nzSpan]="12">
                    <label nz-checkbox formControlName="atividadeServico" [(ngModel)]="checkedServico">S -
                        Serviço</label>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="8">Categoria</nz-form-label>
                <nz-form-control [nzSpan]="12"
                                 [nzHasFeedback]="formDadosCadastrais.formGroup.value.categoria">
                    <nz-select formControlName="categoria" nzAllowClear>
                        <nz-option *ngFor="let opt of comboCategoria"
                                   [nzLabel]="opt.label"
                                   [nzValue]="opt.key">
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="fechar(formDadosCadastrais)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="gravando" (click)="confirmarSalvar();">Confirmar</button>
    </div>
</nz-modal>
<nz-modal [(nzVisible)]="formSistema.modalVisible"
          [nzTitle]="'Alterar Dados do Sistema'" [nzClosable]="true"
          (nzOnCancel)="fechar(formSistema)"
          [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzAutoTips]="autoTips" [nzNoColon]="true" [formGroup]="formSistema.formGroup">
            <nz-form-item>
                <nz-form-label [nzSpan]="10" nzRequired>Disponível para Agenda</nz-form-label>
                <nz-form-control [nzSpan]="13">
                    <nz-radio-group formControlName="disponivelAgenda" [(ngModel)]="radioDisponivelAgenda">
                        <label nz-radio nzValue="sim">Sim</label>
                        <label nz-radio nzValue="nao">Não</label>
                    </nz-radio-group>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="10" nzRequired>Disponível para Compliance</nz-form-label>
                <nz-form-control [nzSpan]="13">
                    <nz-radio-group formControlName="temCompliance" [(ngModel)]="obrigacao.temCompliance">
                        <label nz-radio nzValue="sim">Sim</label>
                        <label nz-radio nzValue="nao">Não</label>
                    </nz-radio-group>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="10" nzRequired>Disponível para Validação</nz-form-label>
                <nz-form-control [nzSpan]="13">
                    <nz-radio-group formControlName="disponivelValidacao" [(ngModel)]="radioDisponivelValidacao">
                        <label nz-radio nzValue="sim">Sim</label>
                        <label nz-radio nzValue="nao">Não</label>
                    </nz-radio-group>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="10" nzRequired>Disponível para Transmissão</nz-form-label>
                <nz-form-control [nzSpan]="13">
                    <nz-radio-group formControlName="disponivelTransmissao" [(ngModel)]="radioDisponivelTransmissao">
                        <label nz-radio nzValue="sim">Sim</label>
                        <label nz-radio nzValue="nao">Não</label>
                    </nz-radio-group>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="10" nzRequired>Prazo de Expiração (em dias)</nz-form-label>
                <nz-form-control [nzSpan]="13">
                    <input nz-input type="text" formControlName="prazoExpiracao" size="60" value="{{prazoExpiracao}}"/>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="10" nzRequired>Bloqueado</nz-form-label>
                <nz-form-control [nzSpan]="13">
                    <nz-radio-group formControlName="bloqueado" [(ngModel)]="radioBloqueado">
                        <label nz-radio nzValue="sim">Sim</label>
                        <label nz-radio nzValue="nao">Não</label>
                    </nz-radio-group>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="fechar(formSistema)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="gravando" (click)="confirmarSalvar();">Confirmar</button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formPrazo.modalVisible"
          [nzTitle]="'Prazo de Expiração'" [nzClosable]="true" (nzOnCancel)="fechar(formPrazo)">
    <div nz-row *nzModalContent>
        <form nz-form [nzAutoTips]="autoTips" [nzNoColon]="true" [formGroup]="formPrazo.formGroup">
            <nz-form-item>
                <nz-form-label [nzSpan]="16" nzRequired nzTooltipTitle="Prazo de Expiração (em dias)">Prazo de Expiração
                    (em dias)
                </nz-form-label>
                <nz-form-control [nzSpan]="8">
                    <input nz-input
                           formControlName="prazoExpiracaoConsulta" type="number" placeholder="Prazo de Expiração"
                           autocomplete="off" size="60" maxlength="20" ngModel="{{obrigacao.prazoExpiracaoConsulta}}"/>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="fechar(formPrazo)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="gravando" (click)="confirmar(formPrazo)">Confirmar</button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formVencimento.modalVisible"
          [nzTitle]="'Vencimento'" [nzClosable]="true" (nzOnCancel)="fechar(formVencimento)">
    <div nz-row *nzModalContent>
        <form nz-form [nzAutoTips]="autoTips" [nzNoColon]="true" [formGroup]="formVencimento.formGroup">
            <input nz-input
                   formControlName="id" type="hidden" ngModel="{{editarId}}"/>
            <nz-form-item>
                <nz-form-label [nzSpan]="8" nzRequired>Competência</nz-form-label>

                <nz-form-control [nzSpan]="12">
                    <input nz-input
                           formControlName="competencia" type="text"
                           autocomplete="off" size="60" maxlength="20" placeholder="MM/AAAA ou AAAA"
                           mask="{{mask}}"
                           (keyup)="alterarMascara($event)"
                           ngModel="{{editarCompetencia}}"/>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="8" nzRequired>Vencimento</nz-form-label>

                <nz-form-control [nzSpan]="12">
                    <nz-date-picker (keyup)="maskNZDatePicker($event)" [nzFormat]="'dd/MM/yyyy'"
                                    ngModel="{{editarVencimento}}"
                                    formControlName="vencimento" style="width: 100%;"></nz-date-picker>
                </nz-form-control>

            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="fechar(formVencimento)">Cancelar</button>
        <button nz-button nzType="primary" *ngIf="editarId" [nzLoading]="gravando" (click)="updateVencimento()">
            Confirmar
        </button>
        <button nz-button nzType="primary" *ngIf="!editarId" [nzLoading]="gravando" (click)="InsertVencimento()">
            Confirmar
        </button>
    </div>
</nz-modal>


<nz-modal [(nzVisible)]="modalVencimentosSugeridosVidible"
          nzWidth="260px"
          [nzBodyStyle]="{'padding': '0 5px'}"
          [nzTitle]="tituloModal" [nzClosable]="true"
          (nzOnCancel)="showModalVencimentosSugeridos(false)">
    <ng-template #tituloModal>
        <h5 style="display: inline-block; width: 200px;">
            Selecione os anos para cadastro
        </h5>
    </ng-template>
    <div nz-row *nzModalContent>
        <nz-table #basicTable
                  nzSize="small"
                  [nzData]="vencimentosSugeridos"
                  [nzFrontPagination]="false"
                  [nzShowPagination]="false"
                  style="width: 100%"
        >
            <thead>
            <tr>
                <th (nzCheckedChange)="onAllChecked($event)" [nzChecked]="checked"
                    style="width: 32px"
                    id="check-all">
                </th>
                <th style="text-align: center;">Ano</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let ano of vencimentosSugeridos" (click)="onItemCheckedByClick(ano)"
                style="cursor: pointer">
                <td (nzCheckedChange)="onItemChecked(ano, $event)"
                    [nzChecked]="checkedItems.has(ano)">
                </td>
                <td style="text-align: center;">{{ano}}</td>
            </tr>
            <tr>
                <td colspan="2" nzAlign="center">
                    <button nz-button nzType="dashed" class="add-button" (click)="addFieldAno(ultimoAno)">
                        <span nz-icon nzType="plus"></span>
                        {{ultimoAno + 1}}
                    </button>
                </td>
            </tr>
            </tbody>
        </nz-table>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="showModalVencimentosSugeridos(false)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="gravando"
                (click)="InsertVencimentosSugeridos()">Confirmar
        </button>
    </div>
</nz-modal>
