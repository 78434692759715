import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AbstractService} from '@services/abstract.service';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {Helpers} from '../../../core/helpers';

@Injectable({providedIn: 'root'})
export class EcacService extends AbstractService<any> {

    urlTabela = environment.apiUrl + '/consolidado/situacao-fiscal/ecac/relatorio/tabela';

    constructor(http: HttpClient) {
        super(http, '/cadastros/obrigacao/categorias', null);
    }

    retornarCabecalho(filtros): Observable<any> {

        const queryStr = Helpers.montaQueryString(filtros);
        return this.http.get(`${environment.apiUrl}/consolidado/situacao-fiscal/ecac/relatorio/cabecalho${queryStr}`);
    }

    retornarSelectsEmpresas(identificador): Observable<any> {
        return this.http.get(`${environment.apiUrl}/filtros/empresa/select?identificador=${identificador}`);
    }

    getPDF(relaorioId: string, cnpj: string): Observable<any> {
        return this.http.get(`${environment.apiUrl}/consolidado/situacao-fiscal/ecac/relatorio/pdf?cnpj=${cnpj}&id=${relaorioId}`);
    }

    getHistorico(cnpj: string, params?: any): Observable<any> {

        const filtros: any = {};

        if (params && params.sort) {
            Object.entries(params.sort).forEach((f: any) => {
                if (f[1].value) {
                    filtros.ordenar = f[1].key;
                    filtros.sentido = f[1].value;
                }
            });
        }

        const queryStr = Helpers.montaQueryString(filtros);

        return this.http.get(`${environment.apiUrl}/consolidado/situacao-fiscal/ecac/relatorio/historico/${cnpj}${queryStr}`);

    }

    reprocessarRegistro(empresaId): Observable<any> {
        return this.http.get(`${environment.apiUrl}/consolidado/situacao-fiscal/ecac/relatorio/solicitar/${empresaId}`);
    }

    desbloquear(empresaId): Observable<any> {
        return this.http.get(`${environment.apiUrl}/consolidado/situacao-fiscal/ecac/relatorio/desbloquear/${empresaId}`);
    }

    uploadArquivo(formData: FormData): Observable<any> {
        return this.http.post(`${environment.apiUrl}/consolidado/situacao-fiscal/ecac/relatorio/upload`, formData);
    }

    exportPdfs(params, search: string = null, baseUrl = null): Observable<any> {

        const {pageIndex, pageSize, sort, filter} = params;
        const currentSort = sort.find(item => item.value !== null);
        const sortField = (currentSort && currentSort.key) || null;
        let sortOrder = (currentSort && currentSort.value) || null;

        const queryParams = [];

        queryParams.push(`tipoExportador=pdf`);
        queryParams.push(`tipo=cnd`);

        if (pageIndex !== null && typeof pageIndex !== 'undefined') {
            queryParams.push(`pagina=${pageIndex}`);
        }

        if (pageSize !== null && typeof pageSize !== 'undefined') {
            queryParams.push(`quantidade=${pageSize}`);
        }

        if (sortField !== null && typeof sortField !== 'undefined') {
            if (sortOrder === 'ascend') {
                sortOrder = 'asc';
            }
            if (sortOrder === 'descend') {
                sortOrder = 'desc';
            }
            queryParams.push(`sort[by]=${sortField}`);
            queryParams.push(`sort[order]=${sortOrder}`);
        }

        if (search !== null && typeof search !== 'undefined') {
            queryParams.push(`busca=${search}`);
        }

        if (params.filter) {
            params.filter.forEach((obj) => {
                if (obj.key && obj.value && !['pagina', 'quantidade'].includes(obj.key)) {
                    queryParams.push(obj.key + '=' + obj.value);
                }
            });
        }

        const queryString = queryParams.length > 0 ? '?' + queryParams.join('&') : '';


        return this.http.get(`${environment.apiUrl}/consolidado/situacao-fiscal/ecac/relatorio/exportar/pdf${queryString}`,
            {responseType: 'blob'});

    }

    exportExcel(filtros: any, tipo: string, search?: string): any {

        if (search) {
            filtros.procurar = search;
        }

        filtros.tipoExportacao = tipo;

        const queryStr = Helpers.montaQueryString(filtros);

        return this.http.get(`${environment.apiUrl}/consolidado/situacao-fiscal/ecac/relatorio/tabela/exportar${queryStr}`,
            {responseType: 'blob'});

    }

    retornarSelectsEmpresasCodigo(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/empresa/select`);
    }

}
