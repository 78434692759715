<div style="background-color: #fff; height: calc(100% - 8px);">
    <nz-page-header class="site-page-header" nzTitle="Configurações" nzSubtitle="Inbox"></nz-page-header>

    <div style="position: relative; display: flex; flex-direction: column; align-items: center;
     padding-top: 3%;">

        <!-- <nz-modal [(nzVisible)]="modalCadastrarVisible" [nzTitle]="'Configurações Inbox'" [nzClosable]="true"
          (nzOnCancel)="modalCadastrar(false)" [nzWidth]="748"> -->
        <!-- *nzModalContent -->
        <div style="width: 75%;">
            <form nz-form [nzAutoTips]="autoTips" [nzNoColon]="true" [formGroup]="formConfiguracao"
                  style="width: 100%;">
                <nz-form-item>
                    <nz-form-label [nzSpan]="7" nzRequired>Host do Servidor IMAP</nz-form-label>
                    <nz-form-control [nzSpan]="12" [nzErrorTip]="'Campo Obrigatório'">
                        <input nz-input type="text" formControlName="host" placeholder="Host do servidor IMAP"
                               type="text" maxlength="100"/>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="7" nzRequired>Conta (e-mail)</nz-form-label>
                    <nz-form-control [nzSpan]="12">
                        <input nz-input type="text" formControlName="email" placeholder="Conta (e-mail)" type="text"
                               maxlength="100"/>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="7" nzRequired>Senha</nz-form-label>
                    <nz-form-control [nzSpan]="12" [nzErrorTip]="'Campo Obrigatório'">
                        <nz-input-group [nzSuffix]="suffixTemplate">
                            <input
                                    [type]="passwordVisible ? 'text' : 'password'"
                                    nz-input
                                    placeholder="Senha"
                                    formControlName="password"
                            />
                        </nz-input-group>
                        <ng-template #suffixTemplate>
                          <span
                                  nz-icon
                                  class="ant-input-password-icon"
                                  [nzType]="passwordVisible ? 'eye-invisible' : 'eye'"
                                  (click)="passwordVisible = !passwordVisible"
                          ></span>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSpan]="7" nzRequired>Porta IMAP</nz-form-label>
                    <nz-form-control [nzSpan]="12" [nzErrorTip]="'Campo Obrigatório'">
                        <input nz-input type="text" formControlName="port" placeholder="Porta" type="text"/>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSpan]="7" nzRequired>Protocolo (TSL)</nz-form-label>
                    <nz-form-control [nzSpan]="12" [nzErrorTip]="'Campo Obrigatório'">
                        <input nz-input type="text" formControlName="protocol" placeholder="Protocolo"
                               type="text"/>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSpan]="7">Pasta de leitura</nz-form-label>
                    <nz-form-control [nzSpan]="12">
                        <input nz-input type="text" formControlName="pasta" placeholder="Pasta para leitura"
                               type="text"/>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSpan]="7">Ler mensagens a partir de</nz-form-label>
                    <nz-form-control [nzSpan]="12">
                        <nz-date-picker style="width: 100%;" formControlName="data"
                                        nzFormat="dd/MM/yyyy"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSpan]="7">Ativo</nz-form-label>
                    <nz-form-control [nzSpan]="12">
                        <nz-switch formControlName="status"></nz-switch>
                    </nz-form-control>
                </nz-form-item>

                <div style="width: 59%; margin: 0 auto; text-align: right;">
                    <!-- <a nz-button nzType="link" (click)="modalCadastrar(false)">Cancelar</a> -->
                    <button nz-button nzType="primary" [nzLoading]="loadingBt" (click)="this.cadastrarConfiguracao();">
                        Gravar
                    </button>
                </div>


            </form>
        </div>
        <!-- <div *nzModalFooter>
            <a nz-button nzType="link" (click)="modalCadastrar(false)">Cancelar</a>
            <button nz-button nzType="primary" [nzLoading]="loadingBt" (click)="this.cadastrarConfiguracao();">
                Gravar
            </button>
        </div> -->
        <!-- </nz-modal> -->

    </div>

</div>