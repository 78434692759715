<nz-page-header class="site-page-header" nzTitle="Flow" style="padding: 3px" nzSubtitle="Obrigações">
    <nz-page-header-extra>
        <a *ngIf="loadFake">
            <span nz-icon nzType="loading" nzTheme="outline"></span>
        </a>
        <a nz-button nzType="link" (click)="slideSwiper('left', 6)">
            <i class="fas fa-angle-double-left"></i>
        </a>
        <a nz-button nzType="link" (click)="slideSwiper('left', 3)">
            <i class="fas fa-angle-left"></i>
        </a>
        <a nz-button nzType="link" (click)="slideSwiper('right', 3)">
            <i class="fas fa-angle-right"></i>
        </a>
        <a nz-button nzType="link" (click)="slideSwiper('right', 6)">
            <i class="fas fa-angle-double-right"></i>
        </a>
        <a nz-button nzType="link" (click)="toggleCollapseCard()">
            <span>{{swiperCollapseCard ? 'Expandir' : 'Recolher'}}</span>
        </a>
    </nz-page-header-extra>

</nz-page-header>
<nz-row [nzGutter]="24">
    <nz-col [nzSpan]="24" style="overflow: hidden;">
        <swiper [(index)]="swiperCardAtivo" (click)="selecionaCardCompetencia($event)" slidesPerView="auto"
            [spaceBetween]="30" [centeredSlides]="true" [mousewheel]="false" [scrollbar]="false" [navigation]="false">
            <ng-template swiperSlide *ngFor="let item of swiperCompetencias; index as i"
                class="swiper-card-competencia">
                <div class="card-competencia ant-card pull-up card-{{i}}" [ngClass]="swiperCollapseCard && 'compress'">
                    <div class="ant-card-head">
                        <h3 style="margin-bottom: 0;">Vencimento:</h3>
                        <h2 style="margin-bottom: 0;">{{(mesesFlow[item.mes - 1] | uppercase)}}/{{item.ano}}</h2>
                    </div>
                    <div class="text-center ant-card-body card-{{i}}">
                        <div nz-row>
                            <div nz-col [nzSpan]="10" style="text-align: right; padding-right: 10px;">
                                {{item.obrigacoes ? item.obrigacoes : 0}}
                            </div>
                            <div nz-col [nzSpan]="14" style="text-align: left; ">
                                Obrigações
                            </div>
                        </div>
                        <div nz-row>
                            <div nz-col [nzSpan]="10" style="text-align: right; padding-right: 10px;">
                                {{item.unidades ? item.unidades : 0}}
                            </div>
                            <div nz-col [nzSpan]="14" style="text-align: left; ">
                                Unidades
                            </div>
                        </div>
                        <div nz-row>
                            <div nz-col [nzSpan]="24" style="text-align: center; ">
                                <div>
                                    <span nz-typography nzType="secondary">Agenda</span>
                                </div>
                                <div>
                                    <span style="margin-right: 5px; color: green;">{{item.agendas ? item.agendas :
                                        0}}</span>
                                    <span style="color: red;">({{item.agendasAtrasadas ? item.agendasAtrasadas : 0}}
                                        )</span>
                                </div>
                            </div>
                        </div>
                        <div nz-row>
                            <div nz-col [nzSpan]="24" style="text-align: center; ">
                                <div>
                                    <span nz-typography nzType="secondary">Armazenamento</span>
                                </div>
                                <div>
                                    <span style="margin-right: 5px; color: green;">{{item.armazenamentos ?
                                        item.armazenamentos : 0}}</span>
                                    <span style="color: red;">({{item.armazenamentosAtrasados ?
                                        item.armazenamentosAtrasados : 0}}
                                        )</span>
                                </div>
                            </div>
                        </div>
                        <div nz-row>
                            <div nz-col [nzSpan]="24" style="text-align: center">
                                <div>
                                    <span nz-typography nzType="secondary">Validações</span>
                                </div>
                                <div>
                                    <span style="margin-right: 5px; color: green;">{{item.validacoes ? item.validacoes :
                                        0}}</span>
                                    <span style="color: red;">({{item.validacoesAtrasadas ? item.validacoesAtrasadas :
                                        0}}
                                        )</span>
                                </div>
                            </div>
                        </div>
                        <div nz-row>
                            <div nz-col [nzSpan]="24" style="text-align: center; ">
                                <div>
                                    <span nz-typography nzType="secondary">Conciliações</span>
                                </div>
                                <div>
                                    <span style="margin-right: 5px; color: green;">{{item.conciliacoes ?
                                        item.conciliacoes : 0}}</span>
                                    <span style="color: red;">({{item.conciliacoesAtrasadas ? item.conciliacoesAtrasadas
                                        : 0}}
                                        )</span>
                                </div>
                            </div>
                        </div>
                        <div nz-row>
                            <div nz-col [nzSpan]="24" style="text-align: center">
                                <div>
                                    <span nz-typography nzType="secondary">Aprovações</span>
                                </div>
                                <div>
                                    <span style="margin-right: 5px; color: green;">{{item.aprovacoes_aprovadas ?
                                        item.aprovacoes_aprovadas : 0}}</span>
                                    <span style="color: red;">({{item.aprovacoes_aguardando ? item.aprovacoes_aguardando
                                        : 0}}
                                        )</span>
                                </div>
                            </div>
                        </div>
                        <div nz-row>
                            <div nz-col [nzSpan]="24" style="text-align: center; ">
                                <div>
                                    <span nz-typography nzType="secondary">Transmissões</span>
                                </div>
                                <div>
                                    <span style="margin-right: 5px; color: green;">{{item.transmissoes ?
                                        item.transmissoes : 0}}</span>
                                    <span style="color: red;">({{item.transmissoes_atrasadas ?
                                        item.transmissoes_atrasadas : 0}}
                                        )</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </swiper>
    </nz-col>
</nz-row>
<nz-content>
    <nz-row [nzGutter]="24">
        <nz-col [nzSpan]="8">
            <nz-card [nzLoading]="cardsCabecalhoLoading">
                <div nz-row>
                    <div nz-col [nzSpan]="24">
                        <h4 class="custom-card-title text-muted" style="margin: 0; line-height: 1;">
                            {{this.mesesFlow[this.cabecalhoFlow.mes - 1]}}
                        </h4>
                        <div class="d-flex align-items-center justify-content-center">
                            <h1 nz-typography class="custom-card-value" style="margin: 0;">
                                {{this.cabecalhoFlow.ano}}
                            </h1>
                        </div>
                    </div>
                </div>
            </nz-card>
        </nz-col>
        <nz-col [nzSpan]="8">
            <nz-card [nzLoading]="cardsCabecalhoLoading">
                <div nz-row>
                    <div nz-col [nzSpan]="24" class="d-flex align-items-center">
                        <i class="far fa-building"
                            style="font-size: 60px; margin-right: 15px; color: #009688 !important;"></i>
                        <div>
                            <h4 class="custom-card-title text-muted" style="margin: 0; line-height: 1;">
                                Unidades
                            </h4>
                            <h1 nz-typography class="custom-card-value" style="margin: 0; color: #009688 !important;">
                                {{this.cabecalhoFlow.unidades}}
                            </h1>
                        </div>
                    </div>
                </div>
            </nz-card>
        </nz-col>
        <nz-col [nzSpan]="8">
            <nz-card [nzLoading]="cardsCabecalhoLoading">
                <div nz-row>
                    <div nz-col [nzSpan]="24" class="d-flex align-items-center">
                        <i class="far fa-calendar-alt"
                            style="font-size: 60px; margin-right: 15px; color:#1e9ff2 !important;"></i>
                        <div>
                            <h4 class="custom-card-title text-muted" style="margin: 0; line-height: 1;">
                                Obrigações
                            </h4>
                            <h1 nz-typography class="custom-card-value" style="margin: 0; color: #1e9ff2 !important;">
                                {{this.cabecalhoFlow.obrigacoes}}
                            </h1>
                        </div>
                    </div>
                </div>
            </nz-card>
        </nz-col>
    </nz-row>
    <nz-row [nzGutter]="24">
        <nz-col [nzSpan]="5">
            <nz-card [nzLoading]="cardsCabecalhoLoading">
                <div nz-row>
                    <div nz-col [nzSpan]="24">
                        <h4 class="custom-card-title text-muted" style="line-height: 1;">
                            Agenda
                        </h4>
                        <nz-row>
                            <nz-col [nzSpan]="24" class="d-flex justify-content-center">
                                <nz-progress [nzPercent]="cabecalhoFlow.porcentagemAgendas" nzType="circle"
                                    [nzWidth]="80" nz-tooltip="true"
                                    nzTooltipTitle="{{this.cabecalhoFlow.agendas}} Concluídas / {{this.cabecalhoFlow.agendasAtrasadas}} Pendentes ou Atrasadas"
                                    [nzFormat]="formatOne"></nz-progress>
                            </nz-col>
                        </nz-row>
                    </div>
                </div>
            </nz-card>
        </nz-col>
        <nz-col [nzSpan]="5">
            <nz-card [nzLoading]="cardsCabecalhoLoading">
                <div nz-row>
                    <div nz-col [nzSpan]="24">
                        <h4 class="custom-card-title text-muted" style="line-height: 1;">
                            Armazenamento
                        </h4>
                        <nz-row>
                            <nz-col [nzSpan]="24" class="d-flex justify-content-center">
                                <nz-progress [nzPercent]="cabecalhoFlow.porcentagemArmazenadas" nzType="circle"
                                    [nzWidth]="80" nz-tooltip="true"
                                    nzTooltipTitle="{{this.cabecalhoFlow.armazenamentos}} Armazenadas / {{this.cabecalhoFlow.armazenamentosAtrasados ? this.cabecalhoFlow.armazenamentosAtrasados : 0}} Ausentes"
                                    [nzFormat]="formatOne"></nz-progress>
                            </nz-col>
                        </nz-row>
                    </div>
                </div>
            </nz-card>
        </nz-col>
        <nz-col [nzSpan]="5">
            <nz-card [nzLoading]="cardsCabecalhoLoading">
                <div nz-row>
                    <div nz-col [nzSpan]="24">
                        <h4 class="custom-card-title text-muted" style="line-height: 1;">
                            Válidas e Conciliadas
                        </h4>
                        <nz-row>
                            <nz-col [nzSpan]="24" class="d-flex justify-content-center">
                                <nz-progress [nzPercent]="cabecalhoFlow.porcentagemValidasConciliadas" nzType="circle"
                                    [nzWidth]="80" nz-tooltip="true"
                                    nzTooltipTitle="{{this.cabecalhoFlow.validacoes}} Validações, {{this.cabecalhoFlow.conciliacoes}} Conciliacões / {{this.cabecalhoFlow.validacoesAtrasadas}} Validações Atrasadas, {{this.cabecalhoFlow.conciliacoesAtrasadas}} Conciliacões Atrasadas"
                                    [nzFormat]="formatOne"></nz-progress>
                            </nz-col>
                        </nz-row>
                    </div>
                </div>
            </nz-card>
        </nz-col>
        <nz-col [nzSpan]="5">
            <nz-card [nzLoading]="cardsCabecalhoLoading">
                <div nz-row>
                    <div nz-col [nzSpan]="24">
                        <h4 class="custom-card-title text-muted" style="line-height: 1;">
                            Aprovações
                        </h4>
                        <nz-row>
                            <nz-col [nzSpan]="24" class="d-flex justify-content-center">
                                <nz-progress [nzPercent]="cabecalhoFlow.porcentagemAprovacoes" nzType="circle"
                                    [nzWidth]="80" nz-tooltip="true"
                                    nzTooltipTitle="{{cabecalhoFlow.aprovacoes_aprovadas ? cabecalhoFlow.aprovacoes_aprovadas : 0}} Aprovadas / {{cabecalhoFlow.aprovacoes_aguardando ? cabecalhoFlow.aprovacoes_aguardando : 0}} Aguardando"
                                    [nzFormat]="formatOne"></nz-progress>
                            </nz-col>
                        </nz-row>
                    </div>
                </div>
            </nz-card>
        </nz-col>
        <nz-col [nzSpan]="4">
            <nz-card [nzLoading]="cardsCabecalhoLoading">
                <div nz-row>
                    <div nz-col [nzSpan]="24">
                        <h4 class="custom-card-title text-muted" style="line-height: 1;">
                            Transmissões
                        </h4>
                        <nz-row>
                            <nz-col [nzSpan]="24" style="text-align: right" class="d-flex justify-content-center">
                                <nz-progress [nzPercent]="cabecalhoFlow.porcentagemTransmissoes" nzType="circle"
                                    [nzWidth]="80" nz-tooltip="true"
                                    nzTooltipTitle="{{cabecalhoFlow.transmissoes ? cabecalhoFlow.transmissoes : 0}} Transmitidas / {{cabecalhoFlow.transmissoesAtrasadas ? cabecalhoFlow.transmissoesAtrasadas : 0}} Não Transmitidas"
                                    [nzFormat]="formatOne"></nz-progress>
                            </nz-col>
                        </nz-row>
                    </div>
                </div>
            </nz-card>
        </nz-col>
    </nz-row>
</nz-content>
<nz-card nzTitle="Obrigações" [nzExtra]="tabsExtra"
    [ngClass]="[cardCompress ? 'card-fullscreen' : '', !cardTabsLoading ? 'ant-card-table' : '']"
    [nzLoading]="cardTabsLoading">
    <nz-table #tableFlow [nzData]="obrigacoesFlow" class="table-small" [nzPageSize]="100" [nzFrontPagination]="true"
        [nzShowTotal]="rangeTemplate">
        <thead>
            <tr>
                <th></th>
                <th>Obrigações</th>
                <th>Agenda</th>
                <th>Armazenadas</th>
                <th>Validação & Conciliações</th>
                <th *ngIf="exibirColunaAprovacoes">Aprovações</th>
                <th>Transmissões</th>
                <th>Confirmações</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let data of tableFlow.data">
                <td nzAlign="center">
                    <span style="font-size: 16px;">{{data.descricao}}</span>
                </td>
                <td nzAlign="center">{{data.obrigacoes}}</td>
                <td>
                    <div *ngIf="data.temAgenda else nd">
                        <div class="d-flex justify-content-between"
                            (click)="filtrarCalendar('status','P', data.obrigacao_id)">
                            <a>Pendentes</a>
                            <a>
                                {{data.agendaPendente}}
                                <i class="fas fa-circle" style="color: #ff9149 !important;; font-size:10px;"></i>
                            </a>
                        </div>
                        <div class="d-flex justify-content-between"
                            (click)="filtrarCalendar('status','A', data.obrigacao_id)">
                            <a>Atrasadas</a>
                            <a>
                                {{data.agendaAtrasado}}
                                <i class="fas fa-circle text-grey"
                                    style="color: #ff4961 !important; font-size:10px;"></i>
                            </a>
                        </div>
                        <div class="d-flex justify-content-between"
                            (click)="filtrarCalendar('status','C', data.obrigacao_id)">
                            <a>Concluídas</a>
                            <a>
                                {{data.agendaConcluido}}
                                <i class="fas fa-circle" style="color: #1565c0 !important; font-size:10px;"></i>
                            </a>
                        </div>
                        <div class="d-flex justify-content-between"
                            (click)="filtrarCalendar('status','CA', data.obrigacao_id)">
                            <a>Concluídas com Atraso</a>
                            <a>
                                {{data.agendaConcluidoForaPrazo}}
                                <i class="fas fa-circle" style="color: #666ee8; font-size:10px;"></i>
                            </a>
                        </div>
                    </div>
                </td>

                <td>
                    <ng-container *ngIf="data.pastaArmazenamento else nd">
                        <div class="d-flex justify-content-between"
                            (click)="filtrarArmazenamento({'situacao': 'armazenadas', 'categoria': data.obrigacao_id})">
                            <a>Armazenadas</a>
                            <a>
                                {{data.armazenadas}}
                                <i class="fas fa-circle" style="color: #1890ff; font-size:10px;"></i>
                            </a>
                        </div>
                        <div class="d-flex justify-content-between"
                            (click)="filtrarArmazenamento({'situacao': 'nao-armazenadas', 'categoria': data.obrigacao_id})">
                            <a>Ausentes</a>
                            <a>
                                {{data.naoArmazenadas}}
                                <i class="fas fa-circle text-grey" style="font-size:10px;"></i>
                            </a>
                        </div>
                        <nz-progress [nzPercent]="data.porcentagemArmazenadas" [nzShowInfo]="false"></nz-progress>
                    </ng-container>
                </td>

                <!--            Coluna Validação & Conciliação-->
                <td>
                    <div *ngIf="data.temValidacao || data.temConciliacao else nd">
                        <div class="d-flex justify-content-between"
                            (click)="filtrarValidacoes({'classificacao' : 'G', 'categoria' : data.obrigacao_id}, $event)">
                            <a>Graves</a>
                            <a>
                                {{data.validacoesGraves}}
                                <i class="fas fa-circle" style="color: red; font-size:10px;"></i>
                            </a>
                        </div>
                        <div class="d-flex justify-content-between">
                            <a
                                (click)="filtrarValidacoes({'classificacao' : 'M', 'categoria' : data.obrigacao_id})">Médias</a>
                            <div class="d-flex">
                                <a nz-tooltip="true" nzTooltipTitle="Aguardando Liberação"
                                    (click)="filtrarValidacoes({'classificacao' : 'M', 'liberacao' : 'pendente', 'categoria' : data.obrigacao_id})"
                                    *ngIf="data.validacoesMediasAguardando > 0" style="margin-right: 10px;">
                                    {{data.validacoesMediasAguardando}}
                                    <i class="fas fa-circle text-grey" style="font-size:10px;"></i>
                                </a>
                                <a
                                    (click)="filtrarValidacoes({'classificacao' : 'M', 'categoria' : data.obrigacao_id})">
                                    {{data.validacoesMediasLiberadas}}
                                    <i class="fas fa-circle" style="color: #52c41a; font-size:10px;"></i>
                                </a>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between"
                            (click)="filtrarValidacoes({'classificacao' : 'L', 'categoria' : data.obrigacao_id})">
                            <a>Leves</a>
                            <a>
                                {{data.validacoesLeves}}
                                <i class="fas fa-circle" style="color: #52c41a; font-size:10px;"></i>
                            </a>
                        </div>
                        <div *ngIf="data.validacoesAndamento > 0" class="text-center">
                            <a (click)="filtrarValidacoes({'status' : 1, 'categoria' : data.obrigacao_id})">
                                {{data.validacoesAndamento}} obrigações em andamento
                            </a>
                        </div>
                    </div>
                </td>

                <!--            Coluna Aprovações-->
                <td class="text-center" *ngIf="exibirColunaAprovacoes">
                    <div *ngIf="data.temAprovacao && (data.temValidacao || data.temConciliacao) else nd">

                        <nz-progress [nzPercent]="100 - data.porcentagemAprovacoes" nzType="circle" [nzWidth]="40"
                            nz-tooltip
                            nzTooltipTitle="{{data.aprovacoesAguardando}} Aguardando / {{data.aprovacoesAprovadas}} Aprovadas"></nz-progress>
                        <div class="d-flex justify-content-between">
                            <a
                                (click)="filtrarAprovacoes({'status' : '0', 'categoria' : data.obrigacao_id})">Aguardando</a>
                            <a (click)="filtrarAprovacoes({'status' : '0', 'categoria' : data.obrigacao_id})">
                                {{data.aprovacoesAguardando}}
                                <i class="fas fa-circle text-grey" style="font-size:10px;"></i>
                            </a>
                        </div>
                        <div class="d-flex justify-content-between">
                            <a
                                (click)="filtrarAprovacoes({'status' : '1', 'categoria' : data.obrigacao_id})">Aprovadas</a>
                            <a (click)="filtrarAprovacoes({'status' : '1', 'categoria' : data.obrigacao_id})">
                                {{data.aprovacoesAprovadas}}
                                <i class="fas fa-circle" style="color: #1890ff; font-size:10px;"></i>
                            </a>
                        </div>

                    </div>
                </td>

                <td>
                    <div *ngIf="data.temTransmissao else nd">
                        <div class="ant-progress-inner" style="background-color: #bfbfbf !important;">
                            <div class="ant-progress-bg"
                                [ngStyle]="{'width': (data.transmissoesEmExecucaoWidth + data.transmissoesTransmitidasWidth + data.transmissoesErroWidth) + '%'}"
                                style="border-radius: 100px; height: 8px;background-color: red;"></div>
                            <div class="ant-progress-success-bg ng-star-inserted"
                                [ngStyle]="{'width': (data.transmissoesEmExecucaoWidth + data.transmissoesTransmitidasWidth) + '%'}"
                                style="border-radius: 100px;height: 8px;background-color: #faad14;"></div>
                            <div class="ant-progress-success-bg ng-star-inserted"
                                [ngStyle]="{'width': data.transmissoesTransmitidasWidth + '%'}"
                                style="border-radius: 100px; height: 8px;"></div>
                        </div>
                        <div class="d-flex justify-content-between">
                            <a (click)="filtrarTransmissoes({'status' : 0, 'categoria' : data.obrigacao_id})">Não
                                Iniciado</a>
                            <a (click)="filtrarTransmissoes({'status' : 0, 'categoria' : data.obrigacao_id})">
                                {{data.transmissoesNaoIniciado}}
                                <i class="fas fa-circle text-grey" style="font-size:10px;"></i>
                            </a>
                        </div>
                        <div class="d-flex justify-content-between">
                            <a (click)="filtrarTransmissoes({'status' : 1, 'categoria' : data.obrigacao_id})">Em
                                Execução</a>
                            <a (click)="filtrarTransmissoes({'status' : 1, 'categoria' : data.obrigacao_id})">
                                {{data.transmissoesEmExecucao}}
                                <i class="fas fa-circle" style="color:#faad14; font-size:10px;"></i>
                            </a>
                        </div>
                        <div class="d-flex justify-content-between">
                            <a (click)="filtrarTransmissoes({'status' : 3, 'categoria' : data.obrigacao_id})">Erro</a>
                            <a (click)="filtrarTransmissoes({'status' : 3, 'categoria' : data.obrigacao_id})">
                                {{data.transmissoesErro}}
                                <i class="fas fa-circle" style="color: red; font-size:10px;"></i>
                            </a>
                        </div>
                        <div class="d-flex justify-content-between">
                            <a
                                (click)="filtrarTransmissoes({'status' : 2, 'categoria' : data.obrigacao_id})">Transmitidas</a>
                            <a (click)="filtrarTransmissoes({'status' : 2, 'categoria' : data.obrigacao_id})">
                                {{data.transmissoesTransmitidas}}
                                <i class="fas fa-circle" style="color: #52c41a; font-size:10px;"></i>
                            </a>
                        </div>
                    </div>
                </td>
                <td>
                    <div *ngIf="data.temConfirmacao && data.temAgenda else nd">
                        <div class="d-flex justify-content-between" (click)="filtrarCalendar('confirmacoes', 'N')">
                            <a>À confirmar</a>
                            <a>
                                {{data.confirmacoesAConfirmar}}
                                <i class="fas fa-circle" style="color: #000 !important;; font-size:10px;"></i>
                            </a>
                        </div>
                        <div class="d-flex justify-content-between" (click)="filtrarCalendar('confirmacoes', 'A')">
                            <a>Em confirmação</a>
                            <a>
                                {{data.confirmacoesEmConfirmacao}}
                                <i class="fas fa-circle text-grey"
                                    style="color: rgb(102, 110, 232) !important; font-size:10px;"></i>
                            </a>
                        </div>
                        <div class="d-flex justify-content-between" (click)="filtrarCalendar('confirmacoes', 'C')">
                            <a>Confimadas</a>
                            <a>
                                {{data.confirmacoesConfirmadas}}
                                <i class="fas fa-circle"
                                    style="color: rgb(40, 208, 148) !important; font-size:10px;"></i>
                            </a>
                        </div>
                        <div class="d-flex justify-content-between" (click)="filtrarCalendar('confirmacoes', 'R')">
                            <a>Rejeitadas</a>
                            <a>
                                {{data.confirmacoesRejeitadas}}
                                <i class="fas fa-circle" style="color: rgb(244, 67, 54); font-size:10px;"></i>
                            </a>
                        </div>

                    </div>
                </td>

                <ng-template #nd>
                    <div class="text-center">N/D</div>
                </ng-template>
            </tr>
        </tbody>
    </nz-table>
    <ng-template #tabsExtra>
        <div class="d-flex">
            <nz-badge [nzCount]="qtdFiltrosAtivos" [nzStyle]="{ backgroundColor: '#52c41a'}">
                <button nz-button nzType="primary" (click)="modalFiltrar(true)">
                    <i nz-icon nzType="search"></i>
                    <span>Filtros</span>
                </button>
            </nz-badge>
            <!--<button *nzSpaceItem nz-button nz-dropdown [nzDropdownMenu]="batchMenu" nzPlacement="bottomLeft">
                Operação em Lote
                <i nz-icon nzType="down"></i>
            </button>-->
            <!--<nz-dropdown-menu #batchMenu="nzDropdownMenu">
                <ul nz-menu>
                    <li nz-menu-item>Excluir</li>
                    <li nz-menu-item>Aprovação</li>
                </ul>
            </nz-dropdown-menu>-->
            <button nz-button nzType="default" (click)="resetSearch();" style="margin-right: 10px;">
                Limpar
            </button>
            <button nz-button [nzType]="'default'" (click)="clickEvent()">
                <i class="fas" [ngClass]="!cardCompress ? 'fa-expand' : 'fa-compress'"></i>
            </button>
        </div>
    </ng-template>
    <ng-template #rangeTemplate let-range="range" let-total>
        {{ range[0] }}-{{ range[1] }} de {{ total }} Registros
    </ng-template>
</nz-card>
<nz-modal [(nzVisible)]="formFiltrosFlow.modalVisible" [nzTitle]="'Filtros Avançados'" [nzClosable]="true"
    (nzOnCancel)="modalFiltrar(false)" [nzWidth]="768">
    <div nz-row *nzModalContent>
        <form nz-form [nzNoColon]="true" [formGroup]="formFiltrosFlow.formGroup" style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Categoria</nz-form-label>
                <nz-form-control [nzSpan]="14" nzValidateStatus="success"
                    [nzHasFeedback]="formFiltrosFlow.formGroup.value.categoria">
                    <nz-select nzShowSearch nzPlaceHolder="Selecione" formControlName="categoria"
                        [(ngModel)]="formFiltrosFlow.formGroup.value.categoria"
                        (ngModelChange)="clearInput(formFiltrosFlow.formGroup, 'categoria', 'obrigacao')">
                        <nz-option *ngFor="let opt of comboCategorias" [nzLabel]="opt.descricao"
                            [nzValue]="opt.id"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Obrigação</nz-form-label>
                <nz-form-control [nzSpan]="14" nzValidateStatus="success"
                    [nzHasFeedback]="formFiltrosFlow.formGroup.value.obrigacao">
                    <nz-select nzShowSearch nzPlaceHolder="Selecione" formControlName="obrigacao"
                        [(ngModel)]="formFiltrosFlow.formGroup.value.obrigacao"
                        (ngModelChange)="clearInput(formFiltrosFlow.formGroup, 'obrigacao', 'categoria')">
                        <nz-option *ngFor="let opt of comboObrigacoes" [nzLabel]="opt.descricao"
                            [nzValue]="opt.id"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalFiltrar(false);">Cancelar</button>
        <button nz-button nzType="primary" (click)="confirmaFiltrar()">Filtrar</button>
    </div>
</nz-modal>