import { Component, HostListener, Input, OnInit, Renderer2, ViewChild} from '@angular/core';
import {NzModalService} from 'ng-zorro-antd/modal';
import {Injectable} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {ToastrService} from 'ngx-toastr';
import {ObrigacoesEditarRegrasService} from './editar-obrigacoes-regras.service';
import {Pagination} from '@models/pagination.model';
import {environment} from '../../../../environments/environment';
import {Subscription} from 'rxjs';
import {DataService} from '@services/data.service';

interface FormStack {
    modalVisible: boolean;
    formGroup: UntypedFormGroup;
}

@Component({
    selector: 'app-obrigacoes-editar-regras',
    templateUrl: './editar-obrigacoes-regras.component.html',
    styleUrls: ['./editar-obrigacoes-regras.component.scss']
})
@Injectable()
export class ObrigacoesEditarRegrasComponent extends AbstractListTable<any> implements OnInit {

    @ViewChild('cardHeight') cardHeight: any;
    @ViewChild('cardHeightTarget') cardHeightTarget: any;

    formEditarClassificacao: FormStack;
    formSupervisor: FormStack;

    textoBotaoSupervisor: any = 'Adicionar Perfil';

    descricaoObrigacaoSelecionada = '';

    @Input() data;

    currentUser: Subscription;
    comboPerfis = [];

    comboTipo = ['V', 'C', 'S', 'E'];
    comboClassificacao = [
        {label: 'Leves', value: 'L'},
        {label: 'Médias', value: 'M'},
        {label: 'Graves', value: 'G'},
    ];



    itemsSupervisores = [];
    loadingSupervisores = true;

    qtdFiltrosAtivos = 0;
    formFiltrar: FormStack;

    constructor(
        private fb: UntypedFormBuilder,
        private toastService: ToastrService,
        private obrigacoesEditarRegrasService: ObrigacoesEditarRegrasService,
        private dataService: DataService,
        private modalService: NzModalService,
    ) {

        super(obrigacoesEditarRegrasService, null, toastService);

        this.getScreenSize();

        this.formEditarClassificacao = {
            modalVisible: false,
            formGroup: this.fb.group({
                id: [null, null],
                classificacao: [null, null]
            })
        };

        this.formSupervisor = {
            modalVisible: false,
            formGroup: this.fb.group({
                perfilId: [null, null]
            })
        };

        this.dataService.currentUser.subscribe((data) => {
            this.currentUser = data;
        });

        this.formFiltrar = {
            modalVisible: false,
            formGroup: this.fb.group({
                codigo: [null, null],
                tipo: [null, null],
                campo: [null, null],
                classificacao: [null, null]
            })
        };

    }

    @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
        this.resizeCard();
    }

    submitFormClassificacao(): void {

        this.loading = true;

        this.obrigacoesEditarRegrasService.editarClassificacao(
            this.formEditarClassificacao.formGroup.value.id, this.formEditarClassificacao.formGroup.value.classificacao)
            .subscribe((res) => {

                this.formEditarClassificacao.modalVisible = false;

                this.toastService.success(res.message);

                this.queryTable(this.currentParams, this.currentSearch);

            }, (res) => {

                this.toastService.error(res.error.message);

            });

    }

    submitFormSupervisor(): void {

        this.obrigacoesEditarRegrasService.gravarSupervisor(
            this.data.obrigacao_id, this.formSupervisor.formGroup.value.perfilId).subscribe((response: any) => {

                this.toastrService.success(response.message);

                this.formSupervisor.formGroup.reset();

                this.formSupervisor.modalVisible = false;

                this.retornaSupervisores();

            },
            (response) => {

                this.toastrService.error(response.error.message);

            }
        );

    }

    ngOnInit(): void {
        if (!this.data || !this.data.obrigacao_id){
            this.data = {};
            this.data.obrigacao_id = localStorage.getItem('param');
        }

        this.descricaoObrigacaoSelecionada = this.data.descricao;

        this.obrigacoesEditarRegrasService.retornaComboPerfis().subscribe((retorno: any) => {
            this.comboPerfis = retorno;
        });

        this.retornaSupervisores();

    }

    queryTable(params: NzTableQueryParams, search: string = null): void {
        this.currentParams = params;

        this.calculaBadgeFiltros();

        const filtros = this.formFiltrar.formGroup.value;
        this.currentParams.filter = [];

        for (const [chave, valor] of Object.entries(filtros)) {
            if (valor) {
                this.currentParams.filter.push({key: chave, value: valor});
            }
        }

        this.loading = true;

        const baseUrl = environment.apiUrl + '/check/obrigacoes/regras/editar/' + this.data.obrigacao_id;

        this.obrigacoesEditarRegrasService.listToTable(params, search, baseUrl).subscribe((response) => {

            this.checkedItems.clear();

            this.items = [];

            this.items = response.data || response;

            this.items.forEach((value) => {

                // value.classificacao = 'G';

                if (value.classificacao === 'G') {
                    value.classificacaoDescricao = 'Grave';
                    value.classificacaoTipo = 'error';
                }

                if (value.classificacao === 'M') {
                    value.classificacaoDescricao = 'Média';
                    value.classificacaoTipo = 'warning';
                }

                if (value.classificacao === 'L') {
                    value.classificacaoDescricao = 'Leve';
                    value.classificacaoTipo = 'success';
                }

            });

            this.pagination = new Pagination(
                response?.per_page || 50,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 50);

            this.loading = false;

            this.resizeCard();

        });

    }

    resizeCard() {

        if (this.cardHeight?.elementRef) {

            setTimeout(() => {

                const height = `${this.cardHeight.elementRef.nativeElement.lastElementChild.offsetHeight - 57}px`;
                // this.renderer.setStyle(this.cardHeightTarget.elementRef.nativeElement.lastElementChild, 'height', height);

            }, 300);

        }

    }

    showHideModal(form: FormStack, visible) {
        form.modalVisible = visible;
    }

    editarClassificacao(id, classificacao) {
        this.formEditarClassificacao.formGroup.get('id').setValue(id);
        this.formEditarClassificacao.formGroup.get('classificacao').setValue(classificacao);
        this.showHideModal(this.formEditarClassificacao, true);
    }

    retornaSupervisores() {

        this.loadingSupervisores = true;

        this.obrigacoesEditarRegrasService.retornaSupervisores(this.data.obrigacao_id).subscribe((response: any) => {

                this.itemsSupervisores = response;

                this.loadingSupervisores = false;

            },
            (response) => {

                this.toastrService.error(response.error.message);

            }
        );

    }

    showConfirmRemoverSupervisor(obrigacaoRegraPerfisId): void {

        this.modalService.confirm({
            nzTitle: 'Deseja remover o Supervisor selecionado?',
            nzOkText: 'Remover',
            nzCancelText: 'Cancelar',
            nzOnOk: () => this.removerSupervisor(obrigacaoRegraPerfisId)
        });

    }

    removerSupervisor(obrigacaoRegraPerfisId) {

        this.obrigacoesEditarRegrasService.removerSupervisor(obrigacaoRegraPerfisId).subscribe((response: any) => {

                this.toastrService.success(response.message);

                this.retornaSupervisores();

            },
            (response) => {

                this.toastrService.error(response.error.message);

            }
        );

    }

    modalFiltrar(visible) {
        this.formFiltrar.modalVisible = visible;
    }

    confirmaFiltrar(): void {

        this.currentParams.filter = [];

        const filtros = this.formFiltrar.formGroup.value;

        for (let [chave, valor] of Object.entries(filtros)) {

            if (chave === 'validadeInicio' || chave === 'validadeFim') {
                valor = this.abstractService.formataDateBD(valor.toString());
            }
            if (!this.currentParams.filter[chave]){
                this.currentParams.filter.push({key: chave, value: valor});
            }

        }

        this.queryTable(this.currentParams, this.currentSearch);

        this.modalFiltrar(false);

    }

    calculaBadgeFiltros(): void {
        this.qtdFiltrosAtivos = 0;
        if (typeof this.formFiltrar !== 'undefined') {
            for (const [chave, valor] of Object.entries(this.formFiltrar.formGroup.value)) {
                if (valor) {
                    this.qtdFiltrosAtivos += 1;
                }
            }
        }
    }

    btnResetSearch() {

        this.currentSearch = null;

        this.currentParams = {
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            sort: [],
            filter: [],
        };

        this.formFiltrar.formGroup.reset();

        this.queryTable(this.currentParams, this.currentSearch);

    }

}
