import {ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../../auth/authentication.service';
import {UserService} from '@services/user.service';
import {TabService} from '@services/tab.service';
import {Tab} from '@models/tab.model';
import {TabHandlerInterface} from '../../../../shared/interfaces/tab-handler.interface';
import {buildUrl, findComponentByUrl} from '../../../../shared/components-helper';
import {UsuarioLogado} from '@models/usuarioLogado.model';
import {DataService} from '@services/data.service';
import {ToastrService} from 'ngx-toastr';
import {environment} from '../../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {NzUploadChangeParam, NzUploadFile} from 'ng-zorro-antd/upload';
import {NzMessageService} from 'ng-zorro-antd/message';
import html2canvas from 'html2canvas';
import {ExternalPageComponent} from '../../../../modules/external-page/pages/external-page.component';
import {AppComponent} from '../../../../app.component';
import {select, Store} from '@ngrx/store';

declare global {
    interface Window {
        Conpass: any;
        userGuiding: any;
    }
}

interface FormStack {
    modalVisible: boolean;
    formGroup: UntypedFormGroup;
}

@Component({
    selector: 'user-menu',
    templateUrl: './user-menu.component.html',
    styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit, TabHandlerInterface {

    constructor(
        private dataService: DataService,
        private router: Router, private authenticationService: AuthenticationService,
        private userService: UserService, private tabService: TabService,
        private toastrService: ToastrService,
        private http: HttpClient,
        private fb: UntypedFormBuilder,
        private cdr: ChangeDetectorRef,
        private msg: NzMessageService,
        private store: Store
    ) {
    }

    get isExternalPage() {
        return ExternalPageComponent.isExternalPage;
    }

    get returnFrame() {
        return ExternalPageComponent.iframe;
    }

    static userName;

    @ViewChild('app-megamenu') megaMenu;
    currentUser: UsuarioLogado;
    contaId;

    @Output() criarNotificacao = new EventEmitter<boolean>();

    branch;
    versionDate;
    identificador: string;

    formChamado: FormStack;
    tipoChamado = null;
    fileListChamado: NzUploadFile[] = [];

    chamadoAberto = null;

    loadings = {
        abrindoChamado: false,
        capturarTela: false
    };

    labelClassificacao = {
        prime: 'Prime',
        super: 'Super',
        excellence: 'Excellence'
    };

    ngOnInit() {

        this.identificador = localStorage.getItem('identificador');

        const decodedToken: any = this.authenticationService.decodePayloadJWT();
        this.contaId = decodedToken.conta_id;

        this.userService.usuarioLogado().subscribe((user) => {

            this.currentUser = user;

            this.createComponentNotify();

            if (user.nome) {
                const userNameSplit = UserMenuComponent.userName = user.nome.split(' ');
                UserMenuComponent.userName = userNameSplit[0];
            }

            if (user.srcAvatar) {
                this.currentUser.srcAvatar = 'data:image/png;base64,' + user.srcAvatar;
            }

            this.dataService.changeMessage(user);

            const partesAppUrl = window.location.href.split('/');

            if (partesAppUrl.length > 3 && partesAppUrl[2].search('mastertax.app') >= 0) {

                const isAdmin = this.currentUser.admin === 1;
                const showUser = this.currentUser.userGuiding === 1;


                if (window.userGuiding && showUser) {
                    window.userGuiding.identify(this.currentUser.id, {
                        email: this.currentUser.email,
                        name: this.currentUser.nome,
                        identificador: this.currentUser.origem === 'console' ? 'console' : this.identificador
                    });
                }
            }

            const tabs = this.tabService.tabs;

            if (tabs.length === 0) {
                AppComponent.showGuideOnApp();
            }

        });

        this.userService.currentuser.subscribe(user => this.currentUser = user);

        this.retornaVersao().subscribe((response: any) => {
            this.branch = response.branch;
            this.versionDate = response.versionDate;
        });

        this.formChamado = {
            modalVisible: false,
            formGroup: this.fb.group({
                tipo: [null, null],
                titulo: [null, null],
                descricao: [null, null],
                arquivo: [null, null],
                conta_id: [null, null],
                url: [null, null],
            })
        };


    }

    createComponentNotify() {
        this.criarNotificacao.emit(true);        
    }

    logout(click: boolean) {
        if (click){
            this.authenticationService.logout('', true, true);
        }else{
            this.authenticationService.logout();
        }
    }

    fechar(formulario: FormStack): void {
        formulario.modalVisible = false;
    }

    abrirModal(formulario: FormStack): void {
        formulario.modalVisible = true;
    }

    openTab(path, queryParams = '', data = '') {
        const component = findComponentByUrl(path);
        const url = buildUrl(component, queryParams);
        const newTab = new Tab(component.name, component.title, url, component.urlType, data);
        this.tabService.addTab(newTab);
    }

    mensagemUserConsole(): void {
        this.toastrService.warning('Dados do usuário editáveis apenas via Console.');
    }

    retornaVersao() {
        return this.http.get(`${environment.apiUrl}/version`);
    }

    changeTipo(tipo) {
        this.tipoChamado = tipo;
    }

    // @ts-ignore
    getTabs = () => this.store.pipe(select('tabs'));

    screenShotFunction() {

        this.loadings.capturarTela = true;

        // const tabs = this.getTabs();

        this.getTabs().subscribe(tabsArray => {

            const subscribeTabs: any = tabsArray;

            const actibeTab = subscribeTabs.filter((element) => {
                return element.active === true;
            });

            const listener = (event) => {

                const dados = event.data;

                const blob = this.base64toBlob(dados.screenShot);

                const numImg = this.fileListChamado.length + 1;

                const file = new File([blob],
                    'anexo ' + numImg.toString() + ' (' + (actibeTab.length > 0 ? actibeTab[0].title : 'embbeded') + ').png',
                    {type: 'image/png'});

                this.beforeUploadChamado(file);

                this.formChamado.formGroup.get('conta_id').setValue(dados.conta_id);
                this.formChamado.formGroup.get('url').setValue(new URL(dados.url));

            };

            window.removeEventListener('message', listener);

            const isExternalPage = subscribeTabs.filter((element) => {
                return element.urlType === 'embedded' && element.active === true;
            });


            if (isExternalPage.length > 0) {

                const iframeActive = this.returnFrame;

                if (iframeActive) {

                    window.addEventListener('message', listener, {once: true});

                    const url = (new URL(iframeActive.src)).origin;

                    iframeActive.contentWindow.postMessage('screenShot', url);
                }

            } else {

                const config = {
                    ignoreElements: (element) => {
                        if (element.classList.contains('cdk-overlay-container')) {
                            return true;
                        }
                    }
                };

                html2canvas(document.body, config).then((canvas) => {

                    canvas.toBlob((blob) => {

                        const numImg = this.fileListChamado.length + 1;

                        const file = new File([blob],
                            'anexo ' + numImg.toString() + ' (' + (actibeTab.length > 0 ? actibeTab[0].title : 'embbeded') + ').png',
                            {type: 'image/png'});

                        this.beforeUploadChamado(file);

                        this.loadings.capturarTela = false;

                    }, 'image/png', 0.95);

                });

            }

        });

    }

    blobToBase64(blob) {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        return new Promise(resolve => {
            reader.onloadend = () => {
                resolve(reader.result);
            };
        });
    }

    private base64toBlob(base64Image: string) {

        const parts = base64Image.split(';base64,');

        const imageType = parts[0].split(':')[1];

        const decodedData = atob(parts[1]);

        const uInt8Array = new Uint8Array(decodedData.length);

        for (let i = 0; i < decodedData.length; ++i) {
            uInt8Array[i] = decodedData.charCodeAt(i);
        }

        return new Blob([uInt8Array], {type: imageType});

    }

    enviarChamado(): void {

        let erro = false;
        let mensagem = null;

        this.formChamado.formGroup.get('url').setValue(document.location.href);

        if (!this.formChamado.formGroup.value.titulo) {
            erro = true;
            mensagem = 'Defina um título para o chamado';
        } else if (!this.formChamado.formGroup.value.descricao) {
            erro = true;
            mensagem = 'Escreva uma descrição para o chamado';
        } else if (!this.tipoChamado) {
            erro = true;
            mensagem = 'Defina o tipo do chamado';
        }

        if (!erro) {

            const formData = new FormData();

            this.fileListChamado.forEach((file: any, index) => {
                formData.append('arquivo[' + index + ']', file);
            });

            formData.append('titulo', this.formChamado.formGroup.value.titulo);
            formData.append('descricao', this.formChamado.formGroup.value.descricao);
            formData.append('tipo', this.tipoChamado);
            formData.append('conta_id', this.formChamado.formGroup.value.conta_id);
            formData.append('url', this.formChamado.formGroup.value.url);

            this.loadings.abrindoChamado = true;

            this.userService.abrirChamado(formData).subscribe((res) => {

                this.loadings.abrindoChamado = false;

                this.fileListChamado = [];

                if (res.id) {
                    this.toastrService.success('Chamado aberto com sucesso: ' + res.id);
                    this.chamadoAberto = res.id;
                }

            }, (res) => {

                this.loadings.abrindoChamado = false;
                this.toastrService.error(res.error.message);
            });

        } else {

            this.toastrService.warning(mensagem);

        }


    }


    beforeUploadChamado = (file: any): boolean => {

        const fileTransform: NzUploadFile = file;

        this.fileListChamado = this.fileListChamado.concat(fileTransform);

        let uid = 0;

        this.fileListChamado.forEach((value) => {
            value.uid = (uid--).toString();
        });

        this.loadings.capturarTela = false;

        return false;

    }

    removerArquivo(file) {
        this.fileListChamado = this.fileListChamado.filter((value) => {
            return value.uid !== file.file.uid;
        });
    }

    outroChamado(): void {
        this.chamadoAberto = null;
        this.fileListChamado = [];
        this.tipoChamado = null;
        this.formChamado.formGroup.reset();
    }

    modalChamado(visible: boolean): void {

        this.chamadoAberto = null;
        this.fileListChamado = [];
        this.tipoChamado = null;
        this.formChamado.formGroup.reset();
        this.formChamado.modalVisible = visible;

        if (visible) {
            this.screenShotFunction();
        }
    }

    goToLink(url: string) {
        window.open(url, '_blank');

        // const mapForm = document.createElement('form');
        // mapForm.target = '_blank';
        // mapForm.method = 'POST';
        // mapForm.action = 'https://suporte.mastertax.com.br/Account/Authenticate/';
        //
        // // Token
        // const inputToken = document.createElement('input');
        // inputToken.type = 'text';
        // inputToken.name = 'token';
        // inputToken.value = '2d384c68-6b5e-4812-8aaf-e34eb16c813';
        // mapForm.appendChild(inputToken);
        //
        // // Usuario
        // const inputUser = document.createElement('input');
        // inputUser.type = 'text';
        // inputUser.name = 'userName';
        // inputUser.value = '41378898800';
        // mapForm.appendChild(inputUser);
        //
        // // Senha
        // const inputPass = document.createElement('input');
        // inputPass.type = 'password';
        // inputPass.name = 'password';
        // inputPass.value = 'auditto2020';
        // mapForm.appendChild(inputPass);
        //
        // document.body.appendChild(mapForm);
        //
        // mapForm.submit();


    }

    protected readonly event = event;
}

