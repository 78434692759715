<nz-page-header class="site-page-header" nzTitle="Notas"
                nzSubtitle="NFE - Terceiros">
</nz-page-header>
<nz-content>
    <ng-template #indicatorTemplate>
        <i class="loading-icon" nz-icon nzType="loading"></i>
    </ng-template>
    <nz-spin [nzSpinning]="loadings.nota" [nzSize]="'large'" nzSimple [nzIndicator]="indicatorTemplate"></nz-spin>
    <div nz-row [nzGutter]="16" *ngIf="!loadings.nota">
        <div nz-col nzXs="24" nzSm="24" nzMd="6" nzLg="6" nzXl="6">
            <nz-card>
                <nz-card-meta [nzTitle]="'XML/Nota Fiscal (NFe)'"></nz-card-meta>
                <nz-divider></nz-divider>
                <nz-list [nzSize]="'small'">
                    <nz-list>
                        <nz-list-item>
                            <nz-list-item-meta nzDescription="Armazenamento">
                                <nz-list-item-meta-title>
                                    {{ notaFiscal.armazenamentoData ? (notaFiscal.armazenamentoData | date:'dd/MM/YY - HH:mm:ss') : '-' }}
                                </nz-list-item-meta-title>
                            </nz-list-item-meta>
                        </nz-list-item>
                    </nz-list>
                    <nz-list>
                        <nz-list-item>
                            <nz-list-item-meta nzDescription="Processamento de Itens">
                                <nz-list-item-meta-title>
                                    {{ notaFiscal.itensProcessadosData ? (notaFiscal.itensProcessadosData | date:'dd/MM/YY - HH:mm:ss') : '-' }}
                                </nz-list-item-meta-title>
                            </nz-list-item-meta>
                        </nz-list-item>
                    </nz-list>
                    <nz-list>
                        <nz-list-item>
                            <nz-list-item-meta nzDescription="Última Validação">
                                <nz-list-item-meta-title>
                                    {{ notaFiscal.validacaoData ? (notaFiscal.validacaoData | date:'dd/MM/YY - HH:mm:ss') : '-' }}
                                </nz-list-item-meta-title>
                            </nz-list-item-meta>
                        </nz-list-item>
                    </nz-list>
                </nz-list>
                <nz-list [nzSize]="'small'">
                    <nz-list>
                        <nz-list-item>
                            <nz-list-item-meta nzDescription="Indicador Do Emitente">
                                <nz-list-item-meta-title>
                                    {{ notaFiscal.emitenteDocumento ? (notaFiscal.emitenteDocumento | cpfCnpj) : '-' }}
                                </nz-list-item-meta-title>
                            </nz-list-item-meta>
                        </nz-list-item>
                    </nz-list>
                    <nz-list>
                        <nz-list-item>
                            <nz-list-item-meta nzDescription="Marcadores">
                                <nz-list-item-meta-title>
                                    <div class="boxMarcadores">
                                <span nz-button class="marcador"
                                      [ngStyle]="{color: '#fff', 'background-color': m.cor}"
                                      *ngFor="let m of notaFiscal?.marcadores">
                                    {{ loadingRemoverMarcador[m.id] ? 'Removendo...' : m.marcador }}
                                </span>
                                    </div>
                                </nz-list-item-meta-title>
                            </nz-list-item-meta>
                        </nz-list-item>
                    </nz-list>
                    <nz-list>
                        <nz-list-item>
                            <nz-list-item-meta nzDescription="Data Entrada">
                                <nz-list-item-meta-title>
                                    {{ notaFiscal.entradaData ? (notaFiscal.entradaData | date:'dd/MM/YY - HH:mm:ss') : '-' }}
                                </nz-list-item-meta-title>
                            </nz-list-item-meta>
                        </nz-list-item>
                    </nz-list>
                    <nz-list>
                        <nz-list-item>
                            <nz-list-item-meta nzDescription="Manifestação Destinatário">
                                <nz-list-item-meta-title>
                                    {{ notaFiscal.manifestacaoData ? (notaFiscal.manifestacaoData | date:'dd/MM/YY - HH:mm:ss') : '-' }}
                                </nz-list-item-meta-title>
                            </nz-list-item-meta>
                        </nz-list-item>
                    </nz-list>
                    <nz-list>
                        <nz-list-item>
                            <nz-list-item-meta nzDescription="Workflow">
                                <nz-list-item-meta-title>
                                    {{ notaFiscal.status_workflow || '-' }}
                                </nz-list-item-meta-title>
                            </nz-list-item-meta>
                        </nz-list-item>
                    </nz-list>
                </nz-list>
            </nz-card>
            <!--            <nz-affix [nzOffsetTop]="offsetTop" [nzTarget]="target">-->
            <!--                <nz-anchor class="transparent-anchor" [nzAffix]="false" [nzShowInkInFixed]="true"-->
            <!--                           [nzContainer]="target">-->
            <!--                    <nz-link nzHref="#st1" nzTitle="Informações da NFe"></nz-link>-->
            <!--                    <nz-link nzHref="#st2" nzTitle="Workflow"></nz-link>-->
            <!--                </nz-anchor>-->
            <!--            </nz-affix>-->
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="18" nzLg="18" nzXl="18" style="padding-bottom: 200px;">
            <nz-card id="st1" nzTitle="Informações da NFe" [nzExtra]="extraInformacoes">
                <p>
                    <span class="chave">Chave:</span> <span>{{ notaFiscal.chave || '-' }}</span>
                    <br/>
                    <span class="chave">Emitente:</span>
                    <span>{{ (notaFiscal.emitenteDocumento | cpfCnpj) || '-' }}</span>
                    <br/>
                    <span class="chave">Destinatário:</span>
                    <span>{{ (notaFiscal.conta_id | cpfCnpj) || '-' }}</span>
                    <br/>
                    <br/>
                    <span class="chave">Emissão:</span>
                    <span>{{ (notaFiscal.emissaoData | date: 'dd/MM/yyyy HH:mm') || '-' }}</span>
                    <br/>
                    <span class="chave">Situação:</span>
                    <span *ngIf="notaFiscal.protocoloData else semDataProtocolo">
                       {{ notaFiscal.status_id === '100' ? 'Autorizada em ' : 'Cancelado em ' }}  {{ notaFiscal.protocoloData | date: 'dd/MM/yyyy HH:mm' }}
                    </span>
                    <ng-template #semDataProtocolo>
                        <span>{{ statusDescricao || '-' }}</span>
                    </ng-template>
                    <br/>
                    <span class="chave">Valor:</span>
                    <span>{{ (notaFiscal.valor | currency: 'BRL') || '-' }}</span>
                    <br/>
                    <br/>
                    <span class="chave">Modelo:</span> <span>{{ modeloDescricao[notaFiscal.modelo_id] || '-' }}</span>
                    <br/>
                    <span class="chave">Série/Número:</span> <span>{{ notaFiscal.serie || '-' }}</span>
                    <br/>
                    <span class="chave">Municipio:</span> <span>{{ notaFiscal.destinatarioCidade || '-' }}</span>
                    <br/>
                    <span class="chave">Natureza:</span> <span>{{ notaFiscal.natureza || '-' }}</span>
                    <br/>
                    <span class="chave">Tipo:</span> <span>{{ tipo[notaFiscal.tipo] || '-' }}</span>
                    <br/>
                    <span class="chave">Tipo Emissão:</span>
                    <span>{{ tipoEmissaoArray[notaFiscal.emissaoTipo] || '-' }}</span>
                    <br/>
                    <br/>
                    <span class="chave">Inf. Complementar:</span> <span>{{ notaFiscal.infCpl || '-' }}</span>
                    <br/>
                </p>
            </nz-card>
            <ng-template #extraInformacoes>
                <button style="margin-right: 10px" nz-button nzType="primary" nz-tooltip="true"
                        nzTooltipTitle="Visualizar DANFE" [nzLoading]="loadings.danfe"
                        (click)="downloadDanfe(notaFiscal)">
                    DANFE
                </button>
                <button nz-button nzType="primary" nz-tooltip="true"
                        nzTooltipTitle="Download XML" [nzLoading]="loadings.xml"
                        (click)="downloadXml(notaFiscal)">
                    XML
                </button>
            </ng-template>
            <nz-card id="st2">

                <nz-card-tab>
                    <nz-tabset nzSize="large" class="tabs" [(nzSelectedIndex)]="tabNumber"
                               [nzTabBarExtraContent]="extra"
                               (nzSelectChange)="changeTabs($event)">
                        <nz-tab nzTitle="Marcadores"></nz-tab>
                        <nz-tab nzTitle="Workflow"></nz-tab>
                    </nz-tabset>
                </nz-card-tab>

                <div *ngIf="tabNumber === 0">

                    <nz-table #tableMarcadores
                              [nzLoading]="loadings.tableMarcadores"
                              [nzData]="notaFiscal.marcadores"
                              [nzFrontPagination]="false"
                              class="table-small">
                        <thead>
                        <tr>
                            <th>Marcador</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let m of tableMarcadores.data">
                            <td>
                                <nz-badge [nzColor]="m.cor"></nz-badge>
                                {{ m.marcador }}
                            </td>
                            <td nzAlign="center">
                                <div style="width: 230px; text-align: center; margin: 0 auto">
                                    <a [nzLoading]="loadingRemoverMarcador[m.marcador]" nz-button nzType="link"
                                       [nz-tooltip]="'Remover marcador'"
                                       (click)="removerMacador(m.marcador)">Remover</a>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </nz-table>

                </div>


                <div *ngIf="tabNumber === 1">

                    <div style="padding: 0px 6px 40px 6px;">
                        <span style="color: rgba(0,0,0,0.6); font-size: 1.2em; margin-right: 4%">Etapa Atual:</span>
                        <span style="color: #ffa646; font-size: 2em">{{ etapaAtual?.descricao }}</span>
                    </div>

                    <nz-table #tableWorkflow
                              [nzLoading]="loadings.notaW"
                              [nzData]="notaFiscal.etapas_workflow"
                              [nzFrontPagination]="false"
                              class="table-small">
                        <thead>
                        <tr>
                            <th>Data e Hora</th>
                            <th>Etapa</th>
                            <th>Observação</th>
                            <th>Úsuario</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let w of tableWorkflow.data">
                            <td>{{ (w?.data | date: 'dd/MM HH:mm') || '-' }}
                            <td>{{ w.etapa }}</td>
                            <td>{{ w.observacao }}</td>
                            <td>{{ w.usuario }}</td>
                        </tr>
                        </tbody>
                    </nz-table>

                </div>


            </nz-card>
            <ng-template #extra>
                <button *ngIf="tabNumber === 0"
                        nz-button
                        nzType="primary" (click)="showModalAdicionarMarcador(true)">Adicionar
                </button>
                <button *ngIf="tabNumber === 1" [disabled]="disabledAlterar"
                        nz-button
                        nzType="primary" (click)="showModalAlterar()">Alterar
                </button>
            </ng-template>
        </div>
    </div>

</nz-content>
<nz-modal [(nzVisible)]="formNota.modalVisible" [nzTitle]="'Alterar Etapa'" [nzClosable]="true"
          (nzOnCancel)="showModalAlterar()">
    <div nz-row *nzModalContent>
        <form nz-form [nzAutoTips]="autoTips" [nzNoColon]="true" [formGroup]="formNota.formGroup">
            <nz-form-item>
                <nz-form-label [nzSpan]="7" nzRequired>Etapa</nz-form-label>
                <nz-form-control [nzSpan]="15">
                    <nz-select nzShowSearch style="min-width: 300px"
                               nzAllowClear
                               [(ngModel)]="statusSelecionado"
                               (ngModelChange)="changeStatus()"
                               nzPlaceHolder="Selecione"
                               formControlName="etapa_id">
                        <nz-option *ngFor="let e of etapasPossiveisParaEtapaAtual" [nzLabel]="e.descricao"
                                   [nzValue]="e.id"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item *ngIf="showMotivo">
                <nz-form-label nzRequired [nzSpan]="7">Motivo</nz-form-label>
                <nz-form-control [nzSpan]="15">
                    <nz-textarea-count [nzMaxCharacterCount]="100" style="min-width: 300px">
                        <textarea rows="3" minlength="15" maxlength="100" nz-input formControlName="motivo"
                                  style="min-width: 300px"
                                  placeholder="Informe um motivo com no mínimo 15 caracteres...">
                        </textarea>
                    </nz-textarea-count>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item *ngIf="notaFiscal.observacao">
                <nz-form-label nzRequired [nzSpan]="7">Observação</nz-form-label>
                <nz-form-control [nzSpan]="15">
                    <nz-textarea-count [nzMaxCharacterCount]="25" style="min-width: 300px">
                            <textarea rows="3" maxlength="25" nz-input formControlName="observacao"
                                      style="min-width: 300px"
                                      placeholder="Informe uma observação com no máximo 25 caracteres...">
                            </textarea>
                    </nz-textarea-count>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="showModalAlterar()">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="loadings.alterandoNota" (click)="alterarNota()">Confirmar
        </button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formAddMarcador.modalVisible" [nzTitle]="'Adicionar Marcador'" [nzClosable]="true"
          (nzOnCancel)="showModalAdicionarMarcador(false)" [nzWidth]="500">
    <div nz-row *nzModalContent>
        <form nz-form [nzAutoTips]="autoTips" [nzNoColon]="true" [formGroup]="formAddMarcador.formGroup">
            <nz-form-item>
                <nz-form-label [nzSpan]="7" nzRequired>Marcador</nz-form-label>
                <nz-form-control [nzSpan]="15">
                    <nz-select nzShowSearch style="min-width: 300px"
                               nzAllowClear
                               nzPlaceHolder="Selecione"
                               formControlName="marcador_id">
                        <nz-option *ngFor="let m of marcadores" [nzLabel]="m.marcador"
                                   [nzValue]="m.marcador"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="showModalNovoMarcador(true)">
            Novo marcador
        </button>
        <button nz-button nzType="link" (click)="showModalAdicionarMarcador(false)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="loadings.addMarcador"
                (click)="novoMarcador(this.formAddMarcador.formGroup.get('marcador_id').value)">Confirmar
        </button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="modalNovoMarcador"
          [nzTitle]="'Criar Novo Marcador'" [nzClosable]="true"
          (nzOnCancel)="showModalNovoMarcador(false)"
          [nzWidth]="500">
    <div *nzModalContent>
        <div>
            <label style="margin: 0 15px 10px 30px">Marcador</label>
            <input style="width: 280px" nz-input [(ngModel)]="novoMarcadorDescricao"/>
        </div>
        <div style="margin-top: 15px; display: flex; align-items: center;">
            <label style="margin: 0 15px 0 65px;">Cor</label>
            <input style="width: 280px; border: 1px solid #d9d9d9; height: 35px" [(ngModel)]="corNovoMarcador"
                   type="color">
        </div>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="showModalNovoMarcador(false);">Cancelar</button>
        <button [nzLoading]="this.loadings.addMarcador" nz-button nzType="primary" (click)="novoMarcador()">Confirmar
        </button>
    </div>
</nz-modal>

