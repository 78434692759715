<nz-page-header class="site-page-header" nzTitle="Drive" nzSubtitle="Transferências de Arquivos">
    <nz-page-header-extra>
        <nz-button-group>
            <button nz-button nzType="primary" nzGhost
                    (click)="showHideModal(formUpload, true)"
                    style="margin-right: 10px; border-radius: 0.45rem;">
                <div *ngIf="!uploading else acompanhar">
                    <i nz-icon nzType="upload"></i>
                    Novo upload
                </div>
                <ng-template #acompanhar>
                    <div nz-tooltip="Ver detalhes">
                        Upload em andamento... {{ progress[0] }}%
                    </div>
                </ng-template>

            </button>
            <button nz-button nzType="primary" nzGhost
                    style="border-radius: 0.45rem;"
                    (click)="atualizar();">
                <i class="fas fa-redo" style="margin-right: 5px;"></i>
                Atualizar
            </button>
        </nz-button-group>
    </nz-page-header-extra>
</nz-page-header>
<nz-alert nzType="info" nzShowIcon [nzMessage]="checkedMessage" style=" margin: 15px !important;">
    <ng-template #checkedMessage>
        <div nz-row nzAlign="middle" nzGutter="24">
            <div nz-col nzSpan="24">
                <strong class="text-primary"></strong>
                Atenção: Os arquivos ficam disponíveis para download por até 90 dias. Após esse período eles serão
                excluídos automaticamente.
            </div>
        </div>
    </ng-template>
</nz-alert>
<nz-content>
    <nz-row nzGutter="6">
        <nz-col nzSpan="4">
            <nz-card [ngClass]="[!loadingCardPasta ? 'ant-card-table' : '']" [nzLoading]="loadingCardPasta">
                <nz-tabset nzSize="large" nzTabPosition="left" class="tab-tipos" [(nzSelectedIndex)]="tabTributoNumber"
                           (nzSelectChange)="changeTabs($event)">
                    <nz-tab nzTitle="{{tabTipo.label}}" *ngFor="let tabTipo of tabsTipo"
                            (nzClick)="retornaArquivosTransitorios(tabTipo.id, pastaStatus, null, true)"></nz-tab>
                </nz-tabset>
            </nz-card>
        </nz-col>
        <nz-col nzSpan="20">
            <nz-card [ngClass]="[!loadingCardTable ? 'ant-card-table' : '']" [nzLoading]="loadingCardTable">
                <nz-card-tab>
                    <nz-tabset nzSize="large" [nzTabBarExtraContent]="tabsExtra" [(nzSelectedIndex)]="tabStatusNumber"
                               (nzSelectChange)="changeTabsStatus($event)">
                        <nz-tab nzTitle="{{tabStatus.label}}" *ngFor="let tabStatus of tabsStatus"
                                (nzClick)="retornaArquivosTransitorios(pastaTipo, tabStatus.pasta, null, true)"></nz-tab>
                    </nz-tabset>
                </nz-card-tab>
                <nz-alert nzType="info" nzShowIcon [nzMessage]="checkedMessage" style="margin: 15px !important;"
                          *ngIf="checkedItems.size > 0">
                    <ng-template #checkedMessage>
                        <div nz-row nzAlign="middle" nzGutter="24">
                            <div nz-col nzSpan="24">
                                <strong class="text-primary"></strong>
                                {{ checkedItems.size }} Registros Selecionados
                            </div>
                        </div>
                    </ng-template>
                </nz-alert>
                <nz-table #rowSelectionTable
                          [nzData]="listOfData"
                          [nzShowTotal]="rangeTemplate"
                          [nzPageSize]="100"
                          class="table-small">
                    <thead>
                    <tr>
                        <th style="text-align: center;">
                            <label nz-checkbox [(ngModel)]="checked" [nzIndeterminate]="indeterminate"
                                   (nzCheckedChange)="onAllChecked($event)"></label>
                        </th>
                        <th *ngFor="let column of colunasTabelaArquivos"
                            [nzSortPriority]="column.priority"
                            [nzSortOrder]="column.sortOrder"
                            [nzSortFn]="column.sortFn"
                            [nzSortDirections]="column.sortDirections">
                            {{ column.title }}
                        </th>
                        <th nzAlign="center" *ngIf="this.pastaStatus === 'processar'"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let data of rowSelectionTable.data">
                        <td nzLeft (nzCheckedChange)="onItemChecked(data.arquivo, $event)"
                            [nzChecked]="checkedItems.has(data.arquivo)"
                            [nzDisabled]="data.disabled">
                        </td>
                        <td>
                            <span style="word-break: break-word; max-width: 250px;">{{ data.arquivo }}</span>
                        </td>
                        <td *ngIf="this.pastaStatus === 'rejeitados' || this.pastaStatus === 'processados'">
                            <span style="word-break: break-word; max-width: 250px;">{{ data.motivo ? data.motivo : '-' }}</span>
                        </td>
                        <td *ngIf="this.pastaStatus === 'processados'">{{ (data.cnpj | cpfCnpj) || '-' }}</td>
                        <td>{{ data.caminho }}</td>
                        <td nzAlign="right">{{ formatBytes(data.tamanho, 2) }}</td>
                        <td nzAlign="center">{{ data.data | date:'dd/MM/yyyy HH:mm:ss' }}</td>
                        <td nzAlign="center" *ngIf="this.pastaStatus === 'processar'">

                            <a nz-button nzType="link" [nz-tooltip]="'Processar'" (click)="reprocessarArquivo(data)"
                               [nzLoading]="loadingsProcessar[data.arquivo]">
                                <span nz-icon nzType="reload" nzTheme="outline"></span>
                            </a>
                        </td>
                    </tr>
                    </tbody>
                </nz-table>
                <ng-template #rangeTemplate let-range="range" let-total>
                    {{ range[0] }}-{{ range[1] }} de {{ listOfDataTotal }} Registros
                </ng-template>
            </nz-card>
        </nz-col>
    </nz-row>
</nz-content>

<ng-template #tabsExtra>
    <div class="d-flex align-items-center">
        <nz-badge [nzCount]="qtdFiltrosAtivos" *ngIf="pastaStatus == 'rejeitados'" style="margin-right: 10px;"
                  [nzStyle]="{ backgroundColor: '#52c41a' }">
            <button *ngIf="pastaStatus == 'rejeitados'" nz-button nzType="primary"
                    (click)="abrirModal(formFiltros)">
                <i nz-icon nzType="search"></i>
                Filtros
            </button>
        </nz-badge>
        <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" [nzSuffix]="inputClearTpl"
                        style="margin-right: 10px;">
            <input (keydown.enter)="buscar()" type="text" nz-input placeholder="Busca" [(ngModel)]="currentSearch"/>
        </nz-input-group>
        <ng-template #suffixIconButton>
            <button nz-button nzType="primary" nzSearch
                    (click)="buscar()"><i
                    nz-icon nzType="search"></i></button>
        </ng-template>
        <ng-template #inputClearTpl>
              <span
                      nz-icon
                      class="ant-input-clear-icon"
                      nzTheme="fill"
                      nzType="close-circle"
                      *ngIf="currentSearch"
                      (click)="currentSearch = null"
              ></span>
        </ng-template>
        <!-- *ngIf="pastaStatus !== 'processar'" -->
        <button nz-button nzType="default"
                [nz-tooltip]="'Reprocessar'"
                (click)="showConfirmReprocessar()"
                style="margin-right: 10px;">
            <i nz-icon nzType="reload" nzTheme="outline"></i>
        </button>
        <button [nz-tooltip]="'Remover'" nz-button nzType="default" (click)="showConfirmRemover()">
            <i nz-icon nzType="delete" nzTheme="outline"></i>
        </button>
    </div>
</ng-template>
<nz-modal [(nzVisible)]="formUpload.modalVisible"
          [nzTitle]="'Upload de Arquivo'" [nzClosable]="true"
          (nzOnCancel)="showHideModal(formUpload, false)"
          [nzWidth]="748">
    <div *nzModalContent>
        <form nz-form [nzAutoTips]="autoTips" [nzNoColon]="false" [formGroup]="formSistema.formGroup">
            <nz-form-item *ngIf="!uploading">
                <nz-form-label nzRequired [nzSpan]="6">Tipo</nz-form-label>
                <nz-form-control [nzSpan]="12">
                    <nz-select [(ngModel)]="filtroTipos"
                               [nzPlaceHolder]="'Selecione'"
                               nzAllowClear
                               formControlName="tipo">
                        <nz-option *ngFor="let opt of arrayFiltrosTipos"
                                   [nzLabel]="opt.label"
                                   [nzValue]="opt.id">
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </form>
        <ng-template #uploadingProgres>
            <div>
                <h3 style="display: inline-block; margin-bottom: 10px">
                    Enviando {{ progress.length }} arquivo{{ progress.length > 1 ? 's' : '' }}
                </h3>
                <nz-progress [nzPercent]="progress[0]"></nz-progress>
                <div style="margin-top: 10px; overflow-y: auto; max-height: 150px">
                    <p *ngFor="let progress of progress; let i = index">
                        {{ fileList[i]?.name }}
                    </p>
                </div>
            </div>
        </ng-template>
        <nz-upload
                *ngIf="!uploading else uploadingProgres"
                [nzAction]="'#'"
                [(nzFileList)]="fileList"
                [nzCustomRequest]="customRequest"
                [nzBeforeUpload]="beforeUpload"
                [nzShowUploadList]="{ showRemoveIcon: true }"
                nzListType="text"
                nzType="drag"
                [nzMultiple]="true"
                (nzChange)="handleChange($event)"
        >
            <p class="ant-upload-drag-icon">
                <i nz-icon nzType="inbox"></i>
            </p>
            <p class="ant-upload-text">
                Clique ou arraste o(s) arquivo(s) para esta área para fazer o upload
            </p>
        </nz-upload>
    </div>
    <div *nzModalFooter>
        <button *ngIf="uploading" nz-button nzType="link" (click)="cancelUpload()">
            Cancelar envio
        </button>
        <button nz-button nzType="default" (click)="showHideModal(formUpload, false)">
            Fechar
        </button>
        <button nz-button
                [nzType]="'primary'"
                [nzLoading]="uploading"
                (click)="startUpload()"
                [disabled]="fileList.length === 0">
            Enviar
        </button>
    </div>
</nz-modal>


<nz-modal [(nzVisible)]="formFiltros.modalVisible"
          [nzTitle]="'Filtros avançados'" [nzClosable]="true"
          (nzOnCancel)="fechar(formFiltros)"
          [nzWidth]="720">
    <nz-row *nzModalContent>
        <nz-col [nzSpan]="24">
            <form nz-form [nzNoColon]="false" [formGroup]="formFiltros.formGroup">
                <nz-form-item>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="3">Motivo</nz-form-label>
                    <nz-form-control [nzSpan]="18" size="60" nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltros.formGroup.value.motivo">
                        <nz-select nzShowSearch nzAllowClear
                                   nzPlaceHolder="Selecione"
                                   [(ngModel)]="formFiltros.formGroup.value.motivo"
                                   formControlName="motivo">
                            <nz-option *ngFor="let opt of arraySelectMotivos"
                                       [nzLabel]="opt"
                                       [nzValue]="opt"
                            ></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
            </form>
        </nz-col>
    </nz-row>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="fechar(formFiltros)">Cancelar</button>
        <button nz-button nzType="primary" (click)="filtrar()">Filtrar</button>
    </div>
</nz-modal>
