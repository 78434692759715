import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {Tab} from '@models/tab.model';
import {TabService} from '@services/tab.service';
import {buildUrl, findComponentByUrl} from '../../../shared/components-helper';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import {Response} from '@models/response.model';
import Visibilidade from '@models/visibilidade.model';
import {Perfil} from '@models/perfil.model';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {Pagination} from '@models/pagination.model';
import {NzModalService} from 'ng-zorro-antd/modal';
import {ArmazenamentosDetalhesService} from './armazenamentosDetalhes.service';
import {DataService} from '@services/data.service';
import * as fileSaver from 'file-saver-es';
import {environment} from '../../../../environments/environment';

interface FormStack {
    modalVisible: boolean;
    formGroup: UntypedFormGroup;
}

@Component({
    selector: 'app-armazenamentos-detalhes',
    templateUrl: './armazenamentosDetalhes.component.html',
    styleUrls: ['./armazenamentosDetalhes.component.scss']
})


export class ArmazenamentosDetalhesComponent extends AbstractListTable<null> implements OnInit, AfterViewInit {

    readonly registerLink = '/usuarios/cadastrar';

    checked = false;

    currentParams: NzTableQueryParams = {
        pageIndex: 1,
        pageSize: 50,
        sort: [],
        filter: []
    };

    dataProfileForm: Perfil[];
    displayProfileModal = false;
    selectedProfile;

    newVisibilidadeModal = false;
    displayVisibilidadeModal = false;
    equipeOptions: { label: string; value: string }[] = [];
    limparEquipes = false;
    newEquipe = '';
    dataVisibilityForm: Response<Visibilidade>;

    token;
    exportUrl;

    modalFiltrarVisible = false;
    formFiltrar: UntypedFormGroup;
    cadastrando = false;

    expandSet = new Set<number>();

    column1: ElementRef;
    column1Width = 0;
    column2Width = 0;
    column3Width = 0;
    column4Width = 0;
    column5Width = 0;

    loadingContadores = true;
    loadingExport = [];

    dadosCabecalho: any = {};

    formFiltrosCabecalho: FormStack;
    formFiltrosGrid: FormStack;
    filtroEmpresaID: '';

    anoAtivo = 0;
    mesAtivo = 0;

    arrayAno = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai',
        'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];

    arraySelectObrigacoes = [];
    arraySelectEmpresa = [];
    arraySelectCnpj = [];
    arraySelectUsuarios = [];
    arraySelectGrupos = [];
    arraySelectOrigem = [];

    qtdFiltrosAtivos = 0;
    qtdFiltrosCabecalho = 0;

    @Input() data: any;

    situacao = null;

    arraySelectCategorias = [];
    currentUser;

    conciliacoesFlag = {};

    loadingProcessandoConciliacoes = false;

    constructor(
        private fb: UntypedFormBuilder,
        private service: ArmazenamentosDetalhesService,
        private toastService: ToastrService,
        private cdr: ChangeDetectorRef,
        private modalService: NzModalService,
        private el: ElementRef,
        private tabService: TabService,
        private dataService: DataService) {
        super(service, null, toastService);

        this.formFiltrosCabecalho = {
            modalVisible: false,
            formGroup: this.fb.group({
                vencimento: [null, null],
                obrigacao: [null, null],
                categoria: [null, null],
            })
        };
        this.formFiltrosGrid = {
            modalVisible: false,
            formGroup: this.fb.group({
                grupo: [null, null],
                empresa_id: [null, null],
                competencia: [null, null],
                situacao: [null, null],
                origem: [null, null],
                usuario: [null, null],
            })
        };

        this.service.retornaSelectsEmpresas().subscribe((retorno: any) => {
            this.arraySelectEmpresa = retorno.empresaNome;
            this.arraySelectCnpj = retorno.empresaCnpj;
        });

        this.service.retornaSelectsUsuarios().subscribe((retorno: any) => {
            this.arraySelectUsuarios = retorno;
        });

        this.service.retornaselectsGrupos().subscribe((retorno: any) => {
            this.arraySelectGrupos = retorno.grupoEmpresarial;
        });

        this.arraySelectOrigem.push({value: 'connect', label: 'Connect'});
        this.arraySelectOrigem.push({value: 'EDI', label: 'EDI'});
        this.arraySelectOrigem.push({value: 'Upload', label: 'upload'});

    }

    ngOnInit() {
        if (this.data?.categoria) {
            this.formFiltrosCabecalho.formGroup.get('categoria').setValue(this.data.categoria);
        }
    }

    ngAfterViewInit() {

        this.dataService.currentUser.subscribe((data) => {
            this.currentUser = data;
            this.cdr.detectChanges();
        });

        this.decodeSearch([this.formFiltrosCabecalho.formGroup, this.formFiltrosGrid.formGroup]);

    }

    decodeSearch(forms) {

        this.retornaSelectObrigacoes(() => {

            forms.forEach((value) => {

                const urlParams: any = this.queryStringToJSON(window.location.href);

                const haveUrlParams = Object.entries(urlParams).length > 0;

                if (urlParams.competencia) {
                    const competencia = urlParams.competencia.split('-');
                    urlParams.competencia = new Date(competencia[0], Number(competencia[1]) - 1);
                }

                if (urlParams.vencimento) {
                    const vencimento = urlParams.vencimento.split('-');
                    urlParams.vencimento = new Date(vencimento[0], Number(vencimento[1]) - 1);
                }

                if (haveUrlParams) {
                    this.resolveParams(urlParams, value);
                }

                const dataParams: any = Object.entries(this.data).filter((element) => {
                    return element[0] !== 'link' && element[0] !== 'extra';
                });

                if (this.data.competencia && Object.prototype.toString.call(this.data.competencia) !== '[object Date]') {
                    const competencia = this.data.competencia.split('-');
                    this.data.competencia = new Date(competencia[0], Number(competencia[1]) - 1);
                }

                if (this.data.vencimento && Object.prototype.toString.call(this.data.vencimento) !== '[object Date]') {
                    const vencimento = this.data.vencimento.split('-');
                    this.data.vencimento = new Date(vencimento[0], Number(vencimento[1]) - 1);
                }

                const haveDataParams = dataParams.length > 0;

                if (haveDataParams) {
                    this.resolveParams(this.data, value);
                }

                if (!haveUrlParams && !haveDataParams) {

                    const params = {
                        vencimento: new Date(),
                        categoria: this.arraySelectCategorias[0].id
                    };

                    this.resolveParams(params, value);

                }

            });

            this.carregaCabecalho();

        });

    }

    resolveParams(params, form) {

        Object.entries(params).forEach((value: any) => {

            const input = form.get(value[0]);

            if (input) {
                form.get(value[0]).setValue(value[1]);
            }

        });

    }

    queryStringToJSON(queryString) {

        if (queryString.indexOf('?') > -1) {
            queryString = queryString.split('?')[1];
        }

        const pairs = queryString.split('&');
        const result = {};

        pairs.forEach((pair) => {

            pair = pair.split('=');

            if (pair[0] !== window.location.href) {
                result[pair[0]] = decodeURIComponent(pair[1] || '');
            }

        });

        return result;
    }

    retornaSelectObrigacoes(callback) {

        this.service.retornaSelectsObrigacoes().subscribe((retorno: any) => {

            this.arraySelectObrigacoes = retorno.obrigacoes;
            this.arraySelectCategorias = retorno.categorias;

            this.arraySelectObrigacoes.sort((a, b) => {
                if (a.descricao > b.descricao) {
                    return 1;
                }
                if (a.descricao < b.descricao) {
                    return -1;
                }
                return 0;
            });

            this.arraySelectCategorias.sort((a, b) => {
                if (a.descricao > b.descricao) {
                    return 1;
                }
                if (a.descricao < b.descricao) {
                    return -1;
                }
                return 0;
            });


            callback();

        });

    }

    openTab(componentName: string, queryParams?: string, data?: {}) {
        const component = findComponentByUrl(componentName);
        const url = buildUrl(component, queryParams);
        const newTab = new Tab(component.name, component.title, url, component.urlType, data);
        this.tabService.closeAndAddTab(newTab);
    }

    listByTableArmazenamentos(params: NzTableQueryParams) {
        let buscar = false;

        if (params.pageIndex === this.pagination.current_page) {
            params.sort.forEach(s => {
                if (s.value) {
                    buscar = true;
                }
            });
        }

        if ((buscar || params.pageIndex === 1 && this.pagination.current_page > 1)
            || params.pageIndex > 1
            && params.pageIndex !== this.pagination.current_page) {
            this.queryTable(params);
            this.pagination.current_page = params.pageIndex;
        }

    }


    queryTable(params: NzTableQueryParams, search: string = null, busca = false): void {

        if (busca) {
            this.currentParams.pageIndex = 1;
        }

        this.loading = true;

        this.currentParams = params;

        this.currentSearch = search;

        this.calculaBadgeFiltros();


        this.currentParams.filter = [];

        const filtros = this.formFiltrosGrid.formGroup.value;

        for (const [chave, valor] of Object.entries(filtros)) {

            if (chave === 'competencia' && valor) {
                this.currentParams.filter.push({key: chave, value: this.service.formataCompetencia(valor)});
            }

            if (chave !== 'competencia' && valor) {
                this.currentParams.filter.push({key: chave, value: valor});
            }

        }

        const vencimento = new Date(this.formFiltrosCabecalho.formGroup.value.vencimento);

        let ano = null;
        let mes = null;

        if (vencimento) {
            ano = vencimento.getFullYear();
            mes = this.formatMonth(vencimento.getMonth() + 1);
        }

        let filtro;

        if (this.formFiltrosCabecalho.formGroup.value.categoria) {
            filtro = this.formFiltrosCabecalho.formGroup.value.categoria;
        }

        if (this.formFiltrosCabecalho.formGroup.value.obrigacao) {
            filtro = this.formFiltrosCabecalho.formGroup.value.obrigacao;
        }

        if (filtro) {
            const baseUrl = `${environment.apiUrl}/check/armazenamentos/${filtro}/${ano}/${mes}`;

            this.abstractService.listToTable(params, search, baseUrl).subscribe((response) => {

                this.checkedItems.clear();

                this.items = [];

                this.items = response.data || response;

                this.items.forEach((item) => {
                    this.expandSet.add(item.key);
                    item.tamanhoDescricao = item.arquivoTamanho ? this.formatSize(item.arquivoTamanho) : '0 B';
                });

                this.pagination = new Pagination(
                    response?.per_page || 50,
                    response?.current_page || 1,
                    response?.last_page || 1,
                    response?.total || 50);

                this.loading = false;

            });
        }

    }

    formatSize(size: number): string {
        if (!size || size < 0) {
            return '';
        }

        const mb = size / (1024 * 1024);
        const gb = size / (1024 * 1024 * 1024);

        if (gb >= 1) {
            return `${gb.toFixed(2)} GB`;
        } else if (mb >= 1) {
            return `${mb.toFixed(2)} MB`;
        } else {
            return `${size} B`;
        }
    }

    calculaBadgeFiltros(): void {

        this.qtdFiltrosAtivos = 0;

        if (typeof this.formFiltrosGrid !== 'undefined') {

            for (const [chave, valor] of Object.entries(this.formFiltrosGrid.formGroup.value)) {

                if (valor) {
                    this.qtdFiltrosAtivos++;
                }

            }

        }

    }

    calculaBadgeFiltrosCabecalho(): void {

        this.qtdFiltrosCabecalho = 0;

        if (typeof this.formFiltrosCabecalho !== 'undefined') {

            for (const [chave, valor] of Object.entries(this.formFiltrosCabecalho.formGroup.value)) {

                if (valor) {
                    this.qtdFiltrosCabecalho++;
                }

            }

        }

    }

    onExpandChange(id: number, checked: boolean): void {
        if (checked) {
            this.expandSet.add(id);
        } else {
            this.expandSet.delete(id);
        }
        this.cdr.detectChanges();
    }

    onAllChecked(checked: boolean): void {

        this.items.forEach((item) => {
            this.updateCheckedSet(item.id, checked);
        });

    }


    formatOne = (percent: number) => `${percent}%`;

    fecharModal(formulario: FormStack): void {
        formulario.modalVisible = false;
    }

    abrirModal(formulario: FormStack): void {
        formulario.modalVisible = true;
    }

    filtrarCabecalho(): any {
        this.currentParams.pageIndex = 1;

        this.carregaCabecalho();

        this.fecharModal(this.formFiltrosCabecalho);
    }

    filtrarGrid(): any {

        this.currentParams.pageIndex = 1;

        this.queryTable(this.currentParams, this.currentSearch);

        this.fecharModal(this.formFiltrosGrid);

    }

    carregaCabecalho(): void {

        this.loadingContadores = true;

        this.calculaBadgeFiltrosCabecalho();

        const vencimento = new Date(this.formFiltrosCabecalho.formGroup.value.vencimento);

        let ano = null;
        let mes = null;

        if (vencimento) {
            ano = vencimento.getFullYear();
            mes = this.formatMonth(vencimento.getMonth() + 1);
        }

        let filtro;

        if (this.formFiltrosCabecalho.formGroup.value.categoria) {
            filtro = this.formFiltrosCabecalho.formGroup.value.categoria;
        }

        if (this.formFiltrosCabecalho.formGroup.value.obrigacao) {
            filtro = this.formFiltrosCabecalho.formGroup.value.obrigacao;
        }

        this.service.retornarCabecalho(filtro, ano, mes).subscribe((response) => {

            this.dadosCabecalho.anoSelecionado = ano;
            this.dadosCabecalho.mesSelecionado = this.arrayAno[Number(mes) - 1].toUpperCase();

            this.dadosCabecalho.obrigacao = response.obrigacao_descricao;
            this.dadosCabecalho.orgao = response.orgao;
            this.dadosCabecalho.qtdUnidades = response.qtdUnidades;
            this.dadosCabecalho.qtdObrigacoes = response.qtdObrigacoes;
            this.dadosCabecalho.qtdArmazenadas = response.qtdArmazenadas;
            this.dadosCabecalho.qtdAusentes = response.qtdAusentes;

            this.loadingContadores = false;

            this.queryTable(this.currentParams, this.currentSearch);

        });

    }

    formatMonth(month) {

        if (month <= 9) {

            return '0' + month;

        } else {

            return month;

        }

    }

    modalFiltrar(visible: boolean): void {
        this.formFiltrosGrid.modalVisible = visible;
    }

    btnResetSearch() {

        this.checkedItems.clear();
        this.checked = false;

        this.currentSearch = null;

        this.currentParams = {
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            sort: [],
            filter: [],
        };

        this.formFiltrosGrid.formGroup.reset();

        this.queryTable(this.currentParams, this.currentSearch);

    }

    clearInput(form, emitter, target) {
        const inputEmitter = form.get(emitter);
        const inputTarget = form.get(target);
        if (inputEmitter.value) {
            inputTarget.setValue(null);
        }
    }

    showConfirm(): void {

        this.modalService.confirm({
            nzTitle: 'Deseja iniciar a validação para os arquivos selecionados?',
            nzOkText: 'Reprocessar',
            nzCancelText: 'Cancelar',
            nzOnOk: () => this.reprocessarRegistro()
        });

    }

    reprocessarRegistro() {

        this.toastService.info('Processamento iniciado.');

        const arrayMd5 = [];

        this.items.forEach(i => {
            if (this.checkedItems.has(i.id) && i.md5) {
                arrayMd5.push(i.md5);
            }
        });

        this.service.reprocessarRegistro(arrayMd5).subscribe((retorno: any) => {

            this.toastService.success(retorno.message);

        }, (retorno) => {

            this.toastrService.error(retorno.error.message);

        });

    }

    exportDocumento(id, arquivoNome) {

        this.loadingExport.push(id);

        this.service.exportDocumento(id, this.dadosCabecalho.orgao).subscribe((res) => {

            const blob = new Blob([res], {type: 'text/json; charset=utf-8'});
            fileSaver.saveAs(blob, arquivoNome);

            this.loadingExport = [];

        }, error => {
            this.loadingExport = [];
            this.toastService.error(error.error.message);
        });
    }

    filtrarCampo(form, campo, valor) {
        form.formGroup.get(campo).setValue(valor);
        this.queryTable(this.currentParams, this.currentSearch);
    }

    showConfirmConciliacao(): void {

        this.modalService.confirm({
            nzTitle: 'Deseja iniciar a conciliação para os arquivos selecionados?',
            nzOkText: 'Reprocessar',
            nzCancelText: 'Cancelar',
            nzOnOk: () => this.iniciarConciliacao()
        });

    }


    iniciarConciliacao() {

        const arrayMd5 = [];

        this.items.forEach(i => {
            if (this.checkedItems.has(i.id) && i.md5) {
                arrayMd5.push(i.md5);
            }
        });

        this.loadingProcessandoConciliacoes = true;

        this.service.iniciarConciliacao(arrayMd5).subscribe((retorno: any) => {

            this.toastService.success(retorno.message);

            this.loadingProcessandoConciliacoes = false;

            this.checkedItems.clear();

        }, (retorno) => {

            this.toastrService.error(retorno.error.message);

            this.loadingProcessandoConciliacoes = false;

        });

    }

    msgSemHistorico(): void {
        this.toastService.warning('Obrigação sem histórico');
    }

    onItemChecked(id: string, checked: boolean): void {

        this.updateCheckedSet(id, checked);

    }

    updateCheckedSet(id: string, checked: boolean): void {

        if (checked) {

            this.checkedItems.add(id);

        } else {

            this.checkedItems.delete(id);

        }

        if (this.items.length !== this.checkedItems.size) {
            this.checked = false;
        } else {
            this.checked = true;
        }
    }

    confirmaExportar(): void {

        const filters = this.currentParams.filter;

        for (const [chave, valor] of Object.entries(this.formFiltrosGrid.formGroup.value)) {

            if (chave === 'competencia' && valor) {
                filters.push({key: chave, value: this.service.formataCompetencia(valor)});
            }

            if (chave !== 'competencia' && valor) {
                filters.push({key: chave, value: valor});
            }

        }

        if (this.currentSearch) {
            filters.push({key: 'procurar', value: this.currentSearch});
        }

        const vencimento = new Date(this.formFiltrosCabecalho.formGroup.value.vencimento);

        if (vencimento) {
            filters.push(({key: 'ano', value: vencimento.getFullYear()}));
            filters.push(({key: 'mes', value: this.formatMonth(vencimento.getMonth() + 1)}));
        }

        if (this.formFiltrosCabecalho.formGroup.value.categoria) {
            filters.push(({key: 'obrigacao', value: this.formFiltrosCabecalho.formGroup.value.categoria}));
        } else if (this.formFiltrosCabecalho.formGroup.value.obrigacao) {
            filters.push(({key: 'obrigacao', value: this.formFiltrosCabecalho.formGroup.value.obrigacao}));
        }


        this.service.exportExcel(filters).subscribe((res) => {

            const message = res.message + ' Acompanhe na central de downloads.';

            this.toastService.success(message);

            this.openTab('/downloadCenter', '', {});

            this.cdr.detectChanges();


        }, (error) => {
            this.toastService.error(error.error.message);
        });
    }
}
