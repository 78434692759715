import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class InboxConfigService {

    constructor(protected http: HttpClient) {
    }


    getConfiguracao(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/inbox/configuracao/detalhe`);

    }

    cadastrarConfiguracao(data, idToUpdate: string): Observable<any> {

        const id = idToUpdate ? `/${idToUpdate}` : '';

        return this.http.post(`${environment.apiUrl}/inbox/configuracao/gravar${id}`, data);

    }
}
