import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {Utils} from '../../../shared/utils';
import {Tab} from '@models/tab.model';
import {TabService} from '@services/tab.service';
import {buildUrl, findComponentByUrl, getComponentConfigByName} from '../../../shared/components-helper';
import {TabHandlerInterface} from '../../../shared/interfaces/tab-handler.interface';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import {Response} from '@models/response.model';
import {Usuario} from '@models/usuario.model';
import {UsuarioService} from '@services/usuario.service';
import Visibilidade from '@models/visibilidade.model';
import {VisibilidadeService} from '@services/visibilidade.service';
import {Perfil} from '@models/perfil.model';
import {PerfilService} from '@services/perfil.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Pagination} from '@models/pagination.model';
import {CheckNotasService} from './checkNotas.service';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {ResponsaveisService} from '../../cadastros/responsaveis/responsaveis.service';

interface FormStack {
    modalVisible: boolean;
    formGroup: UntypedFormGroup;
}


@Component({
    selector: 'app-check-spedNotas',
    templateUrl: './checkNotas.component.html',
    styleUrls: ['./checkNotas.component.scss']
})
export class CheckNotasComponent extends AbstractListTable<any> implements OnInit, TabHandlerInterface {
    readonly registerLink = '/usuarios/cadastrar';

    dataProfileForm: Perfil[];
    profileOptions: { label: string; value: string }[] = [];
    displayProfileModal = false;
    selectedProfile;

    newVisibilidadeModal = false;
    displayVisibilidadeModal = false;
    visibilidadeOptions: { label: string; value: string }[] = [];
    selectedVisibilidades: string[] = [];
    limparVisibilidades = false;
    newVisibilidade = '';
    dataVisibilityForm: Response<Visibilidade>;

    token;
    exportUrl;

    modalCadastrarVisible = false;
    formCadastrar: FormStack;
    cadastrando = false;

    dadosLoading = true;

    formFiltrar: FormStack;

    arraySelectEmpresa = [];
    arraySelectGrupos = [];

    erro = false;
    mensagem = '';

    qtdFiltrosAtivos = 0;

    novoMarcadorDescricao = null;
    corNovoMarcador = '#000';

    modalNovoMarcador = false;

    arrayMarcadores = [];

    dataExport: any;

    formExportar = this.fb.group({
        tipoExportador: [null, Validators.required],
        status: [[]],
    });

    modalExportarVisible = false;
    loadingExportar = false;
    loadingAnalise = false;

    autoTips: Record<string, Record<string, string>> = {
        default: {
            required: 'Campo obrigatório',
        }
    };

    tiposAceitos = [
        {key: '.XLSX', value: 'xlsx'},
        {key: '.PDF', value: 'pdf'},
        {key: '.XML', value: 'xml'}
    ];

    statusAceitos = [
        {value: 'nao_encontradas', key: 'Não Encontradas'},
        {value: 'divergentes', key: 'Divergentes'},
        {value: 'nao_escrituradas', key: 'Não Escrituradas'}
    ];

    pagination: Pagination = {
        per_page: 10,
        current_page: 1,
        last_page: 0,
        total: 0,
        from: 0,
        to: 0
    };

    currentParams: NzTableQueryParams = {
        pageIndex: 1,
        pageSize: 50,
        sort: [],
        filter: [],
    };

    tiposNotas = [
        {label: 'Escrituradas corretamente', value: '0'},
        {label: 'Escrituradas incorretamente', value: '1'},
        {label: 'Não escrituradas', value: '2'},
        {label: 'XMLs Ausentes', value: '3'},
        {label: 'Divergência', value: '4'},
        {label: 'Desconsiderada', value: '5'},
    ];

    checkedItems = new Set<string>();
    checked = false;

    comboResponsaveis = [];

    verificando = false;

    constructor(
        private fb: UntypedFormBuilder,
        private usuarioService: UsuarioService,
        private toastService: ToastrService,
        private visibilidadeService: VisibilidadeService,
        private service: CheckNotasService,
        private perfilService: PerfilService,
        private cdr: ChangeDetectorRef,
        private responsaveisService: ResponsaveisService,
        private tabService: TabService) {
        super(usuarioService, Usuario, toastService);

        this.formFiltrar = {
            modalVisible: false,
            formGroup: this.fb.group({
                responsavel: [null, null],
                cnpj: [null, null],
                empresa_id: [null, null],
                dataInicio: [null, null],
                dataFim: [null, null],
                grupos: [null, null],
                marcador_id: [null, null],
                tipoNota: [null, null]
            })
        };

        this.formCadastrar = {
            modalVisible: false,
            formGroup: this.fb.group({
                empresa_id: [null, null],
                dataInicio: [null, null],
                dataFim: [null, null],
            })
        };


        this.service.retornarSelectsEmpresas(
        ).subscribe((retorno: any) => {
            this.arraySelectEmpresa = retorno.empresaNome;
        });

        responsaveisService.retornaComboResponsaveisEmail().subscribe((retorno: any) => {
            this.comboResponsaveis = retorno;
        });


    }

    ngOnInit() {
        this.getPerfis();
        this.retornaDados(this.currentParams);

        this.service.retornaComboGruposEmpresariais().subscribe((response) => {
            response.grupoEmpresarial.forEach((value) => {
                this.arraySelectGrupos.push({id: value.id, descricao: value.descricao});
            });
        });

        this.service.retornaComboGruposEmpresariais().subscribe((response) => {
            response.grupoEmpresarial.forEach((value) => {
                this.arraySelectGrupos.push({id: value.id, descricao: value.descricao});
            });
        });

        this.service.retornaComboGruposEmpresariais().subscribe((response) => {
            response.grupoEmpresarial.forEach((value) => {
                this.arraySelectGrupos.push({id: value.id, descricao: value.descricao});
            });
        });

        this.getMarcadores();
    }


    async getVisibilidades() {

        return this.visibilidadeService.listToSelect().subscribe((result) => {
            this.visibilidadeOptions = result.map((option: any) => ({
                label: option.descricao,
                value: option.id
            }));
        });
    }

    async getPerfis() {


        return this.perfilService.listToSelect().subscribe((result) => {
            this.profileOptions = result.map((option: any) => ({
                label: option.descricao,
                value: option.id
            }));
        });

    }

    onAlterarPerfilDeAcesso() {
        this.getPerfis();
        this.displayProfileModal = true;
    }

    onAlterarVisibilidadeColetiva() {
        this.getVisibilidades();
        this.selectedVisibilidades = [];
        this.displayVisibilidadeModal = true;
        this.refreshCheckedStatus();
    }

    saveBulkToVisibilities() {

        this.usuarioService.bulkAppend(this.checkedItems, this.selectedVisibilidades, this.limparVisibilidades, 'visibilidades')
            .subscribe(
                () => {
                    this.toastrService.success(`Alteração em massa realizada!`);
                    this.selectedVisibilidades = [];
                    this.displayVisibilidadeModal = false;
                    this.updateTable();
                },
                (response) => {
                    this.toastrService.error(response.error.message);
                }
            );
    }

    saveBulkToProfiles() {
        this.usuarioService.bulkChange(this.checkedItems, {perfil_id: this.selectedProfile, maria: true},
            'alteracao-em-massa').subscribe(
            () => {
                this.toastrService.success(`Alteração em massa realizada!`);
                this.selectedProfile = '';
                this.displayProfileModal = false;
                this.updateTable();
            },
            (response) => {
                this.toastrService.error(response.error.message);
            }
        );
    }

    handleCancel() {
        this.displayVisibilidadeModal = false;
        this.displayProfileModal = false;
    }

    addNewVisibilidade() {
        this.newVisibilidadeModal = true;
    }

    saveNewVisibilidade() {

        if (!Utils.isEmpty(this.newVisibilidade)) {

            this.visibilidadeService.save({descricao: this.newVisibilidade} as Visibilidade).subscribe((res: any) => {

                if (res.hasOwnProperty('success')) {
                    this.toastrService.success(res.success);
                    this.dataVisibilityForm.data.push(res.data);
                    this.selectedVisibilidades.push(res.data.id);
                    this.getVisibilidades();
                    this.newVisibilidadeModal = false;
                }

            }, (response) => {

                this.toastrService.error(response.error.message);
                this.newVisibilidadeModal = false;

            });
        }
    }

    closeNewVisibilidade() {
        this.newVisibilidadeModal = false;
    }

    getStatusLabel(status: number) {
        return status === 1 ? 'Ativo' : 'Inativo';
    }

    openTabByName(componentName: string, queryParams?: string, data?: {}) {
        const component = getComponentConfigByName(componentName);
        const url = buildUrl(component, queryParams);
        const newTab = new Tab(component.name, component.title, url, component.urlType, data);
        this.tabService.addTab(newTab);
    }

    openTab(componentName: string, queryParams?: string, data?: any) {
        const component = findComponentByUrl(componentName);
        const url = buildUrl(component, queryParams);
        const newTab = new Tab(component.name, component.title, url, component.urlType, data);
        this.tabService.closeAndAddTab(newTab);

    }

    modalCadastrar(visible) {

        this.formCadastrar.modalVisible = visible;
    }

    calculaBadgeFiltros(): void {
        let qtd = 0;

        if (this.formFiltrar.formGroup.value.empresa_id) {
            qtd += 2;
        }
        if (this.formFiltrar.formGroup.value.dataInicio) {
            qtd += 1;
        }
        if (this.formFiltrar.formGroup.value.dataFim) {
            qtd += 1;
        }
        if (this.formFiltrar.formGroup.value.grupos?.length) {
            qtd += 1;
        }
        if (this.formFiltrar.formGroup.value.responsavel) {
            qtd += 1;
        }
        if (this.formFiltrar.formGroup.value.marcador_id) {
            qtd += 1;
        }

        if (this.formFiltrar.formGroup.value.tipoNota) {
            qtd += 1;
        }

        this.qtdFiltrosAtivos = qtd;
    }

    retornaDados(params: any, loading: boolean = true): void {

        this.currentParams = params || {filter: []};

        this.dadosLoading = loading;

        this.calculaBadgeFiltros();

        const filtros = this.formFiltrar.formGroup.value;

        if (!this.formFiltrar.formGroup.value.grupos?.length) {
            delete filtros.grupos;
        }

        filtros.pagina = this.pagination?.current_page || 1;
        filtros.quantidade = 10;

        this.service.retornaDados(filtros).subscribe((response) => {

            this.items = [];
            this.items = response.data;

            this.items.forEach((value) => {

                value.porcentagemProcessamento = Math.round(this.getPercentage(value.processamentoPasso, 11));

                switch (value.statusProcessamento) {
                    case 0: {
                        value.statusDescricao = 'Aguardando início';
                        value.statusCor = 'default';
                        break;
                    }
                    case 1: {
                        value.statusDescricao = 'Processando';
                        value.statusCor = 'warning';
                        break;
                    }
                    case 2: {
                        value.statusDescricao = 'Concluída';
                        value.statusCor = 'success';
                        break;
                    }
                    case 3: {
                        value.statusDescricao = 'Erro!';
                        value.statusCor = 'orange';
                        break;
                    }
                    case 6: {
                        value.statusDescricao = 'Importando análises';
                        value.statusCor = 'geekblue';
                        break;
                    }
                }
            });
            this.pagination = new Pagination(
                response?.per_page || 10,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 50);

            this.dadosLoading = false;

            this.cdr.detectChanges();

            if (!this.verificando) {
                this.verificaProcessando();
            }

        });

        this.loading = false;
        this.cdr.detectChanges();

    }

    modalFiltrar(visible) {
        this.formFiltrar.modalVisible = visible;
    }

    confirmaFiltrar(): void {
        this.modalFiltrar(false);
        this.pagination.current_page = 1;

        this.retornaDados(this.currentParams);

    }

    confirmaCadastrar(): void {

        this.validaFormCadastrar();
        const dados = this.formCadastrar.formGroup.value;

        if (!this.erro) {
            this.loadingAnalise = true;
            this.service.solicitarAnalise(dados).subscribe((response) => {
                this.toastrService.success('Análise solicitada com sucesso.');
                this.modalCadastrar(false);
                this.retornaDados(this.currentParams);
                this.loadingAnalise = false;
            }, error => {
                this.loadingAnalise = false;
                this.toastService.error(error.error.message);
            });
        } else {
            this.toastrService.error(this.mensagem);
            this.erro = false;
            this.mensagem = '';
        }


    }

    validaFormCadastrar(): void {
        if (!this.formCadastrar.formGroup.value.empresa_id) {
            this.erro = true;
            this.mensagem = 'Campo Unidade Obrigatório';
        }
        if (!this.formCadastrar.formGroup.value.dataInicio) {
            this.erro = true;
            this.mensagem = 'Campo Data Início Obrigatório';
        }
        if (!this.formCadastrar.formGroup.value.dataFim) {
            this.erro = true;
            this.mensagem = 'Campo Data Fim Obrigatório';
        }


    }

    reprocessar(): void {
        const ids: any = [];

        this.checkedItems.forEach((value) => {
            ids.push(value);
        });

        this.service.reprocessar(ids).subscribe((response) => {
            this.toastrService.success('Análises solicitadas com sucesso.');
            this.checkedItems.clear();
            this.retornaDados(this.currentParams);
        });
    }

    remover(): void {
        const ids: any = [];

        this.checkedItems.forEach((value) => {
            ids.push(value);
        });

        this.service.remover(ids).subscribe((response) => {
            this.toastrService.success('Análises removidas com sucesso.');
            this.checkedItems.clear();
            this.retornaDados(this.currentParams);
        });
    }

    verificaProcessando(): void {


        setTimeout(() => {
            let temAndamento = false;
            this.items.forEach((value) => {

                if (value.statusProcessamento === 0 || value.statusProcessamento === 1 || value.statusProcessamento === 6) {
                    temAndamento = true;
                }
            });
            if (temAndamento) {
                this.verificando = true;
                this.retornaDados(this.currentParams, false);

                this.verificaProcessando();
            } else {
                this.verificando = false;
            }
        }, 15000);

    }

    closeSelecionados(): void {
        this.checkedItems.clear();

    }

    getPercentage(campo1: number, campo2: number): number {
        const maior: any = campo1 > campo2 ? campo1 : campo2;
        const menor: any = campo1 < campo2 ? campo1 : campo2;
        return (menor / maior) * 100;
    }

    showModalNovoMarcador(visible) {

        this.modalNovoMarcador = visible;
    }

    novoMarcador(existente: string = null): void {

        this.novoMarcadorDescricao = existente ? existente : this.novoMarcadorDescricao;

        if (this.novoMarcadorDescricao) {
            this.service.novoMarcador(this.novoMarcadorDescricao, this.corNovoMarcador, Array.from(this.checkedItems))
                .subscribe((response) => {
                    this.toastrService.success(response.message || 'Ação realizada com sucesso.');
                    this.checkedItems.clear();
                    this.retornaDados(this.currentParams);
                    this.novoMarcadorDescricao = null;
                    this.corNovoMarcador = '#000';
                    this.showModalNovoMarcador(false);
                    this.getMarcadores();
                }, error => {
                    this.toastService.error(error.error.message);
                });
        } else {
            this.toastService.error('Marcador inválido!');
        }
    }

    removerMacador(idMarcador, idAnalise) {

        const ids = idAnalise ? [idAnalise] : Array.from(this.checkedItems);

        this.service.removerMarcador(idMarcador, ids).subscribe((response) => {
            this.toastrService.success(response.message || 'Ação realizada com sucesso.');
            this.checkedItems.clear();
            this.retornaDados(this.currentParams);
            this.novoMarcadorDescricao = null;
        }, error => {
            this.toastService.error(error.error.message);
        });

    }

    modalExportar(visible: boolean): void {
        this.modalExportarVisible = visible;
    }

    confirmaExportar(): void {

        if (this.formExportar.valid) {

            this.loadingExportar = true;

            const body: any = {};
            Object.entries(this.formExportar.value).forEach(([key, value]) => {
                if (value) {
                    body[key] = value;
                }
            });

            body.tipo = 'complianceSpedNotas';
            body.ids = Array.from(this.checkedItems);

            this.service.exportExcel(body).subscribe((res) => {

                this.toastService.success('Solicitação realizada com sucesso. Acompanhe na central de downloads.');
                this.openTabByName('DownloadCenterComponent', '', {});
                this.modalExportarVisible = false;
                this.checkedItems.clear();
                this.loadingExportar = false;

            }, err => {
                this.loadingExportar = false;
                this.toastrService.error(err.error.message);
            });

        } else {

            for (const key in this.formExportar.controls) {

                if (key) {

                    const campo = this.formExportar.get(key);

                    campo.markAsDirty();
                    campo.updateValueAndValidity();

                }
            }
        }

    }

    changePage(index) {
        this.checkedItems.clear();
        this.pagination.current_page = index;
        this.retornaDados(this.currentParams);
    }

    getMarcadores() {
        this.service.getMarcadores().subscribe((response) => {
            this.arrayMarcadores = response;
        });
    }

    onAllChecked(checked: boolean): void {

        this.checked = checked;

        if (checked) {
            this.items.forEach(({id}) => this.updateCheckedSet(id, checked));
        } else {
            this.checkedItems.clear();
        }
    }

    onItemChecked(id: string, checked: boolean): void {

        this.updateCheckedSet(id, checked);

    }

    updateCheckedSet(id: string, checked: boolean): void {

        if (checked) {

            this.checkedItems.add(id);

        } else {

            this.checkedItems.delete(id);

        }

        this.checked = this.items.length === this.checkedItems.size;

        this.cdr.detectChanges();
    }


    deleteMarcador(marcador: string) {


        this.service.deleteMarcador(marcador)
            .subscribe((response) => {
                this.toastrService.success(response.message || 'Ação realizada com sucesso.');
                this.getMarcadores();
            }, error => {
                this.toastService.error(error.error.message);
            });

    }
}
