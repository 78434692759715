import {Component, OnInit} from '@angular/core';
import {Pagination} from '@models/pagination.model';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {RegrasAnaliseXmlSaidaService} from './regras-analise-xml-saida.service';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import {Usuario} from '@models/usuario.model';
import {ToastrService} from 'ngx-toastr';
import {Subscription} from 'rxjs';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {NzModalService} from 'ng-zorro-antd/modal';
import {NzUploadChangeParam, NzUploadFile} from 'ng-zorro-antd/upload';
import {environment} from '../../../../environments/environment';
import * as fileSaver from 'file-saver-es';

interface FormStack {
    modalVisible: boolean;
    formGroup: UntypedFormGroup;
}

@Component({
    selector: 'app-regras-analise-xml-saida',
    templateUrl: './regras-analise-xml-saida.component.html',
    styleUrls: ['./regras-analise-xml-saida.component.scss']
})
export class RegrasAnaliseXmlSaidaComponent extends AbstractListTable<Usuario> implements OnInit {

    pageSize = 50;
    pageIndex = 1;

    paginations: any = {
        icms: Pagination,
        pis: Pagination,
        cofins: Pagination,
        ipi: Pagination,
        cnpjs: Pagination
    };

    currentsParams: any = {
        icms: this.abstractService.NzTableQueryParams,
        pis: this.abstractService.NzTableQueryParams,
        cofins: this.abstractService.NzTableQueryParams,
        ipi: this.abstractService.NzTableQueryParams,
        cnpjs: this.abstractService.NzTableQueryParams,
    };

    requestBt: Subscription;

    comboUFs = [];
    comboOrigens = [];
    origensDescricoes = {};
    comboCst = [];
    comboTributos = [
        {label: 'ICMS', value: 'ICMS'},
        {label: 'PIS', value: 'PIS'},
        {label: 'COFINS', value: 'COFINS'},
        {label: 'IPI', value: 'IPI'},
    ];

    tabAtiva = 0;

    tabelaSimples = [];

    regrasICMS = [];
    regrasPIS = [];
    regrasCOFINS = [];
    regrasIPI = [];
    loading = false;
    loadingBt = false;
    loadingBtBasicTable = false;
    loadingRemove = {};
    qtdFiltrosAtivos = {
        icms: 0,
        pis: 0,
        ipi: 0,
    };
    currentSearch = null;

    formFiltrar: FormStack;

    formRegra: FormStack;

    formCadastrarCnpj: FormStack;

    idRegraSelecionada = null;

    modalFiltrarVisible = false;

    tributoSelecionado = 'icms';

    editandoRegistro = false;

    cfopOptions: { label: string; value: string }[] = [];
    selectedCfops: string[] = [];

    modalImportarRegraVisible = false;
    fileList: NzUploadFile[] = [];

    modalBasicTableVisible = false;

    apiUrl = environment.apiUrl;

    modalRegrasVisible = false;

    loadingsExcluirCnpj: any = {};
    loadingCadastrarCnpj: boolean = false;

    constructor(
        private fb: UntypedFormBuilder,
        public service: RegrasAnaliseXmlSaidaService,
        private toastService: ToastrService,
        private modalService: NzModalService,
    ) {
        super(service, {}, toastService);

        const initCp = {
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            sort: [],
            filter: [],
        };

        const initP = new Pagination(50, 1, 1, 0, 0);

        this.paginations.icms = initP;
        this.currentsParams.icms = initCp;

        this.paginations.pis = initP;
        this.currentsParams.pis = initCp;

        this.paginations.cofins = initP;
        this.currentsParams.cofins = initCp;

        this.paginations.ipi = initP;
        this.currentsParams.ipi = initCp;

        this.paginations.cnpjs = initP;
        this.currentsParams.cnpjs = initCp;

        this.service.retornaComboUFs().subscribe((retorno: any) => {
            retorno.estados.forEach((value) => {
                value.label = value.uf + ' - ' + value.nome;
                value.key = value.uf;
            });
            this.comboUFs = retorno.estados;

            this.comboUFs.push({
                label: 'Exterior',
                key: 'EX'
            });
        });

        this.formFiltrar = {
            modalVisible: false,
            formGroup: this.fb.group({
                CST: [null, null],
                CFOP: [null, null],
                uf: [null, null],
                base_calculo: [null, null],
                aliquota: [null, null],
                validar_tabela: [null, null],
                codigo_enquadramento: [null, null],
                origem: [null, null],
            })
        };

        this.formRegra = {
            modalVisible: false,
            formGroup: this.fb.group({
                tributo_id: [null, Validators.required],
                CST: [null, Validators.required],
                CFOP: [null, Validators.required],
                uf: [null, Validators.required],
                base_calculo: [null, Validators.required],
                validar_tabela: [null, Validators.required],
                origem: [null],
                aliquota: [null],
                codigo_enquadramento: [null],
            })
        };

        this.formCadastrarCnpj = {
            modalVisible: false,
            formGroup: this.fb.group({
                cnpj: [null, Validators.required],
                tributo_id: [null, Validators.required]
            })
        };
    }

    ngOnInit() {
        this.getRegrasICMS(this.currentsParams.icms);
        this.getComboOrigem();
        this.getComboCst();
        this.getComboCFOPs();
    }

    getComboOrigem() {
        const tributo = this.tabAtiva === 0 ? 'ICMS' : this.tabAtiva === 1 ? 'PIS' : this.tabAtiva === 2 ? 'COFINS' : 'IPI';

        this.service.retornaComboOrigem(tributo).subscribe((retorno: any) => {
            this.comboOrigens = [];
            this.origensDescricoes = retorno;
            Object.entries(retorno).forEach(item => {
                this.comboOrigens.push({
                    value: item[0],
                    label: item[1]
                });
            });
        });
    }

    getComboCst() {
        const tributo = this.tabAtiva === 0 ? 'ICMS' : this.tabAtiva === 1 ? 'PIS' : this.tabAtiva === 2 ? 'COFINS' : 'IPI';

        this.service.retornaComboCst(tributo).subscribe((retorno: any) => {
            this.comboCst = [];
            Object.entries(retorno).forEach(item => {
                this.comboCst.push({
                    value: item[0],
                    label: item[1]
                });
            });
        });
    }

    getComboCFOPs() {

        this.service.retornaComboCfop().subscribe((retorno: any) => {
            this.cfopOptions = [];
            retorno.forEach(item => {
                this.cfopOptions.push({
                    value: item.id,
                    label: item.id
                });
            });
        });
    }

    changeTabs(event: any) {
        this.requestBt?.unsubscribe();

        this.confirmaFiltrar();
        this.getComboOrigem();
        this.getComboCst();
    }

    modalCadastrar(visible: boolean, regra = null) {
        this.formRegra.formGroup.reset();

        if (regra) {
            this.idRegraSelecionada = regra.id;

            if (typeof regra.CFOP === 'string') {
                regra.CFOP = regra.CFOP.split(',');
            }
            this.selectedCfops = regra.CFOP;

            if (typeof regra.uf === 'string') {
                regra.uf = regra.uf.split(',');
            }

            if (regra.origem?.length === 0) {
                regra.origem = null;
            } else if (typeof regra.origem === 'string') {
                regra.origem = regra.origem.split(',');
            }

            if (regra.codigo_enquadramento?.length === 0) {
                regra.codigo_enquadramento = null;
            }

            this.editandoRegistro = true;

            this.formRegra.formGroup.get('tributo_id').setValue(regra.tributo_id);
            this.formRegra.formGroup.get('CST').setValue(regra.CST);
            this.formRegra.formGroup.get('CFOP').setValue(regra.CFOP);
            this.formRegra.formGroup.get('uf').setValue(regra.uf);
            this.formRegra.formGroup.get('base_calculo').setValue(regra.base_calculo);
            this.formRegra.formGroup.get('validar_tabela').setValue(regra.validar_tabela);
            this.formRegra.formGroup.get('origem').setValue(regra.origem);
            this.formRegra.formGroup.get('aliquota').setValue(regra.aliquota);
            this.formRegra.formGroup.get('codigo_enquadramento').setValue(regra.codigo_enquadramento);

            this.setRegrasFormCadastrarAlterar();
        } else {
            this.idRegraSelecionada = null;
            this.editandoRegistro = false;
        }

        this.formRegra.modalVisible = visible;
    }

    showModalFiltrar(visible: boolean) {
        this.modalFiltrarVisible = visible;
    }

    confirmaFiltrar() {
        this.requestBt?.unsubscribe();

        const currentParams = this.tabAtiva === 0 ? this.currentsParams.icms : this.tabAtiva === 1 ?
            this.currentsParams.pis : this.tabAtiva === 2 ? this.currentsParams.cofins : this.currentsParams.ipi;

        let toRemove = [];

        if (this.tabAtiva === 0) {
            toRemove = ['codigo_enquadramento'];
        } else if (this.tabAtiva === 1 || this.tabAtiva === 2) {
            toRemove = ['codigo_enquadramento', 'origem'];
        } else {
            toRemove = ['aliquota'];
        }

        this.calculaBadgeFiltros(toRemove);

        const filtros = this.formFiltrar.formGroup.value;

        for (const [chave, valor] of Object.entries(filtros) as [string, any][]) {

            if (valor && !toRemove.includes(chave)) {
                if (chave === 'CFOP' && valor.length) {
                    currentParams.filter.push({key: chave, value: valor});
                } else if (chave !== 'CFOP') {
                    currentParams.filter.push({key: chave, value: valor});
                }
            }
        }

        this.showModalFiltrar(false);

        switch (this.tabAtiva) {
            case 0:
                this.currentsParams.icms = currentParams;
                this.currentsParams.icms.pageIndex = 1;
                this.getRegrasICMS(this.currentsParams.icms);
                break;
            case 1:
                this.currentsParams.pis = currentParams;
                this.currentsParams.pis.pageIndex = 1;
                this.getRegrasPIS(this.currentsParams.pis);
                break;
            case 2:
                this.currentsParams.cofins = currentParams;
                this.currentsParams.cofins.pageIndex = 1;
                this.getRegrasCOFINS(this.currentsParams.cofins);
                break;
            case 3:
                this.currentsParams.ipi = currentParams;
                this.currentsParams.ipi.pageIndex = 1;
                this.getRegrasIPI(this.currentsParams.ipi);
        }
    }

    btnResetSearch() {
        this.formFiltrar.formGroup.reset();
        this.confirmaFiltrar();
    }

    calculaBadgeFiltros(toRemove): void {

        this.qtdFiltrosAtivos[this.tributoSelecionado] = 0;

        if (typeof this.formFiltrar !== 'undefined') {

            for (const [chave, valor] of Object.entries(this.formFiltrar.formGroup.value) as [string, any][]) {

                if (valor && !toRemove.includes(chave)) {

                    if (chave === 'CFOP' && valor.length) {
                        this.qtdFiltrosAtivos[this.tributoSelecionado]++;
                    } else if (chave !== 'CFOP') {
                        this.qtdFiltrosAtivos[this.tributoSelecionado]++;
                    }

                }
            }
        }
    }

    listByTableICMS(params: NzTableQueryParams) {
        let buscar = false;

        if (params.pageIndex === this.paginations.icms.current_page) {
            params.sort.forEach(s => {
                if (s.value) {
                    buscar = true;
                }
            });
        }

        if ((buscar || params.pageIndex === 1 && this.paginations.icms.current_page > 1)
            || params.pageIndex > 1
            && params.pageIndex !== this.paginations.icms.current_page) {
            this.getRegrasICMS(params);
            this.paginations.icms.current_page = params.pageIndex;
        }

    }

    listByTableCOFINS(params: NzTableQueryParams) {
        let buscar = false;

        if (params.pageIndex === this.paginations.cofins.current_page) {
            params.sort.forEach(s => {
                if (s.value) {
                    buscar = true;
                }
            });
        }

        if ((buscar || params.pageIndex === 1 && this.paginations.cofins.current_page > 1)
            || params.pageIndex > 1
            && params.pageIndex !== this.paginations.cofins.current_page) {
            this.getRegrasCOFINS(params);
            this.paginations.cofins.current_page = params.pageIndex;
        }

    }

    listByTableIPI(params: NzTableQueryParams) {
        let buscar = false;

        if (params.pageIndex === this.paginations.ipi.current_page) {
            params.sort.forEach(s => {
                if (s.value) {
                    buscar = true;
                }
            });
        }

        if ((buscar || params.pageIndex === 1 && this.paginations.ipi.current_page > 1)
            || params.pageIndex > 1
            && params.pageIndex !== this.paginations.ipi.current_page) {
            this.getRegrasIPI(params);
            this.paginations.ipi.current_page = params.pageIndex;
        }

    }

    listByTablePIS(params: NzTableQueryParams) {
        let buscar = false;

        if (params.pageIndex === this.paginations.pis.current_page) {
            params.sort.forEach(s => {
                if (s.value) {
                    buscar = true;
                }
            });
        }

        if ((buscar || params.pageIndex === 1 && this.paginations.pis.current_page > 1)
            || params.pageIndex > 1
            && params.pageIndex !== this.paginations.pis.current_page) {
            this.getRegrasPIS(params);
            this.paginations.pis.current_page = params.pageIndex;
        }

    }

    getRegrasICMS(params: NzTableQueryParams): void {
        this.loading = true;

        this.currentsParams.icms = params;

        if (this.currentSearch) {
            this.currentsParams.icms.filter.push({
                key: 'procurar',
                value: this.currentSearch
            });
        }

        this.requestBt = this.service.getRegras('icms', this.currentsParams.icms).subscribe({
            next: (response: any) => {

                this.regrasICMS = response.data;

                this.paginations.icms = new Pagination(
                    response?.per_page || 50,
                    response?.current_page || 1,
                    response?.last_page || 1,
                    response?.total || 50,
                    response?.from || 1,
                    response?.to || 1);

                this.loading = false;
            },
            error: (err) => {
                this.loading = false;
                this.toastService.error(err.error.message);
            }
        })
        ;

    }

    getRegrasPIS(params: NzTableQueryParams): void {
        this.loading = true;

        this.currentsParams.pis = params;

        if (this.currentSearch) {
            this.currentsParams.pis.filter.push({
                key: 'procurar',
                value: this.currentSearch
            });
        }

        this.requestBt = this.service.getRegras('pis', this.currentsParams.pis).subscribe({
            next: (response: any) => {
                this.regrasPIS = response.data;

                this.paginations.pis = new Pagination(
                    response?.per_page || 50,
                    response?.current_page || 1,
                    response?.last_page || 1,
                    response?.total || 50,
                    response?.from || 1,
                    response?.to || 1);

                this.loading = false;
            },
            error: (err) => {
                this.loading = false;
                this.toastService.error(err.error.message);
            }
        })
        ;

    }

    getRegrasCOFINS(params: NzTableQueryParams): void {
        this.loading = true;

        this.currentsParams.cofins = params;

        if (this.currentSearch) {
            this.currentsParams.cofins.filter.push({
                key: 'procurar',
                value: this.currentSearch
            });
        }

        console.log('COFINS-------------------')

        this.requestBt = this.service.getRegras('cofins', this.currentsParams.cofins).subscribe({
            next: (response: any) => {
                this.regrasCOFINS = response.data;

                this.paginations.cofins = new Pagination(
                    response?.per_page || 50,
                    response?.current_page || 1,
                    response?.last_page || 1,
                    response?.total || 50,
                    response?.from || 1,
                    response?.to || 1);

                this.loading = false;
            },
            error: (err) => {
                this.loading = false;
                this.toastService.error(err.error.message);
            }
        })
        ;

    }

    getRegrasIPI(params: NzTableQueryParams): void {
        this.loading = true;

        this.currentsParams.ipi = params;

        if (this.currentSearch) {
            this.currentsParams.ipi.filter.push({
                key: 'procurar',
                value: this.currentSearch
            });
        }

        this.requestBt = this.service.getRegras('ipi', this.currentsParams.ipi).subscribe({
            next: (response: any) => {
                this.regrasIPI = response.data;

                this.paginations.ipi = new Pagination(
                    response?.per_page || 50,
                    response?.current_page || 1,
                    response?.last_page || 1,
                    response?.total || 50,
                    response?.from || 1,
                    response?.to || 1);

                this.loading = false;
            },
            error: (err) => {
                this.loading = false;
                this.toastService.error(err.error.message);
            }
        })
        ;

    }

    setRegrasFormCadastrarAlterar() {

    }

    cadastrarRegra() {

        this.setRegrasFormCadastrarAlterar();

        if (this.formRegra.formGroup.valid) {

            this.loadingBt = true;

            this.service.gravarRegra(this.formRegra.formGroup.value).subscribe({
                next: (response: any) => {
                    this.loadingBt = false;
                    this.toastService.success(response.message);
                    this.modalCadastrar(false);

                    this.changeTabs({index: 0});
                },
                error: (err) => {
                    this.loadingBt = false;
                    this.toastService.error(err.error.message);
                }
            });

        } else {
            Object.values(this.formRegra.formGroup.controls).forEach(control => {
                if (control.invalid) {
                    control.markAsDirty();
                    control.updateValueAndValidity({onlySelf: true});
                }
            });
        }
    }


    alterarRegra() {

        this.setRegrasFormCadastrarAlterar();

        if (this.formRegra.formGroup.valid) {

            this.loadingBt = true;

            this.service.alterarRegra(this.formRegra.formGroup.value, this.idRegraSelecionada).subscribe({
                next: (response: any) => {
                    this.loadingBt = false;
                    this.toastService.success(response.message);
                    this.modalCadastrar(false);

                    this.changeTabs({index: 0});
                },
                error: (err) => {
                    this.loadingBt = false;
                    this.toastService.error(err.error.message);
                }
            });

        } else {
            Object.values(this.formRegra.formGroup.controls).forEach(control => {
                if (control.invalid) {
                    control.markAsDirty();
                    control.updateValueAndValidity({onlySelf: true});
                }
            });
        }

    }

    adicionarCFOPCombo(event: any): void {

        this.selectedCfops = [];
        event?.forEach((value, index) => {
            const valor = value.replace(/\D/g, '');
            value = valor;
            if (value && value.length !== 4) {
                event.splice(index, 1);
                this.toastService.warning('CFOP Inválido');
            } else if (value) {
                this.selectedCfops.push(value);
            }
        });

        // Remover Duplicados
        this.selectedCfops = this.selectedCfops.filter((n, i) => this.selectedCfops.indexOf(n) === i);

    }

    showConfirmRemoverRegra(id: string): void {

        this.modalService.confirm({
            nzTitle: 'Deseja mesmo remover a regra selecionada?',
            nzOkText: 'Remover',
            nzCancelText: 'Cancelar',
            nzOnOk: () => this.excluirRegra(id)
        });

    }

    excluirRegra(id: string) {

        this.loadingRemove[id] = true;

        this.service.excluirRegra(id, this.tributoSelecionado).subscribe({
            next: (res: any) => {
                this.loadingRemove[id] = false;
                this.toastService.success(res.message);
                this.confirmaFiltrar();
            },
            error: (err) => {
                this.loadingRemove[id] = false;
                this.toastService.error(err.error.message);
            }
        });

    }

    modalImportarRegra(visible: boolean) {
        this.modalImportarRegraVisible = visible;
    }

    modalImportarBasicTable(visible: boolean) {
        this.modalRegrasVisible = !visible;
        this.modalBasicTableVisible = visible;
    }

    beforeUpload = (file: NzUploadFile): boolean => {

        this.fileList = [];
        this.fileList.push(file);
        return false;

    }


    confirmarImportar(): void {

        this.loadingBt = true;

        const formData = new FormData();

        this.fileList.forEach((file: any) => {
            formData.append('arquivo', file);
        });

        this.service.uploadRegra(formData).subscribe((response: any) => {

                this.toastrService.success(response.message);

                this.fileList = [];

                this.modalImportarRegraVisible = false;

                this.confirmaFiltrar();
                this.loadingBt = false;

            },
            (response) => {

                this.toastrService.error(response.error.message);
                this.loadingBt = false;
            });

    }

    confirmarImportarBasicTable(): void {

        this.loadingBt = true;

        const formData = new FormData();

        this.fileList.forEach((file: any) => {
            formData.append('arquivo', file);
        });

        this.service.uploadBasicTable(formData).subscribe((response: any) => {

                this.toastrService.success(response.message);

                this.fileList = [];

                this.loadingBt = false;
                this.modalImportarBasicTable(false);
                this.getRegrasCnpj(this.currentsParams.cnpjs);

            },
            (response) => {

                this.toastrService.error(response.error.message);
                this.loadingBt = false;
            });

    }

    handleChange({file, fileList}: NzUploadChangeParam): void {
    }

    downloadModelo(): void {

        this.service.downloadModelo().subscribe((res) => {
            const blob = new Blob([res], {type: 'text/json; charset=utf-8'});
            fileSaver.saveAs(blob, 'Modelo Importar' + '.xlsx');
        });
    }

    downloadModeloBasic(): void {

        this.service.downloadModeloBasic().subscribe((res) => {
            const blob = new Blob([res], {type: 'text/json; charset=utf-8'});
            fileSaver.saveAs(blob, 'Modelo Importar' + '.xlsx');
        });
    }

    modalRegras(visible: boolean) {

        if (visible) {
            this.getRegrasCnpj(this.currentsParams.cnpjs);
        }

        this.modalRegrasVisible = visible;

    }

    getRegrasCnpj(params: NzTableQueryParams, loadingBtModal = true) {

        this.currentsParams.cnpjs = params;

        this.loadingBtBasicTable = true;
        this.loadingBtBasicTable = loadingBtModal;

        this.service.getRegrasCnpjs(this.currentsParams.cnpjs).subscribe({
            next: (response: any) => {
                this.tabelaSimples = response.data;

                this.paginations.cnpjs = new Pagination(
                    response?.per_page || 50,
                    response?.current_page || 1,
                    response?.last_page || 1,
                    response?.total || 50,
                    response?.from || 1,
                    response?.to || 1);

                this.loadingBtBasicTable = false;
            },
            error: (err) => {
                this.loadingBtBasicTable = false;
                this.toastService.error(err.error.message);
            }
        });

    }

    listByTableRegrasCnpjs(params: NzTableQueryParams) {
        let buscar = false;

        if (params.pageIndex === this.paginations.cnpjs.current_page) {
            params.sort.forEach(s => {
                if (s.value) {
                    buscar = true;
                }
            });
        }

        if ((buscar || params.pageIndex === 1 && this.paginations.cnpjs.current_page > 1)
            || params.pageIndex > 1
            && params.pageIndex !== this.paginations.cnpjs.current_page) {
            this.getRegrasCnpj(params, false);
            this.paginations.cnpjs.current_page = params.pageIndex;
        }

    }

    excluirCnpj(id: string) {

        this.loadingsExcluirCnpj[id] = true;

        this.service.excluirCnpj(id).subscribe(res => {
            this.toastService.success('CNPJ excluído com sucesso!');
            this.getRegrasCnpj(this.currentsParams.cnpjs);
            this.loadingsExcluirCnpj[id] = false;
        }, (res) => {
            this.loadingsExcluirCnpj[id] = false;
        });

    }

    modalCadastrarCnpj(visibile: boolean) {

        this.formCadastrarCnpj.modalVisible = visibile;
        this.loadingCadastrarCnpj = false;

    }

    cadastrarCnpj() {
        this.loadingCadastrarCnpj = true;

        this.service.cadastrarCnpj(this.formCadastrarCnpj.formGroup.value).subscribe(res => {
            this.loadingCadastrarCnpj = false;
            this.modalCadastrarCnpj(false);
            this.formCadastrarCnpj.formGroup.reset();
            this.toastService.success('CNPJ cadastrado com sucesso!');
            this.getRegrasCnpj(this.currentsParams.cnpjs);
        }, (res) => {
            this.loadingCadastrarCnpj = false;
            this.toastService.error(res.error.message);
        });

    }
}
