import {DetailMenuComponent} from '../../modules/menu/pages/detail/detail-menu.component';
import {LogComponent} from '../../modules/log/pages/log.component';
import {ListProfileComponent} from '../../modules/profile/pages/list/list-profile.component';
import {RegisterProfileComponent} from '../../modules/profile/pages/register/register-profile.component';
import {DetailProfileComponent} from '../../modules/profile/pages/detail/detail-profile.component';
import {ExternalPageComponent} from '../../modules/external-page/pages/external-page.component';
import {AdministracaoComponent} from 'src/app/modules/administracao/administracao.component';
import {ObrigacoesDashboardComponent} from '../../modules/obrigacoes/obrigacoesDashboard/obrigacoesDashboard.component';
import {
    ComplianceObrigacoesComponent
} from '../../modules/compliance/complianceObrigacoes/complianceObrigacoes.component';
import {ObrigacoesFederaisComponent} from '../../modules/drive/obrigacoesFederais/obrigacoesFederais.component';
import {TransitoriosComponent} from '../../modules/drive/transitorios/transitorios.component';
import {
    MonitorArquivosTransitoriosComponent
} from '../../modules/drive/transitorios/arquivos-transitorios/arquivos-transitorios.component';
import {UsuarioLogadoComponent} from '../../modules/usuario-logado/usuario-logado.component';
import {UsuarioDetalheComponent} from '../../modules/administracao/usuarios/usuario-detalhe/usuario-detalhe.component';
import {UsuariosComponent} from '../../modules/administracao/usuarios/usuarios.component';
import {EmpresasComponent} from '../../modules/administracao/empresas/empresas.component';
import {EmpresaDetalheComponent} from '../../modules/administracao/empresas/empresa-detalhe/empresa-detalhe.component';
import {PerfisComponent} from '../../modules/administracao/perfis/perfis.component';
import {PerfilDetalheComponent} from '../../modules/administracao/perfis/perfil-detalhe/perfil-detalhe.component';
import {CadastrosObrigacoesComponent} from '../../modules/cadastros/obrigacoes/obrigacoes.component';
import {
    ObrigacaoDetalheComponent
} from '../../modules/cadastros/obrigacoes/obrigacao-detalhe/obrigacao-detalhe.component';
import {
    ObrigacaoEmpresaDetalheComponent
} from '../../modules/cadastros/obrigacoes-empresas/obrigacao-empresa-detalhe/obrigacao-empresa-detalhe.component';
import {
    CadastrosInscricoesRegimesComponent
} from '../../modules/cadastros/inscricoes-regimes/inscricoes-regimes.component';
import {
    CadastrosEditarInscricoesRegimesComponent
} from '../../modules/cadastros/editar-inscricoes-regimes/editar-inscricoes-regimes.component';
import {
    CadastrosCredenciaisAcessoComponent
} from '../../modules/cadastros/credenciais-acesso/credenciais-acesso.component';
import {
    CadastrosEditarCredenciaisAcessoComponent
} from '../../modules/cadastros/editar-credenciais-acesso/editar-credenciais-acesso.component';
import {
    CadastrosEditarResponsaveisComponent
} from '../../modules/cadastros/editar-responsaveis/editar-responsaveis.component';
import {
    CadastrosObrigacoesEmpresasComponent
} from '../../modules/cadastros/obrigacoes-empresas/obrigacoes-empresas.component';
import {
    CadastrosObrigacoesFederaisComponent
} from '../../modules/cadastros/obrigacoes-federais/obrigacoes-federais.component';
import {
    CadastrosObrigacoesEstaduaisComponent
} from '../../modules/cadastros/obrigacoes-estaduais/obrigacoes-estaduais.component';
import {
    CadastrosObrigacoesPrevidenciarioComponent
} from '../../modules/cadastros/obrigacoes-previdenciario/obrigacoes-previdenciario.component';
import {EquipesComponent} from '../../modules/cadastros/equipes/equipes.component';
import {RegrasPisCofinsComponent} from '../../modules/cadastros/regras-pis-cofins/regras-pis-cofins.component';
import {CadastroResponsaveisComponent} from '../../modules/cadastros/responsaveis/responsaveis.component';
import {
    ComplianceBaixasEAutomacoesComponent
} from '../../modules/configuracoes/compliance-baixas-e-automacoes/compliance-baixas-e-automacoes.component';
import {
    ImportarRegrasPisCofinsComponent
} from '../../modules/cadastros/regras-pis-cofins-importar-movimento/regras-pis-cofins-importar-movimento.component';
import {ObrigacoesRegrasComponent} from '../../modules/check/obrigacoes-regras/obrigacoes-regras.component';
import {
    ObrigacoesEditarRegrasComponent
} from '../../modules/check/editar-obrigacoes-regras/editar-obrigacoes-regras.component';
import {CheckObrigacoesComponent} from '../../modules/check/checkObrigacoes/checkObrigacoes.component';
import {ComplianceTributosComponent} from '../../modules/compliance/complianceTributos/complianceTributos.component';
import {BaixasAutomaticasComponent} from '../../modules/drive/baixas-automaticas/baixas-automaticas.component';
import {
    BaixasAutomaticasDetalhesComponent
} from '../../modules/drive/baixas-automaticas-detalhes/baixas-automaticas-detalhes.component';
import {
    ComplianceTributosEmpresasComponent
} from '../../modules/compliance/complianceTributosEmpresas/complianceTributosEmpresas.component';
import {ComplianceCalendarComponent} from '../../modules/compliance/complianceCalendar/complianceCalendar.component';
import {
    MonitorProcessamentosComponent
} from '../../modules/monitoramento/processamentos/monitorProcessamentos.component';
import {CalendarTasksComponent} from '../../modules/calendar/calendarTasks/calendarTasks.component';
import {CalendarObrigacoesComponent} from '../../modules/calendar/calendarObrigacoes/calendarObrigacoes.component';
import {CheckNotasComponent} from '../../modules/compliance/spedNotas/checkNotas.component';
import {CheckNotasDetalhesComponent} from '../../modules/compliance/spedNotasDetalhes/checkNotasDetalhes.component';
import {
    TransitoriosUploadArquivosComponent
} from '../../modules/drive/transitorios/uploadArquivos/uploadArquivos.component';
import {ObrigacoesEstaduaisComponent} from '../../modules/drive/obrigacoesEstaduais/obrigacoesEstaduais.component';
import {
    CheckEfdContribuicoesTributacaoComponent
} from '../../modules/check/checkEfdContribuicoesTributacao/checkEfdContribuicoesTributacao.component';
import {
    CheckEfdContribuicoesTributacaoDetalhesComponent
} from '../../modules/check/checkEfdContribuicoesTributacaoDetalhes/checkEfdContribuicoesTributacaoDetalhes.component';
import {
    ArmazenamentosDetalhesComponent
} from '../../modules/check/armazenamentosDetalhes/armazenamentosDetalhes.component';
import {FlowObrigacoesComponent} from '../../modules/flow/flowObrigacoes/flowObrigacoes.component';
import {GuiasArquivosComponent} from '../../modules/integracoes/guiasArquivos/guiasArquivos.component';
import {ErpComponent} from '../../modules/integracoes/erp/erp.component';
import {GuiasLotesComponent} from '../../modules/integracoes/guiasLotes/guiasLotes.component';
import {VisibilidadesComponent} from '../../modules/administracao/visibilidades/visibilidades.component';
import {
    VisibilidadeDetalheComponent
} from '../../modules/administracao/visibilidades/visibilidade-detalhe/visibilidade-detalhe.component';
import {NotifyTestComponent} from '../../modules/notificacoes/notifyTest/notifyTest.component';
import {ConnectDownloadComponent} from '../../modules/connect/connectDownload/connectDownload.component';
import {ControleCertidoesComponent} from '../../modules/certidoes/controleCertidoes/controleCertidoes.component';
import {DomicilioEletronicoComponent} from '../../modules/domicilio/domicilioEletronico/domicilioEletronico.component';
import {DecCndComponent} from '../../modules/decCnd/dashboard/decCnd.component';
import {CadastrosCertidoesComponent} from '../../modules/cadastros/certidoes/certidoes.component';
import {
    CadastrosDomicilioEletronicoComponent
} from '../../modules/cadastros/domicilioEletronico/domicilioEletronico.component';
import {SefazErpComponent} from '../../modules/notas/sefazErp/sefazErp.component';
import {SefazErpDetalhesComponent} from '../../modules/notas/sefazErpDetalhes/sefazErpDetalhes.component';
import {NotasEmitidasComponent} from '../../modules/notas/notasEmitidas/notasEmitidas.component';
import {NotasEmitidasEmpresaComponent} from '../../modules/notas/notasEmitidasEmpresa/notasEmitidasEmpresa.component';
import {SimplesNacionalComponent} from '../../modules/terceiros/simplesNacional/simplesNacional.component';
import {
    CadastrosStepsObrigacoesEmpresasComponent
} from '../../modules/cadastros/steps-obrigacoes-empresas/steps-obrigacoes-empresas.component';
import {
    GruposEmpresariaisComponent
} from '../../modules/administracao/grupos-empresariais/grupos-empresariais.component';
import {CadastrosGatilhosComponent} from '../../modules/cadastros/gatilhos/gatilhos.component';
import {CadastrosEditarGatilhosComponent} from '../../modules/cadastros/editar-gatilhos/editar-gatilhos.component';
import {FlowAprovacoesComponent} from '../../modules/flow/flowAprovacoes/flowAprovacoes.component';
import {CadastrosCategoriasComponent} from '../../modules/cadastros/categorias/categorias.component';
import {
    TransmissoesDetalhesComponent
} from '../../modules/delivery/transmissoesDetalhes/transmissoesDetalhes.component';
import {ReceitaFederalComponent} from '../../modules/receita/receitaFederal/receitaFederal.component';
import {ConciliacoesComponent} from '../../modules/check/conciliacoes/conciliacoes.component';
import {ArmazenamentosComponent} from '../../modules/check/armazenamentos/armazenamentos.component';
import {DownloadCenterComponent} from '../../modules/downloadCenter/downloadCenter.component';
import {TransmissoesComponent} from '../../modules/delivery/transmissoes/transmissoes.component';
import {DadosEmpresaComponent} from '../../modules/empresa/dados-empresa/dados-empresa.component';
import {
    DetalhesDadosEmpresaComponent
} from '../../modules/empresa/detalhes-dados-empresa/detalhes-dados-empresa.component';
import {ConciliacoesDetalhesComponent} from '../../modules/check/conciliacoesDetalhes/conciliacoesDetalhes.component';
import {ConsolidadoEcacComponent} from '../../modules/situacaoFiscal/ecac/ecac.component';
import {ObrigacoesPrincipaisComponent} from '../../modules/drive/obrigacoesPrincipais/obrigacoesPrincipais.component';
import {LoadingComponent} from '../../modules/loading/loading.component';
import {
    NotasFiscaisEletronicasComponent
} from '../../modules/configuracoes/notasFiscaisEletronicas/notasFiscaisEletronicas.component';
import {
    EditarNotasFiscaisEletronicasComponent
} from '../../modules/configuracoes/editarNotasFiscaisEletronicas/editarNotasFiscaisEletronicas.component';
import {ConfiguracoesComponent} from '../../modules/integracoes/configuracoes/configuracoes.component';
import {OmissoesSefazComponent} from '../../modules/consultas/omissoes-sefaz/omissoes-sefaz.component';
import {LogsAlteracoesComponent} from '../../modules/administracao/logsAlteracoes/logsAlteracoes.component';
import {DiagnosticoFiscalComponent} from '../../modules/compliance/diagnostico-fiscal/diagnostico-fiscal.component';
import {
    HistoricoDeAcessosComponent
} from '../../modules/monitoramento/historico-de-acesso/historico-de-acessos.component';
import {CalendarConfirmacoesComponent} from '../../modules/calendar/calendarConfirmacoes/calendarConfirmacoes.component';
import {MonitorDeEventosComponent} from '../../modules/cadastros/monitor-de-eventos/monitor-de-eventos.component';
import {DashboardComponent} from '../../modules/compliance/dashboard/dashboard.component';
import {
    RegrasDesconsideracoesComponent
} from '../../modules/compliance/regras-desconsiderações/regras-desconsideracoes.component';
import {
    ComplianceConfiguracoesComponent
} from '../../modules/compliance/configuracoes/compliance-configuracoes.component';
import {CategoriasEStatusComponent} from '../../modules/workflow/categorias-e-status/categorias-e-status.component';
import {
    NotasFiscaisEntradaComponent
} from '../../modules/workflow/notas-fiscais-entrada/notas-fiscais-entrada.component';
import {NfeTerceirosComponent} from '../../modules/notas/nfe-terceiros/nfe-terceiros.component';
import {
    ControleDeCertificadosComponent
} from '../../modules/dashboards/controle-de-certificados/controle-de-certificados.component';
import {
    RegrasAnaliseXmlSaidaComponent
} from '../../modules/cadastros/regras-analise-xml-saida/regras-analise-xml-saida.component';
import {AnalisesXMLSaidasComponent} from '../../modules/notas/AnalisesXMLSaidas/AnalisesXMLSaidas.component';
import {AnalisesXMLSaidasDetalhesComponent} from '../../modules/notas/AnalisesXMLSaidasDetalhesComponent/AnalisesXMLSaidasDetalhes.component';
import { InboxComponent } from 'src/app/modules/configuracoes/inbox/inbox.component';

export const componentsClassConst = {
    ConsolidadoEcacComponent: {
        component: ConsolidadoEcacComponent,
        name: 'ConsolidadoEcacComponent',
    },
    TransmissoesComponent: {
        component: TransmissoesComponent,
        name: 'TransmissoesComponent',
    },
    ArmazenamentosComponent: {
        component: ArmazenamentosComponent,
        name: 'ArmazenamentosComponent',
    },
    ConciliacoesComponent: {
        component: ConciliacoesComponent,
        name: 'ConciliacoesComponent',
    },
    DownloadCenterComponent: {
        component: DownloadCenterComponent,
        name: 'DownloadCenterComponent',
    },
    TransmissoesDetalhesComponent: {
        component: TransmissoesDetalhesComponent,
        name: 'TransmissoesDetalhesComponent',
    },
    CadastrosCategoriasComponent: {
        component: CadastrosCategoriasComponent,
        name: 'CadastrosCategoriasComponent',
    },
    FlowAprovacoesComponent: {
        component: FlowAprovacoesComponent,
        name: 'FlowAprovacoesComponent',
    },
    CadastrosEditarGatilhosComponent: {
        component: CadastrosEditarGatilhosComponent,
        name: 'CadastrosEditarGatilhosComponent',
    },
    CadastrosGatilhosComponent: {
        component: CadastrosGatilhosComponent,
        name: 'CadastrosGatilhosComponent',
    },
    CadastrosStepsObrigacoesEmpresasComponent: {
        component: CadastrosStepsObrigacoesEmpresasComponent,
        name: 'CadastrosStepsObrigacoesEmpresasComponent',
    },
    ConciliacoesDetalhesComponent: {
        component: ConciliacoesDetalhesComponent,
        name: 'ConciliacoesDetalhesComponent',
    },
    SefazErpDetalhesComponent: {
        component: SefazErpDetalhesComponent,
        name: 'SefazErpDetalhesComponent',
    },
    SefazErpComponent: {
        component: SefazErpComponent,
        name: 'SefazErpComponent',
    },
    NotasEmitidasComponent: {
        component: NotasEmitidasComponent,
        name: 'NotasEmitidasComponent',
    },
    NotasEmitidasEmpresaComponent: {
        component: NotasEmitidasEmpresaComponent,
        name: 'NotasEmitidasEmpresaComponent',
    },
    CadastrosDomicilioEletronicoComponent: {
        component: CadastrosDomicilioEletronicoComponent,
        name: 'CadastrosDomicilioEletronicoComponent',
    },
    CadastrosCertidoesComponent: {
        component: CadastrosCertidoesComponent,
        name: 'CadastrosCertidoesComponent',
    },
    DomicilioEletronicoComponent: {
        component: DomicilioEletronicoComponent,
        name: 'DomicilioEletronicoComponent',
    },
    DecCndComponent: {
        component: DecCndComponent,
        name: 'DecCndComponent',
    },
    ControleCertidoesComponent: {
        component: ControleCertidoesComponent,
        name: 'ControleCertidoesComponent',
    },
    ConnectDownloadComponent: {
        component: ConnectDownloadComponent,
        name: 'ConnectDownloadComponent',
    },
    NotifyTestComponent: {
        component: NotifyTestComponent,
        name: 'NotifyTestComponent',
    },
    VisibilidadeDetalheComponent: {
        component: VisibilidadeDetalheComponent,
        name: 'VisibilidadeDetalheComponent',
    },
    VisibilidadesComponent: {
        component: VisibilidadesComponent,
        name: 'VisibilidadesComponent',
    },
    GruposEmpresariaisComponent: {
        component: GruposEmpresariaisComponent,
        name: 'GruposEmpresariaisComponent',
    },
    GuiasLotesComponent: {
        component: GuiasLotesComponent,
        name: 'GuiasLotesComponent',
    },
    GuiasArquivosComponent: {
        component: GuiasArquivosComponent,
        name: 'GuiasArquivosComponent',
    },
    ErpComponent: {
        component: ErpComponent,
        name: 'ErpComponent',
    },
    FlowObrigacoesComponent: {
        component: FlowObrigacoesComponent,
        name: 'FlowObrigacoesComponent',
    },
    CheckEfdContribuicoesTributacaoDetalhesComponent: {
        component: CheckEfdContribuicoesTributacaoDetalhesComponent,
        name: 'CheckEfdContribuicoesTributacaoDetalhesComponent',
    },
    ArmazenamentosDetalhesComponent: {
        component: ArmazenamentosDetalhesComponent,
        name: 'ArmazenamentosDetalhesComponent',
    },
    CheckEfdContribuicoesTributacaoComponent: {
        component: CheckEfdContribuicoesTributacaoComponent,
        name: 'CheckEfdContribuicoesTributacaoComponent',
    },
    ObrigacoesEstaduaisComponent: {
        component: ObrigacoesEstaduaisComponent,
        name: 'ObrigacoesEstaduaisComponent',
    },
    ObrigacoesPrincipaisComponent: {
        component: ObrigacoesPrincipaisComponent,
        name: 'ObrigacoesPrincipaisComponent',
    },
    TransitoriosUploadArquivosComponent: {
        component: TransitoriosUploadArquivosComponent,
        name: 'TransitoriosUploadArquivosComponent',
    },
    CheckNotasDetalhesComponent: {
        component: CheckNotasDetalhesComponent,
        name: 'CheckNotasDetalhesComponent',
    },
    CheckNotasComponent: {
        component: CheckNotasComponent,
        name: 'CheckNotasComponent',
    },
    CalendarObrigacoesComponent: {
        component: CalendarObrigacoesComponent,
        name: 'CalendarObrigacoesComponent',
    },
    CalendarTasksComponent: {
        component: CalendarTasksComponent,
        name: 'CalendarTasksComponent',
    },
    MonitorProcessamentosComponent: {
        component: MonitorProcessamentosComponent,
        name: 'MonitorProcessamentosComponent',
    },
    ComplianceCalendarComponent: {
        component: ComplianceCalendarComponent,
        name: 'ComplianceCalendarComponent',
    },
    ComplianceTributosEmpresasComponent: {
        component: ComplianceTributosEmpresasComponent,
        name: 'ComplianceTributosEmpresasComponent',
    },
    ComplianceTributosComponent: {
        component: ComplianceTributosComponent,
        name: 'ComplianceTributosComponent',
    },
    CheckObrigacoesComponent: {
        component: CheckObrigacoesComponent,
        name: 'CheckObrigacoesComponent',
    },
    ObrigacoesEditarRegrasComponent: {
        component: ObrigacoesEditarRegrasComponent,
        name: 'ObrigacoesEditarRegrasComponent',
    },
    ObrigacoesRegrasComponent: {
        component: ObrigacoesRegrasComponent,
        name: 'ObrigacoesRegrasComponent',
    },
    CadastrosObrigacoesPrevidenciarioComponent: {
        component: CadastrosObrigacoesPrevidenciarioComponent,
        name: 'CadastrosObrigacoesPrevidenciarioComponent',
    },
    CadastrosObrigacoesEstaduaisComponent: {
        component: CadastrosObrigacoesEstaduaisComponent,
        name: 'CadastrosObrigacoesEstaduaisComponent',
    },
    CadastrosObrigacoesFederaisComponent: {
        component: CadastrosObrigacoesFederaisComponent,
        name: 'CadastrosObrigacoesFederaisComponent',
    },
    CadastrosObrigacoesEmpresasComponent: {
        component: CadastrosObrigacoesEmpresasComponent,
        name: 'CadastrosObrigacoesEmpresasComponent',
    },
    CadastrosEditarCredenciaisAcessoComponent: {
        component: CadastrosEditarCredenciaisAcessoComponent,
        name: 'CadastrosEditarCredenciaisAcessoComponent',
    },
    CadastrosEditarResponsaveisComponent: {
        component: CadastrosEditarResponsaveisComponent,
        name: 'CadastrosEditarResponsaveisComponent',
    },
    CadastrosCredenciaisAcessoComponent: {
        component: CadastrosCredenciaisAcessoComponent,
        name: 'CadastrosCredenciaisAcessoComponent',
    },
    CadastrosEditarInscricoesRegimesComponent: {
        component: CadastrosEditarInscricoesRegimesComponent,
        name: 'CadastrosEditarInscricoesRegimesComponent',
    },
    CadastrosInscricoesRegimesComponent: {
        component: CadastrosInscricoesRegimesComponent,
        name: 'CadastrosInscricoesRegimesComponent',
    },
    ObrigacaoDetalheComponent: {
        component: ObrigacaoDetalheComponent,
        name: 'ObrigacaoDetalheComponent',
    },
    ObrigacaoEmpresaDetalheComponent: {
        component: ObrigacaoEmpresaDetalheComponent,
        name: 'ObrigacaoEmpresaDetalheComponent',
    },
    CadastrosObrigacoesComponent: {
        component: CadastrosObrigacoesComponent,
        name: 'CadastrosObrigacoesComponent',
    },
    ObrigacoesFederaisComponent: {
        component: ObrigacoesFederaisComponent,
        name: 'ObrigacoesFederaisComponent',
    },
    ComplianceObrigacoesComponent: {
        component: ComplianceObrigacoesComponent,
        name: 'ComplianceObrigacoesComponent',
    },
    PerfisComponent: {
        component: PerfisComponent,
        name: 'PerfisComponent',
    },
    PerfilDetalheComponent: {
        component: PerfilDetalheComponent,
        name: 'PerfilDetalheComponent',
    },
    UsuariosComponent: {
        component: UsuariosComponent,
        name: 'UsuariosComponent',
    },
    EquipesComponent: {
        component: EquipesComponent,
        name: 'EquipesComponent',
    },
    RegrasPisCofinsComponent: {
        component: RegrasPisCofinsComponent,
        name: 'RegrasPisCofinsComponent',
    },
    CadastroResponsaveisComponent: {
        component: CadastroResponsaveisComponent,
        name: 'CadastroResponsaveisComponent',
    },
    ImportarRegrasPisCofinsComponent: {
        component: ImportarRegrasPisCofinsComponent,
        name: 'ImportarRegrasPisCofinsComponent',
    },
    UsuarioDetalheComponent: {
        component: UsuarioDetalheComponent,
        name: 'UsuarioDetalheComponent',
    },
    UsuarioLogadoComponent: {
        component: UsuarioLogadoComponent,
        name: 'UsuarioLogadoComponent',
    },
    EmpresasComponent: {
        component: EmpresasComponent,
        name: 'EmpresasComponent',
    },
    EmpresaDetalheComponent: {
        component: EmpresaDetalheComponent,
        name: 'EmpresaDetalheComponent',
    },
    TransitoriosComponent: {
        component: TransitoriosComponent,
        name: 'TransitoriosComponent',
    },
    MonitorArquivosTransitoriosComponentUpload: {
        component: MonitorArquivosTransitoriosComponent,
        name: 'MonitorArquivosTransitoriosComponentUpload',
    },
    MonitorArquivosTransitoriosComponentAgent: {
        component: MonitorArquivosTransitoriosComponent,
        name: 'MonitorArquivosTransitoriosComponentAgent',
    },
    MonitorArquivosTransitoriosComponentEdi: {
        component: MonitorArquivosTransitoriosComponent,
        name: 'MonitorArquivosTransitoriosComponentEdi',
    },
    ListLogComponent: {
        component: LogComponent,
        name: 'LogComponent'
    },
    ListProfileComponent: {
        component: ListProfileComponent,
        name: 'ListProfileComponent'
    },
    RegisterProfileComponent: {
        component: RegisterProfileComponent,
        name: 'RegisterProfileComponent'
    },
    DetailMenuComponent: {
        component: DetailMenuComponent,
        name: 'DetailMenuComponent',
    },
    DetailProfileComponent: {
        component: DetailProfileComponent,
        name: 'DetailProfileComponent',
    },
    SimplesNacionalComponent: {
        component: SimplesNacionalComponent,
        name: 'SimplesNacionalComponent',
    },
    AdministracaoComponent: {
        component: AdministracaoComponent,
        name: 'AdministracaoComponent',
    },
    ObrigacoesDashboardComponent: {
        component: ObrigacoesDashboardComponent,
        name: 'ObrigacoesDashboardComponent',
    },
    ExternalPageComponent: {
        component: ExternalPageComponent,
        name: 'ExternalPageComponent',
    },
    ReceitaFederalComponent: {
        component: ReceitaFederalComponent,
        name: 'ReceitaFederalComponent',
    },
    DadosEmpresaComponent: {
        component: DadosEmpresaComponent,
        name: 'DadosEmpresaComponent',
    },
    DetalhesDadosEmpresaComponent: {
        component: DetalhesDadosEmpresaComponent,
        name: 'DetalhesDadosEmpresaComponent',
    },
    BaixasAutomaticasComponent: {
        component: BaixasAutomaticasComponent,
        name: 'BaixasAutomaticasComponent',
    },
    BaixasAutomaticasDetalhesComponent: {
        component: BaixasAutomaticasDetalhesComponent,
        name: 'BaixasAutomaticasDetalhesComponent',
    },
    ConfiguracoesComponent: {
        component: ConfiguracoesComponent,
        name: 'ConfiguracoesComponent',
    },
    LoadingComponent: {
        component: LoadingComponent,
        name: 'LoadingComponent',
    },
    NotasFiscaisEletronicasComponent: {
        component: NotasFiscaisEletronicasComponent,
        name: 'NotasFiscaisEletronicasComponent',
    },
    EditarNotasFiscaisEletronicasComponent: {
        component: EditarNotasFiscaisEletronicasComponent,
        name: 'EditarNotasFiscaisEletronicasComponent',
    },
    OmissoesSefazComponent: {
        component: OmissoesSefazComponent,
        name: 'OmissoesSefazComponent',
    },
    DiagnosticoFiscalComponent: {
        component: DiagnosticoFiscalComponent,
        name: 'DiagnosticoFiscalComponent',
    },
    ComplianceBaixaObrigacoesComponent: {
        component: ComplianceBaixasEAutomacoesComponent,
        name: 'ComplianceBaixaObrigacoesComponent'
    },
    LogsAlteracoesComponent: {
        component: LogsAlteracoesComponent,
        name: 'LogsAlteracoesComponent',
    },
    HistoricoDeAcessosComponent: {
        component: HistoricoDeAcessosComponent,
        name: 'HistoricoDeAcessosComponent',
    },
    RegrasDesconsideracoesComponent: {
        component: RegrasDesconsideracoesComponent,
        name: 'RegrasDesconsideracoesComponent'
    },
    CalendarConfirmacoesComponent: {
        component: CalendarConfirmacoesComponent,
        name: 'CalendarConfirmacoesComponent'
    },
    DashboardComponent: {
        component: DashboardComponent,
        name: 'DashboardComponent'
    },
    MonitorDeEventosComponent: {
        component: MonitorDeEventosComponent,
        name: 'MonitorDeEventosComponent'
    },
    ComplianceConfiguracoesComponent: {
        component: ComplianceConfiguracoesComponent,
        name: 'ComplianceConfiguracoesComponent'
    },
    CategoriasEStatusComponent: {
        component: CategoriasEStatusComponent,
        name: 'CategoriasEStatusComponent'
    },
    EntradasComponent: {
        component: NotasFiscaisEntradaComponent,
        name: 'EntradasComponent'
    },
    NfeTerceirosComponent: {
        component: NfeTerceirosComponent,
        name: 'NfeTerceirosComponent'
    },
    ControleDeCertificadosComponent: {
        component: ControleDeCertificadosComponent,
        name: ControleDeCertificadosComponent
    },
    AnalisesXMLSaidasComponent: {
        component: AnalisesXMLSaidasComponent,
        name: AnalisesXMLSaidasComponent
    },
    AnalisesXMLSaidasDetalhesComponent: {
        component: AnalisesXMLSaidasDetalhesComponent,
        name: AnalisesXMLSaidasDetalhesComponent
    },
    RegrasAnaliseXmlSaidaComponent: {
        component: RegrasAnaliseXmlSaidaComponent,
        name: RegrasAnaliseXmlSaidaComponent
    },
    InboxComponent: {
        component: InboxComponent,
        name: InboxComponent
    }
};
