import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Helpers} from "../../../core/helpers";

@Injectable({
    providedIn: 'root'
})
export class DashboardService {
    http = inject(HttpClient);

    getPeriodos(email: string | null = null): Observable<any> {

        let extra = '';
        if (email) {
            extra = '?email=' + email;
        }

        return this.http.get(`${environment.apiUrl}/filtros/compliance/notas/dashboard/periodos${extra}`);
    }


    retornarSelectsEmpresas(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/empresa/select`);
    }


    graficoPorEmpresa(filtros: any): Observable<any> {

        const queryStr = Helpers.montaQueryString(filtros, ['periodo_atual', 'periodo_anterior']);

        return this.http.get(`${environment.apiUrl}/compliance/notas/dashboard/nao-escituradas/empresa${queryStr}`);
    }

    graficoPorAno(filtros: any): Observable<any> {

        const queryStr = Helpers.montaQueryString(filtros, ['periodo_atual', 'periodo_anterior']);

        return this.http.get(`${environment.apiUrl}/compliance/notas/dashboard/nao-escituradas/ano${queryStr}`);
    }

    graficoTopFiliais(filtros: any): Observable<any> {

        const queryStr = Helpers.montaQueryString(filtros, ['periodo_atual', 'periodo_anterior']);

        return this.http.get(`${environment.apiUrl}/compliance/notas/dashboard/nao-escituradas/top-filiais${queryStr}`);
    }

    graficoPorGrupoEmpresarial(filtros: any): Observable<any> {

        const queryStr = Helpers.montaQueryString(filtros, ['periodo_atual', 'periodo_anterior']);

        return this.http.get(`${environment.apiUrl}/compliance/notas/dashboard/nao-escituradas/grupo-empresarial${queryStr}`);
    }
}
