<nz-page-header class="site-page-header" nzTitle="Cadastros" nzSubtitle="Domicílio Eletronico"></nz-page-header>
<nz-content>
    <div nz-row [nzGutter]="24">
        <div nz-col nzXs="24" nzSm="24" nzMd="4" nzLg="4" nzXl="4">
            <nz-affix [nzOffsetTop]="offsetTop" [nzTarget]="target">
                <nz-anchor class="transparent-anchor" [nzAffix]="false" [nzShowInkInFixed]="true"
                           [nzContainer]="target">
                    <nz-link nzHref="#federal" nzTitle="Federal" *ngIf="tabAtivaOne === 0"></nz-link>
                    <nz-link nzHref="#estadual" nzTitle="Estadual" *ngIf="tabAtivaOne === 0"></nz-link>
                    <nz-link nzHref="#municipal" nzTitle="Municipal" *ngIf="tabAtivaOne === 0"></nz-link>
                    <nz-link nzHref="#cardTabsOne" nzTitle="Domicílio Eletronico" *ngIf="tabAtivaOne === 1"></nz-link>
                    <nz-link #cadastroAnchor id="cadastroAnchor" nzHref="#cardTabsTwo"
                             nzTitle="Cadastro"
                             *ngIf="tabAtivaTwo === 0 && showTabsTwo"></nz-link>
                </nz-anchor>
            </nz-affix>
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="20" nzLg="20" nzXl="20">
            <nz-alert nzType="warning" [nzMessage]="checkedMessage" style="margin-bottom: 15px !important;"
                      *ngIf="quantidadeEmpresasInscricoes > 0">
                <ng-template #checkedMessage>
                    <div nz-row nzAlign="middle">
                        <div nz-col nzSpan="24">
                            Existe(m) <strong>{{quantidadeEmpresasInscricoes}}</strong> empresa(s) sem Inscrições e
                            Regimes
                            definido. <a
                                (click)="openTab('/cadastros/inscricoes-regimes/', '', '')">Ir para Cadastro de
                            Inscrições e Regimes</a>
                        </div>
                    </div>
                </ng-template>
            </nz-alert>
            <nz-card [ngClass]="[!loadingCardTabsOne ? 'ant-card-table' : '']"
                     [nzLoading]="loadingCardTabsOne"
                     id="cardTabsOne">
                <nz-card-tab>
                    <nz-tabset nzSize="large" [(nzSelectedIndex)]="tabAtivaOne"
                               (nzSelectChange)="changeTabs($event, 'one')"
                               [nzTabBarExtraContent]="tabsOneExtra">
                        <nz-tab [nzDisabled]="loadingCardTabsOne" nzTitle="Orgão"></nz-tab>
                        <nz-tab nzTitle="Domicílio Eletrônico"></nz-tab>
                    </nz-tabset>
                </nz-card-tab>
                <nz-divider id="federal" nzText="Federal" nzOrientation="left"
                            style="padding: 0 15px; font-weight: bold;"
                            *ngIf="tabAtivaOne === 0"></nz-divider>
                <nz-table #tableFederal
                          nzSize="small"
                          [nzData]="dataFederal"
                          [nzFrontPagination]="false"
                          [nzShowPagination]="false"
                          *ngIf="tabAtivaOne === 0"
                >
                    <thead>
                    <tr>
                        <th colspan="3">Cadastros</th>
                        <th colspan="2">Domicílio Eletronico</th>
                        <th></th>
                    </tr>
                    <tr>
                        <th nzColumnKey="matriz" [nzSortFn]="true" nzAlign="center">Unidade</th>
                        <th nzColumnKey="obrigacoesSugeridas" [nzSortFn]="true" nzAlign="center">DEC's Sugeridos<br>
                            (por empresa)
                        </th>
                        <th nzColumnKey="empresasCadastradas" [nzSortFn]="true" nzAlign="center">Empresas Habilitadas
                        </th>
                        <th nzColumnKey="estimadas" [nzSortFn]="true" nzAlign="center">Estimada(s)</th>
                        <th nzColumnKey="cadastradas" [nzSortFn]="true" nzAlign="center">Cadastrada(s)</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let item of tableFederal.data">
                        <td nzAlign="center">{{item.descricao}}</td>
                        <td nzAlign="center">{{item.cadastros.certidoes_sugeridas}}</td>
                        <td nzAlign="center">{{item.cadastros.empresas_cadastradas}}</td>
                        <td nzAlign="center">{{item.certidoes.estimadas}}</td>
                        <td nzAlign="center">{{item.certidoes.cadastradas}}</td>
                        <td nzAlign="center">
                            <button nz-button nzType="link" (click)="filtrarOrgao('Federal', item.label)">Detalhes
                            </button>
                        </td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr>
                        <td nzAlign="center">Total</td>
                        <td></td>
                        <td nzAlign="center"></td>
                        <td nzAlign="center">{{totaisFederal.estimadas}}</td>
                        <td nzAlign="center">{{totaisFederal.cadastradas}}</td>
                        <td></td>
                    </tr>
                    </tfoot>
                </nz-table>
                <nz-divider id="estadual" nzText="Estadual" nzOrientation="left"
                            style="padding: 0 15px; font-weight: bold;"
                            *ngIf="tabAtivaOne === 0"></nz-divider>
                <nz-table #tableEstadual
                          nzSize="small"
                          [nzData]="dataEstadual"
                          [nzFrontPagination]="false"
                          [nzShowPagination]="false"
                          *ngIf="tabAtivaOne === 0"
                >
                    <thead>
                    <tr>
                        <th colspan="3">Cadastros</th>
                        <th colspan="2">Domicílio Eletronico</th>
                        <th></th>
                    </tr>
                    <tr>
                        <th nzAlign="center">UF</th>
                        <th nzAlign="center">DEC's Sugeridos<br>(por empresa)</th>
                        <th nzAlign="center">Empresas c/ IEs Habilitadas</th>
                        <th nzAlign="center">Estimada(s)</th>
                        <th nzAlign="center">Cadastrada(s)</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let item of tableEstadual.data">
                        <td nzAlign="center">{{item.label}}</td>
                        <td nzAlign="center">{{item.cadastros.certidoes_sugeridas}}</td>
                        <td nzAlign="center">{{item.cadastros.empresas_cadastradas}}</td>
                        <td nzAlign="center">{{item.certidoes.estimadas}}</td>
                        <td nzAlign="center">{{item.certidoes.cadastradas}}</td>
                        <td nzAlign="center">
                            <button nz-button nzType="link" (click)="filtrarOrgao('Estadual', item.label)">Detalhes
                            </button>
                        </td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr>
                        <td nzAlign="center">Total</td>
                        <td></td>

                        <td nzAlign="center">{{totaisEstaduais.empresasCadastradas}}</td>
                        <td nzAlign="center">{{totaisEstaduais.estimadas}}</td>
                        <td nzAlign="center">{{totaisEstaduais.cadastradas}}</td>
                        <td></td>
                    </tr>
                    </tfoot>
                </nz-table>
                <nz-divider id="municipal" nzText="Municipal" nzOrientation="left"
                            style="padding: 0 15px; font-weight: bold;"
                            *ngIf="tabAtivaOne === 0"></nz-divider>
                <nz-table #tablePrevidenciario
                          nzSize="small"
                          [nzData]="dataPrevidenciario"
                          [nzFrontPagination]="false"
                          [nzShowPagination]="false"
                          *ngIf="tabAtivaOne === 0">
                    <thead>
                    <tr>
                        <th></th>
                        <th colspan="2">Cadastros</th>
                        <th colspan="2">Domicílio Eletronico</th>
                        <th></th>
                    </tr>
                    <tr>
                        <th nzAlign="center">Cidade</th>
                        <th nzAlign="center">DEC's Sugeridos<br>(por empresa)</th>
                        <th nzAlign="center">Empresas Habilitadas</th>
                        <th nzAlign="center">Estimada(s)</th>
                        <th nzAlign="center">Cadastrada(s)</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let item of tablePrevidenciario.data">
                        <td nzAlign="center">{{item.municipio}}</td>
                        <td nzAlign="center">{{item.cadastros.certidoes_sugeridas}}</td>
                        <td nzAlign="center">{{item.cadastros.empresas_cadastradas}}</td>
                        <td nzAlign="center">{{item.certidoes.estimadas}}</td>
                        <td nzAlign="center">{{item.certidoes.cadastradas}}</td>
                        <td nzAlign="center">
                            <button nz-button nzType="link" (click)="filtrarOrgao('Municipal', item.label)">
                                Detalhes
                            </button>
                        </td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr>
                        <td nzAlign="center">Total</td>
                        <td></td>
                        <td nzAlign="center"></td>
                        <td nzAlign="center">{{totaisPrevidenciario.estimadas}}</td>
                        <td nzAlign="center">{{totaisPrevidenciario.cadastradas}}</td>
                        <td></td>
                    </tr>
                    </tfoot>
                </nz-table>
                <nz-table #tableObrigacoes
                          nzSize="small"
                          [nzData]="arrayTableObrigacoes"
                          [nzFrontPagination]="false"
                          [nzShowPagination]="false"
                          *ngIf="tabAtivaOne === 1">
                    <thead>
                    <tr>
                        <th nzAlign="center">Domicílio Eletronico</th>
                        <th nzAlign="center">DEC's cadastrados por empresa</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let data of tableObrigacoes.data">
                        <td nzAlign="center">
                            {{data.descricao}}
                        </td>
                        <td nzAlign="center">
                            {{data.empresas_cadastradas | number:'' :'pt-br'}}
                        </td>
                        <td nzAlign="center">
                            <button nz-button nzType="link" [nzLoading]="loadings.editarDec.includes(data.id)"
                                    (click)="editarDomicilio(data, null)">Editar
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </nz-table>
            </nz-card>
            <div #cardWidth></div>

            <div id="cardTabsTwo" #cardTabsTwo>

                <ng-template #titleCardTabsTwo>
                    <div style="display: flex; justify-content: space-between">
                        <span>{{decSelecionadaLabel}}</span>

                        <nz-switch [(ngModel)]="decSelecionadaHabilitada" [nz-tooltip]="(decSelecionadaHabilitada ? 'Desabilitar' : 'Habilitar') + ' DEC'"
                                   nzCheckedChildren="Habilitada" nzUnCheckedChildren="Desabilitada"
                                   (ngModelChange)="habilitaObrigacao()"
                                   [nzLoading]="loadingHabilitandoDesabilitandoDec"
                        ></nz-switch>
                    </div>
                </ng-template>


                <nz-card [ngClass]="[statusTwo ? 'card-fullscreen' : '', !loadingCardTabsTwo ? 'ant-card-table' : '']"
                         [nzLoading]="loadingCardTabsTwo"
                         *ngIf="showTabsTwo"
                         [nzTitle]="titleCardTabsTwo"
                         [ngStyle]="{'width': cardWidthValue + 'px'}"
                         [nzExtra]="cardTabsTwoExtra">
                    <ng-template #cardTabsTwoExtra>
                        <div class="d-flex align-items-center" *ngIf="loadingAdicionarEmpresas">
                            <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
                            <nz-spin nzSimple [nzIndicator]="indicatorTemplate"></nz-spin>
                            <span class="loading" style="margin-left: 8px;">Cadastrando empresas sugeridas</span>
                        </div>
                    </ng-template>
                    <nz-card-tab>
                        <nz-tabset nzSize="large" [(nzSelectedIndex)]="tabAtivaTwo" [nzTabBarExtraContent]="tabsExtra"
                                   (nzSelectChange)="changeTabs($event, 'two')">
                            <nz-tab nzTitle="Cadastro"></nz-tab>
                        </nz-tabset>
                    </nz-card-tab>
                    <nz-alert nzType="info" nzShowIcon [nzMessage]="checkedMessage" style="margin: 15px !important;"
                              *ngIf="checkedItems.size > 0">
                        <ng-template #checkedMessage>
                            <div nz-row nzAlign="middle" nzGutter="24">
                                <div nz-col nzSpan="24">
                                    <strong class="text-primary"></strong>
                                    {{ checkedItems.size }} Registros Selecionados
                                </div>
                            </div>
                        </ng-template>
                    </nz-alert>
                    <nz-table #tableVencimentoOficial
                              [nzData]="arrayTableVencimentoOficial"
                              [nzFrontPagination]="false"
                              *ngIf="tabAtivaTwo == 0"
                              [nzTotal]="pagination.oficiais?.total"
                              [nzPageSize]="pagination.oficiais?.per_page"
                              [nzPageIndex]="pagination.oficiais?.current_page"
                              (nzQueryParams)="listByTableRegras($event, null, true)"
                              nzBordered
                              [nzShowTotal]="rangeTemplate"
                              class="scrollbar table-small">
                        <thead>
                        <tr>
                            <th nzAlign="center"></th>
                            <th style="text-align: center;">
                                <label nz-checkbox [(ngModel)]="checked"
                                       (nzCheckedChange)="onAllChecked($event)"></label>
                            </th>
                            <th nzAlign="center" nzColumnKey="empresa_nome" [nzSortFn]="true">Empresas</th>
                            <th nzAlign="center" nzColumnKey="ie" [nzSortFn]="true">IE</th>
                            <th nzAlign="center" nzColumnKey="created_at" [nzSortFn]="true">Cadastro</th>
                            <th nzAlign="center" nzColumnKey="dataInicio" [nzSortFn]="true">Data Início</th>
                            <th nzAlign="center" nzColumnKey="dataFim" [nzSortFn]="true">Data Fim</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let data of tableVencimentoOficial.data">
                            <td nzAlign="center">
                                <a nz-button nzType="text" nzDanger (click)="showConfirm(data.id)">
                                    <i class="far fa-trash-alt"></i>
                                </a>
                            </td>
                            <td nzAlign="center" (nzCheckedChange)="onItemChecked(data.id, $event)"
                                [nzChecked]="checkedItems.has(data.id)"
                                [nzDisabled]="data.disabled">
                            </td>
                            <td>
                                <span style="margin-right: 5px;">{{modoDemo(data.empresa_nome, 'empresaCodigoNome')}}</span>({{modoDemo(data?.empresa_cnpj | cpfCnpj)}}
                                )
                                <nz-tag [nzColor]="'#f50'" *ngIf="!data?.empresa_ativo"
                                        style="margin-left: 10px; font-size: 10px">
                                    Inativo
                                </nz-tag>
                                <nz-tag [nzColor]="'#c5c5c5'" *ngIf="data?.empresaAutomacaoConfiguracao_dec !== 1"
                                        style="margin-left: 10px; font-size: 10px">
                                    Desabilitado
                                </nz-tag>
                            </td>
                            <td nzAlign="center">{{data.ie}}</td>
                            <td nzAlign="center">
                                <span style="display: block;">{{data.created_at | date: 'dd/MM/yyyy HH:mm:ss'}}</span>
                                <small *ngIf="data.usuario_criacao">{{data.usuario_criacao}}</small>
                            </td>
                            <td nzAlign="center">{{data.dataInicio | date: 'dd/MM/yyyy'}}</td>
                            <td nzAlign="center">{{data.dataFim | date: 'dd/MM/yyyy'}}</td>
                            <td nzAlign="center">
                                <button nz-button nzType="link"
                                        (click)="onEditarDecEmpresa(data.id, data.ie);">
                                    Editar
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </nz-table>
                    <ng-template #rangeTemplate let-range="range" let-total>
                        {{ range[0] }}-{{ range[1] }} de {{ total }} Registros
                    </ng-template>
                </nz-card>
            </div>
        </div>
    </div>
</nz-content>
<ng-template #tabsOneExtra>
    <div class="d-flex align-items-center">
        <div class="d-flex align-items-center">
            <span style="margin-left: 8px; margin-right: 10px;">
                {{dataProcessamento ? 'Atualizado em ' + (dataProcessamento | date: 'dd/MM/yy HH:mm') : ''}}
            </span>
        </div>
        <button nz-button nzType="default"
                style="margin-right: 10px; padding-left: 8px; padding-right: 8px;"
                (click)="retornaContadoresOrgao();" title="Atualizar"
                *ngIf="tabAtivaOne === 0">
            <i nz-icon nzType="reload"></i>
        </button>
        <button nz-button [nzType]="'primary'" (click)="modalCadastrarEmMassa(true)"
                [nzLoading]="loadingSugeridasMassa"
                *ngIf="tabAtivaOne === 0">
            {{loadingSugeridasMassa ? 'Carregando...' : 'Cadastrar DECs Sugeridos'}}
        </button>
        <div class="d-flex align-items-center" *ngIf="tabAtivaOne === 1 && this.filtroObrigacoes.orgao">
            <span style="margin-left: 8px; margin-right: 10px;">
                Orgão: {{this.filtroObrigacoes.orgao}} {{this.filtroObrigacoes.fonte ? ' | ' + this.filtroObrigacoes.fonte : ''}}
            </span>
        </div>
        <button nz-button [nzType]="'default'" (click)="limparFiltros()"
                *ngIf="tabAtivaOne === 1 && this.filtroObrigacoes.orgao">
            Exibir Todos
        </button>
    </div>
</ng-template>
<ng-template #tabsExtra>
    <div class="d-flex align-items-center">
        <nz-badge [nzCount]="qtdFiltrosAtivos"
                  [nzStyle]="{ backgroundColor: '#52c41a', 'margin-right' : '10px'}">
            <button nz-button nzType="primary"
                    style="margin-right: 10px;"
                    (click)="modalFiltrar(true);">
                <i nz-icon nzType="search"></i>
                Filtros
            </button>
        </nz-badge>
        <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" style="margin-right: 10px;">
            <input (keydown.enter)="retornaEmpresasDec(currentParams.oficiais, currentSearch.oficiais);" type="text"
                   nz-input placeholder="Busca" [(ngModel)]="currentSearch.oficiais">
        </nz-input-group>
        <ng-template #suffixIconButton>
            <button nz-button nzType="primary" nzSearch
                    (click)="retornaEmpresasDec(currentParams.oficiais, currentSearch.oficiais);">
                <i nz-icon nzType="search"></i></button>
        </ng-template>
        <button nz-button [nzType]="'primary'" style="margin-right: 5px;" (click)="modalAddEmpresa(true)">
            Adicionar
        </button>
        <button [nzDropdownMenu]="menuAcoes" style="margin-right: 5px;" class="centralized-icon" nz-button nz-dropdown
                nzType="default">
            Opções
            <em nz-icon nzType="down"></em>
        </button>
        <nz-dropdown-menu #menuAcoes="nzDropdownMenu">
            <ul nz-menu>
                <li nz-submenu nzTitle="Manutenção Coletiva">
                    <ul>
                        <li nz-menu-item nzDisabled>Ações</li>
                        <li nz-menu-divider></li>
                        <li nz-menu-item (click)="showConfirmMassa()">Remover Registros</li>
                        <li nz-submenu nzTitle="Editar Registros">
                            <ul>
                                <li nz-menu-item nzDisabled>Ações</li>
                                <li nz-menu-divider></li>
                                <li nz-menu-item (click)="modalEditarEmpresasMassa(true);">Editar Data Inicio ou Fim
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li nz-menu-item nz-button (click)="showConfirmAdicionarDecs()">Adicionar DEC's Sugeridos</li>
            </ul>
        </nz-dropdown-menu>
        <button nz-button [nzType]="'default'" style="margin-right: 5px;" (click)="reloadCardTwo()">
            <i class="fas fa-redo-alt"></i>
        </button>
        <button nz-button [nzType]="'default'" (click)="clickEvent('two')">
            <i class="fas" [ngClass]="!statusTwo ? 'fa-expand' : 'fa-compress'"></i>
        </button>
    </div>
</ng-template>
<nz-modal [(nzVisible)]="formAddEmpresa.modalVisible"
          [nzTitle]="'Adicionar Empresa'" [nzClosable]="true"
          (nzOnCancel)="modalAddEmpresa(false)"
          [nzWidth]="848">
    <nz-row *nzModalContent>
        <nz-col nzSpan="24">
            <form nz-form [nzNoColon]="true" [formGroup]="formAddEmpresa.formGroup">
                <nz-form-item *ngIf="!modalEmpresaEditar">
                    <nz-form-label [nzSpan]="6" nzRequired>Empresa</nz-form-label>
                    <nz-form-control [nzSpan]="14" nzErrorTip="Empresa Obrigátorio">
                        <nz-select nzShowSearch nzAllowClear
                                   (ngModelChange)="getComboIe(formAddEmpresa.formGroup.get('empresa_id').value)"
                                   [nzPlaceHolder]="'Selecione'" formControlName="empresa_id">
                            <nz-option *ngFor="let opt of comboEmpresas"
                                       [nzLabel]="modoDemo(opt.descricao, 'empresaCodigoNome') + ' (' + (modoDemo(opt.cnpj | cpfCnpj, 'cnpj'))+')'"
                                       [nzValue]="opt.id"
                            >
                            </nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item *ngIf="exibirIE && !modalEmpresaEditar">
                    <nz-form-label [nzSpan]="6">IE</nz-form-label>
                    <nz-form-control [nzSpan]="14">
                        <nz-select nzShowSearch
                                   nzPlaceHolder="Selecione"
                                   formControlName="ie">
                            <nz-option *ngFor="let opt of arraySelectIes"
                                       [nzLabel]="opt.ie"
                                       [nzValue]="opt.ie"
                            ></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label [nzSpan]="6" nzRequired>Data Início</nz-form-label>
                    <nz-form-control [nzSpan]="14" nzErrorTip="Data Início Obrigátorio">
                        <nz-date-picker (keyup)="maskNZDatePicker($event)" style="width: 100%;"
                                        formControlName="dataInicio"
                                        nzFormat="dd/MM/yyyy"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="6">Data Fim</nz-form-label>
                    <nz-form-control [nzSpan]="14">
                        <nz-date-picker (keyup)="maskNZDatePicker($event)" style="width: 100%;"
                                        formControlName="dataFim"
                                        nzFormat="dd/MM/yyyy"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
            </form>
        </nz-col>
    </nz-row>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalAddEmpresa(false)">Cancelar</button>
        <button nz-button nzType="primary" (click)="insertEmpresas()" [nzLoading]="loadings.insertEmpresas">Adicionar
        </button>
    </div>
</nz-modal>
<nz-modal [(nzVisible)]="formEditarEmpresa.modalVisible"
          [nzTitle]="'Editar Empresa'" [nzClosable]="true"
          (nzOnCancel)="modalEditarEmpresa(false)"
          [nzWidth]="648">
    <nz-row *nzModalContent>
        <nz-col nzSpan="24">
            <nz-skeleton *ngIf="loadings.modalEditar"></nz-skeleton>
            <form nz-form [nzNoColon]="true" [formGroup]="formEditarEmpresa.formGroup" *ngIf="!loadings.modalEditar">
                <nz-form-item *ngIf="!modalEmpresaEditar">
                    <nz-form-label [nzSpan]="6" nzRequired>Empresa</nz-form-label>
                    <nz-form-control [nzSpan]="14">
                        <nz-select nzShowSearch nzAllowClear
                                   [nzPlaceHolder]="'Selecione'" formControlName="empresa_id">
                            <nz-option *ngFor="let opt of arraySelectEmpresa"
                                       [nzLabel]="modoDemo(opt.descricao, 'empresaCodigoNome') + ' (' + (modoDemo(opt.empresa_cnpj | cpfCnpj, 'cnpj'))+')'"
                                       [nzValue]="opt.id"
                            >
                            </nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item *ngIf="exibirIE">
                    <nz-form-label [nzSpan]="6" nzRequired>IE:</nz-form-label>
                    <nz-form-control [nzSpan]="14">
                        <input formControlName="ie" type="hidden" [readonly]="true">
                        <span>{{empresaIeEditar}}</span>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="6" nzRequired>Data Início</nz-form-label>
                    <nz-form-control [nzSpan]="14" nzErrorTip="Data Início Obrigatório">
                        <nz-date-picker (keyup)="maskNZDatePicker($event)" style="width: 100%;"
                                        formControlName="dataInicio"
                                        nzFormat="dd/MM/yyyy"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="6">Data Fim</nz-form-label>
                    <nz-form-control [nzSpan]="14">
                        <nz-date-picker (keyup)="maskNZDatePicker($event)" style="width: 100%;"
                                        formControlName="dataFim"
                                        nzFormat="dd/MM/yyyy"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
            </form>
        </nz-col>
    </nz-row>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalEditarEmpresa(false)">Cancelar</button>
        <button nz-button nzType="primary" (click)="updateEmpresas()" [nzLoading]="loadings.updateEmpresa">Confirmar
        </button>
    </div>
</nz-modal>
<nz-modal [(nzVisible)]="formEditarEmpresaMassa.modalVisible"
          [nzTitle]="'Editar Empresas'" [nzClosable]="true"
          (nzOnCancel)="modalEditarEmpresasMassa(false)"
          [nzWidth]="648">
    <nz-row *nzModalContent>
        <nz-col nzSpan="24">
            <nz-skeleton *ngIf="loadings.modalEditarMassa"></nz-skeleton>
            <form nz-form [nzNoColon]="true" [formGroup]="formEditarEmpresaMassa.formGroup"
                  *ngIf="!loadings.modalEditarMassa">

                <nz-form-item>
                    <nz-form-label [nzSpan]="6" nzFor="dataInicio">Data Início</nz-form-label>
                    <nz-form-control [nzSpan]="14" nzErrorTip="Data Início Obrigatório!">
                        <nz-date-picker (keyup)="maskNZDatePicker($event)" style="width: 100%;"
                                        formControlName="dataInicio"
                                        nzFormat="dd/MM/yyyy"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="6">Data Fim</nz-form-label>
                    <nz-form-control [nzSpan]="14">
                        <nz-date-picker (keyup)="maskNZDatePicker($event)" style="width: 100%;"
                                        formControlName="dataFim"
                                        nzFormat="dd/MM/yyyy"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
            </form>

        </nz-col>
    </nz-row>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalEditarEmpresasMassa(false)">Cancelar</button>
        <button nz-button nzType="primary" (click)="updateEmpresasMassa()" [nzLoading]="loadings.updateEmpresa">
            Confirmar
        </button>
    </div>
</nz-modal>
<nz-modal [(nzVisible)]="formCadastrarEmMassa.modalVisible"
          [nzTitle]="'Cadastrar Empresas Sugeridas em Massa'" [nzClosable]="true"
          (nzOnCancel)="modalCadastrarEmMassa(false);"
          [nzWidth]="648">
    <nz-row *nzModalContent>
        <nz-col nzSpan="24">
            <form nz-form [nzNoColon]="true" [formGroup]="formCadastrarEmMassa.formGroup" style="width: 100%">
                <nz-form-item>
                    <nz-form-label [nzSpan]="6">Empresa</nz-form-label>
                    <nz-form-control [nzSpan]="12">
                        <nz-select nzShowSearch
                                   nzPlaceHolder="Selecione"
                                   formControlName="empresa_id"
                                   (ngModelChange)="changeSelectCnds($event)">
                            <nz-option [nzLabel]="'Todas'"
                                       [nzValue]="'todas'"></nz-option>
                            <nz-option *ngFor="let opt of arraySelectEmpresa"
                                       [nzLabel]="modoDemo(opt.descricao, 'empresaCodigoNome') + ' (' + (modoDemo(opt.empresa_cnpj | cpfCnpj, 'cnpj'))+')'"
                                       [nzValue]="opt.id"
                            ></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item style="margin-bottom: 0px;" *ngIf="mostrarCndsSugeridas">
                    <nz-form-label [nzSpan]="12" [nzOffset]="6" style="text-align: center; font-weight: bold;">
                        Certidões
                    </nz-form-label>
                </nz-form-item>
                <nz-form-item style="margin-bottom: 0px;" *ngIf="mostrarCndsSugeridas">
                    <nz-form-label [nzSpan]="10" style="text-align: center">Não selecionadas</nz-form-label>
                    <nz-form-label [nzSpan]="10" [nzOffset]="4" style="text-align: center">Selecionadas</nz-form-label>
                </nz-form-item>
                <nz-form-item *ngIf="mostrarCndsSugeridas">
                    <nz-form-control [nzSpan]="10">
                        <cdk-virtual-scroll-viewport itemSize="73" class="demo-infinite-container">
                            <nz-list nzBordered nzSize="small" style="border: none;">
                                <nz-list-item *ngFor="let item of listaNaoSelecionadas"
                                              (click)="clickNaoSelecionado(item.domicilioEletronico_id);"
                                              [class.itemSelected]="selectedNaoSeleciodo.includes(item.domicilioEletronico_id)"
                                >
                                    {{item.domicilioEletronico_id}}
                                </nz-list-item>
                            </nz-list>
                        </cdk-virtual-scroll-viewport>
                    </nz-form-control>
                    <nz-form-control [nzSpan]="4" style="text-align: center; display: inline-block">
                        <button nzTooltipTitle="Enviar" nz-tooltip nzTooltipPlacement="top"
                                nz-button nzType="link" nzBlock nzSize="small" class="botoesAcoesList"
                                (click)="acaoDobleList('right');">
                            <i nz-icon nzType="right"></i>
                        </button>
                        <button nzTooltipTitle="Enviar todas" nz-tooltip nzTooltipPlacement="top"
                                nz-button nzType="link" nzBlock nzSize="small" class="botoesAcoesList"
                                (click)="acaoDobleList('double-right')">
                            <i nz-icon nzType="double-right"></i>
                        </button>
                        <button nzTooltipTitle="Remover" nz-tooltip nzTooltipPlacement="top"
                                nz-button nzType="link" nzBlock nzSize="small" class="botoesAcoesList"
                                (click)="acaoDobleList('left');">
                            <i nz-icon nzType="left"></i>
                        </button>
                        <button nzTooltipTitle="Remover todas" nz-tooltip nzTooltipPlacement="top"
                                nz-button nzType="link" nzBlock nzSize="small" class="botoesAcoesList"
                                (click)="acaoDobleList('double-left')">
                            <i nz-icon nzType="double-left"></i>
                        </button>
                    </nz-form-control>
                    <nz-form-control [nzSpan]="10">
                        <cdk-virtual-scroll-viewport itemSize="73" class="demo-infinite-container">
                            <nz-list nzBordered nzSize="small" style="border: none;">
                                <nz-list-item *ngFor="let item of listaSimSelecionadas"
                                              (click)="clickSimSelecionado(item.domicilioEletronico_id);"
                                              [class.itemSelected]="selectedSimSeleciodo.includes(item.domicilioEletronico_id)">
                                    {{item.domicilioEletronico_id}}
                                </nz-list-item>
                            </nz-list>
                        </cdk-virtual-scroll-viewport>
                    </nz-form-control>
                </nz-form-item>
            </form>
        </nz-col>
    </nz-row>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalCadastrarEmMassa(false);">Cancelar</button>
        <button nz-button nzType="primary" (click)="cadastrarSugeridasMassa()">Adicionar</button>
    </div>
</nz-modal>
<nz-modal [(nzVisible)]="formFiltrar.modalVisible" [nzTitle]="'Filtros Avançados'" [nzClosable]="true"
          (nzOnCancel)="modalFiltrar(false)" [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzDisableAutoTips]="true" [nzNoColon]="true" [formGroup]="formFiltrar.formGroup"
              style="width: 100%;">

            <nz-form-item>
                <nz-form-label [nzSpan]="5">Inativo</nz-form-label>
                <nz-form-control [nzSpan]="14"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.inativo">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="inativo">
                        <nz-option
                                [nzLabel]="'Mostrar somente inativas e desabilitadas'" [nzValue]="'somente'">
                        </nz-option>
                        <nz-option
                                [nzLabel]="'Mostrar também inativas e desabilitadas'" [nzValue]="'tambem'">
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>


        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalFiltrar(false)">Cancelar</button>
        <button nz-button nzType="primary" (click)="confirmaFiltrar()">Confirmar</button>
    </div>
</nz-modal>
