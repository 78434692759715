import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {AbstractService} from '@services/abstract.service';
import {Observable} from 'rxjs';
import {Helpers} from '../../../core/helpers';


@Injectable({providedIn: 'root'})
export class ComplianceObrgacoesService extends AbstractService<any> {

    constructor(http: HttpClient) {
        super(http, '/compliance/obrigacoes', {});
    }


    retornaAno(ano): Observable<any> {

        return this.http.get(`${environment.apiUrl}/compliance/obrigacoes/retorna-ano/${ano}`);

    }

    retornaCompetencias() {

        return this.http.get(`${environment.apiUrl}/compliance/obrigacoes/retorna-competencias`);
    }


    retornaContadores(mes, ano): Observable<any> {

        const mesAno = mes + '-' + ano;

        return this.http.get(`${environment.apiUrl}/compliance/obrigacoes/retorna-competencias/${mesAno}`);

    }

    retornaObrigacoes(mes, ano): Observable<any> {

        if (Number(mes) < 10) {
            mes = '0' + mes;
        }

        const mesAno = mes + '-' + ano;

        return this.http.get(`${environment.apiUrl}/compliance/obrigacoes/retorna-obrigacoes/${mesAno}`);

    }

    retornaDivergencias(mes, ano, filtros): Observable<any> {

        if (Number(mes) < 10) {
            mes = '0' + mes;
        }
        const mesAno = mes + '-' + ano;

        const queryParams = [];
        for (const [key, value] of Object.entries(filtros)) {
            if (value) {
                queryParams.push(key + '=' + value);
            }
        }

        const queryString = queryParams.length > 0 ? '?' + queryParams.join('&') : '';

        return this.http.get(`${environment.apiUrl}/compliance/obrigacoes/retorna-divergencias/${mesAno}${queryString}`);

    }

    retornaAusencias(mes, ano, filtros): Observable<any> {

        if (Number(mes) < 10) {
            mes = '0' + mes;
        }
        const mesAno = mes + '-' + ano;

        const queryString = Helpers.montaQueryString(filtros);

        return this.http.get(`${environment.apiUrl}/compliance/obrigacoes/retorna-ausencias/${mesAno}${queryString}`);


    }

    retornarSelectsEmpresas(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/empresa/select`);
    }

    retornarSelectsObrigacoes(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/obrigacoes/select`);
    }

    retornarSelectsOcorrencias(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/filtros/obrigacoes/ocorrencias/select`);
    }

    exportExcel(tabela: string, mes: number, ano: number, filtros: any): any {

        const queryParams = Helpers.montaQueryString(filtros);
        let mesString = '';
        if (Number(mes) < 10) {
            mesString = '0' + mes;
        } else {
            mesString = mes.toString();
        }
        const mesAno = mesString + '-' + ano;

        return this.http.get(`${environment.apiUrl}/compliance/obrigacoes/${tabela}/${mesAno}/exportar${queryParams}`,
            {responseType: 'blob'});

    }

    retornaObservacao(tipoOcorrencia, idOcorrencia): Observable<any> {

        return this.http.get(`${environment.apiUrl}/compliance/obrigacoes/retorna-observacoes/${tipoOcorrencia}/${idOcorrencia}`);

    }

    removerObservacao(tipoOcorrencia, idObservasao): Observable<any> {

        return this.http.delete(`${environment.apiUrl}/compliance/obrigacoes/excluir-observacao/${tipoOcorrencia}/${idObservasao}`);

    }

    adicionarObservacao(tipoOcorrencia, idOcorrencia, descricao): Observable<any> {

        const body = {
            descricao
        };

        return this.http.post(`${environment.apiUrl}/compliance/obrigacoes/gravar-observacao/${tipoOcorrencia}/${idOcorrencia}`, body);
    }

    editarObservacao(tipoOcorrencia, idOcorrencia, idObservacao, descricao): Observable<any> {

        const body = {
            descricao
        };

        return this.http.put(`${environment.apiUrl}/compliance/obrigacoes/gravar-observacao/${tipoOcorrencia}/${idOcorrencia}/${idObservacao}`, body);
    }

    justificarComoSemMovimento(tipoOcorrencia, idOcorrencia, acao): Observable<any> {

        return this.http.post(
            `${environment.apiUrl}/compliance/obrigacoes/gravar-sem-movimento/${tipoOcorrencia}/${idOcorrencia}/${acao}`, {});

    }

    retornaDetalheDivergencia(divergenciaId: string) {

        return this.http.get(`${environment.apiUrl}/compliance/obrigacoes/retorna-divergencias/detalhe/${divergenciaId}`);
    }
}
